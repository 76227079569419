import styled from 'styled-components';

export const ContainerServer = styled.div`
    border: 1px solid black;
    height:550px;
    background-color:#f0f5f5;
`;

export const TextServeur = styled.div`
    text-align:center;
    font-size:1.2rem;
`;

export const TextLocal = styled.div`
    text-align:center;
    font-size:.8rem;
`;

export const ServeurContainer = styled.div`
    display:flex;
    flex-direction:row;
`;

export const ServeurBouton = styled.div`
    margin-left:350px;

    @media screen and (max-width: 640px){
        margin-left:30px;
    }

    @media screen and (max-width: 768px){
        margin-left:40px;
    }
`;

export const BoutonAcces = styled.button`
    border: none;
    color: #ffffff;
    display: flex;
    width:110px;
    height: 40px;
    border-radius:10px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    font-size:15px;
    background-color:#b3ffb3;

    @media screen and (max-width: 640px){
        width:70px;
        height: 20px;
    }

    @media screen and (max-width: 768px){
        width:110px;
        height: 40px;
    }

    &:hover{
        background-color:#00b300;
    }
`;

export const ServeurForm = styled.div`
    margin: auto;
    width: 25%;
    border: 1px solid #999999;
    padding: 10px;

    @media screen and (max-width: 640px){
        margin: auto;
        width:90%;
        padding: 5px;
    }

    @media screen and (max-width: 768px){
        margin: auto;
        width:70%;
        padding: 5px;
    }
`;

export const TextForm = styled.div`
    background-color:#999999;
    color:white;
    border:1px solid #999999;
    text-align:center;
    font-size:1.2rem;
`;

export const LargeInput = styled.input`
    width:300px;
    height:30px;
    border-radius:5px;

    @media screen and (max-width: 640px){
        width:200px;
    }

    @media screen and (max-width: 768px){
        width:230px;
    }
`;

export const SmallInput = styled.input`
    width:200px;
    height:30px;
    border-radius:5px;

    @media screen and (max-width: 640px){
        width:100px;
    }

    @media screen and (max-width: 768px){
        width:150px;
    }
`;

export const PortInput = styled.input`
    width:85px;
    height:30px;
    border-radius:5px;

    @media screen and (max-width: 640px){
        width:55px;
    }

    @media screen and (max-width: 768px){
        width:65px;
    }
`;

export const TestButtons = styled.div`
    margin-left:5px;

    @media screen and (max-width: 640px){
        margin-left:5px;
    }

    @media screen and (max-width: 768px){
        margin-left:5px;
    }
`;

export const Test = styled.button`
    background-color:#999999;
    color:white;
    border:1px solid #999999;
    display: flex;
    width:90px;
    height: 20px;
    border-radius:5px;
    align-items: center;
    justify-content: center;
    font-size:15px;

    &:hover{
        background-color:#00b300;
    }
`;

export const Cancel = styled.button`
    background-color:#999999;
    color:white;
    border:1px solid #999999;
    display: flex;
    width:90px;
    height: 20px;
    border-radius:5px;
    align-items: center;
    justify-content: center;
    font-size:15px;

    &:hover{
        background-color:#00b300;
    }

    @media screen and (max-width: 640px){
        width:50px;
    }

    @media screen and (max-width: 768px){
        width:70px;
    }
`;

export const Okay = styled.button`
    background-color:#1aff1a;
    color:white;
    border:1px solid #1aff1a;
    display: flex;
    width:90px;
    height: 20px;
    border-radius:5px;
    align-items: center;
    justify-content: center;
    font-size:15px;

    &:hover{
        background-color:#00b300;
    }

    @media screen and (max-width: 640px){
        width:40px;
    }

    @media screen and (max-width: 768px){
        width:60px;
    }
`;

export const FormScroller = styled.form`
    width:50%;
    height:200px;
    overflow-y:scroll;
    scrollbar-color:rebeccapurple green;
    scrollbar-width:thin;
    border-radius:10px;

    @media screen and (max-width: 640px){
        width:80%;
    }

    @media screen and (max-width: 768px){
        width:80%;
    }
`;