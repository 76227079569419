import React from 'react';
import '../pouchdatabase';
import '../database.css';
import { 
    LargeInput, 
    ServeurContainer, 
    SmallInput, 
    PortInput , 
    FormScroller, 
} from '../../serveur/ServeurStyles';
import { ButtonDiv, StyleButton } from '../DatabaseStyles';

function PrevDeskBase() {
    return ( 
        <div id="prevdeskbase">
            <br/>
            <FormScroller>
                <ServeurContainer>
                    <div>
                        <SmallInput id='hostnamebasechange' type='text' placeholder='Hostname' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='hostportbasechange' type='text' placeholder='Port' />
                    </div>
                </ServeurContainer>
                <br/>
                <div>
                    <LargeInput id='usernamebasechange' type='text' placeholder='Username' />
                </div>
                <br/>
                <div>
                    <LargeInput id='passwordnamebasechange' type='password' placeholder='Password' />
                </div>
                
                <div style={{display:"none"}} id='datanameto'>
                    <LargeInput id='databasenametochange' type='text' placeholder='Database Name' />
                </div>
                <br/>
                <div id='dataname'>
                    <LargeInput id='databasenamechange' type='text' placeholder='Database Name' />
                </div>
                
                <div id='servername'>
                    <LargeInput id='servernamechange' type='text' placeholder='server Name' />
                </div>
                <br/>
                <ButtonDiv id='confirmer'>
                    <StyleButton id='confirm'>Confirm</StyleButton>
                </ButtonDiv>
                <br/>
            </FormScroller>
        </div>
    );
}

export default PrevDeskBase;