import React from 'react';
import '../pouchtasks';
import '../tasks.css';

import DeskTasks from '../compotasks/DeskTasks';
import MobileTasks from '../compotasks/MobileTasks';

function NewTasks() {
    return ( 
        <div id='newtasks'>
           <DeskTasks />
           <MobileTasks /> 
        </div>
    );
}

export default NewTasks;