import React from 'react';
import '../pouchtasks';
import '../tasks.css';
import { 
    LargeInput, 
    ServeurContainer, 
    SmallInput, 
    PortInput , 
    FormScroller, 
} from '../../serveur/ServeurStyles';
import { ButtonDiv, StyleButton } from '../TasksStyles';

function PrevDeskTasks() {
    return ( 
        <div id='prevdesktasks'>
            <br/>
            <FormScroller>
                <ServeurContainer>
                    <div>
                        <SmallInput id='hostnametaskchange' type='text' placeholder='Hostname' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='hostporttaskchange' type='text' placeholder='Port' />
                    </div>
                </ServeurContainer>
                <br/>
                <div>
                    <LargeInput id='usernametaskchange' type='text' placeholder='Username' />
                </div>
                <br/>
                <div>
                    <LargeInput id='passwordnametaskchange' type='password' placeholder='Password' />
                </div>
                
                <div style={{display:"none"}} id='tasnametochange'>
                    <LargeInput id='tasknametochange' type='text' placeholder='Database Name' />
                </div>
                <br/>
                <div id='tasnamechange'>
                    <LargeInput id='tasknamechange' type='text' placeholder='Database Name' />
                </div>
                
                <div style={{display:"none"}} id='servertasknamechange'>
                    <LargeInput id='servertasknamechange' type='text' placeholder='server Name' />
                </div>
                <br/>
                <ButtonDiv id='confirmertaskchange'>
                    <StyleButton id='confirmtaskchange'>Confirm</StyleButton>
                </ButtonDiv>
                <br/>
            </FormScroller>
        </div>
    );
}

export default PrevDeskTasks;