import React from 'react';
import '../pouchqueries';
import '../queries.css';
import { TextLocal, } from '../../serveur/ServeurStyles';

import {FormList} from '../QueriesStyles';

function ListViewQueries() {
    return ( 
        <div id="listviewqueries">
            <br/>
            <FormList>
                <TextLocal>
                    <label id='textlistviewdocumentquery'>Select your template</label>
                </TextLocal>
                <br/>
                <div className='resultsList'>
                    <table className='thin_table_config'>
                        <thead>
                            <tr>
                                <th>Server</th>
                                <th>Table</th>
                            </tr>
                        </thead>
                        <tbody id='crudViewQueryTableBody'></tbody>
                    </table>
                </div>
            </FormList>
        </div>
    );
}

export default ListViewQueries;