import React from 'react';
import '../pouchdatabase';
import '../database.css';
import { 
    LargeInput, 
    ServeurContainer, 
    SmallInput, 
    PortInput , 
    FormScroller, 
} from '../../serveur/ServeurStyles';

function DeskDatabase() {
    return ( 
        <div id="deskdatabase">
            <br/>
            <FormScroller>
                <ServeurContainer>
                    <div>
                        <SmallInput id='hostnamebase' type='text' placeholder='Hostname' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='hostportbase' type='text' placeholder='Port' />
                    </div>
                </ServeurContainer>
                <br/>
                <div>
                    <LargeInput id='usernamebase' type='text' placeholder='Username' />
                </div>
                <br/>
                <div>
                    <LargeInput id='passwordnamebase' type='password' placeholder='Password' />
                </div>
                <br/>
                <div>
                    <LargeInput id='databasenamebase' type='text' placeholder='Database Name' />
                </div>
                <br/>
            </FormScroller>
        </div>
    );
}

export default DeskDatabase;