import React from 'react';
import './pouchdatabase';
import './database.css';

import NewBase from './crudbase/NewBase';
import ListBase from './crudbase/ListBase';
import PreviousBase from './crudbase/PreviousBase';

import {
    BaseContainer, 
    TextBase,
    ServeurBaseContainer, 
    ServeurButtonBase, 
    AccesButtonBase, 
    FormBase, 
    TextForm, 
    TextLocal, 
    TaskButtons, 
    Buttons, 
    TasksUpper,
    QueryBase, 
    ButtonsBottom,
    TaskAdd, 
    TaskEdit, 
    TaskDelete, 
} from './DatabaseStyles';
function Database() {
    return ( 
        <BaseContainer id='basecontaineur'>
            <br/>
            <TextBase>
                <label id='basetitle'>Database Management</label>
            </TextBase>
            <br/>
            <ServeurBaseContainer>
                <ServeurButtonBase>
                    <AccesButtonBase id='localdatabaseserver'>Local</AccesButtonBase>
                </ServeurButtonBase>
                <ServeurButtonBase>
                    <AccesButtonBase id='remotedatabaseserver'>Remote</AccesButtonBase>
                </ServeurButtonBase>
            </ServeurBaseContainer>
            <br/><br/>
            <FormBase id='databasetasks'>
                <TextForm>
                    <label id='databasetextform'>Database Form</label>
                </TextForm>
                <TextLocal>
                    <label id='textlocal'>Local / Remote Database</label>
                </TextLocal>
                <br/>
                <TaskButtons>
                    <Buttons>
                        <TasksUpper id='newdatabase'>New</TasksUpper>
                    </Buttons>
                    <Buttons>
                        <TasksUpper id='previousdatabase'>Previous</TasksUpper>
                    </Buttons>
                    <Buttons>
                        <TasksUpper id='viewdatabase'>Views</TasksUpper>
                    </Buttons>
                </TaskButtons>
                <br/>
                <QueryBase id='queriestasks'>
                    <NewBase />
                    <ListBase />
                    <PreviousBase />
                </QueryBase>
                <br/>
                <TaskButtons id='crudactions'>
                    <ButtonsBottom>
                        <TaskAdd id='adddatabase'>Add</TaskAdd>
                    </ButtonsBottom>
                    <ButtonsBottom>
                        <TaskEdit id='editdatabase'>Edit</TaskEdit>
                    </ButtonsBottom>
                    <ButtonsBottom>
                        <TaskDelete id='deletedatabase'>Delete</TaskDelete>
                    </ButtonsBottom>
                </TaskButtons>
                <div id="modaloutdatabase"></div>
                <div id="backdropoutdatabase"></div>

                <div id="modaloutalertdatabase"></div>
                <div id="backdropoutalertdatabase"></div>
            </FormBase>
        </BaseContainer>
    );
}

export default Database;