import styled from "styled-components";

export const DocumentContainer = styled.div`
    border: 0px solid black;
    height:550px;
    background-color:#f0f5f5;
`;

export const TextBase = styled.div`
    text-align:center;
    font-size:1.2rem;
`;

export const FormTable = styled.div`
    margin:auto;
    width:90%;
    height:85px;

    @media screen and (max-width: 640px){
        margin: auto;
        width:90%;
        height: 85px;
    }

    @media screen and (max-width: 768px){
        margin: auto;
        width:90%;
        height: 85px;
    }
`;

export const TextForm = styled.div`
    background-color:#999999;
    color:white;
    border:1px solid #999999;
    text-align:center;
    font-size:1.2rem;
`;

//table content styles
export const ContentContainer = styled.div`
    width: 250px;
    height:460px;
    float:left;
    background-color:#737373;
    color:#f2f2f2;

    overflow-y:scroll;
    scrollbar-color:rebeccapurple green;
    scrollbar-width:thin;
    

    @media screen and (max-width: 640px){
        height:460px;
    }

    @media screen and (max-width: 768px){
        height:460px;
    }

    @media screen and (max-width: 1024px){
        height:460px;
    }

    @media screen and (max-width: 1280px){
        height:460px;
    }
`;

export const ContentTitle = styled.label`
    font-size:1.5rem;
    font-weight:bold;
`;

export const UserGuides = styled.label`
    font-size:1.3rem;
`;

export const SubTitle = styled.label`
    font-size:1rem;
    margin-left:10px;
    &:hover {
        background-color: #212d45;
        transform: scale(1.2);
        cursor: pointer;
    }
`;

export const TextContainer = styled.div`
    width: 900px;
    height:430px;
    float:left;

    overflow-y:scroll;
    scrollbar-color:rebeccapurple green;
    scrollbar-width:thin;
    

    @media screen and (max-width: 640px){
        height:430px;
        width:100%;
    }

    @media screen and (max-width: 768px){
        height:430px;
        width:100%;
    }

    @media screen and (max-width: 1024px){
        height:430px;
        width:100%;
    }

    @media screen and (max-width: 1280px){
        height:430px;
        width:100%;
    }
`;

export const TextTitle = styled.label`
    font-size:1.5rem;
    font-weight:bold;
    color:blue;
`;

export const TextContent = styled.p`
    font-size:1rem;
    margin-left:10px;
`;

export const DivTextContent = styled.div`
    font-size:1rem;
    margin-left:10px;
`;

export const SubLink = styled.label`
    font-size:1.1rem;
    margin-left:10px;
    color:blue;
`;

export const SocialIcons = styled.a`
    transition: 0.3s ease;
    color: white;
    border-radius: 50px;
    padding: 8px;
    text-decoration:none;
    &:hover {
        background-color: #212d45;
        transform: scale(1.2);
        cursor: pointer;
    }
`