import styled from 'styled-components';

export const SideContainer = styled.div`
    width: 50px;
    height:640px;
    float:left;
    background-color:red;

    @media screen and (max-width: 640px){
        height:631px;
    }

    @media screen and (max-width: 768px){
        height:631px;
    }

    @media screen and (max-width: 1024px){
        height:631px;
    }

    @media screen and (max-width: 1280px){
        height:631px;
    }
`;

export const BurgerIcons = styled.div`
    width: 45px;
    height: 35px;
    display: flex;
    justify-content: center;
    border: 1px solid black;
    background-color:#330a00;
    color:red;
`;

export const CenterIcons = styled.div`
    width: 45px;
    height: 35px;
    display: flex;
    justify-content: center;
    border: 1px solid black;
    background-color:#999999;
    color:white;
    &:hover{
        background-color:red;
    }
`;

export const MainBottom = styled.div`
    width: 45px;
    height: 369px;
    display: flex;
    justify-content: center;
    border: 1px solid black;
    background-color:#330a00;
    position: relative;
    bottom: 0px;

    @media screen and (max-width: 768px) {
        position: relative;
        bottom: 0px;
    }
`;

export const SubBottom = styled.div`
    width: 45px;
    height: 35px;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0px;
`;

export const Img = styled.img`
  object-fit: cover;
  overflow: hidden;
  padding:0rem;
`;