import { Layout } from '../layout/Layout';
import Sidebar from "../Sidebar/Sidebar";
import Header from "../components/Header/Header";
import Principal from '../principal/Principal';
import Serveur from '../serveur/Serveur';
import Database from '../database/Database';
import Tables from '../tables/Tables';
import Tasks from '../tasks/Tasks';
import Queries from '../queries/Queries';
import Documentation from '../documentation/Documentation';
import '../indexroute';

const Home = () => {
    return (
      <Layout>
        <>
          <Sidebar />
          <Header/>
          <Principal />
          <Serveur />
          <Database />
          <Tables />
          <Tasks />
          <Queries />
          <Documentation />
        </>  
      </Layout>  
    );
  };
  
  export default Home;