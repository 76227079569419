import React from 'react';
import '../pouchtasks';
import '../tasks.css';
import {  
    ServeurContainer, 
    SmallInput, 
    PortInput , 
    FormScroller, 
} from '../../serveur/ServeurStyles';
import { /*ButtonTableDiv,*/ StyleTableButton, StyleUndoButton } from '../TasksStyles';

function EditDeleteTasks() {
    return ( 
        <div id="editdeletetasks">
            <br/>
            <FormScroller>
                <ServeurContainer>
                    <div>
                        <SmallInput id='nametaskeditdelete' type='text' placeholder='Table Name' readOnly />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='fieldtaskeditdelete' type='number' min="1" max="20" placeholder='Columns' readOnly />
                    </div>
                </ServeurContainer>
                <div style={{display:'none'}}>
                    <div>
                        <SmallInput id='taskideditdelete' type='text' placeholder='Table Id' />
                    </div>
                    <div>
                        <SmallInput id='taskdatabaseeditdelete' type='text' placeholder='Table database' />
                    </div>
                    <div>
                        <SmallInput id='taskservereditdelete' type='text' placeholder='Table server' />
                    </div>
                    <div>
                        <SmallInput id='taskconnecteditdelete' type='text' placeholder='Table connect' />
                    </div>
                </div>
                <br/>
                <div id='tasktemplateeditdelete'></div>
                <br/>
                <ServeurContainer id='confirmtaskeditdelete'>
                    <StyleTableButton id='selecttasknameeditdelete'>Select</StyleTableButton>
                    &nbsp;&nbsp;
                    <StyleUndoButton id='undotasknameeditdelete'>Undo</StyleUndoButton>
                </ServeurContainer>
                <br/>
            </FormScroller>
        </div>
    );
}

export default EditDeleteTasks;