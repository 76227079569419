import React from 'react';
import '../../pouchdocumentation';

import {GiHamburgerMenu} from 'react-icons/gi';
import {FiDatabase, FiServer, FiTool} from 'react-icons/fi';
import {BsTable} from 'react-icons/bs';
import {SiJquery} from 'react-icons/si';
import {SiReadthedocs} from 'react-icons/si';
import {BurgerIcons, CenterIcons, /*MainBottom, SubBottom, Img*/ } from '../../../Sidebar/SidebarStyles'

import { 
    TextContainer,
    TextTitle, 
    TextContent, 
    DivTextContent,
    //SubLink,  
} from '../../DocumentationStyles';

function Tutorial() {
    return ( 
        <TextContainer id="tutorial">
            <TextTitle>8.Tutorial</TextTitle>
            <TextContent>
                In this tutorial, we’ll take you to a small demo on how to use Couchbench . 
                We’ll create our first document and experiment with Couchbench views.
            </TextContent>
            <TextContent>
                Couchbench consists of several different screens. Most screens will follow a standard Fauxton 
                format, with an aspect that may undergo changes in the future. This tutorial focuses more on 
                interactive features. Each screen or page in Couchbench is known by a canonical 
                name which will always appear in this document with an underline, so you know that we refer 
                to a screen by its name, for example, <u>Home Page</u>.
            </TextContent>
            <TextContent>
                <strong style={{color:'red'}}>Step 1</strong>: If you are using Couchbench for the first time, <a href='https://couchdb.apache.org/'>download and 
                install CouchDB Server</a> on your machine (Desktop, Laptop). If this operation has already 
                been performed, then go to the next step. Nothing to download and install if you are 
                using a mobile device.
            </TextContent>
            <DivTextContent>
                <strong>A. Left side part</strong><br/>
                Seven Button Icons make up this navigation sidebar.<br/>
                <strong>1.</strong>
                <BurgerIcons>
                    <GiHamburgerMenu size="25px" />
                </BurgerIcons>
                <strong style={{color:'blue', fontStyle:'italic'}}>Back to the Home Page</strong><br/>
                <strong>2.</strong>
                <CenterIcons>
                    <FiDatabase size="25px" /> 
                </CenterIcons>
                <strong style={{color:'blue', fontStyle:'italic'}}>Databases:</strong> This button icon allows the user to start a new search to perform on his 
                CouchDB database server(s). It works like a button back to the home page by resetting all 
                the counters and research actions undertaken before to zero. Otherwise, it will produce 
                no effect.<br/>
                <strong>3.</strong>
                <CenterIcons>
                    <FiServer size="25px" /> 
                </CenterIcons>
                <strong style={{color:'blue', fontStyle:'italic'}}>Servers:</strong> This button allows the user to create and test connection with his local server 
                and his hosted remote servers. Clicking on this button opens the Create New Server 
                Connection page. This page containing all the configuration actions to be performed 
                will be described below. It represents the main button that a new Couchbench user 
                will need to press.<br/>
                <strong>4.</strong>
                <CenterIcons>
                    <BsTable size="25px" /> 
                </CenterIcons>
                <strong style={{color:'blue', fontStyle:'italic'}}>Tables:</strong> This button allows creating, updating, deleting, viewing and interacting with 
                table models in a single CouchDB server database at a time.<br/>
                <strong>5.</strong>
                <CenterIcons>
                    <FiTool size="25px" /> 
                </CenterIcons>
                <strong style={{color:'blue', fontStyle:'italic'}}>Tasks:</strong> This button allows you to select a pre-designed table model and then perform 
                CRUD(Create, Read, Edit and Delete) operations on it.<br/>
                <strong>6.</strong>
                <CenterIcons>
                    <SiJquery size="25px" /> 
                </CenterIcons>
                <strong style={{color:'blue', fontStyle:'italic'}}>Queries:</strong> This button allows you to:<br/>
                . Filtering the documents in your database to find those relevant to a particular process.<br/>
                . Extracting data from your documents and presenting it in a specific order.<br/>
                . Building efficient indexes to find documents by any value or structure that resides in them.<br/>
                . Use these indexes to represent relationships among documents.<br/>
                . Finally, with views you can make all sorts of calculations on the data in your documents.<br/>
                This version contains only some predefined and immutable queries to perform. You should use them as predefined.<br/>
                <strong>3.</strong>
                <CenterIcons>
                    <SiReadthedocs size="25px" /> 
                </CenterIcons>
                <strong style={{color:'blue', fontStyle:'italic'}}>Documentation:</strong> This button allows you to read all the documentation about Couchbench.<br/>
            </DivTextContent>
            <TextContent>
                <strong>B. Middle part</strong><br/>
                Two main buttons make up the central part which is the working environment.<br/>
                <strong>1. Local Server</strong><br/>
                This button allows the user to access the local CoucDB server installed on his computer. 
                Clicking this button allows the user to connect to a specific database and perform the 
                necessary actions. If no server exists or connection parameters entered not correct, 
                an alert prompts the user to take the necessary steps and actions to complete this action.<br/>
                <strong>2. Remote Server</strong><br/>
                This button allows the user to access the remote CoucDB server installed on his computer. 
                Clicking this button allows the user to connect to a specific database and perform the 
                necessary actions. If no server exists or connection parameters entered not correct, 
                an alert prompts the user to take the necessary steps and actions to complete this action.
            </TextContent>
            <DivTextContent>
                <strong style={{color:'red'}}>Step 2</strong>: CouchDB Connection Test<br/>
                <strong>2.1</strong>: Click button <CenterIcons><FiServer size="25px" /> </CenterIcons>
                <strong>2.2</strong>: Select the device to run Couchbench.<br/>
                <strong>2.3</strong>: Click Local (Server) then New, enter all your connection parameters.<br/>
                <strong style={{color:'green', fontSize:'18px'}}>A: Laptop/Desktop</strong><br/>
                <strong style={{fontStyle:'italic'}}>. Connection Name</strong> = Trial <br/>
                <strong style={{fontStyle:'italic'}}>. hostname</strong> = 127.0.0.1 <br/>
                <strong style={{fontStyle:'italic'}}>. Port</strong> = 5984 <br/>
                <strong style={{fontStyle:'italic'}}>. Username</strong> = mytrial <br/>
                <strong style={{fontStyle:'italic'}}>. Password</strong> = trial1 <br/>
                <strong style={{color:'gray'}}>Click Test</strong> <br/>
                <strong style={{color:'green', fontSize:'18px'}}>B: Mobile</strong><br/>
                <strong style={{fontStyle:'italic'}}>. Connection Name</strong> = Trial <br/>
                <strong style={{fontStyle:'italic'}}>. Username</strong> = mytrial <br/>
                <strong style={{fontStyle:'italic'}}>. Password</strong> = trial1 <br/>
                <strong style={{color:'gray'}}>Click Test</strong> <br/>
            </DivTextContent>
            <br/>
            <DivTextContent>
                <strong style={{color:'red'}}>Step 3</strong>: Database Management<br/>
                <strong>3.1</strong>: Click left side navigator <CenterIcons><FiDatabase size="25px" /></CenterIcons>
                <strong>3.2</strong>: Select the device to run Couchbench.<br/>
                <strong>3.3</strong>: Click Local (Server)<br/>
                <strong>3.4</strong>: Click Button New<br/>
                <strong style={{color:'green', fontSize:'18px'}}>A: Laptop/Desktop</strong><br/>
                <strong style={{fontStyle:'italic'}}>. hostname</strong> = 127.0.0.1 <br/>
                <strong style={{fontStyle:'italic'}}>. Port</strong> = 5984 <br/>
                <strong style={{fontStyle:'italic'}}>. Username</strong> = mytrial <br/>
                <strong style={{fontStyle:'italic'}}>. Password</strong> = trial1 <br/>
                <strong style={{fontStyle:'italic'}}>. Database Name</strong> = survey <br/>
                <strong style={{color:'lightgreen'}}>Click Add</strong> <br/>
                <strong style={{color:'green', fontSize:'18px'}}>B: Mobile</strong><br/>
                <strong style={{fontStyle:'italic'}}>. Username</strong> = mytrial <br/>
                <strong style={{fontStyle:'italic'}}>. Password</strong> = trial1 <br/>
                <strong style={{color:'lightgreen'}}>Click Add</strong> <br/>
                <strong style={{color:'green',fontStyle:'italic'}}>(Use the same process for Previous and Views)</strong> <br/>
            </DivTextContent>
            <br/>
            <DivTextContent>
                <strong style={{color:'red'}}>Step 4</strong>: Tables Management<br/>
                <strong>4.1</strong>: Click left side navigator <CenterIcons><BsTable size="25px" /></CenterIcons>
                <strong style={{color:'green',fontStyle:'italic'}}>(Use the same process in step 3)</strong> <br/>
                <strong style={{color:'green'}}>Click Confirm and...</strong><br/>
                <strong>4.2</strong>: Table Template<br/>
                <strong style={{fontStyle:'italic'}}>. Template Name</strong> = Choose your table Template name <br/>
                <strong style={{fontStyle:'italic'}}>. Columns</strong> = Choose your number of columns not greater than 20 <br/>
                <strong style={{color:'green'}}>Confirm and...</strong><br/>
                <strong>4.3</strong>: Enter name of each columns without space<br/>
                <strong style={{color:'lightgreen'}}>Click Create</strong><br/>
                <strong style={{color:'green',fontStyle:'italic'}}>(Use the same process for Previous and Views)</strong> <br/>
            </DivTextContent>
            <br/>
            <DivTextContent>
                <strong style={{color:'red'}}>Step 5</strong>: Documents Management<br/>
                <strong>5.1</strong>: Click left side navigator <CenterIcons><FiTool size="25px" /></CenterIcons>
                <strong style={{color:'green',fontStyle:'italic'}}>(Use the same process in step 4)</strong> <br/>
                <strong style={{color:'green'}}>Click Confirm and...</strong><br/>
                <strong>5.2</strong>: Choose your Table Template<br/>
                <strong>5.3</strong>: Select that template<br/>
                <strong style={{fontStyle:'italic'}}>. Table Task name</strong> = Enter tasks name <br/>
                <strong style={{fontStyle:'italic'}}>fill each columns with your data</strong><br/>
                <strong style={{color:'lightgreen'}}>Click Add</strong><br/>
                <strong style={{color:'green',fontStyle:'italic'}}>(Repeat the process if you have more data to insert)</strong> <br/>
            </DivTextContent>
            <br/>
            <DivTextContent>
                <strong style={{color:'red'}}>Step 6</strong>: Queries Management<br/>
                <strong>6.1</strong>: Click left side navigator <CenterIcons><SiJquery size="25px" /></CenterIcons>
                <strong style={{color:'green',fontStyle:'italic'}}>(Use the same process in step 5)</strong> <br/>
                <strong style={{color:'green'}}>Click Confirm and...</strong><br/>
                <strong>6.2</strong>: Choose your Table Template<br/>
                <strong>6.3</strong>: Confirm that template<br/>
                <strong>6.4</strong>: Choose the document to query<br/>
                <strong>6.5</strong>: Choose your query editor<br/>
                <strong style={{fontStyle:'italic'}}>fill each input based on the query selected</strong><br/>
                <strong style={{color:'green'}}>Click Execute</strong><br/>
                <strong style={{color:'green',fontStyle:'italic'}}>(Go back or close for new query)</strong> <br/>
                <br/><br/>
            </DivTextContent>
        </TextContainer>
    );
}

export default Tutorial;