import React from 'react';
import '../pouchdatabase';
import '../database.css';

import {  
    LargeInput, 
    FormScroller, 
} from '../../serveur/ServeurStyles';
import { ButtonDiv, StyleButton } from '../DatabaseStyles';

function PrevMobileBase() {
    return ( 
        <div id="prevmobilebase">
            <br/>
            <FormScroller>
                <div>
                    <LargeInput id='mobileusernamebasechange' type='text' placeholder='Username' />
                </div>
                <br/>
                <div>
                    <LargeInput id='mobilepasswordnamebasechange' type='password' placeholder='Password' />
                </div>
                <br/>
                <div style={{display:"none"}}>
                    <LargeInput id='mobiledatabasenametochange' type='text' placeholder='Database Name' />
                </div>
                <br/>
                <ButtonDiv id='confirmermobile'>
                    <StyleButton id='confirmmobile'>Confirm</StyleButton>
                </ButtonDiv>
                <br/>
            </FormScroller>
            
        </div>
    );
}

export default PrevMobileBase;