window.addEventListener('load', () => {
    document.getElementById("startcouch").addEventListener("click", startcouchChange);
    async function startcouchChange(e){
        e.preventDefault();

        document.getElementById('principal').style.display = 'none';
        document.getElementById('serveur').style.display = 'none';
        document.getElementById('basecontaineur').style.display = 'none';
        document.getElementById('tablescontainer').style.display = 'none';
        document.getElementById('taskscontainer').style.display = 'none';
        document.getElementById('queriescontainer').style.display = 'none';
        document.getElementById('documentcontainer').style.display = 'block';
        document.getElementById('tablecontentview').style.display = 'none';
    }
});