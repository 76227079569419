import React from 'react';
import '../../pouchdocumentation';

import { 
    TextContainer,
    TextTitle, 
    TextContent, 
    SubLink,  
} from '../../DocumentationStyles';

function InstallationGuide() {
    return ( 
        <TextContainer id="installationguide">
            <TextTitle>6.Installation</TextTitle>
            <TextContent>
                <strong>6.1.Install Couchbench</strong><br/>
                Couchbench is a Progressive Web Application (PWA) which does not require any specific 
                download and installation procedure as is done for applications distributed 
                on Google App and Play store. Just the ADD TO HOME SCREEN feature available on almost all 
                devices allows easy installation of the application.
            </TextContent>
            <TextContent>
                <strong>Desktop and Laptop</strong><br/>
                The first time you access Couchbench on desktop or laptop, a prompt in the search bar 
                appears asking you to install the application. Accept the prompt and you're done.
            </TextContent>
            <TextContent>
                <strong>Android Phone and Tablet</strong><br/><br/>
                1. Lunch 'Chrome' app<br/><br/>
                2. Open the website or web page you want to pin to your home screen. 
                Tap the menu icon (3 dots in upper right-hand corner) and tap Add to homescreen.<br/><br/>
                3. You’ll be able to enter a name for the shortcut and then Chrome will add it to your home screen.
            </TextContent>
            <TextContent>
                <strong>iPad or iPhone / Tablet</strong><br/><br/>
                1. Launch “Safari” app.  This does not work from the “Chrome” app.<br/><br/>
                2. Enter into the address field the URL of the website you want to create a shortcut to. 
                Tap “Go.”<br/><br/>
                3. Tap the icon featuring a right-pointing arrow coming out of a box along the top of the 
                Safari window to open a drop-down menu.<br/><br/>
                4. Tap “Add to Home Screen.” The Add to Home dialog box will appear, with the icon that 
                will be used for this website on the left side of the dialog box.<br/><br/>
                5. Enter the name for the shortcut using the on-screen keyboard and tap “Add.” 
                Safari will close automatically and you will be taken to where the icon is located on 
                your device.
            </TextContent>
            <TextContent>
                <strong>6.2.Install CouchDB-Server</strong><br/>
                <a href='https://couchdb.apache.org/fauxton-visual-guide/index.html' style={{textDecoration:'none',color:'blue'}}>Fauxton</a> is a native web-based interface built into CouchDB. 
                It provides a basic interface to the majority of the functionality, 
                including the ability to create, update, delete and view documents and design documents. 
                It provides access to the configuration parameters, and an interface for initiating 
                replication.
            </TextContent>
            <TextContent>
                <strong>Local CouchDB Server</strong><br/>
                <strong>1</strong>. Mobile devices<br/>
                Note that it is not possible to install CouchDB server on a mobile device. 
                Instead, <a href='https://pouchdb.com/guides/' style={{textDecoration:'none',color:'blue'}}>PouchDB Server</a> is the alternative and comes pre-installed to work with 
                Couchbench application. Couchbench users shouldn't worry about installing it on their 
                mobile device.<br/><br/>
                <strong>2</strong>. Desktop and Laptop<br/>
                Refer to the attached link for the installation and configuration steps on desktop and laptop. 
                <a href='https://docs.couchdb.org/en/latest/install/index.html' style={{textDecoration:'none',color:'blue'}}>Local machine</a>
            </TextContent>
            <SubLink id='introcontguideview'>7.Guide to Views</SubLink>
            <br/><br/>
        </TextContainer>
    );
}

export default InstallationGuide;