import React from 'react';
import '../../pouchdocumentation';

import { 
    TextContainer,
    TextTitle, 
    TextContent, 
    SubLink,  
} from '../../DocumentationStyles';

function TechnicalOverview() {
    return ( 
        <TextContainer id='technicaloverview'>
            <TextTitle>4.Technical Overview</TextTitle>
            <TextContent>
                Couchbench is a web application for storing and processing data in document format. 
                Each document is unique in the database and Couchbench uses a RESTful HTTP API for 
                reading and updating (add, update, delete) documents from the database.
            </TextContent>
            <TextContent>
                Documents are the primary unit of data in Couchbench and consist of twenty fields in this 
                version. Document fields are uniquely named and contain values of varying 
                types (text, number, boolean, lists, etc), and no set limit to text size or element count. 
                Document update model is lockless and optimistic.
            </TextContent>
            <TextContent>
                Couchbench also shows data in interesting and useful ways when SQL databases split 
                them into tables. Data in Couchbecnh is stored in flexible semi-structured documents 
                where each has its own implicit structure. Not acting as a fancy file server, 
                it organizes and report on data into tables such as SQL Workbench.
            </TextContent>
            <TextContent>
                To address problem of adding structure back to unstructured and semi-structured data, 
                Couchbench integrates table columns templates and a view model. Views and columns are 
                the method of aggregating and reporting on the documents in a database, 
                and are built on user needs to aggregate, join and report on database documents. 
                Users can have as many different view and columns representations of the same data as they like.
            </TextContent>
            <TextContent>
                Couchbench is a web application and CRUD operations are implementing 
                using pure javascript methods, frameworks and API. Views and templates are defined using 
                JavaScript arrays functions acting as the map part. A view function takes a CouchDB 
                document as an argument and then does whatever computation it needs to do to determine 
                the data that is to be made available through the view, if any. It can add multiple rows 
                to the view based on a single document, or it can add no rows at all.
            </TextContent>
            <TextContent>
                To protect who can read and update documents, Couchbench doesn't have its own 
                administrator accounts. The only Administrator accounts is the CouchDB administrator 
                accounts to access and update design documents. 
            </TextContent>
            <TextContent>
                Couchbench is built on the React, PouchDB, and CouchDB platform, a functional, 
                concurrent javascript programming language and development platform. 
                Couchbench is designed for lock-free concurrency. Reducing bottlenecks and avoiding 
                locks keeps the entire system working predictably under heavy loads. Couchbench 
                can accommodate many users replicating changes continiously syncing CouchDB servers 
                locally or remotely, opening and updating documents, and querying views whose indexes 
                are simultaneously being refreshed for same or other users, without needing locks.
            </TextContent>
            <SubLink id='introcontconsistency'>5.Eventual Consistency</SubLink>
            <br/><br/>
        </TextContainer>
    );
}

export default TechnicalOverview;