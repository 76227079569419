import styled from 'styled-components';

export const TasksContainer = styled.div`
    border: 1px solid black;
    height:550px;
    background-color:#f0f5f5;
`;

export const TextBase = styled.div`
    text-align:center;
    font-size:1.2rem;
`;

export const ServeurBaseContainer = styled.div`
    display:flex;
    flex-direction:row;
`;

export const ServeurButtonBase = styled.div`
    margin-left:350px;

    @media screen and (max-width: 640px){
        margin-left:30px;
    }

    @media screen and (max-width: 768px){
        margin-left:40px;
    }
`;

export const AccesButtonBase = styled.div`
    border: none;
    color: #ffffff;
    display: flex;
    width:110px;
    height: 40px;
    border-radius:10px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    font-size:15px;
    background-color:#b3ffb3;

    &:hover{
        background-color:#00b300;
    }
`;

export const FormTable = styled.div`
    margin:auto;
    width:55%;
    border: 1px solid #999999;
    height:385px;

    @media screen and (max-width: 640px){
        margin: auto;
        width:90%;
        height: 385px;
    }

    @media screen and (max-width: 768px){
        margin: auto;
        width:90%;
        height: 385px;
    }
`;

export const TextForm = styled.div`
    background-color:#999999;
    color:white;
    border:1px solid #999999;
    text-align:center;
    font-size:1.2rem;
`;

export const TextLocal = styled.div`
    text-align:center;
    font-size:0.8rem;
`;

export const TaskButtons = styled.div`
    display:flex;
    flex-direction:row;
`;

export const Buttons = styled.div`
   margin-left:120px;
   
   @media screen and (max-width: 640px){
        margin-left:15px;
    }

    @media screen and (max-width: 768px){
        margin-left:15px;
    }
`;

export const TasksUpper = styled.button`
    background-color:#999999;
    color:white;
    border:1px solid #999999;
    display: flex;
    width:70px;
    height: 20px;
    border-radius:5px;
    align-items: center;
    justify-content: center;
    font-size:15px;

    &:hover{
        background-color:#00b300;
    }
`;

export const QueryBase = styled.div`
    border: 1px solid black;
    border-radius:5px;
    width:100%;
    height:230px;

    @media screen and (max-width: 640px){
        width:90%;
        height:230px;
    }

    @media screen and (max-width: 768px){
        width:100%;
        height:230px;
    }
`;

export const ButtonsBottom = styled.div`
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
`;

export const TaskAdd = styled.button`
    background-color:#b3ffb3;
    color:white;
    border:1px solid #b3ffb3;
    width:70px;
    height: 20px;
    border-radius:5px;
    align-items: center;
    justify-content: center;
    font-size:15px;

    &:hover{
        background-color:#00b300;
    }
`;

export const TaskEdit = styled.button`
    background-color:#ffa31a;
    color:white;
    border:1px solid #ffa31a;
    width:70px;
    height: 20px;
    border-radius:5px;
    align-items: center;
    justify-content: center;
    font-size:15px;

    &:hover{
        background-color:#cc7a00;
    }
`;

export const TaskDelete = styled.button`
    background-color:#ff9980;
    color:white;
    border:1px solid #ff9980;
    width:70px;
    height: 20px;
    border-radius:5px;
    align-items: center;
    justify-content: center;
    font-size:15px;

    &:hover{
        background-color:#ff3300;
    }
`;

export const ButtonDiv = styled.div`
    align-items: center;
    justify-content: center;
    text-align:center;
`;

export const StyleButton = styled.button`
    background-color:#00e64d;
    color:white;
    border:1px solid #00e64d;
    width:100px;
    height: 30px;
    border-radius:5px;
    align-items: center;
    justify-content: center;
    font-size:15px;

    &:hover{
        background-color:#009933;
    }
`;

export const FormList = styled.form`
    width:90%;
    height:200px;
    overflow-y:scroll;
    scrollbar-color:rebeccapurple green;
    scrollbar-width:thin;
    border-radius:10px;

    @media screen and (max-width: 640px){
        width:80%;
    }

    @media screen and (max-width: 768px){
        width:80%;
    }
`;

export const ButtonTableDiv = styled.div`
    align-items: center;
    justify-content: center;
    text-align:center;
`;

export const StyleTableButton = styled.button`
    background-color:#00e64d;
    color:white;
    border:1px solid #00e64d;
    width:100px;
    height: 35px;
    border-radius:5px;
    align-items: center;
    justify-content: center;
    font-size:15px;

    &:hover{
        background-color:#009933;
    }
`;

export const StyleUndoButton = styled.button`
    background-color:#ff9980;
    color:white;
    border:1px solid #ff9980;
    width:100px;
    height: 35px;
    border-radius:5px;
    align-items: center;
    justify-content: center;
    font-size:15px;

    &:hover{
        background-color:#ff3300;
    }
`;

export const FormScrollerView = styled.form`
    width:90%;
    height:200px;
    overflow-y:scroll;
    scrollbar-color:rebeccapurple green;
    scrollbar-width:thin;
    border-radius:10px;

    @media screen and (max-width: 640px){
        width:80%;
    }

    @media screen and (max-width: 768px){
        width:80%;
    }
`;