import React from 'react';
import '../pouchtables';
import '../tables.css';
import {  
    ServeurContainer, 
    SmallInput, 
    PortInput , 
    FormScroller, 
} from '../../serveur/ServeurStyles';
import { ButtonTableDiv, StyleTableButton } from '../TablesStyles';

function ChangeTables() {
    return ( 
        <div id="changetables">
            <br/>
            <FormScroller>
                <ServeurContainer>
                    <div>
                        <SmallInput id='nametablechange' type='text' placeholder='Table Name' readOnly />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='fieldtablechange' type='number' min="1" max="20" placeholder='Columns' readOnly />
                    </div>
                </ServeurContainer>
                <div style={{display:'none'}}>
                    <div>
                        <SmallInput id='tableidchange' type='text' placeholder='Table Id' />
                    </div>
                    <div>
                        <SmallInput id='tabledatabasechange' type='text' placeholder='Table database' />
                    </div>
                    <div>
                        <SmallInput id='tableserverchange' type='text' placeholder='Table server' />
                    </div>
                    <div>
                        <SmallInput id='tableconnectchange' type='text' placeholder='Table connect' />
                    </div>
                </div>
                <br/>
                <div id='tabletemplatechange'></div>
                <br/>
                <ButtonTableDiv id='confirmtablechange'>
                    <StyleTableButton id='confirmtablenamechange'>Confirm</StyleTableButton>
                </ButtonTableDiv>
            </FormScroller>
        </div>
    );
}

export default ChangeTables;