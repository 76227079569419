window.addEventListener('load', () => {
    //main components
    document.getElementById('serveur').style.display = 'none';
    document.getElementById('basecontaineur').style.display = 'none';
    document.getElementById('tablescontainer').style.display = 'none';
    document.getElementById('taskscontainer').style.display = 'none';
    document.getElementById('queriescontainer').style.display = 'none';
    document.getElementById('documentcontainer').style.display = 'none';

    //sub-components
    document.getElementById('selecttable').style.display = 'none';
});