import React from 'react';
import '../../pouchdocumentation';

import { 
    TextContainer,
    TextTitle, 
    TextContent, 
    SubLink,  
} from '../../DocumentationStyles';

function WhyCouchbench() {
    return ( 
        <TextContainer id="whycouchbench">
            <TextTitle>3.Why Couchbench?</TextTitle>
            <TextContent>
                When designing this app, it was helpful to imagine some real stories of 
                how real (stereotypical) people would use it. We will look at three scenarios.
            </TextContent>
            <TextContent>
                <strong>Scenario 1: Don Mello (An experienced CouchDb and Fauxton user)</strong><br/>
                Don Mello is a web developer. He is the manager in a website development company that one 
                day decides to diversify its products by turning to mobile web applications because 
                the general manager saw just in time the importance of mobile phone users and what 
                this offers as business opportunities. The choice falls on the Progressive 
                Web Application (PWA) which offers flexibility in the management and maintenance of 
                caches and which can operate offline. Discovering that CouchDB and PouchDB allows 
                asynchronous operation using the web browser of each mobile device, 
                some prototype applications are developed and tested for its advantages and disadvantages.
            </TextContent>
            <TextContent>
                However, whenever Don Mello accesses the Fauxton interface to query the data, 
                he must each time write the search syntaxes for the particular and precise data. 
                As well as for the operations of create, read, update and delete (CRUD) which often 
                take time to find a precise insertion in order to operate there when a database would 
                contain several hundreds of thousands of rows of records. 
                He would have liked to use a control panel type interface or other integrated tools like in MySQL Workbench for all his database management operations.
            </TextContent>
            <TextContent>
                Don Mello discovers Couchbench which facilitates the aforementioned actions as if 
                he were opening his Visual Studio text editor and MySQL Workbench configured to connect 
                to his SQL or SQLITE databases, without creating any account and without issuing a username and password to access it. 
                Just an installation of the Couchbench application on the device of his choice and a 
                configuration of connection to its remote or local JSON format databases to operate 
                in complete peace of mind even outside working hours and days.
            </TextContent>
            <TextContent>
                <strong>Scenario 2: Sandra (An inexperienced CouchDb and Fauxton user)</strong><br/>
                Sandra is a website developer using HTML, CSS, PHP and MySQL stack as backend. 
                Operating as a Freelancer, she decides to take her steps in the world of Mobile PWA web 
                applications to open up new opportunities, although her many clients have asked her 
                several times to develop these types of applications for their needs. After several researches in this 
                area she discovers that MySQL does not operate asynchronously and that although it 
                would be possible using middleware and several code base modifications to adapt it, 
                this approach is not easy. CouchDB-PouchDB solves the problem but, she is unfamiliar 
                with the Fauxton interface and the JSON format is not well understood like PhPMyadmin 
                and SQL Workbench tools. A colleague from the freelancer network recommends Couchbench 
                which she installs to work easily when processing JSON data.
            </TextContent>
            <TextContent>
                <strong>Scenarion 3: Ahua (A random survey data processor)</strong><br/>
                Ahua is a graduate agent in marketing and advertising in a company. 
                Each times he is asked to conduct consumer surveys to assess the impact of upcoming 
                products and those already on the market. His tools for data processing and analysis are 
                outdated and often do not respond exactly to the expected and predictive results. 
                The software he uses often produces erroneous results, not only these are complex in 
                writing queries on the one hand but, inserting accurate data is a headache depending on 
                the wording of questions-answers and, the way of processing them. He discovers Couchbench 
                after some research on the web, installs CouchDB and runs Couchbench simultaneously to 
                facilitate his daily activities of investigation, manage the results to report them to his agency manager.
            </TextContent>
            <SubLink id='introconttechnical'>4.Technical Overview</SubLink>
            <br/><br/>
        </TextContainer>
    );
}

export default WhyCouchbench;