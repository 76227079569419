import React from 'react';
import '../pouchtasks';
import '../tasks.css';
import {  
    TextLocal , 
    FormScroller, 
} from '../../serveur/ServeurStyles';
//import {FormList} from '../TasksStyles';

function DocTasks() {
    return ( 
        <div id="doctasks">
            <br/>
            <FormScroller>
                <TextLocal>
                    <label id='textdocumenttask'>Select your document Task</label>
                </TextLocal>
                <br/>
                <div className='resultsList'>
                    <table className='thin_table_config'>
                        <thead>
                            <tr>
                                <th>Template</th>
                                <th>Document</th>
                            </tr>
                        </thead>
                        <tbody id='documentTaskTableBody'></tbody>
                    </table>
                    </div>   
            </FormScroller>
        </div>
    );
}

export default DocTasks;