import React from 'react';
import './pouchprincipal';
import { 
    PrincipalContainer, 
    MouseoverContainer, 
    TextServeur, 
    ScreenContainer,  
    ScreenBouton,  
    BoutonHomeScreen, 
    TextLocal, 
} from './PrincipalStyles';

function Principal() {
    return ( 
        <PrincipalContainer id='principal'>
            <MouseoverContainer id="my_div"></MouseoverContainer>
            <br/>
            <TextServeur>
                <label id='homepage'>Welcome to Couchbench</label>
            </TextServeur>
            <br/>
            <TextLocal>
                Please Select your tasks in the left sidebar
            </TextLocal>
            
            <br/><br/>
            <ScreenContainer>
                <ScreenBouton>
                    <BoutonHomeScreen id='startcouch'>The Couch Database Management Systems</BoutonHomeScreen>
                </ScreenBouton>
            </ScreenContainer>
            <br/>
        </PrincipalContainer>
    );
}

export default Principal;