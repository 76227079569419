import React from 'react';
import { HeaderContainer, ImageContainer, Img, TextContainer, ParametreContainer, DataContainer } from './HeaderStyles';
import Couch from '../../projectimage/couchdb-30.png';

function Header() {
    return ( 
        <HeaderContainer>
            <ImageContainer>
                <Img src={Couch} />
            </ImageContainer>
            <TextContainer>
                CouchDB Workbench
            </TextContainer>
            <ParametreContainer>
                <div style={{display:'none'}} id='device'>device</div>
                <div id='serveurheader'>Server</div>
                <DataContainer id='databaseheader'>Database</DataContainer>
                <DataContainer id='tableheader'>Table</DataContainer>
            </ParametreContainer>
        </HeaderContainer> 
    );
}

export default Header;