/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import PouchDB, { sync } from "pouchdb";
import PouchFind from "pouchdb-find";

window.addEventListener("load", () => {
  PouchDB.plugin(PouchFind);
  //local database test
  document.getElementById("localtasksserver").addEventListener("click", localtasksserverChange);
  async function localtasksserverChange(e) {
    e.preventDefault();

    var deviceStr = document.getElementById('device').innerText;

    var divClearDevice = document.getElementById('device');
    while(divClearDevice.hasChildNodes()) {
      divClearDevice.removeChild(divClearDevice.childNodes[0]);
    }

    if(deviceStr === ''){
      var deviceStr = document.getElementById('device');
      deviceStr.innerText = '';
      var tabletypeForm = document.getElementById('taskstextform');
      tabletypeForm.innerText = "Desktop Tasks Form";

      var texttableLocal = document.getElementById('texttaskslocal');
      texttableLocal.innerText = "Desktop Local Tasks"; 
    }

    if(deviceStr === 'mobile'){
      var deviceStr = document.getElementById('device');
      deviceStr.innerText = 'mobile';
      var tabletypeForm = document.getElementById('taskstextform');
      tabletypeForm.innerText = "Mobile Tasks Form";

      var texttableLocal = document.getElementById('texttaskslocal');
      texttableLocal.innerText = "Mobile Local Tasks"; 
    }

    document.getElementById("documenttasks").style.display = "block";
    document.getElementById("querydocumenttasks").style.display = "none";
    document.getElementById("crudtasksactions").style.display = "none";

    var serveurHeader = document.getElementById("serveurheader");
    serveurHeader.innerText = "local";
    serveurHeader.style.color = "red";
  }

  //remote database test
  document.getElementById("remotetasksserver").addEventListener("click", remotetasksserverChange);
  async function remotetasksserverChange(e) {
    e.preventDefault();

    var deviceStr = document.getElementById('device').innerText;

    if(deviceStr === ''){
      var deviceStr = document.getElementById('device');
      deviceStr.innerText = '';
      var tabletypeForm = document.getElementById('taskstextform');
      tabletypeForm.innerText = "Desktop Tasks Form";

      var texttableLocal = document.getElementById('texttaskslocal');
      texttableLocal.innerText = "Desktop Remote Tasks"; 
    }

    if(deviceStr === 'mobile'){
      var deviceStr = document.getElementById('device');
      deviceStr.innerText = 'mobile';
      var tabletypeForm = document.getElementById('taskstextform');
      tabletypeForm.innerText = "Mobile Tasks Form";

      var texttableLocal = document.getElementById('texttaskslocal');
      texttableLocal.innerText = "Mobile Remote Tasks"; 
    }

    document.getElementById("documenttasks").style.display = "block";
    document.getElementById("querydocumenttasks").style.display = "none";
    document.getElementById("crudtasksactions").style.display = "none";

    var serveurHeader = document.getElementById("serveurheader");
    serveurHeader.innerText = "local";
    serveurHeader.style.color = "red";
  }

  //new table test
  document.getElementById("newtask").addEventListener("click", newtaskChange);
  async function newtaskChange(e) {
    e.preventDefault();

    var deviceStr = document.getElementById('device').innerText;
    var serveurHeaderStr = document.getElementById('serveurheader').innerText;

    document.getElementById("documenttasks").style.display = "block";
    document.getElementById("querydocumenttasks").style.display = "block";
    document.getElementById("crudtasksactions").style.display = "block";
    document.getElementById("addtasks").style.display = "none";
    document.getElementById("edittasks").style.display = "none";
    document.getElementById("deletetasks").style.display = "none";
    //xxxxxxxxx crudbase xxxxxxxxx
    if((deviceStr === '' && serveurHeaderStr === 'local') || 
    (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
      document.getElementById("newtasks").style.display = "block";
      document.getElementById('desktasks').style.display = 'block';
      document.getElementById('mobiletasks').style.display = 'none';
      document.getElementById("createtasks").style.display = "none";
      document.getElementById("doctasks").style.display = "none";
      document.getElementById("listtasks").style.display = "none";
      document.getElementById("previoustasks").style.display = "none";
      document.getElementById("editdeletetasks").style.display = "none";
      document.getElementById("changedeledittasks").style.display = "none";
      document.getElementById("changetasks").style.display = "none";
      document.getElementById("viewtasks").style.display = "none";
      document.getElementById("listviewtasks").style.display = "none";
      document.getElementById("viewdisplaytasks").style.display = "none";
      document.getElementById("viewdoctasks").style.display = "none";
      document.getElementById("changeviewtasks").style.display = "none";
      //xxxxxxx input field
      document.getElementById("tasname").style.display = "block";
      document.getElementById("confirmertask").style.display = "block";
    };

    if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
      document.getElementById("newtasks").style.display = "block";
      document.getElementById('desktasks').style.display = 'none';
      document.getElementById('mobiletasks').style.display = 'block';
      document.getElementById("createtasks").style.display = "none";
      document.getElementById("doctasks").style.display = "none";
      document.getElementById("listtasks").style.display = "none";
      document.getElementById("previoustasks").style.display = "none";
      document.getElementById("editdeletetasks").style.display = "none";
      document.getElementById("changedeledittasks").style.display = "none";
      document.getElementById("changetasks").style.display = "none";
      document.getElementById("viewtasks").style.display = "none";
      document.getElementById("listviewtasks").style.display = "none";
      document.getElementById("viewdisplaytasks").style.display = "none";
      document.getElementById("viewdoctasks").style.display = "none";
      document.getElementById("changeviewtasks").style.display = "none";
      //xxxxxxx input field
      //document.getElementById("mobiletasname").style.display = "block";
      document.getElementById("mobileconfirmertask").style.display = "block";
    }
  }

  //

  //

  //Crud tasks (confirmertasks)
  document.getElementById("confirmtask").addEventListener("click", confirmtaskChange);
  async function confirmtaskChange(e) {
    e.preventDefault();

    //alert
    var elementOutId = "modaloutalerttasks";
    var elementDropId = "backdropoutalerttasks";
    var buttonHandler = "ouiHandlerAlertTaskBtn";

    var hostnametaskStr = document.getElementById("hostnametask").value;
    var hostporttaskStr = document.getElementById("hostporttask").value;
    var usernametaskStr = document.getElementById("usernametask").value;
    var passwordnametaskStr = document.getElementById("passwordnametask").value;
    var tasknameStr = document.getElementById("taskname").value.toLowerCase();
    var serveurHeaderStr = document.getElementById("serveurheader").innerText;
    var deviceStr = document.getElementById('device').innerText;
    var tabletemplateStr = "template";
    var localnamebaseStr = "";
    var servernameStr = "database";
    if (serveurHeaderStr === "local") {
      localnamebaseStr = "local";
    } else if (serveurHeaderStr === "remote") {
      localnamebaseStr = "remote";
    }
    var localtypebaseStr = "database";

    //check if values not null
    if (!hostnametaskStr) {
      var pTextehostname = "Enter Host Name";
      alertModal(elementOutId, elementDropId, pTextehostname, buttonHandler);
    } else if (!hostporttaskStr) {
      var pTexteportname = "Enter Port Number";
      alertModal(elementOutId, elementDropId, pTexteportname, buttonHandler);
    } else if (!usernametaskStr) {
      var pTexteusername = "Enter Username";
      alertModal(elementOutId, elementDropId, pTexteusername, buttonHandler);
    } else if (!passwordnametaskStr) {
      var pTextepasswordname = "Enter Password";
      alertModal(elementOutId,elementDropId,pTextepasswordname,buttonHandler);
    } else if (!tasknameStr) {
      var pTextedatabasename = "Enter Database Name";
      alertModal(elementOutId,elementDropId,pTextedatabasename,buttonHandler);
    } else {
      var docsCheckConfirm = [];
      //start main try catch
      try {
        //test if hostname and portnumber are true
        if((deviceStr === '' && localnamebaseStr === 'local') || 
        (deviceStr === '' && localnamebaseStr === 'remote') || (deviceStr === 'mobile' && localnamebaseStr === 'remote')){
          var url = "http://" + hostnametaskStr + ":" + hostporttaskStr + "/_utils/";
          var http_request = new XMLHttpRequest();
          http_request.open("GET", url, true);
          http_request.onreadystatechange = async function () {
            if (http_request.readyState === 4) {
              if (http_request.status === 200) {
                //search in local / remote database documents
                const db = new PouchDB(localnamebaseStr, { skip_setup: true });
                const remote = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/"+localnamebaseStr,{ skip_setup: true });
                PouchDB.plugin(PouchFind);
                // Initialise a sync with the remote server
                function sync() {
                  var opts = {live: true,retry: true,continuous: true,attachments: true,};
                  db.sync(remote, opts);
                }
                if (remote) {
                  sync();
                }

                let result = await db.createIndex({
                  index: {
                    fields: ["connectname", "servername"],
                  }
                });
                result = await db.find({
                  selector: {
                    connectname: tasknameStr,
                    servername: servernameStr,
                  }
                });
                docsCheckConfirm = result.docs;
                var resultatCheckConfirm = docsCheckConfirm.length;
                if (resultatCheckConfirm === 0) {
                  var divClearModal = document.getElementById("modalouttasks");
                  let divModal = document.createElement("div");
                  divModal.innerHTML = 
                  `<div class="modal">
                    <p>Sure this database exists?</p>
                    <button class="btn btn--alt" id="ouiHandlerExistBtn">Yes</button>
                    <button class="btn" id="nonHandlerExistsBtn">No</button>
                  </div>`;
                  divClearModal.appendChild(divModal);

                  var divClearBackdrop = document.getElementById("backdropouttasks");
                  let divBack = document.createElement("div");
                  divBack.innerHTML = `<div class="backdrop" id="backdropHandlerCloseExistsBtn"></div>`;
                  divClearBackdrop.appendChild(divBack);

                  //If Yes Clicked
                  document.getElementById("ouiHandlerExistBtn").addEventListener("click", ouiHandlerExistBtnChange);
                  async function ouiHandlerExistBtnChange(e) {
                    e.preventDefault();

                    //code here
                    var divClearModal = document.getElementById("modalouttasks");
                    let divModal = document.createElement("div");
                    divModal.innerHTML = 
                    `<div class="modal">
                      <p>Wish to Add it?</p>
                      <button class="btn btn--alt" id="ouiHandlerAddBtn">Yes</button>
                      <button class="btn" id="nonHandlerAddBtn">No</button>
                    </div>`;
                    divClearModal.appendChild(divModal);

                    var divClearBackdrop = document.getElementById("backdropouttasks");
                    let divBack = document.createElement("div");
                    divBack.innerHTML = `<div class="backdrop" id="backdropHandlerCloseAddBtn"></div>`;
                    divClearBackdrop.appendChild(divBack);

                    //If Yes Add Clicked
                    document.getElementById("ouiHandlerAddBtn").addEventListener("click", ouiHandlerAddBtnChange);
                    async function ouiHandlerAddBtnChange(e) {
                      e.preventDefault();

                      //code here
                      const db = new PouchDB(localnamebaseStr, {skip_setup: true,});
                      const remote = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +localnamebaseStr,{ skip_setup: true });
                      PouchDB.plugin(PouchFind);
                      //test if hostname and portnumber are true
                      var http_request = new XMLHttpRequest();
                      http_request.open("GET","http://" +hostnametaskStr +":" +hostporttaskStr +"/_utils/",true);
                      http_request.send("null");
                      http_request.onreadystatechange = async function () {
                        if (http_request.readyState === 4) {
                          if (http_request.status === 200) {
                            function sync() {var opts = {live: true,retry: true,continuous: true,attachments: true,};
                            db.sync(remote, opts);
                          }
                          if (remote) {
                            sync();
                          }
                          //end sync
                          var docsCheck = [];
                          try {
                            let result = await db.createIndex({
                              index: {
                                fields: [
                                  "hostname",
                                  "portname",
                                  "username",
                                  "passwordname",
                                ]
                              }
                            });
                            result = await db.find({
                              selector: {
                                hostname: hostnametaskStr,
                                portname: hostporttaskStr,
                                username: usernametaskStr,
                                passwordname: passwordnametaskStr,
                              }
                            });
                            docsCheck = result.docs;
                            var resultatCheck = docsCheck.length;
                            if (resultatCheck === "") {
                              var pTexteincorrectconnection = "Name or password is incorrect.";
                              alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
                            } else {
                              var docsCheckDatabase = [];
                              let resultDatabase = await db.createIndex({
                                index: {
                                  fields: ["connectname"],
                                },
                              });
                              resultDatabase = await db.find({
                                selector: {
                                  connectname: tasknameStr,
                                },
                              });
                              docsCheckDatabase = resultDatabase.docs;
                              var resultatCheckDatabase = docsCheckDatabase.length;
                              if (resultatCheckDatabase === 0) {
                                const dbNew = new PouchDB(tasknameStr);
                                const remoteNew = couchProvider(hostnametaskStr,hostporttaskStr,usernametaskStr,passwordnametaskStr,tasknameStr);
                                //add new database created to local connection server
                                //Create the document _id and _rev.
                                var now = Date.now();
                                //new database id
                                var id = tasknameStr.toLowerCase() + "_" + now;
                                // Add the document to the database.
                                var response = await db.put({
                                  _id: id,
                                  connectname: tasknameStr, //the new database name
                                  hostname: hostnametaskStr,
                                  portname: hostporttaskStr,
                                  username: usernametaskStr,
                                  passwordname: passwordnametaskStr,
                                  servername: localtypebaseStr,
                                });
                                // check if document inserted
                                var doc = await db.get(response.id);
                                if (doc !== "") {
                                  //Initialise a sync with the remote server
                                  function sync() {
                                    var opts = {
                                      live: true,
                                      retry: true,
                                      continuous: true,
                                      attachments: true,
                                    };
                                    dbNew.sync(remoteNew, opts);
                                  }
                                  if (remoteNew) {
                                    sync();
                                  }
                                  var pTextenewdatabase = "New Database added Succesfully";
                                  alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
                                } else {
                                  var pTexteerreuraddnew = "An Error has Occurred";
                                  alertModal(elementOutId,elementDropId,pTexteerreuraddnew,buttonHandler);
                                }
                              } else {
                                var pTexteexistaddnew = "Database " +tasknameStr +" already exists. Confirm again";
                                alertModal(elementOutId,elementDropId,pTexteexistaddnew,buttonHandler);
                              }
                            }
                          } catch (error) {
                            var pTexteerreurconnection = "An Error has Occurred";
                            alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                          }
                        } else {
                          var pTextefailedconnect = "Connection Failed.";
                          alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                        }
                      }
                    }; //
                    var divClearModal = document.getElementById("modalouttasks");
                    while (divClearModal.hasChildNodes()) {
                      divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById("backdropouttasks");
                    while (divClearBackdrop.hasChildNodes()) {
                      divClearBackdrop.removeChild(
                        divClearBackdrop.childNodes[0]
                      );
                    }
                  }

                  //If No Add Clicked
                  document.getElementById("nonHandlerAddBtn").addEventListener("click", nonHandlerAddBtnChange);
                  async function nonHandlerAddBtnChange(e) {
                    e.preventDefault();

                    //code here

                    var divClearModal = document.getElementById("modalouttasks");
                    while (divClearModal.hasChildNodes()) {
                      divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById("backdropouttasks");
                    while (divClearBackdrop.hasChildNodes()) {
                      divClearBackdrop.removeChild(
                        divClearBackdrop.childNodes[0]
                      );
                    }
                  }

                  //If backdrop Clicked
                  document.getElementById("backdropHandlerCloseAddBtn").addEventListener("click",backdropHandlerCloseAddBtnChange);
                  async function backdropHandlerCloseAddBtnChange(e) {
                    e.preventDefault();

                    //code here

                    var divClearModal = document.getElementById("modalouttasks");
                    while (divClearModal.hasChildNodes()) {
                      divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById("backdropouttasks");
                    while (divClearBackdrop.hasChildNodes()) {
                      divClearBackdrop.removeChild(
                        divClearBackdrop.childNodes[0]
                      );
                    }
                  }
                  //
                }

                //If No Clicked
                document.getElementById("nonHandlerExistsBtn").addEventListener("click", nonHandlerExistsBtnChange);
                async function nonHandlerExistsBtnChange(e) {
                  e.preventDefault();

                  //code here
                  var divClearModal = document.getElementById("modalouttasks");
                  let divModal = document.createElement("div");
                  divModal.innerHTML = `
                  <div class="modal">
                    <p>Wish to Use it?</p>
                    <button class="btn btn--alt" id="ouiHandlerUseBtn">Yes</button>
                    <button class="btn" id="nonHandlerUseBtn">No</button>
                  </div>`;
                  divClearModal.appendChild(divModal);

                  var divClearBackdrop = document.getElementById("backdropouttasks");
                  let divBack = document.createElement("div");
                  divBack.innerHTML = `<div class="backdrop" id="backdropHandlerCloseUseBtn"></div>`;
                  divClearBackdrop.appendChild(divBack);

                  //If Yes Use Clicked
                  document.getElementById("ouiHandlerUseBtn").addEventListener("click", ouiHandlerUseBtnChange);
                  async function ouiHandlerUseBtnChange(e) {
                    e.preventDefault();

                    //code here
                    const db = new PouchDB(localnamebaseStr, {skip_setup: true,});
                    const remote = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +localnamebaseStr,{ skip_setup: true });
                    PouchDB.plugin(PouchFind);
                    //test if hostname and portnumber are true
                    var http_request = new XMLHttpRequest();
                    http_request.open("GET", "http://" +hostnametaskStr +":" +hostporttaskStr +"/_utils/",true);
                    http_request.send("null");
                    http_request.onreadystatechange = async function () {
                      if (http_request.readyState === 4) {
                        if (http_request.status === 200) {
                          function sync() {
                            var opts = {
                              live: true,
                              retry: true,
                              continuous: true,
                              attachments: true,
                            };
                            db.sync(remote, opts);
                          }
                          if (remote) {
                            sync();
                          }
                          //end sync
                          var docsCheck = [];
                          try {
                            let result = await db.createIndex({
                              index: {
                                fields: [
                                  "hostname",
                                  "portname",
                                  "username",
                                  "passwordname",
                                ],
                              },
                            });
                            result = await db.find({
                              selector: {
                                hostname: hostnametaskStr,
                                portname: hostporttaskStr,
                                username: usernametaskStr,
                                passwordname: passwordnametaskStr,
                              },
                            });
                            docsCheck = result.docs;
                            var resultatCheck = docsCheck.length;
                            if (resultatCheck === "") {
                              var pTexteincorrectconnection = "Name or password is incorrect.";
                              alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
                            } else {
                              var docsCheckDatabase = [];
                              let resultDatabase = await db.createIndex({
                                index: {
                                  fields: ["connectname"],
                                },
                              });
                              resultDatabase = await db.find({
                                selector: {
                                  connectname: tasknameStr,
                                },
                              });
                              docsCheckDatabase = resultDatabase.docs;
                              var resultatCheckDatabase =
                                docsCheckDatabase.length;
                              if (resultatCheckDatabase === 0) {
                                const dbNew = new PouchDB(tasknameStr);
                                const remoteNew = couchProvider(
                                  hostnametaskStr,
                                  hostporttaskStr,
                                  usernametaskStr,
                                  passwordnametaskStr,
                                  tasknameStr
                                );
                                //add new database created to local connection server
                                //Create the document _id and _rev.
                                var now = Date.now();
                                //new database id
                                var id = tasknameStr.toLowerCase() + "_" + now;
                                // Add the document to the database.
                                var response = await db.put({
                                  _id: id,
                                  connectname: tasknameStr, //the new database name
                                  hostname: hostnametaskStr,
                                  portname: hostporttaskStr,
                                  username: usernametaskStr,
                                  passwordname: passwordnametaskStr,
                                  servername: localtypebaseStr,
                                });
                                // check if document inserted
                                var doc = await db.get(response.id);
                                if (doc !== "") {
                                  //Initialise a sync with the remote server
                                  function sync() {
                                    var opts = {
                                      live: true,
                                      retry: true,
                                      continuous: true,
                                      attachments: true,
                                    };
                                    dbNew.sync(remoteNew, opts);
                                  }
                                  if (remoteNew) {
                                    sync();
                                  }
                                  var pTextenewdatabase = "New Database added Succesfully";
                                  alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
                                } else {
                                  var pTexteerreuraddnew = "An Error has Occurred";
                                  alertModal(elementOutId,elementDropId,pTexteerreuraddnew,buttonHandler);
                                }
                              } else {
                                var pTexteexistaddnew = "Database " +tasknameStr +" already exists. Confirm again";
                                alertModal(elementOutId,elementDropId,pTexteexistaddnew,buttonHandler);
                              }
                            }
                          } catch (error) {
                            var pTexteerreurconnection = "An Error has Occurred";
                            alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                          }
                        } else {
                          var pTextefailedconnect = "Connection Failed.";
                          alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                        }
                      }
                    }; //

                    var divClearModal = document.getElementById("modalouttasks");
                    while (divClearModal.hasChildNodes()) {
                      divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById("backdropouttasks");
                    while (divClearBackdrop.hasChildNodes()) {
                      divClearBackdrop.removeChild(
                        divClearBackdrop.childNodes[0]
                      );
                    }
                  }

                  //If No Use Clicked
                  document.getElementById("nonHandlerUseBtn").addEventListener("click", nonHandlerUseBtnChange);
                  async function nonHandlerUseBtnChange(e) {
                    e.preventDefault();

                    //code here

                    var divClearModal = document.getElementById("modalouttasks");
                    while (divClearModal.hasChildNodes()) {
                      divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById("backdropouttasks");
                    while (divClearBackdrop.hasChildNodes()) {
                      divClearBackdrop.removeChild(
                        divClearBackdrop.childNodes[0]
                      );
                    }
                  }

                  //If backdrop Clicked
                  document.getElementById("backdropHandlerCloseUseBtn").addEventListener("click", backdropHandlerCloseUseBtnChange);
                  async function backdropHandlerCloseUseBtnChange(e) {
                    e.preventDefault();

                    //code here

                    var divClearModal = document.getElementById("modalouttasks");
                    while (divClearModal.hasChildNodes()) {
                      divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById("backdropouttasks");
                    while (divClearBackdrop.hasChildNodes()) {
                      divClearBackdrop.removeChild(
                        divClearBackdrop.childNodes[0]
                      );
                    }
                  }
                  //
                }

                //If backdrop Clicked
                document.getElementById("backdropHandlerCloseExistsBtn").addEventListener("click", backdropHandlerCloseExistsBtnChange);
                async function backdropHandlerCloseExistsBtnChange(e) {
                  e.preventDefault();

                  //code here

                  var divClearModal = document.getElementById("modalouttasks");
                  while (divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                  }

                  var divClearBackdrop = document.getElementById("backdropouttasks");
                  while (divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(
                      divClearBackdrop.childNodes[0]
                    );
                  }
                }
              } else {
                var docsCheckTable = [];
                var tableconnectStr = "table";
                try {
                  let resultTable = await db.createIndex({
                    index: {
                      fields: [
                        "tabledatabase",
                        "tabletemplate", //not use database name but template as table global name
                        "tableserver",
                        "tableconnect",
                      ],
                    },
                  });
                  resultTable = await db.find({
                    selector: {
                      tabledatabase: tasknameStr,
                      tabletemplate: tabletemplateStr, //not use database name but template as table global name
                      tableserver: localnamebaseStr,
                      tableconnect: tableconnectStr,
                    },
                  });
                  docsCheckTable = resultTable.docs;
                  var resultatCheckTable = docsCheckTable.length;
                  if (resultatCheckTable === 0) {
                    var pTextenotable = "No Table available";
                    alertModal(elementOutId,elementDropId,pTextenotable,buttonHandler);
                  } else {
                    document.getElementById("documenttasks").style.display = "block";
                    document.getElementById("querydocumenttasks").style.display = "block";
                    document.getElementById("crudtasksactions").style.display = "block";
                    document.getElementById("addtasks").style.display = "none";
                    document.getElementById("edittasks").style.display = "none";
                    document.getElementById("deletetasks").style.display = "none";
                    //xxxxxxxxx crudbase xxxxxxxxx
                    updateCreateTaskTableUIWithDocs(docsCheckTable);
                    document.getElementById("newtasks").style.display = "none";
                    document.getElementById("createtasks").style.display = "none";
                    document.getElementById("doctasks").style.display = "none";
                    document.getElementById("listtasks").style.display = "block";
                    document.getElementById("previoustasks").style.display = "none";
                    document.getElementById("editdeletetasks").style.display = "none";
                    document.getElementById("changedeledittasks").style.display = "none";
                    document.getElementById("changetasks").style.display = "none";
                    document.getElementById("viewtasks").style.display = "none";
                    document.getElementById("listviewtasks").style.display = "none";
                    document.getElementById("viewdisplaytasks").style.display = "none";
                    document.getElementById("viewdoctasks").style.display = "none";
                    document.getElementById("changeviewtasks").style.display = "none";
                    //xxxxxxx input field
                    document.getElementById("tasname").style.display = "block";
                    //document.getElementById('servertablename').style.display = 'none';
                    document.getElementById("confirmertask").style.display = "block";
                  }
                } catch (error) {
                  var pTexteerreurtable = "An Error has Occurred";
                  alertModal(elementOutId,elementDropId,pTexteerreurtable,buttonHandler);
                }
              } //
            } else {
              var pTexteincorrectconnection = "Incorrect Connection Parameters";
              alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
            }
          }
        };
        http_request.send();
      };//
        
      } catch (error) {
        var pTexteerreurconnection = "An Error has Occurred";
        alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
      }
    }
  }
  //Crud tasks (mobileconfirmertasks)
  document.getElementById("mobileconfirmtask").addEventListener("click", mobileconfirmtaskChange);
  async function mobileconfirmtaskChange(e){
    e.preventDefault();

    //alert
    var elementOutId = "modaloutalerttasks";
    var elementDropId = "backdropoutalerttasks";
    var buttonHandler = "ouiHandlerAlertTaskBtn";

    var mobileusernametaskStr = document.getElementById("mobileusernametask").value;
    var mobilepasswordnametaskStr = document.getElementById("mobilepasswordnametask").value;
    //var mobiletasknameStr = document.getElementById("mobiletaskname").value.toLowerCase();
    var serveurHeaderStr = document.getElementById("serveurheader").innerText;
    var deviceStr = document.getElementById('device').innerText;

    if (!mobileusernametaskStr) {
      var pTexteusername = "Enter Username";
      alertModal(elementOutId, elementDropId, pTexteusername, buttonHandler);
    } else if (!mobilepasswordnametaskStr) {
      var pTextepasswordname = "Enter Password";
      alertModal(elementOutId,elementDropId,pTextepasswordname,buttonHandler);
    } else{
      var docsCheckConfirm = [];
      try {
        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
          const db = new PouchDB(serveurHeaderStr, { skip_setup: true });
          PouchDB.plugin(PouchFind);

          let result = await db.createIndex({
            index: {
              fields: [
                //"tableconnect", 
                //"tabletemplate", 
                "servername"
              ],
            }
          });
          result = await db.find({
            selector: {
              //tableconnect: tableconnectStr,
              //tabletemplate: tabletemplateStr,
              servername: serveurHeaderStr
            }
          });
          docsCheckConfirm = result.docs;
          var resultatCheckConfirm = docsCheckConfirm.length;
          if (resultatCheckConfirm === 0){
            var pTextenotexistaddnew = "Database not exists. Create it first";
            alertModal(elementOutId,elementDropId,pTextenotexistaddnew,buttonHandler);
          }else{
            var docsCheckTable = [];
            var tableconnectStr = "table";
            var tabletemplateStr = "template";
            var tabledatabaseStr = serveurHeaderStr.toLocaleLowerCase();
            var tableserverStr = serveurHeaderStr.toLocaleLowerCase();
            try {
              let resultTable = await db.createIndex({
                index: {
                  fields: [
                    "tabledatabase",
                    "tabletemplate", 
                    "tableserver",
                    "tableconnect",
                  ],
                },
              });
              resultTable = await db.find({
                selector: {
                  tabledatabase: tabledatabaseStr,
                  tabletemplate: tabletemplateStr,
                  tableserver: tableserverStr,
                  tableconnect: tableconnectStr,
                },
              });
              docsCheckTable = resultTable.docs;
              var resultatCheckTable = docsCheckTable.length;
              if (resultatCheckTable === 0){
                var pTextenotable = "No Table available";
                alertModal(elementOutId,elementDropId,pTextenotable,buttonHandler);
              }else{
                document.getElementById("documenttasks").style.display = "block";
                document.getElementById("querydocumenttasks").style.display = "block";
                document.getElementById("crudtasksactions").style.display = "block";
                document.getElementById("addtasks").style.display = "none";
                document.getElementById("edittasks").style.display = "none";
                document.getElementById("deletetasks").style.display = "none";
                //xxxxxxxxx crudbase xxxxxxxxx
                updateCreateTaskTableUIWithDocs(docsCheckTable);
                document.getElementById("newtasks").style.display = "none";
                document.getElementById("createtasks").style.display = "none";
                document.getElementById("doctasks").style.display = "none";
                document.getElementById("listtasks").style.display = "block";
                document.getElementById("previoustasks").style.display = "none";
                document.getElementById("editdeletetasks").style.display = "none";
                document.getElementById("changedeledittasks").style.display = "none";
                document.getElementById("changetasks").style.display = "none";
                document.getElementById("viewtasks").style.display = "none";
                document.getElementById("listviewtasks").style.display = "none";
                document.getElementById("viewdisplaytasks").style.display = "none";
                document.getElementById("viewdoctasks").style.display = "none";
                document.getElementById("changeviewtasks").style.display = "none";
                //xxxxxxx input field
                //document.getElementById("tasname").style.display = "block";
                document.getElementById("confirmertask").style.display = "block";
              }
            } catch (error) {
              var pTexteerreurtable = "An Error has Occurred";
              alertModal(elementOutId,elementDropId,pTexteerreurtable,buttonHandler);
            }
          }
        }
      } catch (error) {
        var pTexteerreurconnection = "An Error has Occurred";
        alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
      }
    }
  }
  // Adds the document to the HTML First table.
  // If the document already exists in the table, it will be updated.
  function createTaskDocToToTable(doc, crudTaskTableBody) {
    // Each row of the table has an id attribute set to the doc._id field.
    var tr = crudTaskTableBody.querySelector(`#${doc._id}`);

    if (!tr) {
      tr = document.createElement("tr");
      tr.id = doc._id;
    }

    // Remove any existing table cells.
    while (tr.hasChildNodes()) {
      tr.removeChild(tr.childNodes[0]);
    }

    // Create new table cells for text fields.
    var tdTableServer = document.createElement("td");
    tdTableServer.innerText = doc.tableserver;
    //var tableNameStr = doc.tablename;
    tdTableServer.onclick = function () {
      var tableidStr = doc._id;
      var tabledatabaseStr = doc.tabledatabase;
      var tablenameStr = doc.tablename;
      var tableserverStr = doc.tableserver;
      var tablekeyStr = doc.tablekey;
      var tablevalueStr = doc.tablevalue;
      var tableconnectStr = doc.tableconnect;
      var nametablechangetext = document.getElementById("nametaskchange");
      nametablechangetext.value = tablenameStr;
      var fieldtablechangetext = document.getElementById("fieldtaskchange");
      fieldtablechangetext.value = tablekeyStr.length;
      var tableidchangetext = document.getElementById("taskidchange");
      tableidchangetext.value = tableidStr;
      var tabledatabasechangetext = document.getElementById("taskdatabasechange");
      tabledatabasechangetext.value = tabledatabaseStr;
      var tableserverchangetext = document.getElementById("taskserverchange");
      tableserverchangetext.value = tableserverStr;
      var tableconnectchangetext = document.getElementById("taskconnectchange");
      tableconnectchangetext.value = tableconnectStr;

      // get the reference for the body
      var body = document.getElementById("tasktemplatechange");
      // Remove any existing input field.
      while (body.hasChildNodes()) {
        body.removeChild(body.childNodes[0]);
      }

      //populate tablekey
      for (let i = 0; i < tablevalueStr.length; i++) {
        // creates a table row
        var input = document.createElement("input");
        input.type = "text";
        input.id = "column" + (i + 1);
        //input.placeholder = 'column '+(i+1);
        input.value = tablevalueStr[i];
        input.style.height = "35px";
        input.style.fontSize = "14px";
        input.setAttribute("readonly", true);
        body.appendChild(input);
        // Append a line break
        body.appendChild(document.createElement("br"));
      }

      document.getElementById("documenttasks").style.display = "block";
      document.getElementById("querydocumenttasks").style.display = "block";
      document.getElementById("crudtasksactions").style.display = "block";
      document.getElementById("addtasks").style.display = "none";
      document.getElementById("edittasks").style.display = "none";
      document.getElementById("deletetasks").style.display = "none";
      //xxxxxxxxx crudbase xxxxxxxxx
      document.getElementById("newtasks").style.display = "none";
      document.getElementById("createtasks").style.display = "none";
      document.getElementById("doctasks").style.display = "none";
      document.getElementById("listtasks").style.display = "none";
      document.getElementById("previoustasks").style.display = "none";
      document.getElementById("editdeletetasks").style.display = "none";
      document.getElementById("changedeledittasks").style.display = "none";
      document.getElementById("changetasks").style.display = "block";
      document.getElementById("viewtasks").style.display = "none";
      document.getElementById("listviewtasks").style.display = "none";
      document.getElementById("viewdisplaytasks").style.display = "none";
      document.getElementById("viewdoctasks").style.display = "none";
      document.getElementById("changeviewtasks").style.display = "none";
      //xxxxxxx input field
      document.getElementById("tasname").style.display = "none";
      document.getElementById("confirmertask").style.display = "none";
      //xxxxxxxxxxxxxx confirm and undo style
      document.getElementById("selecttasknamechange").style.display = "block";
      document.getElementById("undotasknamechange").style.display = "block";
    };
    tr.appendChild(tdTableServer);

    // Create new table cells for text fields.
    var tdTableDatabase = document.createElement("td");
    tdTableDatabase.innerText = doc.tabledatabase;
    //var tableNameStr = doc.tablename;
    tdTableDatabase.onclick = function () {
      var tableidStr = doc._id;
      var tabledatabaseStr = doc.tabledatabase;
      var tablenameStr = doc.tablename;
      var tableserverStr = doc.tableserver;
      var tablekeyStr = doc.tablekey;
      var tablevalueStr = doc.tablevalue;
      var tableconnectStr = doc.tableconnect;
      var nametablechangetext = document.getElementById("nametaskchange");
      nametablechangetext.value = tablenameStr;
      var fieldtablechangetext = document.getElementById("fieldtaskchange");
      fieldtablechangetext.value = tablekeyStr.length;
      var tableidchangetext = document.getElementById("taskidchange");
      tableidchangetext.value = tableidStr;
      var tabledatabasechangetext = document.getElementById("taskdatabasechange");
      tabledatabasechangetext.value = tabledatabaseStr;
      var tableserverchangetext = document.getElementById("taskserverchange");
      tableserverchangetext.value = tableserverStr;
      var tableconnectchangetext = document.getElementById("taskconnectchange");
      tableconnectchangetext.value = tableconnectStr;

      // get the reference for the body
      var body = document.getElementById("tasktemplatechange");
      // Remove any existing input field.
      while (body.hasChildNodes()) {
        body.removeChild(body.childNodes[0]);
      }

      //populate tablekey
      for (let i = 0; i < tablevalueStr.length; i++) {
        // creates a table row
        var input = document.createElement("input");
        input.type = "text";
        input.id = "column" + (i + 1);
        //input.placeholder = 'column '+(i+1);
        input.value = tablevalueStr[i];
        input.style.height = "35px";
        input.style.fontSize = "14px";
        input.setAttribute("readonly", true);
        body.appendChild(input);
        // Append a line break
        body.appendChild(document.createElement("br"));
      }

      document.getElementById("documenttasks").style.display = "block";
      document.getElementById("querydocumenttasks").style.display = "block";
      document.getElementById("crudtasksactions").style.display = "block";
      document.getElementById("addtasks").style.display = "none";
      document.getElementById("edittasks").style.display = "none";
      document.getElementById("deletetasks").style.display = "none";
      //xxxxxxxxx crudbase xxxxxxxxx
      document.getElementById("newtasks").style.display = "none";
      document.getElementById("createtasks").style.display = "none";
      document.getElementById("doctasks").style.display = "none";
      document.getElementById("listtasks").style.display = "none";
      document.getElementById("previoustasks").style.display = "none";
      document.getElementById("editdeletetasks").style.display = "none";
      document.getElementById("changedeledittasks").style.display = "none";
      document.getElementById("changetasks").style.display = "block";
      document.getElementById("viewtasks").style.display = "none";
      document.getElementById("listviewtasks").style.display = "none";
      document.getElementById("viewdisplaytasks").style.display = "none";
      document.getElementById("viewdoctasks").style.display = "none";
      document.getElementById("changeviewtasks").style.display = "none";
      //xxxxxxx input field
      document.getElementById("tasname").style.display = "none";
      document.getElementById("confirmertask").style.display = "none";
      //xxxxxxxxxxxxxx confirm and undo style
      document.getElementById("selecttasknamechange").style.display = "block";
      document.getElementById("undotasknamechange").style.display = "block";
    };
    tr.appendChild(tdTableDatabase);

    // Create new table cells for text fields.
    var tdTableName = document.createElement("td");
    tdTableName.innerText = doc.tablename;
    //var tdIdTableStr = doc.tablename;
    tdTableName.onclick = function () {
      var tableidStr = doc._id;
      var tabledatabaseStr = doc.tabledatabase;
      var tablenameStr = doc.tablename;
      var tableserverStr = doc.tableserver;
      var tablekeyStr = doc.tablekey;
      var tablevalueStr = doc.tablevalue;
      var tableconnectStr = doc.tableconnect;
      var nametablechangetext = document.getElementById("nametaskchange");
      nametablechangetext.value = tablenameStr;
      var fieldtablechangetext = document.getElementById("fieldtaskchange");
      fieldtablechangetext.value = tablekeyStr.length;
      var tableidchangetext = document.getElementById("taskidchange");
      tableidchangetext.value = tableidStr;
      var tabledatabasechangetext = document.getElementById("taskdatabasechange");
      tabledatabasechangetext.value = tabledatabaseStr;
      var tableserverchangetext = document.getElementById("taskserverchange");
      tableserverchangetext.value = tableserverStr;
      var tableconnectchangetext = document.getElementById("taskconnectchange");
      tableconnectchangetext.value = tableconnectStr;

      // get the reference for the body
      var body = document.getElementById("tasktemplatechange");
      // Remove any existing input field.
      while (body.hasChildNodes()) {
        body.removeChild(body.childNodes[0]);
      }

      //populate tablekey
      for (let i = 0; i < tablevalueStr.length; i++) {
        // creates a table row
        var input = document.createElement("input");
        input.type = "text";
        input.id = "column" + (i + 1);
        //input.placeholder = 'column '+(i+1);
        input.value = tablevalueStr[i];
        input.style.height = "35px";
        input.style.fontSize = "14px";
        input.setAttribute("readonly", true);
        body.appendChild(input);
        // Append a line break
        body.appendChild(document.createElement("br"));
      }

      document.getElementById("documenttasks").style.display = "block";
      document.getElementById("querydocumenttasks").style.display = "block";
      document.getElementById("crudtasksactions").style.display = "block";
      document.getElementById("addtasks").style.display = "none";
      document.getElementById("edittasks").style.display = "none";
      document.getElementById("deletetasks").style.display = "none";
      //xxxxxxxxx crudbase xxxxxxxxx
      document.getElementById("newtasks").style.display = "none";
      document.getElementById("createtasks").style.display = "none";
      document.getElementById("doctasks").style.display = "none";
      document.getElementById("listtasks").style.display = "none";
      document.getElementById("previoustasks").style.display = "none";
      document.getElementById("editdeletetasks").style.display = "none";
      document.getElementById("changedeledittasks").style.display = "none";
      document.getElementById("changetasks").style.display = "block";
      document.getElementById("viewtasks").style.display = "none";
      document.getElementById("listviewtasks").style.display = "none";
      document.getElementById("viewdisplaytasks").style.display = "none";
      document.getElementById("viewdoctasks").style.display = "none";
      document.getElementById("changeviewtasks").style.display = "none";
      //xxxxxxx input field
      document.getElementById("tasname").style.display = "none";
      document.getElementById("confirmertask").style.display = "none";
      //xxxxxxxxxxxxxx confirm and undo style
      document.getElementById("selecttasknamechange").style.display = "block";
      document.getElementById("undotasknamechange").style.display = "block";
    };
    tr.appendChild(tdTableName);

    // Add the row to the top of the table.
    crudTaskTableBody.insertBefore(tr, crudTaskTableBody.nextSibling);
  }
  /**Clear and update the UI to display the docs.*/
  function updateCreateTaskTableUIWithDocs(docs) {
    var crudTaskTableBody = document.getElementById("crudTaskTableBody");

    // Remove all child nodes from the table
    while (crudTaskTableBody.hasChildNodes()) {
      crudTaskTableBody.removeChild(crudTaskTableBody.childNodes[0]);
    }

    // Add the new documents to the div
    for (var i = 0; i < docs.length; i++) {
      var doc = docs[i];
      createTaskDocToToTable(doc, crudTaskTableBody);
    }
  }

  //Undo table template selected
  document.getElementById("undotasknamechange").addEventListener("click", undotasknamechangeChange);
  async function undotasknamechangeChange(e) {
    e.preventDefault();

    document.getElementById("documenttasks").style.display = "block";
    document.getElementById("querydocumenttasks").style.display = "block";
    document.getElementById("crudtasksactions").style.display = "block";
    document.getElementById("addtasks").style.display = "none";
    document.getElementById("edittasks").style.display = "none";
    document.getElementById("deletetasks").style.display = "none";
    //xxxxxxxxx crudbase xxxxxxxx
    document.getElementById("newtasks").style.display = "none";
    document.getElementById("createtasks").style.display = "none";
    document.getElementById("doctasks").style.display = "none";
    document.getElementById("listtasks").style.display = "block";
    document.getElementById("previoustasks").style.display = "none";
    document.getElementById("editdeletetasks").style.display = "none";
    document.getElementById("changedeledittasks").style.display = "none";
    document.getElementById("changetasks").style.display = "none";
    document.getElementById("viewtasks").style.display = "none";
    document.getElementById("listviewtasks").style.display = "none";
    document.getElementById("viewdisplaytasks").style.display = "none";
    document.getElementById("viewdoctasks").style.display = "none";
    document.getElementById("changeviewtasks").style.display = "none";
    //xxxxxxx input field
    document.getElementById("tasname").style.display = "block";
    //document.getElementById('servertablename').style.display = 'none';
    document.getElementById("confirmertask").style.display = "block";
  }

  //set and initialize a global array variable to retrieve table template value
  var tablevalueStr = [];
  //retreive the table input field dynamically to fill data in the table
  document.getElementById("selecttasknamechange").addEventListener("click", selecttasknamechangeChange);
  async function selecttasknamechangeChange(e) {
    e.preventDefault();

    //alert
    var elementOutId = "modaloutalerttasks";
    var elementDropId = "backdropoutalerttasks";
    var buttonHandler = "ouiHandlerAlertTasksBtn";

    var hostnametaskStr = document.getElementById("hostnametask").value;
    var hostporttaskStr = document.getElementById("hostporttask").value;
    var usernametaskStr = document.getElementById("usernametask").value;
    var passwordnametaskStr = document.getElementById("passwordnametask").value;
    //var tasknameStr = document.getElementById('taskname').value;
    var serveurHeaderStr = document.getElementById("serveurheader").innerText;
    var deviceStr = document.getElementById('device').innerText;
    //xxxxxxxxxxxxxxxxxxx
    var nametaskchangeStr = document.getElementById("nametaskchange").value.toLocaleLowerCase();
    //var fieldtaskchangeStr = document.getElementById('fieldtaskchange').value;
    var taskidchangeStr = document.getElementById("taskidchange").value;
    var taskdatabasechangeStr = document.getElementById("taskname").value.toLocaleLowerCase(); //from newtasks component
    var taskserverchangeStr = document.getElementById("taskserverchange").value;
    //var documentnumberStr = '1'; //retrieve all document with number = 1
    var taskconnectchangeStr = document.getElementById("taskconnectchange").value;
    //xxxxxxxxxxxxxx
    var localnamebaseStr = "";
    //var servernameStr = 'database';
    if (serveurHeaderStr === "local") {
      localnamebaseStr = "local";
    } else if (serveurHeaderStr === "remote") {
      localnamebaseStr = "remote";
    }

    //code here
    if((deviceStr === '' && localnamebaseStr === 'local') || 
    (deviceStr === '' && localnamebaseStr === 'remote') || (deviceStr === 'mobile' && localnamebaseStr === 'remote')){
      const db = new PouchDB(localnamebaseStr, { skip_setup: true });
      const remote = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +localnamebaseStr,{ skip_setup: true });
      PouchDB.plugin(PouchFind);
      //test if hostname and portnumber are true
      var http_request = new XMLHttpRequest();
      http_request.open("GET", "http://" + hostnametaskStr + ":" + hostporttaskStr + "/_utils/",true);
      http_request.onreadystatechange = async function () {
        if (http_request.readyState === 4) {
          if (http_request.status === 200) {
            function sync() {
              var opts = {
                live: true,
                retry: true,
                continuous: true,
                attachments: true,
              };
              db.sync(remote, opts);
            }
            if (remote) {
              sync();
            }
            //end sync
            var docsCheck = [];
            try {
              let result = await db.createIndex({
                index: {
                  fields: ["hostname", "portname", "username", "passwordname"],
                },
              });
              result = await db.find({
                selector: {
                  hostname: hostnametaskStr,
                  portname: hostporttaskStr,
                  username: usernametaskStr,
                  passwordname: passwordnametaskStr,
                },
              });
              docsCheck = result.docs;
              var resultatCheck = docsCheck.length;
              if (resultatCheck === "") {
                var pTexteincorrectconnection = "Name or password is incorrect.";
                alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
              } else {
                var docsCheckDatabase = [];
                let resultDatabase = await db.createIndex({
                  index: {
                    fields: ["_id"],
                  },
                });
                resultDatabase = await db.find({
                  selector: {
                    _id: taskidchangeStr,
                  },
                });
                docsCheckDatabase = resultDatabase.docs;
                var resultatCheckDatabase = docsCheckDatabase.length;
                if (resultatCheckDatabase === 0) {
                  var pTextenotable ="Table template " + nametaskchangeStr + " not available";
                  alertModal(elementOutId,elementDropId,pTextenotable,buttonHandler);
                } else {
                  //search in local / remote database documents
                  const dbbase = new PouchDB(taskdatabasechangeStr, {skip_setup: true,});
                  const remotebase = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +taskdatabasechangeStr,{ skip_setup: true });
                  PouchDB.plugin(PouchFind);
                  // Initialise a sync with the remote server
                  function sync() {
                    var opts = {
                      live: true,
                      retry: true,
                      continuous: true,
                      attachments: true,
                    };
                    dbbase.sync(remotebase, opts);
                  }
                  if (remotebase) {
                    sync();
                  }
                  var docsCheckDocument = [];
                  let result = await dbbase.createIndex({
                    index: {
                      fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "taskconnect",
                      ],
                    },
                  });
                  result = await dbbase.find({
                    selector: {
                      taskdatabase: taskdatabasechangeStr,
                      taskname: nametaskchangeStr,
                      taskserver: taskserverchangeStr,
                      taskconnect: taskconnectchangeStr,
                    },
                    limit: 1,
                  });
                  docsCheckDocument = result.docs;
                  var resultatCheckDocument = docsCheckDocument.length;
                  if (resultatCheckDocument === 0) {
                    // get the reference for the body
                    for (let i = 0; i < docsCheckDatabase.length; i++) {
                      tablevalueStr = docsCheckDatabase[i].tablevalue;
                    }
                    
                    // get the reference for the body
                    var divBody = document.getElementById("tasktemplatedocument");
                    // Remove any existing input field.
                    while(divBody.hasChildNodes()) {
                      divBody.removeChild(divBody.childNodes[0]);
                    }
                    //retrieve and populate label with tablavalue
                    for (let j = 0; j < tablevalueStr.length; j++) {
                      var divcontainer = document.createElement("div");
                      divcontainer.innerHTML = `
                      <form>
                        <div id="table_scroller">
                          <label id="label_table" type="text">${tablevalueStr[j]}</label>
                          &nbsp;&nbsp;
                          <input class="input_table" id="${"column" + (j + 1)}" type="text" />
                        </div>
                        <br/>
                      </form>`;
                      divBody.appendChild(divcontainer);
                    }
                    var fieldtaskdocumentStr = document.getElementById("fieldtaskdocument");
                    fieldtaskdocumentStr.value = "1";

                    document.getElementById("documenttasks").style.display = "block";
                    document.getElementById("querydocumenttasks").style.display = "block";
                    document.getElementById("crudtasksactions").style.display = "block";
                    document.getElementById("addtasks").style.display = "block";
                    document.getElementById("edittasks").style.display = "none";
                    document.getElementById("deletetasks").style.display = "none";
                    //xxxxxxxxx crudbase xxxxxxxxx
                    document.getElementById("newtasks").style.display = "none";
                    document.getElementById("createtasks").style.display = "block";
                    document.getElementById("doctasks").style.display = "none";
                    document.getElementById("listtasks").style.display = "none";
                    document.getElementById("previoustasks").style.display = "none";
                    document.getElementById("editdeletetasks").style.display = "none";
                    document.getElementById("changedeledittasks").style.display = "none";
                    document.getElementById("changetasks").style.display = "none";
                    document.getElementById("viewtasks").style.display = "none";
                    document.getElementById("listviewtasks").style.display = "none";
                    document.getElementById("viewdisplaytasks").style.display = "none";
                    document.getElementById("viewdoctasks").style.display = "none";
                    document.getElementById("changeviewtasks").style.display = "none";
                    //xxxxxxx input field
                    document.getElementById("tasname").style.display = "none";
                    document.getElementById("confirmertask").style.display = "none";
                    //xxxxxxxxxxxxxx confirm and undo style
                    document.getElementById("selecttasknamechange").style.display = "none";
                    document.getElementById("undotasknamechange").style.display = "none";
                  }else{
                    // get the reference for the body
                    for (let i = 0; i < docsCheckDatabase.length; i++) {
                      tablevalueStr = docsCheckDatabase[i].tablevalue;
                    }

                    document.getElementById("documenttasks").style.display = "block";
                    document.getElementById("querydocumenttasks").style.display = "block";
                    document.getElementById("crudtasksactions").style.display = "block";
                    document.getElementById("addtasks").style.display = "none";
                    document.getElementById("edittasks").style.display = "none";
                    document.getElementById("deletetasks").style.display = "none";
                    //xxxxxxxxx crudbase xxxxxxxxx
                    updateSelectDocumentTaskTableUIWithDocs(docsCheckDocument);
                    document.getElementById("newtasks").style.display = "none";
                    document.getElementById("createtasks").style.display = "none";
                    document.getElementById("doctasks").style.display = "block";
                    document.getElementById("listtasks").style.display = "none";
                    document.getElementById("previoustasks").style.display = "none";
                    document.getElementById("editdeletetasks").style.display = "none";
                    document.getElementById("changedeledittasks").style.display = "none";
                    document.getElementById("changetasks").style.display = "none";
                    document.getElementById("viewtasks").style.display = "none";
                    document.getElementById("listviewtasks").style.display = "none";
                    document.getElementById("viewdisplaytasks").style.display = "none";
                    document.getElementById("viewdoctasks").style.display = "none";
                    document.getElementById("changeviewtasks").style.display = "none";
                    //xxxxxxx input field
                    document.getElementById("tasname").style.display = "none";
                    document.getElementById("confirmertask").style.display = "none";
                    //xxxxxxxxxxxxxx confirm and undo style
                    document.getElementById("selecttasknamechange").style.display = "none";
                    document.getElementById("undotasknamechange").style.display = "none";
                  }
                }
              }
            } catch (error) {
              var pTexteerreurconnection = "An Error has Occurred";
              alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
          } else {
            var pTextefailedconnect = "Connection Failed.";
            alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
          }
        }
      };
      http_request.send();////
    };

    if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
      const db = new PouchDB(serveurHeaderStr, { skip_setup: true });
      PouchDB.plugin(PouchFind);
      var docsCheck = [];
      try {
        let result = await db.createIndex({
          index: {
            fields: [
              'servername'
            ]
          },
        });
        result = await db.find({
          selector: {
            servername: serveurHeaderStr
          }
        });
        docsCheck = result.docs;
        var resultatCheck = docsCheck.length;
        if (resultatCheck === 0){
          var pTextenotexistaddnew = "Database not exists. Create it first";
          alertModal(elementOutId,elementDropId,pTextenotexistaddnew,buttonHandler);
        }else{
          var docsCheckDatabase = [];
          let resultDatabase = await db.createIndex({
            index: {
              fields: ["_id"],
            },
          });
          resultDatabase = await db.find({
            selector: {
              _id: taskidchangeStr,
            },
          });
          docsCheckDatabase = resultDatabase.docs;
          var resultatCheckDatabase = docsCheckDatabase.length;
          if (resultatCheckDatabase === 0){
            var pTextenotable ="Table template " + nametaskchangeStr + " not available";
            alertModal(elementOutId,elementDropId,pTextenotable,buttonHandler);
          }else{
            const dbbase = new PouchDB(serveurHeaderStr, {skip_setup: true,});
            PouchDB.plugin(PouchFind);
            var docsCheckDocument = [];
            let result = await dbbase.createIndex({
              index: {
                fields: [
                  "taskdatabase",
                  "taskname",
                  "taskserver",
                  "taskconnect",
                ],
              },
            });
            result = await dbbase.find({
              selector: {
                taskdatabase: serveurHeaderStr,
                taskname: nametaskchangeStr,
                taskserver: taskserverchangeStr,
                taskconnect: taskconnectchangeStr,
              },
              limit: 1,
            });
            docsCheckDocument = result.docs;
            var resultatCheckDocument = docsCheckDocument.length;
            if(resultatCheckDocument === 0){
              // get the reference for the body
              for (let i = 0; i < docsCheckDatabase.length; i++) {
                tablevalueStr = docsCheckDatabase[i].tablevalue;
              }
            
              // get the reference for the body
              var divBody = document.getElementById("tasktemplatedocument");
              // Remove any existing input field.
              while(divBody.hasChildNodes()) {
                divBody.removeChild(divBody.childNodes[0]);
              }
              //retrieve and populate label with tablavalue
              for (let j = 0; j < tablevalueStr.length; j++) {
                var divcontainer = document.createElement("div");
                divcontainer.innerHTML = `
                <form>
                  <div id="table_scroller">
                    <label id="label_table" type="text">${tablevalueStr[j]}</label>
                    &nbsp;&nbsp;
                    <input class="input_table" id="${"column" + (j + 1)}" type="text" />
                  </div>
                  <br/>
                </form>`;
                divBody.appendChild(divcontainer);
              }
              var fieldtaskdocumentStr = document.getElementById("fieldtaskdocument");
              fieldtaskdocumentStr.value = "1";

              document.getElementById("documenttasks").style.display = "block";
              document.getElementById("querydocumenttasks").style.display = "block";
              document.getElementById("crudtasksactions").style.display = "block";
              document.getElementById("addtasks").style.display = "block";
              document.getElementById("edittasks").style.display = "none";
              document.getElementById("deletetasks").style.display = "none";
              //xxxxxxxxx crudbase xxxxxxxxx
              document.getElementById("newtasks").style.display = "none";
              document.getElementById("createtasks").style.display = "block";
              document.getElementById("doctasks").style.display = "none";
              document.getElementById("listtasks").style.display = "none";
              document.getElementById("previoustasks").style.display = "none";
              document.getElementById("editdeletetasks").style.display = "none";
              document.getElementById("changedeledittasks").style.display = "none";
              document.getElementById("changetasks").style.display = "none";
              document.getElementById("viewtasks").style.display = "none";
              document.getElementById("listviewtasks").style.display = "none";
              document.getElementById("viewdisplaytasks").style.display = "none";
              document.getElementById("viewdoctasks").style.display = "none";
              document.getElementById("changeviewtasks").style.display = "none";
              //xxxxxxx input field
              document.getElementById("tasname").style.display = "none";
              document.getElementById("confirmertask").style.display = "none";
              //xxxxxxxxxxxxxx confirm and undo style
              document.getElementById("selecttasknamechange").style.display = "none";
              document.getElementById("undotasknamechange").style.display = "none";
            }else{
              // get the reference for the body
              for (let i = 0; i < docsCheckDatabase.length; i++) {
                tablevalueStr = docsCheckDatabase[i].tablevalue;
              }

              document.getElementById("documenttasks").style.display = "block";
              document.getElementById("querydocumenttasks").style.display = "block";
              document.getElementById("crudtasksactions").style.display = "block";
              document.getElementById("addtasks").style.display = "none";
              document.getElementById("edittasks").style.display = "none";
              document.getElementById("deletetasks").style.display = "none";
              //xxxxxxxxx crudbase xxxxxxxxx
              updateSelectDocumentTaskTableUIWithDocs(docsCheckDocument);
              document.getElementById("newtasks").style.display = "none";
              document.getElementById("createtasks").style.display = "none";
              document.getElementById("doctasks").style.display = "block";
              document.getElementById("listtasks").style.display = "none";
              document.getElementById("previoustasks").style.display = "none";
              document.getElementById("editdeletetasks").style.display = "none";
              document.getElementById("changedeledittasks").style.display = "none";
              document.getElementById("changetasks").style.display = "none";
              document.getElementById("viewtasks").style.display = "none";
              document.getElementById("listviewtasks").style.display = "none";
              document.getElementById("viewdisplaytasks").style.display = "none";
              document.getElementById("viewdoctasks").style.display = "none";
              document.getElementById("changeviewtasks").style.display = "none";
              //xxxxxxx input field
              document.getElementById("tasname").style.display = "none";
              document.getElementById("confirmertask").style.display = "none";
              //xxxxxxxxxxxxxx confirm and undo style
              document.getElementById("selecttasknamechange").style.display = "none";
              document.getElementById("undotasknamechange").style.display = "none";
            }
          }
        }
      } catch (error) {
        var pTexteerreurconnection = "An Error has Occurred";
        alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
      }
    }
  }
  // Adds the document to the HTML First table.
  // If the document already exists in the table, it will be updated.
  function selectTaskDocToToTable(doc, documentTaskTableBody) {
    // Each row of the table has an id attribute set to the doc._id field.
    var tr = documentTaskTableBody.querySelector(`#${doc._id}`);

    var deviceStr = document.getElementById('device').innerText;
    var serveurHeaderStr = document.getElementById('serveurheader').innerText;

    if (!tr) {
      tr = document.createElement("tr");
      tr.id = doc._id;
    }

    // Remove any existing table cells.
    while (tr.hasChildNodes()) {
      tr.removeChild(tr.childNodes[0]);
    }

    //alert
    var elementOutId = "modaloutalerttasks";
    var elementDropId = "backdropoutalerttasks";
    var buttonHandler = "ouiHandlerAlertTasksBtn";

    if((deviceStr === '' && serveurHeaderStr === 'local') || 
    (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
      // Create new table cells for text fields.
      var tdTemplate = document.createElement("td");
      tdTemplate.innerText = doc.taskname;
      //var tableNameStr = doc.tablename;
      tdTemplate.onclick = async function () {
        var taskdatabaseStr = doc.taskdatabase;
        var tasknameStr = doc.taskname;
        var taskserverStr = doc.taskserver;
        var documentnameStr = doc.documentname;
        var taskconnectStr = doc.taskconnect;
        var tablekeyvalue = tablevalueStr;
        //xxxxxxxxxxxxxxxxxxx
        var hostnametaskStr = document.getElementById("hostnametask").value;
        var hostporttaskStr = document.getElementById("hostporttask").value;
        var usernametaskStr = document.getElementById("usernametask").value;
        var passwordnametaskStr = document.getElementById("passwordnametask").value;

        //search in local / remote database documents
        const dbbase = new PouchDB(taskdatabaseStr, { skip_setup: true });
        const remotebase = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +taskdatabaseStr,{ skip_setup: true });
        PouchDB.plugin(PouchFind);
        // Initialise a sync with the remote server
        function sync() {
          var opts = {
            live: true,
            retry: true,
            continuous: true,
            attachments: true,
          };
          dbbase.sync(remotebase, opts);
        }
        if (remotebase) {
          sync();
        }
        var docsCheckDocNumber = [];
        try {
          let result = await dbbase.createIndex({
            index: {
              fields: [
                "taskdatabase",
                "taskname",
                "documentname",
                "taskserver",
                "taskconnect",
              ],
            },
          });
          result = await dbbase.find({
            selector: {
              taskdatabase: taskdatabaseStr,
              taskname: tasknameStr,
              documentname: documentnameStr,
              taskserver: taskserverStr,
              taskconnect: taskconnectStr,
            },
          });
          docsCheckDocNumber = result.docs;
          for (let i = 0; i < 1; i++) {
            tablevalueStr = docsCheckDocNumber[i].taskdata;
          }
          // get the reference for the body
          var body = document.getElementById("tasktemplatedocument");
          // Remove any existing input field.
          while (body.hasChildNodes()) {
            body.removeChild(body.childNodes[0]);
          }

          //get json array column
          const tableData = [tablevalueStr];
          const column = Object.keys(tableData[0]);
        
          // get table heading data
          const columnData = ()=>{
            return column.map((data)=>{
              return data
            });
          };
          var columnDonnee = columnData();

          //retrieve and populate label with tablavalue
          for (let j = 0; j < column.length; j++) {
            var divcontainer = document.createElement("div");
            divcontainer.innerHTML = `
            <form>
              <div id="table_scroller">
                <label id="${"label" + (j + 1)}" type="text">${tablekeyvalue[j]}</label>
                &nbsp;&nbsp;
                <input class="input_table" id="${"column" + (j + 1)}" type="text" />
              </div>
              <br/>
            </form>`;
            body.appendChild(divcontainer);
          }
          var docNumber = 0;
          for (let k = 0; k < docsCheckDocNumber.length; k++) {
            docNumber = docsCheckDocNumber[k].documentnumber;
          }
          //docNumber = docsCheckDocNumber.length+1;
          var nametaskdocumentStr = document.getElementById("nametaskdocument");
          nametaskdocumentStr.value = documentnameStr;
          var fieldtaskdocumentStr = document.getElementById("fieldtaskdocument");
          fieldtaskdocumentStr.value = parseFloat(docNumber) + 1;
          //xxxxxxxxxx
          document.getElementById("documenttasks").style.display = "block";
          document.getElementById("querydocumenttasks").style.display = "block";
          document.getElementById("crudtasksactions").style.display = "block";
          document.getElementById("addtasks").style.display = "block";
          document.getElementById("edittasks").style.display = "none";
          document.getElementById("deletetasks").style.display = "none";
          //xxxxxxxxx crudbase xxxxxxxxx
          document.getElementById("newtasks").style.display = "none";
          document.getElementById("createtasks").style.display = "block";
          document.getElementById("doctasks").style.display = "none";
          document.getElementById("listtasks").style.display = "none";
          document.getElementById("previoustasks").style.display = "none";
          document.getElementById("editdeletetasks").style.display = "none";
          document.getElementById("changedeledittasks").style.display = "none";
          document.getElementById("changetasks").style.display = "none";
          document.getElementById("viewtasks").style.display = "none";
          document.getElementById("listviewtasks").style.display = "none";
          document.getElementById("viewdisplaytasks").style.display = "none";
          document.getElementById("viewdoctasks").style.display = "none";
          document.getElementById("changeviewtasks").style.display = "none";
          //xxxxxxx input field
          document.getElementById("tasname").style.display = "none";
          document.getElementById("confirmertask").style.display = "none";
          //xxxxxxxxxxxxxx confirm and undo style
          document.getElementById("selecttasknamechange").style.display = "none";
          document.getElementById("undotasknamechange").style.display = "none";
        } catch (error) {
          var pTexteerreurconnection = "An Error has Occurred";
          alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
        }
      };
      tr.appendChild(tdTemplate);///

      // Create new table cells for text fields.
      var tdDocument = document.createElement("td");
      tdDocument.innerText = doc.documentname;
      //var tableNameStr = doc.tablename;
      tdDocument.onclick = async function () {
        var taskdatabaseStr = doc.taskdatabase;
        var tasknameStr = doc.taskname;
        var taskserverStr = doc.taskserver;
        var documentnameStr = doc.documentname;
        var taskconnectStr = doc.taskconnect;
        var tablekeyvalue = tablevalueStr;
        //xxxxxxxxxxxxxxxxxxx
        var hostnametaskStr = document.getElementById("hostnametask").value;
        var hostporttaskStr = document.getElementById("hostporttask").value;
        var usernametaskStr = document.getElementById("usernametask").value;
        var passwordnametaskStr = document.getElementById("passwordnametask").value;

        //search in local / remote database documents
        const dbbase = new PouchDB(taskdatabaseStr, { skip_setup: true });
        const remotebase = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +taskdatabaseStr,{ skip_setup: true });
        PouchDB.plugin(PouchFind);
        // Initialise a sync with the remote server
        function sync() {
          var opts = {
            live: true,
            retry: true,
            continuous: true,
            attachments: true,
          };
          dbbase.sync(remotebase, opts);
        }
        if (remotebase) {
          sync();
        }
        var docsCheckDocNumber = [];
        try {
          let result = await dbbase.createIndex({
            index: {
              fields: [
                "taskdatabase",
                "taskname",
                "documentname",
                "taskserver",
                "taskconnect",
              ],
            },
          });
          result = await dbbase.find({
            selector: {
              taskdatabase: taskdatabaseStr,
              taskname: tasknameStr,
              documentname: documentnameStr,
              taskserver: taskserverStr,
              taskconnect: taskconnectStr,
            },
          });
          docsCheckDocNumber = result.docs;
          for (let i = 0; i < 1; i++) {
            tablevalueStr = docsCheckDocNumber[i].taskdata;
          }
          // get the reference for the body
          var body = document.getElementById("tasktemplatedocument");
          // Remove any existing input field.
          while (body.hasChildNodes()) {
            body.removeChild(body.childNodes[0]);
          }
        
          //get json array column
          const tableData = [tablevalueStr];
          const column = Object.keys(tableData[0]);
        
          // get table heading data
          const columnData = ()=>{
            return column.map((data)=>{
              return data
            });
          };
          var columnDonnee = columnData();

          //retrieve and populate label with tablavalue
          for (let j = 0; j < column.length; j++) {
            var divcontainer = document.createElement("div");
            divcontainer.innerHTML = `
            <form>
              <div id="table_scroller">
                <label id="${"label" + (j + 1)}" type="text">${tablekeyvalue[j]}</label>
                &nbsp;&nbsp;
                <input class="input_table" id="${"column" + (j + 1)}" type="text" />
              </div>
              <br/>
            </form>`;
            body.appendChild(divcontainer);
          }

          var docNumber = 0;
          docNumber = docsCheckDocNumber.length + 1;
          var nametaskdocumentStr = document.getElementById("nametaskdocument");
          nametaskdocumentStr.value = documentnameStr;
          var fieldtaskdocumentStr = document.getElementById("fieldtaskdocument");
          fieldtaskdocumentStr.value = docNumber;
          //xxxxxxxxxx
          document.getElementById("documenttasks").style.display = "block";
          document.getElementById("querydocumenttasks").style.display = "block";
          document.getElementById("crudtasksactions").style.display = "block";
          document.getElementById("addtasks").style.display = "block";
          document.getElementById("edittasks").style.display = "none";
          document.getElementById("deletetasks").style.display = "none";
          //xxxxxxxxx crudbase xxxxxxxxx
          document.getElementById("newtasks").style.display = "none";
          document.getElementById("createtasks").style.display = "block";
          document.getElementById("doctasks").style.display = "none";
          document.getElementById("listtasks").style.display = "none";
          document.getElementById("previoustasks").style.display = "none";
          document.getElementById("editdeletetasks").style.display = "none";
          document.getElementById("changedeledittasks").style.display = "none";
          document.getElementById("changetasks").style.display = "none";
          document.getElementById("viewtasks").style.display = "none";
          document.getElementById("listviewtasks").style.display = "none";
          document.getElementById("viewdisplaytasks").style.display = "none";
          document.getElementById("viewdoctasks").style.display = "none";
          document.getElementById("changeviewtasks").style.display = "none";
          //xxxxxxx input field
          document.getElementById("tasname").style.display = "none";
          document.getElementById("confirmertask").style.display = "none";
          //xxxxxxxxxxxxxx confirm and undo style
          document.getElementById("selecttasknamechange").style.display = "none";
          document.getElementById("undotasknamechange").style.display = "none";
        } catch (error) {
          var pTexteerreurconnection = "An Error has Occurred";
          alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
        }
      };
      tr.appendChild(tdDocument);
    };

    if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
      // Create new table cells for text fields.
      var tdTemplate = document.createElement("td");
      tdTemplate.innerText = doc.taskname;
      tdTemplate.onclick = async function (){
        var taskdatabaseStr = doc.taskdatabase;
        var tasknameStr = doc.taskname;
        var taskserverStr = doc.taskserver;
        var documentnameStr = doc.documentname;
        var taskconnectStr = doc.taskconnect;
        var tablekeyvalue = tablevalueStr;
        
        //xxxxxxxxxxxxxxxxxxx
        //search in local / remote database documents
        const dbbase = new PouchDB(taskdatabaseStr, { skip_setup: true });
        PouchDB.plugin(PouchFind);
        var docsCheckDocNumber = [];
        try {
          let result = await dbbase.createIndex({
            index: {
              fields: [
                "taskdatabase",
                "taskname",
                "documentname",
                "taskserver",
                "taskconnect",
              ],
            },
          });
          result = await dbbase.find({
            selector: {
              taskdatabase: taskdatabaseStr,
              taskname: tasknameStr,
              documentname: documentnameStr,
              taskserver: taskserverStr,
              taskconnect: taskconnectStr,
            },
          });
          docsCheckDocNumber = result.docs;
          //console.log(docsCheckDocNumber)
          for (let i = 0; i < 1; i++) {
            tablevalueStr = docsCheckDocNumber[i].taskdata;
          }
          // get the reference for the body
          var body = document.getElementById("tasktemplatedocument");
          // Remove any existing input field.
          while (body.hasChildNodes()) {
            body.removeChild(body.childNodes[0]);
          }

          //get json array column
          const tableData = [tablevalueStr];
          const column = Object.keys(tableData[0]);
          //console.log(tableData)
          //console.log(column)
          // get table heading data
          const columnData = ()=>{
            return column.map((data)=>{
              return data
            });
          };
          var columnDonnee = columnData();
          //console.log(columnDonnee)
          //retrieve and populate label with tablavalue
          for (let j = 0; j < column.length; j++) {
            var divcontainer = document.createElement("div");
            divcontainer.innerHTML = `
            <form>
              <div id="table_scroller">
                <label id="${"label" + (j + 1)}" type="text">${tablekeyvalue[j]}</label>
                &nbsp;&nbsp;
                <input class="input_table" id="${"column" + (j + 1)}" type="text" />
              </div>
              <br/>
            </form>`;
            body.appendChild(divcontainer);
          }
          var docNumber = 0;
          for(let k = 0; k < docsCheckDocNumber.length; k++){
            docNumber = docsCheckDocNumber[k].documentnumber;
          }
          var nametaskdocumentStr = document.getElementById("nametaskdocument");
          nametaskdocumentStr.value = documentnameStr;
          var fieldtaskdocumentStr = document.getElementById("fieldtaskdocument");
          fieldtaskdocumentStr.value = parseFloat(docNumber) + 1;
          //xxxxxxxxxx
          document.getElementById("documenttasks").style.display = "block";
          document.getElementById("querydocumenttasks").style.display = "block";
          document.getElementById("crudtasksactions").style.display = "block";
          document.getElementById("addtasks").style.display = "block";
          document.getElementById("edittasks").style.display = "none";
          document.getElementById("deletetasks").style.display = "none";
          //xxxxxxxxx crudbase xxxxxxxxx
          document.getElementById("newtasks").style.display = "none";
          document.getElementById("createtasks").style.display = "block";
          document.getElementById("doctasks").style.display = "none";
          document.getElementById("listtasks").style.display = "none";
          document.getElementById("previoustasks").style.display = "none";
          document.getElementById("editdeletetasks").style.display = "none";
          document.getElementById("changedeledittasks").style.display = "none";
          document.getElementById("changetasks").style.display = "none";
          document.getElementById("viewtasks").style.display = "none";
          document.getElementById("listviewtasks").style.display = "none";
          document.getElementById("viewdisplaytasks").style.display = "none";
          document.getElementById("viewdoctasks").style.display = "none";
          document.getElementById("changeviewtasks").style.display = "none";
          //xxxxxxx input field
          document.getElementById("tasname").style.display = "none";
          document.getElementById("confirmertask").style.display = "none";
          //xxxxxxxxxxxxxx confirm and undo style
          document.getElementById("selecttasknamechange").style.display = "none";
          document.getElementById("undotasknamechange").style.display = "none";
        } catch (error) {
          var pTexteerreurconnection = "An Error has Occurred";
          alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
        }
      };
      tr.appendChild(tdTemplate);

      // Create new table cells for text fields.
      var tdDocument = document.createElement("td");
      tdDocument.innerText = doc.documentname;
      tdDocument.onclick = async function (){
        var taskdatabaseStr = doc.taskdatabase;
        var tasknameStr = doc.taskname;
        var taskserverStr = doc.taskserver;
        var documentnameStr = doc.documentname;
        var taskconnectStr = doc.taskconnect;
        var tablekeyvalue = tablevalueStr;
        
        //xxxxxxxxxxxxxxxxxxx
        //search in local / remote database documents
        const dbbase = new PouchDB(taskdatabaseStr, { skip_setup: true });
        PouchDB.plugin(PouchFind);
        var docsCheckDocNumber = [];
        try {
          let result = await dbbase.createIndex({
            index: {
              fields: [
                "taskdatabase",
                "taskname",
                "documentname",
                "taskserver",
                "taskconnect",
              ],
            },
          });
          result = await dbbase.find({
            selector: {
              taskdatabase: taskdatabaseStr,
              taskname: tasknameStr,
              documentname: documentnameStr,
              taskserver: taskserverStr,
              taskconnect: taskconnectStr,
            },
          });
          docsCheckDocNumber = result.docs;
          for (let i = 0; i < 1; i++) {
            tablevalueStr = docsCheckDocNumber[i].taskdata;
          }
          // get the reference for the body
          var body = document.getElementById("tasktemplatedocument");
          // Remove any existing input field.
          while (body.hasChildNodes()) {
            body.removeChild(body.childNodes[0]);
          }

          //get json array column
          const tableData = [tablevalueStr];
          const column = Object.keys(tableData[0]);
          // get table heading data
          const columnData = ()=>{
            return column.map((data)=>{
              return data
            });
          };
          var columnDonnee = columnData();
          //retrieve and populate label with tablavalue
          for (let j = 0; j < column.length; j++) {
            var divcontainer = document.createElement("div");
            divcontainer.innerHTML = `
            <form>
              <div id="table_scroller">
                <label id="${"label" + (j + 1)}" type="text">${tablekeyvalue[j]}</label>
                &nbsp;&nbsp;
                <input class="input_table" id="${"column" + (j + 1)}" type="text" />
              </div>
              <br/>
            </form>`;
            body.appendChild(divcontainer);
          }
          var docNumber = 0;
          for(let k = 0; k < docsCheckDocNumber.length; k++){
            docNumber = docsCheckDocNumber[k].documentnumber;
          }
          var nametaskdocumentStr = document.getElementById("nametaskdocument");
          nametaskdocumentStr.value = documentnameStr;
          var fieldtaskdocumentStr = document.getElementById("fieldtaskdocument");
          fieldtaskdocumentStr.value = parseFloat(docNumber) + 1;
          //xxxxxxxxxx
          document.getElementById("documenttasks").style.display = "block";
          document.getElementById("querydocumenttasks").style.display = "block";
          document.getElementById("crudtasksactions").style.display = "block";
          document.getElementById("addtasks").style.display = "block";
          document.getElementById("edittasks").style.display = "none";
          document.getElementById("deletetasks").style.display = "none";
          //xxxxxxxxx crudbase xxxxxxxxx
          document.getElementById("newtasks").style.display = "none";
          document.getElementById("createtasks").style.display = "block";
          document.getElementById("doctasks").style.display = "none";
          document.getElementById("listtasks").style.display = "none";
          document.getElementById("previoustasks").style.display = "none";
          document.getElementById("editdeletetasks").style.display = "none";
          document.getElementById("changedeledittasks").style.display = "none";
          document.getElementById("changetasks").style.display = "none";
          document.getElementById("viewtasks").style.display = "none";
          document.getElementById("listviewtasks").style.display = "none";
          document.getElementById("viewdisplaytasks").style.display = "none";
          document.getElementById("viewdoctasks").style.display = "none";
          document.getElementById("changeviewtasks").style.display = "none";
          //xxxxxxx input field
          document.getElementById("tasname").style.display = "none";
          document.getElementById("confirmertask").style.display = "none";
          //xxxxxxxxxxxxxx confirm and undo style
          document.getElementById("selecttasknamechange").style.display = "none";
          document.getElementById("undotasknamechange").style.display = "none";
        } catch (error) {
          var pTexteerreurconnection = "An Error has Occurred";
          alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
        }        
      };
      tr.appendChild(tdDocument);
    };

    // Add the row to the top of the table.
    documentTaskTableBody.insertBefore(tr, documentTaskTableBody.nextSibling);
  }
  /**Clear and update the UI to display the docs.*/
  function updateSelectDocumentTaskTableUIWithDocs(docs) {
    var documentTaskTableBody = document.getElementById("documentTaskTableBody");

    // Remove all child nodes from the table
    while (documentTaskTableBody.hasChildNodes()) {
      documentTaskTableBody.removeChild(documentTaskTableBody.childNodes[0]);
    }

    // Add the new documents to the div
    for (var i = 0; i < docs.length; i++) {
      var doc = docs[i];
      selectTaskDocToToTable(doc, documentTaskTableBody);
    }
  }

  //create the data table
  document.getElementById("addtasks").addEventListener("click", addtasksChange);
  async function addtasksChange(e) {
    e.preventDefault();

    //alert
    var elementOutId = "modaloutalerttasks";
    var elementDropId = "backdropoutalerttasks";
    var buttonHandler = "ouiHandlerAlertTasksBtn";

    var deviceStr = document.getElementById('device').innerText;
    var serveurHeaderStr = document.getElementById('serveurheader').innerText;

    if((deviceStr === '' && serveurHeaderStr === 'local') || 
    (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
      var hostnametaskStr = document.getElementById("hostnametask").value;
      var hostporttaskStr = document.getElementById("hostporttask").value;
      var usernametaskStr = document.getElementById("usernametask").value;
      var passwordnametaskStr = document.getElementById("passwordnametask").value;

      //variables from changetasks component
      var nametaskchangeStr = document.getElementById("nametaskchange").value.toLocaleLowerCase();
      //var taskidchangeStr = document.getElementById('taskidchange').value;
      var taskdatabasechangeStr = document.getElementById("taskname").value.toLocaleLowerCase(); //from newtasks component
      var taskserverchangeStr = document.getElementById("taskserverchange").value;
      var taskconnectchangeStr = document.getElementById("taskconnectchange").value;
      var nametaskdocumentStr = document.getElementById("nametaskdocument").value.toLocaleLowerCase();
      var fieldtaskdocumentStr = document.getElementById("fieldtaskdocument").value;
      var datetodayStr = getCurrentDate();
      var numbercol = 0;

      if (!nametaskdocumentStr) {
        var pTextenamedocument = "Enter document name";
        alertModal(elementOutId,elementDropId,pTextenamedocument,buttonHandler);
      } else {
        const dbbasedoc = new PouchDB(taskdatabasechangeStr, {skip_setup: true,});
        const remotebasedoc = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +taskdatabasechangeStr,{ skip_setup: true });
        PouchDB.plugin(PouchFind);

        var taskvalue = [];
        try {
          const taskkeyDoc = [tablevalueStr];
          const column = Object.keys(taskkeyDoc[0]);
          // get table heading data
          const columnData = () => {
              return column.map((data) => {
              return data;
            });
          };
          var columnDonnee = columnData();
          //retrieve all data inserted in dynamic input field freated
          numbercol = document.getElementById("fieldtaskchange").value;
          for (let j = 0; j < numbercol; j++) {
            var idfield = document.getElementById("column" + (j + 1)).value.toLocaleLowerCase();
            taskvalue.push(idfield);
          }

          const taskdata = {};
          columnDonnee.forEach((element, index) => {
          taskdata[element] = taskvalue[index];
          });

          //Create the document _id and _rev.
          var now = Date.now();
          //new data table id
          var id = nametaskdocumentStr.toLowerCase() + "_" + now;
          //console.log(id);
          // Add the document to the database.
          var response = await dbbasedoc.put({
            _id: id,
            taskdatabase: taskdatabasechangeStr.toLowerCase(),
            taskname: nametaskchangeStr.toLowerCase(),
            taskserver: taskserverchangeStr.toLocaleLowerCase(),
            documentname: nametaskdocumentStr,
            documentnumber: fieldtaskdocumentStr,
            taskdata,
            taskconnect: taskconnectchangeStr,
            documentdate: datetodayStr,
          });
          // check if document inserted
          var doc = await dbbasedoc.get(response.id);
          if (doc !== "") {
            function syncbase() {
              var opts = {
                live: true,
                retry: true,
                continuous: true,
                attachments: true,
              };
              dbbasedoc.sync(remotebasedoc, opts);
            }
            if (remotebasedoc) {
              syncbase();
            }
            var pTextenewdocument = "Doc " +nametaskdocumentStr +" " +fieldtaskdocumentStr +" added Succesfully";
            alertModal(elementOutId,elementDropId,pTextenewdocument,buttonHandler);
            var incNumber = parseFloat(fieldtaskdocumentStr) + 1;
            document.getElementById("fieldtaskdocument").value = incNumber.toString();
            for (let j = 0; j < numbercol; j++) {
              document.getElementById("column" + (j + 1)).value = "";
            }
          } else {
            var pTexteerreuraddnew = "An Error has Occurred 2";
            alertModal(elementOutId,elementDropId,pTexteerreuraddnew,buttonHandler);
          }
        } catch (error) {
          var pTexteerreurconnection = "An Error has Occurred 1";
          alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
        }
      }
    };

    if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
      //variables from changetasks component
      var nametaskchangeStr = document.getElementById("nametaskchange").value.toLocaleLowerCase();
      //var taskidchangeStr = document.getElementById('taskidchange').value;
      var taskdatabasechangeStr = serveurHeaderStr.toLocaleLowerCase(); //from newtasks component
      var taskserverchangeStr = document.getElementById("taskserverchange").value;
      var taskconnectchangeStr = document.getElementById("taskconnectchange").value;
      var nametaskdocumentStr = document.getElementById("nametaskdocument").value.toLocaleLowerCase();
      var fieldtaskdocumentStr = document.getElementById("fieldtaskdocument").value;
      var datetodayStr = getCurrentDate();
      var numbercol = 0;

      if(!nametaskdocumentStr) {
        var pTextenamedocument = "Enter document name";
        alertModal(elementOutId,elementDropId,pTextenamedocument,buttonHandler);
      }else{
        /*console.log(nametaskchangeStr)
        console.log(taskdatabasechangeStr)
        console.log(taskserverchangeStr)
        console.log(taskconnectchangeStr)
        console.log(nametaskdocumentStr)
        console.log(fieldtaskdocumentStr)
        console.log(datetodayStr)*/
        const dbbasedoc = new PouchDB(taskdatabasechangeStr, {skip_setup: true,});
        PouchDB.plugin(PouchFind);
        var taskvalue = [];
        try {
          const taskkeyDoc = [tablevalueStr];
          const column = Object.keys(taskkeyDoc[0]);
          // get table heading data
          const columnData = () => {
              return column.map((data) => {
              return data;
            });
          };
          var columnDonnee = columnData();
          //retrieve all data inserted in dynamic input field freated
          numbercol = document.getElementById("fieldtaskchange").value;
          for (let j = 0; j < numbercol; j++) {
            var idfield = document.getElementById("column" + (j + 1)).value.toLocaleLowerCase();
            taskvalue.push(idfield);
          }

          const taskdata = {};
          columnDonnee.forEach((element, index) => {
          taskdata[element] = taskvalue[index];
          });

          //Create the document _id and _rev.
          var now = Date.now();
          //new data table id
          var id = nametaskdocumentStr.toLowerCase() + "_" + now;
          //console.log(id);
          // Add the document to the database.
          var response = await dbbasedoc.put({
            _id: id,
            taskdatabase: taskdatabasechangeStr.toLowerCase(),
            taskname: nametaskchangeStr.toLowerCase(),
            taskserver: taskserverchangeStr.toLocaleLowerCase(),
            documentname: nametaskdocumentStr,
            documentnumber: fieldtaskdocumentStr,
            taskdata,
            taskconnect: taskconnectchangeStr,
            documentdate: datetodayStr,
          });
          // check if document inserted
          var doc = await dbbasedoc.get(response.id);
          if (doc !== ""){
            var pTextenewdocument = "Doc " +nametaskdocumentStr +" " +fieldtaskdocumentStr +" added Succesfully";
            alertModal(elementOutId,elementDropId,pTextenewdocument,buttonHandler);
            var incNumber = parseFloat(fieldtaskdocumentStr) + 1;
            document.getElementById("fieldtaskdocument").value = incNumber.toString();
            for (let j = 0; j < numbercol; j++) {
              document.getElementById("column" + (j + 1)).value = "";
            }
          }else{
            var pTexteerreuraddnew = "Add new task failed";
            alertModal(elementOutId,elementDropId,pTexteerreuraddnew,buttonHandler);
          }
        } catch (error) {
          var pTexteerreurconnection = "An Error has Occurred 1";
          alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
        }
      }
    };
  }

  //previous table test
  //set global variable
  var typecrud = "";
  document.getElementById("previoustask").addEventListener("click", previoustaskChange);
  async function previoustaskChange(e) {
    e.preventDefault();

    var divClearModal = document.getElementById("modalouttasks");
    let divModal = document.createElement("div");
    divModal.innerHTML = `
    <div class="modal">
      <p>Select your task</p>
      <button class="btn btn--alt" id="ouiHandlerEditTaskBtn">Edit</button>
      <button class="btn" id="nonHandlerDeleteTaskBtn">Delete</button>
    </div>`;
    divClearModal.appendChild(divModal);

    var divClearBackdrop = document.getElementById("backdropouttasks");
    let divBack = document.createElement("div");
    divBack.innerHTML = `<div class="backdrop" id="backdropHandlerCloseTaskBtn"></div>`;
    divClearBackdrop.appendChild(divBack);

    //If Edit Clicked
    document.getElementById("ouiHandlerEditTaskBtn").addEventListener("click", ouiHandlerEditTaskBtnChange);
    async function ouiHandlerEditTaskBtnChange(e) {
      e.preventDefault();

      typecrud = "edit";

      var deviceStr = document.getElementById('device').innerText;
      var serveurHeaderStr = document.getElementById('serveurheader').innerText;

      document.getElementById("documenttasks").style.display = "block";
      document.getElementById("querydocumenttasks").style.display = "block";
      document.getElementById("crudtasksactions").style.display = "block";
      document.getElementById("addtasks").style.display = "none";
      document.getElementById("edittasks").style.display = "none";
      document.getElementById("deletetasks").style.display = "none";
      //xxxxxxxxx crudbase xxxxxxxxx
      if((deviceStr === '' && serveurHeaderStr === 'local') || 
      (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
        document.getElementById("newtasks").style.display = "none";
        document.getElementById("createtasks").style.display = "none";
        document.getElementById("doctasks").style.display = "none";
        document.getElementById("listtasks").style.display = "none";
        document.getElementById("previoustasks").style.display = "block";
        document.getElementById('prevdesktasks').style.display = 'block';
        document.getElementById('prevmobiletasks').style.display = 'none';
        document.getElementById("editdeletetasks").style.display = "none";
        document.getElementById("changedeledittasks").style.display = "none";
        document.getElementById("changetasks").style.display = "none";
        document.getElementById("viewtasks").style.display = "none";
        document.getElementById("listviewtasks").style.display = "none";
        document.getElementById("viewdisplaytasks").style.display = "none";
        document.getElementById("viewdoctasks").style.display = "none";
        document.getElementById("changeviewtasks").style.display = "none";
        //xxxxxxx input field
        document.getElementById("tasname").style.display = "block";
        //document.getElementById('servertablename').style.display = 'none';
        document.getElementById("confirmertask").style.display = "block";
      }

      if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
        document.getElementById("newtasks").style.display = "none";
        document.getElementById("createtasks").style.display = "none";
        document.getElementById("doctasks").style.display = "none";
        document.getElementById("listtasks").style.display = "none";
        document.getElementById("previoustasks").style.display = "block";
        document.getElementById('prevdesktasks').style.display = 'none';
        document.getElementById('prevmobiletasks').style.display = 'block';
        document.getElementById("editdeletetasks").style.display = "none";
        document.getElementById("changedeledittasks").style.display = "none";
        document.getElementById("changetasks").style.display = "none";
        document.getElementById("viewtasks").style.display = "none";
        document.getElementById("listviewtasks").style.display = "none";
        document.getElementById("viewdisplaytasks").style.display = "none";
        document.getElementById("viewdoctasks").style.display = "none";
        document.getElementById("changeviewtasks").style.display = "none";
        //xxxxxxx input field
        //document.getElementById("mobiletasname").style.display = "block";
        //document.getElementById('servertablename').style.display = 'none';
        document.getElementById("mobileconfirmertask").style.display = "block";
      }

      var divClearModal = document.getElementById("modalouttasks");
      while (divClearModal.hasChildNodes()) {
        divClearModal.removeChild(divClearModal.childNodes[0]);
      }

      var divClearBackdrop = document.getElementById("backdropouttasks");
      while (divClearBackdrop.hasChildNodes()) {
        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
      }
    }

    //If Delete Clicked
    document.getElementById("nonHandlerDeleteTaskBtn").addEventListener("click", nonHandlerDeleteTaskBtnChange);
    async function nonHandlerDeleteTaskBtnChange(e) {
      e.preventDefault();

      typecrud = "delete";

      var deviceStr = document.getElementById('device').innerText;
      var serveurHeaderStr = document.getElementById('serveurheader').innerText;

      document.getElementById("documenttasks").style.display = "block";
      document.getElementById("querydocumenttasks").style.display = "block";
      document.getElementById("crudtasksactions").style.display = "block";
      document.getElementById("addtasks").style.display = "none";
      document.getElementById("edittasks").style.display = "none";
      document.getElementById("deletetasks").style.display = "none";
      //xxxxxxxxx crudbase xxxxxxxxx
      if((deviceStr === '' && serveurHeaderStr === 'local') || 
      (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
        document.getElementById("newtasks").style.display = "none";
        document.getElementById("createtasks").style.display = "none";
        document.getElementById("doctasks").style.display = "none";
        document.getElementById("listtasks").style.display = "none";
        document.getElementById("previoustasks").style.display = "block";
        document.getElementById('prevdesktasks').style.display = 'block';
        document.getElementById('prevmobiletasks').style.display = 'none';
        document.getElementById("editdeletetasks").style.display = "none";
        document.getElementById("changedeledittasks").style.display = "none";
        document.getElementById("changetasks").style.display = "none";
        document.getElementById("viewtasks").style.display = "none";
        document.getElementById("listviewtasks").style.display = "none";
        document.getElementById("viewdisplaytasks").style.display = "none";
        document.getElementById("viewdoctasks").style.display = "none";
        document.getElementById("changeviewtasks").style.display = "none";
        //xxxxxxx input field
        document.getElementById("tasname").style.display = "block";
        //document.getElementById('servertablename').style.display = 'none';
        document.getElementById("confirmertask").style.display = "block";
      }

      if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
        document.getElementById("newtasks").style.display = "none";
        document.getElementById("createtasks").style.display = "none";
        document.getElementById("doctasks").style.display = "none";
        document.getElementById("listtasks").style.display = "none";
        document.getElementById("previoustasks").style.display = "block";
        document.getElementById('prevdesktasks').style.display = 'none';
        document.getElementById('prevmobiletasks').style.display = 'block';
        document.getElementById("editdeletetasks").style.display = "none";
        document.getElementById("changedeledittasks").style.display = "none";
        document.getElementById("changetasks").style.display = "none";
        document.getElementById("viewtasks").style.display = "none";
        document.getElementById("listviewtasks").style.display = "none";
        document.getElementById("viewdisplaytasks").style.display = "none";
        document.getElementById("viewdoctasks").style.display = "none";
        document.getElementById("changeviewtasks").style.display = "none";
        //xxxxxxx input field
        //document.getElementById("mobiletasname").style.display = "block";
        //document.getElementById('servertablename').style.display = 'none';
        document.getElementById("mobileconfirmertask").style.display = "block";
      }

      var divClearModal = document.getElementById("modalouttasks");
      while (divClearModal.hasChildNodes()) {
        divClearModal.removeChild(divClearModal.childNodes[0]);
      }

      var divClearBackdrop = document.getElementById("backdropouttasks");
      while (divClearBackdrop.hasChildNodes()) {
        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
      }
    }

    //If backdrop Clicked
    document.getElementById("backdropHandlerCloseTaskBtn").addEventListener("click", backdropHandlerCloseTaskBtnChange);
    async function backdropHandlerCloseTaskBtnChange(e) {
      e.preventDefault();

      var divClearModal = document.getElementById("modalouttasks");
      while (divClearModal.hasChildNodes()) {
        divClearModal.removeChild(divClearModal.childNodes[0]);
      }

      var divClearBackdrop = document.getElementById("backdropouttasks");
      while (divClearBackdrop.hasChildNodes()) {
        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
      }
    }
  }

  //Crud tasks (confirmertaskschange)
  document.getElementById("confirmtaskchange").addEventListener("click", confirmtaskchangeChange);
  async function confirmtaskchangeChange(e) {
    e.preventDefault();

    //alert
    var elementOutId = "modaloutalerttasks";
    var elementDropId = "backdropoutalerttasks";
    var buttonHandler = "ouiHandlerAlertTaskBtn";

    var hostnametaskchangeStr = document.getElementById("hostnametaskchange").value;
    var hostporttaskchangeStr = document.getElementById("hostporttaskchange").value;
    var usernametaskchangeStr = document.getElementById("usernametaskchange").value;
    var passwordnametaskchangeStr = document.getElementById("passwordnametaskchange").value;
    var tasknamechangeStr = document.getElementById("tasknamechange").value.toLowerCase();
    var serveurHeaderStr = document.getElementById("serveurheader").innerText;
    var deviceStr = document.getElementById('device').innerText;
    var tabletemplateStr = "template";
    var localnamebaseStr = "";
    var servernameStr = "database";
    if (serveurHeaderStr === "local") {
      localnamebaseStr = "local";
    } else if (serveurHeaderStr === "remote") {
      localnamebaseStr = "remote";
    }
    var localtypebaseStr = "database";

    //check if values not null
    if (!hostnametaskchangeStr) {
      var pTextehostname = "Enter Host Name";
      alertModal(elementOutId, elementDropId, pTextehostname, buttonHandler);
    } else if (!hostporttaskchangeStr) {
      var pTexteportname = "Enter Port Number";
      alertModal(elementOutId, elementDropId, pTexteportname, buttonHandler);
    } else if (!usernametaskchangeStr) {
      var pTexteusername = "Enter Username";
      alertModal(elementOutId, elementDropId, pTexteusername, buttonHandler);
    } else if (!passwordnametaskchangeStr) {
      var pTextepasswordname = "Enter Password";
      alertModal(elementOutId,elementDropId,pTextepasswordname,buttonHandler);
    } else if (!tasknamechangeStr) {
      var pTextedatabasename = "Enter Database Name";
      alertModal(elementOutId,elementDropId,pTextedatabasename,buttonHandler);
    } else {
      var docsCheckConfirm = [];
      //start main try catch
      try {
        //test if hostname and portnumber are true
        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
          var url = "http://" +hostnametaskchangeStr +":" +hostporttaskchangeStr +"/_utils/";
          var http_request = new XMLHttpRequest();
          http_request.open("GET", url, true);
          //http_request.send("null");
          http_request.onreadystatechange = async function () {
            if (http_request.readyState === 4) {
              if (http_request.status === 200) {
                //search in local / remote database documents
                const db = new PouchDB(localnamebaseStr, { skip_setup: true });
                const remote = new PouchDB("http://" +usernametaskchangeStr +":" +passwordnametaskchangeStr +"@" +hostnametaskchangeStr +":" +hostporttaskchangeStr +"/" +localnamebaseStr,{ skip_setup: true });
                PouchDB.plugin(PouchFind);
                // Initialise a sync with the remote server
                function sync() {
                  var opts = {
                    live: true,
                    retry: true,
                    continuous: true,
                    attachments: true,
                  };
                  db.sync(remote, opts);
                }
                if (remote) {
                  sync();
                }

                let result = await db.createIndex({
                  index: {
                    fields: ["connectname", "servername"],
                  },
                });
                result = await db.find({
                  selector: {
                    connectname: tasknamechangeStr,
                    servername: servernameStr,
                  },
                });
                docsCheckConfirm = result.docs;
                var resultatCheckConfirm = docsCheckConfirm.length;
                if (resultatCheckConfirm === 0) {
                  var divClearModal = document.getElementById("modalouttasks");
                  let divModal = document.createElement("div");
                  divModal.innerHTML = `
                  <div class="modal">
                    <p>Sure this database exists?</p>
                    <button class="btn btn--alt" id="ouiHandlerExistChangeBtn">Yes</button>
                    <button class="btn" id="nonHandlerExistsChangeBtn">No</button>
                  </div>`;
                  divClearModal.appendChild(divModal);

                  var divClearBackdrop = document.getElementById("backdropouttasks");
                  let divBack = document.createElement("div");
                  divBack.innerHTML = `<div class="backdrop" id="backdropHandlerCloseExistsChangeBtn"></div>`;
                  divClearBackdrop.appendChild(divBack);

                  //If Yes Clicked
                  document.getElementById("ouiHandlerExistChangeBtn").addEventListener("click", ouiHandlerExistChangeBtnChange);
                  async function ouiHandlerExistChangeBtnChange(e) {
                    e.preventDefault();

                    //code here
                    var divClearModal = document.getElementById("modalouttasks");
                    let divModal = document.createElement("div");
                    divModal.innerHTML = `
                    <div class="modal">
                      <p>Wish to Add it?</p>
                      <button class="btn btn--alt" id="ouiHandlerAddChangeBtn">Yes</button>
                      <button class="btn" id="nonHandlerAddChangeBtn">No</button>
                    </div>`;
                    divClearModal.appendChild(divModal);

                    var divClearBackdrop = document.getElementById("backdropouttasks");
                    let divBack = document.createElement("div");
                    divBack.innerHTML = `<div class="backdrop" id="backdropHandlerCloseAddChangeBtn"></div>`;
                    divClearBackdrop.appendChild(divBack);

                    //If Yes Add Clicked
                    document.getElementById("ouiHandlerAddChangeBtn").addEventListener("click", ouiHandlerAddChangeBtnChange);
                    async function ouiHandlerAddChangeBtnChange(e) {
                      e.preventDefault();

                      //code here
                      const db = new PouchDB(localnamebaseStr, {skip_setup: true,});
                      const remote = new PouchDB("http://" +usernametaskchangeStr +":" +passwordnametaskchangeStr +"@" +hostnametaskchangeStr +":" +hostporttaskchangeStr +"/" +localnamebaseStr,{ skip_setup: true });
                      PouchDB.plugin(PouchFind);
                      //test if hostname and portnumber are true
                      var http_request = new XMLHttpRequest();
                      http_request.open("GET","http://" +hostnametaskchangeStr +":" +hostporttaskchangeStr +"/_utils/",true);
                      http_request.send("null");
                      http_request.onreadystatechange = async function () {
                        if (http_request.readyState === 4) {
                          if (http_request.status === 200) {
                            function sync() {
                              var opts = {
                                live: true,
                                retry: true,
                                continuous: true,
                                attachments: true,
                              };
                              db.sync(remote, opts);
                            }
                            if (remote) {
                              sync();
                            }
                            //end sync
                            var docsCheck = [];
                            try {
                              let result = await db.createIndex({
                                index: {
                                  fields: [
                                    "hostname",
                                    "portname",
                                    "username",
                                    "passwordname",
                                  ],
                                },
                              });
                              result = await db.find({
                                selector: {
                                  hostname: hostnametaskchangeStr,
                                  portname: hostporttaskchangeStr,
                                  username: usernametaskchangeStr,
                                  passwordname: passwordnametaskchangeStr,
                                },
                              });
                              docsCheck = result.docs;
                              var resultatCheck = docsCheck.length;
                              if (resultatCheck === "") {
                                var pTexteincorrectconnection = "Name or password is incorrect.";
                                alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
                              } else {
                                var docsCheckDatabase = [];
                                let resultDatabase = await db.createIndex({
                                  index: {
                                    fields: ["connectname"],
                                  },
                                });
                                resultDatabase = await db.find({
                                  selector: {
                                    connectname: tasknamechangeStr,
                                  },
                                });
                                docsCheckDatabase = resultDatabase.docs;
                                var resultatCheckDatabase = docsCheckDatabase.length;
                                if (resultatCheckDatabase === 0) {
                                  const dbNew = new PouchDB(tasknamechangeStr);
                                  const remoteNew = couchProvider(hostnametaskchangeStr,hostporttaskchangeStr,usernametaskchangeStr,passwordnametaskchangeStr,tasknamechangeStr);
                                  //add new database created to local connection server
                                  //Create the document _id and _rev.
                                  var now = Date.now();
                                  //new database id
                                  var id = tasknamechangeStr.toLowerCase() + "_" + now;
                                  // Add the document to the database.
                                  var response = await db.put({
                                    _id: id,
                                    connectname: tasknamechangeStr, //the new database name
                                    hostname: hostnametaskchangeStr,
                                    portname: hostporttaskchangeStr,
                                    username: usernametaskchangeStr,
                                    passwordname: passwordnametaskchangeStr,
                                    servername: localtypebaseStr,
                                  });
                                  // check if document inserted
                                  var doc = await db.get(response.id);
                                  if (doc !== "") {
                                    //Initialise a sync with the remote server
                                    function sync() {
                                      var opts = {
                                        live: true,
                                        retry: true,
                                        continuous: true,
                                        attachments: true,
                                      };
                                      dbNew.sync(remoteNew, opts);
                                    }
                                    if (remoteNew) {
                                      sync();
                                    }
                                    var pTextenewdatabase = "New Database added Succesfully";
                                    alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
                                  } else {
                                    var pTexteerreuraddnew = "An Error has Occurred";
                                    alertModal(elementOutId,elementDropId,pTexteerreuraddnew,buttonHandler);
                                  }
                                } else {
                                  var pTexteexistaddnew = "Database " +tasknamechangeStr +" already exists. Confirm again";
                                  alertModal(elementOutId,elementDropId,pTexteexistaddnew,buttonHandler);
                                }
                              }
                            } catch (error) {
                              var pTexteerreurconnection = "An Error has Occurred";
                              alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                            }
                          } else {
                            var pTextefailedconnect = "Connection Failed.";
                            alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                          }
                        }
                      };
                      var divClearModal = document.getElementById("modalouttasks");
                      while (divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                      }

                      var divClearBackdrop = document.getElementById("backdropouttasks");
                      while (divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(
                          divClearBackdrop.childNodes[0]
                        );
                      }
                    }
                    //If No Add Clicked
                    document.getElementById("nonHandlerAddChangeBtn").addEventListener("click", nonHandlerAddChangeBtnChange);
                    async function nonHandlerAddChangeBtnChange(e) {
                      e.preventDefault();

                      //code here

                      var divClearModal = document.getElementById("modalouttasks");
                      while (divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                      }

                      var divClearBackdrop = document.getElementById("backdropouttasks");
                      while (divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(
                          divClearBackdrop.childNodes[0]
                        );
                      }
                    }

                    //If backdrop Clicked
                    document.getElementById("backdropHandlerCloseAddChangeBtn").addEventListener("click", backdropHandlerCloseAddChangeBtnChange);
                    async function backdropHandlerCloseAddChangeBtnChange(e) {
                      e.preventDefault();

                      //code here

                      var divClearModal = document.getElementById("modalouttasks");
                      while (divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                      }

                      var divClearBackdrop = document.getElementById("backdropouttasks");
                      while (divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(
                          divClearBackdrop.childNodes[0]
                        );
                      }
                    }
                  //
                  }
                  //If No Clicked
                  document.getElementById("nonHandlerExistsChangeBtn").addEventListener("click", nonHandlerExistsChangeBtnChange);
                  async function nonHandlerExistsChangeBtnChange(e) {
                    e.preventDefault();

                    //code here
                    var divClearModal = document.getElementById("modalouttasks");
                    let divModal = document.createElement("div");
                    divModal.innerHTML = `
                    <div class="modal">
                      <p>Wish to Use it?</p>
                      <button class="btn btn--alt" id="ouiHandlerUseChangeBtn">Yes</button>
                      <button class="btn" id="nonHandlerUseChangeBtn">No</button>
                    </div>`;
                    divClearModal.appendChild(divModal);

                    var divClearBackdrop = document.getElementById("backdropouttasks");
                    let divBack = document.createElement("div");
                    divBack.innerHTML = `<div class="backdrop" id="backdropHandlerCloseUseChangeBtn"></div>`;
                    divClearBackdrop.appendChild(divBack);

                    //If Yes Use Clicked
                    document.getElementById("ouiHandlerUseChangeBtn").addEventListener("click", ouiHandlerUseChangeBtnChange);
                    async function ouiHandlerUseChangeBtnChange(e) {
                      e.preventDefault();

                      //code here
                      const db = new PouchDB(localnamebaseStr, {skip_setup: true,});
                      const remote = new PouchDB("http://" +usernametaskchangeStr +":" +passwordnametaskchangeStr +"@" +hostnametaskchangeStr +":" +hostporttaskchangeStr +"/" +localnamebaseStr,{ skip_setup: true });
                      PouchDB.plugin(PouchFind);
                      //test if hostname and portnumber are true
                      var http_request = new XMLHttpRequest();
                      http_request.open("GET","http://" +hostnametaskchangeStr +":" +hostporttaskchangeStr +"/_utils/",true);
                      http_request.send("null");
                      http_request.onreadystatechange = async function () {
                        if (http_request.readyState === 4) {
                          if (http_request.status === 200) {
                            function sync() {
                              var opts = {
                                live: true,
                                retry: true,
                                continuous: true,
                                attachments: true,
                              };
                              db.sync(remote, opts);
                            }
                            if (remote) {
                              sync();
                            }
                            //end sync
                            var docsCheck = [];
                            try {
                              let result = await db.createIndex({
                                index: {
                                  fields: [
                                    "hostname",
                                    "portname",
                                    "username",
                                    "passwordname",
                                  ],
                                },
                              });
                              result = await db.find({
                                selector: {
                                  hostname: hostnametaskchangeStr,
                                  portname: hostporttaskchangeStr,
                                  username: usernametaskchangeStr,
                                  passwordname: passwordnametaskchangeStr,
                                },
                              });
                              docsCheck = result.docs;
                              var resultatCheck = docsCheck.length;
                              if (resultatCheck === "") {
                                var pTexteincorrectconnection = "Name or password is incorrect.";
                                alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
                              } else {
                                var docsCheckDatabase = [];
                                let resultDatabase = await db.createIndex({
                                  index: {
                                    fields: ["connectname"],
                                  },
                                });
                                resultDatabase = await db.find({
                                  selector: {
                                    connectname: tasknamechangeStr,
                                  },
                                });
                                docsCheckDatabase = resultDatabase.docs;
                                var resultatCheckDatabase = docsCheckDatabase.length;
                                if (resultatCheckDatabase === 0) {
                                  const dbNew = new PouchDB(tasknamechangeStr);
                                  const remoteNew = couchProvider(hostnametaskchangeStr,hostporttaskchangeStr,usernametaskchangeStr,passwordnametaskchangeStr,tasknamechangeStr);
                                  //add new database created to local connection server
                                  //Create the document _id and _rev.
                                  var now = Date.now();
                                  //new database id
                                  var id = tasknamechangeStr.toLowerCase() + "_" + now;
                                  // Add the document to the database.
                                  var response = await db.put({
                                    _id: id,
                                    connectname: tasknamechangeStr, //the new database name
                                    hostname: hostnametaskchangeStr,
                                    portname: hostporttaskchangeStr,
                                    username: usernametaskchangeStr,
                                    passwordname: passwordnametaskchangeStr,
                                    servername: localtypebaseStr,
                                  });
                                  // check if document inserted
                                  var doc = await db.get(response.id);
                                  if (doc !== "") {
                                    //Initialise a sync with the remote server
                                    function sync() {
                                      var opts = {
                                        live: true,
                                        retry: true,
                                        continuous: true,
                                        attachments: true,
                                      };
                                      dbNew.sync(remoteNew, opts);
                                    }
                                    if (remoteNew) {
                                      sync();
                                    }
                                    var pTextenewdatabase = "New Database added Succesfully";
                                    alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
                                  } else {
                                    var pTexteerreuraddnew = "An Error has Occurred";
                                    alertModal(elementOutId,elementDropId,pTexteerreuraddnew,buttonHandler);
                                  }
                                } else {
                                  var pTexteexistaddnew = "Database " +tasknamechangeStr +" already exists. Confirm again";
                                  alertModal(elementOutId,elementDropId,pTexteexistaddnew,buttonHandler);
                                }
                              }
                            } catch (error) {
                              var pTexteerreurconnection = "An Error has Occurred";
                              alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                            }
                          } else {
                            var pTextefailedconnect = "Connection Failed.";
                            alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                          }
                        }
                      };
                      var divClearModal = document.getElementById("modalouttasks");
                      while (divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                      }

                      var divClearBackdrop = document.getElementById("backdropouttasks");
                      while (divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(
                          divClearBackdrop.childNodes[0]
                        );
                      }
                    }

                    //If No Use Clicked
                    document.getElementById("nonHandlerUseChangeBtn").addEventListener("click", nonHandlerUseChangeBtnChange);
                    async function nonHandlerUseChangeBtnChange(e) {
                      e.preventDefault();

                      //code here

                      var divClearModal = document.getElementById("modalouttasks");
                      while (divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                      }

                      var divClearBackdrop = document.getElementById("backdropouttasks");
                      while (divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(
                          divClearBackdrop.childNodes[0]
                        );
                      }
                    }

                    //If backdrop Clicked
                    document.getElementById("backdropHandlerCloseUseChangeBtn").addEventListener("click", backdropHandlerCloseUseChangeBtnChange);
                    async function backdropHandlerCloseUseChangeBtnChange(e) {
                      e.preventDefault();

                      //code here

                      var divClearModal = document.getElementById("modalouttasks");
                      while (divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                      }

                      var divClearBackdrop = document.getElementById("backdropouttasks");
                      while (divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                      }
                    }
                  //
                  }

                  //If backdrop Clicked
                  document.getElementById("backdropHandlerCloseExistsChangeBtn").addEventListener("click", backdropHandlerCloseExistsChangeBtnChange);
                  async function backdropHandlerCloseExistsChangeBtnChange(e) {
                    e.preventDefault();

                    //code here

                    var divClearModal = document.getElementById("modalouttasks");
                    while (divClearModal.hasChildNodes()) {
                      divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById("backdropouttasks");
                    while (divClearBackdrop.hasChildNodes()) {
                      divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                    }
                  }
                } else {
                  var docsCheckTable = [];
                  var tableconnectStr = "table";
                  try {
                    let resultTable = await db.createIndex({
                      index: {
                        fields: [
                          "tabletemplate", //not use database name but template as table global name
                          "tableserver",
                          "tableconnect",
                        ],
                      },
                    });
                    resultTable = await db.find({
                      selector: {
                        tabletemplate: tabletemplateStr, //not use database name but template as table global name
                        tableserver: localnamebaseStr,
                        tableconnect: tableconnectStr,
                      },
                    });
                    docsCheckTable = resultTable.docs;
                    var resultatCheckTable = docsCheckTable.length;
                    if (resultatCheckTable === 0) {
                      var pTextenotable = "No Table template available";
                      alertModal(elementOutId,elementDropId,pTextenotable,buttonHandler);
                    } else {
                      document.getElementById("documenttasks").style.display = "block";
                      document.getElementById("querydocumenttasks").style.display = "block";
                      document.getElementById("crudtasksactions").style.display = "block";
                      document.getElementById("addtasks").style.display = "none";
                      document.getElementById("edittasks").style.display = "none";
                      document.getElementById("deletetasks").style.display = "none";
                      //xxxxxxxxx crudbase xxxxxxxxx
                      updateTaskTableUIWithDocs(docsCheckTable);
                      document.getElementById("newtasks").style.display = "none";
                      document.getElementById("createtasks").style.display = "none";
                      document.getElementById("doctasks").style.display = "none";
                      document.getElementById("listtasks").style.display = "block";
                      document.getElementById("previoustasks").style.display = "none";
                      document.getElementById("editdeletetasks").style.display = "none";
                      document.getElementById("changedeledittasks").style.display = "none";
                      document.getElementById("changetasks").style.display = "none";
                      document.getElementById("viewtasks").style.display = "none";
                      document.getElementById("listviewtasks").style.display = "none";
                      document.getElementById("viewdisplaytasks").style.display = "none";
                      document.getElementById("viewdoctasks").style.display = "none";
                      document.getElementById("changeviewtasks").style.display = "none";
                      //xxxxxxx input field
                      document.getElementById("tasname").style.display = "block";
                      document.getElementById("confirmertask").style.display = "block";
                    }
                  } catch (error) {
                    var pTexteerreurtable = "An Error has Occurred";
                    alertModal(elementOutId,elementDropId,pTexteerreurtable,buttonHandler);
                  }
                }
              } else {
                var pTexteincorrectconnection = "Incorrect Connection Parameters";
                alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
              }
            }
          };
          http_request.send();
        }
      } catch (error) {
        var pTexteerreurconnection = "An Error has Occurred";
        alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
      }
    }
  }
  //Crud tasks (mobileconfirmertable)
  document.getElementById("mobileconfirmtaskchange").addEventListener("click", mobileconfirmtaskchangeChange);
  async function mobileconfirmtaskchangeChange(e){
    e.preventDefault();
    
    //alert
    var elementOutId = "modaloutalerttasks";
    var elementDropId = "backdropoutalerttasks";
    var buttonHandler = "ouiHandlerAlertTaskBtn";

    var mobileusernametaskchangeStr = document.getElementById("mobileusernametaskchange").value;
    var mobilepasswordnametaskchangeStr = document.getElementById("mobilepasswordnametaskchange").value;
    //var tasknamechangeStr = document.getElementById("tasknamechange").value.toLowerCase();
    var serveurHeaderStr = document.getElementById("serveurheader").innerText;
    var deviceStr = document.getElementById('device').innerText;
    var tabletemplateStr = "template";

    if(!mobileusernametaskchangeStr){
      var pTexteusername = "Enter Username";
      alertModal(elementOutId, elementDropId, pTexteusername, buttonHandler);
    }else if(!mobilepasswordnametaskchangeStr) {
      var pTextepasswordname = "Enter Password";
      alertModal(elementOutId,elementDropId,pTextepasswordname,buttonHandler);
    }else{
      var docsCheckConfirm = [];
      try {
        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
          const db = new PouchDB(serveurHeaderStr, { skip_setup: true });
          PouchDB.plugin(PouchFind);
          let result = await db.createIndex({
            index: {
              fields: [
                "servername"
              ],
            },
          });
          result = await db.find({
            selector: {
              servername: serveurHeaderStr,
            },
          });
          docsCheckConfirm = result.docs;
          var resultatCheckConfirm = docsCheckConfirm.length;
          if (resultatCheckConfirm === 0){
            var pTextedatabasenotexist = "Database not exists. Create it first";
            alertModal(elementOutId,elementDropId,pTextedatabasenotexist,buttonHandler);
          }else{
            var docsCheckTable = [];
            var tableconnectStr = "table";
            try {
              let resultTable = await db.createIndex({
                index: {
                  fields: [
                    "tabletemplate", //not use database name but template as table global name
                    "tableserver",
                    "tableconnect",
                  ],
                },
              });
              resultTable = await db.find({
                selector: {
                  tabletemplate: tabletemplateStr, //not use database name but template as table global name
                  tableserver: serveurHeaderStr,
                  tableconnect: tableconnectStr,
                },
              });
              docsCheckTable = resultTable.docs;
              var resultatCheckTable = docsCheckTable.length;
              if (resultatCheckTable === 0){
                var pTextenotable = "No Table template available";
                alertModal(elementOutId,elementDropId,pTextenotable,buttonHandler);
              }else{
                document.getElementById("documenttasks").style.display = "block";
                document.getElementById("querydocumenttasks").style.display = "block";
                document.getElementById("crudtasksactions").style.display = "block";
                document.getElementById("addtasks").style.display = "none";
                document.getElementById("edittasks").style.display = "none";
                document.getElementById("deletetasks").style.display = "none";
                //xxxxxxxxx crudbase xxxxxxxxx
                updateTaskTableUIWithDocs(docsCheckTable);
                document.getElementById("newtasks").style.display = "none";
                document.getElementById("createtasks").style.display = "none";
                document.getElementById("doctasks").style.display = "none";
                document.getElementById("listtasks").style.display = "block";
                document.getElementById("previoustasks").style.display = "none";
                document.getElementById("editdeletetasks").style.display = "none";
                document.getElementById("changedeledittasks").style.display = "none";
                document.getElementById("changetasks").style.display = "none";
                document.getElementById("viewtasks").style.display = "none";
                document.getElementById("listviewtasks").style.display = "none";
                document.getElementById("viewdisplaytasks").style.display = "none";
                document.getElementById("viewdoctasks").style.display = "none";
                document.getElementById("changeviewtasks").style.display = "none";
                //xxxxxxx input field
                document.getElementById("tasname").style.display = "block";
                document.getElementById("confirmertask").style.display = "block";
              }
            } catch (error) {
              var pTexteerreurtable = "An Error has Occurred";
              alertModal(elementOutId,elementDropId,pTexteerreurtable,buttonHandler);
            }
          }
        }
      } catch (error) {
        var pTexteerreurconnection = "An Error has Occurred";
        alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
      }
    }
  }
  // Adds the document to the HTML First table.
  // If the document already exists in the table, it will be updated.
  var edittablekeyvalue = '';//global variable
  function updateTaskDocToToTable(doc, crudTaskTableBody) {
    // Each row of the table has an id attribute set to the doc._id field.
    var tr = crudTaskTableBody.querySelector(`#${doc._id}`);

    if (!tr) {
      tr = document.createElement("tr");
      tr.id = doc._id;
    }

    // Remove any existing table cells.
    while (tr.hasChildNodes()) {
      tr.removeChild(tr.childNodes[0]);
    }

    // Create new table cells for text fields.
    var tdTableServer = document.createElement("td");
    tdTableServer.innerText = doc.tableserver;
    //var tableNameStr = doc.tablename;
    tdTableServer.onclick = function () {
      var tableidStr = doc._id;
      var tabledatabaseStr = doc.tabledatabase;
      var tablenameStr = doc.tablename;
      var tableserverStr = doc.tableserver;
      var tablekeyStr = doc.tablekey;
      var tablevalueStr = doc.tablevalue;
      edittablekeyvalue = tablevalueStr;
      var tableconnectStr = doc.tableconnect;
      //check if task is edit,delete or else
      if (typecrud === "edit" || typecrud === "delete") {
        var nametablechangetext = document.getElementById("nametaskeditdelete");
        nametablechangetext.value = tablenameStr;
        var fieldtablechangetext = document.getElementById("fieldtaskeditdelete");
        fieldtablechangetext.value = tablekeyStr.length;
        var tableidchangetext = document.getElementById("taskideditdelete");
        tableidchangetext.value = tableidStr;
        var tabledatabasechangetext = document.getElementById("taskdatabaseeditdelete");
        tabledatabasechangetext.value = tabledatabaseStr;
        var tableserverchangetext = document.getElementById("taskservereditdelete");
        tableserverchangetext.value = tableserverStr;
        var tableconnectchangetext = document.getElementById("taskconnecteditdelete");
        tableconnectchangetext.value = tableconnectStr;

        // get the reference for the body
        var body = document.getElementById("tasktemplateeditdelete");
        // Remove any existing input field.
        while (body.hasChildNodes()) {
          body.removeChild(body.childNodes[0]);
        }

        //populate tablekey
        for (let i = 0; i < tablevalueStr.length; i++) {
          // creates a table row
          var input = document.createElement("input");
          input.type = "text";
          input.id = "column" + (i + 1);
          //input.placeholder = 'column '+(i+1);
          input.value = tablevalueStr[i];
          input.style.height = "35px";
          input.style.fontSize = "14px";
          input.setAttribute("readonly", true);
          body.appendChild(input);
          // Append a line break
          body.appendChild(document.createElement("br"));
        }

        document.getElementById("documenttasks").style.display = "block";
        document.getElementById("querydocumenttasks").style.display = "block";
        document.getElementById("crudtasksactions").style.display = "block";
        document.getElementById("addtasks").style.display = "none";
        document.getElementById("edittasks").style.display = "none";
        document.getElementById("deletetasks").style.display = "none";
        //xxxxxxxxx crudbase xxxxxxxxx
        document.getElementById("newtasks").style.display = "none";
        document.getElementById("createtasks").style.display = "none";
        document.getElementById("doctasks").style.display = "none";
        document.getElementById("listtasks").style.display = "none";
        document.getElementById("previoustasks").style.display = "none";
        document.getElementById("editdeletetasks").style.display = "block";
        document.getElementById("changedeledittasks").style.display = "none";
        document.getElementById("changetasks").style.display = "none";
        document.getElementById("viewtasks").style.display = "none";
        document.getElementById("listviewtasks").style.display = "none";
        document.getElementById("viewdisplaytasks").style.display = "none";
        document.getElementById("viewdoctasks").style.display = "none";
        document.getElementById("changeviewtasks").style.display = "none";
        //xxxxxxx input field
        document.getElementById("tasname").style.display = "none";
        document.getElementById("confirmertask").style.display = "none";
        //xxxxxxxxxxxxxx confirm and undo style
        document.getElementById("selecttasknamechange").style.display = "block";
        document.getElementById("undotasknamechange").style.display = "block";
      } else {
        var nametablechangetext = document.getElementById("nametaskchange");
        nametablechangetext.value = tablenameStr;
        var fieldtablechangetext = document.getElementById("fieldtaskchange");
        fieldtablechangetext.value = tablekeyStr.length;
        var tableidchangetext = document.getElementById("taskidchange");
        tableidchangetext.value = tableidStr;
        var tabledatabasechangetext =
          document.getElementById("taskdatabasechange");
        tabledatabasechangetext.value = tabledatabaseStr;
        var tableserverchangetext = document.getElementById("taskserverchange");
        tableserverchangetext.value = tableserverStr;
        var tableconnectchangetext =
          document.getElementById("taskconnectchange");
        tableconnectchangetext.value = tableconnectStr;

        // get the reference for the body
        var body = document.getElementById("tasktemplatechange");
        // Remove any existing input field.
        while (body.hasChildNodes()) {
          body.removeChild(body.childNodes[0]);
        }

        //populate tablekey
        for (let i = 0; i < tablevalueStr.length; i++) {
          // creates a table row
          var input = document.createElement("input");
          input.type = "text";
          input.id = "column" + (i + 1);
          //input.placeholder = 'column '+(i+1);
          input.value = tablevalueStr[i];
          input.style.height = "35px";
          input.style.fontSize = "14px";
          input.setAttribute("readonly", true);
          body.appendChild(input);
          // Append a line break
          body.appendChild(document.createElement("br"));
        }

        document.getElementById("documenttasks").style.display = "block";
        document.getElementById("querydocumenttasks").style.display = "block";
        document.getElementById("crudtasksactions").style.display = "block";
        document.getElementById("addtasks").style.display = "none";
        document.getElementById("edittasks").style.display = "none";
        document.getElementById("deletetasks").style.display = "none";
        //xxxxxxxxx crudbase xxxxxxxxx
        document.getElementById("newtasks").style.display = "none";
        document.getElementById("createtasks").style.display = "none";
        document.getElementById("doctasks").style.display = "none";
        document.getElementById("listtasks").style.display = "none";
        document.getElementById("previoustasks").style.display = "none";
        document.getElementById("editdeletetasks").style.display = "none";
        document.getElementById("changedeledittasks").style.display = "none";
        document.getElementById("changetasks").style.display = "block";
        document.getElementById("viewtasks").style.display = "none";
        document.getElementById("listviewtasks").style.display = "none";
        document.getElementById("viewdisplaytasks").style.display = "none";
        document.getElementById("viewdoctasks").style.display = "none";
        document.getElementById("changeviewtasks").style.display = "none";
        //xxxxxxx input field
        document.getElementById("tasname").style.display = "none";
        document.getElementById("confirmertask").style.display = "none";
        //xxxxxxxxxxxxxx confirm and undo style
        document.getElementById("selecttasknamechange").style.display = "block";
        document.getElementById("undotasknamechange").style.display = "block";
      }
    };
    tr.appendChild(tdTableServer);

    // Create new table cells for text fields.
    var tdTableDatabase = document.createElement("td");
    tdTableDatabase.innerText = doc.tabledatabase;
    tdTableDatabase.onclick = function (){
      var tableidStr = doc._id;
      var tabledatabaseStr = doc.tabledatabase;
      var tablenameStr = doc.tablename;
      var tableserverStr = doc.tableserver;
      var tablekeyStr = doc.tablekey;
      var tablevalueStr = doc.tablevalue;
      edittablekeyvalue = tablevalueStr;
      var tableconnectStr = doc.tableconnect;
      //check if task is edit,delete or else
      if (typecrud === "edit" || typecrud === "delete") {
        var nametablechangetext = document.getElementById("nametaskeditdelete");
        nametablechangetext.value = tablenameStr;
        var fieldtablechangetext = document.getElementById("fieldtaskeditdelete");
        fieldtablechangetext.value = tablekeyStr.length;
        var tableidchangetext = document.getElementById("taskideditdelete");
        tableidchangetext.value = tableidStr;
        var tabledatabasechangetext = document.getElementById("taskdatabaseeditdelete");
        tabledatabasechangetext.value = tabledatabaseStr;
        var tableserverchangetext = document.getElementById("taskservereditdelete");
        tableserverchangetext.value = tableserverStr;
        var tableconnectchangetext = document.getElementById("taskconnecteditdelete");
        tableconnectchangetext.value = tableconnectStr;

        // get the reference for the body
        var body = document.getElementById("tasktemplateeditdelete");
        // Remove any existing input field.
        while (body.hasChildNodes()) {
          body.removeChild(body.childNodes[0]);
        }

        //populate tablekey
        for (let i = 0; i < tablevalueStr.length; i++) {
          // creates a table row
          var input = document.createElement("input");
          input.type = "text";
          input.id = "column" + (i + 1);
          //input.placeholder = 'column '+(i+1);
          input.value = tablevalueStr[i];
          input.style.height = "35px";
          input.style.fontSize = "14px";
          input.setAttribute("readonly", true);
          body.appendChild(input);
          // Append a line break
          body.appendChild(document.createElement("br"));
        }

        document.getElementById("documenttasks").style.display = "block";
        document.getElementById("querydocumenttasks").style.display = "block";
        document.getElementById("crudtasksactions").style.display = "block";
        document.getElementById("addtasks").style.display = "none";
        document.getElementById("edittasks").style.display = "none";
        document.getElementById("deletetasks").style.display = "none";
        //xxxxxxxxx crudbase xxxxxxxxx
        document.getElementById("newtasks").style.display = "none";
        document.getElementById("createtasks").style.display = "none";
        document.getElementById("doctasks").style.display = "none";
        document.getElementById("listtasks").style.display = "none";
        document.getElementById("previoustasks").style.display = "none";
        document.getElementById("editdeletetasks").style.display = "block";
        document.getElementById("changedeledittasks").style.display = "none";
        document.getElementById("changetasks").style.display = "none";
        document.getElementById("viewtasks").style.display = "none";
        document.getElementById("listviewtasks").style.display = "none";
        document.getElementById("viewdisplaytasks").style.display = "none";
        document.getElementById("viewdoctasks").style.display = "none";
        document.getElementById("changeviewtasks").style.display = "none";
        //xxxxxxx input field
        document.getElementById("tasname").style.display = "none";
        document.getElementById("confirmertask").style.display = "none";
        //xxxxxxxxxxxxxx confirm and undo style
        document.getElementById("selecttasknamechange").style.display = "block";
        document.getElementById("undotasknamechange").style.display = "block";
      } else {
        var nametablechangetext = document.getElementById("nametaskchange");
        nametablechangetext.value = tablenameStr;
        var fieldtablechangetext = document.getElementById("fieldtaskchange");
        fieldtablechangetext.value = tablekeyStr.length;
        var tableidchangetext = document.getElementById("taskidchange");
        tableidchangetext.value = tableidStr;
        var tabledatabasechangetext =
          document.getElementById("taskdatabasechange");
        tabledatabasechangetext.value = tabledatabaseStr;
        var tableserverchangetext = document.getElementById("taskserverchange");
        tableserverchangetext.value = tableserverStr;
        var tableconnectchangetext =
          document.getElementById("taskconnectchange");
        tableconnectchangetext.value = tableconnectStr;

        // get the reference for the body
        var body = document.getElementById("tasktemplatechange");
        // Remove any existing input field.
        while (body.hasChildNodes()) {
          body.removeChild(body.childNodes[0]);
        }

        //populate tablekey
        for (let i = 0; i < tablevalueStr.length; i++) {
          // creates a table row
          var input = document.createElement("input");
          input.type = "text";
          input.id = "column" + (i + 1);
          //input.placeholder = 'column '+(i+1);
          input.value = tablevalueStr[i];
          input.style.height = "35px";
          input.style.fontSize = "14px";
          input.setAttribute("readonly", true);
          body.appendChild(input);
          // Append a line break
          body.appendChild(document.createElement("br"));
        }

        document.getElementById("documenttasks").style.display = "block";
        document.getElementById("querydocumenttasks").style.display = "block";
        document.getElementById("crudtasksactions").style.display = "block";
        document.getElementById("addtasks").style.display = "none";
        document.getElementById("edittasks").style.display = "none";
        document.getElementById("deletetasks").style.display = "none";
        //xxxxxxxxx crudbase xxxxxxxxx
        document.getElementById("newtasks").style.display = "none";
        document.getElementById("createtasks").style.display = "none";
        document.getElementById("doctasks").style.display = "none";
        document.getElementById("listtasks").style.display = "none";
        document.getElementById("previoustasks").style.display = "none";
        document.getElementById("editdeletetasks").style.display = "none";
        document.getElementById("changedeledittasks").style.display = "none";
        document.getElementById("changetasks").style.display = "block";
        document.getElementById("viewtasks").style.display = "none";
        document.getElementById("listviewtasks").style.display = "none";
        document.getElementById("viewdisplaytasks").style.display = "none";
        document.getElementById("viewdoctasks").style.display = "none";
        document.getElementById("changeviewtasks").style.display = "none";
        //xxxxxxx input field
        document.getElementById("tasname").style.display = "none";
        document.getElementById("confirmertask").style.display = "none";
        //xxxxxxxxxxxxxx confirm and undo style
        document.getElementById("selecttasknamechange").style.display = "block";
        document.getElementById("undotasknamechange").style.display = "block";
      }
    };
    tr.appendChild(tdTableDatabase);

    // Create new table cells for text fields.
    var tdTableName = document.createElement("td");
    tdTableName.innerText = doc.tablename;
    //var tdIdTableStr = doc.tablename;
    tdTableName.onclick = function () {
      var tableidStr = doc._id;
      var tabledatabaseStr = doc.tabledatabase;
      var tablenameStr = doc.tablename;
      var tableserverStr = doc.tableserver;
      var tablekeyStr = doc.tablekey;
      var tablevalueStr = doc.tablevalue;
      edittablekeyvalue = tablevalueStr;
      var tableconnectStr = doc.tableconnect;
      //check if task is edit,delete or else
      if (typecrud === "edit" || typecrud === "delete") {
        var nametablechangetext = document.getElementById("nametaskeditdelete");
        nametablechangetext.value = tablenameStr;
        var fieldtablechangetext = document.getElementById("fieldtaskeditdelete");
        fieldtablechangetext.value = tablekeyStr.length;
        var tableidchangetext = document.getElementById("taskideditdelete");
        tableidchangetext.value = tableidStr;
        var tabledatabasechangetext = document.getElementById("taskdatabaseeditdelete");
        tabledatabasechangetext.value = tabledatabaseStr;
        var tableserverchangetext = document.getElementById("taskservereditdelete");
        tableserverchangetext.value = tableserverStr;
        var tableconnectchangetext = document.getElementById("taskconnecteditdelete");
        tableconnectchangetext.value = tableconnectStr;

        // get the reference for the body
        var body = document.getElementById("tasktemplateeditdelete");
        // Remove any existing input field.
        while (body.hasChildNodes()) {
          body.removeChild(body.childNodes[0]);
        }

        //populate tablekey
        for (let i = 0; i < tablevalueStr.length; i++) {
          // creates a table row
          var input = document.createElement("input");
          input.type = "text";
          input.id = "column" + (i + 1);
          //input.placeholder = 'column '+(i+1);
          input.value = tablevalueStr[i];
          input.style.height = "35px";
          input.style.fontSize = "14px";
          input.setAttribute("readonly", true);
          body.appendChild(input);
          // Append a line break
          body.appendChild(document.createElement("br"));
        }

        document.getElementById("documenttasks").style.display = "block";
        document.getElementById("querydocumenttasks").style.display = "block";
        document.getElementById("crudtasksactions").style.display = "block";
        document.getElementById("addtasks").style.display = "none";
        document.getElementById("edittasks").style.display = "none";
        document.getElementById("deletetasks").style.display = "none";
        //xxxxxxxxx crudbase xxxxxxxxx
        document.getElementById("newtasks").style.display = "none";
        document.getElementById("createtasks").style.display = "none";
        document.getElementById("doctasks").style.display = "none";
        document.getElementById("listtasks").style.display = "none";
        document.getElementById("previoustasks").style.display = "none";
        document.getElementById("editdeletetasks").style.display = "block";
        document.getElementById("changedeledittasks").style.display = "none";
        document.getElementById("changetasks").style.display = "none";
        document.getElementById("viewtasks").style.display = "none";
        document.getElementById("listviewtasks").style.display = "none";
        document.getElementById("viewdisplaytasks").style.display = "none";
        document.getElementById("viewdoctasks").style.display = "none";
        document.getElementById("changeviewtasks").style.display = "none";
        //xxxxxxx input field
        document.getElementById("tasname").style.display = "none";
        document.getElementById("confirmertask").style.display = "none";
        //xxxxxxxxxxxxxx confirm and undo style
        document.getElementById("selecttasknamechange").style.display = "block";
        document.getElementById("undotasknamechange").style.display = "block";
      } else {
        var nametablechangetext = document.getElementById("nametaskchange");
        nametablechangetext.value = tablenameStr;
        var fieldtablechangetext = document.getElementById("fieldtaskchange");
        fieldtablechangetext.value = tablekeyStr.length;
        var tableidchangetext = document.getElementById("taskidchange");
        tableidchangetext.value = tableidStr;
        var tabledatabasechangetext = document.getElementById("taskdatabasechange");
        tabledatabasechangetext.value = tabledatabaseStr;
        var tableserverchangetext = document.getElementById("taskserverchange");
        tableserverchangetext.value = tableserverStr;
        var tableconnectchangetext = document.getElementById("taskconnectchange");
        tableconnectchangetext.value = tableconnectStr;

        // get the reference for the body
        var body = document.getElementById("tasktemplatechange");
        // Remove any existing input field.
        while (body.hasChildNodes()) {
          body.removeChild(body.childNodes[0]);
        }

        //populate tablekey
        for (let i = 0; i < tablevalueStr.length; i++) {
          // creates a table row
          var input = document.createElement("input");
          input.type = "text";
          input.id = "column" + (i + 1);
          //input.placeholder = 'column '+(i+1);
          input.value = tablevalueStr[i];
          input.style.height = "35px";
          input.style.fontSize = "14px";
          input.setAttribute("readonly", true);
          body.appendChild(input);
          // Append a line break
          body.appendChild(document.createElement("br"));
        }

        document.getElementById("documenttasks").style.display = "block";
        document.getElementById("querydocumenttasks").style.display = "block";
        document.getElementById("crudtasksactions").style.display = "block";
        document.getElementById("addtasks").style.display = "none";
        document.getElementById("edittasks").style.display = "none";
        document.getElementById("deletetasks").style.display = "none";
        //xxxxxxxxx crudbase xxxxxxxxx
        document.getElementById("newtasks").style.display = "none";
        document.getElementById("createtasks").style.display = "none";
        document.getElementById("doctasks").style.display = "none";
        document.getElementById("listtasks").style.display = "none";
        document.getElementById("previoustasks").style.display = "none";
        document.getElementById("editdeletetasks").style.display = "none";
        document.getElementById("changedeledittasks").style.display = "none";
        document.getElementById("changetasks").style.display = "block";
        document.getElementById("viewtasks").style.display = "none";
        document.getElementById("listviewtasks").style.display = "none";
        document.getElementById("viewdisplaytasks").style.display = "none";
        document.getElementById("viewdoctasks").style.display = "none";
        document.getElementById("changeviewtasks").style.display = "none";
        //xxxxxxx input field
        document.getElementById("tasname").style.display = "none";
        document.getElementById("confirmertask").style.display = "none";
        //xxxxxxxxxxxxxx confirm and undo style
        document.getElementById("selecttasknamechange").style.display = "block";
        document.getElementById("undotasknamechange").style.display = "block";
      }
    };
    tr.appendChild(tdTableName);

    // Add the row to the top of the table.
    crudTaskTableBody.insertBefore(tr, crudTaskTableBody.nextSibling);
  }
  /**Clear and update the UI to display the docs.*/
  function updateTaskTableUIWithDocs(docs) {
    var crudTaskTableBody = document.getElementById("crudTaskTableBody");

    // Remove all child nodes from the table
    while (crudTaskTableBody.hasChildNodes()) {
      crudTaskTableBody.removeChild(crudTaskTableBody.childNodes[0]);
    }

    // Add the new documents to the div
    for (var i = 0; i < docs.length; i++) {
      var doc = docs[i];
      updateTaskDocToToTable(doc, crudTaskTableBody);
    }
  }

  //global arrays variable to edit
  var taskkeyedit = [];
  var taskvalueedit = [];
  //retreive the table input field dynamically to fill data in the table
  document.getElementById("selecttasknameeditdelete").addEventListener("click", selecttasknameeditdeleteChange);
  async function selecttasknameeditdeleteChange(e) {
    e.preventDefault();

    //alert
    var elementOutId = "modaloutalerttasks";
    var elementDropId = "backdropoutalerttasks";
    var buttonHandler = "ouiHandlerAlertTasksBtn";

    var hostnametaskStr = document.getElementById("hostnametaskchange").value;
    var hostporttaskStr = document.getElementById("hostporttaskchange").value;
    var usernametaskStr = document.getElementById("usernametaskchange").value;
    var passwordnametaskStr = document.getElementById("passwordnametaskchange").value;
    //var tasknameStr = document.getElementById('taskname').value;
    var serveurHeaderStr = document.getElementById("serveurheader").innerText;
    var deviceStr = document.getElementById('device').innerText;
    //xxxxxxxxxxxxxxxxxxx
    var nametaskchangeStr = document.getElementById("nametaskeditdelete").value.toLocaleLowerCase();
    var taskidchangeStr = document.getElementById("taskideditdelete").value;
    var taskdatabasechangeStr = document.getElementById("tasknamechange").value; //from newtasks component
    var taskserverchangeStr = document.getElementById("taskservereditdelete").value;
    var taskconnectchangeStr = document.getElementById("taskconnecteditdelete").value;
    //xxxxxxxxxxxxxx
    var localnamebaseStr = "";
    //var servernameStr = 'database';
    if (serveurHeaderStr === "local") {
      localnamebaseStr = "local";
    } else if (serveurHeaderStr === "remote") {
      localnamebaseStr = "remote";
    }

    //code here
    if((deviceStr === '' && localnamebaseStr === 'local') || 
    (deviceStr === '' && localnamebaseStr === 'remote') || (deviceStr === 'mobile' && localnamebaseStr === 'remote')){
      const db = new PouchDB(localnamebaseStr, { skip_setup: true });
      const remote = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +localnamebaseStr,{ skip_setup: true });
      PouchDB.plugin(PouchFind);
      //test if hostname and portnumber are true
      var http_request = new XMLHttpRequest();
      http_request.open("GET","http://" + hostnametaskStr + ":" + hostporttaskStr + "/_utils/",true);
      http_request.onreadystatechange = async function () {
        if(http_request.readyState === 4){
          if(http_request.status === 200){
            function sync() {
              var opts = {
                live: true,
                retry: true,
                continuous: true,
                attachments: true,
              };
              db.sync(remote, opts);
            }
            if (remote) {
              sync();
            }
            //end sync
            var docsCheck = [];
            try {
              let result = await db.createIndex({
                index: {
                  fields: ["hostname", "portname", "username", "passwordname"],
                },
              });
              result = await db.find({
                selector: {
                  hostname: hostnametaskStr,
                  portname: hostporttaskStr,
                  username: usernametaskStr,
                  passwordname: passwordnametaskStr,
                },
              });
              docsCheck = result.docs;
              var resultatCheck = docsCheck.length;
              if (resultatCheck === "") {
                var pTexteincorrectconnection = "Name or password is incorrect.";
                alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
              } else {
                var docsCheckDatabase = [];
                let resultDatabase = await db.createIndex({
                  index: {
                    fields: ["_id"],
                  },
                });
                resultDatabase = await db.find({
                  selector: {
                    _id: taskidchangeStr,
                  },
                });
                docsCheckDatabase = resultDatabase.docs;
                var resultatCheckDatabase = docsCheckDatabase.length;
                if (resultatCheckDatabase === 0) {
                  var pTextenotable = "Table template " + nametaskchangeStr + " not available";
                  alertModal(elementOutId,elementDropId,pTextenotable,buttonHandler);
                } else {
                  //search in local / remote database documents
                  const dbbase = new PouchDB(taskdatabasechangeStr, {skip_setup: true,});
                  const remotebase = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +taskdatabasechangeStr,{ skip_setup: true });
                  PouchDB.plugin(PouchFind);
                  // Initialise a sync with the remote server
                  function sync() {
                    var opts = {
                      live: true,
                      retry: true,
                      continuous: true,
                      attachments: true,
                    };
                    dbbase.sync(remotebase, opts);
                  }
                  if (remotebase) {
                    sync();
                  }
                  var docsCheckDocument = [];
                  let result = await dbbase.createIndex({
                    index: {
                      fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "taskconnect",
                      ],
                    },
                  });
                  result = await dbbase.find({
                    selector: {
                      taskdatabase: taskdatabasechangeStr,
                      taskname: nametaskchangeStr,
                      taskserver: taskserverchangeStr,
                      taskconnect: taskconnectchangeStr,
                    },
                  });
                  docsCheckDocument = result.docs;
                  var resultatCheckDocument = docsCheckDocument.length;
                  if (resultatCheckDocument === 0) {
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                  } else {
                    document.getElementById("documenttasks").style.display = "block";
                    document.getElementById("querydocumenttasks").style.display = "block";
                    document.getElementById("crudtasksactions").style.display = "block";
                    document.getElementById("addtasks").style.display = "none";
                    document.getElementById("edittasks").style.display = "none";
                    document.getElementById("deletetasks").style.display = "none";
                    //xxxxxxxxx crudbase xxxxxxxxx
                    editdeleteSelectDocumentTaskTableUIWithDocs(docsCheckDocument);
                    document.getElementById("newtasks").style.display = "none";
                    document.getElementById("createtasks").style.display = "none";
                    document.getElementById("doctasks").style.display = "block";
                    document.getElementById("listtasks").style.display = "none";
                    document.getElementById("previoustasks").style.display = "none";
                    document.getElementById("editdeletetasks").style.display = "none";
                    document.getElementById("changedeledittasks").style.display = "none";
                    document.getElementById("changetasks").style.display = "none";
                    document.getElementById("viewtasks").style.display = "none";
                    document.getElementById("listviewtasks").style.display = "none";
                    document.getElementById("viewdisplaytasks").style.display = "none";
                    document.getElementById("viewdoctasks").style.display = "none";
                    document.getElementById("changeviewtasks").style.display = "none";
                    //xxxxxxx input field
                    document.getElementById("tasname").style.display = "none";
                    document.getElementById("confirmertask").style.display = "none";
                    //xxxxxxxxxxxxxx confirm and undo style
                    document.getElementById("selecttasknamechange").style.display = "none";
                    document.getElementById("undotasknamechange").style.display = "none";
                  }
                }
              }
            } catch (error) {
              var pTexteerreurconnection = "An Error has Occurred";
              alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
          } else {
            var pTextefailedconnect = "Connection Failed.";
            alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
          }
        }
      };
      http_request.send();
    };

    if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
      const db = new PouchDB(localnamebaseStr, { skip_setup: true });
      PouchDB.plugin(PouchFind);
      var docsCheck = [];
      try {
        let result = await db.createIndex({
          index: {
            fields: [
              "servername"
            ],
          },
        });
        result = await db.find({
          selector: {
            servername: serveurHeaderStr
          },
        });
        docsCheck = result.docs;
        var resultatCheck = docsCheck.length;
        if (resultatCheck === 0){
          var pTextenotexistaddnew = "Database not exists. Create it first";
          alertModal(elementOutId,elementDropId,pTextenotexistaddnew,buttonHandler);
        }else{
          var docsCheckDatabase = [];
          let resultDatabase = await db.createIndex({
            index: {
              fields: ["_id"],
            },
          });
          resultDatabase = await db.find({
            selector: {
              _id: taskidchangeStr,
            },
          });
          docsCheckDatabase = resultDatabase.docs;
          var resultatCheckDatabase = docsCheckDatabase.length;
          if (resultatCheckDatabase === 0){
            var pTextenotable = "Table template " + nametaskchangeStr + " not available";
            alertModal(elementOutId,elementDropId,pTextenotable,buttonHandler);
          }else{
            const dbbase = new PouchDB(serveurHeaderStr, {skip_setup: true,});
            PouchDB.plugin(PouchFind);
            var docsCheckDocument = [];
            let result = await dbbase.createIndex({
              index: {
                fields: [
                  "taskdatabase",
                  "taskname",
                  "taskserver",
                  "taskconnect",
                ],
              },
            });
            result = await dbbase.find({
              selector: {
                taskdatabase: serveurHeaderStr,
                taskname: nametaskchangeStr,
                taskserver: taskserverchangeStr,
                taskconnect: taskconnectchangeStr,
              },
            });
            docsCheckDocument = result.docs;
            var resultatCheckDocument = docsCheckDocument.length;
            if (resultatCheckDocument === 0){
              var pTextenodocument = "No Document available";
              alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
            }else{
              document.getElementById("documenttasks").style.display = "block";
              document.getElementById("querydocumenttasks").style.display = "block";
              document.getElementById("crudtasksactions").style.display = "block";
              document.getElementById("addtasks").style.display = "none";
              document.getElementById("edittasks").style.display = "none";
              document.getElementById("deletetasks").style.display = "none";
              //xxxxxxxxx crudbase xxxxxxxxx
              editdeleteSelectDocumentTaskTableUIWithDocs(docsCheckDocument);
              document.getElementById("newtasks").style.display = "none";
              document.getElementById("createtasks").style.display = "none";
              document.getElementById("doctasks").style.display = "block";
              document.getElementById("listtasks").style.display = "none";
              document.getElementById("previoustasks").style.display = "none";
              document.getElementById("editdeletetasks").style.display = "none";
              document.getElementById("changedeledittasks").style.display = "none";
              document.getElementById("changetasks").style.display = "none";
              document.getElementById("viewtasks").style.display = "none";
              document.getElementById("listviewtasks").style.display = "none";
              document.getElementById("viewdisplaytasks").style.display = "none";
              document.getElementById("viewdoctasks").style.display = "none";
              document.getElementById("changeviewtasks").style.display = "none";
              //xxxxxxx input field
              document.getElementById("tasname").style.display = "none";
              document.getElementById("confirmertask").style.display = "none";
              //xxxxxxxxxxxxxx confirm and undo style
              document.getElementById("selecttasknamechange").style.display = "none";
              document.getElementById("undotasknamechange").style.display = "none";
            }
          }
        }
      } catch (error) {
        var pTexteerreurconnection = "An Error has Occurred";
        alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
      }
    }
  }
  // Adds the document to the HTML First table.
  // If the document already exists in the table, it will be updated.
  function selecteditdeleteTaskDocToToTable(doc, documentTaskTableBody) {
    // Each row of the table has an id attribute set to the doc._id field.
    var tr = documentTaskTableBody.querySelector(`#${doc._id}`);

    if (!tr) {
      tr = document.createElement("tr");
      tr.id = doc._id;
    }

    // Remove any existing table cells.
    while (tr.hasChildNodes()) {
      tr.removeChild(tr.childNodes[0]);
    }

    // Create new table cells for text fields.
    var tdTemplate = document.createElement("td");
    tdTemplate.innerText = doc.taskname;
    //var tableNameStr = doc.tablename;
    tdTemplate.onclick = async function () {
      var taskidStr = doc._id;
      var taskdatabaseStr = doc.taskdatabase;
      var tasknameStr = doc.taskname;
      var taskserverStr = doc.taskserver;
      var documentnameStr = doc.documentname;
      var documentnumberStr = doc.documentnumber;
      var taskdataStr = doc.taskdata;
      var taskconnectStr = doc.taskconnect;
      var documentdateStr = doc.documentdate;
      //assign value to global arrays variable
      taskkeyedit = taskdataStr;
      var nametaskchangedeledittext = document.getElementById("nametaskchangedeledit");
      nametaskchangedeledittext.value = documentnameStr;
      var fieldtaskchangedeledittext = document.getElementById("fieldtaskchangedeledit");
      fieldtaskchangedeledittext.value = documentnumberStr;
      var taskidchangedeledittext = document.getElementById("taskidchangedeledit");
      taskidchangedeledittext.value = taskidStr;
      var taskdatabasechangedeledittext = document.getElementById("taskdatabasechangedeledit");
      taskdatabasechangedeledittext.value = taskdatabaseStr;
      var tasknamechangedeledittext = document.getElementById("tasknamechangedeledit");
      tasknamechangedeledittext.value = tasknameStr;
      var taskserverchangedeledittext = document.getElementById("taskserverchangedeledit");
      taskserverchangedeledittext.value = taskserverStr;
      var taskconnectchangedeledittext = document.getElementById("taskconnectchangedeledit");
      taskconnectchangedeledittext.value = taskconnectStr;
      var taskdatechangedeledittext = document.getElementById("taskdatechangedeledit");
      taskdatechangedeledittext.value = documentdateStr;

      // get the reference for the body
      var body = document.getElementById("tasktemplatechangedeledit");
      // Remove any existing input field.
      while (body.hasChildNodes()) {
        body.removeChild(body.childNodes[0]);
      }

      //get json array column
      const tableData = [taskkeyedit];
      const column = Object.keys(tableData[0]);
      const row = Object.values(tableData[0]);
      // get table heading data
      const columnData = () => {
        return column.map((data) => {
          return data;
        });
      };

      var columnDonnee = columnData();

      // get row data
      const rowData = () => {
        return row.map((v) => {
          return v;
        });
      };

      var rowDonnee = rowData();

      //populate tablekey
      for (let i = 0; i < column.length; i++) {
        var divcontainer = document.createElement("div");
        divcontainer.innerHTML = `
        <form>
          <div id="table_scroller">
            <label id="label_table" type="text">${edittablekeyvalue[i]}</label>
            &nbsp;&nbsp;
            <input class="input_table" id="${"columns" + (i + 1)}" value="${rowDonnee[i]}" type="text" />
          </div>
          <br/>
        </form>`;
        body.appendChild(divcontainer);
      }

      document.getElementById("documenttasks").style.display = "block";
      document.getElementById("querydocumenttasks").style.display = "block";
      document.getElementById("crudtasksactions").style.display = "block";
      document.getElementById("addtasks").style.display = "none";
      document.getElementById("edittasks").style.display = "none";
      document.getElementById("deletetasks").style.display = "none";
      //xxxxxxxxx crudbase xxxxxxxxx
      document.getElementById("newtasks").style.display = "none";
      document.getElementById("createtasks").style.display = "none";
      document.getElementById("doctasks").style.display = "none";
      document.getElementById("listtasks").style.display = "none";
      document.getElementById("previoustasks").style.display = "none";
      document.getElementById("editdeletetasks").style.display = "none";
      document.getElementById("changedeledittasks").style.display = "block";
      document.getElementById("changetasks").style.display = "none";
      document.getElementById("viewtasks").style.display = "none";
      document.getElementById("listviewtasks").style.display = "none";
      document.getElementById("viewdisplaytasks").style.display = "none";
      document.getElementById("viewdoctasks").style.display = "none";
      document.getElementById("changeviewtasks").style.display = "none";
      //xxxxxxx input field
      document.getElementById("tasname").style.display = "none";
      document.getElementById("confirmertask").style.display = "none";
      //xxxxxxxxxxxxxx confirm and undo style
      document.getElementById("selecttasknamechange").style.display = "none";
      document.getElementById("undotasknamechange").style.display = "none";
    };
    tr.appendChild(tdTemplate);

    // Create new table cells for text fields.
    var tdDocument = document.createElement("td");
    tdDocument.innerText = doc.documentname;
    //var tableNameStr = doc.tablename;
    tdDocument.onclick = async function () {
      var taskidStr = doc._id;
      var taskdatabaseStr = doc.taskdatabase;
      var tasknameStr = doc.taskname;
      var taskserverStr = doc.taskserver;
      var documentnameStr = doc.documentname;
      var documentnumberStr = doc.documentnumber;
      var taskdataStr = doc.taskdata;
      var taskconnectStr = doc.taskconnect;
      var documentdateStr = doc.documentdate;
      //assign value to global arrays variable
      taskkeyedit = taskdataStr;
      var nametaskchangedeledittext = document.getElementById("nametaskchangedeledit");
      nametaskchangedeledittext.value = documentnameStr;
      var fieldtaskchangedeledittext = document.getElementById("fieldtaskchangedeledit");
      fieldtaskchangedeledittext.value = documentnumberStr;
      var taskidchangedeledittext = document.getElementById("taskidchangedeledit");
      taskidchangedeledittext.value = taskidStr;
      var taskdatabasechangedeledittext = document.getElementById("taskdatabasechangedeledit");
      taskdatabasechangedeledittext.value = taskdatabaseStr;
      var tasknamechangedeledittext = document.getElementById("tasknamechangedeledit");
      tasknamechangedeledittext.value = tasknameStr;
      var taskserverchangedeledittext = document.getElementById("taskserverchangedeledit");
      taskserverchangedeledittext.value = taskserverStr;
      var taskconnectchangedeledittext = document.getElementById("taskconnectchangedeledit");
      taskconnectchangedeledittext.value = taskconnectStr;
      var taskdatechangedeledittext = document.getElementById("taskdatechangedeledit");
      taskdatechangedeledittext.value = documentdateStr;

      // get the reference for the body
      var body = document.getElementById("tasktemplatechangedeledit");
      // Remove any existing input field.
      while (body.hasChildNodes()) {
        body.removeChild(body.childNodes[0]);
      }

      //get json array column
      const tableData = [taskkeyedit];
      const column = Object.keys(tableData[0]);
      const row = Object.values(tableData[0]);
      // get table heading data
      const columnData = () => {
        return column.map((data) => {
          return data;
        });
      };

      var columnDonnee = columnData();

      // get row data
      const rowData = () => {
        return row.map((v) => {
          return v;
        });
      };

      var rowDonnee = rowData();

      //populate tablekey
      for (let i = 0; i < column.length; i++) {
        var divcontainer = document.createElement("div");
        divcontainer.innerHTML = `
        <form>
          <div id="table_scroller">
            <label id="label_table" type="text">${edittablekeyvalue[i]}</label>
            &nbsp;&nbsp;
            <input class="input_table" id="${"columns" + (i + 1)}" value="${rowDonnee[i]}" type="text" />
          </div>
          <br/>
        </form>`;
        body.appendChild(divcontainer);
      }

      document.getElementById("documenttasks").style.display = "block";
      document.getElementById("querydocumenttasks").style.display = "block";
      document.getElementById("crudtasksactions").style.display = "block";
      document.getElementById("addtasks").style.display = "none";
      document.getElementById("edittasks").style.display = "none";
      document.getElementById("deletetasks").style.display = "none";
      //xxxxxxxxx crudbase xxxxxxxxx
      document.getElementById("newtasks").style.display = "none";
      document.getElementById("createtasks").style.display = "none";
      document.getElementById("doctasks").style.display = "none";
      document.getElementById("listtasks").style.display = "none";
      document.getElementById("previoustasks").style.display = "none";
      document.getElementById("editdeletetasks").style.display = "none";
      document.getElementById("changedeledittasks").style.display = "block";
      document.getElementById("changetasks").style.display = "none";
      document.getElementById("viewtasks").style.display = "none";
      document.getElementById("listviewtasks").style.display = "none";
      document.getElementById("viewdisplaytasks").style.display = "none";
      document.getElementById("viewdoctasks").style.display = "none";
      document.getElementById("changeviewtasks").style.display = "none";
      //xxxxxxx input field
      document.getElementById("tasname").style.display = "none";
      document.getElementById("confirmertask").style.display = "none";
      //xxxxxxxxxxxxxx confirm and undo style
      document.getElementById("selecttasknamechange").style.display = "none";
      document.getElementById("undotasknamechange").style.display = "none";
    };
    tr.appendChild(tdDocument);

    // Add the row to the top of the table.
    documentTaskTableBody.insertBefore(tr, documentTaskTableBody.nextSibling);
  }
  /**Clear and update the UI to display the docs.*/
  function editdeleteSelectDocumentTaskTableUIWithDocs(docs) {
    var documentTaskTableBody = document.getElementById("documentTaskTableBody");

    // Remove all child nodes from the table
    while (documentTaskTableBody.hasChildNodes()) {
      documentTaskTableBody.removeChild(documentTaskTableBody.childNodes[0]);
    }

    // Add the new documents to the div
    for (var i = 0; i < docs.length; i++) {
      var doc = docs[i];
      selecteditdeleteTaskDocToToTable(doc, documentTaskTableBody);
    }
  } ////

  //Undo table template selected
  document.getElementById("undotasknameeditdelete").addEventListener("click", undotasknameeditdeleteChange);
  async function undotasknameeditdeleteChange(e) {
    e.preventDefault();

    document.getElementById("documenttasks").style.display = "block";
    document.getElementById("querydocumenttasks").style.display = "block";
    document.getElementById("crudtasksactions").style.display = "block";
    document.getElementById("addtasks").style.display = "none";
    document.getElementById("edittasks").style.display = "none";
    document.getElementById("deletetasks").style.display = "none";
    //xxxxxxxxx crudbase xxxxxxxx
    document.getElementById("newtasks").style.display = "none";
    document.getElementById("createtasks").style.display = "none";
    document.getElementById("doctasks").style.display = "none";
    document.getElementById("listtasks").style.display = "block";
    document.getElementById("previoustasks").style.display = "none";
    document.getElementById("editdeletetasks").style.display = "none";
    document.getElementById("changedeledittasks").style.display = "none";
    document.getElementById("changetasks").style.display = "none";
    document.getElementById("viewtasks").style.display = "none";
    document.getElementById("listviewtasks").style.display = "none";
    document.getElementById("viewdisplaytasks").style.display = "none";
    document.getElementById("viewdoctasks").style.display = "none";
    document.getElementById("changeviewtasks").style.display = "none";
    //xxxxxxx input field
    document.getElementById("tasname").style.display = "block";
    document.getElementById("confirmertask").style.display = "block";
  }

  //Cancel table template selected
  document.getElementById("canceltasknamechangedeledit").addEventListener("click", canceltasknamechangedeleditChange);
  async function canceltasknamechangedeleditChange(e) {
    e.preventDefault();

    document.getElementById("documenttasks").style.display = "block";
    document.getElementById("querydocumenttasks").style.display = "block";
    document.getElementById("crudtasksactions").style.display = "block";
    document.getElementById("addtasks").style.display = "none";
    document.getElementById("edittasks").style.display = "none";
    document.getElementById("deletetasks").style.display = "none";
    //xxxxxxxxx crudbase xxxxxxxx
    document.getElementById("newtasks").style.display = "none";
    document.getElementById("createtasks").style.display = "none";
    document.getElementById("doctasks").style.display = "block";
    document.getElementById("listtasks").style.display = "none";
    document.getElementById("previoustasks").style.display = "none";
    document.getElementById("editdeletetasks").style.display = "none";
    document.getElementById("changedeledittasks").style.display = "none";
    document.getElementById("changetasks").style.display = "none";
    document.getElementById("viewtasks").style.display = "none";
    document.getElementById("listviewtasks").style.display = "none";
    document.getElementById("viewdisplaytasks").style.display = "none";
    document.getElementById("viewdoctasks").style.display = "none";
    document.getElementById("changeviewtasks").style.display = "none";
    //xxxxxxx input field
    document.getElementById("tasname").style.display = "block";
    document.getElementById("confirmertask").style.display = "block";
  }

  document.getElementById("confirmtasknamechangedeledit").addEventListener("click", confirmtasknamechangedeleditChange);
  async function confirmtasknamechangedeleditChange(e) {
    e.preventDefault();

    document.getElementById("documenttasks").style.display = "block";
    document.getElementById("querydocumenttasks").style.display = "block";
    document.getElementById("crudtasksactions").style.display = "block";
    document.getElementById("addtasks").style.display = "none";
    if (typecrud === "edit") {
      document.getElementById("edittasks").style.display = "block";
      document.getElementById("deletetasks").style.display = "none";
    } else if (typecrud === "delete") {
      document.getElementById("edittasks").style.display = "none";
      document.getElementById("deletetasks").style.display = "block";
    }
    //xxxxxxxxx crudbase xxxxxxxxx
    document.getElementById("newtasks").style.display = "none";
    document.getElementById("createtasks").style.display = "none";
    document.getElementById("doctasks").style.display = "none";
    document.getElementById("listtasks").style.display = "none";
    document.getElementById("previoustasks").style.display = "none";
    document.getElementById("editdeletetasks").style.display = "none";
    document.getElementById("changedeledittasks").style.display = "block";
    document.getElementById("changetasks").style.display = "none";
    document.getElementById("viewtasks").style.display = "none";
    document.getElementById("listviewtasks").style.display = "none";
    document.getElementById("viewdisplaytasks").style.display = "none";
    document.getElementById("viewdoctasks").style.display = "none";
    document.getElementById("changeviewtasks").style.display = "none";
    //xxxxxxx input field
    document.getElementById("tasname").style.display = "none";
    document.getElementById("confirmertask").style.display = "none";
    //xxxxxxxxxxxxxx confirm and undo style
    document.getElementById("selecttasknamechange").style.display = "none";
    document.getElementById("undotasknamechange").style.display = "none";
  }

  //Crud tasks (edit)
  document.getElementById("edittasks").addEventListener("click", edittasksChange);
  async function edittasksChange(e) {
    e.preventDefault();

    //alert
    var elementOutId = "modaloutalerttasks";
    var elementDropId = "backdropoutalerttasks";
    var buttonHandler = "ouiHandlerAlertTasksBtn";

    var deviceStr = document.getElementById('device').innerText;
    var serveurHeaderStr = document.getElementById('serveurheader').innerText;

    if((deviceStr === '' && serveurHeaderStr === 'local') || 
    (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
      var hostnametaskStr = document.getElementById("hostnametaskchange").value;
      var hostporttaskStr = document.getElementById("hostporttaskchange").value;
      var usernametaskStr = document.getElementById("usernametaskchange").value;
      var passwordnametaskStr = document.getElementById("passwordnametaskchange").value;
      //var serveurHeaderStr = document.getElementById('serveurheader').innerText;
      //xxxxxxxxxxxxxx
      var nametaskchangedeleditStr = document.getElementById("nametaskchangedeledit").value.toLocaleLowerCase(); //doc name
      var fieldtaskchangedeleditStr = document.getElementById("fieldtaskchangedeledit").value; //doc number
      var taskidchangedeleditStr = document.getElementById("taskidchangedeledit").value; //doc id
      var taskdatabasechangedeleditStr = document.getElementById("taskdatabasechangedeledit").value; //doc database
      var tasknamechangedeleditStr = document.getElementById("tasknamechangedeledit").value; // doc table template name
      var taskserverchangedeleditStr = document.getElementById("taskserverchangedeledit").value; //doc server name
      var taskconnectchangedeleditStr = document.getElementById("taskconnectchangedeledit").value; //doc connection name
      var taskdatechangedeleditStr = document.getElementById("taskdatechangedeledit").value; //doc date
      //xxxxxxxxxxxxxx
      var tabletemplatenumberStr = document.getElementById("fieldtaskeditdelete").value; //table template fields number
      //xxxxxxxxxxxxxx
      var numbercol = 0;

      const dbbasedoc = new PouchDB(taskdatabasechangedeleditStr, {skip_setup: true,});
      const remotebasedoc = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +taskdatabasechangedeleditStr,{ skip_setup: true });
      PouchDB.plugin(PouchFind);

      var taskkeydoc = [];
      var taskvalue = [];
      try {
        taskkeydoc = taskvalueedit;
        taskkeydoc = taskkeyedit;
        //retrieve all data inserted in dynamic input field threated
        numbercol = parseFloat(tabletemplatenumberStr);
        for (let j = 0; j < numbercol; j++) {
          var idfield = document.getElementById("columns" + (j + 1)).value.toLocaleLowerCase();
          taskvalue.push(idfield);
        }

        //get json array column
        const tableData = [taskkeydoc];
        const column = Object.keys(tableData[0]);
        // get table heading data
        const columnData = () => {
          return column.map((data) => {
            return data;
          });
        };

        var columnDonnee = columnData();
        const taskdataedit = {};
        columnDonnee.forEach((element, index) => {
          taskdataedit[element] = taskvalue[index];
        })

        //start editing document
        var documentID = taskidchangedeleditStr;
        //edit document in local and remote database
        // Retrieve the document to get the latest revision
        var docModifier = await dbbasedoc.get(documentID);
        // Make changes
        docModifier.taskdatabase = taskdatabasechangedeleditStr;
        docModifier.taskname = tasknamechangedeleditStr;
        docModifier.taskserver = taskserverchangedeleditStr;
        docModifier.documentname = nametaskchangedeleditStr;
        docModifier.documentnumber = fieldtaskchangedeleditStr;
        docModifier.taskdata = taskdataedit;
        docModifier.taskconnect = taskconnectchangedeleditStr;
        docModifier.documentdate = taskdatechangedeleditStr;
        // Put the document, saving it as a new revision
        var responseModifier = await dbbasedoc.put(docModifier);
        // Get the document we change and update the UI.
        var docEdit = await dbbasedoc.get(responseModifier.id);
        if(docEdit !== ''){
          //Initialise a sync with the remote server
          function sync(){
            var opts = {live: true, retry: true, continuous: true, attachments: true,};
            dbbasedoc.sync(remotebasedoc, opts);
          }
          if(remotebasedoc){
            sync();
          }
          var pTextenewdatabase = "Document "+nametaskchangedeleditStr+" "+fieldtaskchangedeleditStr+" Updated Succesfully";
          alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
          //xxxxxxxxxxxxxx
          document.getElementById('documenttasks').style.display = 'block';
          document.getElementById('querydocumenttasks').style.display = 'block';
          document.getElementById('crudtasksactions').style.display = 'block';
          document.getElementById('addtasks').style.display = 'none';
          document.getElementById('edittasks').style.display = 'none';
          document.getElementById('deletetasks').style.display = 'none';
          //xxxxxxxxx crudbase xxxxxxxx
          document.getElementById('newtasks').style.display = 'none';
          document.getElementById('createtasks').style.display = 'none';
          document.getElementById('doctasks').style.display = 'block';
          document.getElementById('listtasks').style.display = 'none';
          document.getElementById('previoustasks').style.display = 'none';
          document.getElementById('editdeletetasks').style.display = 'none';
          document.getElementById('changedeledittasks').style.display = 'none';
          document.getElementById('changetasks').style.display = 'none';
          document.getElementById('viewtasks').style.display = 'none';
          document.getElementById('listviewtasks').style.display = 'none';
          document.getElementById('viewdisplaytasks').style.display = 'none';
          document.getElementById('viewdoctasks').style.display = 'none';
          document.getElementById('changeviewtasks').style.display = 'none';
          //xxxxxxx input field
          document.getElementById('tasname').style.display = 'none';
          document.getElementById('confirmertask').style.display = 'none';
        }else{
          var pTexteerreuraddnew = "Update document failed";
          alertModal(elementOutId,elementDropId,pTexteerreuraddnew,buttonHandler);
          //xxxxxxxxxxxxxx
          document.getElementById('documenttasks').style.display = 'block';
          document.getElementById('querydocumenttasks').style.display = 'block';
          document.getElementById('crudtasksactions').style.display = 'block';
          document.getElementById('addtasks').style.display = 'none';
          document.getElementById('edittasks').style.display = 'none';
          document.getElementById('deletetasks').style.display = 'none';
          //xxxxxxxxx crudbase xxxxxxxx
          document.getElementById('newtasks').style.display = 'none';
          document.getElementById('createtasks').style.display = 'none';
          document.getElementById('doctasks').style.display = 'block';
          document.getElementById('listtasks').style.display = 'none';
          document.getElementById('previoustasks').style.display = 'none';
          document.getElementById('editdeletetasks').style.display = 'none';
          document.getElementById('changedeledittasks').style.display = 'none';
          document.getElementById('changetasks').style.display = 'none';
          document.getElementById('viewtasks').style.display = 'none';
          document.getElementById('listviewtasks').style.display = 'none';
          document.getElementById('viewdisplaytasks').style.display = 'none';
          document.getElementById('viewdoctasks').style.display = 'none';
          document.getElementById('changeviewtasks').style.display = 'none';
          //xxxxxxx input field
          document.getElementById('tasname').style.display = 'none';
          document.getElementById('confirmertask').style.display = 'none';
        }
      } catch (error) {
        var pTexteerreurconnection = "An Error has Occurred";
        alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
      }
    };
    
    if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
      var nametaskchangedeleditStr = document.getElementById("nametaskchangedeledit").value.toLocaleLowerCase(); //doc name
      var fieldtaskchangedeleditStr = document.getElementById("fieldtaskchangedeledit").value; //doc number
      var taskidchangedeleditStr = document.getElementById("taskidchangedeledit").value; //doc id
      var taskdatabasechangedeleditStr = document.getElementById("taskdatabasechangedeledit").value; //doc database
      var tasknamechangedeleditStr = document.getElementById("tasknamechangedeledit").value; // doc table template name
      var taskserverchangedeleditStr = document.getElementById("taskserverchangedeledit").value; //doc server name
      var taskconnectchangedeleditStr = document.getElementById("taskconnectchangedeledit").value; //doc connection name
      var taskdatechangedeleditStr = document.getElementById("taskdatechangedeledit").value; //doc date
      //xxxxxxxxxxxxxx
      var tabletemplatenumberStr = document.getElementById("fieldtaskeditdelete").value; //table template fields number
      //xxxxxxxxxxxxxx
      var numbercol = 0;

      const dbbasedoc = new PouchDB(serveurHeaderStr, {skip_setup: true,});
      PouchDB.plugin(PouchFind);

      var taskkeydoc = [];
      var taskvalue = [];
      try {
        taskkeydoc = taskvalueedit;
        taskkeydoc = taskkeyedit;
        //retrieve all data inserted in dynamic input field threated
        numbercol = parseFloat(tabletemplatenumberStr);
        for (let j = 0; j < numbercol; j++) {
          var idfield = document.getElementById("columns" + (j + 1)).value.toLocaleLowerCase();
          taskvalue.push(idfield);
        }

        //get json array column
        const tableData = [taskkeydoc];
        const column = Object.keys(tableData[0]);
        // get table heading data
        const columnData = () => {
          return column.map((data) => {
            return data;
          });
        };

        var columnDonnee = columnData();
        const taskdataedit = {};
        columnDonnee.forEach((element, index) => {
          taskdataedit[element] = taskvalue[index];
        })

        //start editing document
        var documentID = taskidchangedeleditStr;
        //edit document in local and remote database
        // Retrieve the document to get the latest revision
        var docModifier = await dbbasedoc.get(documentID);
        // Make changes
        docModifier.taskdatabase = taskdatabasechangedeleditStr;
        docModifier.taskname = tasknamechangedeleditStr;
        docModifier.taskserver = taskserverchangedeleditStr;
        docModifier.documentname = nametaskchangedeleditStr;
        docModifier.documentnumber = fieldtaskchangedeleditStr;
        docModifier.taskdata = taskdataedit;
        docModifier.taskconnect = taskconnectchangedeleditStr;
        docModifier.documentdate = taskdatechangedeleditStr;
        // Put the document, saving it as a new revision
        var responseModifier = await dbbasedoc.put(docModifier);
        // Get the document we change and update the UI.
        var docEdit = await dbbasedoc.get(responseModifier.id);
        if(docEdit !== ''){
          var pTextenewdatabase = "Document "+nametaskchangedeleditStr+" "+fieldtaskchangedeleditStr+" Updated Succesfully";
          alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
          //xxxxxxxxxxxxxx
          document.getElementById('documenttasks').style.display = 'block';
          document.getElementById('querydocumenttasks').style.display = 'block';
          document.getElementById('crudtasksactions').style.display = 'block';
          document.getElementById('addtasks').style.display = 'none';
          document.getElementById('edittasks').style.display = 'none';
          document.getElementById('deletetasks').style.display = 'none';
          //xxxxxxxxx crudbase xxxxxxxx
          document.getElementById('newtasks').style.display = 'none';
          document.getElementById('createtasks').style.display = 'none';
          document.getElementById('doctasks').style.display = 'block';
          document.getElementById('listtasks').style.display = 'none';
          document.getElementById('previoustasks').style.display = 'none';
          document.getElementById('editdeletetasks').style.display = 'none';
          document.getElementById('changedeledittasks').style.display = 'none';
          document.getElementById('changetasks').style.display = 'none';
          document.getElementById('viewtasks').style.display = 'none';
          document.getElementById('listviewtasks').style.display = 'none';
          document.getElementById('viewdisplaytasks').style.display = 'none';
          document.getElementById('viewdoctasks').style.display = 'none';
          document.getElementById('changeviewtasks').style.display = 'none';
          //xxxxxxx input field
          document.getElementById('tasname').style.display = 'none';
          document.getElementById('confirmertask').style.display = 'none';
        }else{
          var pTexteerreuraddnew = "Update document failed";
          alertModal(elementOutId,elementDropId,pTexteerreuraddnew,buttonHandler);
          //xxxxxxxxxxxxxx
          document.getElementById('documenttasks').style.display = 'block';
          document.getElementById('querydocumenttasks').style.display = 'block';
          document.getElementById('crudtasksactions').style.display = 'block';
          document.getElementById('addtasks').style.display = 'none';
          document.getElementById('edittasks').style.display = 'none';
          document.getElementById('deletetasks').style.display = 'none';
          //xxxxxxxxx crudbase xxxxxxxx
          document.getElementById('newtasks').style.display = 'none';
          document.getElementById('createtasks').style.display = 'none';
          document.getElementById('doctasks').style.display = 'block';
          document.getElementById('listtasks').style.display = 'none';
          document.getElementById('previoustasks').style.display = 'none';
          document.getElementById('editdeletetasks').style.display = 'none';
          document.getElementById('changedeledittasks').style.display = 'none';
          document.getElementById('changetasks').style.display = 'none';
          document.getElementById('viewtasks').style.display = 'none';
          document.getElementById('listviewtasks').style.display = 'none';
          document.getElementById('viewdisplaytasks').style.display = 'none';
          document.getElementById('viewdoctasks').style.display = 'none';
          document.getElementById('changeviewtasks').style.display = 'none';
          //xxxxxxx input field
          document.getElementById('tasname').style.display = 'none';
          document.getElementById('confirmertask').style.display = 'none';
        }
      } catch (error) {
        var pTexteerreurconnection = "An Error has Occurred";
        alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
      }
    }
  }

  //Crud tasks (delete)
  document.getElementById("deletetasks").addEventListener("click", deletetasksChange);
  async function deletetasksChange(e) {
    e.preventDefault();

    //alert
    var elementOutId = "modaloutalerttasks";
    var elementDropId = "backdropoutalerttasks";
    var buttonHandler = "ouiHandlerAlertTasksBtn";

    var hostnametaskStr = document.getElementById("hostnametaskchange").value;
    var hostporttaskStr = document.getElementById("hostporttaskchange").value;
    var usernametaskStr = document.getElementById("usernametaskchange").value;
    var passwordnametaskStr = document.getElementById("passwordnametaskchange").value;
    //xxxxxxxxxxxxxx
    var nametaskchangedeleditStr = document.getElementById("nametaskchangedeledit").value.toLocaleLowerCase(); //doc name
    var fieldtaskchangedeleditStr = document.getElementById("fieldtaskchangedeledit").value; //doc number
    var taskidchangedeleditStr = document.getElementById("taskidchangedeledit").value; //doc id
    var taskdatabasechangedeleditStr = document.getElementById("taskdatabasechangedeledit").value; //doc database
    var serveurHeaderStr = document.getElementById('serveurheader').innerText;
    var deviceStr = document.getElementById('device').innerText;

    var divClearModal = document.getElementById("modalouttasks");
    let divModal = document.createElement("div");
    divModal.innerHTML = 
    `<div class="modal">
      <p>This action will permanently delete. Do you want to continue?</p>
        <button class="btn btn--alt" id="ouiHandlerYesDeleteTaskBtn">Yes</button>
        <button class="btn" id="nonHandlerNoDeleteTaskBtn">No</button>
    </div>`;
    divClearModal.appendChild(divModal);

    var divClearBackdrop = document.getElementById("backdropouttasks");
    let divBack = document.createElement("div");
    divBack.innerHTML = `<div class="backdrop" id="backdropHandlerDropDeleteTaskBtn"></div>`;
    divClearBackdrop.appendChild(divBack);

    //If Yes Clicked
    document.getElementById("ouiHandlerYesDeleteTaskBtn").addEventListener("click", ouiHandlerYesDeleteTaskBtnChange);
    async function ouiHandlerYesDeleteTaskBtnChange(e) {
      e.preventDefault();

      if((deviceStr === '' && serveurHeaderStr === 'local') || 
      (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
        const dbbasedoc = new PouchDB(taskdatabasechangedeleditStr, {skip_setup: true,});
        const remotebasedoc = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +taskdatabasechangedeleditStr,{ skip_setup: true });
        PouchDB.plugin(PouchFind);
        //Initialise a sync with the remote server
        function sync() {
          var opts = {live: true,retry: true,continuous: true,attachments: true,};
          dbbasedoc.sync(remotebasedoc, opts);
        }
        if (remotebasedoc) {
          sync();
        }
        try {
          //start deleting document
          var documentID = taskidchangedeleditStr;
          var docSupprimer = await dbbasedoc.get(documentID);
          var responseSupprimer = await dbbasedoc.remove(docSupprimer);
          var pTextenewdatabase = "Document " +nametaskchangedeleditStr +" " +fieldtaskchangedeleditStr +" Deleted Succesfully";
          alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
          //xxxxxxxxxxxxxx
          document.getElementById("documenttasks").style.display = "block";
          document.getElementById("querydocumenttasks").style.display = "block";
          document.getElementById("crudtasksactions").style.display = "block";
          document.getElementById("addtasks").style.display = "none";
          document.getElementById("edittasks").style.display = "none";
          document.getElementById("deletetasks").style.display = "none";
          //xxxxxxxxx crudbase xxxxxxxx
          document.getElementById("newtasks").style.display = "none";
          document.getElementById("createtasks").style.display = "none";
          document.getElementById("doctasks").style.display = "block";
          document.getElementById("listtasks").style.display = "none";
          document.getElementById("previoustasks").style.display = "none";
          document.getElementById("editdeletetasks").style.display = "none";
          document.getElementById("changedeledittasks").style.display = "none";
          document.getElementById("changetasks").style.display = "none";
          document.getElementById("viewtasks").style.display = "none";
          document.getElementById("listviewtasks").style.display = "none";
          document.getElementById("viewdisplaytasks").style.display = "none";
          document.getElementById("viewdoctasks").style.display = "none";
          document.getElementById("changeviewtasks").style.display = "none";
          //xxxxxxx input field
          document.getElementById("tasname").style.display = "none";
          document.getElementById("confirmertask").style.display = "none";
        } catch (error) {
          var pTexteerreurconnection = "An Error has Occurred";
          alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
        }
      };

      if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
        const dbbasedoc = new PouchDB(taskdatabasechangedeleditStr, {skip_setup: true,});
        PouchDB.plugin(PouchFind);
        try {
          //start deleting document
          var documentID = taskidchangedeleditStr;
          var docSupprimer = await dbbasedoc.get(documentID);
          var responseSupprimer = await dbbasedoc.remove(docSupprimer);

          var pTextenewdatabase = "Document " +nametaskchangedeleditStr +" " +fieldtaskchangedeleditStr +" Deleted Succesfully";
          alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
          //xxxxxxxxxxxxxx
          document.getElementById("documenttasks").style.display = "block";
          document.getElementById("querydocumenttasks").style.display = "block";
          document.getElementById("crudtasksactions").style.display = "block";
          document.getElementById("addtasks").style.display = "none";
          document.getElementById("edittasks").style.display = "none";
          document.getElementById("deletetasks").style.display = "none";
          //xxxxxxxxx crudbase xxxxxxxx
          document.getElementById("newtasks").style.display = "none";
          document.getElementById("createtasks").style.display = "none";
          document.getElementById("doctasks").style.display = "block";
          document.getElementById("listtasks").style.display = "none";
          document.getElementById("previoustasks").style.display = "none";
          document.getElementById("editdeletetasks").style.display = "none";
          document.getElementById("changedeledittasks").style.display = "none";
          document.getElementById("changetasks").style.display = "none";
          document.getElementById("viewtasks").style.display = "none";
          document.getElementById("listviewtasks").style.display = "none";
          document.getElementById("viewdisplaytasks").style.display = "none";
          document.getElementById("viewdoctasks").style.display = "none";
          document.getElementById("changeviewtasks").style.display = "none";
          //xxxxxxx input field
          document.getElementById("tasname").style.display = "none";
          document.getElementById("confirmertask").style.display = "none";
        } catch (error) {
          var pTexteerreurconnection = "An Error has Occurred";
          alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
        }
      };

      var divClearModal = document.getElementById("modalouttasks");
      while (divClearModal.hasChildNodes()) {
        divClearModal.removeChild(divClearModal.childNodes[0]);
      }

      var divClearBackdrop = document.getElementById("backdropouttasks");
      while (divClearBackdrop.hasChildNodes()) {
        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
      }
    }

    //If No Clicked
    document.getElementById("nonHandlerNoDeleteTaskBtn").addEventListener("click", nonHandlerNoDeleteTaskBtnChange);
    async function nonHandlerNoDeleteTaskBtnChange(e) {
      e.preventDefault();

      //xxxxxxxxxxxxxx
      document.getElementById("documenttasks").style.display = "block";
      document.getElementById("querydocumenttasks").style.display = "block";
      document.getElementById("crudtasksactions").style.display = "block";
      document.getElementById("addtasks").style.display = "none";
      document.getElementById("edittasks").style.display = "none";
      document.getElementById("deletetasks").style.display = "none";
      //xxxxxxxxx crudbase xxxxxxxx
      document.getElementById("newtasks").style.display = "none";
      document.getElementById("createtasks").style.display = "none";
      document.getElementById("doctasks").style.display = "block";
      document.getElementById("listtasks").style.display = "none";
      document.getElementById("previoustasks").style.display = "none";
      document.getElementById("editdeletetasks").style.display = "none";
      document.getElementById("changedeledittasks").style.display = "none";
      document.getElementById("changetasks").style.display = "none";
      document.getElementById("viewtasks").style.display = "none";
      document.getElementById("listviewtasks").style.display = "none";
      document.getElementById("viewdisplaytasks").style.display = "none";
      document.getElementById("viewdoctasks").style.display = "none";
      document.getElementById("changeviewtasks").style.display = "none";
      //xxxxxxx input field
      document.getElementById("tasname").style.display = "none";
      document.getElementById("confirmertask").style.display = "none";

      var divClearModal = document.getElementById("modalouttasks");
      while (divClearModal.hasChildNodes()) {
        divClearModal.removeChild(divClearModal.childNodes[0]);
      }

      var divClearBackdrop = document.getElementById("backdropouttasks");
      while (divClearBackdrop.hasChildNodes()) {
        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
      }
    }

    //If backdrop Clicked
    document.getElementById("backdropHandlerDropDeleteTaskBtn").addEventListener("click", backdropHandlerDropDeleteTaskBtnChange);
    async function backdropHandlerDropDeleteTaskBtnChange(e) {
      e.preventDefault();

      //xxxxxxxxxxxxxx
      document.getElementById("documenttasks").style.display = "block";
      document.getElementById("querydocumenttasks").style.display = "block";
      document.getElementById("crudtasksactions").style.display = "block";
      document.getElementById("addtasks").style.display = "none";
      document.getElementById("edittasks").style.display = "none";
      document.getElementById("deletetasks").style.display = "none";
      //xxxxxxxxx crudbase xxxxxxxx
      document.getElementById("newtasks").style.display = "none";
      document.getElementById("createtasks").style.display = "none";
      document.getElementById("doctasks").style.display = "block";
      document.getElementById("listtasks").style.display = "none";
      document.getElementById("previoustasks").style.display = "none";
      document.getElementById("editdeletetasks").style.display = "none";
      document.getElementById("changedeledittasks").style.display = "none";
      document.getElementById("changetasks").style.display = "none";
      document.getElementById("viewtasks").style.display = "none";
      document.getElementById("listviewtasks").style.display = "none";
      document.getElementById("viewdisplaytasks").style.display = "none";
      document.getElementById("viewdoctasks").style.display = "none";
      document.getElementById("changeviewtasks").style.display = "none";
      //xxxxxxx input field
      document.getElementById("tasname").style.display = "none";
      document.getElementById("confirmertask").style.display = "none";

      var divClearModal = document.getElementById("modalouttasks");
      while (divClearModal.hasChildNodes()) {
        divClearModal.removeChild(divClearModal.childNodes[0]);
      }

      var divClearBackdrop = document.getElementById("backdropouttasks");
      while (divClearBackdrop.hasChildNodes()) {
        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
      }
    }
  }

  //views table test
  document.getElementById("viewtask").addEventListener("click", viewtaskChange);
  async function viewtaskChange(e) {
    e.preventDefault();

    var deviceStr = document.getElementById('device').innerText;
    var serveurHeaderStr = document.getElementById('serveurheader').innerText;

    typecrud = "view";

    document.getElementById("documenttasks").style.display = "block";
    document.getElementById("querydocumenttasks").style.display = "block";
    document.getElementById("crudtasksactions").style.display = "block";
    document.getElementById("addtasks").style.display = "none";
    document.getElementById("edittasks").style.display = "none";
    document.getElementById("deletetasks").style.display = "none";
    //xxxxxxxxx crudbase xxxxxxxxx
    if((deviceStr === '' && serveurHeaderStr === 'local') || 
    (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
      document.getElementById("newtasks").style.display = "none";
      document.getElementById("createtasks").style.display = "none";
      document.getElementById("doctasks").style.display = "none";
      document.getElementById("listtasks").style.display = "none";
      document.getElementById("previoustasks").style.display = "none";
      document.getElementById("editdeletetasks").style.display = "none";
      document.getElementById("changedeledittasks").style.display = "none";
      document.getElementById("changetasks").style.display = "none";
      document.getElementById("viewtasks").style.display = "block";
      document.getElementById('viewdesktasks').style.display = 'block';
      document.getElementById('viewmobiletasks').style.display = 'none';
      document.getElementById("listviewtasks").style.display = "none";
      document.getElementById("viewdisplaytasks").style.display = "none";
      document.getElementById("viewdoctasks").style.display = "none";
      document.getElementById("changeviewtasks").style.display = "none";
      //xxxxxxx input field
      document.getElementById("tasnameview").style.display = "block";
      //document.getElementById('servertablename').style.display = 'none';
      document.getElementById("confirmertaskview").style.display = "block";
    };
    
    if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
      document.getElementById("newtasks").style.display = "none";
      document.getElementById("createtasks").style.display = "none";
      document.getElementById("doctasks").style.display = "none";
      document.getElementById("listtasks").style.display = "none";
      document.getElementById("previoustasks").style.display = "none";
      document.getElementById("editdeletetasks").style.display = "none";
      document.getElementById("changedeledittasks").style.display = "none";
      document.getElementById("changetasks").style.display = "none";
      document.getElementById("viewtasks").style.display = "block";
      document.getElementById('viewdesktasks').style.display = 'none';
      document.getElementById('viewmobiletasks').style.display = 'block';
      document.getElementById("listviewtasks").style.display = "none";
      document.getElementById("viewdisplaytasks").style.display = "none";
      document.getElementById("viewdoctasks").style.display = "none";
      document.getElementById("changeviewtasks").style.display = "none";
      //xxxxxxx input field
      //document.getElementById("mobiletasnameview").style.display = "block";
      //document.getElementById('servertablename').style.display = 'none';
      document.getElementById("mobileconfirmertaskview").style.display = "block";
    }
  }

  //Crud tasks (confirmertable)
  document.getElementById("confirmtaskview").addEventListener("click", confirmtaskviewChange);
  async function confirmtaskviewChange(e) {
    e.preventDefault();

    //alert
    var elementOutId = "modaloutalerttasks";
    var elementDropId = "backdropoutalerttasks";
    var buttonHandler = "ouiHandlerAlertTaskBtn";

    var hostnametaskStr = document.getElementById("hostnametaskview").value;
    var hostporttaskStr = document.getElementById("hostporttaskview").value;
    var usernametaskStr = document.getElementById("usernametaskview").value;
    var passwordnametaskStr = document.getElementById("passwordnametaskview").value;
    var tasknameStr = document.getElementById("tasknameview").value.toLowerCase();
    var serveurHeaderStr = document.getElementById("serveurheader").innerText;
    var deviceStr = document.getElementById('device').innerText;
    var tabletemplateStr = "template";
    var localnamebaseStr = "";
    var servernameStr = "database";
    if (serveurHeaderStr === "local") {
      localnamebaseStr = "local";
    } else if (serveurHeaderStr === "remote") {
      localnamebaseStr = "remote";
    }
    var localtypebaseStr = "database";

    //check if values not null
    if (!hostnametaskStr) {
      var pTextehostname = "Enter Host Name";
      alertModal(elementOutId, elementDropId, pTextehostname, buttonHandler);
    } else if (!hostporttaskStr) {
      var pTexteportname = "Enter Port Number";
      alertModal(elementOutId, elementDropId, pTexteportname, buttonHandler);
    } else if (!usernametaskStr) {
      var pTexteusername = "Enter Username";
      alertModal(elementOutId, elementDropId, pTexteusername, buttonHandler);
    } else if (!passwordnametaskStr) {
      var pTextepasswordname = "Enter Password";
      alertModal(elementOutId,elementDropId,pTextepasswordname,buttonHandler);
    } else if (!tasknameStr) {
      var pTextedatabasename = "Enter Database Name";
      alertModal(elementOutId,elementDropId,pTextedatabasename,buttonHandler);
    } else {
      var docsCheckConfirm = [];
      try {
        //test if hostname and portnumber are true
        if((deviceStr === '' && localnamebaseStr === 'local') || 
        (deviceStr === '' && localnamebaseStr === 'remote') || (deviceStr === 'mobile' && localnamebaseStr === 'remote')){
          var url = "http://" + hostnametaskStr + ":" + hostporttaskStr + "/_utils/";
          var http_request = new XMLHttpRequest();
          http_request.open("GET", url, true);
          //http_request.send("null");
          http_request.onreadystatechange = async function () {
            if (http_request.readyState === 4) {
              if (http_request.status === 200) {
                //search in local / remote database documents
                const db = new PouchDB(localnamebaseStr, { skip_setup: true });
                const remote = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +localnamebaseStr,{ skip_setup: true });
                PouchDB.plugin(PouchFind);
                // Initialise a sync with the remote server
                function sync() {
                  var opts = {
                    live: true,
                    retry: true,
                    continuous: true,
                    attachments: true,
                  };
                  db.sync(remote, opts);
                }
                if (remote) {
                  sync();
                }

                let result = await db.createIndex({
                  index: {
                    fields: ["connectname", "servername"],
                  },
                });
                result = await db.find({
                  selector: {
                    connectname: tasknameStr,
                    servername: servernameStr,
                  },
                });
                docsCheckConfirm = result.docs;
                var resultatCheckConfirm = docsCheckConfirm.length;
                if (resultatCheckConfirm === 0) {
                  var divClearModal = document.getElementById("modalouttasks");
                  let divModal = document.createElement("div");
                  divModal.innerHTML = 
                  `<div class="modal">
                    <p>Sure this database exists?</p>
                    <button class="btn btn--alt" id="ouiHandlerExistViewBtn">Yes</button>
                    <button class="btn" id="nonHandlerExistsViewBtn">No</button>
                  </div>`;
                  divClearModal.appendChild(divModal);

                  var divClearBackdrop = document.getElementById("backdropouttasks");
                  let divBack = document.createElement("div");
                  divBack.innerHTML = `<div class="backdrop" id="backdropHandlerCloseExistsViewBtn"></div>`;
                  divClearBackdrop.appendChild(divBack);

                  //If Yes Clicked
                  document.getElementById("ouiHandlerExistViewBtn").addEventListener("click", ouiHandlerExistViewBtnChange);
                  async function ouiHandlerExistViewBtnChange(e) {
                    e.preventDefault();

                    //code here
                    var divClearModal = document.getElementById("modalouttasks");
                    let divModal = document.createElement("div");
                    divModal.innerHTML = 
                    `<div class="modal">
                      <p>Wish to Add it?</p>
                      <button class="btn btn--alt" id="ouiHandlerAddViewBtn">Yes</button>
                      <button class="btn" id="nonHandlerAddViewBtn">No</button>
                    </div>`;
                    divClearModal.appendChild(divModal);

                    var divClearBackdrop = document.getElementById("backdropouttasks");
                    let divBack = document.createElement("div");
                    divBack.innerHTML = `<div class="backdrop" id="backdropHandlerCloseAddViewBtn"></div>`;
                    divClearBackdrop.appendChild(divBack);

                    //If Yes Add Clicked
                    document.getElementById("ouiHandlerAddViewBtn").addEventListener("click", ouiHandlerAddViewBtnChange);
                    async function ouiHandlerAddViewBtnChange(e) {
                      e.preventDefault();

                      //code here
                      const db = new PouchDB(localnamebaseStr, {skip_setup: true,});
                      const remote = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +localnamebaseStr,{ skip_setup: true });
                      PouchDB.plugin(PouchFind);
                      //test if hostname and portnumber are true
                      var http_request = new XMLHttpRequest();
                      http_request.open("GET","http://" +hostnametaskStr +":" +hostporttaskStr +"/_utils/",true);
                      http_request.send("null");
                      http_request.onreadystatechange = async function () {
                        if (http_request.readyState === 4) {
                          if (http_request.status === 200) {
                            function sync() {
                              var opts = {
                                live: true,
                                retry: true,
                                continuous: true,
                                attachments: true,
                              };
                              db.sync(remote, opts);
                            }
                            if (remote) {
                              sync();
                            }
                            //end sync
                            var docsCheck = [];
                            try {
                              let result = await db.createIndex({
                                index: {
                                  fields: [
                                    "hostname",
                                    "portname",
                                    "username",
                                    "passwordname",
                                  ],
                                },
                              });
                              result = await db.find({
                                selector: {
                                  hostname: hostnametaskStr,
                                  portname: hostporttaskStr,
                                  username: usernametaskStr,
                                  passwordname: passwordnametaskStr,
                                },
                              });
                              docsCheck = result.docs;
                              var resultatCheck = docsCheck.length;
                              if (resultatCheck === "") {
                                var pTexteincorrectconnection = "Name or password is incorrect.";
                                alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
                              } else {
                                var docsCheckDatabase = [];
                                let resultDatabase = await db.createIndex({
                                  index: {
                                    fields: ["connectname"],
                                  },
                                });
                                resultDatabase = await db.find({
                                  selector: {
                                    connectname: tasknameStr,
                                  },
                                });
                                docsCheckDatabase = resultDatabase.docs;
                                var resultatCheckDatabase = docsCheckDatabase.length;
                                if (resultatCheckDatabase === 0) {
                                  const dbNew = new PouchDB(tasknameStr);
                                  const remoteNew = couchProvider(hostnametaskStr,hostporttaskStr,usernametaskStr,passwordnametaskStr,tasknameStr);
                                  //add new database created to local connection server
                                  //Create the document _id and _rev.
                                  var now = Date.now();
                                  //new database id
                                  var id = tasknameStr.toLowerCase() + "_" + now;
                                  // Add the document to the database.
                                  var response = await db.put({
                                    _id: id,
                                    connectname: tasknameStr, //the new database name
                                    hostname: hostnametaskStr,
                                    portname: hostporttaskStr,
                                    username: usernametaskStr,
                                    passwordname: passwordnametaskStr,
                                    servername: localtypebaseStr,
                                  });
                                  // check if document inserted
                                  var doc = await db.get(response.id);
                                  if (doc !== "") {
                                    //Initialise a sync with the remote server
                                    function sync() {
                                      var opts = {
                                        live: true,
                                        retry: true,
                                        continuous: true,
                                        attachments: true,
                                      };
                                      dbNew.sync(remoteNew, opts);
                                    }
                                    if (remoteNew) {
                                      sync();
                                    }
                                    var pTextenewdatabase = "New Database added Succesfully";
                                    alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
                                  } else {
                                    var pTexteerreuraddnew = "An Error has Occurred";
                                    alertModal(elementOutId,elementDropId,pTexteerreuraddnew,buttonHandler);
                                  }
                                } else {
                                  var pTexteexistaddnew = "Database " +tasknameStr +" already exists. Confirm again";
                                  alertModal(elementOutId,elementDropId,pTexteexistaddnew,buttonHandler);
                                }
                              }
                            } catch (error) {
                              var pTexteerreurconnection = "An Error has Occurred";
                              alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                            }
                          } else {
                            var pTextefailedconnect = "Connection Failed.";
                            alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                          }
                        }
                      }; //
                      var divClearModal = document.getElementById("modalouttasks");
                      while (divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                      }

                      var divClearBackdrop = document.getElementById("backdropouttasks");
                      while (divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]
                        );
                      }
                    }

                    //If No Add Clicked
                    document.getElementById("nonHandlerAddViewBtn").addEventListener("click", nonHandlerAddViewBtnChange);
                    async function nonHandlerAddViewBtnChange(e) {
                      e.preventDefault();

                      //code here

                      var divClearModal = document.getElementById("modalouttasks");
                      while (divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                      }

                      var divClearBackdrop = document.getElementById("backdropouttasks");
                      while (divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(
                          divClearBackdrop.childNodes[0]
                        );
                      }
                    }

                    //If backdrop Clicked
                    document.getElementById("backdropHandlerCloseAddViewBtn").addEventListener("click", backdropHandlerCloseAddViewBtnChange);
                    async function backdropHandlerCloseAddViewBtnChange(e) {
                      e.preventDefault();

                      //code here

                      var divClearModal = document.getElementById("modalouttasks");
                      while (divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                      }

                      var divClearBackdrop = document.getElementById("backdropouttasks");
                      while (divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(
                          divClearBackdrop.childNodes[0]
                        );
                      }
                    }
                  //
                  }

                  //If No Clicked
                  document.getElementById("nonHandlerExistsViewBtn").addEventListener("click", nonHandlerExistsViewBtnChange);
                  async function nonHandlerExistsViewBtnChange(e) {
                    e.preventDefault();

                    //code here
                    var divClearModal = document.getElementById("modalouttasks");
                    let divModal = document.createElement("div");
                    divModal.innerHTML = 
                    `<div class="modal">
                      <p>Wish to Use it?</p>
                      <button class="btn btn--alt" id="ouiHandlerUseViewBtn">Yes</button>
                      <button class="btn" id="nonHandlerUseViewBtn">No</button>
                    </div>`;
                    divClearModal.appendChild(divModal);

                    var divClearBackdrop = document.getElementById("backdropouttasks");
                    let divBack = document.createElement("div");
                    divBack.innerHTML = `<div class="backdrop" id="backdropHandlerCloseUseViewBtn"></div>`;
                    divClearBackdrop.appendChild(divBack);

                    //If Yes Use Clicked
                    document.getElementById("ouiHandlerUseViewBtn").addEventListener("click", ouiHandlerUseViewBtnChange);
                    async function ouiHandlerUseViewBtnChange(e) {
                      e.preventDefault();

                      //code here
                      const db = new PouchDB(localnamebaseStr, {skip_setup: true,});
                      const remote = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +localnamebaseStr,{ skip_setup: true });
                      PouchDB.plugin(PouchFind);
                      //test if hostname and portnumber are true
                      var http_request = new XMLHttpRequest();
                      http_request.open("GET","http://" +hostnametaskStr +":" +hostporttaskStr +"/_utils/",true);
                      http_request.send("null");
                      http_request.onreadystatechange = async function () {
                        if (http_request.readyState === 4) {
                          if (http_request.status === 200) {
                            function sync() {
                              var opts = {
                                live: true,
                                retry: true,
                                continuous: true,
                                attachments: true,
                              };
                              db.sync(remote, opts);
                            }
                            if (remote) {
                              sync();
                            }
                            //end sync
                            var docsCheck = [];
                            try {
                              let result = await db.createIndex({
                                index: {
                                  fields: [
                                    "hostname",
                                    "portname",
                                    "username",
                                    "passwordname",
                                  ],
                                },
                              });
                              result = await db.find({
                                selector: {
                                  hostname: hostnametaskStr,
                                  portname: hostporttaskStr,
                                  username: usernametaskStr,
                                  passwordname: passwordnametaskStr,
                                },
                              });
                              docsCheck = result.docs;
                              var resultatCheck = docsCheck.length;
                              if (resultatCheck === "") {
                                var pTexteincorrectconnection = "Name or password is incorrect.";
                                alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
                              } else {
                                var docsCheckDatabase = [];
                                let resultDatabase = await db.createIndex({
                                  index: {
                                    fields: ["connectname"],
                                  },
                                });
                                resultDatabase = await db.find({
                                  selector: {
                                    connectname: tasknameStr,
                                  },
                                });
                                docsCheckDatabase = resultDatabase.docs;
                                var resultatCheckDatabase =
                                  docsCheckDatabase.length;
                                if (resultatCheckDatabase === 0) {
                                  const dbNew = new PouchDB(tasknameStr);
                                  const remoteNew = couchProvider(hostnametaskStr,hostporttaskStr,usernametaskStr,passwordnametaskStr,tasknameStr);
                                  //add new database created to local connection server
                                  //Create the document _id and _rev.
                                  var now = Date.now();
                                  //new database id
                                  var id = tasknameStr.toLowerCase() + "_" + now;
                                  // Add the document to the database.
                                  var response = await db.put({
                                    _id: id,
                                    connectname: tasknameStr, //the new database name
                                    hostname: hostnametaskStr,
                                    portname: hostporttaskStr,
                                    username: usernametaskStr,
                                    passwordname: passwordnametaskStr,
                                    servername: localtypebaseStr,
                                  });
                                  // check if document inserted
                                  var doc = await db.get(response.id);
                                  if (doc !== "") {
                                    //Initialise a sync with the remote server
                                    function sync() {
                                      var opts = {
                                        live: true,
                                        retry: true,
                                        continuous: true,
                                        attachments: true,
                                      };
                                      dbNew.sync(remoteNew, opts);
                                    }
                                    if (remoteNew) {
                                      sync();
                                    }
                                    var pTextenewdatabase = "New Database added Succesfully";
                                    alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
                                  } else {
                                    var pTexteerreuraddnew = "An Error has Occurred";
                                    alertModal(elementOutId,elementDropId,pTexteerreuraddnew,buttonHandler);
                                  }
                                } else {
                                  var pTexteexistaddnew = "Database " +tasknameStr +" already exists. Confirm again";
                                  alertModal(elementOutId,elementDropId,pTexteexistaddnew,buttonHandler);
                                }
                              }
                            } catch (error) {
                              var pTexteerreurconnection = "An Error has Occurred";
                              alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                            }
                          } else {
                            var pTextefailedconnect = "Connection Failed.";
                            alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                          }
                        }
                      }; //
                      var divClearModal = document.getElementById("modalouttasks");
                      while (divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                      }

                      var divClearBackdrop = document.getElementById("backdropouttasks");
                      while (divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(
                          divClearBackdrop.childNodes[0]
                        );
                      }
                    }

                    //If No Use Clicked
                    document.getElementById("nonHandlerUseViewBtn").addEventListener("click", nonHandlerUseViewBtnChange);
                    async function nonHandlerUseViewBtnChange(e) {
                      e.preventDefault();

                      //code here

                      var divClearModal = document.getElementById("modalouttasks");
                      while (divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                      }

                      var divClearBackdrop = document.getElementById("backdropouttasks");
                      while (divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(
                          divClearBackdrop.childNodes[0]
                        );
                      }
                    }

                    //If backdrop Clicked
                    document.getElementById("backdropHandlerCloseUseViewBtn").addEventListener("click", backdropHandlerCloseUseViewBtnChange);
                    async function backdropHandlerCloseUseViewBtnChange(e) {
                      e.preventDefault();

                      //code here

                      var divClearModal = document.getElementById("modalouttasks");
                      while (divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                      }

                      var divClearBackdrop = document.getElementById("backdropouttasks");
                      while (divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(
                          divClearBackdrop.childNodes[0]
                        );
                      }
                    }
                  //
                  }

                  //If backdrop Clicked
                  document.getElementById("backdropHandlerCloseExistsViewBtn").addEventListener("click", backdropHandlerCloseExistsViewBtnChange);
                  async function backdropHandlerCloseExistsViewBtnChange(e) {
                    e.preventDefault();

                    //code here

                    var divClearModal = document.getElementById("modalouttasks");
                    while (divClearModal.hasChildNodes()) {
                      divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById("backdropouttasks");
                    while (divClearBackdrop.hasChildNodes()) {
                      divClearBackdrop.removeChild(
                        divClearBackdrop.childNodes[0]
                      );
                    }
                  }
                } else {
                  var docsCheckTable = [];
                  var tableconnectStr = "table";
                  try {
                    let resultTable = await db.createIndex({
                      index: {
                        fields: [
                          "tabletemplate", //not use database name but template as table global name
                          "tableserver",
                          "tableconnect",
                        ],
                      },
                    });
                    resultTable = await db.find({
                      selector: {
                        tabletemplate: tabletemplateStr, //not use database name but template as table global name
                        tableserver: localnamebaseStr,
                        tableconnect: tableconnectStr,
                      },
                    });
                    docsCheckTable = resultTable.docs;
                    var resultatCheckTable = docsCheckTable.length;
                    if (resultatCheckTable === 0) {
                      var pTextenotable = "No Table available";
                      alertModal(elementOutId,elementDropId,pTextenotable,buttonHandler);
                    } else {
                      document.getElementById("documenttasks").style.display = "block";
                      document.getElementById("querydocumenttasks").style.display = "block";
                      document.getElementById("crudtasksactions").style.display = "block";
                      document.getElementById("addtasks").style.display = "none";
                      document.getElementById("edittasks").style.display = "none";
                      document.getElementById("deletetasks").style.display = "none";
                      //xxxxxxxxx crudbase xxxxxxxxx
                      updateViewTaskTableUIWithDocs(docsCheckTable);
                      document.getElementById("newtasks").style.display = "none";
                      document.getElementById("createtasks").style.display = "none";
                      document.getElementById("doctasks").style.display = "none";
                      document.getElementById("listtasks").style.display = "none";
                      document.getElementById("previoustasks").style.display = "none";
                      document.getElementById("editdeletetasks").style.display = "none";
                      document.getElementById("changedeledittasks").style.display = "none";
                      document.getElementById("changetasks").style.display = "none";
                      document.getElementById("viewtasks").style.display = "none";
                      document.getElementById("listviewtasks").style.display = "block";
                      document.getElementById("viewdisplaytasks").style.display = "none";
                      document.getElementById("viewdoctasks").style.display = "none";
                      document.getElementById("changeviewtasks").style.display = "none";
                      //xxxxxxx input field
                      document.getElementById("tasname").style.display = "none";
                      document.getElementById("confirmertask").style.display = "none";
                    }
                  } catch (error) {
                    var pTexteerreurtable = "An Error has Occurred";
                    alertModal(elementOutId,elementDropId,pTexteerreurtable,buttonHandler);
                  }
                } //
              } else {
                var pTexteincorrectconnection = "Incorrect Connection Parameters";
                alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
              }
            }
          };
          http_request.send();//zzzz
        }
      } catch (error) {
        var pTexteerreurconnection = "An Error has Occurred";
        alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
      }
    }
  }
  //Crud tasks (mobileconfirmertable)
  document.getElementById("mobileconfirmtaskview").addEventListener("click", mobileconfirmtaskviewChange);
  async function mobileconfirmtaskviewChange(e){
    e.preventDefault();

    //alert
    var elementOutId = "modaloutalerttasks";
    var elementDropId = "backdropoutalerttasks";
    var buttonHandler = "ouiHandlerAlertTaskBtn";

    var mobileusernametaskStr = document.getElementById("mobileusernametaskview").value;
    var mobilepasswordnametaskStr = document.getElementById("mobilepasswordnametaskview").value;
    //var tasknameStr = document.getElementById("tasknameview").value.toLowerCase();
    var serveurHeaderStr = document.getElementById("serveurheader").innerText;
    var deviceStr = document.getElementById('device').innerText;

    if(!mobileusernametaskStr){
      var pTexteusername = "Enter Username";
      alertModal(elementOutId,elementDropId,pTexteusername,buttonHandler);
    }else if(!mobilepasswordnametaskStr){
      var pTextepasswordname = "Enter Password";
      alertModal(elementOutId,elementDropId,pTextepasswordname,buttonHandler);
    }else{
      if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
        const db = new PouchDB(serveurHeaderStr, { skip_setup: true });
        PouchDB.plugin(PouchFind);
        var docsCheckConfirm = [];
        try {
          let result = await db.createIndex({
            index: {
              fields: [ 
                "servername"
              ],
            },
          });
          result = await db.find({
            selector: {
              servername: serveurHeaderStr,
            },
          });
          docsCheckConfirm = result.docs;
          var resultatCheckConfirm = docsCheckConfirm.length;
          if (resultatCheckConfirm === 0){
            var pTextenotexistaddnew = "Database not exists. Create it first";
            alertModal(elementOutId,elementDropId,pTextenotexistaddnew,buttonHandler);
          }else{
            var docsCheckTable = [];
            var tabletemplateStr = 'template';
            var tableconnectStr = "table";
            try {
              let resultTable = await db.createIndex({
                index: {
                  fields: [
                    "tabletemplate", //not use database name but template as table global name
                    "tableserver",
                    "tableconnect",
                  ],
                },
              });
              resultTable = await db.find({
                selector: {
                  tabletemplate: tabletemplateStr, //not use database name but template as table global name
                  tableserver: serveurHeaderStr,
                  tableconnect: tableconnectStr,
                },
              });
              docsCheckTable = resultTable.docs;
              var resultatCheckTable = docsCheckTable.length;
              if (resultatCheckTable === 0){
                var pTextenotable = "No Table template available";
                alertModal(elementOutId,elementDropId,pTextenotable,buttonHandler);
              }else{
                document.getElementById("documenttasks").style.display = "block";
                document.getElementById("querydocumenttasks").style.display = "block";
                document.getElementById("crudtasksactions").style.display = "block";
                document.getElementById("addtasks").style.display = "none";
                document.getElementById("edittasks").style.display = "none";
                document.getElementById("deletetasks").style.display = "none";
                //xxxxxxxxx crudbase xxxxxxxxx
                updateViewTaskTableUIWithDocs(docsCheckTable);
                document.getElementById("newtasks").style.display = "none";
                document.getElementById("createtasks").style.display = "none";
                document.getElementById("doctasks").style.display = "none";
                document.getElementById("listtasks").style.display = "none";
                document.getElementById("previoustasks").style.display = "none";
                document.getElementById("editdeletetasks").style.display = "none";
                document.getElementById("changedeledittasks").style.display = "none";
                document.getElementById("changetasks").style.display = "none";
                document.getElementById("viewtasks").style.display = "none";
                document.getElementById("listviewtasks").style.display = "block";
                document.getElementById("viewdisplaytasks").style.display = "none";
                document.getElementById("viewdoctasks").style.display = "none";
                document.getElementById("changeviewtasks").style.display = "none";
                //xxxxxxx input field
                document.getElementById("tasname").style.display = "none";
                document.getElementById("confirmertask").style.display = "none";
              }
            } catch (error) {
              var pTexteerreurtable = "An Error has Occurred";
              alertModal(elementOutId,elementDropId,pTexteerreurtable,buttonHandler);
            }
          }
        } catch (error) {
          var pTexteerreurconnection = "An Error has Occurred";
          alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
        }
      }
    }
  }
  // Adds the document to the HTML First table.
  // If the document already exists in the table, it will be updated.
  var taskviewkeyvalue = ''; //global variables
  function updateViewTaskDocToToTable(doc, crudViewTaskTableBody) {
    // Each row of the table has an id attribute set to the doc._id field.
    var tr = crudViewTaskTableBody.querySelector(`#${doc._id}`);

    //alert
    /*var elementOutId = "modaloutalerttasks";
    var elementDropId = "backdropoutalerttasks";
    var buttonHandler = "ouiHandlerAlertTasksBtn";*/

    if (!tr) {
      tr = document.createElement("tr");
      tr.id = doc._id;
    }

    // Remove any existing table cells.
    while (tr.hasChildNodes()) {
      tr.removeChild(tr.childNodes[0]);
    }

    // Create new table cells for text fields.
    var tdTableServer = document.createElement("td");
    tdTableServer.innerText = doc.tableserver;
    //var tableNameStr = doc.tablename;
    tdTableServer.onclick = async function () {
      var tableidStr = doc._id;
      var tabledatabaseStr = doc.tabledatabase;
      var tablenameStr = doc.tablename;
      var tableserverStr = doc.tableserver;
      var tablekeyStr = doc.tablekey;
      var tablevalueStr = doc.tablevalue;
      taskviewkeyvalue = tablevalueStr;
      var tableconnectStr = doc.tableconnect;

      var nametablechangetext = document.getElementById("nametaskviewdisplay");
      nametablechangetext.value = tablenameStr;
      var fieldtablechangetext = document.getElementById("fieldtaskviewdisplay");
      fieldtablechangetext.value = tablekeyStr.length;
      var tableidchangetext = document.getElementById("taskidviewdisplay");
      tableidchangetext.value = tableidStr;
      var tabledatabasechangetext = document.getElementById("taskdatabaseviewdisplay");
      tabledatabasechangetext.value = tabledatabaseStr;
      var tableserverchangetext = document.getElementById("taskserverviewdisplay");
      tableserverchangetext.value = tableserverStr;
      var tableconnectchangetext = document.getElementById("taskconnectviewdisplay");
      tableconnectchangetext.value = tableconnectStr;

      document.getElementById("documenttasks").style.display = "block";
      document.getElementById("querydocumenttasks").style.display = "block";
      document.getElementById("crudtasksactions").style.display = "block";
      document.getElementById("addtasks").style.display = "none";
      document.getElementById("edittasks").style.display = "none";
      document.getElementById("deletetasks").style.display = "none";
      document.getElementById("newtasks").style.display = "none";
      document.getElementById("createtasks").style.display = "none";
      document.getElementById("doctasks").style.display = "none";
      document.getElementById("listtasks").style.display = "none";
      document.getElementById("previoustasks").style.display = "none";
      document.getElementById("editdeletetasks").style.display = "none";
      document.getElementById("changedeledittasks").style.display = "none";
      document.getElementById("changetasks").style.display = "none";
      document.getElementById("viewtasks").style.display = "none";
      document.getElementById("listviewtasks").style.display = "none";
      document.getElementById("viewdisplaytasks").style.display = "block";
      document.getElementById("viewdoctasks").style.display = "none";
      document.getElementById("changeviewtasks").style.display = "none";
      //xxxxxxx input field
      document.getElementById("tasname").style.display = "none";
      document.getElementById("confirmertask").style.display = "none";
    };
    tr.appendChild(tdTableServer);

    // Create new table cells for text fields.
    var tdTableName = document.createElement("td");
    tdTableName.innerText = doc.tablename;
    //var tdIdTableStr = doc.tablename;
    tdTableName.onclick = function () {
      var tableidStr = doc._id;
      var tabledatabaseStr = doc.tabledatabase;
      var tablenameStr = doc.tablename;
      var tableserverStr = doc.tableserver;
      var tablekeyStr = doc.tablekey;
      var tablevalueStr = doc.tablevalue;
      taskviewkeyvalue = tablevalueStr;
      var tableconnectStr = doc.tableconnect;

      var nametablechangetext = document.getElementById("nametaskviewdisplay");
      nametablechangetext.value = tablenameStr;
      var fieldtablechangetext = document.getElementById("fieldtaskviewdisplay");
      fieldtablechangetext.value = tablekeyStr.length;
      var tableidchangetext = document.getElementById("taskidviewdisplay");
      tableidchangetext.value = tableidStr;
      var tabledatabasechangetext = document.getElementById("taskdatabaseviewdisplay");
      tabledatabasechangetext.value = tabledatabaseStr;
      var tableserverchangetext = document.getElementById("taskserverviewdisplay");
      tableserverchangetext.value = tableserverStr;
      var tableconnectchangetext = document.getElementById("taskconnectviewdisplay");
      tableconnectchangetext.value = tableconnectStr;

      document.getElementById("documenttasks").style.display = "block";
      document.getElementById("querydocumenttasks").style.display = "block";
      document.getElementById("crudtasksactions").style.display = "block";
      document.getElementById("addtasks").style.display = "none";
      document.getElementById("edittasks").style.display = "none";
      document.getElementById("deletetasks").style.display = "none";
      document.getElementById("newtasks").style.display = "none";
      document.getElementById("createtasks").style.display = "none";
      document.getElementById("doctasks").style.display = "none";
      document.getElementById("listtasks").style.display = "none";
      document.getElementById("previoustasks").style.display = "none";
      document.getElementById("editdeletetasks").style.display = "none";
      document.getElementById("changedeledittasks").style.display = "none";
      document.getElementById("changetasks").style.display = "none";
      document.getElementById("viewtasks").style.display = "none";
      document.getElementById("listviewtasks").style.display = "none";
      document.getElementById("viewdisplaytasks").style.display = "block";
      document.getElementById("viewdoctasks").style.display = "none";
      document.getElementById("changeviewtasks").style.display = "none";
      //xxxxxxx input field
      document.getElementById("tasname").style.display = "none";
      document.getElementById("confirmertask").style.display = "none";
    };
    tr.appendChild(tdTableName);

    // Add the row to the top of the table.
    crudViewTaskTableBody.insertBefore(tr, crudViewTaskTableBody.nextSibling);
  }
  /**Clear and update the UI to display the docs.*/
  function updateViewTaskTableUIWithDocs(docs) {
    var crudViewTaskTableBody = document.getElementById("crudViewTaskTableBody");

    // Remove all child nodes from the table
    while (crudViewTaskTableBody.hasChildNodes()) {
      crudViewTaskTableBody.removeChild(crudViewTaskTableBody.childNodes[0]);
    }

    // Add the new documents to the div
    for (var i = 0; i < docs.length; i++) {
      var doc = docs[i];
      updateViewTaskDocToToTable(doc, crudViewTaskTableBody);
    }
  }

  //global arrays variable to edit
  var taskkeyview = [];
  var taskvalueview = [];
  //retreive the table input field dynamically to fill data in the table
  document.getElementById("selecttasknameviewdisplay").addEventListener("click", selecttasknameviewdisplayChange);
  async function selecttasknameviewdisplayChange(e) {
    e.preventDefault();

    //alert
    var elementOutId = "modaloutalerttasks";
    var elementDropId = "backdropoutalerttasks";
    var buttonHandler = "ouiHandlerAlertTasksBtn";

    var hostnametaskStr = document.getElementById("hostnametaskview").value;
    var hostporttaskStr = document.getElementById("hostporttaskview").value;
    var usernametaskStr = document.getElementById("usernametaskview").value;
    var passwordnametaskStr = document.getElementById("passwordnametaskview").value;
    //var tasknameStr = document.getElementById('taskname').value;
    var serveurHeaderStr = document.getElementById("serveurheader").innerText;
    var deviceStr = document.getElementById('device').innerText;
    //xxxxxxxxxxxxxxxxxxx
    var nametaskchangeStr = document.getElementById("nametaskviewdisplay").value.toLocaleLowerCase();
    var taskidchangeStr = document.getElementById("taskidviewdisplay").value;
    var taskdatabasechangeStr = document.getElementById("tasknameview").value; //from newtasks component
    var taskserverchangeStr = document.getElementById("taskserverviewdisplay").value;
    var taskconnectchangeStr = document.getElementById("taskconnectviewdisplay").value;
    //xxxxxxxxxxxxxx
    var localnamebaseStr = "";
    //var servernameStr = 'database';
    if (serveurHeaderStr === "local") {
      localnamebaseStr = "local";
    } else if (serveurHeaderStr === "remote") {
      localnamebaseStr = "remote";
    }

    //code here
    if((deviceStr === '' && serveurHeaderStr === 'local') || 
    (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
      const db = new PouchDB(localnamebaseStr, { skip_setup: true });
      const remote = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +localnamebaseStr,{ skip_setup: true });
      PouchDB.plugin(PouchFind);
      //test if hostname and portnumber are true
      var http_request = new XMLHttpRequest();
      http_request.open("GET","http://" + hostnametaskStr + ":" + hostporttaskStr + "/_utils/",true);
      http_request.onreadystatechange = async function () {
        if (http_request.readyState === 4) {
          if (http_request.status === 200) {
            function sync() {
              var opts = {
                live: true,
                retry: true,
                continuous: true,
                attachments: true,
              };
              db.sync(remote, opts);
            }
            if (remote) {
              sync();
            }
            //end sync
            var docsCheck = [];
            try {
              let result = await db.createIndex({
                index: {
                  fields: ["hostname", "portname", "username", "passwordname"],
                },
              });
              result = await db.find({
                selector: {
                  hostname: hostnametaskStr,
                  portname: hostporttaskStr,
                  username: usernametaskStr,
                  passwordname: passwordnametaskStr,
                },
              });
              docsCheck = result.docs;
              var resultatCheck = docsCheck.length;
              if (resultatCheck === "") {
                var pTexteincorrectconnection = "Name or password is incorrect.";
                alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
              } else {
                var docsCheckDatabase = [];
                let resultDatabase = await db.createIndex({
                  index: {
                    fields: ["_id"],
                  },
                });
                resultDatabase = await db.find({
                  selector: {
                    _id: taskidchangeStr,
                  },
                });
                docsCheckDatabase = resultDatabase.docs;
                var resultatCheckDatabase = docsCheckDatabase.length;
                if (resultatCheckDatabase === 0) {
                  var pTextenotable = "Table template " + nametaskchangeStr + " not available";
                  alertModal(elementOutId,elementDropId,pTextenotable,buttonHandler);
                } else {
                  //search in local / remote database documents
                  const dbbase = new PouchDB(taskdatabasechangeStr, {skip_setup: true,});
                  const remotebase = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +taskdatabasechangeStr,{ skip_setup: true });
                  PouchDB.plugin(PouchFind);
                  // Initialise a sync with the remote server
                  function sync() {
                    var opts = {
                      live: true,
                      retry: true,
                      continuous: true,
                      attachments: true,
                    };
                    dbbase.sync(remotebase, opts);
                  }
                  if (remotebase) {
                    sync();
                  }
                  var docsCheckDocument = [];
                  let result = await dbbase.createIndex({
                    index: {
                      fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "taskconnect",
                      ],
                    },
                  });
                  result = await dbbase.find({
                    selector: {
                      taskdatabase: taskdatabasechangeStr,
                      taskname: nametaskchangeStr,
                      taskserver: taskserverchangeStr,
                      taskconnect: taskconnectchangeStr,
                    },
                  });
                  docsCheckDocument = result.docs;

                  var resultatCheckDocument = docsCheckDocument.length;
                  if (resultatCheckDocument === 0) {
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                  } else {
                    taskkeyview = []; // set taskkeyvew array new value to empty 
                    var valeur = [];
                    taskvalueview = []; // set taskkeyvew array new value to empty 
                    for (let i = 0; i < docsCheckDocument.length; i++) {
                      taskkeyview.push(docsCheckDocument[i].taskdata);
                      valeur.push(docsCheckDocument[i].taskdata)
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                      taskvalueview.push(Object.values(valeur[j]));
                    }

                    document.getElementById("documenttasks").style.display = "block";
                    document.getElementById("querydocumenttasks").style.display = "block";
                    document.getElementById("crudtasksactions").style.display = "block";
                    document.getElementById("addtasks").style.display = "none";
                    document.getElementById("edittasks").style.display = "none";
                    document.getElementById("deletetasks").style.display = "none";
                    //xxxxxxxxx crudbase xxxxxxxxx
                    viewSelectDocumentTaskTableUIWithDocs(docsCheckDocument);
                    document.getElementById("newtasks").style.display = "none";
                    document.getElementById("createtasks").style.display = "none";
                    document.getElementById("doctasks").style.display = "none";
                    document.getElementById("listtasks").style.display = "none";
                    document.getElementById("previoustasks").style.display = "none";
                    document.getElementById("editdeletetasks").style.display = "none";
                    document.getElementById("changedeledittasks").style.display = "none";
                    document.getElementById("changetasks").style.display = "none";
                    document.getElementById("viewtasks").style.display = "none";
                    document.getElementById("listviewtasks").style.display = "none";
                    document.getElementById("viewdisplaytasks").style.display = "none";
                    document.getElementById("viewdoctasks").style.display = "block";
                    document.getElementById("changeviewtasks").style.display = "none";
                    //xxxxxxx input field
                    document.getElementById("tasname").style.display = "none";
                    document.getElementById("confirmertask").style.display = "none";
                    //xxxxxxxxxxxxxx confirm and undo style
                    //document.getElementById('selecttasknameviewdisplay').style.display = 'none';
                    //document.getElementById('undotasknameviewdisplay').style.display = 'none';
                  }
                }
              }
            } catch (error) {
              var pTexteerreurconnection = "An Error has Occurred";
              alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
          } else {
            var pTextefailedconnect = "Connection Failed.";
            alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
          }
        }
      };
      http_request.send();
    };

    if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
      const db = new PouchDB(serveurHeaderStr, { skip_setup: true });
      PouchDB.plugin(PouchFind);
      var docsCheck = [];
      try {
        let result = await db.createIndex({
          index: {
            fields: [ 
              "servername"
            ],
          },
        });
        result = await db.find({
          selector: {
            servername: serveurHeaderStr
          },
        });
        docsCheck = result.docs;
        var resultatCheck = docsCheck.length;
        if (resultatCheck === 0){
          var pTextenotexistaddnew = "Database not exists. Create it first";
          alertModal(elementOutId,elementDropId,pTextenotexistaddnew,buttonHandler);
        }else{
          var docsCheckDatabase = [];
          let resultDatabase = await db.createIndex({
            index: {
              fields: ["_id"],
            },
          });
          resultDatabase = await db.find({
            selector: {
              _id: taskidchangeStr,
            },
          });
          docsCheckDatabase = resultDatabase.docs;
          var resultatCheckDatabase = docsCheckDatabase.length;
          if (resultatCheckDatabase === 0){
            var pTextenotable = "Table template " + nametaskchangeStr + " not available";
            alertModal(elementOutId,elementDropId,pTextenotable,buttonHandler);
          }else{
            const dbbase = new PouchDB(serveurHeaderStr, {skip_setup: true,});
            PouchDB.plugin(PouchFind);
            var docsCheckDocument = [];
            let result = await dbbase.createIndex({
              index: {
                fields: [
                  "taskdatabase",
                  "taskname",
                  "taskserver",
                  "taskconnect",
                ],
              },
            });
            result = await dbbase.find({
              selector: {
                taskdatabase: serveurHeaderStr,
                taskname: nametaskchangeStr,
                taskserver: taskserverchangeStr,
                taskconnect: taskconnectchangeStr,
              },
            });
            docsCheckDocument = result.docs;
            var resultatCheckDocument = docsCheckDocument.length;
            if (resultatCheckDocument === 0) {
              var pTextenodocument = "No Document available";
              alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
            }else{
              taskkeyview = []; // set taskkeyvew array new value to empty 
              var valeur = [];
              taskvalueview = []; // set taskkeyvew array new value to empty 
              for (let i = 0; i < docsCheckDocument.length; i++) {
                taskkeyview.push(docsCheckDocument[i].taskdata);
                valeur.push(docsCheckDocument[i].taskdata)
              }
              //get value from json object in javascript
              for(let j=0; j<valeur.length; j++){
                taskvalueview.push(Object.values(valeur[j]));
              }

              document.getElementById("documenttasks").style.display = "block";
              document.getElementById("querydocumenttasks").style.display = "block";
              document.getElementById("crudtasksactions").style.display = "block";
              document.getElementById("addtasks").style.display = "none";
              document.getElementById("edittasks").style.display = "none";
              document.getElementById("deletetasks").style.display = "none";
              //xxxxxxxxx crudbase xxxxxxxxx
              viewSelectDocumentTaskTableUIWithDocs(docsCheckDocument);
              document.getElementById("newtasks").style.display = "none";
              document.getElementById("createtasks").style.display = "none";
              document.getElementById("doctasks").style.display = "none";
              document.getElementById("listtasks").style.display = "none";
              document.getElementById("previoustasks").style.display = "none";
              document.getElementById("editdeletetasks").style.display = "none";
              document.getElementById("changedeledittasks").style.display = "none";
              document.getElementById("changetasks").style.display = "none";
              document.getElementById("viewtasks").style.display = "none";
              document.getElementById("listviewtasks").style.display = "none";
              document.getElementById("viewdisplaytasks").style.display = "none";
              document.getElementById("viewdoctasks").style.display = "block";
              document.getElementById("changeviewtasks").style.display = "none";
              //xxxxxxx input field
              document.getElementById("tasname").style.display = "none";
              document.getElementById("confirmertask").style.display = "none";
              //xxxxxxxxxxxxxx confirm and undo style
              //document.getElementById('selecttasknameviewdisplay').style.display = 'none';
              //document.getElementById('undotasknameviewdisplay').style.display = 'none';
            }
          }
        }
      } catch (error) {
        var pTexteerreurconnection = "An Error has Occurred";
        alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
      }
    };
  }

  // Adds the document to the HTML First table.
  // If the document already exists in the table, it will be updated.
  function selectViewTaskDocToToTable(doc, viewDocumentTaskTableBody) {
    // Each row of the table has an id attribute set to the doc._id field.
    var tr = viewDocumentTaskTableBody.querySelector(`#${doc._id}`);

    if (!tr) {
      tr = document.createElement("tr");
      tr.id = doc._id;
    }

    // Remove any existing table cells.
    while (tr.hasChildNodes()) {
      tr.removeChild(tr.childNodes[0]);
    }


    // Create new table cells for text fields.
    var tdTemplate = document.createElement("td");
    tdTemplate.innerText = doc.taskname;
    //var tableNameStr = doc.tablename;
    tdTemplate.onclick = async function () {

      //xxxxxxxx set header variables xxxxxxx
      //get json array column
      var tableData = [doc.taskdata];
      const column = Object.keys(tableData[0]);
      //xxxxxx multiple rows dynamically varaibles xxxxxxx
      var tableRows = taskvalueview;

      //Create an HTML table using JavaScript
      // get the reference for the header
      var dynamicView = document.getElementById("dynamicview");
      // Remove any existing input field.
      while (dynamicView.hasChildNodes()) {
        dynamicView.removeChild(dynamicView.childNodes[0]);
      }
      //1.create table
      var userTable = document.createElement('table');
      userTable.setAttribute('id', 'usertable');
      userTable.setAttribute('class', 'thin_table_config')
      dynamicView.appendChild(userTable);

      //2.add header to the table
      var tableHeadRow = userTable.insertRow(-1);
      //var tableHeadArray = column;
      var tableHeadArray = taskviewkeyvalue;
      for(let i=0; i<tableHeadArray.length; i++){
        var th = document.createElement('th');
        th.innerHTML = tableHeadArray[i];
        tableHeadRow.appendChild(th);
      }

      //3.add rows dynamically to the table
      var fetch = document.getElementById('usertable');
      var array = tableRows;

      for(var j=0; j<array.length; j++){
        var newRow = fetch.insertRow(fetch.length);
        for(var k=0; k<array[j].length; k++){
          var cell = newRow.insertCell(k);
          cell.innerHTML =  array[j][k];
        }
      }

      //xxxxxxxxxx
      document.getElementById("documenttasks").style.display = "block";
      document.getElementById("querydocumenttasks").style.display = "block";
      document.getElementById("crudtasksactions").style.display = "block";
      document.getElementById("addtasks").style.display = "none";
      document.getElementById("edittasks").style.display = "none";
      document.getElementById("deletetasks").style.display = "none";
      //xxxxxxxxx crudbase xxxxxxxxx
      document.getElementById("newtasks").style.display = "none";
      document.getElementById("createtasks").style.display = "none";
      document.getElementById("doctasks").style.display = "none";
      document.getElementById("listtasks").style.display = "none";
      document.getElementById("previoustasks").style.display = "none";
      document.getElementById("editdeletetasks").style.display = "none";
      document.getElementById("changedeledittasks").style.display = "none";
      document.getElementById("changetasks").style.display = "none";
      document.getElementById("viewtasks").style.display = "none";
      document.getElementById("listviewtasks").style.display = "none";
      document.getElementById("viewdisplaytasks").style.display = "none";
      document.getElementById("viewdoctasks").style.display = "none";
      document.getElementById("changeviewtasks").style.display = "block";
      //xxxxxxx input field
      document.getElementById("tasname").style.display = "none";
      document.getElementById("confirmertask").style.display = "none";
      //xxxxxxxxxxxxxx confirm and undo style
      document.getElementById("selecttasknamechange").style.display = "none";
      document.getElementById("undotasknamechange").style.display = "none";
    };
    tr.appendChild(tdTemplate);

    // Create new table cells for text fields.
    var tdDocument = document.createElement("td");
    tdDocument.innerText = doc.documentname;
    //var tableNameStr = doc.tablename;
    tdDocument.onclick = async function () {
      //xxxxxxxx set header variables xxxxxxx
      //get json array column
      var tableData = [doc.taskdata];
      const column = Object.keys(tableData[0]);
      //xxxxxx multiple rows dynamically varaibles xxxxxxx
      var tableRows = taskvalueview;

      //Create an HTML table using JavaScript
      // get the reference for the header
      var dynamicView = document.getElementById("dynamicview");
      // Remove any existing input field.
      while (dynamicView.hasChildNodes()) {
        dynamicView.removeChild(dynamicView.childNodes[0]);
      }
      //1.create table
      var userTable = document.createElement('table');
      userTable.setAttribute('id', 'usertable');
      userTable.setAttribute('class', 'thin_table_config')
      dynamicView.appendChild(userTable);

      //2.add header to the table
      var tableHeadRow = userTable.insertRow(-1);
      //var tableHeadArray = column;
      var tableHeadArray = taskviewkeyvalue;
      for(let i=0; i<tableHeadArray.length; i++){
        var th = document.createElement('th');
        th.innerHTML = tableHeadArray[i];
        tableHeadRow.appendChild(th);
      }

      //3.add rows dynamically to the table
      var fetch = document.getElementById('usertable');
      var array = tableRows;

      for(var j=0; j<array.length; j++){
        var newRow = fetch.insertRow(fetch.length);
        for(var k=0; k<array[j].length; k++){
          var cell = newRow.insertCell(k);
          cell.innerHTML =  array[j][k];
        }
      }

      //xxxxxxxxxx
      document.getElementById("documenttasks").style.display = "block";
      document.getElementById("querydocumenttasks").style.display = "block";
      document.getElementById("crudtasksactions").style.display = "block";
      document.getElementById("addtasks").style.display = "none";
      document.getElementById("edittasks").style.display = "none";
      document.getElementById("deletetasks").style.display = "none";
      //xxxxxxxxx crudbase xxxxxxxxx
      document.getElementById("newtasks").style.display = "none";
      document.getElementById("createtasks").style.display = "none";
      document.getElementById("doctasks").style.display = "none";
      document.getElementById("listtasks").style.display = "none";
      document.getElementById("previoustasks").style.display = "none";
      document.getElementById("editdeletetasks").style.display = "none";
      document.getElementById("changedeledittasks").style.display = "none";
      document.getElementById("changetasks").style.display = "none";
      document.getElementById("viewtasks").style.display = "none";
      document.getElementById("listviewtasks").style.display = "none";
      document.getElementById("viewdisplaytasks").style.display = "none";
      document.getElementById("viewdoctasks").style.display = "none";
      document.getElementById("changeviewtasks").style.display = "block";
      //xxxxxxx input field
      document.getElementById("tasname").style.display = "none";
      document.getElementById("confirmertask").style.display = "none";
      //xxxxxxxxxxxxxx confirm and undo style
      document.getElementById("selecttasknamechange").style.display = "none";
      document.getElementById("undotasknamechange").style.display = "none";
    };
    tr.appendChild(tdDocument);

    // Add the row to the top of the table.
    viewDocumentTaskTableBody.insertBefore(tr, viewDocumentTaskTableBody.nextSibling);
  }
  /**Clear and update the UI to display the docs.*/
  function viewSelectDocumentTaskTableUIWithDocs(docs) {
    var viewDocumentTaskTableBody = document.getElementById("viewDocumentTaskTableBody");

    // Remove all child nodes from the table
    while (viewDocumentTaskTableBody.hasChildNodes()) {
      viewDocumentTaskTableBody.removeChild(viewDocumentTaskTableBody.childNodes[0]);
    }

    // Add the new documents to the div
    for (var i = 0; i<1; i++) {
      var doc = docs[i];
      selectViewTaskDocToToTable(doc, viewDocumentTaskTableBody);
    }
  }//

  //Undo table template selected
  document.getElementById("undotasknameviewdisplay").addEventListener("click", undotasknameviewdisplayChange);
  async function undotasknameviewdisplayChange(e) {
    e.preventDefault();

    document.getElementById("documenttasks").style.display = "block";
    document.getElementById("querydocumenttasks").style.display = "block";
    document.getElementById("crudtasksactions").style.display = "block";
    document.getElementById("addtasks").style.display = "none";
    document.getElementById("edittasks").style.display = "none";
    document.getElementById("deletetasks").style.display = "none";
    document.getElementById("newtasks").style.display = "none";
    document.getElementById("createtasks").style.display = "none";
    document.getElementById("doctasks").style.display = "none";
    document.getElementById("listtasks").style.display = "none";
    document.getElementById("previoustasks").style.display = "none";
    document.getElementById("editdeletetasks").style.display = "none";
    document.getElementById("changedeledittasks").style.display = "none";
    document.getElementById("changetasks").style.display = "none";
    document.getElementById("viewtasks").style.display = "none";
    document.getElementById("listviewtasks").style.display = "block";
    document.getElementById("viewdisplaytasks").style.display = "none";
    document.getElementById("viewdoctasks").style.display = "none";
    document.getElementById("changeviewtasks").style.display = "none";
    //xxxxxxx input field
    document.getElementById("tasname").style.display = "none";
    document.getElementById("confirmertask").style.display = "none";
  }

  //back to new view
  document.getElementById("confirmtasknamechangeview").addEventListener("click", confirmtasknamechangeviewChange);
  async function confirmtasknamechangeviewChange(e) {
    e.preventDefault();

    document.getElementById("documenttasks").style.display = "block";
    document.getElementById("querydocumenttasks").style.display = "block";
    document.getElementById("crudtasksactions").style.display = "block";
    document.getElementById("addtasks").style.display = "none";
    document.getElementById("edittasks").style.display = "none";
    document.getElementById("deletetasks").style.display = "none";
    document.getElementById("newtasks").style.display = "none";
    document.getElementById("createtasks").style.display = "none";
    document.getElementById("doctasks").style.display = "none";
    document.getElementById("listtasks").style.display = "none";
    document.getElementById("previoustasks").style.display = "none";
    document.getElementById("editdeletetasks").style.display = "none";
    document.getElementById("changedeledittasks").style.display = "none";
    document.getElementById("changetasks").style.display = "none";
    document.getElementById("viewtasks").style.display = "none";
    document.getElementById("listviewtasks").style.display = "none";
    document.getElementById("viewdisplaytasks").style.display = "none";
    document.getElementById("viewdoctasks").style.display = "block";
    document.getElementById("changeviewtasks").style.display = "none";
    //xxxxxxx input field
    document.getElementById("tasname").style.display = "none";
    document.getElementById("confirmertask").style.display = "none";
  }

  //Undo table template selected
  document.getElementById("canceltasknamechangeview").addEventListener("click", canceltasknamechangeviewChange);
  async function canceltasknamechangeviewChange(e) {
    e.preventDefault();

    document.getElementById("documenttasks").style.display = "block";
    document.getElementById("querydocumenttasks").style.display = "block";
    document.getElementById("crudtasksactions").style.display = "block";
    document.getElementById("addtasks").style.display = "none";
    document.getElementById("edittasks").style.display = "none";
    document.getElementById("deletetasks").style.display = "none";
    document.getElementById("newtasks").style.display = "none";
    document.getElementById("createtasks").style.display = "none";
    document.getElementById("doctasks").style.display = "none";
    document.getElementById("listtasks").style.display = "none";
    document.getElementById("previoustasks").style.display = "none";
    document.getElementById("editdeletetasks").style.display = "none";
    document.getElementById("changedeledittasks").style.display = "none";
    document.getElementById("changetasks").style.display = "none";
    document.getElementById("viewtasks").style.display = "none";
    document.getElementById("listviewtasks").style.display = "block";
    document.getElementById("viewdisplaytasks").style.display = "none";
    document.getElementById("viewdoctasks").style.display = "none";
    document.getElementById("changeviewtasks").style.display = "none";
    //xxxxxxx input field
    document.getElementById("tasname").style.display = "none";
    document.getElementById("confirmertask").style.display = "none";
  }
});

/*export async function tableData(taskkeyview){
  return taskkeyview;
}*/

//1: Get Current Date in JavaScript
function getCurrentDate() {
  // Date object
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  // Current Date
  var date = yyyy + "-" + mm + "-" + dd;
  return date;
}

function couchProvider(hostname, portnumber, username, password, typebasename) {
  //alert
  var elementOutId = "modaloutalerttasks";
  var elementDropId = "backdropoutalerttasks";
  var buttonHandler = "ouiHandlerAlertTasksBtn";

  var dbName = "";
  var remoteCouch = "";
  var options = {
    live: true,
    retry: true,
    continuous: true,
    attachments: true,
  };

  try {
    dbName = new PouchDB(typebasename);
    remoteCouch = new PouchDB(
      "http://" +
        username +
        ":" +
        password +
        "@" +
        hostname +
        ":" +
        portnumber +
        "/" +
        typebasename
    );
    PouchDB.plugin(PouchFind);
    dbName.sync(remoteCouch, options);
  } catch (error) {
    var pTexteerreurconnection = "Connection Failed";
    alertModal(
      elementOutId,
      elementDropId,
      pTexteerreurconnection,
      buttonHandler
    );
  }
}

/**1: alertmodal function*/
function alertModal(elementOutId, elementDropId, pTexte, buttonHandler) {
  var divClearModal = document.getElementById(elementOutId);
  let divModal = document.createElement("div");
  divModal.innerHTML = `<div class="modalalert">
        <p>${pTexte}</p>
        <button class="btnalert btnalert--alt" id="${buttonHandler}">OK</button>
    </div>`;
  divClearModal.appendChild(divModal);

  var divClearBackdrop = document.getElementById(elementDropId);
  let divBack = document.createElement("div");
  divBack.innerHTML = `<div class="backdropalert"></div>`;
  divClearBackdrop.appendChild(divBack);

  var buttonChange = document.getElementById(buttonHandler);
  buttonChange.addEventListener("click", (e) => {
    e.preventDefault();

    var divClearModal = document.getElementById(elementOutId);
    while (divClearModal.hasChildNodes()) {
      divClearModal.removeChild(divClearModal.childNodes[0]);
    }

    var divClearModalDrop = document.getElementById(elementDropId);
    while (divClearModalDrop.hasChildNodes()) {
      divClearModalDrop.removeChild(divClearModalDrop.childNodes[0]);
    }
  });
}
