import React from 'react';
import '../pouchqueries';
import '../queries.css';

import { 
    SelectTable, 
    SelectOneColumn, 
    SelectTwoColumn, 
    SelectThreeColumn,

    WhereTable, 
    WhereOneColumn, 
    WhereTwoColumn, 
    WhereThreeColumn,

    CountTable, 
    CountOneColumn, 
    CountTwoColumn, 
    CountThreeColumn,

    UnionTable, 
    UnionTwoDocument,
    UnionTwoDocOneColumn, 
    UnionTwoDocTwoColumn, 
    UnionTwoDocThreeColumn,  
    
    UnionThreeDocument, 
    UnionThreeDocOneColumn, 
    UnionThreeDocTwoColumn, 
    UnionThreeDocThreeColumn, 
} from './Components';

import { 
    //QueriesContainer, 
    //TextBase,
    //ServeurBaseContainer, 
    //ServeurButtonBase, 
    //AccesButtonBase, 
    FormTable, 
    FormScroller,
    //TextForm, 
    TextLocal, 
    QueryButtons, 
    Buttons, 
    QueriesUpper,
    //QueriesTextArea,
    ButtonDiv, 
    StyleButton, 
    //FormScroller,
    //ButtonsBottom,
    //QueryAdd, 
    //QueryEdit, 
    //QueryDelete,
} from '../QueriesStyles';
import { SmallInput, LargeInput } from '../../serveur/ServeurStyles';

function EditorQueries() {
    return ( 
        <div id="editorqueries">
            <br/>
            <TextLocal>
                <label id='editorquerytextform'>Query Editor</label>
            </TextLocal>
            <FormScroller>
                <div style={{display:"none"}}>
                    <div>
                        <SmallInput id='databasequery' type='text' placeholder='database' readOnly />
                    </div>
                    <div>
                        <SmallInput id='templatequery' type='text' placeholder='template' readOnly />
                    </div>
                    <div>
                        <SmallInput id='serverquery' type='text' placeholder='server' readOnly />
                    </div>
                    <div>
                        <SmallInput id='documentquery' type='text' placeholder='document' readOnly />
                    </div>
                    <div>
                        <SmallInput id='selectedtquery' type='text' placeholder='selected query' readOnly />
                    </div>
                    <div>
                        <SmallInput id='querycol_1' type='text' placeholder='column 1' readOnly />
                    </div>
                    <div>
                        <SmallInput id='querycol_2' type='text' placeholder='column 2' readOnly />
                    </div>
                    <div>
                        <SmallInput id='querycol_3' type='text' placeholder='column 3' readOnly />
                    </div>
                    <div>
                        <SmallInput id='colname_1' type='text' placeholder='columname 1' readOnly />
                    </div>
                    <div>
                        <SmallInput id='colname_2' type='text' placeholder='columname 2' readOnly />
                    </div>
                    <div>
                        <SmallInput id='colname_3' type='text' placeholder='columname 3' readOnly />
                    </div>
                </div>
                <br/>
                <FormTable id="formeditorqueries" autoComplete='off'>
                    <QueryButtons id='queryeditor'>
                        <Buttons>
                            <QueriesUpper id='selectquery'>Select</QueriesUpper>
                        </Buttons>
                        <Buttons>
                            <QueriesUpper id='wherequery'>Where</QueriesUpper>
                        </Buttons>
                        <Buttons>
                            <QueriesUpper id='countquery'>Count</QueriesUpper>
                        </Buttons>
                        <Buttons>
                            <QueriesUpper id='unionquery'>Union</QueriesUpper>
                        </Buttons>
                        <Buttons style={{display:'none'}}>
                            <QueriesUpper id='intersectquery'>Intersect</QueriesUpper>
                        </Buttons>
                    </QueryButtons>
                    <div>
                        <SelectTable />
                        <SelectOneColumn />
                        <SelectTwoColumn />
                        <SelectThreeColumn />
                        <WhereTable />
                        <WhereOneColumn />
                        <WhereTwoColumn />
                        <WhereThreeColumn />

                        <CountTable /> 
                        <CountOneColumn /> 
                        <CountTwoColumn />
                        <CountThreeColumn />

                        <UnionTable />
                        <UnionTwoDocument />
                        <UnionTwoDocOneColumn />
                        <UnionTwoDocTwoColumn />
                        <UnionTwoDocThreeColumn />

                        <UnionThreeDocument />
                        <UnionThreeDocOneColumn />
                        <UnionThreeDocTwoColumn />
                        <UnionThreeDocThreeColumn />
                    </div>
                    <br/>
                    <div id="outputquery">
                        <LargeInput id='writequery' type='text' placeholder='complete query' />
                    </div>
                    <br/>
                </FormTable>
                <br/><br/>
                <ButtonDiv id='executerquery'>
                    <StyleButton id='executequery'>Execute</StyleButton>
                </ButtonDiv>
                <br/>
            </FormScroller>
        </div>
    );
}

export default EditorQueries;