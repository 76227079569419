import React from 'react';
import '../pouchtasks';
import '../tasks.css';
import { 
    LargeInput, 
    ServeurContainer, 
    SmallInput, 
    PortInput , 
    FormScroller, 
} from '../../serveur/ServeurStyles';
import { ButtonDiv, StyleButton } from '../TasksStyles';

function DeskTasks() {
    return ( 
        <div id='desktasks'>
            <br/>
            <FormScroller>
                <ServeurContainer>
                    <div>
                        <SmallInput id='hostnametask' type='text' placeholder='Hostname' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='hostporttask' type='text' placeholder='Port' />
                    </div>
                </ServeurContainer>
                <br/>
                <div>
                    <LargeInput id='usernametask' type='text' placeholder='Username' />
                </div>
                <br/>
                <div>
                    <LargeInput id='passwordnametask' type='password' placeholder='Password' />
                </div>
                <br/>
                <div id='tasname'>
                    <LargeInput id='taskname' type='text' placeholder='Database Name' />
                </div>
                
                <div style={{display:"none"}} id='servertaskname'>
                    <LargeInput id='servertaskname' type='text' placeholder='server Name' />
                </div>
                <br/>
                <ButtonDiv id='confirmertask'>
                    <StyleButton id='confirmtask'>Confirm</StyleButton>
                </ButtonDiv>
                <br/>
            </FormScroller>
        </div>
    );
}

export default DeskTasks;