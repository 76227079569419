import React from 'react';
import './pouchserveur';
import './server.css';

import DeskServer from './composerver/DeskServer';
import MobileServer from './composerver/MobileServer';

import { 
    ContainerServer, 
    TextServeur, 
    ServeurContainer, 
    ServeurBouton, 
    BoutonAcces, 
    ServeurForm, 
    TextForm,
    TextLocal,  
    TestButtons, 
    Test, 
    Cancel, 
    Okay,
} from './ServeurStyles';

function Serveur() {
    return ( 
        <ContainerServer id="serveur">
            <br/>
            <TextServeur>
                <label>CouchDB Connection Test</label>
            </TextServeur>
            <br/>
            <ServeurContainer>
                <ServeurBouton>
                    <BoutonAcces id='localservertest'>Local</BoutonAcces>
                </ServeurBouton>
                <ServeurBouton>
                    <BoutonAcces id='remoteservertest'>Remote</BoutonAcces>
                </ServeurBouton>
            </ServeurContainer>
            <br/><br/>
            <ServeurForm id='formserver'>
                <TextForm>
                    <label id='typeform'>Type Form</label>
                </TextForm>
                <TextLocal>
                    <label id='testmobile'>Mobile IP Address -- Port:3000</label>
                </TextLocal>
                <br/>
                <form>
                    <DeskServer />
                    <MobileServer />
                    <br/>
                    <ServeurContainer>
                        <TestButtons id='tester'>
                            <Test id='testconnection'>Test</Test>
                        </TestButtons>
                        <TestButtons>
                            <Cancel id='cancelconnection'>Cancel</Cancel>
                        </TestButtons>
                        <TestButtons id='sauvegarder'>
                            <Okay id='saveconnection'>Save</Okay>
                        </TestButtons>
                    </ServeurContainer>

                    <div id="modaloutserver"></div>
                    <div id="backdropoutserver"></div>

                    <div id="modaloutalertserver"></div>
                    <div id="backdropoutalertserver"></div>
                </form>
            </ServeurForm>
        </ContainerServer>
    );
}

export default Serveur;