import React from 'react';
import '../pouchtasks';
import '../tasks.css';
import { 
    LargeInput,  
    FormScroller, 
} from '../../serveur/ServeurStyles';
import { ButtonDiv, StyleButton } from '../TasksStyles';

function MobileTasks() {
    return ( 
        <div id="mobiletasks">
            <br/>
            <FormScroller>
                <div>
                    <LargeInput id='mobileusernametask' type='text' placeholder='Username' />
                </div>
                <br/>
                <div>
                    <LargeInput id='mobilepasswordnametask' type='password' placeholder='Password' />
                </div>
                
                <div style={{display:'none'}} id='mobiletasname'>
                    <LargeInput id='mobiletaskname' type='text' placeholder='Database Name' />
                </div>
                
                <div style={{display:"none"}} id='mobileservertaskname'>
                    <LargeInput id='mobileservertaskname' type='text' placeholder='server Name' />
                </div>
                <br/>
                <ButtonDiv id='mobileconfirmertask'>
                    <StyleButton id='mobileconfirmtask'>Confirm</StyleButton>
                </ButtonDiv>
                <br/>
            </FormScroller>
        </div>
    );
}

export default MobileTasks;