import React from 'react';
import '../pouchtables';
import '../tables.css';

import { 
    LargeInput, 
    ServeurContainer, 
    SmallInput, 
    PortInput , 
    FormScroller, 
} from '../../serveur/ServeurStyles';
import { ButtonDiv, StyleButton } from '../TablesStyles';

function DeskTables() {
    return ( 
        <div id="desktables">
            <br/>
            <FormScroller>
                <ServeurContainer>
                    <div>
                        <SmallInput id='hostnametable' type='text' placeholder='Hostname' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='hostporttable' type='text' placeholder='Port' />
                    </div>
                </ServeurContainer>
                <br/>
                <div>
                    <LargeInput id='usernametable' type='text' placeholder='Username' />
                </div>
                <br/>
                <div>
                    <LargeInput id='passwordnametable' type='password' placeholder='Password' />
                </div>
                <br/>
                <div id='tabname'>
                    <LargeInput id='tablename' type='text' placeholder='Database Name' />
                </div>
                
                <div style={{display:"none"}} id='servertablename'>
                    <LargeInput id='servertablename' type='text' placeholder='server Name' />
                </div>
                <br/>
                <ButtonDiv id='confirmertable'>
                    <StyleButton id='confirmtable'>Confirm</StyleButton>
                </ButtonDiv>
                <br/>
            </FormScroller>
        </div>
    );
}

export default DeskTables;