import React from 'react';
import './pouchSidebar';

import {GiHamburgerMenu} from 'react-icons/gi';
import {FiDatabase, FiServer, FiTool} from 'react-icons/fi';
import {BsTable} from 'react-icons/bs';
import {SiJquery} from 'react-icons/si';
import {SiReadthedocs} from 'react-icons/si';
import {SideContainer, BurgerIcons, CenterIcons, MainBottom, SubBottom, Img } from './SidebarStyles';
import Couch from './../projectimage/couchdb-30.png';

function Sidebar() {
    return ( 
        <SideContainer>
            <form>
            <BurgerIcons id='burgerclick'>
                <GiHamburgerMenu size="25px" /> 
            </BurgerIcons>
            <CenterIcons id='serverover'>
                <FiServer size="25px" /> 
            </CenterIcons>
            <CenterIcons id='databaseover'>
                <FiDatabase size="25px" /> 
            </CenterIcons>
            <CenterIcons id='tableover'>
                <BsTable size="25px" /> 
            </CenterIcons>
            <CenterIcons id='toolover'>
                <FiTool size="25px" /> 
            </CenterIcons>
            <CenterIcons id='queryover'>
                <SiJquery size="25px" /> 
            </CenterIcons>
            <CenterIcons id='documentover'>
                <SiReadthedocs size="25px" /> 
            </CenterIcons>
            <MainBottom>
                <SubBottom>
                    <Img src={Couch} />
                </SubBottom>
            </MainBottom>

            <div id="modaloutsidebar"></div>
            <div id="backdropoutsidebar"></div>
            </form>
        </SideContainer>
    );
}

export default Sidebar;