import React from 'react';
import './pouchqueries';
import './queries.css';

import ConnectQueries from './crudqueries/ConnectQueries';
import ListViewQueries from './crudqueries/ListViewQueries';
import ViewDocQueries from './crudqueries/ViewDocQueries';
import ViewDisplayQueries from './crudqueries/ViewDisplayQueries';
import EditorQueries from './crudqueries/EditorQueries';
import DynamicTableQueries from './crudqueries/DynamicTableQueries';

import { 
    QueriesContainer, 
    TextBase,
    ServeurBaseContainer, 
    ServeurButtonBase, 
    AccesButtonBase, 
    FormTable, 
    TextForm, 
    TextLocal,  
    QueryBase, 
} from './QueriesStyles';

function Queries() {
    return ( 
        <QueriesContainer id="queriescontainer">
            <br/>
            <TextBase>
                <label id='queriestitle'>Queries Management</label>
            </TextBase>
            <br/>
            <ServeurBaseContainer>
                <ServeurButtonBase>
                    <AccesButtonBase id='localqueriesserver'>Local</AccesButtonBase>
                </ServeurButtonBase>
                <ServeurButtonBase>
                    <AccesButtonBase id='remotequeriesserver'>Remote</AccesButtonBase>
                </ServeurButtonBase>
            </ServeurBaseContainer>
            <br/><br/>
            <FormTable id="documentqueries">
                <TextForm>
                    <label id='queriestextform'>Queries Form</label>
                </TextForm>
                <TextLocal>
                    <label id='textquerieslocal'>Local / Remote Database</label>
                </TextLocal>
                <br/>
                <br/>
                <QueryBase id="querydocumentqueries">
                    <ConnectQueries />
                    <ListViewQueries />
                    <ViewDisplayQueries />
                    <ViewDocQueries />
                    <EditorQueries />
                    <DynamicTableQueries />
                </QueryBase>
                <br/>
                <div id="modaloutqueries"></div>
                <div id="backdropoutqueries"></div>

                <div id="modaloutalertqueries"></div>
                <div id="backdropoutalertqueries"></div>
            </FormTable>
        </QueriesContainer>
    );
}

export default Queries;