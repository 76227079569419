import React from 'react';
import '../pouchqueries';
import '../queries.css';
import {  
    TextLocal, 
    FormScroller, 
} from '../../serveur/ServeurStyles';

function ViewDocQueries() {
    
    return ( 
        <div id="viewdocqueries">
            <br/>
            <FormScroller>
                <TextLocal>
                    <label id='textviewdocumentquery'>Select document to query</label>
                </TextLocal>
                <br/>
                <div className='resultsList'>
                    <table className='thin_table_config'>
                        <thead>
                            <tr>
                                <th>Template</th>
                                <th>Document</th>
                            </tr>
                        </thead>
                        <tbody id='viewDocumentQueryTableBody'></tbody>
                    </table>
                </div>  
            </FormScroller>
        </div>
    );
}

export default ViewDocQueries;