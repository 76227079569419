import React from 'react';
import '../pouchtables';
import '../tables.css';

import {FormList} from '../TablesStyles';

function ListTables() {
    return ( 
        <div id="listtables">
            <br/>
            <FormList>
                <div className='resultsList'>
                    <table className='thin_table_config'>
                        <thead>
                            <tr>
                                <th>Table</th>
                                <th>ID</th>
                            </tr>
                        </thead>
                        <tbody id='editTableTableBody'></tbody>
                    </table>
                </div>
            </FormList>
        </div>
    );
}

export default ListTables;