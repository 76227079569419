import React from 'react';

//import Sidebar from "../Sidebar/Sidebar";
//import Header from '../components/Header/Header';
import { Container } from './LayoutStyles';

export const Layout = ({children}) => {
  return (
    <Container>
      <main>{children}</main> 
    </Container>
  )
}