import React from 'react';
import '../pouchtasks';
import '../tasks.css';
import {  
    ServeurContainer, 
    SmallInput, 
    PortInput , 
    FormScroller, 
} from '../../serveur/ServeurStyles';
import { StyleTableButton,  StyleUndoButton } from '../TasksStyles';

function ChangeDelEditTasks() {
    return ( 
        <div id="changedeledittasks">
            <br/>
            <FormScroller>
                <ServeurContainer>
                    <div>
                        <SmallInput id='nametaskchangedeledit' type='text' placeholder='Table Name' readOnly />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='fieldtaskchangedeledit' type='number' min="1" max="20" placeholder='Columns' readOnly />
                    </div>
                </ServeurContainer>
                <div style={{display:'none'}}>
                    <div>
                        <SmallInput id='taskidchangedeledit' type='text' placeholder='Table Id' />
                    </div>
                    <div>
                        <SmallInput id='taskdatabasechangedeledit' type='text' placeholder='Table database' />
                    </div>
                    <div>
                        <SmallInput id='tasknamechangedeledit' type='text' placeholder='Table name' />
                    </div>
                    <div>
                        <SmallInput id='taskserverchangedeledit' type='text' placeholder='Table server' />
                    </div>
                    <div>
                        <SmallInput id='taskconnectchangedeledit' type='text' placeholder='Table connect' />
                    </div>
                    <div>
                        <SmallInput id='taskdatechangedeledit' type='text' placeholder='Table date' />
                    </div>
                </div>
                <br/>
                <div id='tasktemplatechangedeledit'></div>
                <br/>
                <ServeurContainer>
                    <StyleTableButton id='confirmtasknamechangedeledit'>Confirm</StyleTableButton>
                    &nbsp;&nbsp;
                    <StyleUndoButton id='canceltasknamechangedeledit'>Cancel</StyleUndoButton>
                </ServeurContainer>
            </FormScroller>
        </div>
    );
}

export default ChangeDelEditTasks;