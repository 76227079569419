import React from 'react';
import '../../pouchdocumentation';

import { 
    TextContainer,
    TextTitle, 
    TextContent, 
    SubLink,  
} from '../../DocumentationStyles';

function Consistency() {
    return ( 
        <TextContainer id="consistency">
            <TextTitle>5.Eventual Consistency</TextTitle>
            <TextContent>
                <strong>How Couchbench handles synchronization</strong><br/>
                Couchbench's background sync is that if the same event is queued multiple times while offline, 
                it fires once when online naming distinct events for specific synchronization periods 
                events on predetermined interval. A Feature that is not available in any browsers. 
                Background Sync is well supported in Chrome, but work is in process to bring Background 
                Sync to other browsers.
            </TextContent>
            <TextContent>
                CouchDB's flexibility allows data to evolve as applications grow and change. 
                More detailed documentation can be found by clicking on the attached link.
                <a href='https://docs.couchdb.org/en/latest/intro/consistency.html' style={{textDecoration:'none',color:'blue'}}>Working with the Grain...</a>
            </TextContent>
            <SubLink id='introcontinstallation'>6.Installation</SubLink>
            <br/><br/>
        </TextContainer>
    );
}

export default Consistency;