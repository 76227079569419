import React from 'react';
import '../pouchqueries';
import '../queries.css';

import DeskConQueries from '../compoqueries/DeskConQueries';
import MobileConQueries from '../compoqueries/MobileConQueries';

/*import { 
    TextLocal, 
    LargeInput, 
    ServeurContainer, 
    SmallInput, 
    PortInput , 
    FormScroller, 
} from '../../serveur/ServeurStyles';
import { ButtonDiv, StyleButton } from '../QueriesStyles';*/

function ConnectQueries() {
    return ( 
        <div id="connectqueries">
           <DeskConQueries />
           <MobileConQueries /> 
        </div>
    );
}

export default ConnectQueries;