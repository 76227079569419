import React from 'react';
import '../pouchserveur';
import '../server.css';

import { 
    ServeurContainer, 
    LargeInput, 
    SmallInput, 
    PortInput, 
} from '../ServeurStyles';

function DeskServer() {
    return ( 
        <div id='deskserver'>
            <div>
                <LargeInput id='connectname' type='text' placeholder='Connection Name' />
            </div>
            <br/>
            <ServeurContainer>
                <div>
                    <SmallInput id='hostname' type='text' placeholder='Hostname' />
                </div>
                &nbsp;&nbsp;
                <div>
                    <PortInput id='hostport' type='text' placeholder='Port' />
                </div>
            </ServeurContainer>
            <br/>
            <div>
                <LargeInput id='username' type='text' placeholder='Username' />
            </div>
            <br/>
            <div>
                <LargeInput id='passwordname' type='password' placeholder='Password' />
            </div>
            <br/>
            <div style={{display:"none"}}>
                <LargeInput id='databasename' type='text' placeholder='Database Name' />
            </div>
            <br/>
        </div>
    );
}

export default DeskServer;