import React from 'react';
import '../pouchserveur';
import '../server.css';

import {  
    LargeInput,  
} from '../ServeurStyles';

function MobileServer() {
    return ( 
        <div id='mobileserver'>
            <div>
                <LargeInput id='mobileconnectname' type='text' placeholder='Connection Name' />
            </div>
            <br/>
            <div>
                <LargeInput id='mobileusername' type='text' placeholder='Username' />
            </div>
            <br/>
            <div>
                <LargeInput id='mobilepasswordname' type='password' placeholder='Password' />
            </div>
            <br/>
            <div style={{display:"none"}}>
                <LargeInput id='mobiledatabasename' type='text' placeholder='Database Name' />
            </div>
            <br/>
        </div>
    );
}

export default MobileServer;