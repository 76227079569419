import { 
    TextLocal, 
    LargeInput,  
    FormScroller, 
} from '../../serveur/ServeurStyles';
import { ButtonDiv, StyleButton } from '../QueriesStyles';

function MobileConQueries() {
    return ( 
        <div id="mobileconqueries">
            <br/>
            <FormScroller>
                <TextLocal>
                    <label id='connectquerytextform'>Database Connection </label>
                </TextLocal>
                <br/>
                <div>
                    <LargeInput id='mobileusernamequery' type='text' placeholder='Username' />
                </div>
                <br/>
                <div>
                    <LargeInput id='mobilepasswordnamequery' type='password' placeholder='Password' />
                </div>
                
                <div style={{display:'none'}} id='mobilequername'>
                    <LargeInput id='mobilequeryname' type='text' placeholder='Database Name' />
                </div>
                <br/>
                <ButtonDiv id='mobileconfirmerquery'>
                    <StyleButton id='mobileconfirmquery'>Connect</StyleButton>
                </ButtonDiv>
                <br/>
            </FormScroller>
        </div>
    );
}

export default MobileConQueries;