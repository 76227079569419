import React from 'react';
import '../pouchtables';
import '../tables.css';

import { 
    LargeInput, 
    FormScroller, 
} from '../../serveur/ServeurStyles';
import { ButtonDiv, StyleButton } from '../TablesStyles';

function MobileTables() {
    return ( 
        <div id="mobiletables">
            <br/>
            <FormScroller>
                <div>
                    <LargeInput id='mobileusernametable' type='text' placeholder='Username' />
                </div>
                <br/>
                <div>
                    <LargeInput id='mobilepasswordnametable' type='password' placeholder='Password' />
                </div>
                <br/>
                <div style={{display:"none"}} id='mobiletabname'>
                    <LargeInput id='mobiletablename' type='text' placeholder='Database Name' />
                </div>
                
                <div style={{display:"none"}} id='mobileservertablename'>
                    <LargeInput id='mobileservertablename' type='text' placeholder='server Name' readOnly />
                </div>
                <br/>
                <ButtonDiv id='mobileconfirmertable'>
                    <StyleButton id='mobileconfirmtable'>Confirm</StyleButton>
                </ButtonDiv>
                <br/>
            </FormScroller>
        </div>
    );
}

export default MobileTables;