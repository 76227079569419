//import Pouch from 'pouchdb';
//import PouchFind from 'pouchdb-find';

window.addEventListener('load', () => {
    document.getElementById('my_div').style.display = 'none';

    //burger icon
    document.getElementById('burgerclick').addEventListener('click', burgerclickChange);
    async function burgerclickChange(e){
        e.preventDefault();

        document.getElementById('principal').style.display = 'block';
        document.getElementById('basecontaineur').style.display = 'none';
        document.getElementById('serveur').style.display = 'none';
        document.getElementById('tablescontainer').style.display = 'none';
        document.getElementById('taskscontainer').style.display = 'none';
        document.getElementById('queriescontainer').style.display = 'none';
        document.getElementById('documentcontainer').style.display = 'none';
        //xxxxx
        var serveurHeader = document.getElementById('serveurheader');
        serveurHeader.innerText = "Server";
        serveurHeader.style.color = 'black';
        var databaseHeader = document.getElementById('databaseheader');
        databaseHeader.innerText = "Database";
        databaseHeader.style.color = 'black';
        var tableHeader = document.getElementById('tableheader');
        tableHeader.innerText = "Table";
        tableHeader.style.color = 'black';
    }

    //databases
    document.getElementById('databaseover').addEventListener('mouseover', databaseoverChange);
    async function databaseoverChange(e){
        e.preventDefault();

        const divEl = document.getElementById('my_div');
        divEl.innerText = "Databases";
        document.getElementById('my_div').style.display = 'block';
    }
    document.getElementById('databaseover').addEventListener('mouseleave', databaseleaveChange);
    async function databaseleaveChange(e){
        e.preventDefault();

        document.getElementById('my_div').style.display = 'none';
    }
    document.getElementById('databaseover').addEventListener('click', databaseclickChange);
    async function databaseclickChange(e){
        e.preventDefault();

        var divClearModal = document.getElementById('modaloutsidebar');
        let divModal = document.createElement('div');
        divModal.innerHTML =
            `<div class="modal">
                <p>Running Couchbench on?</p>
                <button class="btn btn--alt" id="ouiHandlerSideBarBtn">Mobile</button>
                <button class="btn" id="nonHandlerSideBarBtn">PCs</button>
            </div>`;
        divClearModal.appendChild(divModal);

        var divClearBackdrop = document.getElementById('backdropoutsidebar');
        let divBack = document.createElement('div');
        divBack.innerHTML =
            `<div class="backdrop" id="backdropHandlerSideBarBtn"></div>`;
        divClearBackdrop.appendChild(divBack);

        //If Yes Clicked
        document.getElementById('ouiHandlerSideBarBtn').addEventListener('click', ouiHandlerSideBarBtnChange);
        async function ouiHandlerSideBarBtnChange(e){
            e.preventDefault();

            var deviceStr = document.getElementById('device');
            deviceStr.innerText = 'mobile';

            var divClearModal = document.getElementById('modaloutsidebar');
            while(divClearModal.hasChildNodes()) {
                divClearModal.removeChild(divClearModal.childNodes[0]);
            }

            var divClearBackdrop = document.getElementById('backdropoutsidebar');
            while(divClearBackdrop.hasChildNodes()) {
                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
            }

            document.getElementById('principal').style.display = 'none';
            document.getElementById('serveur').style.display = 'none';
            document.getElementById('basecontaineur').style.display = 'block';
            document.getElementById('tablescontainer').style.display = 'none';
            document.getElementById('databasetasks').style.display = 'none';
            document.getElementById('taskscontainer').style.display = 'none';
            document.getElementById('queriescontainer').style.display = 'none';
            document.getElementById('documentcontainer').style.display = 'none';
            //xxxxxxxx
            var serveurHeader = document.getElementById('serveurheader');
            serveurHeader.innerText = "Server";
            serveurHeader.style.color = 'black';
            var databaseHeader = document.getElementById('databaseheader');
            databaseHeader.innerText = "database";
            databaseHeader.style.color = 'red';
            var tableHeader = document.getElementById('tableheader');
            tableHeader.innerText = "Table";
            tableHeader.style.color = 'black';
        }

        //If No Clicked
        document.getElementById('nonHandlerSideBarBtn').addEventListener('click', nonHandlerSideBarBtnChange);
        async function nonHandlerSideBarBtnChange(e){
            e.preventDefault();

            var divClearDevice = document.getElementById('device');
            while(divClearDevice.hasChildNodes()) {
                divClearDevice.removeChild(divClearDevice.childNodes[0]);
            }

            var divClearModal = document.getElementById('modaloutsidebar');
            while(divClearModal.hasChildNodes()) {
                divClearModal.removeChild(divClearModal.childNodes[0]);
            }

            var divClearBackdrop = document.getElementById('backdropoutsidebar');
            while(divClearBackdrop.hasChildNodes()) {
                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
            }

            document.getElementById('principal').style.display = 'none';
            document.getElementById('serveur').style.display = 'none';
            document.getElementById('basecontaineur').style.display = 'block';
            document.getElementById('tablescontainer').style.display = 'none';
            document.getElementById('databasetasks').style.display = 'none';
            document.getElementById('taskscontainer').style.display = 'none';
            document.getElementById('queriescontainer').style.display = 'none';
            document.getElementById('documentcontainer').style.display = 'none';
            //xxxxxxxx
            var serveurHeader = document.getElementById('serveurheader');
            serveurHeader.innerText = "Server";
            serveurHeader.style.color = 'black';
            var databaseHeader = document.getElementById('databaseheader');
            databaseHeader.innerText = "database";
            databaseHeader.style.color = 'red';
            var tableHeader = document.getElementById('tableheader');
            tableHeader.innerText = "Table";
            tableHeader.style.color = 'black';
        };

        //If Backdrop Clicked
        document.getElementById('backdropHandlerSideBarBtn').addEventListener('click', backdropHandlerSideBarBtnChange);
        async function backdropHandlerSideBarBtnChange(e){
            e.preventDefault();

            var divClearDevice = document.getElementById('device');
            while(divClearDevice.hasChildNodes()) {
                divClearDevice.removeChild(divClearDevice.childNodes[0]);
            }

            var divClearModal = document.getElementById('modaloutsidebar');
            while(divClearModal.hasChildNodes()) {
                divClearModal.removeChild(divClearModal.childNodes[0]);
            }

            var divClearBackdrop = document.getElementById('backdropoutsidebar');
            while(divClearBackdrop.hasChildNodes()) {
                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
            }

            document.getElementById('principal').style.display = 'block';
            document.getElementById('serveur').style.display = 'none';
            document.getElementById('basecontaineur').style.display = 'none';
            document.getElementById('tablescontainer').style.display = 'none';
            document.getElementById('databasetasks').style.display = 'none';
            document.getElementById('taskscontainer').style.display = 'none';
            document.getElementById('queriescontainer').style.display = 'none';
            document.getElementById('documentcontainer').style.display = 'none';
            //xxxxxxxx
            var serveurHeader = document.getElementById('serveurheader');
            serveurHeader.innerText = "Server";
            serveurHeader.style.color = 'black';
            var databaseHeader = document.getElementById('databaseheader');
            databaseHeader.innerText = "database";
            databaseHeader.style.color = 'black';
            var tableHeader = document.getElementById('tableheader');
            tableHeader.innerText = "Table";
            tableHeader.style.color = 'black';
        }
    };

    //servers
    document.getElementById('serverover').addEventListener('mouseover', serveroverChange);
    async function serveroverChange(e){
        e.preventDefault();

        const divEl = document.getElementById('my_div');
        divEl.innerText = "Servers";
        document.getElementById('my_div').style.display = 'block';
    }
    document.getElementById('serverover').addEventListener('mouseleave', serverleaveChange);
    async function serverleaveChange(e){
        e.preventDefault();

        document.getElementById('my_div').style.display = 'none';
    }
    document.getElementById('serverover').addEventListener('click', serverclickChange);
    async function serverclickChange(e){
        e.preventDefault();

        var divClearModal = document.getElementById('modaloutsidebar');
        let divModal = document.createElement('div');
        divModal.innerHTML =
            `<div class="modal">
                <p>Running Couchbench on?</p>
                <button class="btn btn--alt" id="ouiHandlerSideBarBtn">Mobile</button>
                <button class="btn" id="nonHandlerSideBarBtn">PCs</button>
            </div>`;
        divClearModal.appendChild(divModal);

        var divClearBackdrop = document.getElementById('backdropoutsidebar');
        let divBack = document.createElement('div');
        divBack.innerHTML =
            `<div class="backdrop" id="backdropHandlerSideBarBtn"></div>`;
        divClearBackdrop.appendChild(divBack);

        //If Yes Clicked
        document.getElementById('ouiHandlerSideBarBtn').addEventListener('click', ouiHandlerSideBarBtnChange);
        async function ouiHandlerSideBarBtnChange(e){
            e.preventDefault();

            var deviceStr = document.getElementById('device');
            deviceStr.innerText = 'mobile';

            var divClearModal = document.getElementById('modaloutsidebar');
            while(divClearModal.hasChildNodes()) {
                divClearModal.removeChild(divClearModal.childNodes[0]);
            }

            var divClearBackdrop = document.getElementById('backdropoutsidebar');
            while(divClearBackdrop.hasChildNodes()) {
                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
            }

            document.getElementById('principal').style.display = 'none';
            document.getElementById('basecontaineur').style.display = 'none';
            document.getElementById('tablescontainer').style.display = 'none';
            document.getElementById('taskscontainer').style.display = 'none';
            document.getElementById('queriescontainer').style.display = 'none';
            document.getElementById('documentcontainer').style.display = 'none';
            document.getElementById('serveur').style.display = 'block';
            document.getElementById('formserver').style.display = 'none';
            var databaseHeader = document.getElementById('databaseheader');
            databaseHeader.innerText = "Database";
            databaseHeader.style.color = 'black';
            var serveurHeader = document.getElementById('serveurheader');
            serveurHeader.innerText = "Server";
            serveurHeader.style.color = 'red';
            var tableHeader = document.getElementById('tableheader');
            tableHeader.innerText = "Table";
            tableHeader.style.color = 'black';
        };

        //If No Clicked
        document.getElementById('nonHandlerSideBarBtn').addEventListener('click', nonHandlerSideBarBtnChange);
        async function nonHandlerSideBarBtnChange(e){
            e.preventDefault();

            var divClearDevice = document.getElementById('device');
            while(divClearDevice.hasChildNodes()) {
                divClearDevice.removeChild(divClearDevice.childNodes[0]);
            }

            var divClearModal = document.getElementById('modaloutsidebar');
            while(divClearModal.hasChildNodes()) {
                divClearModal.removeChild(divClearModal.childNodes[0]);
            }

            var divClearBackdrop = document.getElementById('backdropoutsidebar');
            while(divClearBackdrop.hasChildNodes()) {
                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
            }

            document.getElementById('principal').style.display = 'none';
            document.getElementById('basecontaineur').style.display = 'none';
            document.getElementById('tablescontainer').style.display = 'none';
            document.getElementById('taskscontainer').style.display = 'none';
            document.getElementById('queriescontainer').style.display = 'none';
            document.getElementById('documentcontainer').style.display = 'none';
            document.getElementById('serveur').style.display = 'block';
            document.getElementById('formserver').style.display = 'none';
            var databaseHeader = document.getElementById('databaseheader');
            databaseHeader.innerText = "Database";
            databaseHeader.style.color = 'black';
            var serveurHeader = document.getElementById('serveurheader');
            serveurHeader.innerText = "Server";
            serveurHeader.style.color = 'red';
            var tableHeader = document.getElementById('tableheader');
            tableHeader.innerText = "Table";
            tableHeader.style.color = 'black';
        };

        //If Backdrop Clicked
        document.getElementById('backdropHandlerSideBarBtn').addEventListener('click', backdropHandlerSideBarBtnChange);
        async function backdropHandlerSideBarBtnChange(e){
            e.preventDefault();

            var divClearDevice = document.getElementById('device');
            while(divClearDevice.hasChildNodes()) {
                divClearDevice.removeChild(divClearDevice.childNodes[0]);
            }

            var divClearModal = document.getElementById('modaloutsidebar');
            while(divClearModal.hasChildNodes()) {
                divClearModal.removeChild(divClearModal.childNodes[0]);
            }

            var divClearBackdrop = document.getElementById('backdropoutsidebar');
            while(divClearBackdrop.hasChildNodes()) {
                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
            }

            document.getElementById('principal').style.display = 'block';
            document.getElementById('basecontaineur').style.display = 'none';
            document.getElementById('tablescontainer').style.display = 'none';
            document.getElementById('taskscontainer').style.display = 'none';
            document.getElementById('queriescontainer').style.display = 'none';
            document.getElementById('documentcontainer').style.display = 'none';
            document.getElementById('serveur').style.display = 'none';
            document.getElementById('formserver').style.display = 'none';
            var databaseHeader = document.getElementById('databaseheader');
            databaseHeader.innerText = "Database";
            databaseHeader.style.color = 'black';
            var serveurHeader = document.getElementById('serveurheader');
            serveurHeader.innerText = "Server";
            serveurHeader.style.color = 'red';
            var tableHeader = document.getElementById('tableheader');
            tableHeader.innerText = "Table";
            tableHeader.style.color = 'black';
        }
    }

    //tables
    document.getElementById('tableover').addEventListener('mouseover', tableoverChange);
    async function tableoverChange(e){
        e.preventDefault();

        const divEl = document.getElementById('my_div');
        divEl.innerText = "Tables";
        document.getElementById('my_div').style.display = 'block';
    }
    document.getElementById('tableover').addEventListener('mouseleave', tableleaveChange);
    async function tableleaveChange(e){
        e.preventDefault();

        document.getElementById('my_div').style.display = 'none';
    }
    document.getElementById('tableover').addEventListener('click', tableclickChange);
    async function tableclickChange(e){
        e.preventDefault();

        var divClearModal = document.getElementById('modaloutsidebar');
        let divModal = document.createElement('div');
        divModal.innerHTML =
            `<div class="modal">
                <p>Running Couchbench on?</p>
                <button class="btn btn--alt" id="ouiHandlerSideBarBtn">Mobile</button>
                <button class="btn" id="nonHandlerSideBarBtn">PCs</button>
            </div>`;
        divClearModal.appendChild(divModal);

        var divClearBackdrop = document.getElementById('backdropoutsidebar');
        let divBack = document.createElement('div');
        divBack.innerHTML =
            `<div class="backdrop" id="backdropHandlerSideBarBtn"></div>`;
        divClearBackdrop.appendChild(divBack);

        //If Yes Clicked
        document.getElementById('ouiHandlerSideBarBtn').addEventListener('click', ouiHandlerSideBarBtnChange);
        async function ouiHandlerSideBarBtnChange(e){
            e.preventDefault();

            var deviceStr = document.getElementById('device');
            deviceStr.innerText = 'mobile';

            var divClearModal = document.getElementById('modaloutsidebar');
            while(divClearModal.hasChildNodes()) {
                divClearModal.removeChild(divClearModal.childNodes[0]);
            }

            var divClearBackdrop = document.getElementById('backdropoutsidebar');
            while(divClearBackdrop.hasChildNodes()) {
                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
            }

            document.getElementById('principal').style.display = 'none';
            document.getElementById('serveur').style.display = 'none';
            document.getElementById('basecontaineur').style.display = 'none';
            document.getElementById('tablescontainer').style.display = 'block';
            document.getElementById('taskscontainer').style.display = 'none';
            document.getElementById('tabletasks').style.display = 'none';
            document.getElementById('queriescontainer').style.display = 'none';
            document.getElementById('documentcontainer').style.display = 'none';
            //xxxxxxxx
            var serveurHeader = document.getElementById('serveurheader');
            serveurHeader.innerText = "Server";
            serveurHeader.style.color = 'black';
            var databaseHeader = document.getElementById('databaseheader');
            databaseHeader.innerText = "Database";
            databaseHeader.style.color = 'black';
            var tableHeader = document.getElementById('tableheader');
            tableHeader.innerText = "table";
            tableHeader.style.color = 'red';
        };

        //If No Clicked
        document.getElementById('nonHandlerSideBarBtn').addEventListener('click', nonHandlerSideBarBtnChange);
        async function nonHandlerSideBarBtnChange(e){
            e.preventDefault();

            var divClearDevice = document.getElementById('device');
            while(divClearDevice.hasChildNodes()) {
                divClearDevice.removeChild(divClearDevice.childNodes[0]);
            }

            var divClearModal = document.getElementById('modaloutsidebar');
            while(divClearModal.hasChildNodes()) {
                divClearModal.removeChild(divClearModal.childNodes[0]);
            }

            var divClearBackdrop = document.getElementById('backdropoutsidebar');
            while(divClearBackdrop.hasChildNodes()) {
                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
            }

            document.getElementById('principal').style.display = 'none';
            document.getElementById('serveur').style.display = 'none';
            document.getElementById('basecontaineur').style.display = 'none';
            document.getElementById('tablescontainer').style.display = 'block';
            document.getElementById('taskscontainer').style.display = 'none';
            document.getElementById('tabletasks').style.display = 'none';
            document.getElementById('queriescontainer').style.display = 'none';
            document.getElementById('documentcontainer').style.display = 'none';
            //xxxxxxxx
            var serveurHeader = document.getElementById('serveurheader');
            serveurHeader.innerText = "Server";
            serveurHeader.style.color = 'black';
            var databaseHeader = document.getElementById('databaseheader');
            databaseHeader.innerText = "Database";
            databaseHeader.style.color = 'black';
            var tableHeader = document.getElementById('tableheader');
            tableHeader.innerText = "table";
            tableHeader.style.color = 'red';
        };

        //If Backdrop Clicked
        document.getElementById('backdropHandlerSideBarBtn').addEventListener('click', backdropHandlerSideBarBtnChange);
        async function backdropHandlerSideBarBtnChange(e){
            e.preventDefault();

            var divClearDevice = document.getElementById('device');
            while(divClearDevice.hasChildNodes()) {
                divClearDevice.removeChild(divClearDevice.childNodes[0]);
            }

            var divClearModal = document.getElementById('modaloutsidebar');
            while(divClearModal.hasChildNodes()) {
                divClearModal.removeChild(divClearModal.childNodes[0]);
            }

            var divClearBackdrop = document.getElementById('backdropoutsidebar');
            while(divClearBackdrop.hasChildNodes()) {
                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
            }

            document.getElementById('principal').style.display = 'block';
            document.getElementById('serveur').style.display = 'none';
            document.getElementById('basecontaineur').style.display = 'none';
            document.getElementById('tablescontainer').style.display = 'none';
            document.getElementById('taskscontainer').style.display = 'none';
            document.getElementById('tabletasks').style.display = 'none';
            document.getElementById('queriescontainer').style.display = 'none';
            document.getElementById('documentcontainer').style.display = 'none';
            //xxxxxxxx
            var serveurHeader = document.getElementById('serveurheader');
            serveurHeader.innerText = "Server";
            serveurHeader.style.color = 'black';
            var databaseHeader = document.getElementById('databaseheader');
            databaseHeader.innerText = "Database";
            databaseHeader.style.color = 'black';
            var tableHeader = document.getElementById('tableheader');
            tableHeader.innerText = "table";
            tableHeader.style.color = 'red';
        }
    };

    //tools
    document.getElementById('toolover').addEventListener('mouseover', tooloverChange);
    async function tooloverChange(e){
        e.preventDefault();

        const divEl = document.getElementById('my_div');
        divEl.innerText = "Tasks";
        document.getElementById('my_div').style.display = 'block';
    }
    document.getElementById('toolover').addEventListener('mouseleave', toolleaveChange);
    async function toolleaveChange(e){
        e.preventDefault();

        document.getElementById('my_div').style.display = 'none';
    }
    document.getElementById('toolover').addEventListener('click', toolclickChange);
    async function toolclickChange(e){
        e.preventDefault();

        var divClearModal = document.getElementById('modaloutsidebar');
        let divModal = document.createElement('div');
        divModal.innerHTML =
            `<div class="modal">
                <p>Running Couchbench on?</p>
                <button class="btn btn--alt" id="ouiHandlerSideBarBtn">Mobile</button>
                <button class="btn" id="nonHandlerSideBarBtn">PCs</button>
            </div>`;
        divClearModal.appendChild(divModal);

        var divClearBackdrop = document.getElementById('backdropoutsidebar');
        let divBack = document.createElement('div');
        divBack.innerHTML =
            `<div class="backdrop" id="backdropHandlerSideBarBtn"></div>`;
        divClearBackdrop.appendChild(divBack);

        //If Yes Clicked
        document.getElementById('ouiHandlerSideBarBtn').addEventListener('click', ouiHandlerSideBarBtnChange);
        async function ouiHandlerSideBarBtnChange(e){
            e.preventDefault();

            var deviceStr = document.getElementById('device');
            deviceStr.innerText = 'mobile';

            var divClearModal = document.getElementById('modaloutsidebar');
            while(divClearModal.hasChildNodes()) {
                divClearModal.removeChild(divClearModal.childNodes[0]);
            }

            var divClearBackdrop = document.getElementById('backdropoutsidebar');
            while(divClearBackdrop.hasChildNodes()) {
                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
            }

            document.getElementById('principal').style.display = 'none';
            document.getElementById('serveur').style.display = 'none';
            document.getElementById('basecontaineur').style.display = 'none';
            document.getElementById('tablescontainer').style.display = 'none';
            document.getElementById('taskscontainer').style.display = 'block';
            document.getElementById('documenttasks').style.display = 'none';
            document.getElementById('queriescontainer').style.display = 'none';
            document.getElementById('documentcontainer').style.display = 'none';
            //xxxxxxxx
            var serveurHeader = document.getElementById('serveurheader');
            serveurHeader.innerText = "Server";
            serveurHeader.style.color = 'black';
            var databaseHeader = document.getElementById('databaseheader');
            databaseHeader.innerText = "Database";
            databaseHeader.style.color = 'black';
            var tableHeader = document.getElementById('tableheader');
            tableHeader.innerText = "table";
            tableHeader.style.color = 'red';
        };

        //If No Clicked
        document.getElementById('nonHandlerSideBarBtn').addEventListener('click', nonHandlerSideBarBtnChange);
        async function nonHandlerSideBarBtnChange(e){
            e.preventDefault();

            var divClearDevice = document.getElementById('device');
            while(divClearDevice.hasChildNodes()) {
                divClearDevice.removeChild(divClearDevice.childNodes[0]);
            }

            var divClearModal = document.getElementById('modaloutsidebar');
            while(divClearModal.hasChildNodes()) {
                divClearModal.removeChild(divClearModal.childNodes[0]);
            }

            var divClearBackdrop = document.getElementById('backdropoutsidebar');
            while(divClearBackdrop.hasChildNodes()) {
                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
            }

            document.getElementById('principal').style.display = 'none';
            document.getElementById('serveur').style.display = 'none';
            document.getElementById('basecontaineur').style.display = 'none';
            document.getElementById('tablescontainer').style.display = 'none';
            document.getElementById('taskscontainer').style.display = 'block';
            document.getElementById('documenttasks').style.display = 'none';
            document.getElementById('queriescontainer').style.display = 'none';
            document.getElementById('documentcontainer').style.display = 'none';
            //xxxxxxxx
            var serveurHeader = document.getElementById('serveurheader');
            serveurHeader.innerText = "Server";
            serveurHeader.style.color = 'black';
            var databaseHeader = document.getElementById('databaseheader');
            databaseHeader.innerText = "Database";
            databaseHeader.style.color = 'black';
            var tableHeader = document.getElementById('tableheader');
            tableHeader.innerText = "table";
            tableHeader.style.color = 'red';
        };

        //If Backdrop Clicked
        document.getElementById('backdropHandlerSideBarBtn').addEventListener('click', backdropHandlerSideBarBtnChange);
        async function backdropHandlerSideBarBtnChange(e){
            e.preventDefault();

            var divClearDevice = document.getElementById('device');
            while(divClearDevice.hasChildNodes()) {
                divClearDevice.removeChild(divClearDevice.childNodes[0]);
            }

            var divClearModal = document.getElementById('modaloutsidebar');
            while(divClearModal.hasChildNodes()) {
                divClearModal.removeChild(divClearModal.childNodes[0]);
            }

            var divClearBackdrop = document.getElementById('backdropoutsidebar');
            while(divClearBackdrop.hasChildNodes()) {
                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
            }

            document.getElementById('principal').style.display = 'block';
            document.getElementById('serveur').style.display = 'none';
            document.getElementById('basecontaineur').style.display = 'none';
            document.getElementById('tablescontainer').style.display = 'none';
            document.getElementById('taskscontainer').style.display = 'none';
            document.getElementById('documenttasks').style.display = 'none';
            document.getElementById('queriescontainer').style.display = 'none';
            document.getElementById('documentcontainer').style.display = 'none';
            //xxxxxxxx
            var serveurHeader = document.getElementById('serveurheader');
            serveurHeader.innerText = "Server";
            serveurHeader.style.color = 'black';
            var databaseHeader = document.getElementById('databaseheader');
            databaseHeader.innerText = "Database";
            databaseHeader.style.color = 'black';
            var tableHeader = document.getElementById('tableheader');
            tableHeader.innerText = "table";
            tableHeader.style.color = 'red';
        }

        /*document.getElementById('principal').style.display = 'none';
        document.getElementById('serveur').style.display = 'none';
        document.getElementById('basecontaineur').style.display = 'none';
        document.getElementById('tablescontainer').style.display = 'none';
        document.getElementById('taskscontainer').style.display = 'block';
        document.getElementById('documenttasks').style.display = 'none';
        document.getElementById('queriescontainer').style.display = 'none';
        document.getElementById('documentcontainer').style.display = 'none';
        //xxxxxxxx
        var serveurHeader = document.getElementById('serveurheader');
        serveurHeader.innerText = "Server";
        serveurHeader.style.color = 'black';
        var databaseHeader = document.getElementById('databaseheader');
        databaseHeader.innerText = "Database";
        databaseHeader.style.color = 'black';
        var tableHeader = document.getElementById('tableheader');
        tableHeader.innerText = "table";
        tableHeader.style.color = 'red';*/
    }

    //query
    document.getElementById('queryover').addEventListener('mouseover', queryoverChange);
    async function queryoverChange(e){
        e.preventDefault();

        const divEl = document.getElementById('my_div');
        divEl.innerText = "Queries";
        document.getElementById('my_div').style.display = 'block';
    }
    document.getElementById('queryover').addEventListener('mouseleave', queryleaveChange);
    async function queryleaveChange(e){
        e.preventDefault();

        document.getElementById('my_div').style.display = 'none';
    }
    document.getElementById('queryover').addEventListener('click', queryclickChange);
    async function queryclickChange(e){
        e.preventDefault();

        var divClearModal = document.getElementById('modaloutsidebar');
        let divModal = document.createElement('div');
        divModal.innerHTML =
            `<div class="modal">
                <p>Running Couchbench on?</p>
                <button class="btn btn--alt" id="ouiHandlerSideBarBtn">Mobile</button>
                <button class="btn" id="nonHandlerSideBarBtn">PCs</button>
            </div>`;
        divClearModal.appendChild(divModal);

        var divClearBackdrop = document.getElementById('backdropoutsidebar');
        let divBack = document.createElement('div');
        divBack.innerHTML =
            `<div class="backdrop" id="backdropHandlerSideBarBtn"></div>`;
        divClearBackdrop.appendChild(divBack);

        //If Yes Clicked
        document.getElementById('ouiHandlerSideBarBtn').addEventListener('click', ouiHandlerSideBarBtnChange);
        async function ouiHandlerSideBarBtnChange(e){
            e.preventDefault();

            var deviceStr = document.getElementById('device');
            deviceStr.innerText = 'mobile';

            var divClearModal = document.getElementById('modaloutsidebar');
            while(divClearModal.hasChildNodes()) {
                divClearModal.removeChild(divClearModal.childNodes[0]);
            }

            var divClearBackdrop = document.getElementById('backdropoutsidebar');
            while(divClearBackdrop.hasChildNodes()) {
                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
            }

            document.getElementById('principal').style.display = 'none';
            document.getElementById('serveur').style.display = 'none';
            document.getElementById('basecontaineur').style.display = 'none';
            document.getElementById('tablescontainer').style.display = 'none';
            document.getElementById('taskscontainer').style.display = 'none';
            document.getElementById('queriescontainer').style.display = 'block';
            document.getElementById('documentqueries').style.display = 'none';
            document.getElementById('documentcontainer').style.display = 'none';
            //xxxxxxxx
            var serveurHeader = document.getElementById('serveurheader');
            serveurHeader.innerText = "Server";
            serveurHeader.style.color = 'red';
            var databaseHeader = document.getElementById('databaseheader');
            databaseHeader.innerText = "Database";
            databaseHeader.style.color = 'red';
            var tableHeader = document.getElementById('tableheader');
            tableHeader.innerText = "table";
            tableHeader.style.color = 'red';
        };

        //If No Clicked
        document.getElementById('nonHandlerSideBarBtn').addEventListener('click', nonHandlerSideBarBtnChange);
        async function nonHandlerSideBarBtnChange(e){
            e.preventDefault();

            var divClearDevice = document.getElementById('device');
            while(divClearDevice.hasChildNodes()) {
                divClearDevice.removeChild(divClearDevice.childNodes[0]);
            }

            var divClearModal = document.getElementById('modaloutsidebar');
            while(divClearModal.hasChildNodes()) {
                divClearModal.removeChild(divClearModal.childNodes[0]);
            }

            var divClearBackdrop = document.getElementById('backdropoutsidebar');
            while(divClearBackdrop.hasChildNodes()) {
                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
            }

            document.getElementById('principal').style.display = 'none';
            document.getElementById('serveur').style.display = 'none';
            document.getElementById('basecontaineur').style.display = 'none';
            document.getElementById('tablescontainer').style.display = 'none';
            document.getElementById('taskscontainer').style.display = 'none';
            document.getElementById('queriescontainer').style.display = 'block';
            document.getElementById('documentqueries').style.display = 'none';
            document.getElementById('documentcontainer').style.display = 'none';
            //xxxxxxxx
            var serveurHeader = document.getElementById('serveurheader');
            serveurHeader.innerText = "Server";
            serveurHeader.style.color = 'red';
            var databaseHeader = document.getElementById('databaseheader');
            databaseHeader.innerText = "Database";
            databaseHeader.style.color = 'red';
            var tableHeader = document.getElementById('tableheader');
            tableHeader.innerText = "table";
            tableHeader.style.color = 'red';
        };

        //If Backdrop Clicked
        document.getElementById('backdropHandlerSideBarBtn').addEventListener('click', backdropHandlerSideBarBtnChange);
        async function backdropHandlerSideBarBtnChange(e){
            e.preventDefault();

            var divClearDevice = document.getElementById('device');
            while(divClearDevice.hasChildNodes()) {
                divClearDevice.removeChild(divClearDevice.childNodes[0]);
            }

            var divClearModal = document.getElementById('modaloutsidebar');
            while(divClearModal.hasChildNodes()) {
                divClearModal.removeChild(divClearModal.childNodes[0]);
            }

            var divClearBackdrop = document.getElementById('backdropoutsidebar');
            while(divClearBackdrop.hasChildNodes()) {
                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
            }

            document.getElementById('principal').style.display = 'block';
            document.getElementById('serveur').style.display = 'none';
            document.getElementById('basecontaineur').style.display = 'none';
            document.getElementById('tablescontainer').style.display = 'none';
            document.getElementById('taskscontainer').style.display = 'none';
            document.getElementById('queriescontainer').style.display = 'none';
            document.getElementById('documentqueries').style.display = 'none';
            document.getElementById('documentcontainer').style.display = 'none';
            //xxxxxxxx
            var serveurHeader = document.getElementById('serveurheader');
            serveurHeader.innerText = "Server";
            serveurHeader.style.color = 'red';
            var databaseHeader = document.getElementById('databaseheader');
            databaseHeader.innerText = "Database";
            databaseHeader.style.color = 'red';
            var tableHeader = document.getElementById('tableheader');
            tableHeader.innerText = "table";
            tableHeader.style.color = 'red';
        }
    }

    //documentation
    document.getElementById('documentover').addEventListener('mouseover', documentoverChange);
    async function documentoverChange(e){
        e.preventDefault();

        const divEl = document.getElementById('my_div');
        divEl.innerText = "Documentation";
        document.getElementById('my_div').style.display = 'block';
    }

    document.getElementById('documentover').addEventListener('mouseleave', documentleaveChange);
    async function documentleaveChange(e){
        e.preventDefault();

        document.getElementById('my_div').style.display = 'none';
    }

    document.getElementById('documentover').addEventListener('click', documentclickChange);
    async function documentclickChange(e){
        e.preventDefault();

        document.getElementById('principal').style.display = 'none';
        document.getElementById('serveur').style.display = 'none';
        document.getElementById('basecontaineur').style.display = 'none';
        document.getElementById('tablescontainer').style.display = 'none';
        document.getElementById('taskscontainer').style.display = 'none';
        document.getElementById('queriescontainer').style.display = 'none';
        document.getElementById('documentcontainer').style.display = 'block';
        document.getElementById('tablecontentview').style.display = 'none';
        //xxxxxxxx
        var serveurHeader = document.getElementById('serveurheader');
        serveurHeader.innerText = "Server";
        serveurHeader.style.color = 'black';
        var databaseHeader = document.getElementById('databaseheader');
        databaseHeader.innerText = "Database";
        databaseHeader.style.color = 'black';
        var tableHeader = document.getElementById('tableheader');
        tableHeader.innerText = "table";
        tableHeader.style.color = 'black';
    }
});