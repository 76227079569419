import React from 'react';
import '../pouchdocumentation';

import { 
    TextContainer,
    TextTitle, 
    TextContent, 
    SubLink,  
} from '../DocumentationStyles';

function Introduction() {
    return ( 
        <TextContainer id="introduction">
            <TextTitle>1.Introduction</TextTitle>
            <TextContent>
                <a href='https://couchdb.apache.org/fauxton-visual-guide/index.html' style={{textDecoration:'none'}}>Fauxton</a> is a native web interface built into <a href='https://couchdb.apache.org/' style={{textDecoration:'none'}}>CouchDB</a>. 
                It provides a basic interface to the majority of functionality, 
                including the ability to create, update, delete, and view documents and design documents. 
                It provides access to configuration settings and an interface to initiate replication. 
            </TextContent>
            <TextContent>
                Couchbench is an alternative application to Fauxton. 
                It provides a simpler and more intuitive basic interface for developers 
                using CouchDB as their database server in their applications. 
                It also allows non-developers to facilitate actions in the processing of their public and 
                private survey activities. Finally, it allows any user unfamiliar with the <a href='https://www.json.org/json-en.html' style={{textDecoration:'none'}}>JSON format</a> to 
                create, update, delete, display and process documents and data like on <a href='https://www.mysql.com/it/products/workbench/' style={{textDecoration:'none'}}>MySQL Workbench</a> from a few lines of writing and clicks.
            </TextContent>
            <TextContent>
                This documentation does not cover essential algorithms and functions. 
                It simply explains what the user sees when interacting with CouchDB Workbench.
            </TextContent>
            <SubLink id='introcontwhatnew'>2.What's New Couchbench?</SubLink>
            <br/><br/>
        </TextContainer>
    );
}

export default Introduction;