import React from 'react';
import '../pouchtasks';
import '../tasks.css';

import {FormList} from '../TasksStyles';

function ListViewTasks() {
    return ( 
        <div id="listviewtasks">
            <br/>
            <FormList>
                <div className='resultsList'>
                    <table className='thin_table_config'>
                        <thead>
                            <tr>
                                <th>Server</th>
                                <th>Table</th>
                            </tr>
                        </thead>
                        <tbody id='crudViewTaskTableBody'></tbody>
                    </table>
                </div>
            </FormList>
        </div>
    );
}

export default ListViewTasks;