import React from 'react';
import '../pouchtables';
import '../tables.css';

import PrevDeskTables from '../compotables/PrevDeskTables';
import PrevMobileTables from '../compotables/PrevMobileTables';

/*import { 
    LargeInput, 
    ServeurContainer, 
    SmallInput, 
    PortInput , 
    FormScroller, 
} from '../../serveur/ServeurStyles';
import { ButtonDiv, StyleButton } from '../TablesStyles';*/

function PreviousTables() {
    return ( 
        <div id="previoustables">
           <PrevDeskTables />
           <PrevMobileTables /> 
        </div>
    );
}

export default PreviousTables;