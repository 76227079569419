import React from 'react';
import '../pouchtasks';
import '../tasks.css';
import {  
    ServeurContainer, 
    SmallInput, 
    PortInput , 
    FormScroller, 
} from '../../serveur/ServeurStyles';
//import { ButtonTableDiv, StyleTableButton } from '../TasksStyles';

function CreateTasks() {
    return ( 
        <div id="createtasks">
            <br/>
            <FormScroller>
                <ServeurContainer>
                    <div>
                        <SmallInput id='nametaskdocument' type='text' placeholder='Table task' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='fieldtaskdocument' type='number' placeholder='Doc N°' readOnly />
                    </div>
                </ServeurContainer>
                <br/>
                <div id='tasktemplatedocument'></div>
            </FormScroller>
        </div>
    );
}

export default CreateTasks;