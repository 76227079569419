import React from 'react';
import './pouchtables';
import './tables.css';

import NewTables from './crudtables/NewTables';
import CreateTables from './crudtables/CreateTables';
import PreviousTables from './crudtables/PreviousTables';
import ChangeTables from './crudtables/ChangeTables';
import ListTables from './crudtables/ListTables';

import { 
    TablesContainer, 
    TextBase,
    ServeurBaseContainer, 
    ServeurButtonBase, 
    AccesButtonBase, 
    FormTable, 
    TextForm, 
    TextLocal, 
    TaskButtons, 
    Buttons, 
    TasksUpper,
    QueryBase, 
    ButtonsBottom,
    TaskAdd, 
    TaskEdit, 
    TaskDelete,
} from './TablesStyles';

function Tables() {
    return ( 
        <TablesContainer id="tablescontainer">
            <br/>
            <TextBase>
                <label id='tabletitle'>Tables Management</label>
            </TextBase>
            <br/>
            <ServeurBaseContainer>
                <ServeurButtonBase>
                    <AccesButtonBase id='localtableserver'>Local</AccesButtonBase>
                </ServeurButtonBase>
                <ServeurButtonBase>
                    <AccesButtonBase id='remotetableserver'>Remote</AccesButtonBase>
                </ServeurButtonBase>
            </ServeurBaseContainer>
            <br/><br/>
            <FormTable id='tabletasks'>
                <TextForm>
                    <label id='tabletextform'>Tables Form</label>
                </TextForm>
                <TextLocal>
                    <label id='texttablelocal'>Local / Remote Database</label>
                </TextLocal>
                <br/>
                <TaskButtons>
                    <Buttons>
                        <TasksUpper id='newtable'>New</TasksUpper>
                    </Buttons>
                    <Buttons>
                        <TasksUpper id='previoustable'>Previous</TasksUpper>
                    </Buttons>
                    <Buttons>
                        <TasksUpper id='viewtable'>Views</TasksUpper>
                    </Buttons>
                </TaskButtons>
                <br/>
                <QueryBase id='querytablestasks'>
                    <NewTables />
                    <CreateTables />
                    <PreviousTables />
                    <ChangeTables />
                    <ListTables />
                </QueryBase>
                <br/>
                <TaskButtons id='crudtablesactions'>
                    <ButtonsBottom>
                        <TaskAdd id='addtables'>Create</TaskAdd>
                    </ButtonsBottom>
                    <ButtonsBottom>
                        <TaskEdit id='edittables'>Edit</TaskEdit>
                    </ButtonsBottom>
                    <ButtonsBottom>
                        <TaskDelete id='deletetables'>Delete</TaskDelete>
                    </ButtonsBottom>
                </TaskButtons>
                <div id="modalouttables"></div>
                <div id="backdropouttables"></div>

                <div id="modaloutalerttables"></div>
                <div id="backdropoutalerttables"></div>
            </FormTable>
        </TablesContainer>
    );
}

export default Tables;