import React from 'react';
import './pouchdocumentation';
import { AiOutlineWhatsApp, AiOutlineMail } from 'react-icons/ai';

import { 
    ContentContainer, 
    ContentTitle, 
    UserGuides, 
    SubTitle,
    SocialIcons,  
} from './DocumentationStyles';

function TableContent() {
    return ( 
        <ContentContainer id="tablecontent">
            <br/>
            <ContentTitle>Table of Contents</ContentTitle><br/>
            <span>*Click and scroll page*</span><br/><br/><br/>
            <UserGuides>User Guides</UserGuides><br/><br/>
            <SubTitle id='contintroduction'>1. Introduction</SubTitle><br/><br/>
            <SubTitle id='contwhatnew'>2. What's New Couchbench?</SubTitle><br/><br/>
            <SubTitle id='contwhycouchbench'>3. Why Couchbench?</SubTitle><br/><br/>
            <SubTitle id='conttechnical'>4. Technical Overview</SubTitle><br/><br/>
            <SubTitle id='contconsistency'>5. Eventual Consistency</SubTitle><br/><br/><br/>
            <UserGuides>Getting Start</UserGuides><br/><br/>
            <SubTitle id='continstallation'>6. Installation</SubTitle><br/><br/>
            <SubTitle id='contguideview'>7. Guide to Views</SubTitle><br/><br/>
            <SubTitle id='conttutorial'>8. Tutorial</SubTitle><br/><br/><br/>
            <UserGuides>Feedbacks/Comments</UserGuides><br/><br/>
            <SubTitle id='contresearches'>
                <SocialIcons href='https://api.whatsapp.com/send?phone=+447459367632'>
                    <AiOutlineWhatsApp size="1.2rem" />whatsapp
                </SocialIcons>
            </SubTitle><br/><br/>
            <SubTitle id='contresources'>
                <SocialIcons href='mailto:grandbico81@gmail.com'>
                    <AiOutlineMail size="1.2rem" />Email
                </SocialIcons>
            </SubTitle><br/><br/>
        </ContentContainer> 
    );
}

export default TableContent;