import React from 'react';
import '../pouchtables';
import '../tables.css';
import {  
    ServeurContainer, 
    SmallInput, 
    PortInput , 
    FormScroller, 
} from '../../serveur/ServeurStyles';
import { ButtonTableDiv, StyleTableButton, TextLocal, } from '../TablesStyles';

function CreateTables() {
    return ( 
        <div id="createtables">
            <br/>
            <FormScroller>
                <ServeurContainer>
                    <div>
                        <SmallInput id='nametable' type='text' placeholder='Template Name' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='fieldtable' type='number' min="1" max="20" placeholder='Columns' />
                    </div>
                </ServeurContainer>
                <br/>
                <ButtonTableDiv id='confirmtable'>
                    <StyleTableButton id='confirmtablename'>Confirm</StyleTableButton>
                </ButtonTableDiv>
                <br/>
                <TextLocal id='setcolumns'>
                    <label style={{fontSize:'1rem'}} id='settablelocal'>Fill all columns please</label>
                </TextLocal>
                <br/>
                <div id='tabletemplate'></div>
            </FormScroller>
        </div>
    );
}

export default CreateTables;