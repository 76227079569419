import React from 'react';
import '../pouchqueries';
import '../queries.css';
import { TextLocal, ServeurContainer,  } from '../../serveur/ServeurStyles';

import {StyleTableButton,  StyleUndoButton, FormScrollerView} from '../QueriesStyles'; 

function DynamicTableQueries() {
    return ( 
        <div id="dynamictablequeries">
            <br/>
            <FormScrollerView>
                <TextLocal>
                    <label id='dynamicviewdocumentquery'>Query Results</label>
                </TextLocal>
                <br/>
                <div className='resultsList'>
                    <div id='dynamicviewquery'>
                        dynamic query result table here
                    </div>
                </div>  
                <br/>
                <ServeurContainer>
                    <StyleTableButton id='confirmdynamicqueryview'>Back</StyleTableButton>
                    &nbsp;&nbsp;
                    <StyleUndoButton id='canceldynamicqueryview'>Close</StyleUndoButton>
                </ServeurContainer>
            </FormScrollerView>
        </div>
    );
}

export default DynamicTableQueries;