import React from 'react';
import '../pouchtables';
import '../tables.css';
import { 
    LargeInput,  
    FormScroller, 
} from '../../serveur/ServeurStyles';
import { ButtonDiv, StyleButton } from '../TablesStyles';

function PrevMobileTables() {
    return ( 
        <div id="prevmobiletables">
            <br/>
            <FormScroller>
                <div>
                    <LargeInput id='mobileusernametablechange' type='text' placeholder='Username' />
                </div>
                <br/>
                <div>
                    <LargeInput id='mobilepasswordnametablechange' type='password' placeholder='Password' />
                </div>
                
                <div style={{display:"none"}} id='mobiletabnametochange'>
                    <LargeInput id='mobiletablenametochange' type='text' placeholder='Database Name' readOnly />
                </div>
                <br/>
                <div style={{display:"none"}} id='mobiletabnamechange'>
                    <LargeInput id='mobiletablenamechange' type='text' placeholder='Database Name' />
                </div>
                
                <div style={{display:"none"}} id='mobileservertablenamechange'>
                    <LargeInput id='mobileservertablenamechange' type='text' placeholder='server Name' readOnly />
                </div>
                <br/>
                <ButtonDiv id='mobileconfirmertablechange'>
                    <StyleButton id='mobileconfirmtablechange'>Confirm</StyleButton>
                </ButtonDiv>
                <br/>
            </FormScroller>
        </div>
    );
}

export default PrevMobileTables;