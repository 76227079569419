import styled from 'styled-components';

export const MouseoverContainer = styled.div`
    border: 1px solid black;
    border-radius:5px;
    height: 30px;
    width: 180px;
    align-items: center;
    justify-content: center;
    background-color: #999999;
    color:white;
`;

export const PrincipalContainer = styled.div`
    border: 1px solid black;
    height:550px;
`;

export const TextServeur = styled.div`
    text-align:center;
    font-size:1.2rem;
`;

export const ScreenContainer = styled.div`
    display:flex;
    flex-direction:row;
`;

export const ScreenBouton = styled.div`
    margin-left:550px;

    @media screen and (max-width: 640px){
        margin-left:60px;
    }

    @media screen and (max-width: 768px){
        margin-left:60px;
    }
`;

export const BoutonHomeScreen = styled.button`
    border: none;
    color: #ffffff;
    display: flex;
    width:200px;
    height: 40px;
    border-radius:10px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    font-size:15px;
    background-color:#b3b3ff;

    &:hover{
        background-color:#3333ff;
    }
`;

export const TextLocal = styled.div`
    text-align:center;
    font-size:.9rem;
    width:100%;
`;

