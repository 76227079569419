import React from 'react';
import '../pouchdatabase';
import '../database.css';

import {FormList} from '../DatabaseStyles';

function ListBase() {
    return ( 
        <div id="listbase">
            <br/>
            <FormList>
                <div className='resultsList'>
                    <table className='thin_table_config'>
                        <thead>
                            <tr>
                                <th>Database</th>
                                <th>ID</th>
                            </tr>
                        </thead>
                        <tbody id='editDatabaseTableBody'></tbody>
                    </table>
                </div>
            </FormList>
        </div>
    );
}

export default ListBase;