import styled from 'styled-components';

export const HeaderContainer = styled.div`
  border: 1px solid black;
`;

export const ImageContainer = styled.div`
  float:left;
`;

export const Img = styled.img`
  width:50px;
  height:50px;
  object-fit: cover;
  overflow: hidden;
  padding:0rem;
  margin-left:2%;
`;

export const TextContainer = styled.div`
  text-align:center;
  align-items:center;
  margin-bottom:30px;
  font-size:1.2rem;
`;

export const ParametreContainer = styled.div`
  display:flex;
  flex-direction:row;
`;

export const DataContainer = styled.div`
  margin-left:400px;
  @media screen and (max-width: 768px){
    margin-left:30px;
  }
`;