import React from 'react';
import '../pouchtasks';
import '../tasks.css';
import {  
    ServeurContainer, 
    SmallInput, 
    PortInput , 
    FormScroller, 
} from '../../serveur/ServeurStyles';
import { /*ButtonTableDiv,*/ StyleTableButton, StyleUndoButton } from '../TasksStyles';

function ViewDisplayTasks() {
    return ( 
        <div id="viewdisplaytasks">
            <br/>
            <FormScroller>
                <ServeurContainer>
                    <div id='jsonlist'></div>
                    <div>
                        <SmallInput id='nametaskviewdisplay' type='text' placeholder='Table Name' readOnly />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='fieldtaskviewdisplay' type='number' min="1" max="20" placeholder='Columns' readOnly />
                    </div>
                </ServeurContainer>
                <div style={{display:'block'}}>
                    <div>
                        <SmallInput id='taskidviewdisplay' type='text' placeholder='Table Id' readOnly />
                    </div>
                    <div>
                        <SmallInput id='taskdatabaseviewdisplay' type='text' placeholder='Table database' readOnly />
                    </div>
                    <div>
                        <SmallInput id='taskserverviewdisplay' type='text' placeholder='Table server' readOnly />
                    </div>
                    <div>
                        <SmallInput id='taskconnectviewdisplay' type='text' placeholder='Table connect' readOnly />
                    </div>
                </div>
                <br/>
                <div id='tasktemplateviewdisplay'></div>
                <br/>
                <ServeurContainer id='confirmtaskviewdisplay'>
                    <StyleTableButton id='selecttasknameviewdisplay'>Select</StyleTableButton>
                    &nbsp;&nbsp;
                    <StyleUndoButton id='undotasknameviewdisplay'>Undo</StyleUndoButton>
                </ServeurContainer>
                <br/>
            </FormScroller>
        </div>
    );
}

export default ViewDisplayTasks;