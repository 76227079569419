import React from 'react';
import '../pouchtasks';
import '../tasks.css';
import {  
    TextLocal , 
    FormScroller, 
} from '../../serveur/ServeurStyles';

//import {tableData} from '../pouchtasks';

function ViewDocTasks() {
    //const tablevalue = [tableData];
    //console.log(tablevalue);
    return ( 
        <div id="viewdoctasks">
            <br/>
            <FormScroller>
                <TextLocal>
                    <label id='textviewdotask'>Select the document</label>
                </TextLocal>
                <br/>
                <div className='resultsList'>
                    <table className='thin_table_config'>
                        <thead>
                            <tr>
                                <th>Template</th>
                                <th>Document</th>
                            </tr>
                        </thead>
                        <tbody id='viewDocumentTaskTableBody'></tbody>
                    </table>
                </div>  
            </FormScroller>
        </div>
    );
}

/*const scrollTr = {
    overflowX: 'scroll',
    scrollbarColor: 'rebeccapurple green',
}*/

export default ViewDocTasks;