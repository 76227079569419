import React from 'react';
import './pouchdocumentation';

import Introduction from './cruddocumentation/Introduction';
import WhatNew from './cruddocumentation/meetcouchbench/WhatNew';
import WhyCouchbench from './cruddocumentation/meetcouchbench/WhyCouchbench';
import TechnicalOverview from './cruddocumentation/meetcouchbench/TechnicalOverview';
import Consistency from './cruddocumentation/meetcouchbench/Consistency';
//
import InstallationGuide from './cruddocumentation/gettingstart/InstallationGuide';
import GuideView from './cruddocumentation/gettingstart/GuideViews';
import Tutorial from './cruddocumentation/gettingstart/Tutorial';

function TableContentView() {
    return ( 
        <div id="tablecontentview">
            <br/>
            <Introduction />
            <WhatNew />
            <WhyCouchbench />
            <TechnicalOverview />
            <Consistency />
            <InstallationGuide />
            <GuideView />
            <Tutorial />
        </div>
    );
}

export default TableContentView;