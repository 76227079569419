/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import PouchDB, { emit } from 'pouchdb';
import PouchFind from 'pouchdb-find';

window.addEventListener('load', () => {
    PouchDB.plugin(PouchFind);

    //local database test
    document.getElementById('localqueriesserver').addEventListener('click', localqueriesserverChange);
    async function localqueriesserverChange(e){
        e.preventDefault();

        var deviceStr = document.getElementById('device').innerText;

        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxx
        var divClearDevice = document.getElementById('device');
        while(divClearDevice.hasChildNodes()) {
            divClearDevice.removeChild(divClearDevice.childNodes[0]);
        }

        if(deviceStr === ''){
            var deviceStr = document.getElementById('device');
            deviceStr.innerText = '';
            var databasetypeForm = document.getElementById('queriestextform');
            databasetypeForm.innerText = "Desktop Local Queries Tasks";

            var textLocal = document.getElementById('textquerieslocal');
            textLocal.innerText = "Desktop Local Queries"; 
            //xxxxxxxx
            document.getElementById('connectqueries').style.display = 'block';
            document.getElementById('deskconqueries').style.display = 'block';
            document.getElementById('mobileconqueries').style.display = 'none';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'none';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'none';
            document.getElementById('dynamictablequeries').style.display = 'none';
        }

        if(deviceStr === 'mobile'){
            var deviceStr = document.getElementById('device');
            deviceStr.innerText = 'mobile';
            var databasetypeForm = document.getElementById('queriestextform');
            databasetypeForm.innerText = "Mobile Local Queries Tasks";

            var textLocal = document.getElementById('textquerieslocal');
            textLocal.innerText = "Mobile Local Queries"; 
            //xxxxxxxx
            document.getElementById('connectqueries').style.display = 'block';
            document.getElementById('deskconqueries').style.display = 'none';
            document.getElementById('mobileconqueries').style.display = 'block';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'none';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'none';
            document.getElementById('dynamictablequeries').style.display = 'none';
        }

        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';

        var serveurHeader = document.getElementById('serveurheader');
        serveurHeader.innerText = "local";
        serveurHeader.style.color = 'red';
    }

    //remote database test
    document.getElementById('remotequeriesserver').addEventListener('click', remotequeriesserverChange);
    async function remotequeriesserverChange(e){
        e.preventDefault();

        var deviceStr = document.getElementById('device').innerText;

        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxx
        var divClearDevice = document.getElementById('device');
        while(divClearDevice.hasChildNodes()) {
            divClearDevice.removeChild(divClearDevice.childNodes[0]);
        }

        if(deviceStr === ''){
            var deviceStr = document.getElementById('device');
            deviceStr.innerText = '';
            var databasetypeForm = document.getElementById('queriestextform');
            databasetypeForm.innerText = "Desktop Remote Queries Tasks";

            var textLocal = document.getElementById('textquerieslocal');
            textLocal.innerText = "Desktop Remote Queries"; 
            //xxxxxxxx
            document.getElementById('connectqueries').style.display = 'block';
            document.getElementById('deskconqueries').style.display = 'block';
            document.getElementById('mobileconqueries').style.display = 'none';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'none';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'none';
            document.getElementById('dynamictablequeries').style.display = 'none';
        }

        if(deviceStr === 'mobile'){
            var deviceStr = document.getElementById('device');
            deviceStr.innerText = 'mobile';
            var databasetypeForm = document.getElementById('queriestextform');
            databasetypeForm.innerText = "Mobile Remote Queries Tasks";

            var textLocal = document.getElementById('textquerieslocal');
            textLocal.innerText = "Mobile Remote Queries"; 
            //xxxxxxxx
            document.getElementById('connectqueries').style.display = 'block';
            document.getElementById('deskconqueries').style.display = 'none';
            document.getElementById('mobileconqueries').style.display = 'block';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'none';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'none';
            document.getElementById('dynamictablequeries').style.display = 'none';
        }
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';

        var serveurHeader = document.getElementById('serveurheader');
        serveurHeader.innerText = "local";
        serveurHeader.style.color = 'red';
    }

    //connection query
    //Crud tasks (confirmquery)
    document.getElementById('confirmquery').addEventListener('click', confirmqueryChange);
    async function confirmqueryChange(e){
        e.preventDefault();

        //alert
        var elementOutId = 'modaloutalertqueries';
        var elementDropId = 'backdropoutalertqueries';
        var buttonHandler = 'ouiHandlerAlertQueryBtn';

        var hostnametaskStr = document.getElementById('hostnamequery').value;
        var hostporttaskStr = document.getElementById('hostportquery').value;
        var usernametaskStr = document.getElementById('usernamequery').value;
        var passwordnametaskStr = document.getElementById('passwordnamequery').value;
        var tasknameStr = document.getElementById('queryname').value.toLowerCase();
        var serveurHeaderStr = document.getElementById('serveurheader').innerText;
        var deviceStr = document.getElementById('device').innerText;
        var tabletemplateStr = 'template';
        var localnamebaseStr = '';
        var servernameStr = 'database';
        if(serveurHeaderStr === 'local'){
            localnamebaseStr = 'local';
        }else if(serveurHeaderStr === 'remote'){
            localnamebaseStr = 'remote';
        }
        var localtypebaseStr = 'database';

        //check if values not null
        if(!hostnametaskStr){
            var pTextehostname = "Enter Host Name";
            alertModal(elementOutId,elementDropId,pTextehostname,buttonHandler);
        }else if(!hostporttaskStr){
            var pTexteportname = "Enter Port Number";
            alertModal(elementOutId,elementDropId,pTexteportname,buttonHandler);
        }else if(!usernametaskStr){
            var pTexteusername = "Enter Username";
            alertModal(elementOutId,elementDropId,pTexteusername,buttonHandler);
        }else if(!passwordnametaskStr){
            var pTextepasswordname = "Enter Password";
            alertModal(elementOutId,elementDropId,pTextepasswordname,buttonHandler);
        }else if(!tasknameStr){
            var pTextedatabasename = "Enter Database Name";
            alertModal(elementOutId,elementDropId,pTextedatabasename,buttonHandler);
        }else{
            var docsCheckConfirm = [];
            //start main try catch
            try {
                //test if hostname and portnumber are true
                if((deviceStr === '' && localnamebaseStr === 'local') || 
                (deviceStr === '' && localnamebaseStr === 'remote') || (deviceStr === 'mobile' && localnamebaseStr === 'remote')){
                    var url = "http://"+hostnametaskStr+":"+hostporttaskStr+"/_utils/";
                    var http_request = new XMLHttpRequest();
                    http_request.open("GET", url ,true);
                    http_request.onreadystatechange = async function(){
                        if(http_request.readyState === 4){
                            if(http_request.status === 200){
                                //search in local / remote database documents
                                const db = new PouchDB(localnamebaseStr, {skip_setup:true});
                                const remote = new PouchDB('http://'+usernametaskStr+':'+passwordnametaskStr+'@'+hostnametaskStr+':'+hostporttaskStr+'/'+localnamebaseStr, {skip_setup:true});
                                PouchDB.plugin(PouchFind);
                                // Initialise a sync with the remote server
                                function sync(){
                                    var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                    db.sync(remote, opts);
                                }
                                if(remote){
                                    sync();
                                }

                                let result = await db.createIndex({
                                    index: {
                                        fields: [
                                            'connectname',
                                            'servername'
                                        ]
                                    }
                                });
                                result = await db.find({
                                    selector: {
                                        connectname: tasknameStr,
                                        servername: servernameStr
                                    }
                                });
                                docsCheckConfirm = result.docs;
                                var resultatCheckConfirm = docsCheckConfirm.length;
                                if(resultatCheckConfirm === 0){
                                    var divClearModal = document.getElementById('modaloutqueries');
                                    let divModal = document.createElement('div');
                                    divModal.innerHTML =
                                    `<div class="modal">
                                        <p>Sure this database exists?</p>
                                        <button class="btn btn--alt" id="ouiHandlerExistQueryBtn">Yes</button>
                                        <button class="btn" id="nonHandlerExistsQueryBtn">No</button>
                                    </div>`;
                                    divClearModal.appendChild(divModal);

                                    var divClearBackdrop = document.getElementById('backdropoutqueries');
                                    let divBack = document.createElement('div');
                                    divBack.innerHTML =
                                    `<div class="backdrop" id="backdropHandlerCloseExistsQueryBtn"></div>`;
                                    divClearBackdrop.appendChild(divBack);

                                    //If Yes Clicked
                                    document.getElementById('ouiHandlerExistQueryBtn').addEventListener('click', ouiHandlerExistQueryBtnChange);
                                    async function ouiHandlerExistQueryBtnChange(e){
                                        e.preventDefault();

                                        //code here
                                        var divClearModal = document.getElementById('modaloutqueries');
                                        let divModal = document.createElement('div');
                                        divModal.innerHTML =
                                        `<div class="modal">
                                            <p>Wish to Add it?</p>
                                            <button class="btn btn--alt" id="ouiHandlerAddQueryBtn">Yes</button>
                                            <button class="btn" id="nonHandlerAddQueryBtn">No</button>
                                        </div>`;
                                        divClearModal.appendChild(divModal);

                                        var divClearBackdrop = document.getElementById('backdropoutqueries');
                                        let divBack = document.createElement('div');
                                        divBack.innerHTML =
                                        `<div class="backdrop" id="backdropHandlerCloseAddQueryBtn"></div>`;
                                        divClearBackdrop.appendChild(divBack);

                                        //If Yes Add Clicked
                                        document.getElementById('ouiHandlerAddQueryBtn').addEventListener('click', ouiHandlerAddQueryBtnChange);
                                        async function ouiHandlerAddQueryBtnChange(e){
                                            e.preventDefault();

                                            //code here
                                            const db = new PouchDB(localnamebaseStr, {skip_setup:true});
                                            const remote = new PouchDB('http://'+usernametaskStr+':'+passwordnametaskStr+'@'+hostnametaskStr+':'+hostporttaskStr+'/'+localnamebaseStr, {skip_setup:true});
                                            PouchDB.plugin(PouchFind);
                                            //test if hostname and portnumber are true
                                            var http_request = new XMLHttpRequest();
                                            http_request.open("GET", "http://"+hostnametaskStr+":"+hostporttaskStr+"/_utils/" ,true);
                                            http_request.send("null");
                                            http_request.onreadystatechange = async function(){
                                                if(http_request.readyState === 4){
                                                    if(http_request.status === 200){
                                                        function sync(){
                                                            var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                                            db.sync(remote, opts);
                                                        }
                                                        if(remote){
                                                            sync();
                                                        }
                                                        //end sync
                                                        var docsCheck = [];
                                                        try {
                                                            let result = await db.createIndex({
                                                                index: {
                                                                    fields: [
                                                                        'hostname', 
                                                                        'portname',
                                                                        'username',
                                                                        'passwordname'
                                                                    ]
                                                                }
                                                            });
                                                            result = await db.find({
                                                                selector: {
                                                                    hostname: hostnametaskStr,
                                                                    portname: hostporttaskStr,
                                                                    username: usernametaskStr,
                                                                    passwordname: passwordnametaskStr
                                                                }
                                                            });
                                                            docsCheck = result.docs;
                                                            var resultatCheck = docsCheck.length;
                                                            if(resultatCheck === ''){
                                                                var pTexteincorrectconnection = "Name or password is incorrect.";
                                                                alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
                                                            }else{
                                                                var docsCheckDatabase = [];
                                                                let resultDatabase = await db.createIndex({
                                                                    index: {
                                                                        fields: [
                                                                            'connectname'
                                                                        ]
                                                                    }
                                                                });
                                                                resultDatabase = await db.find({
                                                                    selector: {
                                                                        connectname: tasknameStr
                                                                    }
                                                                });
                                                                docsCheckDatabase = resultDatabase.docs;
                                                                var resultatCheckDatabase = docsCheckDatabase.length;
                                                                if(resultatCheckDatabase === 0){
                                                                    const dbNew = new PouchDB(tasknameStr);
                                                                    const remoteNew = couchProvider(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, tasknameStr);
                                                                    //add new database created to local connection server
                                                                    //Create the document _id and _rev.
                                                                    var now = Date.now();
                                                                    //new database id
                                                                    var id = tasknameStr.toLowerCase()+'_' + now;
                                                                    // Add the document to the database.
                                                                    var response = await db.put({
                                                                        _id: id,
                                                                        connectname: tasknameStr, //the new database name
                                                                        hostname: hostnametaskStr,
                                                                        portname: hostporttaskStr,
                                                                        username: usernametaskStr,
                                                                        passwordname: passwordnametaskStr,
                                                                        servername: localtypebaseStr,
                                                                    });
                                                                    // check if document inserted
                                                                    var doc = await db.get(response.id);
                                                                    if(doc !== ''){
                                                                        //Initialise a sync with the remote server
                                                                        function sync(){
                                                                            var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                                                            dbNew.sync(remoteNew, opts);
                                                                        }
                                                                        if(remoteNew){
                                                                            sync();
                                                                        }
                                                                        var pTextenewdatabase = "New Database added Succesfully";
                                                                        alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
                                                                    }else{
                                                                        var pTexteerreuraddnew = "An Error has Occurred";
                                                                        alertModal(elementOutId,elementDropId,pTexteerreuraddnew,buttonHandler);
                                                                    }
                                                                }else{
                                                                    var pTexteexistaddnew = "Database "+tasknameStr+" already exists. Confirm again";
                                                                    alertModal(elementOutId,elementDropId,pTexteexistaddnew,buttonHandler);
                                                                }
                                                            }
                                                        } catch (error) {
                                                            var pTexteerreurconnection = "An Error has Occurred";
                                                            alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler); 
                                                        }
                                                    }else{
                                                        var pTextefailedconnect = "Connection Failed.";
                                                        alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                                                    }
                                                }
                                            }//
                                            var divClearModal = document.getElementById('modaloutqueries');
                                            while(divClearModal.hasChildNodes()) {
                                                divClearModal.removeChild(divClearModal.childNodes[0]);
                                            }

                                            var divClearBackdrop = document.getElementById('backdropoutqueries');
                                            while(divClearBackdrop.hasChildNodes()) {
                                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                            }
                                        }

                                        //If No Add Clicked
                                        document.getElementById('nonHandlerAddQueryBtn').addEventListener('click', nonHandlerAddQueryBtnChange);
                                        async function nonHandlerAddQueryBtnChange(e){
                                            e.preventDefault();

                                            //code here

                                            var divClearModal = document.getElementById('modaloutqueris');
                                            while(divClearModal.hasChildNodes()) {
                                                divClearModal.removeChild(divClearModal.childNodes[0]);
                                            }

                                            var divClearBackdrop = document.getElementById('backdropoutqueries');
                                            while(divClearBackdrop.hasChildNodes()) {
                                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                            }
                                        }

                                        //If backdrop Clicked
                                        document.getElementById('backdropHandlerCloseAddQueryBtn').addEventListener('click', backdropHandlerCloseAddQueryBtnChange);
                                        async function backdropHandlerCloseAddQueryBtnChange(e){
                                            e.preventDefault();

                                            //code here

                                            var divClearModal = document.getElementById('modaloutqueries');
                                            while(divClearModal.hasChildNodes()) {
                                                divClearModal.removeChild(divClearModal.childNodes[0]);
                                            }

                                            var divClearBackdrop = document.getElementById('backdropoutqueries');
                                            while(divClearBackdrop.hasChildNodes()) {
                                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                            }
                                        }
                                        // 
                                    }

                                    //If No Clicked
                                    document.getElementById('nonHandlerExistsQueryBtn').addEventListener('click', nonHandlerExistsQueryBtnChange);
                                    async function nonHandlerExistsQueryBtnChange(e){
                                        e.preventDefault();

                                        //code here
                                        var divClearModal = document.getElementById('modaloutqueries');
                                        let divModal = document.createElement('div');
                                        divModal.innerHTML =
                                        `<div class="modal">
                                            <p>Wish to Use it?</p>
                                            <button class="btn btn--alt" id="ouiHandlerUseQueryBtn">Yes</button>
                                            <button class="btn" id="nonHandlerUseQueryBtn">No</button>
                                        </div>`;
                                        divClearModal.appendChild(divModal);

                                        var divClearBackdrop = document.getElementById('backdropoutqueries');
                                        let divBack = document.createElement('div');
                                        divBack.innerHTML =
                                        `<div class="backdrop" id="backdropHandlerCloseUseQueryBtn"></div>`;
                                        divClearBackdrop.appendChild(divBack);

                                        //If Yes Use Clicked
                                        document.getElementById('ouiHandlerUseQueryBtn').addEventListener('click', ouiHandlerUseQueryBtnChange);
                                        async function ouiHandlerUseQueryBtnChange(e){
                                            e.preventDefault();

                                            //code here
                                            const db = new PouchDB(localnamebaseStr, {skip_setup:true});
                                            const remote = new PouchDB('http://'+usernametaskStr+':'+passwordnametaskStr+'@'+hostnametaskStr+':'+hostporttaskStr+'/'+localnamebaseStr, {skip_setup:true});
                                            PouchDB.plugin(PouchFind);
                                            //test if hostname and portnumber are true
                                            var http_request = new XMLHttpRequest();
                                            http_request.open("GET", "http://"+hostnametaskStr+":"+hostporttaskStr+"/_utils/" ,true);
                                            http_request.send("null");
                                            http_request.onreadystatechange = async function(){
                                                if(http_request.readyState === 4){
                                                    if(http_request.status === 200){
                                                        function sync(){
                                                            var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                                            db.sync(remote, opts);
                                                        }
                                                        if(remote){
                                                            sync();
                                                        }
                                                        //end sync
                                                        var docsCheck = [];
                                                        try {
                                                            let result = await db.createIndex({
                                                                index: {
                                                                    fields: [
                                                                        'hostname', 
                                                                        'portname',
                                                                        'username',
                                                                        'passwordname'
                                                                    ]
                                                                }
                                                            });
                                                            result = await db.find({
                                                                selector: {
                                                                    hostname: hostnametaskStr,
                                                                    portname: hostporttaskStr,
                                                                    username: usernametaskStr,
                                                                    passwordname: passwordnametaskStr
                                                                }
                                                            });
                                                            docsCheck = result.docs;
                                                            var resultatCheck = docsCheck.length;
                                                            if(resultatCheck === ''){
                                                                var pTexteincorrectconnection = "Name or password is incorrect.";
                                                                alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
                                                            }else{
                                                                var docsCheckDatabase = [];
                                                                let resultDatabase = await db.createIndex({
                                                                    index: {
                                                                        fields: [
                                                                            'connectname'
                                                                        ]
                                                                    }
                                                                });
                                                                resultDatabase = await db.find({
                                                                    selector: {
                                                                        connectname: tasknameStr
                                                                    }
                                                                });
                                                                docsCheckDatabase = resultDatabase.docs;
                                                                var resultatCheckDatabase = docsCheckDatabase.length;
                                                                if(resultatCheckDatabase === 0){
                                                                    const dbNew = new PouchDB(tasknameStr);
                                                                    const remoteNew = couchProvider(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, tasknameStr);
                                                                    //add new database created to local connection server
                                                                    //Create the document _id and _rev.
                                                                    var now = Date.now();
                                                                    //new database id
                                                                    var id = tasknameStr.toLowerCase()+'_' + now;
                                                                    // Add the document to the database.
                                                                    var response = await db.put({
                                                                        _id: id,
                                                                        connectname: tasknameStr, //the new database name
                                                                        hostname: hostnametaskStr,
                                                                        portname: hostporttaskStr,
                                                                        username: usernametaskStr,
                                                                        passwordname: passwordnametaskStr,
                                                                        servername: localtypebaseStr,
                                                                    });
                                                                    // check if document inserted
                                                                    var doc = await db.get(response.id);
                                                                    if(doc !== ''){
                                                                        //Initialise a sync with the remote server
                                                                        function sync(){
                                                                            var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                                                            dbNew.sync(remoteNew, opts);
                                                                        }
                                                                        if(remoteNew){
                                                                            sync();
                                                                        }
                                                                        var pTextenewdatabase = "New Database added Succesfully";
                                                                        alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
                                                                    }else{
                                                                        var pTexteerreuraddnew = "An Error has Occurred";
                                                                        alertModal(elementOutId,elementDropId,pTexteerreuraddnew,buttonHandler);
                                                                    }
                                                                }else{
                                                                    var pTexteexistaddnew = "Database "+tasknameStr+" already exists. Confirm again";
                                                                    alertModal(elementOutId,elementDropId,pTexteexistaddnew,buttonHandler);
                                                                }
                                                            }
                                                        } catch (error) {
                                                            var pTexteerreurconnection = "An Error has Occurred";
                                                            alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                                                        }
                                                    }else{
                                                        var pTextefailedconnect = "Connection Failed.";
                                                        alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                                                    }
                                                }
                                            }//

                                            var divClearModal = document.getElementById('modaloutqueries');
                                            while(divClearModal.hasChildNodes()) {
                                                divClearModal.removeChild(divClearModal.childNodes[0]);
                                            }

                                            var divClearBackdrop = document.getElementById('backdropoutqueries');
                                            while(divClearBackdrop.hasChildNodes()) {
                                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                            }
                                        }

                                        //If No Use Clicked
                                        document.getElementById('nonHandlerUseQueryBtn').addEventListener('click', nonHandlerUseQueryBtnChange);
                                        async function nonHandlerUseQueryBtnChange(e){
                                            e.preventDefault();

                                            //code here

                                            var divClearModal = document.getElementById('modaloutqueries');
                                            while(divClearModal.hasChildNodes()) {
                                                divClearModal.removeChild(divClearModal.childNodes[0]);
                                            }

                                            var divClearBackdrop = document.getElementById('backdropoutqueries');
                                            while(divClearBackdrop.hasChildNodes()) {
                                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                            }
                                        }

                                        //If backdrop Clicked
                                        document.getElementById('backdropHandlerCloseUseQueryBtn').addEventListener('click', backdropHandlerCloseUseQueryBtnChange);
                                        async function backdropHandlerCloseUseQueryBtnChange(e){
                                            e.preventDefault();

                                            //code here

                                            var divClearModal = document.getElementById('modaloutqueries');
                                            while(divClearModal.hasChildNodes()) {
                                                divClearModal.removeChild(divClearModal.childNodes[0]);
                                            }

                                            var divClearBackdrop = document.getElementById('backdropoutqueries');
                                            while(divClearBackdrop.hasChildNodes()) {
                                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                            }
                                        }
                                        // 
                                    }
                                    //If backdrop Clicked
                                    document.getElementById('backdropHandlerCloseExistsQueryBtn').addEventListener('click', backdropHandlerCloseExistsQueryBtnChange);
                                    async function backdropHandlerCloseExistsQueryBtnChange(e){
                                        e.preventDefault();

                                        //code here

                                        var divClearModal = document.getElementById('modaloutqueries');
                                        while(divClearModal.hasChildNodes()) {
                                            divClearModal.removeChild(divClearModal.childNodes[0]);
                                        }

                                        var divClearBackdrop = document.getElementById('backdropoutqueries');
                                        while(divClearBackdrop.hasChildNodes()) {
                                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                        }
                                    }
                                }else{
                                    var docsCheckTable = [];
                                    var tableconnectStr = 'table';
                                    try {
                                        let resultTable = await db.createIndex({
                                            index: {
                                                fields: [
                                                    'tabletemplate', //not use database name but template as table global name
                                                    'tableserver',
                                                    'tableconnect'
                                                ]
                                            }
                                        });
                                        resultTable = await db.find({
                                            selector: {
                                                tabletemplate: tabletemplateStr, //not use database name but template as table global name
                                                tableserver: localnamebaseStr,
                                                tableconnect: tableconnectStr
                                            }
                                        });
                                        docsCheckTable = resultTable.docs;
                                        var resultatCheckTable = docsCheckTable.length;
                                        if(resultatCheckTable === 0){
                                            var pTextenotable = "No Table template available";
                                            alertModal(elementOutId,elementDropId,pTextenotable,buttonHandler);
                                        }else{
                                            document.getElementById('documentqueries').style.display = 'block';
                                            document.getElementById('querydocumentqueries').style.display = 'block';
                                            //xxxxxxxxx crudbase xxxxxxxxx
                                            updateListViewTableUIWithDocs(docsCheckTable);
                                            //xxxxxxxxxxxxxxxxxxxxxx
                                            document.getElementById('connectqueries').style.display = 'none';
                                            document.getElementById('listviewqueries').style.display = 'block';
                                            document.getElementById('viewdisplayqueries').style.display = 'none';
                                            document.getElementById('viewdocqueries').style.display = 'none';
                                            document.getElementById('editorqueries').style.display = 'none';
                                            document.getElementById('dynamictablequeries').style.display = 'none';
                                            //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
                                            document.getElementById('outputquery').style.display = 'none';
                                            document.getElementById('executerquery').style.display = 'none';
                                            //select components
                                            document.getElementById('selecttable').style.display = 'none';
                                            document.getElementById('selectonecolumn').style.display = 'none';
                                            document.getElementById('selecttwocolumn').style.display = 'none';
                                            document.getElementById('selectthreecolumn').style.display = 'none';
                                            //where components
                                            document.getElementById('wheretable').style.display = 'none';
                                            document.getElementById('whereonecolumn').style.display = 'none';
                                            document.getElementById('wheretwocolumn').style.display = 'none';
                                            document.getElementById('wherethreecolumn').style.display = 'none';
                                            //count components
                                            document.getElementById('counttable').style.display = 'none';
                                            document.getElementById('countonecolumn').style.display = 'none';
                                            document.getElementById('counttwocolumn').style.display = 'none';
                                            document.getElementById('countthreecolumn').style.display = 'none';
                                            //union components
                                            document.getElementById('uniontable').style.display = 'none';
                                            document.getElementById('uniontwodocument').style.display = 'none';
                                            document.getElementById('uniontwodoconecolumn').style.display = 'none';
                                            document.getElementById('uniontwodoctwocolumn').style.display = 'none';
                                            document.getElementById('uniontwodocthreecolumn').style.display = 'none';
                                            document.getElementById('unionthreedocument').style.display = 'none';
                                            document.getElementById('unionthreedoconecolumn').style.display = 'none';
                                            document.getElementById('unionthreedoctwocolumn').style.display = 'none';
                                            document.getElementById('unionthreedocthreecolumn').style.display = 'none';   
                                        }
                                    } catch (error) {
                                        var pTexteerreurtable = "An Error has Occurred 1";
                                        alertModal(elementOutId,elementDropId,pTexteerreurtable,buttonHandler);
                                    }
                                }//
                            }else{
                                var pTexteincorrectconnection = "Incorrect Connection Parameters";
                                alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
                            }
                        }
                    }
                    http_request.send();
                };
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        }
    }
    //Crud tasks (mobileconfirmquery)
    document.getElementById('mobileconfirmquery').addEventListener('click', mobileconfirmqueryChange);
    async function mobileconfirmqueryChange(e){
        e.preventDefault();

        //alert
        var elementOutId = 'modaloutalertqueries';
        var elementDropId = 'backdropoutalertqueries';
        var buttonHandler = 'ouiHandlerAlertQueryBtn';

        var mobileusernametaskStr = document.getElementById('mobileusernamequery').value;
        var mobilepasswordnametaskStr = document.getElementById('mobilepasswordnamequery').value;
        //var tasknameStr = document.getElementById('queryname').value.toLowerCase();
        var serveurHeaderStr = document.getElementById('serveurheader').innerText;
        var deviceStr = document.getElementById('device').innerText;
        var tabletemplateStr = 'template';
        if(!mobileusernametaskStr){
            var pTexteusername = "Enter Username";
            alertModal(elementOutId,elementDropId,pTexteusername,buttonHandler);
        }else if(!mobilepasswordnametaskStr){
            var pTextepasswordname = "Enter Password";
            alertModal(elementOutId,elementDropId,pTextepasswordname,buttonHandler);
        }else{
            var docsCheckConfirm = [];
            try {
                if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
                    const db = new PouchDB(serveurHeaderStr, { skip_setup: true });
                    PouchDB.plugin(PouchFind);

                    let result = await db.createIndex({
                        index: {
                          fields: [
                            "username", 
                            "passwordname", 
                            "servername"
                          ],
                        }
                    });
                    result = await db.find({
                        selector: {
                          username: mobileusernametaskStr,
                          passwordname: mobilepasswordnametaskStr,
                          servername: serveurHeaderStr
                        }
                    });
                    docsCheckConfirm = result.docs;
                    var resultatCheckConfirm = docsCheckConfirm.length;
                    if (resultatCheckConfirm === 0){
                        var pTextenotexistdetail = "Invalid Username or Password";
                        alertModal(elementOutId,elementDropId,pTextenotexistdetail,buttonHandler);
                    }else{
                        var docsCheckTable = [];
                        var tableconnectStr = "table";
                        var tabletemplateStr = "template";
                        var tabledatabaseStr = serveurHeaderStr.toLocaleLowerCase();
                        var tableserverStr = serveurHeaderStr.toLocaleLowerCase();
                        try {
                            let resultTable = await db.createIndex({
                                index: {
                                  fields: [
                                    "tabledatabase",
                                    "tabletemplate", 
                                    "tableserver",
                                    "tableconnect",
                                  ],
                                },
                            });
                            resultTable = await db.find({
                                selector: {
                                  tabledatabase: tabledatabaseStr,
                                  tabletemplate: tabletemplateStr,
                                  tableserver: tableserverStr,
                                  tableconnect: tableconnectStr,
                                },
                            });
                            docsCheckTable = resultTable.docs;
                            var resultatCheckTable = docsCheckTable.length;
                            if (resultatCheckTable === 0){
                                var pTextenotable = "No Table available";
                                alertModal(elementOutId,elementDropId,pTextenotable,buttonHandler);
                            }else{
                                document.getElementById('documentqueries').style.display = 'block';
                                document.getElementById('querydocumentqueries').style.display = 'block';
                                //xxxxxxxxx crudbase xxxxxxxxx
                                updateListViewTableUIWithDocs(docsCheckTable);
                                //xxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('connectqueries').style.display = 'none';
                                document.getElementById('listviewqueries').style.display = 'block';
                                document.getElementById('viewdisplayqueries').style.display = 'none';
                                document.getElementById('viewdocqueries').style.display = 'none';
                                document.getElementById('editorqueries').style.display = 'none';
                                document.getElementById('dynamictablequeries').style.display = 'none';
                                //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
                                document.getElementById('outputquery').style.display = 'none';
                                document.getElementById('executerquery').style.display = 'none';
                                //select components
                                document.getElementById('selecttable').style.display = 'none';
                                document.getElementById('selectonecolumn').style.display = 'none';
                                document.getElementById('selecttwocolumn').style.display = 'none';
                                document.getElementById('selectthreecolumn').style.display = 'none';
                                //where components
                                document.getElementById('wheretable').style.display = 'none';
                                document.getElementById('whereonecolumn').style.display = 'none';
                                document.getElementById('wheretwocolumn').style.display = 'none';
                                document.getElementById('wherethreecolumn').style.display = 'none';
                                //count components
                                document.getElementById('counttable').style.display = 'none';
                                document.getElementById('countonecolumn').style.display = 'none';
                                document.getElementById('counttwocolumn').style.display = 'none';
                                document.getElementById('countthreecolumn').style.display = 'none';
                                //union components
                                document.getElementById('uniontable').style.display = 'none';
                                document.getElementById('uniontwodocument').style.display = 'none';
                                document.getElementById('uniontwodoconecolumn').style.display = 'none';
                                document.getElementById('uniontwodoctwocolumn').style.display = 'none';
                                document.getElementById('uniontwodocthreecolumn').style.display = 'none';
                                document.getElementById('unionthreedocument').style.display = 'none';
                                document.getElementById('unionthreedoconecolumn').style.display = 'none';
                                document.getElementById('unionthreedoctwocolumn').style.display = 'none';
                                document.getElementById('unionthreedocthreecolumn').style.display = 'none';  
                            }
                        } catch (error) {
                            var pTexteerreurtable = "An Error has Occurred";
                            alertModal(elementOutId,elementDropId,pTexteerreurtable,buttonHandler);
                        }
                    }
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        }
    }
    // Adds the document to the HTML First table.
    // If the document already exists in the table, it will be updated.
    var queryHeader = '' //global variable to assign template header
    function listViewDocToToTable(doc, crudViewQueryTableBody){
        // Each row of the table has an id attribute set to the doc._id field.
        var tr = crudViewQueryTableBody.querySelector(`#${doc._id}`);

        if( !tr ) {
            tr = document.createElement('tr');
            tr.id = doc._id;
        }

        // Remove any existing table cells.
        while(tr.hasChildNodes()) {
            tr.removeChild(tr.childNodes[0]);
        }

        // Create new table cells for text fields.
        var tdTableServer = document.createElement('td');
        tdTableServer.innerText = doc.tableserver;
        //var tableNameStr = doc.tablename;
        tdTableServer.onclick = function(){
            var tableidStr = doc._id;
            var tabledatabaseStr = doc.tabledatabase;
            var tablenameStr = doc.tablename;
            var tableserverStr = doc.tableserver;
            var tablekeyStr = doc.tablekey;
            var tablevalueStr = doc.tablevalue;
            queryHeader = tablevalueStr;
            var tableconnectStr = doc.tableconnect;

            var nametablechangetext = document.getElementById('namequeryviewdisplay');
            nametablechangetext.value = tablenameStr;
            var fieldtablechangetext = document.getElementById('fieldqueryviewdisplay');
            fieldtablechangetext.value = tablekeyStr.length;
            var tableidchangetext = document.getElementById('queryidviewdisplay');
            tableidchangetext.value = tableidStr;
            var tabledatabasechangetext = document.getElementById('querydatabaseviewdisplay');
            tabledatabasechangetext.value = tabledatabaseStr;
            var tableserverchangetext = document.getElementById('queryserverviewdisplay');
            tableserverchangetext.value = tableserverStr;
            var tableconnectchangetext = document.getElementById('queryconnectviewdisplay');
            tableconnectchangetext.value = tableconnectStr;

            document.getElementById('documentqueries').style.display = 'block';
            document.getElementById('querydocumentqueries').style.display = 'block';
            //xxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('connectqueries').style.display = 'none';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'block';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'none';
            document.getElementById('dynamictablequeries').style.display = 'none';
            //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
            document.getElementById('outputquery').style.display = 'none';
            document.getElementById('executerquery').style.display = 'none';
            //select components
            document.getElementById('selecttable').style.display = 'none';
            document.getElementById('selectonecolumn').style.display = 'none';
            document.getElementById('selecttwocolumn').style.display = 'none';
            document.getElementById('selectthreecolumn').style.display = 'none';
            //where components
            document.getElementById('wheretable').style.display = 'none';
            document.getElementById('whereonecolumn').style.display = 'none';
            document.getElementById('wheretwocolumn').style.display = 'none';
            document.getElementById('wherethreecolumn').style.display = 'none';
            //count components
            document.getElementById('counttable').style.display = 'none';
            document.getElementById('countonecolumn').style.display = 'none';
            document.getElementById('counttwocolumn').style.display = 'none';
            document.getElementById('countthreecolumn').style.display = 'none';
            //union components
            document.getElementById('uniontable').style.display = 'none';
            document.getElementById('uniontwodocument').style.display = 'none';
            document.getElementById('uniontwodoconecolumn').style.display = 'none';
            document.getElementById('uniontwodoctwocolumn').style.display = 'none';
            document.getElementById('uniontwodocthreecolumn').style.display = 'none';
            document.getElementById('unionthreedocument').style.display = 'none';
            document.getElementById('unionthreedoconecolumn').style.display = 'none';
            document.getElementById('unionthreedoctwocolumn').style.display = 'none';
            document.getElementById('unionthreedocthreecolumn').style.display = 'none';
        }
        tr.appendChild(tdTableServer);

        // Create new table cells for text fields.
        var tdTableName = document.createElement('td');
        tdTableName.innerText = doc.tablename;
        //var tdIdTableStr = doc.tablename;
        tdTableName.onclick = function(){
            var tableidStr = doc._id;
            var tabledatabaseStr = doc.tabledatabase;
            var tablenameStr = doc.tablename;
            var tableserverStr = doc.tableserver;
            var tablekeyStr = doc.tablekey;
            var tablevalueStr = doc.tablevalue;
            queryHeader = tablevalueStr;
            var tableconnectStr = doc.tableconnect;

            var nametablechangetext = document.getElementById('namequeryviewdisplay');
            nametablechangetext.value = tablenameStr;
            var fieldtablechangetext = document.getElementById('fieldqueryviewdisplay');
            fieldtablechangetext.value = tablekeyStr.length;
            var tableidchangetext = document.getElementById('queryidviewdisplay');
            tableidchangetext.value = tableidStr;
            var tabledatabasechangetext = document.getElementById('querydatabaseviewdisplay');
            tabledatabasechangetext.value = tabledatabaseStr;
            var tableserverchangetext = document.getElementById('queryserverviewdisplay');
            tableserverchangetext.value = tableserverStr;
            var tableconnectchangetext = document.getElementById('queryconnectviewdisplay');
            tableconnectchangetext.value = tableconnectStr;

            document.getElementById('documentqueries').style.display = 'block';
            document.getElementById('querydocumentqueries').style.display = 'block';
            //xxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('connectqueries').style.display = 'none';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'block';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'none';
            document.getElementById('dynamictablequeries').style.display = 'none';
            //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
            document.getElementById('outputquery').style.display = 'none';
            document.getElementById('executerquery').style.display = 'none';
            //select components
            document.getElementById('selecttable').style.display = 'none';
            document.getElementById('selectonecolumn').style.display = 'none';
            document.getElementById('selecttwocolumn').style.display = 'none';
            document.getElementById('selectthreecolumn').style.display = 'none';
            //where components
            document.getElementById('wheretable').style.display = 'none';
            document.getElementById('whereonecolumn').style.display = 'none';
            document.getElementById('wheretwocolumn').style.display = 'none';
            document.getElementById('wherethreecolumn').style.display = 'none';
            //count components
            document.getElementById('counttable').style.display = 'none';
            document.getElementById('countonecolumn').style.display = 'none';
            document.getElementById('counttwocolumn').style.display = 'none';
            document.getElementById('countthreecolumn').style.display = 'none';
            //union components
            document.getElementById('uniontable').style.display = 'none';
            document.getElementById('uniontwodocument').style.display = 'none';
            document.getElementById('uniontwodoconecolumn').style.display = 'none';
            document.getElementById('uniontwodoctwocolumn').style.display = 'none';
            document.getElementById('uniontwodocthreecolumn').style.display = 'none';
            document.getElementById('unionthreedocument').style.display = 'none';
            document.getElementById('unionthreedoconecolumn').style.display = 'none';
            document.getElementById('unionthreedoctwocolumn').style.display = 'none';
            document.getElementById('unionthreedocthreecolumn').style.display = 'none';
        }
        tr.appendChild(tdTableName);

        // Add the row to the top of the table.
        crudViewQueryTableBody.insertBefore(tr, crudViewQueryTableBody.nextSibling);
    }
    /**Clear and update the UI to display the docs.*/
    function updateListViewTableUIWithDocs(docs){
        var crudViewQueryTableBody = document.getElementById('crudViewQueryTableBody');

        // Remove all child nodes from the table
        while(crudViewQueryTableBody.hasChildNodes()) {
            crudViewQueryTableBody.removeChild(crudViewQueryTableBody.childNodes[0]);
        }

        // Add the new documents to the div
        for(var i=0; i<docs.length; i++) {
            var doc = docs[i];
            listViewDocToToTable(doc, crudViewQueryTableBody);
        }
    }

    //global arrays variable to query
    //var querykeyview = []; var queryvalueview = [];
    //retreive the table input field dynamically to fill data in the table
    document.getElementById('confirmquerynameviewdisplay').addEventListener('click', confirmquerynameviewdisplayChange);
    async function confirmquerynameviewdisplayChange(e){
        e.preventDefault();

        //alert
        var elementOutId = 'modaloutalertqueries';
        var elementDropId = 'backdropoutalertqueries';
        var buttonHandler = 'ouiHandlerAlertQueriesBtn';

        var hostnametaskStr = document.getElementById('hostnamequery').value;
        var hostporttaskStr = document.getElementById('hostportquery').value;
        var usernametaskStr = document.getElementById('usernamequery').value;
        var passwordnametaskStr = document.getElementById('passwordnamequery').value;
        //var tasknameStr = document.getElementById('taskname').value;
        var serveurHeaderStr = document.getElementById('serveurheader').innerText;
        var deviceStr = document.getElementById('device').innerText;
        //xxxxxxxxxxxxxxxxxxx
        var nametaskchangeStr = document.getElementById('namequeryviewdisplay').value.toLocaleLowerCase();
        var taskidchangeStr = document.getElementById('queryidviewdisplay').value;
        var taskdatabasechangeStr = document.getElementById('queryname').value; //from newtasks component
        var taskserverchangeStr = document.getElementById('queryserverviewdisplay').value;
        var taskconnectchangeStr = document.getElementById('queryconnectviewdisplay').value;
        //xxxxxxxxxxxxxx
        var localnamebaseStr = '';
        //var servernameStr = 'database';
        if(serveurHeaderStr === 'local'){
            localnamebaseStr = 'local';
        }else if(serveurHeaderStr === 'remote'){
            localnamebaseStr = 'remote';
        }

        //code here
        if((deviceStr === '' && localnamebaseStr === 'local') || 
        (deviceStr === '' && localnamebaseStr === 'remote') || (deviceStr === 'mobile' && localnamebaseStr === 'remote')){
            const db = new PouchDB(localnamebaseStr, {skip_setup:true});
            const remote = new PouchDB('http://'+usernametaskStr+':'+passwordnametaskStr+'@'+hostnametaskStr+':'+hostporttaskStr+'/'+localnamebaseStr, {skip_setup:true});
            PouchDB.plugin(PouchFind);
            //test if hostname and portnumber are true
            var http_request = new XMLHttpRequest();
            http_request.open("GET", "http://"+hostnametaskStr+":"+hostporttaskStr+"/_utils/" ,true);
            http_request.onreadystatechange = async function(){
                if(http_request.readyState === 4){
                    if(http_request.status === 200){
                        function sync(){
                            var opts = {live: true, retry: true, continuous: true, attachments: true,};
                            db.sync(remote, opts);
                        }
                        if(remote){
                            sync();
                        }
                        //end sync
                        var docsCheck = [];
                        try {
                            let result = await db.createIndex({
                                index: {
                                    fields: [
                                        'hostname', 
                                        'portname',
                                        'username',
                                        'passwordname'
                                    ]
                                }
                            });
                            result = await db.find({
                                selector: {
                                    hostname: hostnametaskStr,
                                    portname: hostporttaskStr,
                                    username: usernametaskStr,
                                    passwordname: passwordnametaskStr
                                }
                            });
                            docsCheck = result.docs;
                            var resultatCheck = docsCheck.length;
                            if(resultatCheck === ''){
                                var pTexteincorrectconnection = "Name or password is incorrect.";
                                alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
                            }else{
                                var docsCheckDatabase = [];
                                let resultDatabase = await db.createIndex({
                                    index: {
                                        fields: [
                                            '_id'
                                        ]
                                    }
                                });
                                resultDatabase = await db.find({
                                    selector: {
                                        _id: taskidchangeStr
                                    }
                                });
                                docsCheckDatabase = resultDatabase.docs;
                                var resultatCheckDatabase = docsCheckDatabase.length;
                                if(resultatCheckDatabase === 0){
                                    var pTextenotable = "Table "+nametaskchangeStr+" not available";
                                    alertModal(elementOutId,elementDropId,pTextenotable,buttonHandler);
                                }else{
                                    //search in local / remote database documents
                                    const dbbase = new PouchDB(taskdatabasechangeStr, {skip_setup:true});
                                    const remotebase = new PouchDB('http://'+usernametaskStr+':'+passwordnametaskStr+'@'+hostnametaskStr+':'+hostporttaskStr+'/'+taskdatabasechangeStr, {skip_setup:true});
                                    PouchDB.plugin(PouchFind);
                                    // Initialise a sync with the remote server
                                    function sync(){
                                        var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                        dbbase.sync(remotebase, opts);
                                    }
                                    if(remotebase){
                                        sync();
                                    }
                                    var docsCheckDocument = [];
                                    let result = await dbbase.createIndex({
                                        index: {
                                            fields: [
                                                'taskdatabase',
                                                'taskname',
                                                'taskserver',
                                                'taskconnect'
                                            ]
                                        }
                                    });
                                    result = await dbbase.find({
                                        selector: {
                                            taskdatabase: taskdatabasechangeStr,
                                            taskname: nametaskchangeStr,
                                            taskserver: taskserverchangeStr,
                                            taskconnect: taskconnectchangeStr
                                        }
                                    }); 
                                    docsCheckDocument = result.docs;
                                    var resultatCheckDocument = docsCheckDocument.length;
                                    if(resultatCheckDocument === 0){
                                        var pTextenodocument = "No Document available";
                                        alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                                    }else{
                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('documentqueries').style.display = 'block';
                                        document.getElementById('querydocumentqueries').style.display = 'block';
                                        //xxxxxxxxxxxxxxxxxxxxxx
                                        viewSelectDocumentQueryTableUIWithDocs(docsCheckDocument);
                                        document.getElementById('connectqueries').style.display = 'none';
                                        document.getElementById('listviewqueries').style.display = 'none';
                                        document.getElementById('viewdisplayqueries').style.display = 'none';
                                        document.getElementById('viewdocqueries').style.display = 'block';
                                        document.getElementById('editorqueries').style.display = 'none';
                                        document.getElementById('dynamictablequeries').style.display = 'none';
                                        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
                                        document.getElementById('outputquery').style.display = 'none';
                                        document.getElementById('executerquery').style.display = 'none';
                                        //select components
                                        document.getElementById('selecttable').style.display = 'none';
                                        document.getElementById('selectonecolumn').style.display = 'none';
                                        document.getElementById('selecttwocolumn').style.display = 'none';
                                        document.getElementById('selectthreecolumn').style.display = 'none';
                                        //where components
                                        document.getElementById('wheretable').style.display = 'none';
                                        document.getElementById('whereonecolumn').style.display = 'none';
                                        document.getElementById('wheretwocolumn').style.display = 'none';
                                        document.getElementById('wherethreecolumn').style.display = 'none';
                                        //count components
                                        document.getElementById('counttable').style.display = 'none';
                                        document.getElementById('countonecolumn').style.display = 'none';
                                        document.getElementById('counttwocolumn').style.display = 'none';
                                        document.getElementById('countthreecolumn').style.display = 'none';
                                        //union components
                                        document.getElementById('uniontable').style.display = 'none';
                                        document.getElementById('uniontwodocument').style.display = 'none';
                                        document.getElementById('uniontwodoconecolumn').style.display = 'none';
                                        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
                                        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
                                        document.getElementById('unionthreedocument').style.display = 'none';
                                        document.getElementById('unionthreedoconecolumn').style.display = 'none';
                                        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
                                        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
                                    }
                                }
                            }
                        } catch (error) {
                            var pTexteerreurconnection = "An Error has Occurred";
                            alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                        }
                    }else{
                        var pTextefailedconnect = "Connection Failed.";
                        alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                    }
                }
            }
            http_request.send();
        };

        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            const db = new PouchDB(serveurHeaderStr, { skip_setup: true });
            PouchDB.plugin(PouchFind);
            var docsCheck = [];
            try {
                let result = await db.createIndex({
                    index: {
                      fields: [
                        'servername'
                      ]
                    },
                });
                result = await db.find({
                    selector: {
                      servername: serveurHeaderStr
                    }
                });
                docsCheck = result.docs;
                var resultatCheck = docsCheck.length;
                if (resultatCheck === 0){
                    var pTextenotexistaddnew = "Database not exists. Create it first";
                    alertModal(elementOutId,elementDropId,pTextenotexistaddnew,buttonHandler);
                }else{
                    var docsCheckDatabase = [];
                    let resultDatabase = await db.createIndex({
                        index: {
                            fields: [
                                '_id'
                            ]
                        }
                    });
                    resultDatabase = await db.find({
                        selector: {
                            _id: taskidchangeStr
                        }
                    });
                    docsCheckDatabase = resultDatabase.docs;
                    var resultatCheckDatabase = docsCheckDatabase.length;
                    if(resultatCheckDatabase === 0){
                        var pTextenotable = "Table "+nametaskchangeStr+" not available";
                        alertModal(elementOutId,elementDropId,pTextenotable,buttonHandler);
                    }else{
                        const dbbase = new PouchDB(serveurHeaderStr, {skip_setup:true});
                        PouchDB.plugin(PouchFind);
                        var docsCheckDocument = [];
                        let result = await dbbase.createIndex({
                            index: {
                                fields: [
                                    'taskdatabase',
                                    'taskname',
                                    'taskserver',
                                    'taskconnect'
                                ]
                            }
                        });
                        result = await dbbase.find({
                            selector: {
                                taskdatabase: serveurHeaderStr,
                                taskname: nametaskchangeStr,
                                taskserver: taskserverchangeStr,
                                taskconnect: taskconnectchangeStr
                            }
                        }); 
                        docsCheckDocument = result.docs;
                        var resultatCheckDocument = docsCheckDocument.length;
                        if(resultatCheckDocument === 0){
                            var pTextenodocument = "No Document available";
                            alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                        }else{
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('documentqueries').style.display = 'block';
                            document.getElementById('querydocumentqueries').style.display = 'block';
                            //xxxxxxxxxxxxxxxxxxxxxx
                            viewSelectDocumentQueryTableUIWithDocs(docsCheckDocument);
                            document.getElementById('connectqueries').style.display = 'none';
                            document.getElementById('listviewqueries').style.display = 'none';
                            document.getElementById('viewdisplayqueries').style.display = 'none';
                            document.getElementById('viewdocqueries').style.display = 'block';
                            document.getElementById('editorqueries').style.display = 'none';
                            document.getElementById('dynamictablequeries').style.display = 'none';
                            //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
                            document.getElementById('outputquery').style.display = 'none';
                            document.getElementById('executerquery').style.display = 'none';
                            //select components
                            document.getElementById('selecttable').style.display = 'none';
                            document.getElementById('selectonecolumn').style.display = 'none';
                            document.getElementById('selecttwocolumn').style.display = 'none';
                            document.getElementById('selectthreecolumn').style.display = 'none';
                            //where components
                            document.getElementById('wheretable').style.display = 'none';
                            document.getElementById('whereonecolumn').style.display = 'none';
                            document.getElementById('wheretwocolumn').style.display = 'none';
                            document.getElementById('wherethreecolumn').style.display = 'none';
                            //count components
                            document.getElementById('counttable').style.display = 'none';
                            document.getElementById('countonecolumn').style.display = 'none';
                            document.getElementById('counttwocolumn').style.display = 'none';
                            document.getElementById('countthreecolumn').style.display = 'none';
                            //union components
                            document.getElementById('uniontable').style.display = 'none';
                            document.getElementById('uniontwodocument').style.display = 'none';
                            document.getElementById('uniontwodoconecolumn').style.display = 'none';
                            document.getElementById('uniontwodoctwocolumn').style.display = 'none';
                            document.getElementById('uniontwodocthreecolumn').style.display = 'none';
                            document.getElementById('unionthreedocument').style.display = 'none';
                            document.getElementById('unionthreedoconecolumn').style.display = 'none';
                            document.getElementById('unionthreedoctwocolumn').style.display = 'none';
                            document.getElementById('unionthreedocthreecolumn').style.display = 'none';
                        }
                    }
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };
    }
    // Adds the document to the HTML First table.
    // If the document already exists in the table, it will be updated.
    function selectViewQueryDocToToTable(doc, viewDocumentQueryTableBody){
        // Each row of the table has an id attribute set to the doc._id field.
        var tr = viewDocumentQueryTableBody.querySelector(`#${doc._id}`);

        if( !tr ) {
            tr = document.createElement('tr');
            tr.id = doc._id;
        }

        // Remove any existing table cells.
        while(tr.hasChildNodes()) {
            tr.removeChild(tr.childNodes[0]);
        }

        // Create new table cells for text fields.
        var tdTemplate = document.createElement('td');
        tdTemplate.innerText = doc.taskname;
        tdTemplate.onclick = async function(){
            var taskdatabaseStr = doc.taskdatabase;
            var tasknameStr = doc.taskname;
            var taskserverStr = doc.taskserver;
            var documentnameStr = doc.documentname;

            var databasequerytext = document.getElementById('databasequery');
            databasequerytext.value = taskdatabaseStr;
            var templatequerytext = document.getElementById('templatequery');
            templatequerytext.value = tasknameStr;
            var serverquerytext = document.getElementById('serverquery');
            serverquerytext.value = taskserverStr;
            var documentquerytext = document.getElementById('documentquery');
            documentquerytext.value = documentnameStr;
            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('documentqueries').style.display = 'block';
            document.getElementById('querydocumentqueries').style.display = 'block';
            //xxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('connectqueries').style.display = 'none';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'none';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'block';
            document.getElementById('dynamictablequeries').style.display = 'none';
            //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
            document.getElementById('outputquery').style.display = 'none';
            document.getElementById('executerquery').style.display = 'none';
            //select components
            document.getElementById('selecttable').style.display = 'none';
            document.getElementById('selectonecolumn').style.display = 'none';
            document.getElementById('selecttwocolumn').style.display = 'none';
            document.getElementById('selectthreecolumn').style.display = 'none';
            //where components
            document.getElementById('wheretable').style.display = 'none';
            document.getElementById('whereonecolumn').style.display = 'none';
            document.getElementById('wheretwocolumn').style.display = 'none';
            document.getElementById('wherethreecolumn').style.display = 'none';
            //count components
            document.getElementById('counttable').style.display = 'none';
            document.getElementById('countonecolumn').style.display = 'none';
            document.getElementById('counttwocolumn').style.display = 'none';
            document.getElementById('countthreecolumn').style.display = 'none';
            //union components
            document.getElementById('uniontable').style.display = 'none';
            document.getElementById('uniontwodocument').style.display = 'none';
            document.getElementById('uniontwodoconecolumn').style.display = 'none';
            document.getElementById('uniontwodoctwocolumn').style.display = 'none';
            document.getElementById('uniontwodocthreecolumn').style.display = 'none';
            document.getElementById('unionthreedocument').style.display = 'none';
            document.getElementById('unionthreedoconecolumn').style.display = 'none';
            document.getElementById('unionthreedoctwocolumn').style.display = 'none';
            document.getElementById('unionthreedocthreecolumn').style.display = 'none';
        }
        tr.appendChild(tdTemplate);

        // Create new table cells for text fields.
        var tdDocument = document.createElement('td');
        tdDocument.innerText = doc.documentname;
        tdDocument.onclick = async function(){
            var taskdatabaseStr = doc.taskdatabase;
            var tasknameStr = doc.taskname;
            var taskserverStr = doc.taskserver;
            var documentnameStr = doc.documentname;

            var databasequerytext = document.getElementById('databasequery');
            databasequerytext.value = taskdatabaseStr;
            var templatequerytext = document.getElementById('templatequery');
            templatequerytext.value = tasknameStr;
            var serverquerytext = document.getElementById('serverquery');
            serverquerytext.value = taskserverStr;
            var documentquerytext = document.getElementById('documentquery');
            documentquerytext.value = documentnameStr;
            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('documentqueries').style.display = 'block';
            document.getElementById('querydocumentqueries').style.display = 'block';
            //xxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('connectqueries').style.display = 'none';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'none';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'block';
            document.getElementById('dynamictablequeries').style.display = 'none';
            //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
            document.getElementById('outputquery').style.display = 'none';
            document.getElementById('executerquery').style.display = 'none';
            //select components
            document.getElementById('selecttable').style.display = 'none';
            document.getElementById('selectonecolumn').style.display = 'none';
            document.getElementById('selecttwocolumn').style.display = 'none';
            document.getElementById('selectthreecolumn').style.display = 'none';
            //where components
            document.getElementById('wheretable').style.display = 'none';
            document.getElementById('whereonecolumn').style.display = 'none';
            document.getElementById('wheretwocolumn').style.display = 'none';
            document.getElementById('wherethreecolumn').style.display = 'none';
            //count components
            document.getElementById('counttable').style.display = 'none';
            document.getElementById('countonecolumn').style.display = 'none';
            document.getElementById('counttwocolumn').style.display = 'none';
            document.getElementById('countthreecolumn').style.display = 'none';
            //union components
            document.getElementById('uniontable').style.display = 'none';
            document.getElementById('uniontwodocument').style.display = 'none';
            document.getElementById('uniontwodoconecolumn').style.display = 'none';
            document.getElementById('uniontwodoctwocolumn').style.display = 'none';
            document.getElementById('uniontwodocthreecolumn').style.display = 'none';
            document.getElementById('unionthreedocument').style.display = 'none';
            document.getElementById('unionthreedoconecolumn').style.display = 'none';
            document.getElementById('unionthreedoctwocolumn').style.display = 'none';
            document.getElementById('unionthreedocthreecolumn').style.display = 'none';
        }
        tr.appendChild(tdDocument);

        // Add the row to the top of the table.
        viewDocumentQueryTableBody.insertBefore(tr, viewDocumentQueryTableBody.nextSibling);
    }
    /**Clear and update the UI to display the docs.*/
    function viewSelectDocumentQueryTableUIWithDocs(docs){
        var viewDocumentQueryTableBody = document.getElementById('viewDocumentQueryTableBody');

        // Remove all child nodes from the table
        while(viewDocumentQueryTableBody.hasChildNodes()) {
            viewDocumentQueryTableBody.removeChild(viewDocumentQueryTableBody.childNodes[0]);
        }

        // Add the new documents to the div
        for(var i=0; i<1; i++) {
            var doc = docs[i];
            selectViewQueryDocToToTable(doc, viewDocumentQueryTableBody);
        }
    }////

    //Undo table template selected
    document.getElementById('undoquerynameviewdisplay').addEventListener('click', undoquerynameviewdisplayChange);
    async function undoquerynameviewdisplayChange(e){
        e.preventDefault();

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'block';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'none';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }

    //QUERIES WORKBENCH
    //A. select * from table
    document.getElementById('selectquery').addEventListener('click', selectqueryChange);
    async function selectqueryChange(e){
        e.preventDefault();
        
        //xxxxxxxxxxxxxxx selected query name xxxxxxxxxx
        var selectedtqueryStr = document.getElementById('selectedtquery');
        selectedtqueryStr.value = 'select';

        //clear some input variable
        document.getElementById('querycol_1').value = '';
        document.getElementById('querycol_2').value = '';
        document.getElementById('querycol_3').value = '';
        document.getElementById('colname_1').value = '';
        document.getElementById('colname_2').value = '';
        document.getElementById('colname_3').value = '';

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'block';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }
    //--------- event listener for select query --------------- 
    //select all rows of all columns
    document.getElementById('all_0').addEventListener('click', all_0Change);
    async function all_0Change(e){
        e.preventDefault();

        //clear some input variable
        document.getElementById('querycol_1').value = '';
        document.getElementById('querycol_2').value = '';
        document.getElementById('querycol_3').value = '';
        document.getElementById('colname_1').value = '';
        document.getElementById('colname_2').value = '';
        document.getElementById('colname_3').value = '';
        
        var documentqueryStr = document.getElementById('documentquery').value; //document name
        var writequeryStr = document.getElementById('writequery');
        writequeryStr.value = 'SELECT * FROM '+documentqueryStr+';';
        writequeryStr.readOnly = true;

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'block';
        document.getElementById('executerquery').style.display = 'block';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }
    //select all rows of 1 specified column
    document.getElementById('all_1').addEventListener('click', all_1Change);
    async function all_1Change(e){
        e.preventDefault();

        //clear some input variable
        document.getElementById('querycol_1').value = '';
        document.getElementById('querycol_2').value = '';
        document.getElementById('querycol_3').value = '';
        document.getElementById('colname_1').value = '';
        document.getElementById('colname_2').value = '';
        document.getElementById('colname_3').value = '';

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var hostnametaskStr = document.getElementById('hostnamequery').value;
        var hostporttaskStr = document.getElementById('hostportquery').value;
        var usernametaskStr = document.getElementById('usernamequery').value;
        var passwordnametaskStr = document.getElementById('passwordnamequery').value;
        var serveurHeaderStr = document.getElementById("serveurheader").innerText;
        var deviceStr = document.getElementById('device').innerText;
        //xxxxxxxxxxxxxxx
        var databasequeryStr = document.getElementById('databasequery').value;
        var templatequeryStr = document.getElementById('templatequery').value;
        var serverqueryStr = document.getElementById('serverquery').value;
        var documentqueryStr = document.getElementById('documentquery').value;
        //search in local / remote database documents
        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            const dbbase = new PouchDB(databasequeryStr, {skip_setup: true,});
            const remotebase = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +databasequeryStr,{ skip_setup: true });
            PouchDB.plugin(PouchFind);
            // Initialise a sync with the remote server
            function sync() {
                var opts = {live: true,retry: true,continuous: true,attachments: true,};
                dbbase.sync(remotebase, opts);
            }
            if (remotebase) {
                sync();
            }
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                        fields: [
                            "taskdatabase",
                            "taskname",
                            "taskserver",
                            "documentname",
                        ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: databasequeryStr,
                        taskname: templatequeryStr,
                        taskserver: serverqueryStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    taskkeyquery = [];
                    var valeur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("onecolumn"), column);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            const dbbase = new PouchDB(serveurHeaderStr, {skip_setup: true,});
            PouchDB.plugin(PouchFind);
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                      fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "documentname",
                      ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: serveurHeaderStr,
                        taskname: templatequeryStr,
                        taskserver: serveurHeaderStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    taskkeyquery = [];
                    var valeur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("onecolumn"), column);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };
        
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'block';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }
    //confirm onecolumn click
    document.getElementById('confirmonecolumn').addEventListener('click', confirmonecolumnChange);
    async function confirmonecolumnChange(e){
        e.preventDefault();

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var onecolumnStr = document.getElementById('onecolumn').value;
        var documentqueryStr = document.getElementById('documentquery').value; //document name
        var writequeryStr = document.getElementById('writequery');
        if(!onecolumnStr){
            var pTextenocolumn = "Please enter column name";
            alertModal(elementOutId,elementDropId,pTextenocolumn,buttonHandler);
        }else{
            writequeryStr.value = 'SELECT '+onecolumnStr+' FROM '+documentqueryStr+';';
            writequeryStr.readOnly = true;
            var querycol_1Str = document.getElementById('querycol_1');
            querycol_1Str.value = onecolumnStr;

            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('documentqueries').style.display = 'block';
            document.getElementById('querydocumentqueries').style.display = 'block';
            //xxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('connectqueries').style.display = 'none';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'none';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'block';
            document.getElementById('dynamictablequeries').style.display = 'none';
            //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
            document.getElementById('outputquery').style.display = 'block';
            document.getElementById('executerquery').style.display = 'block';
            //select components
            document.getElementById('selecttable').style.display = 'none';
            document.getElementById('selectonecolumn').style.display = 'none';
            document.getElementById('selecttwocolumn').style.display = 'none';
            document.getElementById('selectthreecolumn').style.display = 'none';
            //where components
            document.getElementById('wheretable').style.display = 'none';
            document.getElementById('whereonecolumn').style.display = 'none';
            document.getElementById('wheretwocolumn').style.display = 'none';
            document.getElementById('wherethreecolumn').style.display = 'none';
            //count components
            document.getElementById('counttable').style.display = 'none';
            document.getElementById('countonecolumn').style.display = 'none';
            document.getElementById('counttwocolumn').style.display = 'none';
            document.getElementById('countthreecolumn').style.display = 'none';
            //union components
            document.getElementById('uniontable').style.display = 'none';
            document.getElementById('uniontwodocument').style.display = 'none';
            document.getElementById('uniontwodoconecolumn').style.display = 'none';
            document.getElementById('uniontwodoctwocolumn').style.display = 'none';
            document.getElementById('uniontwodocthreecolumn').style.display = 'none';
            document.getElementById('unionthreedocument').style.display = 'none';
            document.getElementById('unionthreedoconecolumn').style.display = 'none';
            document.getElementById('unionthreedoctwocolumn').style.display = 'none';
            document.getElementById('unionthreedocthreecolumn').style.display = 'none';
        }
    }

    //select all rows of 2 specified columns
    document.getElementById('all_2').addEventListener('click', all_2Change);
    async function all_2Change(e){
        e.preventDefault();

        //clear some input variable
        document.getElementById('querycol_1').value = '';
        document.getElementById('querycol_2').value = '';
        document.getElementById('querycol_3').value = '';
        document.getElementById('colname_1').value = '';
        document.getElementById('colname_2').value = '';
        document.getElementById('colname_3').value = '';

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var hostnametaskStr = document.getElementById('hostnamequery').value;
        var hostporttaskStr = document.getElementById('hostportquery').value;
        var usernametaskStr = document.getElementById('usernamequery').value;
        var passwordnametaskStr = document.getElementById('passwordnamequery').value;
        var serveurHeaderStr = document.getElementById("serveurheader").innerText;
        var deviceStr = document.getElementById('device').innerText;
        //xxxxxxxxxxxxxxx
        var databasequeryStr = document.getElementById('databasequery').value;
        var templatequeryStr = document.getElementById('templatequery').value;
        var serverqueryStr = document.getElementById('serverquery').value;
        var documentqueryStr = document.getElementById('documentquery').value;
        //search in local / remote database documents
        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            const dbbase = new PouchDB(databasequeryStr, {skip_setup: true,});
            const remotebase = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +databasequeryStr,{ skip_setup: true });
            PouchDB.plugin(PouchFind);
            // Initialise a sync with the remote server
            function sync() {
                var opts = {live: true,retry: true,continuous: true,attachments: true,};
                dbbase.sync(remotebase, opts);
            }
            if (remotebase) {
                sync();
            }
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                        fields: [
                            "taskdatabase",
                            "taskname",
                            "taskserver",
                            "documentname",
                        ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: databasequeryStr,
                        taskname: templatequeryStr,
                        taskserver: serverqueryStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    taskkeyquery = [];
                    var valeur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                    /*initiate the autocomplete function on the "twocolumn_one" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("twocolumn_one"), column);
                    /*initiate the autocomplete function on the "twocolumn_two" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("twocolumn_two"), column);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };
        
        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            const dbbase = new PouchDB(serveurHeaderStr, {skip_setup: true,});
            PouchDB.plugin(PouchFind);
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                      fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "documentname",
                      ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: serveurHeaderStr,
                        taskname: templatequeryStr,
                        taskserver: serveurHeaderStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    taskkeyquery = [];
                    var valeur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                    /*initiate the autocomplete function on the "twocolumn_one" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("twocolumn_one"), column);
                    /*initiate the autocomplete function on the "twocolumn_two" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("twocolumn_two"), column);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'block';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }
    //confirm twocolumn click
    document.getElementById('confirmtwocolumn').addEventListener('click', confirmtwocolumnChange);
    async function confirmtwocolumnChange(e){
        e.preventDefault();

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var twocolumn_oneStr = document.getElementById('twocolumn_one').value;
        var twocolumn_twoStr = document.getElementById('twocolumn_two').value;
        var documentqueryStr = document.getElementById('documentquery').value; //document name
        var writequeryStr = document.getElementById('writequery');
        if(!twocolumn_oneStr){
            var pTextenocolumnone = "Please enter column 1 name";
            alertModal(elementOutId,elementDropId,pTextenocolumnone,buttonHandler);
        }else if(!twocolumn_twoStr){
            var pTextenocolumntwo = "Please enter column 2 name";
            alertModal(elementOutId,elementDropId,pTextenocolumntwo,buttonHandler);
        }else if(twocolumn_oneStr === twocolumn_twoStr){
            var pTextenocolumnequal = "The two columns must be different";
            alertModal(elementOutId,elementDropId,pTextenocolumnequal,buttonHandler);
        }else{
            writequeryStr.value = 'SELECT '+twocolumn_oneStr+','+twocolumn_twoStr+' FROM '+documentqueryStr+';';
            writequeryStr.readOnly = true;
            var querycol_1Str = document.getElementById('querycol_1');
            querycol_1Str.value = twocolumn_oneStr;
            var querycol_2Str = document.getElementById('querycol_2');
            querycol_2Str.value = twocolumn_twoStr;

            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('documentqueries').style.display = 'block';
            document.getElementById('querydocumentqueries').style.display = 'block';
            //xxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('connectqueries').style.display = 'none';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'none';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'block';
            document.getElementById('dynamictablequeries').style.display = 'none';
            //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
            document.getElementById('outputquery').style.display = 'block';
            document.getElementById('executerquery').style.display = 'block';
            //select components
            document.getElementById('selecttable').style.display = 'none';
            document.getElementById('selectonecolumn').style.display = 'none';
            document.getElementById('selecttwocolumn').style.display = 'none';
            document.getElementById('selectthreecolumn').style.display = 'none';
            //where components
            document.getElementById('wheretable').style.display = 'none';
            document.getElementById('whereonecolumn').style.display = 'none';
            document.getElementById('wheretwocolumn').style.display = 'none';
            document.getElementById('wherethreecolumn').style.display = 'none';
            //count components
            document.getElementById('counttable').style.display = 'none';
            document.getElementById('countonecolumn').style.display = 'none';
            document.getElementById('counttwocolumn').style.display = 'none';
            document.getElementById('countthreecolumn').style.display = 'none';
            //union components
            document.getElementById('uniontable').style.display = 'none';
            document.getElementById('uniontwodocument').style.display = 'none';
            document.getElementById('uniontwodoconecolumn').style.display = 'none';
            document.getElementById('uniontwodoctwocolumn').style.display = 'none';
            document.getElementById('uniontwodocthreecolumn').style.display = 'none';
            document.getElementById('unionthreedocument').style.display = 'none';
            document.getElementById('unionthreedoconecolumn').style.display = 'none';
            document.getElementById('unionthreedoctwocolumn').style.display = 'none';
            document.getElementById('unionthreedocthreecolumn').style.display = 'none';
        }
    }

    //select all rows of 3 specified columns
    document.getElementById('all_3').addEventListener('click', all_3Change);
    async function all_3Change(e){
        e.preventDefault();

        //clear some input variable
        document.getElementById('querycol_1').value = '';
        document.getElementById('querycol_2').value = '';
        document.getElementById('querycol_3').value = '';
        document.getElementById('colname_1').value = '';
        document.getElementById('colname_2').value = '';
        document.getElementById('colname_3').value = '';

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var hostnametaskStr = document.getElementById('hostnamequery').value;
        var hostporttaskStr = document.getElementById('hostportquery').value;
        var usernametaskStr = document.getElementById('usernamequery').value;
        var passwordnametaskStr = document.getElementById('passwordnamequery').value;
        var serveurHeaderStr = document.getElementById("serveurheader").innerText;
        var deviceStr = document.getElementById('device').innerText;
        //xxxxxxxxxxxxxxx
        var databasequeryStr = document.getElementById('databasequery').value;
        var templatequeryStr = document.getElementById('templatequery').value;
        var serverqueryStr = document.getElementById('serverquery').value;
        var documentqueryStr = document.getElementById('documentquery').value;
        //search in local / remote database documents
        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            const dbbase = new PouchDB(databasequeryStr, {skip_setup: true,});
            const remotebase = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +databasequeryStr,{ skip_setup: true });
            PouchDB.plugin(PouchFind);
            // Initialise a sync with the remote server
            function sync() {
                var opts = {live: true,retry: true,continuous: true,attachments: true,};
                dbbase.sync(remotebase, opts);
            }
            if (remotebase) {
                sync();
            }
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                        fields: [
                            "taskdatabase",
                            "taskname",
                            "taskserver",
                            "documentname",
                        ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: databasequeryStr,
                        taskname: templatequeryStr,
                        taskserver: serverqueryStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    taskkeyquery = [];
                    var valeur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                    /*initiate the autocomplete function on the "threecolumn_one" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("threecolumn_one"), column);
                    /*initiate the autocomplete function on the "threecolumn_two" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("threecolumn_two"), column);
                    /*initiate the autocomplete function on the "threecolumn_three" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("threecolumn_three"), column);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            const dbbase = new PouchDB(serveurHeaderStr, {skip_setup: true,});
            PouchDB.plugin(PouchFind);
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                      fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "documentname",
                      ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: serveurHeaderStr,
                        taskname: templatequeryStr,
                        taskserver: serveurHeaderStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    taskkeyquery = [];
                    var valeur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                    /*initiate the autocomplete function on the "threecolumn_one" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("threecolumn_one"), column);
                    /*initiate the autocomplete function on the "threecolumn_two" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("threecolumn_two"), column);
                    /*initiate the autocomplete function on the "threecolumn_three" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("threecolumn_three"), column);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };
    
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'block';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }
    //confirm threecolumn click
    document.getElementById('confirmthreecolumn').addEventListener('click', confirmthreecolumnChange);
    async function confirmthreecolumnChange(e){
        e.preventDefault();

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var threecolumn_oneStr = document.getElementById('threecolumn_one').value;
        var threecolumn_twoStr = document.getElementById('threecolumn_two').value;
        var threecolumn_threeStr = document.getElementById('threecolumn_three').value;
        var documentqueryStr = document.getElementById('documentquery').value; //document name
        var writequeryStr = document.getElementById('writequery');
        if(!threecolumn_oneStr){
            var pTextenocolumnone = "Please enter column 1 name";
            alertModal(elementOutId,elementDropId,pTextenocolumnone,buttonHandler);
        }else if(!threecolumn_twoStr){
            var pTextenocolumntwo = "Please enter column 2 name";
            alertModal(elementOutId,elementDropId,pTextenocolumntwo,buttonHandler);
        }else if(!threecolumn_threeStr){
            var pTextenocolumnthree = "Please enter column 3 name";
            alertModal(elementOutId,elementDropId,pTextenocolumnthree,buttonHandler);
        }else if(threecolumn_oneStr === threecolumn_twoStr || threecolumn_oneStr === threecolumn_threeStr || threecolumn_twoStr === threecolumn_threeStr){
            var pTextenocolumnequal = "All three columns must be different";
            alertModal(elementOutId,elementDropId,pTextenocolumnequal,buttonHandler);
        }else{
            writequeryStr.value = 'SELECT '+threecolumn_oneStr+','+threecolumn_twoStr+','+threecolumn_threeStr+' FROM '+documentqueryStr+';';
            writequeryStr.readOnly = true;
            var querycol_1Str = document.getElementById('querycol_1');
            querycol_1Str.value = threecolumn_oneStr;
            var querycol_2Str = document.getElementById('querycol_2');
            querycol_2Str.value = threecolumn_twoStr;
            var querycol_3Str = document.getElementById('querycol_3');
            querycol_3Str.value = threecolumn_threeStr;

            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('documentqueries').style.display = 'block';
            document.getElementById('querydocumentqueries').style.display = 'block';
            //xxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('connectqueries').style.display = 'none';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'none';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'block';
            document.getElementById('dynamictablequeries').style.display = 'none';
            //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
            document.getElementById('outputquery').style.display = 'block';
            document.getElementById('executerquery').style.display = 'block';
            //select components
            document.getElementById('selecttable').style.display = 'none';
            document.getElementById('selectonecolumn').style.display = 'none';
            document.getElementById('selecttwocolumn').style.display = 'none';
            document.getElementById('selectthreecolumn').style.display = 'none';
            //where components
            document.getElementById('wheretable').style.display = 'none';
            document.getElementById('whereonecolumn').style.display = 'none';
            document.getElementById('wheretwocolumn').style.display = 'none';
            document.getElementById('wherethreecolumn').style.display = 'none';
            //count components
            document.getElementById('counttable').style.display = 'none';
            document.getElementById('countonecolumn').style.display = 'none';
            document.getElementById('counttwocolumn').style.display = 'none';
            document.getElementById('countthreecolumn').style.display = 'none';
            //union components
            document.getElementById('uniontable').style.display = 'none';
            document.getElementById('uniontwodocument').style.display = 'none';
            document.getElementById('uniontwodoconecolumn').style.display = 'none';
            document.getElementById('uniontwodoctwocolumn').style.display = 'none';
            document.getElementById('uniontwodocthreecolumn').style.display = 'none';
            document.getElementById('unionthreedocument').style.display = 'none';
            document.getElementById('unionthreedoconecolumn').style.display = 'none';
            document.getElementById('unionthreedoctwocolumn').style.display = 'none';
            document.getElementById('unionthreedocthreecolumn').style.display = 'none';
        }
    }

    //. select * from table where
    document.getElementById('wherequery').addEventListener('click', wherequeryChange);
    async function wherequeryChange(e){
        e.preventDefault();

        //xxxxxxxxxxxxxxx selected query name xxxxxxxxxx
        var selectedtqueryStr = document.getElementById('selectedtquery');
        selectedtqueryStr.value = 'where';

        //clear some input variable
        document.getElementById('querycol_1').value = '';
        document.getElementById('querycol_2').value = '';
        document.getElementById('querycol_3').value = '';
        document.getElementById('colname_1').value = '';
        document.getElementById('colname_2').value = '';
        document.getElementById('colname_3').value = '';

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'block';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }
    //--------- event listener for where query --------------- 
    //where 1 specified column
    document.getElementById('where_1').addEventListener('click', where_1Change);
    async function where_1Change(e){
        e.preventDefault();

        //clear some input variable
        document.getElementById('querycol_1').value = '';
        document.getElementById('querycol_2').value = '';
        document.getElementById('querycol_3').value = '';
        document.getElementById('colname_1').value = '';
        document.getElementById('colname_2').value = '';
        document.getElementById('colname_3').value = '';

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var hostnametaskStr = document.getElementById('hostnamequery').value;
        var hostporttaskStr = document.getElementById('hostportquery').value;
        var usernametaskStr = document.getElementById('usernamequery').value;
        var passwordnametaskStr = document.getElementById('passwordnamequery').value;
        var serveurHeaderStr = document.getElementById("serveurheader").innerText;
        var deviceStr = document.getElementById('device').innerText;
        //xxxxxxxxxxxxxxx
        var databasequeryStr = document.getElementById('databasequery').value;
        var templatequeryStr = document.getElementById('templatequery').value;
        var serverqueryStr = document.getElementById('serverquery').value;
        var documentqueryStr = document.getElementById('documentquery').value;
        //search in local / remote database documents
        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            const dbbase = new PouchDB(databasequeryStr, {skip_setup: true,});
            const remotebase = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +databasequeryStr,{ skip_setup: true });
            PouchDB.plugin(PouchFind);
            // Initialise a sync with the remote server
            function sync() {
                var opts = {live: true,retry: true,continuous: true,attachments: true,};
                dbbase.sync(remotebase, opts);
            }
            if (remotebase) {
                sync();
            }
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                        fields: [
                            "taskdatabase",
                            "taskname",
                            "taskserver",
                            "documentname",
                        ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: databasequeryStr,
                        taskname: templatequeryStr,
                        taskserver: serverqueryStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    taskkeyquery = [];
                    var valeur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("where_one"), column);
                    //array of logical operators
                    var operateur = ["=","<",">"];
                    autocomplete(document.getElementById("operator_one"), operateur);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };
        
        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            const dbbase = new PouchDB(serveurHeaderStr, {skip_setup: true,});
            PouchDB.plugin(PouchFind);
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                      fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "documentname",
                      ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: serveurHeaderStr,
                        taskname: templatequeryStr,
                        taskserver: serveurHeaderStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    taskkeyquery = [];
                    var valeur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("where_one"), column);
                    //array of logical operators
                    var operateur = ["=","<",">"];
                    autocomplete(document.getElementById("operator_one"), operateur);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'block';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }
    //confirm where one column click
    document.getElementById('confirmwhereonecolumn').addEventListener('click', confirmwhereonecolumnChange);
    async function confirmwhereonecolumnChange(e){
        e.preventDefault();

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var where_oneStr = document.getElementById('where_one').value;
        var operator_oneStr = document.getElementById('operator_one').value;
        var value_oneStr = document.getElementById('value_one').value;
        var documentqueryStr = document.getElementById('documentquery').value; //document name
        var writequeryStr = document.getElementById('writequery');
        if(!where_oneStr){
            var pTextenocolumn = "Please enter column name";
            alertModal(elementOutId,elementDropId,pTextenocolumn,buttonHandler);
        }else if(!operator_oneStr){
            var pTextenooperator = "Please enter operator";
            alertModal(elementOutId,elementDropId,pTextenooperator,buttonHandler);
        }else if(!value_oneStr){
            var pTextenovalue = "Please enter value";
            alertModal(elementOutId,elementDropId,pTextenovalue,buttonHandler);
        }else{
            writequeryStr.value = 'SELECT * FROM '+documentqueryStr+' WHERE '+where_oneStr+' '+operator_oneStr+' '+value_oneStr+';';
            writequeryStr.readOnly = true;
            var querycol_1Str = document.getElementById('querycol_1');
            querycol_1Str.value = where_oneStr;

            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('documentqueries').style.display = 'block';
            document.getElementById('querydocumentqueries').style.display = 'block';
            //xxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('connectqueries').style.display = 'none';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'none';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'block';
            document.getElementById('dynamictablequeries').style.display = 'none';
            //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
            document.getElementById('outputquery').style.display = 'block';
            document.getElementById('executerquery').style.display = 'block';
            //select components
            document.getElementById('selecttable').style.display = 'none';
            document.getElementById('selectonecolumn').style.display = 'none';
            document.getElementById('selecttwocolumn').style.display = 'none';
            document.getElementById('selectthreecolumn').style.display = 'none';
            //where components
            document.getElementById('wheretable').style.display = 'none';
            document.getElementById('whereonecolumn').style.display = 'none';
            document.getElementById('wheretwocolumn').style.display = 'none';
            document.getElementById('wherethreecolumn').style.display = 'none';
            //count components
            document.getElementById('counttable').style.display = 'none';
            document.getElementById('countonecolumn').style.display = 'none';
            document.getElementById('counttwocolumn').style.display = 'none';
            document.getElementById('countthreecolumn').style.display = 'none';
            //union components
            document.getElementById('uniontable').style.display = 'none';
            document.getElementById('uniontwodocument').style.display = 'none';
            document.getElementById('uniontwodoconecolumn').style.display = 'none';
            document.getElementById('uniontwodoctwocolumn').style.display = 'none';
            document.getElementById('uniontwodocthreecolumn').style.display = 'none';
            document.getElementById('unionthreedocument').style.display = 'none';
            document.getElementById('unionthreedoconecolumn').style.display = 'none';
            document.getElementById('unionthreedoctwocolumn').style.display = 'none';
            document.getElementById('unionthreedocthreecolumn').style.display = 'none';
        }
    }

    //where 2 specified column
    document.getElementById('where_2').addEventListener('click', where_2Change);
    async function where_2Change(e){
        e.preventDefault();

        //clear some input variable
        document.getElementById('querycol_1').value = '';
        document.getElementById('querycol_2').value = '';
        document.getElementById('querycol_3').value = '';
        document.getElementById('colname_1').value = '';
        document.getElementById('colname_2').value = '';
        document.getElementById('colname_3').value = '';

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var hostnametaskStr = document.getElementById('hostnamequery').value;
        var hostporttaskStr = document.getElementById('hostportquery').value;
        var usernametaskStr = document.getElementById('usernamequery').value;
        var passwordnametaskStr = document.getElementById('passwordnamequery').value;
        var serveurHeaderStr = document.getElementById("serveurheader").innerText;
        var deviceStr = document.getElementById('device').innerText;
        //xxxxxxxxxxxxxxx
        var databasequeryStr = document.getElementById('databasequery').value;
        var templatequeryStr = document.getElementById('templatequery').value;
        var serverqueryStr = document.getElementById('serverquery').value;
        var documentqueryStr = document.getElementById('documentquery').value;
        //search in local / remote database documents
        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            const dbbase = new PouchDB(databasequeryStr, {skip_setup: true,});
            const remotebase = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +databasequeryStr,{ skip_setup: true });
            PouchDB.plugin(PouchFind);
            // Initialise a sync with the remote server
            function sync() {
                var opts = {live: true,retry: true,continuous: true,attachments: true,};
                dbbase.sync(remotebase, opts);
            }
            if (remotebase) {
                sync();
            }
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                        fields: [
                            "taskdatabase",
                            "taskname",
                            "taskserver",
                            "documentname",
                        ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: databasequeryStr,
                        taskname: templatequeryStr,
                        taskserver: serverqueryStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    taskkeyquery = [];
                    var valeur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("twowhere_one"), column);
                    //array of logical operators
                    var operateur = ["=","<",">"];
                    autocomplete(document.getElementById("twooperator_one"), operateur);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("twowhere_two"), column);
                    autocomplete(document.getElementById("twooperator_two"), operateur);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };
        
        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            const dbbase = new PouchDB(serveurHeaderStr, {skip_setup: true,});
            PouchDB.plugin(PouchFind);
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                      fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "documentname",
                      ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: serveurHeaderStr,
                        taskname: templatequeryStr,
                        taskserver: serveurHeaderStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    taskkeyquery = [];
                    var valeur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("twowhere_one"), column);
                    //array of logical operators
                    var operateur = ["=","<",">"];
                    autocomplete(document.getElementById("twooperator_one"), operateur);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("twowhere_two"), column);
                    autocomplete(document.getElementById("twooperator_two"), operateur);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'block';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }
    //confirm where two column click
    document.getElementById('confirmwheretwocolumn').addEventListener('click', confirmwheretwocolumnChange);
    async function confirmwheretwocolumnChange(e){
        e.preventDefault();

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var twowhere_oneStr = document.getElementById('twowhere_one').value;
        var twooperator_oneStr = document.getElementById('twooperator_one').value;
        var twovalue_oneStr = document.getElementById('twovalue_one').value;
        var twowhere_twoStr = document.getElementById('twowhere_two').value;
        var twooperator_twoStr = document.getElementById('twooperator_two').value;
        var twovalue_twoStr = document.getElementById('twovalue_two').value;
        var documentqueryStr = document.getElementById('documentquery').value; //document name
        var writequeryStr = document.getElementById('writequery');
        if(!twowhere_oneStr){
            var pTextenocolumn = "Please enter column 1 name";
            alertModal(elementOutId,elementDropId,pTextenocolumn,buttonHandler);
        }else if(!twooperator_oneStr){
            var pTextenooperator = "Please enter operator 1";
            alertModal(elementOutId,elementDropId,pTextenooperator,buttonHandler);
        }else if(!twovalue_oneStr){
            var pTextenovalue = "Please enter value 1";
            alertModal(elementOutId,elementDropId,pTextenovalue,buttonHandler);
        }else if(!twowhere_twoStr){
            var pTextenocolumn_2 = "Please enter column 2 name";
            alertModal(elementOutId,elementDropId,pTextenocolumn_2,buttonHandler);
        }else if(!twooperator_twoStr){
            var pTextenooperator_2 = "Please enter operator 2";
            alertModal(elementOutId,elementDropId,pTextenooperator_2,buttonHandler);
        }else if(!twovalue_twoStr){
            var pTextenovalue_2 = "Please enter value 2";
            alertModal(elementOutId,elementDropId,pTextenovalue_2,buttonHandler);
        }else{
            writequeryStr.value = 'SELECT * FROM '+documentqueryStr+' WHERE '+twowhere_oneStr+' '+twooperator_oneStr+' '+twovalue_oneStr+
            ' AND '+twowhere_twoStr+' '+twooperator_twoStr+' '+twovalue_twoStr+';';
            writequeryStr.readOnly = true;
            var querycol_1Str = document.getElementById('querycol_1');
            querycol_1Str.value = twowhere_oneStr;
            var querycol_2Str = document.getElementById('querycol_2');
            querycol_2Str.value = twowhere_twoStr;

            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('documentqueries').style.display = 'block';
            document.getElementById('querydocumentqueries').style.display = 'block';
            //xxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('connectqueries').style.display = 'none';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'none';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'block';
            document.getElementById('dynamictablequeries').style.display = 'none';
            //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
            document.getElementById('outputquery').style.display = 'block';
            document.getElementById('executerquery').style.display = 'block';
            //select components
            document.getElementById('selecttable').style.display = 'none';
            document.getElementById('selectonecolumn').style.display = 'none';
            document.getElementById('selecttwocolumn').style.display = 'none';
            document.getElementById('selectthreecolumn').style.display = 'none';
            //where components
            document.getElementById('wheretable').style.display = 'none';
            document.getElementById('whereonecolumn').style.display = 'none';
            document.getElementById('wheretwocolumn').style.display = 'none';
            document.getElementById('wherethreecolumn').style.display = 'none';
            //count components
            document.getElementById('counttable').style.display = 'none';
            document.getElementById('countonecolumn').style.display = 'none';
            document.getElementById('counttwocolumn').style.display = 'none';
            document.getElementById('countthreecolumn').style.display = 'none';
            //union components
            document.getElementById('uniontable').style.display = 'none';
            document.getElementById('uniontwodocument').style.display = 'none';
            document.getElementById('uniontwodoconecolumn').style.display = 'none';
            document.getElementById('uniontwodoctwocolumn').style.display = 'none';
            document.getElementById('uniontwodocthreecolumn').style.display = 'none';
            document.getElementById('unionthreedocument').style.display = 'none';
            document.getElementById('unionthreedoconecolumn').style.display = 'none';
            document.getElementById('unionthreedoctwocolumn').style.display = 'none';
            document.getElementById('unionthreedocthreecolumn').style.display = 'none';
        }
    }

    //where 3 specified column
    document.getElementById('where_3').addEventListener('click', where_3Change);
    async function where_3Change(e){
        e.preventDefault();

        //clear some input variable
        document.getElementById('querycol_1').value = '';
        document.getElementById('querycol_2').value = '';
        document.getElementById('querycol_3').value = '';
        document.getElementById('colname_1').value = '';
        document.getElementById('colname_2').value = '';
        document.getElementById('colname_3').value = '';

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var hostnametaskStr = document.getElementById('hostnamequery').value;
        var hostporttaskStr = document.getElementById('hostportquery').value;
        var usernametaskStr = document.getElementById('usernamequery').value;
        var passwordnametaskStr = document.getElementById('passwordnamequery').value;
        var serveurHeaderStr = document.getElementById("serveurheader").innerText;
        var deviceStr = document.getElementById('device').innerText;
        //xxxxxxxxxxxxxxx
        var databasequeryStr = document.getElementById('databasequery').value;
        var templatequeryStr = document.getElementById('templatequery').value;
        var serverqueryStr = document.getElementById('serverquery').value;
        var documentqueryStr = document.getElementById('documentquery').value;
        //search in local / remote database documents
        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            const dbbase = new PouchDB(databasequeryStr, {skip_setup: true,});
            const remotebase = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +databasequeryStr,{ skip_setup: true });
            PouchDB.plugin(PouchFind);
            // Initialise a sync with the remote server
            function sync() {
                var opts = {live: true,retry: true,continuous: true,attachments: true,};
                dbbase.sync(remotebase, opts);
            }
            if (remotebase) {
                sync();
            }
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                        fields: [
                            "taskdatabase",
                            "taskname",
                            "taskserver",
                            "documentname",
                        ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: databasequeryStr,
                        taskname: templatequeryStr,
                        taskserver: serverqueryStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    taskkeyquery = [];
                    var valeur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("threewhere_one"), column);
                    //array of logical operators
                    var operateur = ["=","<",">"];
                    autocomplete(document.getElementById("threeoperator_one"), operateur);
                    /*initiate the autocomplete function on the "twocolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("threewhere_two"), column);
                    autocomplete(document.getElementById("threeoperator_two"), operateur);
                    /*initiate the autocomplete function on the "threecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("threewhere_three"), column);
                    autocomplete(document.getElementById("threeoperator_three"), operateur);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            const dbbase = new PouchDB(serveurHeaderStr, {skip_setup: true,});
            PouchDB.plugin(PouchFind);
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                      fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "documentname",
                      ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: serveurHeaderStr,
                        taskname: templatequeryStr,
                        taskserver: serveurHeaderStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    taskkeyquery = [];
                    var valeur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("threewhere_one"), column);
                    //array of logical operators
                    var operateur = ["=","<",">"];
                    autocomplete(document.getElementById("threeoperator_one"), operateur);
                    /*initiate the autocomplete function on the "twocolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("threewhere_two"), column);
                    autocomplete(document.getElementById("threeoperator_two"), operateur);
                    /*initiate the autocomplete function on the "threecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("threewhere_three"), column);
                    autocomplete(document.getElementById("threeoperator_three"), operateur);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };
        

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'block';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }
    //confirm where three column click
    document.getElementById('confirmwherethreecolumn').addEventListener('click', confirmwherethreecolumnChange);
    async function confirmwherethreecolumnChange(e){
        e.preventDefault();

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var threewhere_oneStr = document.getElementById('threewhere_one').value;
        var threeoperator_oneStr = document.getElementById('threeoperator_one').value;
        var threevalue_oneStr = document.getElementById('threevalue_one').value;
        var threewhere_twoStr = document.getElementById('threewhere_two').value;
        var threeoperator_twoStr = document.getElementById('threeoperator_two').value;
        var threevalue_twoStr = document.getElementById('threevalue_two').value;
        var threewhere_threeStr = document.getElementById('threewhere_three').value;
        var threeoperator_threeStr = document.getElementById('threeoperator_three').value;
        var threevalue_threeStr = document.getElementById('threevalue_three').value;
        var documentqueryStr = document.getElementById('documentquery').value; //document name
        var writequeryStr = document.getElementById('writequery');
        if(!threewhere_oneStr){
            var pTextenocolumn = "Please enter column 1 name";
            alertModal(elementOutId,elementDropId,pTextenocolumn,buttonHandler);
        }else if(!threeoperator_oneStr){
            var pTextenooperator = "Please enter operator 1";
            alertModal(elementOutId,elementDropId,pTextenooperator,buttonHandler);
        }else if(!threevalue_oneStr){
            var pTextenovalue = "Please enter value 1";
            alertModal(elementOutId,elementDropId,pTextenovalue,buttonHandler);
        }else if(!threewhere_twoStr){
            var pTextenocolumn_2 = "Please enter column 2 name";
            alertModal(elementOutId,elementDropId,pTextenocolumn_2,buttonHandler);
        }else if(!threeoperator_twoStr){
            var pTextenooperator_2 = "Please enter operator 2";
            alertModal(elementOutId,elementDropId,pTextenooperator_2,buttonHandler);
        }else if(!threevalue_twoStr){
            var pTextenovalue_2 = "Please enter value 2";
            alertModal(elementOutId,elementDropId,pTextenovalue_2,buttonHandler);
        }else if(!threewhere_threeStr){
            var pTextenocolumn_3 = "Please enter column 3 name";
            alertModal(elementOutId,elementDropId,pTextenocolumn_3,buttonHandler);
        }else if(!threeoperator_threeStr){
            var pTextenooperator_3 = "Please enter operator 3";
            alertModal(elementOutId,elementDropId,pTextenooperator_3,buttonHandler);
        }else if(!threevalue_threeStr){
            var pTextenovalue_3 = "Please enter value 3";
            alertModal(elementOutId,elementDropId,pTextenovalue_3,buttonHandler);
        }else{
            writequeryStr.value = 'SELECT * FROM '+documentqueryStr+' WHERE '+threewhere_oneStr+' '+threeoperator_oneStr+' '+threevalue_oneStr+
            ' AND '+threewhere_twoStr+' '+threeoperator_twoStr+' '+threevalue_twoStr+
            ' AND '+threewhere_threeStr+' '+threeoperator_threeStr+' '+threevalue_threeStr+';';
            writequeryStr.readOnly = true;
            var querycol_1Str = document.getElementById('querycol_1');
            querycol_1Str.value = threewhere_oneStr;
            var querycol_2Str = document.getElementById('querycol_2');
            querycol_2Str.value = threewhere_twoStr;
            var querycol_3Str = document.getElementById('querycol_3');
            querycol_3Str.value = threewhere_threeStr;

            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('documentqueries').style.display = 'block';
            document.getElementById('querydocumentqueries').style.display = 'block';
            //xxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('connectqueries').style.display = 'none';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'none';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'block';
            document.getElementById('dynamictablequeries').style.display = 'none';
            //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
            document.getElementById('outputquery').style.display = 'block';
            document.getElementById('executerquery').style.display = 'block';
            //select components
            document.getElementById('selecttable').style.display = 'none';
            document.getElementById('selectonecolumn').style.display = 'none';
            document.getElementById('selecttwocolumn').style.display = 'none';
            document.getElementById('selectthreecolumn').style.display = 'none';
            //where components
            document.getElementById('wheretable').style.display = 'none';
            document.getElementById('whereonecolumn').style.display = 'none';
            document.getElementById('wheretwocolumn').style.display = 'none';
            document.getElementById('wherethreecolumn').style.display = 'none';
            //count components
            document.getElementById('counttable').style.display = 'none';
            document.getElementById('countonecolumn').style.display = 'none';
            document.getElementById('counttwocolumn').style.display = 'none';
            document.getElementById('countthreecolumn').style.display = 'none';
            //union components
            document.getElementById('uniontable').style.display = 'none';
            document.getElementById('uniontwodocument').style.display = 'none';
            document.getElementById('uniontwodoconecolumn').style.display = 'none';
            document.getElementById('uniontwodoctwocolumn').style.display = 'none';
            document.getElementById('uniontwodocthreecolumn').style.display = 'none';
            document.getElementById('unionthreedocument').style.display = 'none';
            document.getElementById('unionthreedoconecolumn').style.display = 'none';
            document.getElementById('unionthreedoctwocolumn').style.display = 'none';
            document.getElementById('unionthreedocthreecolumn').style.display = 'none';
        }
    }

    //--------- event listener for count query ---------------
    document.getElementById('countquery').addEventListener('click', countqueryChange);
    async function countqueryChange(e){
        e.preventDefault();


        //xxxxxxxxxxxxxxx selected query name xxxxxxxxxx
        var selectedtqueryStr = document.getElementById('selectedtquery');
        selectedtqueryStr.value = 'count';

        //clear some input variable
        document.getElementById('querycol_1').value = '';
        document.getElementById('querycol_2').value = '';
        document.getElementById('querycol_3').value = '';
        document.getElementById('colname_1').value = '';
        document.getElementById('colname_2').value = '';
        document.getElementById('colname_3').value = '';

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'block';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }
    //Count all rows
    document.getElementById('count_all').addEventListener('click', count_allChange);
    async function count_allChange(e){
        e.preventDefault();

        //clear some input variable
        document.getElementById('querycol_1').value = '';
        document.getElementById('querycol_2').value = '';
        document.getElementById('querycol_3').value = '';
        document.getElementById('colname_1').value = '';
        document.getElementById('colname_2').value = '';
        document.getElementById('colname_3').value = '';

        var documentqueryStr = document.getElementById('documentquery').value; //document name
        var writequeryStr = document.getElementById('writequery');
        writequeryStr.value = 'SELECT COUNT(*) FROM '+documentqueryStr+';';
        writequeryStr.readOnly = true;

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'block';
        document.getElementById('executerquery').style.display = 'block';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    } 

    //Count Rows where 1 column
    document.getElementById('count_1').addEventListener('click', count_1Change);
    async function count_1Change(e){
        e.preventDefault();

        //clear some input variable
        document.getElementById('querycol_1').value = '';
        document.getElementById('querycol_2').value = '';
        document.getElementById('querycol_3').value = '';
        document.getElementById('colname_1').value = '';
        document.getElementById('colname_2').value = '';
        document.getElementById('colname_3').value = '';

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var hostnametaskStr = document.getElementById('hostnamequery').value;
        var hostporttaskStr = document.getElementById('hostportquery').value;
        var usernametaskStr = document.getElementById('usernamequery').value;
        var passwordnametaskStr = document.getElementById('passwordnamequery').value;
        var serveurHeaderStr = document.getElementById("serveurheader").innerText;
        var deviceStr = document.getElementById('device').innerText;
        //xxxxxxxxxxxxxxx
        var databasequeryStr = document.getElementById('databasequery').value;
        var templatequeryStr = document.getElementById('templatequery').value;
        var serverqueryStr = document.getElementById('serverquery').value;
        var documentqueryStr = document.getElementById('documentquery').value;
        //search in local / remote database documents
        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            const dbbase = new PouchDB(databasequeryStr, {skip_setup: true,});
            const remotebase = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +databasequeryStr,{ skip_setup: true });
            PouchDB.plugin(PouchFind);
            // Initialise a sync with the remote server
            function sync() {
                var opts = {live: true,retry: true,continuous: true,attachments: true,};
                dbbase.sync(remotebase, opts);
            }
            if (remotebase) {
                sync();
            }
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                        fields: [
                            "taskdatabase",
                            "taskname",
                            "taskserver",
                            "documentname",
                        ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: databasequeryStr,
                        taskname: templatequeryStr,
                        taskserver: serverqueryStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    taskkeyquery = [];
                    var valeur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("count_one"), column);
                    //array of logical operators
                    var operateur = ["=","<",">"];
                    autocomplete(document.getElementById("countoperator_one"), operateur);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };
        
        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            const dbbase = new PouchDB(serveurHeaderStr, {skip_setup: true,});
            PouchDB.plugin(PouchFind);
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                      fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "documentname",
                      ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: serveurHeaderStr,
                        taskname: templatequeryStr,
                        taskserver: serveurHeaderStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    taskkeyquery = [];
                    var valeur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("count_one"), column);
                    //array of logical operators
                    var operateur = ["=","<",">"];
                    autocomplete(document.getElementById("countoperator_one"), operateur);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'block';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }
    //confirm where one column click
    document.getElementById('confirmcountonecolumn').addEventListener('click', confirmcountonecolumnChange);
    async function confirmcountonecolumnChange(e){
        e.preventDefault();

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var count_oneStr = document.getElementById('count_one').value;
        var countoperator_oneStr = document.getElementById('countoperator_one').value;
        var countvalue_oneStr = document.getElementById('countvalue_one').value;
        var documentqueryStr = document.getElementById('documentquery').value; //document name
        var writequeryStr = document.getElementById('writequery');
        if(!count_oneStr){
            var pTextenocolumn = "Please enter column name";
            alertModal(elementOutId,elementDropId,pTextenocolumn,buttonHandler);
        }else if(!countoperator_oneStr){
            var pTextenooperator = "Please enter operator";
            alertModal(elementOutId,elementDropId,pTextenooperator,buttonHandler);
        }else if(!countvalue_oneStr){
            var pTextenovalue = "Please enter value";
            alertModal(elementOutId,elementDropId,pTextenovalue,buttonHandler);
        }else{
            writequeryStr.value = 'SELECT COUNT(*) FROM '+documentqueryStr+' WHERE '+count_oneStr+' '+countoperator_oneStr+' '+countvalue_oneStr+';';
            writequeryStr.readOnly = true;
            var querycol_1Str = document.getElementById('querycol_1');
            querycol_1Str.value = count_oneStr;

            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('documentqueries').style.display = 'block';
            document.getElementById('querydocumentqueries').style.display = 'block';
            //xxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('connectqueries').style.display = 'none';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'none';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'block';
            document.getElementById('dynamictablequeries').style.display = 'none';
            //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
            document.getElementById('outputquery').style.display = 'block';
            document.getElementById('executerquery').style.display = 'block';
            //select components
            document.getElementById('selecttable').style.display = 'none';
            document.getElementById('selectonecolumn').style.display = 'none';
            document.getElementById('selecttwocolumn').style.display = 'none';
            document.getElementById('selectthreecolumn').style.display = 'none';
            //where components
            document.getElementById('wheretable').style.display = 'none';
            document.getElementById('whereonecolumn').style.display = 'none';
            document.getElementById('wheretwocolumn').style.display = 'none';
            document.getElementById('wherethreecolumn').style.display = 'none';
            //count components
            document.getElementById('counttable').style.display = 'none';
            document.getElementById('countonecolumn').style.display = 'none';
            document.getElementById('counttwocolumn').style.display = 'none';
            document.getElementById('countthreecolumn').style.display = 'none';
            //union components
            document.getElementById('uniontable').style.display = 'none';
            document.getElementById('uniontwodocument').style.display = 'none';
            document.getElementById('uniontwodoconecolumn').style.display = 'none';
            document.getElementById('uniontwodoctwocolumn').style.display = 'none';
            document.getElementById('uniontwodocthreecolumn').style.display = 'none';
            document.getElementById('unionthreedocument').style.display = 'none';
            document.getElementById('unionthreedoconecolumn').style.display = 'none';
            document.getElementById('unionthreedoctwocolumn').style.display = 'none';
            document.getElementById('unionthreedocthreecolumn').style.display = 'none';
        }
    }

    //where 2 specified column
    document.getElementById('count_2').addEventListener('click', count_2Change);
    async function count_2Change(e){
        e.preventDefault();

        //clear some input variable
        document.getElementById('querycol_1').value = '';
        document.getElementById('querycol_2').value = '';
        document.getElementById('querycol_3').value = '';
        document.getElementById('colname_1').value = '';
        document.getElementById('colname_2').value = '';
        document.getElementById('colname_3').value = '';

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var hostnametaskStr = document.getElementById('hostnamequery').value;
        var hostporttaskStr = document.getElementById('hostportquery').value;
        var usernametaskStr = document.getElementById('usernamequery').value;
        var passwordnametaskStr = document.getElementById('passwordnamequery').value;
        var serveurHeaderStr = document.getElementById("serveurheader").innerText;
        var deviceStr = document.getElementById('device').innerText;
        //xxxxxxxxxxxxxxx
        var databasequeryStr = document.getElementById('databasequery').value;
        var templatequeryStr = document.getElementById('templatequery').value;
        var serverqueryStr = document.getElementById('serverquery').value;
        var documentqueryStr = document.getElementById('documentquery').value;
        //search in local / remote database documents
        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            const dbbase = new PouchDB(databasequeryStr, {skip_setup: true,});
            const remotebase = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +databasequeryStr,{ skip_setup: true });
            PouchDB.plugin(PouchFind);
            // Initialise a sync with the remote server
            function sync() {
                var opts = {live: true,retry: true,continuous: true,attachments: true,};
                dbbase.sync(remotebase, opts);
            }
            if (remotebase) {
                sync();
            }
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                        fields: [
                            "taskdatabase",
                            "taskname",
                            "taskserver",
                            "documentname",
                        ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: databasequeryStr,
                        taskname: templatequeryStr,
                        taskserver: serverqueryStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    taskkeyquery = [];
                    var valeur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("twocount_one"), column);
                    //array of logical operators
                    var operateur = ["=","<",">"];
                    autocomplete(document.getElementById("twocountoperator_one"), operateur);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("twocount_two"), column);
                    autocomplete(document.getElementById("twocountoperator_two"), operateur);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };
        
        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            const dbbase = new PouchDB(serveurHeaderStr, {skip_setup: true,});
            PouchDB.plugin(PouchFind);
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                      fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "documentname",
                      ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: serveurHeaderStr,
                        taskname: templatequeryStr,
                        taskserver: serveurHeaderStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    taskkeyquery = [];
                    var valeur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("twocount_one"), column);
                    //array of logical operators
                    var operateur = ["=","<",">"];
                    autocomplete(document.getElementById("twocountoperator_one"), operateur);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("twocount_two"), column);
                    autocomplete(document.getElementById("twocountoperator_two"), operateur);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'block';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }
    //confirm where two column click
    document.getElementById('confirmcounttwocolumn').addEventListener('click', confirmcounttwocolumnChange);
    async function confirmcounttwocolumnChange(e){
        e.preventDefault();

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var twocount_oneStr = document.getElementById('twocount_one').value;
        var twocountoperator_oneStr = document.getElementById('twocountoperator_one').value;
        var twocountvalue_oneStr = document.getElementById('twocountvalue_one').value;
        var twocount_twoStr = document.getElementById('twocount_two').value;
        var twocountoperator_twoStr = document.getElementById('twocountoperator_two').value;
        var twocountvalue_twoStr = document.getElementById('twocountvalue_two').value;
        var documentqueryStr = document.getElementById('documentquery').value; //document name
        var writequeryStr = document.getElementById('writequery');
        if(!twocount_oneStr){
            var pTextenocolumn = "Please enter column 1 name";
            alertModal(elementOutId,elementDropId,pTextenocolumn,buttonHandler);
        }else if(!twocountoperator_oneStr){
            var pTextenooperator = "Please enter operator 1";
            alertModal(elementOutId,elementDropId,pTextenooperator,buttonHandler);
        }else if(!twocountvalue_oneStr){
            var pTextenovalue = "Please enter value 1";
            alertModal(elementOutId,elementDropId,pTextenovalue,buttonHandler);
        }else if(!twocount_twoStr){
            var pTextenocolumn_2 = "Please enter column 2 name";
            alertModal(elementOutId,elementDropId,pTextenocolumn_2,buttonHandler);
        }else if(!twocountoperator_twoStr){
            var pTextenooperator_2 = "Please enter operator 2";
            alertModal(elementOutId,elementDropId,pTextenooperator_2,buttonHandler);
        }else if(!twocountvalue_twoStr){
            var pTextenovalue_2 = "Please enter value 2";
            alertModal(elementOutId,elementDropId,pTextenovalue_2,buttonHandler);
        }else{
            writequeryStr.value = 'SELECT COUNT(*) FROM '+documentqueryStr+' WHERE '+twocount_oneStr+' '+twocountoperator_oneStr+' '+twocountvalue_oneStr+
            ' AND '+twocount_twoStr+' '+twocountoperator_twoStr+' '+twocountvalue_twoStr+';';
            writequeryStr.readOnly = true;
            var querycol_1Str = document.getElementById('querycol_1');
            querycol_1Str.value = twocount_oneStr;
            var querycol_2Str = document.getElementById('querycol_2');
            querycol_2Str.value = twocount_twoStr;

            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('documentqueries').style.display = 'block';
            document.getElementById('querydocumentqueries').style.display = 'block';
            //xxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('connectqueries').style.display = 'none';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'none';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'block';
            document.getElementById('dynamictablequeries').style.display = 'none';
            //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
            document.getElementById('outputquery').style.display = 'block';
            document.getElementById('executerquery').style.display = 'block';
            //select components
            document.getElementById('selecttable').style.display = 'none';
            document.getElementById('selectonecolumn').style.display = 'none';
            document.getElementById('selecttwocolumn').style.display = 'none';
            document.getElementById('selectthreecolumn').style.display = 'none';
            //where components
            document.getElementById('wheretable').style.display = 'none';
            document.getElementById('whereonecolumn').style.display = 'none';
            document.getElementById('wheretwocolumn').style.display = 'none';
            document.getElementById('wherethreecolumn').style.display = 'none';
            //count components
            document.getElementById('counttable').style.display = 'none';
            document.getElementById('countonecolumn').style.display = 'none';
            document.getElementById('counttwocolumn').style.display = 'none';
            document.getElementById('countthreecolumn').style.display = 'none';
            //union components
            document.getElementById('uniontable').style.display = 'none';
            document.getElementById('uniontwodocument').style.display = 'none';
            document.getElementById('uniontwodoconecolumn').style.display = 'none';
            document.getElementById('uniontwodoctwocolumn').style.display = 'none';
            document.getElementById('uniontwodocthreecolumn').style.display = 'none';
            document.getElementById('unionthreedocument').style.display = 'none';
            document.getElementById('unionthreedoconecolumn').style.display = 'none';
            document.getElementById('unionthreedoctwocolumn').style.display = 'none';
            document.getElementById('unionthreedocthreecolumn').style.display = 'none';
        }
    }

    //where 3 specified column
    document.getElementById('count_3').addEventListener('click', count_3Change);
    async function count_3Change(e){
        e.preventDefault();

        //clear some input variable
        document.getElementById('querycol_1').value = '';
        document.getElementById('querycol_2').value = '';
        document.getElementById('querycol_3').value = '';
        document.getElementById('colname_1').value = '';
        document.getElementById('colname_2').value = '';
        document.getElementById('colname_3').value = '';

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var hostnametaskStr = document.getElementById('hostnamequery').value;
        var hostporttaskStr = document.getElementById('hostportquery').value;
        var usernametaskStr = document.getElementById('usernamequery').value;
        var passwordnametaskStr = document.getElementById('passwordnamequery').value;
        var serveurHeaderStr = document.getElementById("serveurheader").innerText;
        var deviceStr = document.getElementById('device').innerText;
        //xxxxxxxxxxxxxxx
        var databasequeryStr = document.getElementById('databasequery').value;
        var templatequeryStr = document.getElementById('templatequery').value;
        var serverqueryStr = document.getElementById('serverquery').value;
        var documentqueryStr = document.getElementById('documentquery').value;
        //search in local / remote database documents
        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            const dbbase = new PouchDB(databasequeryStr, {skip_setup: true,});
            const remotebase = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +databasequeryStr,{ skip_setup: true });
            PouchDB.plugin(PouchFind);
            // Initialise a sync with the remote server
            function sync() {
                var opts = {live: true,retry: true,continuous: true,attachments: true,};
                dbbase.sync(remotebase, opts);
            }
            if (remotebase) {
                sync();
            }
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                        fields: [
                            "taskdatabase",
                            "taskname",
                            "taskserver",
                            "documentname",
                        ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: databasequeryStr,
                        taskname: templatequeryStr,
                        taskserver: serverqueryStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    taskkeyquery = [];
                    var valeur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("threecount_one"), column);
                    //array of logical operators
                    var operateur = ["=","<",">"];
                    autocomplete(document.getElementById("threecountoperator_one"), operateur);
                    /*initiate the autocomplete function on the "twocolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("threecount_two"), column);
                    autocomplete(document.getElementById("threecountoperator_two"), operateur);
                    /*initiate the autocomplete function on the "threecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("threecount_three"), column);
                    autocomplete(document.getElementById("threecountoperator_three"), operateur);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };
        
        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            const dbbase = new PouchDB(serveurHeaderStr, {skip_setup: true,});
            PouchDB.plugin(PouchFind);
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                      fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "documentname",
                      ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: serveurHeaderStr,
                        taskname: templatequeryStr,
                        taskserver: serveurHeaderStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    taskkeyquery = [];
                    var valeur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("threecount_one"), column);
                    //array of logical operators
                    var operateur = ["=","<",">"];
                    autocomplete(document.getElementById("threecountoperator_one"), operateur);
                    /*initiate the autocomplete function on the "twocolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("threecount_two"), column);
                    autocomplete(document.getElementById("threecountoperator_two"), operateur);
                    /*initiate the autocomplete function on the "threecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("threecount_three"), column);
                    autocomplete(document.getElementById("threecountoperator_three"), operateur);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'block';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }
    //confirm where three column click
    document.getElementById('confirmcountthreecolumn').addEventListener('click', confirmcountthreecolumnChange);
    async function confirmcountthreecolumnChange(e){
        e.preventDefault();

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var threecount_oneStr = document.getElementById('threecount_one').value;
        var threecountoperator_oneStr = document.getElementById('threecountoperator_one').value;
        var threecountvalue_oneStr = document.getElementById('threecountvalue_one').value;
        var threecount_twoStr = document.getElementById('threecount_two').value;
        var threecountoperator_twoStr = document.getElementById('threecountoperator_two').value;
        var threecountvalue_twoStr = document.getElementById('threecountvalue_two').value;
        var threecount_threeStr = document.getElementById('threecount_three').value;
        var threecountoperator_threeStr = document.getElementById('threecountoperator_three').value;
        var threecountvalue_threeStr = document.getElementById('threecountvalue_three').value;
        var documentqueryStr = document.getElementById('documentquery').value; //document name
        var writequeryStr = document.getElementById('writequery');
        if(!threecount_oneStr){
            var pTextenocolumn = "Please enter column 1 name";
            alertModal(elementOutId,elementDropId,pTextenocolumn,buttonHandler);
        }else if(!threecountoperator_oneStr){
            var pTextenooperator = "Please enter operator 1";
            alertModal(elementOutId,elementDropId,pTextenooperator,buttonHandler);
        }else if(!threecountvalue_oneStr){
            var pTextenovalue = "Please enter value 1";
            alertModal(elementOutId,elementDropId,pTextenovalue,buttonHandler);
        }else if(!threecount_twoStr){
            var pTextenocolumn_2 = "Please enter column 2 name";
            alertModal(elementOutId,elementDropId,pTextenocolumn_2,buttonHandler);
        }else if(!threecountoperator_twoStr){
            var pTextenooperator_2 = "Please enter operator 2";
            alertModal(elementOutId,elementDropId,pTextenooperator_2,buttonHandler);
        }else if(!threecountvalue_twoStr){
            var pTextenovalue_2 = "Please enter value 2";
            alertModal(elementOutId,elementDropId,pTextenovalue_2,buttonHandler);
        }else if(!threecount_threeStr){
            var pTextenocolumn_3 = "Please enter column 3 name";
            alertModal(elementOutId,elementDropId,pTextenocolumn_3,buttonHandler);
        }else if(!threecountoperator_threeStr){
            var pTextenooperator_3 = "Please enter operator 3";
            alertModal(elementOutId,elementDropId,pTextenooperator_3,buttonHandler);
        }else if(!threecountvalue_threeStr){
            var pTextenovalue_3 = "Please enter value 3";
            alertModal(elementOutId,elementDropId,pTextenovalue_3,buttonHandler);
        }else{
            writequeryStr.value = 'SELECT COUNT(*) FROM '+documentqueryStr+' WHERE '+threecount_oneStr+' '+threecountoperator_oneStr+' '+threecountvalue_oneStr+
            ' AND '+threecount_twoStr+' '+threecountoperator_twoStr+' '+threecountvalue_twoStr+
            ' AND '+threecount_threeStr+' '+threecountoperator_threeStr+' '+threecountvalue_threeStr+';';
            writequeryStr.readOnly = true;
            var querycol_1Str = document.getElementById('querycol_1');
            querycol_1Str.value = threecount_oneStr;
            var querycol_2Str = document.getElementById('querycol_2');
            querycol_2Str.value = threecount_twoStr;
            var querycol_3Str = document.getElementById('querycol_3');
            querycol_3Str.value = threecount_threeStr;

            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('documentqueries').style.display = 'block';
            document.getElementById('querydocumentqueries').style.display = 'block';
            //xxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('connectqueries').style.display = 'none';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'none';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'block';
            document.getElementById('dynamictablequeries').style.display = 'none';
            //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
            document.getElementById('outputquery').style.display = 'block';
            document.getElementById('executerquery').style.display = 'block';
            //select components
            document.getElementById('selecttable').style.display = 'none';
            document.getElementById('selectonecolumn').style.display = 'none';
            document.getElementById('selecttwocolumn').style.display = 'none';
            document.getElementById('selectthreecolumn').style.display = 'none';
            //where components
            document.getElementById('wheretable').style.display = 'none';
            document.getElementById('whereonecolumn').style.display = 'none';
            document.getElementById('wheretwocolumn').style.display = 'none';
            document.getElementById('wherethreecolumn').style.display = 'none';
            //count components
            document.getElementById('counttable').style.display = 'none';
            document.getElementById('countonecolumn').style.display = 'none';
            document.getElementById('counttwocolumn').style.display = 'none';
            document.getElementById('countthreecolumn').style.display = 'none';
            //union components
            document.getElementById('uniontable').style.display = 'none';
            document.getElementById('uniontwodocument').style.display = 'none';
            document.getElementById('uniontwodoconecolumn').style.display = 'none';
            document.getElementById('uniontwodoctwocolumn').style.display = 'none';
            document.getElementById('uniontwodocthreecolumn').style.display = 'none';
            document.getElementById('unionthreedocument').style.display = 'none';
            document.getElementById('unionthreedoconecolumn').style.display = 'none';
            document.getElementById('unionthreedoctwocolumn').style.display = 'none';
            document.getElementById('unionthreedocthreecolumn').style.display = 'none';
        }
    }

    //--------- event listener for union query ---------------
    document.getElementById('unionquery').addEventListener('click', unionqueryChange);
    async function unionqueryChange(e){
        e.preventDefault();

        //xxxxxxxxxxxxxxx selected query name xxxxxxxxxx
        var selectedtqueryStr = document.getElementById('selectedtquery');
        selectedtqueryStr.value = 'union';

        //clear some input variable
        document.getElementById('querycol_1').value = '';
        document.getElementById('querycol_2').value = '';
        document.getElementById('querycol_3').value = '';
        document.getElementById('colname_1').value = '';
        document.getElementById('colname_2').value = '';
        document.getElementById('colname_3').value = '';

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'block';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }
    //Union 2 documents
    document.getElementById('union_2').addEventListener('click', union_2Change);
    async function union_2Change(e){
        e.preventDefault();

        //clear some input variable
        document.getElementById('querycol_1').value = '';
        document.getElementById('querycol_2').value = '';
        document.getElementById('querycol_3').value = '';
        document.getElementById('colname_1').value = '';
        document.getElementById('colname_2').value = '';
        document.getElementById('colname_3').value = '';

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var hostnametaskStr = document.getElementById('hostnamequery').value;
        var hostporttaskStr = document.getElementById('hostportquery').value;
        var usernametaskStr = document.getElementById('usernamequery').value;
        var passwordnametaskStr = document.getElementById('passwordnamequery').value;
        var serveurHeaderStr = document.getElementById("serveurheader").innerText;
        var deviceStr = document.getElementById('device').innerText;
        //xxxxxxxxxxxxxxx
        var databasequeryStr = document.getElementById('databasequery').value;
        var templatequeryStr = document.getElementById('templatequery').value;
        var serverqueryStr = document.getElementById('serverquery').value;
        var documentqueryStr = document.getElementById('documentquery').value;
        //search in local / remote database documents
        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            const dbbase = new PouchDB(databasequeryStr, {skip_setup: true,});
            const remotebase = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +databasequeryStr,{ skip_setup: true });
            PouchDB.plugin(PouchFind);
            // Initialise a sync with the remote server
            function sync() {
                var opts = {live: true,retry: true,continuous: true,attachments: true,};
                dbbase.sync(remotebase, opts);
            }
            if (remotebase) {
                sync();
            }
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                        fields: [
                            "taskdatabase",
                            "taskname",
                            "taskserver",
                            "documentname",
                        ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: databasequeryStr,
                        taskname: templatequeryStr,
                        taskserver: serverqueryStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    allDocument = [];
                    allDocument = docsCheckDocument;
                    taskkeyquery = [];
                    var valeur = [];
                    var docvaleur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                        docvaleur.push(docsCheckDocument[i].documentnumber);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                
                    /*initiate the autocomplete function on the "two document" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("uniontwodocument_one"), docvaleur);
                    autocomplete(document.getElementById("uniontwodocument_two"), docvaleur);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };
        
        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            const dbbase = new PouchDB(serveurHeaderStr, {skip_setup: true,});
            PouchDB.plugin(PouchFind);
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                      fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "documentname",
                      ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: serveurHeaderStr,
                        taskname: templatequeryStr,
                        taskserver: serveurHeaderStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    allDocument = [];
                    allDocument = docsCheckDocument;
                    taskkeyquery = [];
                    var valeur = [];
                    var docvaleur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                        docvaleur.push(docsCheckDocument[i].documentnumber);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                
                    /*initiate the autocomplete function on the "two document" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("uniontwodocument_one"), docvaleur);
                    autocomplete(document.getElementById("uniontwodocument_two"), docvaleur);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'block';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }
    //confirm two document click
    document.getElementById('confirmuniontwodocument').addEventListener('click', confirmuniontwodocumentChange);
    async function confirmuniontwodocumentChange(e){
        e.preventDefault();

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var uniontwodocument_oneStr = document.getElementById('uniontwodocument_one').value;
        var uniontwodocument_twoStr = document.getElementById('uniontwodocument_two').value;
        var documentqueryStr = document.getElementById('documentquery').value; //document name
        var writequeryStr = document.getElementById('writequery');
        if(!uniontwodocument_oneStr){
            var pTextenocolumn = "Please Enter First Doc name or number";
            alertModal(elementOutId,elementDropId,pTextenocolumn,buttonHandler);
        }else if(!uniontwodocument_twoStr){
            var pTextenovalue = "Please enter Second Doc name or number";
            alertModal(elementOutId,elementDropId,pTextenovalue,buttonHandler);
        }else{
            writequeryStr.value = 'SELECT '+uniontwodocument_oneStr+' FROM '+documentqueryStr+' UNION SELECT '+uniontwodocument_twoStr+' FROM '+documentqueryStr+';';
            writequeryStr.readOnly = true;
            var querycol_1Str = document.getElementById('querycol_1');
            querycol_1Str.value = uniontwodocument_oneStr;
            var querycol_2Str = document.getElementById('querycol_2');
            querycol_2Str.value = uniontwodocument_twoStr;

            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('documentqueries').style.display = 'block';
            document.getElementById('querydocumentqueries').style.display = 'block';
            //xxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('connectqueries').style.display = 'none';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'none';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'block';
            document.getElementById('dynamictablequeries').style.display = 'none';
            //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
            document.getElementById('outputquery').style.display = 'block';
            document.getElementById('executerquery').style.display = 'block';
            //select components
            document.getElementById('selecttable').style.display = 'none';
            document.getElementById('selectonecolumn').style.display = 'none';
            document.getElementById('selecttwocolumn').style.display = 'none';
            document.getElementById('selectthreecolumn').style.display = 'none';
            //where components
            document.getElementById('wheretable').style.display = 'none';
            document.getElementById('whereonecolumn').style.display = 'none';
            document.getElementById('wheretwocolumn').style.display = 'none';
            document.getElementById('wherethreecolumn').style.display = 'none';
            //count components
            document.getElementById('counttable').style.display = 'none';
            document.getElementById('countonecolumn').style.display = 'none';
            document.getElementById('counttwocolumn').style.display = 'none';
            document.getElementById('countthreecolumn').style.display = 'none';
            //union components
            document.getElementById('uniontable').style.display = 'none';
            document.getElementById('uniontwodocument').style.display = 'none';
            document.getElementById('uniontwodoconecolumn').style.display = 'none';
            document.getElementById('uniontwodoctwocolumn').style.display = 'none';
            document.getElementById('uniontwodocthreecolumn').style.display = 'none';
            document.getElementById('unionthreedocument').style.display = 'none';
            document.getElementById('unionthreedoconecolumn').style.display = 'none';
            document.getElementById('unionthreedoctwocolumn').style.display = 'none';
            document.getElementById('unionthreedocthreecolumn').style.display = 'none';
        }
    }

    //Union 2 documents
    document.getElementById('union_2_1').addEventListener('click', union_2_1Change);
    async function union_2_1Change(e){
        e.preventDefault();

        //clear some input variable
        document.getElementById('querycol_1').value = '';
        document.getElementById('querycol_2').value = '';
        document.getElementById('querycol_3').value = '';
        document.getElementById('colname_1').value = '';
        document.getElementById('colname_2').value = '';
        document.getElementById('colname_3').value = '';

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var hostnametaskStr = document.getElementById('hostnamequery').value;
        var hostporttaskStr = document.getElementById('hostportquery').value;
        var usernametaskStr = document.getElementById('usernamequery').value;
        var passwordnametaskStr = document.getElementById('passwordnamequery').value;
        var serveurHeaderStr = document.getElementById("serveurheader").innerText;
        var deviceStr = document.getElementById('device').innerText;
        //xxxxxxxxxxxxxxx
        var databasequeryStr = document.getElementById('databasequery').value;
        var templatequeryStr = document.getElementById('templatequery').value;
        var serverqueryStr = document.getElementById('serverquery').value;
        var documentqueryStr = document.getElementById('documentquery').value;
        //search in local / remote database documents
        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            const dbbase = new PouchDB(databasequeryStr, {skip_setup: true,});
            const remotebase = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +databasequeryStr,{ skip_setup: true });
            PouchDB.plugin(PouchFind);
            // Initialise a sync with the remote server
            function sync() {
                var opts = {live: true,retry: true,continuous: true,attachments: true,};
                dbbase.sync(remotebase, opts);
            }
            if (remotebase) {
                sync();
            }
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                        fields: [
                            "taskdatabase",
                            "taskname",
                            "taskserver",
                            "documentname",
                        ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: databasequeryStr,
                        taskname: templatequeryStr,
                        taskserver: serverqueryStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    allDocument = [];
                    allDocument = docsCheckDocument;
                    taskkeyquery = [];
                    var valeur = [];
                    var docvaleur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                        docvaleur.push(docsCheckDocument[i].documentnumber);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                
                    /*initiate the autocomplete function on the "two document" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("uniontwodocone_one"), docvaleur);
                    autocomplete(document.getElementById("uniontwodocone_two"), docvaleur);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("uniontwodocone_onename"), column);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            const dbbase = new PouchDB(serveurHeaderStr, {skip_setup: true,});
            PouchDB.plugin(PouchFind);
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                      fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "documentname",
                      ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: serveurHeaderStr,
                        taskname: templatequeryStr,
                        taskserver: serveurHeaderStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    allDocument = [];
                    allDocument = docsCheckDocument;
                    taskkeyquery = [];
                    var valeur = [];
                    var docvaleur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                        docvaleur.push(docsCheckDocument[i].documentnumber);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                
                    /*initiate the autocomplete function on the "two document" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("uniontwodocone_one"), docvaleur);
                    autocomplete(document.getElementById("uniontwodocone_two"), docvaleur);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("uniontwodocone_onename"), column);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'block';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }
    //confirm two document click
    document.getElementById('confirmuniontwodoconecolumn').addEventListener('click', confirmuniontwodoconecolumnChange);
    async function confirmuniontwodoconecolumnChange(e){
        e.preventDefault();

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var uniontwodocone_oneStr = document.getElementById('uniontwodocone_one').value;
        var uniontwodocone_twoStr = document.getElementById('uniontwodocone_two').value;
        var uniontwodocone_onenameStr = document.getElementById('uniontwodocone_onename').value;
        var documentqueryStr = document.getElementById('documentquery').value; //document name
        var writequeryStr = document.getElementById('writequery');
        if(!uniontwodocone_oneStr){
            var pTextenocolumn = "Please Enter First Doc name or number";
            alertModal(elementOutId,elementDropId,pTextenocolumn,buttonHandler);
        }else if(!uniontwodocone_twoStr){
            var pTextenovalue = "Please enter Second Doc name or number";
            alertModal(elementOutId,elementDropId,pTextenovalue,buttonHandler);
        }else if(!uniontwodocone_onenameStr){
            var pTextenocolumn = "Please enter column name";
            alertModal(elementOutId,elementDropId,pTextenocolumn,buttonHandler);
        }else{
            writequeryStr.value = 'SELECT '+uniontwodocone_oneStr+' FROM '+documentqueryStr+' UNION SELECT '+uniontwodocone_twoStr +
            ' FROM '+documentqueryStr+' WHERE column = '+uniontwodocone_onenameStr+';';
            writequeryStr.readOnly = true;
            var querycol_1Str = document.getElementById('querycol_1');
            querycol_1Str.value = uniontwodocone_oneStr;
            var querycol_2Str = document.getElementById('querycol_2');
            querycol_2Str.value = uniontwodocone_twoStr;
            var colname_1Str = document.getElementById('colname_1');
            colname_1Str.value = uniontwodocone_onenameStr;

            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('documentqueries').style.display = 'block';
            document.getElementById('querydocumentqueries').style.display = 'block';
            //xxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('connectqueries').style.display = 'none';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'none';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'block';
            document.getElementById('dynamictablequeries').style.display = 'none';
            //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
            document.getElementById('outputquery').style.display = 'block';
            document.getElementById('executerquery').style.display = 'block';
            //select components
            document.getElementById('selecttable').style.display = 'none';
            document.getElementById('selectonecolumn').style.display = 'none';
            document.getElementById('selecttwocolumn').style.display = 'none';
            document.getElementById('selectthreecolumn').style.display = 'none';
            //where components
            document.getElementById('wheretable').style.display = 'none';
            document.getElementById('whereonecolumn').style.display = 'none';
            document.getElementById('wheretwocolumn').style.display = 'none';
            document.getElementById('wherethreecolumn').style.display = 'none';
            //count components
            document.getElementById('counttable').style.display = 'none';
            document.getElementById('countonecolumn').style.display = 'none';
            document.getElementById('counttwocolumn').style.display = 'none';
            document.getElementById('countthreecolumn').style.display = 'none';
            //union components
            document.getElementById('uniontable').style.display = 'none';
            document.getElementById('uniontwodocument').style.display = 'none';
            document.getElementById('uniontwodoconecolumn').style.display = 'none';
            document.getElementById('uniontwodoctwocolumn').style.display = 'none';
            document.getElementById('uniontwodocthreecolumn').style.display = 'none';
            document.getElementById('unionthreedocument').style.display = 'none';
            document.getElementById('unionthreedoconecolumn').style.display = 'none';
            document.getElementById('unionthreedoctwocolumn').style.display = 'none';
            document.getElementById('unionthreedocthreecolumn').style.display = 'none';
        }
    }

    //Union 2 documents
    document.getElementById('union_2_2').addEventListener('click', union_2_2Change);
    async function union_2_2Change(e){
        e.preventDefault();

        //clear some input variable
        document.getElementById('querycol_1').value = '';
        document.getElementById('querycol_2').value = '';
        document.getElementById('querycol_3').value = '';
        document.getElementById('colname_1').value = '';
        document.getElementById('colname_2').value = '';
        document.getElementById('colname_3').value = '';

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var hostnametaskStr = document.getElementById('hostnamequery').value;
        var hostporttaskStr = document.getElementById('hostportquery').value;
        var usernametaskStr = document.getElementById('usernamequery').value;
        var passwordnametaskStr = document.getElementById('passwordnamequery').value;
        var serveurHeaderStr = document.getElementById("serveurheader").innerText;
        var deviceStr = document.getElementById('device').innerText;
        //xxxxxxxxxxxxxxx
        var databasequeryStr = document.getElementById('databasequery').value;
        var templatequeryStr = document.getElementById('templatequery').value;
        var serverqueryStr = document.getElementById('serverquery').value;
        var documentqueryStr = document.getElementById('documentquery').value;
        //search in local / remote database documents
        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            const dbbase = new PouchDB(databasequeryStr, {skip_setup: true,});
            const remotebase = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +databasequeryStr,{ skip_setup: true });
            PouchDB.plugin(PouchFind);
            // Initialise a sync with the remote server
            function sync() {
                var opts = {live: true,retry: true,continuous: true,attachments: true,};
                dbbase.sync(remotebase, opts);
            }
            if (remotebase) {
                sync();
            }
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                        fields: [
                            "taskdatabase",
                            "taskname",
                            "taskserver",
                            "documentname",
                        ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: databasequeryStr,
                        taskname: templatequeryStr,
                        taskserver: serverqueryStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    allDocument = [];
                    allDocument = docsCheckDocument;
                    taskkeyquery = [];
                    var valeur = [];
                    var docvaleur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                        docvaleur.push(docsCheckDocument[i].documentnumber);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                
                    /*initiate the autocomplete function on the "two document" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("uniontwodoctwo_one"), docvaleur);
                    autocomplete(document.getElementById("uniontwodoctwo_two"), docvaleur);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("uniontwodoctwo_onename"), column);
                    autocomplete(document.getElementById("uniontwodoctwo_twoname"), column);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };
        
        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            const dbbase = new PouchDB(serveurHeaderStr, {skip_setup: true,});
            PouchDB.plugin(PouchFind);
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                      fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "documentname",
                      ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: serveurHeaderStr,
                        taskname: templatequeryStr,
                        taskserver: serveurHeaderStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    allDocument = [];
                    allDocument = docsCheckDocument;
                    taskkeyquery = [];
                    var valeur = [];
                    var docvaleur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                        docvaleur.push(docsCheckDocument[i].documentnumber);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                
                    /*initiate the autocomplete function on the "two document" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("uniontwodoctwo_one"), docvaleur);
                    autocomplete(document.getElementById("uniontwodoctwo_two"), docvaleur);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("uniontwodoctwo_onename"), column);
                    autocomplete(document.getElementById("uniontwodoctwo_twoname"), column);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'block';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }
    //confirm two document click
    document.getElementById('confirmuniontwodoctwocolumn').addEventListener('click', confirmuniontwodoctwocolumnChange);
    async function confirmuniontwodoctwocolumnChange(e){
        e.preventDefault();

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var uniontwodoctwo_oneStr = document.getElementById('uniontwodoctwo_one').value;
        var uniontwodoctwo_twoStr = document.getElementById('uniontwodoctwo_two').value;
        var uniontwodoctwo_onenameStr = document.getElementById('uniontwodoctwo_onename').value;
        var uniontwodoctwo_twonameStr = document.getElementById('uniontwodoctwo_twoname').value;
        var documentqueryStr = document.getElementById('documentquery').value; //document name
        var writequeryStr = document.getElementById('writequery');
        if(!uniontwodoctwo_oneStr){
            var pTextenocolumn = "Please Enter First Doc name or number";
            alertModal(elementOutId,elementDropId,pTextenocolumn,buttonHandler);
        }else if(!uniontwodoctwo_twoStr){
            var pTextenovalue = "Please enter Second Doc name or number";
            alertModal(elementOutId,elementDropId,pTextenovalue,buttonHandler);
        }else if(!uniontwodoctwo_onenameStr){
            var pTextenocolumn_1 = "Please enter column 1 name";
            alertModal(elementOutId,elementDropId,pTextenocolumn_1,buttonHandler);
        }else if(!uniontwodoctwo_twonameStr){
            var pTextenocolumn_2 = "Please enter column 2 name";
            alertModal(elementOutId,elementDropId,pTextenocolumn_2,buttonHandler);
        }else{
            writequeryStr.value = 'SELECT '+uniontwodoctwo_oneStr+' FROM '+documentqueryStr+' UNION SELECT '+uniontwodoctwo_twoStr+
            ' FROM '+documentqueryStr+' WHERE column 1 = '+uniontwodoctwo_onenameStr+' AND column 2 = '+uniontwodoctwo_twonameStr+';';
            writequeryStr.readOnly = true;
            var querycol_1Str = document.getElementById('querycol_1');
            querycol_1Str.value = uniontwodoctwo_oneStr;
            var querycol_2Str = document.getElementById('querycol_2');
            querycol_2Str.value = uniontwodoctwo_twoStr;
            var colname_1Str = document.getElementById('colname_1');
            colname_1Str.value = uniontwodoctwo_onenameStr;
            var colname_2Str = document.getElementById('colname_2');
            colname_2Str.value = uniontwodoctwo_twonameStr;

            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('documentqueries').style.display = 'block';
            document.getElementById('querydocumentqueries').style.display = 'block';
            //xxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('connectqueries').style.display = 'none';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'none';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'block';
            document.getElementById('dynamictablequeries').style.display = 'none';
            //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
            document.getElementById('outputquery').style.display = 'block';
            document.getElementById('executerquery').style.display = 'block';
            //select components
            document.getElementById('selecttable').style.display = 'none';
            document.getElementById('selectonecolumn').style.display = 'none';
            document.getElementById('selecttwocolumn').style.display = 'none';
            document.getElementById('selectthreecolumn').style.display = 'none';
            //where components
            document.getElementById('wheretable').style.display = 'none';
            document.getElementById('whereonecolumn').style.display = 'none';
            document.getElementById('wheretwocolumn').style.display = 'none';
            document.getElementById('wherethreecolumn').style.display = 'none';
            //count components
            document.getElementById('counttable').style.display = 'none';
            document.getElementById('countonecolumn').style.display = 'none';
            document.getElementById('counttwocolumn').style.display = 'none';
            document.getElementById('countthreecolumn').style.display = 'none';
            //union components
            document.getElementById('uniontable').style.display = 'none';
            document.getElementById('uniontwodocument').style.display = 'none';
            document.getElementById('uniontwodoconecolumn').style.display = 'none';
            document.getElementById('uniontwodoctwocolumn').style.display = 'none';
            document.getElementById('uniontwodocthreecolumn').style.display = 'none';
            document.getElementById('unionthreedocument').style.display = 'none';
            document.getElementById('unionthreedoconecolumn').style.display = 'none';
            document.getElementById('unionthreedoctwocolumn').style.display = 'none';
            document.getElementById('unionthreedocthreecolumn').style.display = 'none';
        }
    }//

    //Union 2 documents
    document.getElementById('union_2_3').addEventListener('click', union_2_3Change);
    async function union_2_3Change(e){
        e.preventDefault();

        //clear some input variable
        document.getElementById('querycol_1').value = '';
        document.getElementById('querycol_2').value = '';
        document.getElementById('querycol_3').value = '';
        document.getElementById('colname_1').value = '';
        document.getElementById('colname_2').value = '';
        document.getElementById('colname_3').value = '';

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var hostnametaskStr = document.getElementById('hostnamequery').value;
        var hostporttaskStr = document.getElementById('hostportquery').value;
        var usernametaskStr = document.getElementById('usernamequery').value;
        var passwordnametaskStr = document.getElementById('passwordnamequery').value;
        var serveurHeaderStr = document.getElementById("serveurheader").innerText;
        var deviceStr = document.getElementById('device').innerText;
        //xxxxxxxxxxxxxxx
        var databasequeryStr = document.getElementById('databasequery').value;
        var templatequeryStr = document.getElementById('templatequery').value;
        var serverqueryStr = document.getElementById('serverquery').value;
        var documentqueryStr = document.getElementById('documentquery').value;
        //search in local / remote database documents
        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            const dbbase = new PouchDB(databasequeryStr, {skip_setup: true,});
            const remotebase = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +databasequeryStr,{ skip_setup: true });
            PouchDB.plugin(PouchFind);
            // Initialise a sync with the remote server
            function sync() {
                var opts = {live: true,retry: true,continuous: true,attachments: true,};
                dbbase.sync(remotebase, opts);
            }
            if (remotebase) {
                sync();
            }
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                        fields: [
                            "taskdatabase",
                            "taskname",
                            "taskserver",
                            "documentname",
                        ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: databasequeryStr,
                        taskname: templatequeryStr,
                        taskserver: serverqueryStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    allDocument = [];
                    allDocument = docsCheckDocument;
                    taskkeyquery = [];
                    var valeur = [];
                    var docvaleur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                        docvaleur.push(docsCheckDocument[i].documentnumber);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                
                    /*initiate the autocomplete function on the "two document" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("uniontwodocthree_one"), docvaleur);
                    autocomplete(document.getElementById("uniontwodocthree_two"), docvaleur);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("uniontwodocthree_onename"), column);
                    autocomplete(document.getElementById("uniontwodocthree_twoname"), column);
                    autocomplete(document.getElementById("uniontwodocthree_threename"), column);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler); 
            }
        };

        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            const dbbase = new PouchDB(serveurHeaderStr, {skip_setup: true,});
            PouchDB.plugin(PouchFind);
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                      fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "documentname",
                      ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: serveurHeaderStr,
                        taskname: templatequeryStr,
                        taskserver: serveurHeaderStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    allDocument = [];
                    allDocument = docsCheckDocument;
                    taskkeyquery = [];
                    var valeur = [];
                    var docvaleur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                        docvaleur.push(docsCheckDocument[i].documentnumber);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                
                    /*initiate the autocomplete function on the "two document" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("uniontwodocthree_one"), docvaleur);
                    autocomplete(document.getElementById("uniontwodocthree_two"), docvaleur);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("uniontwodocthree_onename"), column);
                    autocomplete(document.getElementById("uniontwodocthree_twoname"), column);
                    autocomplete(document.getElementById("uniontwodocthree_threename"), column);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'block';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }//
    //confirm two document click
    document.getElementById('confirmuniontwodocthreecolumn').addEventListener('click', confirmuniontwodocthreecolumnChange);
    async function confirmuniontwodocthreecolumnChange(e){
        e.preventDefault();

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var uniontwodocthree_oneStr = document.getElementById('uniontwodocthree_one').value;
        var uniontwodocthree_twoStr = document.getElementById('uniontwodocthree_two').value;
        var uniontwodocthree_onenameStr = document.getElementById('uniontwodocthree_onename').value;
        var uniontwodocthree_twonameStr = document.getElementById('uniontwodocthree_twoname').value;
        var uniontwodocthree_threenameStr = document.getElementById('uniontwodocthree_threename').value;
        var documentqueryStr = document.getElementById('documentquery').value; //document name
        var writequeryStr = document.getElementById('writequery');
        if(!uniontwodocthree_oneStr){
            var pTextenocolumn = "Please Enter First Doc name or number";
            alertModal(elementOutId,elementDropId,pTextenocolumn,buttonHandler);
        }else if(!uniontwodocthree_twoStr){
            var pTextenovalue = "Please enter Second Doc name or number";
            alertModal(elementOutId,elementDropId,pTextenovalue,buttonHandler);
        }else if(!uniontwodocthree_onenameStr){
            var pTextenocolumn_1 = "Please enter column 1 name";
            alertModal(elementOutId,elementDropId,pTextenocolumn_1,buttonHandler);
        }else if(!uniontwodocthree_twonameStr){
            var pTextenocolumn_2 = "Please enter column 2 name";
            alertModal(elementOutId,elementDropId,pTextenocolumn_2,buttonHandler);
        }else if(!uniontwodocthree_threenameStr){
            var pTextenocolumn_3 = "Please enter column 3 name";
            alertModal(elementOutId,elementDropId,pTextenocolumn_3,buttonHandler);
        }else{
            writequeryStr.value = 'SELECT '+uniontwodocthree_oneStr+' FROM '+documentqueryStr+' UNION SELECT '+uniontwodocthree_twoStr+
            ' FROM '+documentqueryStr+' WHERE column 1 = '+uniontwodocthree_onenameStr+
            ' AND column 2 = '+uniontwodocthree_twonameStr+' AND column 3 = '+uniontwodocthree_threenameStr+';';
            writequeryStr.readOnly = true;
            var querycol_1Str = document.getElementById('querycol_1');
            querycol_1Str.value = uniontwodocthree_oneStr;
            var querycol_2Str = document.getElementById('querycol_2');
            querycol_2Str.value = uniontwodocthree_twoStr;
            var colname_1Str = document.getElementById('colname_1');
            colname_1Str.value = uniontwodocthree_onenameStr;
            var colname_2Str = document.getElementById('colname_2');
            colname_2Str.value = uniontwodocthree_twonameStr;
            var colname_3Str = document.getElementById('colname_3');
            colname_3Str.value = uniontwodocthree_threenameStr;

            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('documentqueries').style.display = 'block';
            document.getElementById('querydocumentqueries').style.display = 'block';
            //xxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('connectqueries').style.display = 'none';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'none';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'block';
            document.getElementById('dynamictablequeries').style.display = 'none';
            //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
            document.getElementById('outputquery').style.display = 'block';
            document.getElementById('executerquery').style.display = 'block';
            //select components
            document.getElementById('selecttable').style.display = 'none';
            document.getElementById('selectonecolumn').style.display = 'none';
            document.getElementById('selecttwocolumn').style.display = 'none';
            document.getElementById('selectthreecolumn').style.display = 'none';
            //where components
            document.getElementById('wheretable').style.display = 'none';
            document.getElementById('whereonecolumn').style.display = 'none';
            document.getElementById('wheretwocolumn').style.display = 'none';
            document.getElementById('wherethreecolumn').style.display = 'none';
            //count components
            document.getElementById('counttable').style.display = 'none';
            document.getElementById('countonecolumn').style.display = 'none';
            document.getElementById('counttwocolumn').style.display = 'none';
            document.getElementById('countthreecolumn').style.display = 'none';
            //union components
            document.getElementById('uniontable').style.display = 'none';
            document.getElementById('uniontwodocument').style.display = 'none';
            document.getElementById('uniontwodoconecolumn').style.display = 'none';
            document.getElementById('uniontwodoctwocolumn').style.display = 'none';
            document.getElementById('uniontwodocthreecolumn').style.display = 'none';
            document.getElementById('unionthreedocument').style.display = 'none';
            document.getElementById('unionthreedoconecolumn').style.display = 'none';
            document.getElementById('unionthreedoctwocolumn').style.display = 'none';
            document.getElementById('unionthreedocthreecolumn').style.display = 'none';
        }
    }

    //Union 3 documents
    document.getElementById('union_3').addEventListener('click', union_3Change);
    async function union_3Change(e){
        e.preventDefault();

        //clear some input variable
        document.getElementById('querycol_1').value = '';
        document.getElementById('querycol_2').value = '';
        document.getElementById('querycol_3').value = '';
        document.getElementById('colname_1').value = '';
        document.getElementById('colname_2').value = '';
        document.getElementById('colname_3').value = '';

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var hostnametaskStr = document.getElementById('hostnamequery').value;
        var hostporttaskStr = document.getElementById('hostportquery').value;
        var usernametaskStr = document.getElementById('usernamequery').value;
        var passwordnametaskStr = document.getElementById('passwordnamequery').value;
        var serveurHeaderStr = document.getElementById("serveurheader").innerText;
        var deviceStr = document.getElementById('device').innerText;
        //xxxxxxxxxxxxxxx
        var databasequeryStr = document.getElementById('databasequery').value;
        var templatequeryStr = document.getElementById('templatequery').value;
        var serverqueryStr = document.getElementById('serverquery').value;
        var documentqueryStr = document.getElementById('documentquery').value;
        //search in local / remote database documents
        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            const dbbase = new PouchDB(databasequeryStr, {skip_setup: true,});
            const remotebase = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +databasequeryStr,{ skip_setup: true });
            PouchDB.plugin(PouchFind);
            // Initialise a sync with the remote server
            function sync() {
                var opts = {live: true,retry: true,continuous: true,attachments: true,};
                dbbase.sync(remotebase, opts);
            }
            if (remotebase) {
                sync();
            }
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                        fields: [
                            "taskdatabase",
                            "taskname",
                            "taskserver",
                            "documentname",
                        ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: databasequeryStr,
                        taskname: templatequeryStr,
                        taskserver: serverqueryStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    allDocument = [];
                    allDocument = docsCheckDocument;
                    taskkeyquery = [];
                    var valeur = [];
                    var docvaleur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                        docvaleur.push(docsCheckDocument[i].documentnumber);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                
                    /*initiate the autocomplete function on the "two document" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("unionthreedocument_one"), docvaleur);
                    autocomplete(document.getElementById("unionthreedocument_two"), docvaleur);
                    autocomplete(document.getElementById("unionthreedocument_three"), docvaleur);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            const dbbase = new PouchDB(serveurHeaderStr, {skip_setup: true,});
            PouchDB.plugin(PouchFind);
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                      fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "documentname",
                      ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: serveurHeaderStr,
                        taskname: templatequeryStr,
                        taskserver: serveurHeaderStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    allDocument = [];
                    allDocument = docsCheckDocument;
                    taskkeyquery = [];
                    var valeur = [];
                    var docvaleur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                        docvaleur.push(docsCheckDocument[i].documentnumber);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                
                    /*initiate the autocomplete function on the "two document" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("unionthreedocument_one"), docvaleur);
                    autocomplete(document.getElementById("unionthreedocument_two"), docvaleur);
                    autocomplete(document.getElementById("unionthreedocument_three"), docvaleur);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'block';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }
    //confirm two document click
    document.getElementById('confirmunionthreedocument').addEventListener('click', confirmunionthreedocumentChange);
    async function confirmunionthreedocumentChange(e){
        e.preventDefault();

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var unionthreedocument_oneStr = document.getElementById('unionthreedocument_one').value;
        var unionthreedocument_twoStr = document.getElementById('unionthreedocument_two').value;
        var unionthreedocument_threeStr = document.getElementById('unionthreedocument_three').value;
        var documentqueryStr = document.getElementById('documentquery').value; //document name
        var writequeryStr = document.getElementById('writequery');
        if(!unionthreedocument_oneStr){
            var pTextenocolumn_1 = "Please Enter First Doc name or number";
            alertModal(elementOutId,elementDropId,pTextenocolumn_1,buttonHandler);
        }else if(!unionthreedocument_twoStr){
            var pTextenovalue_2 = "Please enter Second Doc name or number";
            alertModal(elementOutId,elementDropId,pTextenovalue_2,buttonHandler);
        }else if(!unionthreedocument_threeStr){
            var pTextenovalue_3 = "Please enter Second Doc name or number";
            alertModal(elementOutId,elementDropId,pTextenovalue_3,buttonHandler);
        }else{
            writequeryStr.value = 'SELECT '+unionthreedocument_oneStr+' FROM '+documentqueryStr+
            ' UNION SELECT '+unionthreedocument_twoStr+' FROM '+documentqueryStr+
            ' UNION SELECT '+unionthreedocument_threeStr+' FROM '+documentqueryStr+';';
            writequeryStr.readOnly = true;
            var querycol_1Str = document.getElementById('querycol_1');
            querycol_1Str.value = unionthreedocument_oneStr;
            var querycol_2Str = document.getElementById('querycol_2');
            querycol_2Str.value = unionthreedocument_twoStr;
            var querycol_3Str = document.getElementById('querycol_3');
            querycol_3Str.value = unionthreedocument_threeStr;

            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('documentqueries').style.display = 'block';
            document.getElementById('querydocumentqueries').style.display = 'block';
            //xxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('connectqueries').style.display = 'none';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'none';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'block';
            document.getElementById('dynamictablequeries').style.display = 'none';
            //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
            document.getElementById('outputquery').style.display = 'block';
            document.getElementById('executerquery').style.display = 'block';
            //select components
            document.getElementById('selecttable').style.display = 'none';
            document.getElementById('selectonecolumn').style.display = 'none';
            document.getElementById('selecttwocolumn').style.display = 'none';
            document.getElementById('selectthreecolumn').style.display = 'none';
            //where components
            document.getElementById('wheretable').style.display = 'none';
            document.getElementById('whereonecolumn').style.display = 'none';
            document.getElementById('wheretwocolumn').style.display = 'none';
            document.getElementById('wherethreecolumn').style.display = 'none';
            //count components
            document.getElementById('counttable').style.display = 'none';
            document.getElementById('countonecolumn').style.display = 'none';
            document.getElementById('counttwocolumn').style.display = 'none';
            document.getElementById('countthreecolumn').style.display = 'none';
            //union components
            document.getElementById('uniontable').style.display = 'none';
            document.getElementById('uniontwodocument').style.display = 'none';
            document.getElementById('uniontwodoconecolumn').style.display = 'none';
            document.getElementById('uniontwodoctwocolumn').style.display = 'none';
            document.getElementById('uniontwodocthreecolumn').style.display = 'none';
            document.getElementById('unionthreedocument').style.display = 'none';
            document.getElementById('unionthreedoconecolumn').style.display = 'none';
            document.getElementById('unionthreedoctwocolumn').style.display = 'none';
            document.getElementById('unionthreedocthreecolumn').style.display = 'none';
        }
    }//

    //Union 3 documents
    document.getElementById('union_3_1').addEventListener('click', union_3_1Change);
    async function union_3_1Change(e){
        e.preventDefault();

        //clear some input variable
        document.getElementById('querycol_1').value = '';
        document.getElementById('querycol_2').value = '';
        document.getElementById('querycol_3').value = '';
        document.getElementById('colname_1').value = '';
        document.getElementById('colname_2').value = '';
        document.getElementById('colname_3').value = '';

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var hostnametaskStr = document.getElementById('hostnamequery').value;
        var hostporttaskStr = document.getElementById('hostportquery').value;
        var usernametaskStr = document.getElementById('usernamequery').value;
        var passwordnametaskStr = document.getElementById('passwordnamequery').value;
        var serveurHeaderStr = document.getElementById("serveurheader").innerText;
        var deviceStr = document.getElementById('device').innerText;
        //xxxxxxxxxxxxxxx
        var databasequeryStr = document.getElementById('databasequery').value;
        var templatequeryStr = document.getElementById('templatequery').value;
        var serverqueryStr = document.getElementById('serverquery').value;
        var documentqueryStr = document.getElementById('documentquery').value;
        //search in local / remote database documents
        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            const dbbase = new PouchDB(databasequeryStr, {skip_setup: true,});
            const remotebase = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +databasequeryStr,{ skip_setup: true });
            PouchDB.plugin(PouchFind);
            // Initialise a sync with the remote server
            function sync() {
                var opts = {live: true,retry: true,continuous: true,attachments: true,};
                dbbase.sync(remotebase, opts);
            }
            if (remotebase) {
                sync();
            }
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                        fields: [
                            "taskdatabase",
                            "taskname",
                            "taskserver",
                            "documentname",
                        ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: databasequeryStr,
                        taskname: templatequeryStr,
                        taskserver: serverqueryStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    allDocument = [];
                    allDocument = docsCheckDocument;
                    taskkeyquery = [];
                    var valeur = [];
                    var docvaleur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                        docvaleur.push(docsCheckDocument[i].documentnumber);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                
                    /*initiate the autocomplete function on the "two document" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("unionthreedocone_one"), docvaleur);
                    autocomplete(document.getElementById("unionthreedocone_two"), docvaleur);
                    autocomplete(document.getElementById("unionthreedocone_three"), docvaleur);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("unionthreedocone_onename"), column);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            const dbbase = new PouchDB(serveurHeaderStr, {skip_setup: true,});
            PouchDB.plugin(PouchFind);
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                      fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "documentname",
                      ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: serveurHeaderStr,
                        taskname: templatequeryStr,
                        taskserver: serveurHeaderStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    allDocument = [];
                    allDocument = docsCheckDocument;
                    taskkeyquery = [];
                    var valeur = [];
                    var docvaleur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                        docvaleur.push(docsCheckDocument[i].documentnumber);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                
                    /*initiate the autocomplete function on the "two document" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("unionthreedocone_one"), docvaleur);
                    autocomplete(document.getElementById("unionthreedocone_two"), docvaleur);
                    autocomplete(document.getElementById("unionthreedocone_three"), docvaleur);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("unionthreedocone_onename"), column);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'block';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }
    //confirm two document click
    document.getElementById('confirmunionthreedoconecolumn').addEventListener('click', confirmunionthreedoconecolumnChange);
    async function confirmunionthreedoconecolumnChange(e){
        e.preventDefault();

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var unionthreedocone_oneStr = document.getElementById('unionthreedocone_one').value;
        var unionthreedocone_twoStr = document.getElementById('unionthreedocone_two').value;
        var unionthreedocone_threeStr = document.getElementById('unionthreedocone_three').value;
        var unionthreedocone_onenameStr = document.getElementById('unionthreedocone_onename').value;
        var documentqueryStr = document.getElementById('documentquery').value; //document name
        var writequeryStr = document.getElementById('writequery');
        if(!unionthreedocone_oneStr){
            var pTextenocolumn = "Please Enter First Doc name or number";
            alertModal(elementOutId,elementDropId,pTextenocolumn,buttonHandler);
        }else if(!unionthreedocone_twoStr){
            var pTextenovalue = "Please enter Third Doc name or number";
            alertModal(elementOutId,elementDropId,pTextenovalue,buttonHandler);
        }else if(!unionthreedocone_threeStr){
            var pTextenovalue = "Please enter Second Doc name or number";
            alertModal(elementOutId,elementDropId,pTextenovalue,buttonHandler);
        }
        else if(!unionthreedocone_onenameStr){
            var pTextenocolumn = "Please enter column name";
            alertModal(elementOutId,elementDropId,pTextenocolumn,buttonHandler);
        }else{
            writequeryStr.value = 'SELECT '+unionthreedocone_oneStr+' FROM '+documentqueryStr+
            ' UNION SELECT '+unionthreedocone_twoStr+' FROM '+documentqueryStr+
            ' UNION SELECT '+unionthreedocone_threeStr+' FROM '+documentqueryStr+
            ' WHERE column = '+unionthreedocone_onenameStr+';';
            writequeryStr.readOnly = true;
            var querycol_1Str = document.getElementById('querycol_1');
            querycol_1Str.value = unionthreedocone_oneStr;
            var querycol_2Str = document.getElementById('querycol_2');
            querycol_2Str.value = unionthreedocone_twoStr;
            var querycol_3Str = document.getElementById('querycol_3');
            querycol_3Str.value = unionthreedocone_threeStr;
            var colname_1Str = document.getElementById('colname_1');
            colname_1Str.value = unionthreedocone_onenameStr;

            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('documentqueries').style.display = 'block';
            document.getElementById('querydocumentqueries').style.display = 'block';
            //xxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('connectqueries').style.display = 'none';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'none';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'block';
            document.getElementById('dynamictablequeries').style.display = 'none';
            //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
            document.getElementById('outputquery').style.display = 'block';
            document.getElementById('executerquery').style.display = 'block';
            //select components
            document.getElementById('selecttable').style.display = 'none';
            document.getElementById('selectonecolumn').style.display = 'none';
            document.getElementById('selecttwocolumn').style.display = 'none';
            document.getElementById('selectthreecolumn').style.display = 'none';
            //where components
            document.getElementById('wheretable').style.display = 'none';
            document.getElementById('whereonecolumn').style.display = 'none';
            document.getElementById('wheretwocolumn').style.display = 'none';
            document.getElementById('wherethreecolumn').style.display = 'none';
            //count components
            document.getElementById('counttable').style.display = 'none';
            document.getElementById('countonecolumn').style.display = 'none';
            document.getElementById('counttwocolumn').style.display = 'none';
            document.getElementById('countthreecolumn').style.display = 'none';
            //union components
            document.getElementById('uniontable').style.display = 'none';
            document.getElementById('uniontwodocument').style.display = 'none';
            document.getElementById('uniontwodoconecolumn').style.display = 'none';
            document.getElementById('uniontwodoctwocolumn').style.display = 'none';
            document.getElementById('uniontwodocthreecolumn').style.display = 'none';
            document.getElementById('unionthreedocument').style.display = 'none';
            document.getElementById('unionthreedoconecolumn').style.display = 'none';
            document.getElementById('unionthreedoctwocolumn').style.display = 'none';
            document.getElementById('unionthreedocthreecolumn').style.display = 'none';
        }
    }//

    //Union 3 documents
    document.getElementById('union_3_2').addEventListener('click', union_3_2Change);
    async function union_3_2Change(e){
        e.preventDefault();

        //clear some input variable
        document.getElementById('querycol_1').value = '';
        document.getElementById('querycol_2').value = '';
        document.getElementById('querycol_3').value = '';
        document.getElementById('colname_1').value = '';
        document.getElementById('colname_2').value = '';
        document.getElementById('colname_3').value = '';

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var hostnametaskStr = document.getElementById('hostnamequery').value;
        var hostporttaskStr = document.getElementById('hostportquery').value;
        var usernametaskStr = document.getElementById('usernamequery').value;
        var passwordnametaskStr = document.getElementById('passwordnamequery').value;
        var serveurHeaderStr = document.getElementById("serveurheader").innerText;
        var deviceStr = document.getElementById('device').innerText;
        //xxxxxxxxxxxxxxx
        var databasequeryStr = document.getElementById('databasequery').value;
        var templatequeryStr = document.getElementById('templatequery').value;
        var serverqueryStr = document.getElementById('serverquery').value;
        var documentqueryStr = document.getElementById('documentquery').value;
        //search in local / remote database documents
        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            const dbbase = new PouchDB(databasequeryStr, {skip_setup: true,});
            const remotebase = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +databasequeryStr,{ skip_setup: true });
            PouchDB.plugin(PouchFind);
            // Initialise a sync with the remote server
            function sync() {
                var opts = {live: true,retry: true,continuous: true,attachments: true,};
                dbbase.sync(remotebase, opts);
            }
            if (remotebase) {
                sync();
            }
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                        fields: [
                            "taskdatabase",
                            "taskname",
                            "taskserver",
                            "documentname",
                        ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: databasequeryStr,
                        taskname: templatequeryStr,
                        taskserver: serverqueryStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    allDocument = [];
                    allDocument = docsCheckDocument;
                    taskkeyquery = [];
                    var valeur = [];
                    var docvaleur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                        docvaleur.push(docsCheckDocument[i].documentnumber);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                
                    /*initiate the autocomplete function on the "two document" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("unionthreedoctwo_one"), docvaleur);
                    autocomplete(document.getElementById("unionthreedoctwo_two"), docvaleur);
                    autocomplete(document.getElementById("unionthreedoctwo_three"), docvaleur);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("unionthreedoctwo_onename"), column);
                    autocomplete(document.getElementById("unionthreedoctwo_twoname"), column);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            const dbbase = new PouchDB(serveurHeaderStr, {skip_setup: true,});
            PouchDB.plugin(PouchFind);
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                      fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "documentname",
                      ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: serveurHeaderStr,
                        taskname: templatequeryStr,
                        taskserver: serveurHeaderStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    allDocument = [];
                    allDocument = docsCheckDocument;
                    taskkeyquery = [];
                    var valeur = [];
                    var docvaleur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                        docvaleur.push(docsCheckDocument[i].documentnumber);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                
                    /*initiate the autocomplete function on the "two document" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("unionthreedoctwo_one"), docvaleur);
                    autocomplete(document.getElementById("unionthreedoctwo_two"), docvaleur);
                    autocomplete(document.getElementById("unionthreedoctwo_three"), docvaleur);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("unionthreedoctwo_onename"), column);
                    autocomplete(document.getElementById("unionthreedoctwo_twoname"), column);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'block';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }
    //confirm two document click
    document.getElementById('confirmunionthreedoctwocolumn').addEventListener('click', confirmunionthreedoctwocolumnChange);
    async function confirmunionthreedoctwocolumnChange(e){
        e.preventDefault();

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var unionthreedoctwo_oneStr = document.getElementById('unionthreedoctwo_one').value;
        var unionthreedoctwo_twoStr = document.getElementById('unionthreedoctwo_two').value;
        var unionthreedoctwo_threeStr = document.getElementById('unionthreedoctwo_three').value;

        var unionthreedoctwo_onenameStr = document.getElementById('unionthreedoctwo_onename').value;
        var unionthreedoctwo_twonameStr = document.getElementById('unionthreedoctwo_twoname').value;

        var documentqueryStr = document.getElementById('documentquery').value; //document name
        var writequeryStr = document.getElementById('writequery');

        if(!unionthreedoctwo_oneStr){
            var pTextenocolumn = "Please Enter First Doc name or number";
            alertModal(elementOutId,elementDropId,pTextenocolumn,buttonHandler);
        }else if(!unionthreedoctwo_twoStr){
            var pTextenovalue = "Please enter Second Doc name or number";
            alertModal(elementOutId,elementDropId,pTextenovalue,buttonHandler);
        }else if(!unionthreedoctwo_threeStr){
            var pTextenovalue = "Please enter Third Doc name or number";
            alertModal(elementOutId,elementDropId,pTextenovalue,buttonHandler);
        }else if(!unionthreedoctwo_onenameStr){
            var pTextenocolumn_1 = "Please enter column 1 name";
            alertModal(elementOutId,elementDropId,pTextenocolumn_1,buttonHandler);
        }else if(!unionthreedoctwo_twonameStr){
            var pTextenocolumn_2 = "Please enter column 2 name";
            alertModal(elementOutId,elementDropId,pTextenocolumn_2,buttonHandler);
        }else{
            writequeryStr.value = 'SELECT '+unionthreedoctwo_oneStr+' FROM '+documentqueryStr+
            ' UNION SELECT '+unionthreedoctwo_twoStr+' FROM '+documentqueryStr+
            ' UNION SELECT '+unionthreedoctwo_threeStr+' FROM '+documentqueryStr+
            ' WHERE column 1 = '+unionthreedoctwo_onenameStr+
            ' AND column 2 = '+unionthreedoctwo_twonameStr+';';
            writequeryStr.readOnly = true;
            var querycol_1Str = document.getElementById('querycol_1');
            querycol_1Str.value = unionthreedoctwo_oneStr;
            var querycol_2Str = document.getElementById('querycol_2');
            querycol_2Str.value = unionthreedoctwo_twoStr;
            var querycol_3Str = document.getElementById('querycol_3');
            querycol_3Str.value = unionthreedoctwo_threeStr;
            var colname_1Str = document.getElementById('colname_1');
            colname_1Str.value = unionthreedoctwo_onenameStr;
            var colname_2Str = document.getElementById('colname_2');
            colname_2Str.value = unionthreedoctwo_twonameStr;

            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('documentqueries').style.display = 'block';
            document.getElementById('querydocumentqueries').style.display = 'block';
            //xxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('connectqueries').style.display = 'none';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'none';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'block';
            document.getElementById('dynamictablequeries').style.display = 'none';
            //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
            document.getElementById('outputquery').style.display = 'block';
            document.getElementById('executerquery').style.display = 'block';
            //select components
            document.getElementById('selecttable').style.display = 'none';
            document.getElementById('selectonecolumn').style.display = 'none';
            document.getElementById('selecttwocolumn').style.display = 'none';
            document.getElementById('selectthreecolumn').style.display = 'none';
            //where components
            document.getElementById('wheretable').style.display = 'none';
            document.getElementById('whereonecolumn').style.display = 'none';
            document.getElementById('wheretwocolumn').style.display = 'none';
            document.getElementById('wherethreecolumn').style.display = 'none';
            //count components
            document.getElementById('counttable').style.display = 'none';
            document.getElementById('countonecolumn').style.display = 'none';
            document.getElementById('counttwocolumn').style.display = 'none';
            document.getElementById('countthreecolumn').style.display = 'none';
            //union components
            document.getElementById('uniontable').style.display = 'none';
            document.getElementById('uniontwodocument').style.display = 'none';
            document.getElementById('uniontwodoconecolumn').style.display = 'none';
            document.getElementById('uniontwodoctwocolumn').style.display = 'none';
            document.getElementById('uniontwodocthreecolumn').style.display = 'none';
            document.getElementById('unionthreedocument').style.display = 'none';
            document.getElementById('unionthreedoconecolumn').style.display = 'none';
            document.getElementById('unionthreedoctwocolumn').style.display = 'none';
            document.getElementById('unionthreedocthreecolumn').style.display = 'none';
        }
    }//

    //Union 3 documents
    document.getElementById('union_3_3').addEventListener('click', union_3_3Change);
    async function union_3_3Change(e){
        e.preventDefault();

        //clear some input variable
        document.getElementById('querycol_1').value = '';
        document.getElementById('querycol_2').value = '';
        document.getElementById('querycol_3').value = '';
        document.getElementById('colname_1').value = '';
        document.getElementById('colname_2').value = '';
        document.getElementById('colname_3').value = '';

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var hostnametaskStr = document.getElementById('hostnamequery').value;
        var hostporttaskStr = document.getElementById('hostportquery').value;
        var usernametaskStr = document.getElementById('usernamequery').value;
        var passwordnametaskStr = document.getElementById('passwordnamequery').value;
        var serveurHeaderStr = document.getElementById("serveurheader").innerText;
        var deviceStr = document.getElementById('device').innerText;
        //xxxxxxxxxxxxxxx
        var databasequeryStr = document.getElementById('databasequery').value;
        var templatequeryStr = document.getElementById('templatequery').value;
        var serverqueryStr = document.getElementById('serverquery').value;
        var documentqueryStr = document.getElementById('documentquery').value;
        //search in local / remote database documents
        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            const dbbase = new PouchDB(databasequeryStr, {skip_setup: true,});
            const remotebase = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +databasequeryStr,{ skip_setup: true });
            PouchDB.plugin(PouchFind);
            // Initialise a sync with the remote server
            function sync() {
                var opts = {live: true,retry: true,continuous: true,attachments: true,};
                dbbase.sync(remotebase, opts);
            }
            if (remotebase) {
                sync();
            }
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                        fields: [
                            "taskdatabase",
                            "taskname",
                            "taskserver",
                            "documentname",
                        ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: databasequeryStr,
                        taskname: templatequeryStr,
                        taskserver: serverqueryStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    allDocument = [];
                    allDocument = docsCheckDocument;
                    taskkeyquery = [];
                    var valeur = [];
                    var docvaleur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                        docvaleur.push(docsCheckDocument[i].documentnumber);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                
                    /*initiate the autocomplete function on the "two document" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("unionthreedocthree_one"), docvaleur);
                    autocomplete(document.getElementById("unionthreedocthree_two"), docvaleur);
                    autocomplete(document.getElementById("unionthreedocthree_three"), docvaleur);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("unionthreedocthree_onename"), column);
                    autocomplete(document.getElementById("unionthreedocthree_twoname"), column);
                    autocomplete(document.getElementById("unionthreedocthree_threename"), column);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            const dbbase = new PouchDB(serveurHeaderStr, {skip_setup: true,});
            PouchDB.plugin(PouchFind);
            var docsCheckDocument = [];
            try {
                let result = await dbbase.createIndex({
                    index: {
                      fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "documentname",
                      ],
                    },
                });
                result = await dbbase.find({
                    selector: {
                        taskdatabase: serveurHeaderStr,
                        taskname: templatequeryStr,
                        taskserver: serveurHeaderStr,
                        documentname: documentqueryStr,
                    },
                });
                docsCheckDocument = result.docs;
                var resultatCheckDocument = docsCheckDocument.length;
                if (resultatCheckDocument === 0){
                    var pTextenodocument = "No Document available";
                    alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                }else{
                    // set arrays values to null
                    allDocument = [];
                    allDocument = docsCheckDocument;
                    taskkeyquery = [];
                    var valeur = [];
                    var docvaleur = [];
                    taskvaluequery = [];
                    //start some for loops to retrieve necessary data
                    for(let i=0; i<docsCheckDocument.length; i++){
                        taskkeyquery.push(docsCheckDocument[i].taskdata);
                        valeur.push(docsCheckDocument[i].taskdata);
                        docvaleur.push(docsCheckDocument[i].documentnumber);
                    }
                    //get value from json object in javascript
                    for(let j=0; j<valeur.length; j++){
                        taskvaluequery.push(Object.values(valeur[j]));
                    }
                
                    //get json array column
                    var tableData = taskkeyquery;
                    const column = Object.keys(tableData[0]);
                
                    /*initiate the autocomplete function on the "two document" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("unionthreedocthree_one"), docvaleur);
                    autocomplete(document.getElementById("unionthreedocthree_two"), docvaleur);
                    autocomplete(document.getElementById("unionthreedocthree_three"), docvaleur);
                    /*initiate the autocomplete function on the "onecolumn" element, and pass along the column array as possible autocomplete values:*/
                    autocomplete(document.getElementById("unionthreedocthree_onename"), column);
                    autocomplete(document.getElementById("unionthreedocthree_twoname"), column);
                    autocomplete(document.getElementById("unionthreedocthree_threename"), column);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //where components
        document.getElementById('wheretable').style.display = 'none';
        document.getElementById('whereonecolumn').style.display = 'none';
        document.getElementById('wheretwocolumn').style.display = 'none';
        document.getElementById('wherethreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'block';
    }
    //confirm two document click
    document.getElementById('confirmunionthreedocthreecolumn').addEventListener('click', confirmunionthreedocthreecolumnChange);
    async function confirmunionthreedocthreecolumnChange(e){
        e.preventDefault();

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";

        var unionthreedocthree_oneStr = document.getElementById('unionthreedocthree_one').value;
        var unionthreedocthree_twoStr = document.getElementById('unionthreedocthree_two').value;
        var unionthreedocthree_threeStr = document.getElementById('unionthreedocthree_three').value;

        var unionthreedocthree_onenameStr = document.getElementById('unionthreedocthree_onename').value;
        var unionthreedocthree_twonameStr = document.getElementById('unionthreedocthree_twoname').value;
        var unionthreedocthree_threenameStr = document.getElementById('unionthreedocthree_threename').value;

        var documentqueryStr = document.getElementById('documentquery').value; //document name
        var writequeryStr = document.getElementById('writequery');

        if(!unionthreedocthree_oneStr){
            var pTextenocolumn = "Please Enter First Doc name or number";
            alertModal(elementOutId,elementDropId,pTextenocolumn,buttonHandler);
        }else if(!unionthreedocthree_twoStr){
            var pTextenovalue = "Please enter Second Doc name or number";
            alertModal(elementOutId,elementDropId,pTextenovalue,buttonHandler);
        }else if(!unionthreedocthree_threeStr){
            var pTextenovalue = "Please enter Third Doc name or number";
            alertModal(elementOutId,elementDropId,pTextenovalue,buttonHandler);
        }else if(!unionthreedocthree_onenameStr){
            var pTextenocolumn_1 = "Please enter column 1 name";
            alertModal(elementOutId,elementDropId,pTextenocolumn_1,buttonHandler);
        }else if(!unionthreedocthree_twonameStr){
            var pTextenocolumn_2 = "Please enter column 2 name";
            alertModal(elementOutId,elementDropId,pTextenocolumn_2,buttonHandler);
        }else if(!unionthreedocthree_threenameStr){
            var pTextenocolumn_3 = "Please enter column 3 name";
            alertModal(elementOutId,elementDropId,pTextenocolumn_3,buttonHandler);
        }
        else{
            writequeryStr.value = 'SELECT '+unionthreedocthree_oneStr+' FROM '+documentqueryStr+
            ' UNION SELECT '+unionthreedocthree_twoStr+' FROM '+documentqueryStr+
            ' UNION SELECT '+unionthreedocthree_threeStr+' FROM '+documentqueryStr+
            ' WHERE column 1 = '+unionthreedocthree_onenameStr+
            ' AND column 2 = '+unionthreedocthree_twonameStr+
            ' AND column 3 = '+unionthreedocthree_threenameStr+';';
            writequeryStr.readOnly = true;
            var querycol_1Str = document.getElementById('querycol_1');
            querycol_1Str.value = unionthreedocthree_oneStr;
            var querycol_2Str = document.getElementById('querycol_2');
            querycol_2Str.value = unionthreedocthree_twoStr;
            var querycol_3Str = document.getElementById('querycol_3');
            querycol_3Str.value = unionthreedocthree_threeStr;
            var colname_1Str = document.getElementById('colname_1');
            colname_1Str.value = unionthreedocthree_onenameStr;
            var colname_2Str = document.getElementById('colname_2');
            colname_2Str.value = unionthreedocthree_twonameStr;
            var colname_3Str = document.getElementById('colname_3');
            colname_3Str.value = unionthreedocthree_threenameStr;

            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('documentqueries').style.display = 'block';
            document.getElementById('querydocumentqueries').style.display = 'block';
            //xxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('connectqueries').style.display = 'none';
            document.getElementById('listviewqueries').style.display = 'none';
            document.getElementById('viewdisplayqueries').style.display = 'none';
            document.getElementById('viewdocqueries').style.display = 'none';
            document.getElementById('editorqueries').style.display = 'block';
            document.getElementById('dynamictablequeries').style.display = 'none';
            //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
            document.getElementById('outputquery').style.display = 'block';
            document.getElementById('executerquery').style.display = 'block';
            //select components
            document.getElementById('selecttable').style.display = 'none';
            document.getElementById('selectonecolumn').style.display = 'none';
            document.getElementById('selecttwocolumn').style.display = 'none';
            document.getElementById('selectthreecolumn').style.display = 'none';
            //where components
            document.getElementById('wheretable').style.display = 'none';
            document.getElementById('whereonecolumn').style.display = 'none';
            document.getElementById('wheretwocolumn').style.display = 'none';
            document.getElementById('wherethreecolumn').style.display = 'none';
            //count components
            document.getElementById('counttable').style.display = 'none';
            document.getElementById('countonecolumn').style.display = 'none';
            document.getElementById('counttwocolumn').style.display = 'none';
            document.getElementById('countthreecolumn').style.display = 'none';
            //union components
            document.getElementById('uniontable').style.display = 'none';
            document.getElementById('uniontwodocument').style.display = 'none';
            document.getElementById('uniontwodoconecolumn').style.display = 'none';
            document.getElementById('uniontwodoctwocolumn').style.display = 'none';
            document.getElementById('uniontwodocthreecolumn').style.display = 'none';
            document.getElementById('unionthreedocument').style.display = 'none';
            document.getElementById('unionthreedoconecolumn').style.display = 'none';
            document.getElementById('unionthreedoctwocolumn').style.display = 'none';
            document.getElementById('unionthreedocthreecolumn').style.display = 'none';
        }
    }

    //QUERIES RESULTS
    //global arrays variable to edit
    var taskkeyquery = [];
    var taskvaluequery = [];
    var documentData = []; //use to fecth taskdata inside document data
    var allDocument = [];
    document.getElementById('executequery').addEventListener('click', executequeryChange);
    async function executequeryChange(e){
        e.preventDefault();

        //alert
        var elementOutId = "modaloutalertqueries";
        var elementDropId = "backdropoutalertqueries";
        var buttonHandler = "ouiHandlerAlertQueriesBtn";
        
        var hostnametaskStr = document.getElementById('hostnamequery').value;
        var hostporttaskStr = document.getElementById('hostportquery').value;
        var usernametaskStr = document.getElementById('usernamequery').value;
        var passwordnametaskStr = document.getElementById('passwordnamequery').value;
        var serveurHeaderStr = document.getElementById('serveurheader').innerText;
        var deviceStr = document.getElementById('device').innerText;
        //xxxxxxxxxxxxxxx
        var databasequeryStr = document.getElementById('databasequery').value;
        var templatequeryStr = document.getElementById('templatequery').value;
        var serverqueryStr = document.getElementById('serverquery').value;
        var documentqueryStr = document.getElementById('documentquery').value;
        var selectedtqueryStr = document.getElementById('selectedtquery').value;
        if(!selectedtqueryStr){
            var pTexteselectquery = "Please select your query.";
            alertModal(elementOutId,elementDropId,pTexteselectquery,buttonHandler);
        }else{
            //search in local / remote database documents
            if((deviceStr === '' && serveurHeaderStr === 'local') || 
            (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
                const dbbase = new PouchDB(databasequeryStr, {skip_setup: true,});
                const remotebase = new PouchDB("http://" +usernametaskStr +":" +passwordnametaskStr +"@" +hostnametaskStr +":" +hostporttaskStr +"/" +databasequeryStr,{ skip_setup: true });
                PouchDB.plugin(PouchFind);
                // Initialise a sync with the remote server
                function sync() {
                    var opts = {live: true,retry: true,continuous: true,attachments: true,};
                    dbbase.sync(remotebase, opts);
                }
                if (remotebase) {
                    sync();
                }
                var docsCheckDocument = [];
                try {
                    let result = await dbbase.createIndex({
                        index: {
                            fields: [
                                "taskdatabase",
                                "taskname",
                                "taskserver",
                                "documentname",
                            ],
                        },
                    });
                    result = await dbbase.find({
                        selector: {
                            taskdatabase: databasequeryStr,
                            taskname: templatequeryStr,
                            taskserver: serverqueryStr,
                            documentname: documentqueryStr,
                        },
                    });
                    docsCheckDocument = result.docs;
                    var resultatCheckDocument = docsCheckDocument.length;
                    if (resultatCheckDocument === 0){
                        var pTextenodocument = "No Document available";
                        alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                    }else{
                        taskkeyquery = [];
                        var valeur = [];
                        taskvaluequery = [];
                        documentData = [];
                        allDocument = [];
                        allDocument = docsCheckDocument;
                        //start some for loops to retrieve necessary data
                        for(let i=0; i<docsCheckDocument.length; i++){
                            taskkeyquery.push(docsCheckDocument[i].taskdata);
                            valeur.push(docsCheckDocument[i].taskdata);
                            documentData.push(docsCheckDocument[i].taskdata);
                        }
                        //get value from json object in javascript
                        for(let j=0; j<docsCheckDocument.length; j++){
                            taskvaluequery.push(Object.values(valeur[j]));
                        }

                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('documentqueries').style.display = 'block';
                        document.getElementById('querydocumentqueries').style.display = 'block';
                        //xxxxxxxxx function query results xxxxxxxxx
                        resultQueryTableUIWithDocs(docsCheckDocument);
                        //xxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('connectqueries').style.display = 'none';
                        document.getElementById('listviewqueries').style.display = 'none';
                        document.getElementById('viewdisplayqueries').style.display = 'none';
                        document.getElementById('viewdocqueries').style.display = 'none';
                        document.getElementById('editorqueries').style.display = 'none';
                        document.getElementById('dynamictablequeries').style.display = 'block';
                        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
                        document.getElementById('outputquery').style.display = 'none';
                        document.getElementById('executerquery').style.display = 'none';
                        //select components
                        document.getElementById('selecttable').style.display = 'none';
                        document.getElementById('selectonecolumn').style.display = 'none';
                        document.getElementById('selecttwocolumn').style.display = 'none';
                        document.getElementById('selectthreecolumn').style.display = 'none';
                        //count components
                        document.getElementById('counttable').style.display = 'none';
                        document.getElementById('countonecolumn').style.display = 'none';
                        document.getElementById('counttwocolumn').style.display = 'none';
                        document.getElementById('countthreecolumn').style.display = 'none';
                        //union components
                        document.getElementById('uniontable').style.display = 'none';
                        document.getElementById('uniontwodocument').style.display = 'none';
                        document.getElementById('uniontwodoconecolumn').style.display = 'none';
                        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
                        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
                        document.getElementById('unionthreedocument').style.display = 'none';
                        document.getElementById('unionthreedoconecolumn').style.display = 'none';
                        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
                        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
                    }
                } catch (error) {
                    var pTexteerreurconnection = "An Error has Occurred";
                    alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                }
            };

            if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
                const dbbase = new PouchDB(serveurHeaderStr, {skip_setup: true,});
                PouchDB.plugin(PouchFind);
                var docsCheckDocument = [];
                try {
                    let result = await dbbase.createIndex({
                        index: {
                            fields: [
                                "taskdatabase",
                                "taskname",
                                "taskserver",
                                "documentname",
                            ],
                        },
                    });
                    result = await dbbase.find({
                        selector: {
                            taskdatabase: serveurHeaderStr,
                            taskname: templatequeryStr,
                            taskserver: serveurHeaderStr,
                            documentname: documentqueryStr,
                        },
                    });
                    docsCheckDocument = result.docs;
                    var resultatCheckDocument = docsCheckDocument.length;
                    if (resultatCheckDocument === 0){
                        var pTextenodocument = "No Document available";
                        alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
                    }else{
                        taskkeyquery = [];
                        var valeur = [];
                        taskvaluequery = [];
                        documentData = [];
                        allDocument = [];
                        allDocument = docsCheckDocument;
                        //start some for loops to retrieve necessary data
                        for(let i=0; i<docsCheckDocument.length; i++){
                            taskkeyquery.push(docsCheckDocument[i].taskdata);
                            valeur.push(docsCheckDocument[i].taskdata);
                            documentData.push(docsCheckDocument[i].taskdata);
                        }
                        //get value from json object in javascript
                        for(let j=0; j<docsCheckDocument.length; j++){
                            taskvaluequery.push(Object.values(valeur[j]));
                        }
                        /*console.log(taskkeyquery)
                        console.log(valeur)
                        console.log(taskvaluequery)
                        console.log(documentData)
                        console.log(allDocument)*/
                        //console.log()
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('documentqueries').style.display = 'block';
                        document.getElementById('querydocumentqueries').style.display = 'block';
                        //xxxxxxxxx function query results xxxxxxxxx
                        resultQueryTableUIWithDocs(docsCheckDocument);
                        //xxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('connectqueries').style.display = 'none';
                        document.getElementById('listviewqueries').style.display = 'none';
                        document.getElementById('viewdisplayqueries').style.display = 'none';
                        document.getElementById('viewdocqueries').style.display = 'none';
                        document.getElementById('editorqueries').style.display = 'none';
                        document.getElementById('dynamictablequeries').style.display = 'block';
                        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
                        document.getElementById('outputquery').style.display = 'none';
                        document.getElementById('executerquery').style.display = 'none';
                        //select components
                        document.getElementById('selecttable').style.display = 'none';
                        document.getElementById('selectonecolumn').style.display = 'none';
                        document.getElementById('selecttwocolumn').style.display = 'none';
                        document.getElementById('selectthreecolumn').style.display = 'none';
                        //count components
                        document.getElementById('counttable').style.display = 'none';
                        document.getElementById('countonecolumn').style.display = 'none';
                        document.getElementById('counttwocolumn').style.display = 'none';
                        document.getElementById('countthreecolumn').style.display = 'none';
                        //union components
                        document.getElementById('uniontable').style.display = 'none';
                        document.getElementById('uniontwodocument').style.display = 'none';
                        document.getElementById('uniontwodoconecolumn').style.display = 'none';
                        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
                        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
                        document.getElementById('unionthreedocument').style.display = 'none';
                        document.getElementById('unionthreedoconecolumn').style.display = 'none';
                        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
                        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
                    }
                } catch (error) {
                    var pTexteerreurconnection = "An Error has Occurred";
                    alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler); 
                }
            };
        }
    }
    // Adds the document to the HTML First table.
    // If the document already exists in the table, it will be updated.
    async function resultQueryDocToToTable(doc){

        var dynamicviewqueryBody = document.getElementById("dynamicviewquery");
        // Remove all child nodes from the table
        while (dynamicviewqueryBody.hasChildNodes()) {
            dynamicviewqueryBody.removeChild(dynamicviewqueryBody.childNodes[0]);
        }

        //set input selector variables to implement query results
        var selectedtqueryStr = document.getElementById('selectedtquery').value;
        var querycol = document.getElementById('querycol_1').value;
        var querycol_2 = document.getElementById('querycol_2').value;
        var querycol_3 = document.getElementById('querycol_3').value;
        //column names
        var colname_1 = document.getElementById('colname_1').value;
        var colname_2 = document.getElementById('colname_2').value;
        var colname_3 = document.getElementById('colname_3').value;
        //xxxxxxxx set header variables xxxxxxx
        //get json array column
        var tableData = [doc.taskdata];
        
        //const column = Object.keys(tableData[0]);
        const column = queryHeader;
        //xxxxxx multiple rows dynamically varaibles xxxxxxx
        var tableRows = taskvaluequery;
        //Create an HTML table using JavaScript
        // get the reference for the header
        var dynamicviewquery = document.getElementById("dynamicviewquery");
        // Remove any existing input field.
        while (dynamicviewquery.hasChildNodes()) {
            dynamicviewquery.removeChild(dynamicviewquery.childNodes[0]);
        }
        
        //1. Result if query = select * from table
        if(selectedtqueryStr === 'select' && querycol === '' && querycol_2 === '' && querycol_3 === '' && 
        colname_1 === '' && colname_2 === '' && colname_3 === ''){
            //display number of document queried
            var array = tableRows;
            var tableLength = array.length;
            var documentnumber = document.getElementById('dynamicviewdocumentquery');
            documentnumber.innerText = 'Query Results: '+tableLength * column.length;

            //1.create table
            var userTable = document.createElement('table');
            userTable.setAttribute('id', 'usertable');
            userTable.setAttribute('class', 'thin_table_config');
            dynamicviewquery.appendChild(userTable);

            //2.add header to the table
            var tableHeadRow = userTable.insertRow(-1);

            var tableHeadArray = column;
            for(let i=0; i<tableHeadArray.length; i++){
                var th = document.createElement('th');
                th.innerHTML = tableHeadArray[i];
                tableHeadRow.appendChild(th);
            }

            //3.add rows dynamically to the table
            var fetch = document.getElementById('usertable');
            //var array = tableRows;
            for(var j=0; j<array.length; j++){
                var newRow = fetch.insertRow(fetch.length);
                for(var k=0; k<array[j].length; k++){
                  var cell = newRow.insertCell(k);
                  cell.innerHTML =  array[j][k];
                }
            }
        };
        //1. Result if query = select column 1 specified from table
        if(selectedtqueryStr === 'select' && querycol !== '' && querycol_2 === '' && querycol_3 === '' && 
        colname_1 === '' && colname_2 === '' && colname_3 === ''){
            var index = column.indexOf(querycol);
            var propriete = '';
            propriete = column[index];
            /*console.log(index)
            console.log(propriete)
            console.log(documentData)*/
            //retrieve data array based on 1 key
            var tableDataArray = [];
            /*var key = querycol;
            console.log(key)
            console.log(documentData.length)*/
            for(let i=0; i<documentData.length; i++){
                /*if(!propriete[i].hasOwnProperty(index)){
                    continue;
                }*/
                tableDataArray.push(documentData[i][index]);
            }

            //console.log(tableDataArray);


            //display number of document queried
            var tableLength = tableDataArray.length;
            var documentnumber = document.getElementById('dynamicviewdocumentquery');
            documentnumber.innerText = 'Query Results: '+tableLength;

            //1.create dynamic table
            var userTable = document.createElement('table');
            var tr = document.createElement('tr');
            userTable.setAttribute('id', 'usertable');
            userTable.setAttribute('class', 'thin_table_config');
            //dynamicviewquery.appendChild(userTable);
            //2.add header to the table
            var th = document.createElement('th'); //column
            var text = document.createTextNode(propriete); //cell
            th.appendChild(text);
            tr.appendChild(th);
            userTable.appendChild(tr);
            //3.add rows dynamically to the table
            for(let i=0; i<tableDataArray.length; i++){
                var tr = document.createElement('tr');
                var td1 = document.createElement('td');
                var text1 = document.createTextNode(tableDataArray[i]);
                td1.appendChild(text1);
                tr.appendChild(td1);
                userTable.appendChild(tr);
            }
            dynamicviewquery.appendChild(userTable);
        }
        //1. Result if query = select column 2 specified from table
        if(selectedtqueryStr === 'select' && querycol !== '' && querycol_2 !== '' && querycol_3 === '' && 
        colname_1 === '' && colname_2 === '' && colname_3 === ''){
            //retrieve data array based on querycol
            var index_1 = column.indexOf(querycol);
            var index_2 = column.indexOf(querycol_2);

            var tableDataArray_1 = [];
            for(let i=0; i<documentData.length; i++){
                tableDataArray_1.push(documentData[i][index_1]);
            }
            //retrieve data array based on querycol_2
            var tableDataArray_2 = [];
            for(let i=0; i<documentData.length; i++){
                tableDataArray_2.push(documentData[i][index_2]);
            }
            //concat the two header
            var headerconcat = [querycol,querycol_2];

            //display number of document queried
            var tableLength = (tableDataArray_1.length + tableDataArray_2.length);
            var documentnumber = document.getElementById('dynamicviewdocumentquery');
            documentnumber.innerText = 'Query Results: '+tableLength;

            //1.create dynamic table
            var userTable = document.createElement('table');
            var tr = document.createElement('tr');
            userTable.setAttribute('id', 'usertable');
            userTable.setAttribute('class', 'thin_table_config');
            //2.add header to the table
            for(let j=0; j<headerconcat.length; j++){
                var th = document.createElement('th'); //column
                var text = document.createTextNode(headerconcat[j]); //cell
                th.appendChild(text);
                tr.appendChild(th);
            }
            userTable.appendChild(tr);
            //3.add rows dynamically to the table 
            //first column
            for(let i=0; i<tableDataArray_1.length; i++){
                var tr = document.createElement('tr');
                var td1 = document.createElement('td');
                var text1 = document.createTextNode(tableDataArray_1[i]);
                td1.appendChild(text1);
                tr.appendChild(td1);
                var td2 = document.createElement('td');
                var text2 = document.createTextNode(tableDataArray_2[i]);
                td2.appendChild(text2);
                tr.appendChild(td2);
                
                userTable.appendChild(tr);
            }
            dynamicviewquery.appendChild(userTable);
        }
        //1. Result if query = select column 3 specified from table
        if(selectedtqueryStr === 'select' && querycol !== '' && querycol_2 !== '' && querycol_3 !== '' && 
        colname_1 === '' && colname_2 === '' && colname_3 === ''){
            //retrieve data array based on querycol
            var index_1 = column.indexOf(querycol);
            var index_2 = column.indexOf(querycol_2);
            var index_3 = column.indexOf(querycol_3);

            var tableDataArray_1 = [];
            for(let i=0; i<documentData.length; i++){
                tableDataArray_1.push(documentData[i][index_1]);
            }
            
            //retrieve data array based on querycol_2
            var tableDataArray_2 = [];
            for(let i=0; i<documentData.length; i++){
                tableDataArray_2.push(documentData[i][index_2]);
            }
            
            //retrieve data array based on querycol_2
            var tableDataArray_3 = [];
            for(let i=0; i<documentData.length; i++){
                tableDataArray_3.push(documentData[i][index_3]);
            }

            //concat the three headers
            var headerconcat = [querycol,querycol_2,querycol_3];

            //display number of document queried
            var tableLength = (tableDataArray_1.length + tableDataArray_2.length + tableDataArray_3.length);
            var documentnumber = document.getElementById('dynamicviewdocumentquery');
            documentnumber.innerText = 'Query Results: '+tableLength;

            //1.create dynamic table
            var userTable = document.createElement('table');
            var tr = document.createElement('tr');
            userTable.setAttribute('id', 'usertable');
            userTable.setAttribute('class', 'thin_table_config');
            //2.add header to the table
            for(let j=0; j<headerconcat.length; j++){
                var th = document.createElement('th'); //column
                var text = document.createTextNode(headerconcat[j]); //cell
                th.appendChild(text);
                tr.appendChild(th);
            }
            userTable.appendChild(tr);
            //3.add rows dynamically to the table 
            for(let i=0; i<tableDataArray_1.length; i++){
                var tr = document.createElement('tr');
                var td1 = document.createElement('td');
                var text1 = document.createTextNode(tableDataArray_1[i]);
                td1.appendChild(text1);
                tr.appendChild(td1);
                var td2 = document.createElement('td');
                var text2 = document.createTextNode(tableDataArray_2[i]);
                td2.appendChild(text2);
                tr.appendChild(td2);
                var td3 = document.createElement('td');
                var text3 = document.createTextNode(tableDataArray_3[i]);
                td3.appendChild(text3);
                tr.appendChild(td3);
                
                userTable.appendChild(tr);
            }
            dynamicviewquery.appendChild(userTable);
        }

        //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX SELECT * FROM table WHERE ? ? XXXXXXXXXXXXXXXXXXXX
        //1. Result if query = select * from table where one column ? value
        if(selectedtqueryStr === 'where' && querycol !== '' && querycol_2 === '' && querycol_3 === ''&& 
        colname_1 === '' && colname_2 === '' && colname_3 === '' ){
            var operator_oneStr = document.getElementById('operator_one').value;
            var value_oneStr = document.getElementById('value_one').value
            //retrieve data array based on querycol
            var index_1 = column.indexOf(querycol);

            try {
                //set up query based on operator and value
                //operator =
                if(operator_oneStr === '='){
                    var resultat = [];
                    //retrieve data array based on 1 key
                    var tableDataArray = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(value_oneStr);
                    if(checkNumber === true){
                        var threshold = parseFloat(value_oneStr);
                        resultat = findEqual(tableDataArray,threshold);
                    }else{
                        var threshold = value_oneStr;
                        resultat = findString(tableDataArray,threshold);
                    }

                    //display number of document queried
                    var tableLength = resultat.length;
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    var th = document.createElement('th'); //column
                    var text = document.createTextNode(querycol); //cell
                    th.appendChild(text);
                    tr.appendChild(th);
                    userTable.appendChild(tr);
                    th.appendChild(text);
                    tr.appendChild(th);
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<resultat.length; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode(resultat[i]);
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        userTable.appendChild(tr);
                    }
                    dynamicviewquery.appendChild(userTable);
                }

                //operator <
                if(operator_oneStr === '<'){
                    var resultat = [];
                    //retrieve data array based on 1 key
                    var tableDataArray = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(value_oneStr);
                    if(checkNumber === true){
                        var threshold = parseFloat(value_oneStr);
                        resultat = findSmaller(tableDataArray,threshold);
                    }else{
                        var threshold = value_oneStr;
                        resultat = findString(tableDataArray,threshold);
                    }

                    //display number of document queried
                    var tableLength = resultat.length;
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    var th = document.createElement('th'); //column
                    var text = document.createTextNode(querycol); //cell
                    th.appendChild(text);
                    tr.appendChild(th);
                    userTable.appendChild(tr);
                    th.appendChild(text);
                    tr.appendChild(th);
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<resultat.length; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode(resultat[i]);
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        userTable.appendChild(tr);
                    }
                    dynamicviewquery.appendChild(userTable);
                }

                //operator >
                if(operator_oneStr === '>'){
                    var resultat = [];
                    //retrieve data array based on 1 key
                    var tableDataArray = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(value_oneStr);
                    if(checkNumber === true){
                        var threshold = parseFloat(value_oneStr);
                        resultat = findGreater(tableDataArray,threshold);
                    }else{
                        var threshold = value_oneStr;
                        resultat = findString(tableDataArray,threshold);
                    }

                    //display number of document queried
                    var tableLength = resultat.length;
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    var th = document.createElement('th'); //column
                    var text = document.createTextNode(querycol); //cell
                    th.appendChild(text);
                    tr.appendChild(th);
                    userTable.appendChild(tr);
                    th.appendChild(text);
                    tr.appendChild(th);
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<resultat.length; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode(resultat[i]);
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        userTable.appendChild(tr);
                    }
                    dynamicviewquery.appendChild(userTable);
                }
            } catch (error) {
                
            }
        }

        //1. Result if query = where column 2 specified from table
        if(selectedtqueryStr === 'where' && querycol !== '' && querycol_2 !== '' && querycol_3 === '' && 
        colname_1 === '' && colname_2 === '' && colname_3 === ''){
            var twooperator_oneStr = document.getElementById('twooperator_one').value;
            var twovalue_oneStr = document.getElementById('twovalue_one').value;
            var twooperator_twoStr = document.getElementById('twooperator_two').value;
            var twovalue_twoStr = document.getElementById('twovalue_two').value;

            //retrieve data array based on querycol
            var index_1 = column.indexOf(querycol);
            var index_2 = column.indexOf(querycol_2);

            try {
                //set up query based on operator and value
                //operator 1 = and operator 2 =
                if(twooperator_oneStr === '=' && twooperator_twoStr === '='){
                    var resultat_1 = [];
                    var resultat_2 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twovalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(twovalue_oneStr);
                        resultat_1 = findEqual(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = value_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twovalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(twovalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = twovalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //concat the two header
                    var headerconcat = [querycol,querycol_2];

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    //resultat_1 = resultat_2 length
                    if(resultat_1.length === resultat_2.length){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    //resultat_1 < resultat_2 length
                    if(resultat_1.length < resultat_2.length){
                        for(let i=0; i<resultat_2.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    //resultat_1 > resultat_2 length
                    if(resultat_1.length > resultat_2.length){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    
                    dynamicviewquery.appendChild(userTable);
                }   

                //operator 1 = and operator 2 <
                if(twooperator_oneStr === '=' && twooperator_twoStr === '<'){
                    var resultat_1 = [];
                    var resultat_2 = [];
                
                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twovalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(twovalue_oneStr);
                        resultat_1 = findEqual(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = value_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twovalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(twovalue_twoStr);
                        resultat_2 = findSmaller(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = twovalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //concat the two header
                    var headerconcat = [querycol,querycol_2];

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table based on resultat_1 and resultat_2 length
                    //resultat_1 = resultat_2 length
                    if(resultat_1.length === resultat_2.length){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    //resultat_1 < resultat_2 length
                    if(resultat_1.length < resultat_2.length){
                        for(let i=0; i<resultat_2.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    //resultat_1 > resultat_2 length
                    if(resultat_1.length > resultat_2.length){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    
                    dynamicviewquery.appendChild(userTable);
                }

                //operator 1 = and operator 2 >
                if(twooperator_oneStr === '=' && twooperator_twoStr === '>'){
                    var resultat_1 = [];
                    var resultat_2 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twovalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(twovalue_oneStr);
                        resultat_1 = findEqual(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = value_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    var key_2 = querycol_2;
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twovalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(twovalue_twoStr);
                        resultat_2 = findGreater(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = twovalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //concat the two header
                    var headerconcat = [querycol,querycol_2];

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table based on resultat_1 and resultat_2 length
                    //resultat_1 = resultat_2 length
                    if(resultat_1.length === resultat_2.length){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    //resultat_1 < resultat_2 length
                    if(resultat_1.length < resultat_2.length){
                        for(let i=0; i<resultat_2.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    //resultat_1 > resultat_2 length
                    if(resultat_1.length > resultat_2.length){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }

                    dynamicviewquery.appendChild(userTable);
                }

                //operator 1 < and operator 2 =
                if(twooperator_oneStr === '<' && twooperator_twoStr === '='){
                    var resultat_1 = [];
                    var resultat_2 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twovalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(twovalue_oneStr);
                        resultat_1 = findSmaller(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = value_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twovalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(twovalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = twovalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //concat the two header
                    var headerconcat = [querycol,querycol_2];

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table based on resultat_1 and resultat_2 length
                    //resultat_1 = resultat_2 length
                    if(resultat_1.length === resultat_2.length){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    //resultat_1 < resultat_2 length
                    if(resultat_1.length > resultat_2.length){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    //resultat_1 > resultat_2 length
                    if(resultat_1.length > resultat_2.length){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    
                    dynamicviewquery.appendChild(userTable);
                }

                //operator 1 < and operator 2 <
                if(twooperator_oneStr === '<' && twooperator_twoStr === '<'){
                    var resultat_1 = [];
                    var resultat_2 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twovalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(twovalue_oneStr);
                        resultat_1 = findSmaller(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = value_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    var key_2 = querycol_2;
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twovalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(twovalue_twoStr);
                        resultat_2 = findSmaller(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = twovalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //concat the two header
                    var headerconcat = [querycol,querycol_2];

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table based on resultat_1 and resultat_2 length
                    //resultat_1 = resultat_2 length
                    if(resultat_1.length === resultat_2.length){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    //resultat_1 < resultat_2 length
                    if(resultat_1.length < resultat_2.length){
                        for(let i=0; i<resultat_2.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    //resultat_1 > resultat_2 length
                    if(resultat_1.length > resultat_2.length){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    
                    dynamicviewquery.appendChild(userTable);
                }

                //operator 1 < and operator 2 >
                if(twooperator_oneStr === '<' && twooperator_twoStr === '>'){
                    var resultat_1 = [];
                    var resultat_2 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twovalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(twovalue_oneStr);
                        resultat_1 = findSmaller(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = value_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twovalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(twovalue_twoStr);
                        resultat_2 = findGreater(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = twovalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //concat the two header
                    var headerconcat = [querycol,querycol_2];

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table based on resultat_1 and resultat_2 length
                    //resultat_1 = resultat_2 length
                    if(resultat_1.length === resultat_2.length){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    //resultat_1 < resultat_2 length
                    if(resultat_1.length < resultat_2.length){
                        for(let i=0; i<resultat_2.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    //resultat_1 > resultat_2 length
                    if(resultat_1.length > resultat_2.length){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    
                    dynamicviewquery.appendChild(userTable);
                }

                //operator 1 > and operator 2 =
                if(twooperator_oneStr === '>' && twooperator_twoStr === '='){
                    var resultat_1 = [];
                    var resultat_2 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twovalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(twovalue_oneStr);
                        resultat_1 = findGreater(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = value_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twovalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(twovalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = twovalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //concat the two header
                    var headerconcat = [querycol,querycol_2];

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table based on resultat_1 and resultat_2 length
                    //resultat_1 = resultat_2 length
                    if(resultat_1.length === resultat_2.length){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    //resultat_1 > resultat_2 length
                    if(resultat_1.length < resultat_2.length){
                        for(let i=0; i<resultat_2.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    //resultat_1 > resultat_2 length
                    if(resultat_1.length > resultat_2.length){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    
                    dynamicviewquery.appendChild(userTable);
                }

                //operator 1 > and operator 2 <
                if(twooperator_oneStr === '>' && twooperator_twoStr === '<'){
                    var resultat_1 = [];
                    var resultat_2 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twovalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(twovalue_oneStr);
                        resultat_1 = findGreater(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = value_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twovalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(twovalue_twoStr);
                        resultat_2 = findSmaller(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = twovalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //concat the two header
                    var headerconcat = [querycol,querycol_2];

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table based on resultat_1 and resultat_2 length
                    //resultat_1 = resultat_2 length
                    if(resultat_1.length === resultat_2.length){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    //resultat_1 < resultat_2 length
                    if(resultat_1.length < resultat_2.length){
                        for(let i=0; i<resultat_2.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    //resultat_1 > resultat_2 length
                    if(resultat_1.length > resultat_2.length){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    
                    dynamicviewquery.appendChild(userTable);
                }

                //operator 1 > and operator 2 =
                if(twooperator_oneStr === '>' && twooperator_twoStr === '>'){
                    var resultat_1 = [];
                    var resultat_2 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twovalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(twovalue_oneStr);
                        resultat_1 = findGreater(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = value_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twovalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(twovalue_twoStr);
                        resultat_2 = findGreater(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = twovalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //concat the two header
                    var headerconcat = [querycol,querycol_2];

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table based on resultat_1 and resultat_2 length
                    //resultat_1 = resultat_2 length
                    if(resultat_1.length === resultat_2.length){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    //resultat_1 < resultat_2 length
                    if(resultat_1.length < resultat_2.length){
                        for(let i=0; i<resultat_2.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    //resultat_1 > resultat_2 length
                    if(resultat_1.length > resultat_2.length){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            userTable.appendChild(tr);
                        }
                    }
                    
                    dynamicviewquery.appendChild(userTable);
                }

            } catch (error) {
                
            }
        }

        //1. Result if query = where column 3 specified from table
        if(selectedtqueryStr === 'where' && querycol !== '' && querycol_2 !== '' && querycol_3 !== '' && 
        colname_1 === '' && colname_2 === '' && colname_3 === ''){
            var threeoperator_oneStr = document.getElementById('threeoperator_one').value;
            var threevalue_oneStr = document.getElementById('threevalue_one').value;
            var threeoperator_twoStr = document.getElementById('threeoperator_two').value;
            var threevalue_twoStr = document.getElementById('threevalue_two').value;
            var threeoperator_threeStr = document.getElementById('threeoperator_three').value;
            var threevalue_threeStr = document.getElementById('threevalue_three').value;

            //retrieve data array based on querycol
            var index_1 = column.indexOf(querycol);
            var index_2 = column.indexOf(querycol_2);
            var index_3 = column.indexOf(querycol_3);

            try {
                //set up query based on operator and value 
                //--------Operator 1---------------------------
                //1.operator 1 = and operator 2 = and operator 3 =
                if(threeoperator_oneStr === '=' && threeoperator_twoStr === '=' && threeoperator_threeStr === '='){
                    var resultat_1 = [];
                    var resultat_2 = [];
                    var resultat_3 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(threevalue_oneStr);
                        resultat_1 = findEqual(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = threevalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(threevalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = threevalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //retrieve data array based on 1 key 3
                    var tableDataArray_3 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_3.push(parseFloat(documentData[i][index_3]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_threeStr);
                    if(checkNumber === true){
                        var threshold_3 = parseFloat(threevalue_threeStr);
                        resultat_3 = findEqual(tableDataArray_3,threshold_3);
                    }else{
                        var threshold_3 = threevalue_threeStr;
                        resultat_3 = findString(tableDataArray_3,threshold_3);
                    }

                    //concat the two header
                    var headerconcat = [querycol,querycol_2,querycol_3];

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length + resultat_3.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //compare to find the largest among three numbers
                    var greatest = greaterOne(resultat_1.length, resultat_2.length, resultat_3.length);
                    
                    //3.add rows dynamically to the table
                    if(greatest === 'equal'){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val1'){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val2'){
                        for(let i=0; i<resultat_2.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val3'){
                        for(let i=0; i<resultat_3.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    
                    dynamicviewquery.appendChild(userTable);
                }//

                //1.operator 1 = and operator 2 = and operator 3 <
                if(threeoperator_oneStr === '=' && threeoperator_twoStr === '=' && threeoperator_threeStr === '<'){
                    var resultat_1 = [];
                    var resultat_2 = [];
                    var resultat_3 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(threevalue_oneStr);
                        resultat_1 = findEqual(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = threevalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(threevalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = threevalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //retrieve data array based on 1 key 3
                    var tableDataArray_3 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_3.push(parseFloat(documentData[i][index_3]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_threeStr);
                    if(checkNumber === true){
                        var threshold_3 = parseFloat(threevalue_threeStr);
                        resultat_3 = findSmaller(tableDataArray_3,threshold_3);
                    }else{
                        var threshold_3 = threevalue_threeStr;
                        resultat_3 = findString(tableDataArray_3,threshold_3);
                    }

                    //concat the two header
                    var headerconcat = [querycol,querycol_2,querycol_3];

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length + resultat_3.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //compare to find the largest among three numbers
                    var greatest = greaterOne(resultat_1.length, resultat_2.length, resultat_3.length);

                    //3.add rows dynamically to the table
                    if(greatest === 'equal'){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val1'){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val2'){
                        for(let i=0; i<resultat_2.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val3'){
                        for(let i=0; i<resultat_3.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    
                    dynamicviewquery.appendChild(userTable);
                }//

                //1.operator 1 = and operator 2 = and operator 3 >
                if(threeoperator_oneStr === '=' && threeoperator_twoStr === '=' && threeoperator_threeStr === '>'){
                    var resultat_1 = [];
                    var resultat_2 = [];
                    var resultat_3 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(threevalue_oneStr);
                        resultat_1 = findEqual(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = threevalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(threevalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = threevalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //retrieve data array based on 1 key 3
                    var tableDataArray_3 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_3.push(parseFloat(documentData[i][index_3]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_threeStr);
                    if(checkNumber === true){
                        var threshold_3 = parseFloat(threevalue_threeStr);
                        resultat_3 = findEqual(tableDataArray_3,threshold_3);
                    }else{
                        var threshold_3 = threevalue_threeStr;
                        resultat_3 = findString(tableDataArray_3,threshold_3);
                    }

                    //concat the two header
                    var headerconcat = [querycol,querycol_2,querycol_3];

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length + resultat_3.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //compare to find the largest among three numbers
                    var greatest = greaterOne(resultat_1.length, resultat_2.length, resultat_3.length);

                    //3.add rows dynamically to the table
                    if(greatest === 'equal'){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val1'){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val2'){
                        for(let i=0; i<resultat_2.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val3'){
                        for(let i=0; i<resultat_3.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    
                    dynamicviewquery.appendChild(userTable);
                }//

                //1.operator 1 < and operator 2 = and operator 3 =
                if(threeoperator_oneStr === '<' && threeoperator_twoStr === '=' && threeoperator_threeStr === '='){
                    var resultat_1 = [];
                    var resultat_2 = [];
                    var resultat_3 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(threevalue_oneStr);
                        resultat_1 = findSmaller(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = threevalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(threevalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = threevalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //retrieve data array based on 1 key 3
                    var tableDataArray_3 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_3.push(parseFloat(documentData[i][index_3]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_threeStr);
                    if(checkNumber === true){
                        var threshold_3 = parseFloat(threevalue_threeStr);
                        resultat_3 = findEqual(tableDataArray_3,threshold_3);
                    }else{
                        var threshold_3 = threevalue_threeStr;
                        resultat_3 = findString(tableDataArray_3,threshold_3);
                    }

                    //concat the two header
                    var headerconcat = [querycol,querycol_2,querycol_3];

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length + resultat_3.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //compare to find the largest among three numbers
                    var greatest = greaterOne(resultat_1.length, resultat_2.length, resultat_3.length);
                    
                    //3.add rows dynamically to the table
                    if(greatest === 'equal'){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val1'){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val2'){
                        for(let i=0; i<resultat_2.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val3'){
                        for(let i=0; i<resultat_3.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    
                    dynamicviewquery.appendChild(userTable);
                }//

                //1.operator 1 < and operator 2 = and operator 3 <
                if(threeoperator_oneStr === '<' && threeoperator_twoStr === '=' && threeoperator_threeStr === '<'){    
                    var resultat_1 = [];
                    var resultat_2 = [];
                    var resultat_3 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(threevalue_oneStr);
                        resultat_1 = findSmaller(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = threevalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(threevalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = threevalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //retrieve data array based on 1 key 3
                    var tableDataArray_3 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_3.push(parseFloat(documentData[i][index_3]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_threeStr);
                    if(checkNumber === true){
                        var threshold_3 = parseFloat(threevalue_threeStr);
                        resultat_3 = findSmaller(tableDataArray_3,threshold_3);
                    }else{
                        var threshold_3 = threevalue_threeStr;
                        resultat_3 = findString(tableDataArray_3,threshold_3);
                    }

                    //concat the two header
                    var headerconcat = [querycol,querycol_2,querycol_3];

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length + resultat_3.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //compare to find the largest among three numbers
                    var greatest = greaterOne(resultat_1.length, resultat_2.length, resultat_3.length);
                    
                    //3.add rows dynamically to the table
                    if(greatest === 'equal'){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val1'){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val2'){
                        for(let i=0; i<resultat_2.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val3'){
                        for(let i=0; i<resultat_3.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    
                    dynamicviewquery.appendChild(userTable);
                }//

                //1.operator 1 < and operator 2 = and operator 3 >
                if(threeoperator_oneStr === '<' && threeoperator_twoStr === '=' && threeoperator_threeStr === '>'){
                    var resultat_1 = [];
                    var resultat_2 = [];
                    var resultat_3 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(threevalue_oneStr);
                        resultat_1 = findSmaller(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = threevalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(threevalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = threevalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //retrieve data array based on 1 key 3
                    var tableDataArray_3 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_3.push(parseFloat(documentData[i][index_3]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_threeStr);
                    if(checkNumber === true){
                        var threshold_3 = parseFloat(threevalue_threeStr);
                        resultat_3 = findGreater(tableDataArray_3,threshold_3);
                    }else{
                        var threshold_3 = threevalue_threeStr;
                        resultat_3 = findString(tableDataArray_3,threshold_3);
                    }

                    //concat the two header
                    var headerconcat = [querycol,querycol_2,querycol_3];

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length + resultat_3.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //compare to find the largest among three numbers
                    var greatest = greaterOne(resultat_1.length, resultat_2.length, resultat_3.length);
                    
                    //3.add rows dynamically to the table
                    if(greatest === 'equal'){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val1'){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val2'){
                        for(let i=0; i<resultat_2.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val3'){
                        for(let i=0; i<resultat_3.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    
                    dynamicviewquery.appendChild(userTable);
                }//

                //1.operator 1 > and operator 2 = and operator 3 =
                if(threeoperator_oneStr === '>' && threeoperator_twoStr === '=' && threeoperator_threeStr === '='){
                    var resultat_1 = [];
                    var resultat_2 = [];
                    var resultat_3 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(threevalue_oneStr);
                        resultat_1 = findGreater(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = threevalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(threevalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = threevalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //retrieve data array based on 1 key 3
                    var tableDataArray_3 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_3.push(parseFloat(documentData[i][index_3]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_threeStr);
                    if(checkNumber === true){
                        var threshold_3 = parseFloat(threevalue_threeStr);
                        resultat_3 = findEqual(tableDataArray_3,threshold_3);
                    }else{
                        var threshold_3 = threevalue_threeStr;
                        resultat_3 = findString(tableDataArray_3,threshold_3);
                    }

                    //concat the two header
                    var headerconcat = [querycol,querycol_2,querycol_3];

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length + resultat_3.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //compare to find the largest among three numbers
                    var greatest = greaterOne(resultat_1.length, resultat_2.length, resultat_3.length);
                    
                    //3.add rows dynamically to the table
                    if(greatest === 'equal'){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val1'){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val2'){
                        for(let i=0; i<resultat_2.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val3'){
                        for(let i=0; i<resultat_3.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    
                    dynamicviewquery.appendChild(userTable);
                }//

                //1.operator 1 > and operator 2 = and operator 3 <
                if(threeoperator_oneStr === '>' && threeoperator_twoStr === '=' && threeoperator_threeStr === '<'){
                    var resultat_1 = [];
                    var resultat_2 = [];
                    var resultat_3 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(threevalue_oneStr);
                        resultat_1 = findGreater(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = threevalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(threevalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = threevalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //retrieve data array based on 1 key 3
                    var tableDataArray_3 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_3.push(parseFloat(documentData[i][index_3]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_threeStr);
                    if(checkNumber === true){
                        var threshold_3 = parseFloat(threevalue_threeStr);
                        resultat_3 = findSmaller(tableDataArray_3,threshold_3);
                    }else{
                        var threshold_3 = threevalue_threeStr;
                        resultat_3 = findString(tableDataArray_3,threshold_3);
                    }

                    //concat the two header
                    var headerconcat = [querycol,querycol_2,querycol_3];

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length + resultat_3.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //compare to find the largest among three numbers
                    var greatest = greaterOne(resultat_1.length, resultat_2.length, resultat_3.length);
                   
                    //3.add rows dynamically to the table
                    if(greatest === 'equal'){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val1'){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val2'){
                        for(let i=0; i<resultat_2.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val3'){
                        for(let i=0; i<resultat_3.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    
                    dynamicviewquery.appendChild(userTable);
                }//

                //1.operator 1 > and operator 2 = and operator 3 >
                if(threeoperator_oneStr === '>' && threeoperator_twoStr === '=' && threeoperator_threeStr === '>'){
                    var resultat_1 = [];
                    var resultat_2 = [];
                    var resultat_3 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(threevalue_oneStr);
                        resultat_1 = findGreater(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = threevalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(threevalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = threevalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //retrieve data array based on 1 key 3
                    var tableDataArray_3 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_3.push(parseFloat(documentData[i][index_3]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_threeStr);
                    if(checkNumber === true){
                        var threshold_3 = parseFloat(threevalue_threeStr);
                        resultat_3 = findGreater(tableDataArray_3,threshold_3);
                    }else{
                        var threshold_3 = threevalue_threeStr;
                        resultat_3 = findString(tableDataArray_3,threshold_3);
                    }

                    //concat the two header
                    var headerconcat = [querycol,querycol_2,querycol_3];

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length + resultat_3.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //compare to find the largest among three numbers
                    var greatest = greaterOne(resultat_1.length, resultat_2.length, resultat_3.length);
                    
                    //3.add rows dynamically to the table
                    if(greatest === 'equal'){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val1'){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val2'){
                        for(let i=0; i<resultat_2.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val3'){
                        for(let i=0; i<resultat_3.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    
                    dynamicviewquery.appendChild(userTable);
                }//

                //1.operator 1 > and operator 2 > and operator 3 >
                if(threeoperator_oneStr === '>' && threeoperator_twoStr === '>' && threeoperator_threeStr === '>'){
                    var resultat_1 = [];
                    var resultat_2 = [];
                    var resultat_3 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(threevalue_oneStr);
                        resultat_1 = findGreater(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = threevalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(threevalue_twoStr);
                        resultat_2 = findGreater(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = threevalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //retrieve data array based on 1 key 3
                    var tableDataArray_3 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_3.push(parseFloat(documentData[i][index_3]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_threeStr);
                    if(checkNumber === true){
                        var threshold_3 = parseFloat(threevalue_threeStr);
                        resultat_3 = findGreater(tableDataArray_3,threshold_3);
                    }else{
                        var threshold_3 = threevalue_threeStr;
                        resultat_3 = findString(tableDataArray_3,threshold_3);
                    }

                    //concat the two header
                    var headerconcat = [querycol,querycol_2,querycol_3];

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length + resultat_3.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //compare to find the largest among three numbers
                    var greatest = greaterOne(resultat_1.length, resultat_2.length, resultat_3.length);
                    
                    //3.add rows dynamically to the table
                    if(greatest === 'equal'){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val1'){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val2'){
                        for(let i=0; i<resultat_2.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val3'){
                        for(let i=0; i<resultat_3.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    
                    dynamicviewquery.appendChild(userTable);
                }//

                //1.operator 1 < and operator 2 < and operator 3 <
                if(threeoperator_oneStr === '<' && threeoperator_twoStr === '<' && threeoperator_threeStr === '<'){
                    var resultat_1 = [];
                    var resultat_2 = [];
                    var resultat_3 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        if(!documentData[i].hasOwnProperty(querycol)){
                            continue;
                        }
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(threevalue_oneStr);
                        resultat_1 = findSmaller(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = threevalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(threevalue_twoStr);
                        resultat_2 = findSmaller(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = threevalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //retrieve data array based on 1 key 3
                    var tableDataArray_3 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_3.push(parseFloat(documentData[i][index_3]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threevalue_threeStr);
                    if(checkNumber === true){
                        var threshold_3 = parseFloat(threevalue_threeStr);
                        resultat_3 = findSmaller(tableDataArray_3,threshold_3);
                    }else{
                        var threshold_3 = threevalue_threeStr;
                        resultat_3 = findString(tableDataArray_3,threshold_3);
                    }

                    //concat the two header
                    var headerconcat = [querycol,querycol_2,querycol_3];

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length + resultat_3.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //compare to find the largest among three numbers
                    var greatest = greaterOne(resultat_1.length, resultat_2.length, resultat_3.length);
                    
                    //3.add rows dynamically to the table
                    if(greatest === 'equal'){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val1'){
                        for(let i=0; i<resultat_1.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val2'){
                        for(let i=0; i<resultat_2.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    if(greatest === 'val3'){
                        for(let i=0; i<resultat_3.length; i++){
                            var tr = document.createElement('tr');
                            var td1 = document.createElement('td');
                            var text1 = document.createTextNode(resultat_1[i]);
                            td1.appendChild(text1);
                            tr.appendChild(td1);
                            var td2 = document.createElement('td');
                            var text2 = document.createTextNode(resultat_2[i]);
                            td2.appendChild(text2);
                            tr.appendChild(td2);
                            var td3 = document.createElement('td');
                            var text3 = document.createTextNode(resultat_3[i]);
                            td3.appendChild(text3);
                            tr.appendChild(td3);
                            userTable.appendChild(tr);
                        }
                    }
                    
                    dynamicviewquery.appendChild(userTable);
                }
            } catch (error) {
                
            }
        }

        //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX SELECT COUNT(*) FROM table AND WHERE ? ? XXXXXXXXXXXXXXXXXXXX
        //1. Result SELECT COUNT(*) FROM table;
        if(selectedtqueryStr === 'count' && querycol === '' && querycol_2 === '' && querycol_3 === '' && 
        colname_1 === '' && colname_2 === '' && colname_3 === ''){
            var headerone = "";
            var headertwo = "COUNT(*)";
            //concat the two header
            var headerconcat = [headerone,headertwo];

            //display number of document queried
            var array = tableRows;
            var tableLength = array.length;
            var documentnumber = document.getElementById('dynamicviewdocumentquery');
            documentnumber.innerText = 'Query Results: '+tableLength * column.length;

            var resultat = [tableLength * column.length];

            //1.create dynamic table
            var userTable = document.createElement('table');
            var tr = document.createElement('tr');
            userTable.setAttribute('id', 'usertable');
            userTable.setAttribute('class', 'thin_table_config');
            //2.add header to the table
            for(let j=0; j<headerconcat.length; j++){
                var th = document.createElement('th'); //column
                var text = document.createTextNode(headerconcat[j]); //cell
                th.appendChild(text);
                tr.appendChild(th);
            }
            userTable.appendChild(tr);
            //3.add rows dynamically to the table
            for(let i=0; i<1; i++){
                var tr = document.createElement('tr');
                var td1 = document.createElement('td');
                var text1 = document.createTextNode("▶");
                td1.appendChild(text1);
                tr.appendChild(td1);
                var td2 = document.createElement('td');
                var text2 = document.createTextNode(resultat[i]);
                td2.appendChild(text2);
                tr.appendChild(td2);
                userTable.appendChild(tr);
            }

            dynamicviewquery.appendChild(userTable);
        }//

        //1. Result SELECT COUNT(*) FROM table where 1 column ? value;
        if(selectedtqueryStr === 'count' && querycol !== '' && querycol_2 === '' && querycol_3 === '' && 
        colname_1 === '' && colname_2 === '' && colname_3 === ''){
            var count_oneStr = document.getElementById('count_one').value;
            var countoperator_oneStr = document.getElementById('countoperator_one').value;
            var countvalue_oneStr = document.getElementById('countvalue_one').value;
            //retrieve data array based on querycol
            var index_1 = column.indexOf(querycol);

            var headerone = "";
            var headertwo = "COUNT "+count_oneStr+"(*)";

            //concat the two header
            var headerconcat = [headerone,headertwo];
            try {
                //set up query based on operator and value
                //operator =
                if(countoperator_oneStr === '='){
                    var resultat = [];
                    //retrieve data array based on 1 key
                    var tableDataArray = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(countvalue_oneStr);
                    if(checkNumber === true){
                        var threshold = parseFloat(countvalue_oneStr);
                        resultat = findEqual(tableDataArray,threshold);
                    }else{
                        var threshold = countvalue_oneStr;
                        resultat = findString(tableDataArray,threshold);
                    }
                    //display number of document queried
                    var tableLength = resultat.length;
                   
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;
                    var resultat = [tableLength];
                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<1; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode("▶");
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        var td2 = document.createElement('td');
                        var text2 = document.createTextNode(resultat[i]);
                        td2.appendChild(text2);
                        tr.appendChild(td2);
                        userTable.appendChild(tr);
                    }

                    dynamicviewquery.appendChild(userTable);
                }//

                //operator <
                if(countoperator_oneStr === '<'){
                    var resultat = [];
                    //retrieve data array based on 1 key
                    var tableDataArray = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(countvalue_oneStr);
                    if(checkNumber === true){
                        var threshold = parseFloat(countvalue_oneStr);
                        resultat = findSmaller(tableDataArray,threshold);
                    }else{
                        var threshold = countvalue_oneStr;
                        resultat = findString(tableDataArray,threshold);
                    }
                    //display number of document queried
                    var tableLength = resultat.length;
                    
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;
                    var resultat = [tableLength];
                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<1; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode("▶");
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        var td2 = document.createElement('td');
                        var text2 = document.createTextNode(resultat[i]);
                        td2.appendChild(text2);
                        tr.appendChild(td2);
                        userTable.appendChild(tr);
                    }

                    dynamicviewquery.appendChild(userTable);
                }//

                //operator >
                if(countoperator_oneStr === '>'){
                    var resultat = [];
                    //retrieve data array based on 1 key
                    var tableDataArray = [];
                    var key = querycol;
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(countvalue_oneStr);
                    if(checkNumber === true){
                        var threshold = parseFloat(countvalue_oneStr);
                        resultat = findGreater(tableDataArray,threshold);
                    }else{
                        var threshold = countvalue_oneStr;
                        resultat = findString(tableDataArray,threshold);
                    }
                    //display number of document queried
                    var tableLength = resultat.length;

                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;
                    var resultat = [tableLength];
                    //1.create dynamic table
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<1; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode("▶");
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        var td2 = document.createElement('td');
                        var text2 = document.createTextNode(resultat[i]);
                        td2.appendChild(text2);
                        tr.appendChild(td2);
                        userTable.appendChild(tr);
                    }

                    dynamicviewquery.appendChild(userTable);
                }
            } catch (error) {
                
            }
        }//

        //1. Result if query = where column 2 specified from table
        if(selectedtqueryStr === 'count' && querycol !== '' && querycol_2 !== '' && querycol_3 === '' && 
        colname_1 === '' && colname_2 === '' && colname_3 === ''){
            var twocount_oneStr = document.getElementById('twocount_one').value;
            var twocountoperator_oneStr = document.getElementById('twocountoperator_one').value;
            var twocountvalue_oneStr = document.getElementById('twocountvalue_one').value;
            var twocount_twoStr = document.getElementById('twocount_two').value;
            var twocountoperator_twoStr = document.getElementById('twocountoperator_two').value;
            var twocountvalue_twoStr = document.getElementById('twocountvalue_two').value;
            //retrieve data array based on querycol
            var index_1 = column.indexOf(querycol);
            var index_2 = column.indexOf(querycol_2);

            var headerzero = "";
            var headerone = "COUNT "+twocount_oneStr+"(*)";
            var headertwo = "COUNT "+twocount_twoStr+"(*)";

            //concat the two header
            var headerconcat = [headerzero,headerone,headertwo];
            try {
                //set up query based on operator and value
                //operator 1 = and operator 2 =
                if(twocountoperator_oneStr === '=' && twocountoperator_twoStr === '='){
                    var resultat_1 = [];
                    var resultat_2 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twocountvalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(twocountvalue_oneStr);
                        resultat_1 = findEqual(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = twocountvalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twocountvalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(twocountvalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = twocountvalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    var res_1 = [resultat_1.length];
                    var res_2 = [resultat_2.length];

                    //1.create dynamic 
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<1; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode("▶");
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        var td2 = document.createElement('td');
                        var text2 = document.createTextNode(res_1[i]);
                        td2.appendChild(text2);
                        tr.appendChild(td2);
                        var td3 = document.createElement('td');
                        var text3 = document.createTextNode(res_2[i]);
                        td3.appendChild(text3);
                        tr.appendChild(td3);
                        userTable.appendChild(tr);
                    }

                    dynamicviewquery.appendChild(userTable);
                }//

                //operator 1 = and operator 2 <
                if(twocountoperator_oneStr === '=' && twocountoperator_twoStr === '<'){
                    var resultat_1 = [];
                    var resultat_2 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twocountvalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(twocountvalue_oneStr);
                        resultat_1 = findEqual(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = twocountvalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twocountvalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(twocountvalue_twoStr);
                        resultat_2 = findSmaller(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = twocountvalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    var res_1 = [resultat_1.length];
                    var res_2 = [resultat_2.length];

                    //1.create dynamic 
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<1; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode("▶");
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        var td2 = document.createElement('td');
                        var text2 = document.createTextNode(res_1[i]);
                        td2.appendChild(text2);
                        tr.appendChild(td2);
                        var td3 = document.createElement('td');
                        var text3 = document.createTextNode(res_2[i]);
                        td3.appendChild(text3);
                        tr.appendChild(td3);
                        userTable.appendChild(tr);
                    }

                    dynamicviewquery.appendChild(userTable);
                }//

                //operator 1 = and operator 2 >
                if(twocountoperator_oneStr === '=' && twocountoperator_twoStr === '>'){
                    var resultat_1 = [];
                    var resultat_2 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twocountvalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(twocountvalue_oneStr);
                        resultat_1 = findEqual(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = twocountvalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twocountvalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(twocountvalue_twoStr);
                        resultat_2 = findGreater(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = twocountvalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    var res_1 = [resultat_1.length];
                    var res_2 = [resultat_2.length];

                    //1.create dynamic 
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<1; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode("▶");
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        var td2 = document.createElement('td');
                        var text2 = document.createTextNode(res_1[i]);
                        td2.appendChild(text2);
                        tr.appendChild(td2);
                        var td3 = document.createElement('td');
                        var text3 = document.createTextNode(res_2[i]);
                        td3.appendChild(text3);
                        tr.appendChild(td3);
                        userTable.appendChild(tr);
                    }

                    dynamicviewquery.appendChild(userTable);
                }//

                //operator 1 < and operator 2 =
                if(twocountoperator_oneStr === '<' && twocountoperator_twoStr === '='){
                    var resultat_1 = [];
                    var resultat_2 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twocountvalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(twocountvalue_oneStr);
                        resultat_1 = findSmaller(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = twocountvalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twocountvalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(twocountvalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = twocountvalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    var res_1 = [resultat_1.length];
                    var res_2 = [resultat_2.length];

                    //1.create dynamic 
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<1; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode("▶");
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        var td2 = document.createElement('td');
                        var text2 = document.createTextNode(res_1[i]);
                        td2.appendChild(text2);
                        tr.appendChild(td2);
                        var td3 = document.createElement('td');
                        var text3 = document.createTextNode(res_2[i]);
                        td3.appendChild(text3);
                        tr.appendChild(td3);
                        userTable.appendChild(tr);
                    }

                    dynamicviewquery.appendChild(userTable);
                }//

                //operator 1 < and operator 2 <
                if(twocountoperator_oneStr === '<' && twocountoperator_twoStr === '<'){
                    var resultat_1 = [];
                    var resultat_2 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twocountvalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(twocountvalue_oneStr);
                        resultat_1 = findSmaller(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = twocountvalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twocountvalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(twocountvalue_twoStr);
                        resultat_2 = findSmaller(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = twocountvalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    var res_1 = [resultat_1.length];
                    var res_2 = [resultat_2.length];

                    //1.create dynamic 
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<1; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode("▶");
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        var td2 = document.createElement('td');
                        var text2 = document.createTextNode(res_1[i]);
                        td2.appendChild(text2);
                        tr.appendChild(td2);
                        var td3 = document.createElement('td');
                        var text3 = document.createTextNode(res_2[i]);
                        td3.appendChild(text3);
                        tr.appendChild(td3);
                        userTable.appendChild(tr);
                    }

                    dynamicviewquery.appendChild(userTable);
                }//

                //operator 1 < and operator 2 >
                if(twocountoperator_oneStr === '<' && twocountoperator_twoStr === '>'){
                    var resultat_1 = [];
                    var resultat_2 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twocountvalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(twocountvalue_oneStr);
                        resultat_1 = findSmaller(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = twocountvalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twocountvalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(twocountvalue_twoStr);
                        resultat_2 = findGreater(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = twocountvalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    var res_1 = [resultat_1.length];
                    var res_2 = [resultat_2.length];

                    //1.create dynamic 
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<1; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode("▶");
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        var td2 = document.createElement('td');
                        var text2 = document.createTextNode(res_1[i]);
                        td2.appendChild(text2);
                        tr.appendChild(td2);
                        var td3 = document.createElement('td');
                        var text3 = document.createTextNode(res_2[i]);
                        td3.appendChild(text3);
                        tr.appendChild(td3);
                        userTable.appendChild(tr);
                    }

                    dynamicviewquery.appendChild(userTable);
                }//

                //operator 1 > and operator 2 =
                if(twocountoperator_oneStr === '>' && twocountoperator_twoStr === '='){
                    var resultat_1 = [];
                    var resultat_2 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twocountvalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(twocountvalue_oneStr);
                        resultat_1 = findGreater(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = twocountvalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    var key_2 = querycol_2;
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twocountvalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(twocountvalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = twocountvalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    var res_1 = [resultat_1.length];
                    var res_2 = [resultat_2.length];

                    //1.create dynamic 
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<1; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode("▶");
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        var td2 = document.createElement('td');
                        var text2 = document.createTextNode(res_1[i]);
                        td2.appendChild(text2);
                        tr.appendChild(td2);
                        var td3 = document.createElement('td');
                        var text3 = document.createTextNode(res_2[i]);
                        td3.appendChild(text3);
                        tr.appendChild(td3);
                        userTable.appendChild(tr);
                    }

                    dynamicviewquery.appendChild(userTable);
                }//

                //operator 1 > and operator 2 <
                if(twocountoperator_oneStr === '>' && twocountoperator_twoStr === '<'){
                    var resultat_1 = [];
                    var resultat_2 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twocountvalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(twocountvalue_oneStr);
                        resultat_1 = findGreater(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = twocountvalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twocountvalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(twocountvalue_twoStr);
                        resultat_2 = findSmaller(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = twocountvalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    var res_1 = [resultat_1.length];
                    var res_2 = [resultat_2.length];

                    //1.create dynamic 
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<1; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode("▶");
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        var td2 = document.createElement('td');
                        var text2 = document.createTextNode(res_1[i]);
                        td2.appendChild(text2);
                        tr.appendChild(td2);
                        var td3 = document.createElement('td');
                        var text3 = document.createTextNode(res_2[i]);
                        td3.appendChild(text3);
                        tr.appendChild(td3);
                        userTable.appendChild(tr);
                    }

                    dynamicviewquery.appendChild(userTable);
                }//

                //operator 1 > and operator 2 >
                if(twocountoperator_oneStr === '>' && twocountoperator_twoStr === '>'){
                    var resultat_1 = [];
                    var resultat_2 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twocountvalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(twocountvalue_oneStr);
                        resultat_1 = findGreater(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = twocountvalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(twocountvalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(twocountvalue_twoStr);
                        resultat_2 = findGreater(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = twocountvalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    var res_1 = [resultat_1.length];
                    var res_2 = [resultat_2.length];

                    //1.create dynamic 
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<1; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode("▶");
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        var td2 = document.createElement('td');
                        var text2 = document.createTextNode(res_1[i]);
                        td2.appendChild(text2);
                        tr.appendChild(td2);
                        var td3 = document.createElement('td');
                        var text3 = document.createTextNode(res_2[i]);
                        td3.appendChild(text3);
                        tr.appendChild(td3);
                        userTable.appendChild(tr);
                    }

                    dynamicviewquery.appendChild(userTable);
                }//
            } catch (error) {
                
            }
        }//

        //1. Result if query = where column 3 specified from table
        if(selectedtqueryStr === 'count' && querycol !== '' && querycol_2 !== '' && querycol_3 !== '' && 
        colname_1 === '' && colname_2 === '' && colname_3 === ''){
            var threecount_oneStr = document.getElementById('threecount_one').value;
            var threecountoperator_oneStr = document.getElementById('threecountoperator_one').value;
            var threecountvalue_oneStr = document.getElementById('threecountvalue_one').value;
            var threecount_twoStr = document.getElementById('threecount_two').value;
            var threecountoperator_twoStr = document.getElementById('threecountoperator_two').value;
            var threecountvalue_twoStr = document.getElementById('threecountvalue_two').value;
            var threecount_threeStr = document.getElementById('threecount_three').value;
            var threecountoperator_threeStr = document.getElementById('threecountoperator_three').value;
            var threecountvalue_threeStr = document.getElementById('threecountvalue_three').value;
            //retrieve data array based on querycol
            var index_1 = column.indexOf(querycol);
            var index_2 = column.indexOf(querycol_2);
            var index_3 = column.indexOf(querycol_3);

            var headerzero = "";
            var headerone = "COUNT "+threecount_oneStr+"(*)";
            var headertwo = "COUNT "+threecount_twoStr+"(*)";
            var headerthree = "COUNT "+threecount_threeStr+"(*)";

            //concat the two header
            var headerconcat = [headerzero,headerone,headertwo,headerthree];
            try {
                //set up query based on operator and value
                //--------Operator 1---------------------------
                //1.operator 1 = and operator 2 = and operator 3 =
                if(threecountoperator_oneStr === '=' && threecountoperator_twoStr === '=' && threecountoperator_threeStr === '='){
                    var resultat_1 = [];
                    var resultat_2 = [];
                    var resultat_3 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(threecountvalue_oneStr);
                        resultat_1 = findEqual(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = threecountvalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(threecountvalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = threecountvalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //retrieve data array based on 1 key 3
                    var tableDataArray_3 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_3.push(parseFloat(documentData[i][index_3]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_threeStr);
                    if(checkNumber === true){
                        var threshold_3 = parseFloat(threecountvalue_threeStr);
                        resultat_3 = findEqual(tableDataArray_3,threshold_3);
                    }else{
                        var threshold_3 = threecountvalue_threeStr;
                        resultat_3 = findString(tableDataArray_3,threshold_3);
                    }

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length + resultat_3.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    var res_1 = [resultat_1.length];
                    var res_2 = [resultat_2.length];
                    var res_3 = [resultat_3.length];

                    //1.create dynamic 
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<1; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode("▶");
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        var td2 = document.createElement('td');
                        var text2 = document.createTextNode(res_1[i]);
                        td2.appendChild(text2);
                        tr.appendChild(td2);
                        var td3 = document.createElement('td');
                        var text3 = document.createTextNode(res_2[i]);
                        td3.appendChild(text3);
                        tr.appendChild(td3);
                        var td4 = document.createElement('td');
                        var text4 = document.createTextNode(res_3[i]);
                        td4.appendChild(text4);
                        tr.appendChild(td4);
                        userTable.appendChild(tr);
                    }

                    dynamicviewquery.appendChild(userTable);
                }//

                //1.operator 1 = and operator 2 = and operator 3 <
                if(threecountoperator_oneStr === '=' && threecountoperator_twoStr === '=' && threecountoperator_threeStr === '<'){
                    var resultat_1 = [];
                    var resultat_2 = [];
                    var resultat_3 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(threecountvalue_oneStr);
                        resultat_1 = findEqual(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = threecountvalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(threecountvalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = threecountvalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //retrieve data array based on 1 key 3
                    var tableDataArray_3 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_3.push(parseFloat(documentData[i][index_3]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_threeStr);
                    if(checkNumber === true){
                        var threshold_3 = parseFloat(threecountvalue_threeStr);
                        resultat_3 = findSmaller(tableDataArray_3,threshold_3);
                    }else{
                        var threshold_3 = threecountvalue_threeStr;
                        resultat_3 = findString(tableDataArray_3,threshold_3);
                    }

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length + resultat_3.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    var res_1 = [resultat_1.length];
                    var res_2 = [resultat_2.length];
                    var res_3 = [resultat_3.length];

                    //1.create dynamic 
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<1; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode("▶");
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        var td2 = document.createElement('td');
                        var text2 = document.createTextNode(res_1[i]);
                        td2.appendChild(text2);
                        tr.appendChild(td2);
                        var td3 = document.createElement('td');
                        var text3 = document.createTextNode(res_2[i]);
                        td3.appendChild(text3);
                        tr.appendChild(td3);
                        var td4 = document.createElement('td');
                        var text4 = document.createTextNode(res_3[i]);
                        td4.appendChild(text4);
                        tr.appendChild(td4);
                        userTable.appendChild(tr);
                    }

                    dynamicviewquery.appendChild(userTable);
                }//

                //1.operator 1 = and operator 2 = and operator 3 >
                if(threecountoperator_oneStr === '=' && threecountoperator_twoStr === '=' && threecountoperator_threeStr === '>'){
                    var resultat_1 = [];
                    var resultat_2 = [];
                    var resultat_3 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(threecountvalue_oneStr);
                        resultat_1 = findEqual(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = threecountvalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(threecountvalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = threecountvalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //retrieve data array based on 1 key 3
                    var tableDataArray_3 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_3.push(parseFloat(documentData[i][index_3]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_threeStr);
                    if(checkNumber === true){
                        var threshold_3 = parseFloat(threecountvalue_threeStr);
                        resultat_3 = findGreater(tableDataArray_3,threshold_3);
                    }else{
                        var threshold_3 = threecountvalue_threeStr;
                        resultat_3 = findString(tableDataArray_3,threshold_3);
                    }

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length + resultat_3.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    var res_1 = [resultat_1.length];
                    var res_2 = [resultat_2.length];
                    var res_3 = [resultat_3.length];

                    //1.create dynamic 
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<1; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode("▶");
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        var td2 = document.createElement('td');
                        var text2 = document.createTextNode(res_1[i]);
                        td2.appendChild(text2);
                        tr.appendChild(td2);
                        var td3 = document.createElement('td');
                        var text3 = document.createTextNode(res_2[i]);
                        td3.appendChild(text3);
                        tr.appendChild(td3);
                        var td4 = document.createElement('td');
                        var text4 = document.createTextNode(res_3[i]);
                        td4.appendChild(text4);
                        tr.appendChild(td4);
                        userTable.appendChild(tr);
                    }

                    dynamicviewquery.appendChild(userTable);
                }//
                
                //1.operator 1 < and operator 2 = and operator 3 =
                if(threecountoperator_oneStr === '<' && threecountoperator_twoStr === '=' && threecountoperator_threeStr === '='){
                    var resultat_1 = [];
                    var resultat_2 = [];
                    var resultat_3 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(threecountvalue_oneStr);
                        resultat_1 = findSmaller(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = threecountvalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(threecountvalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = threecountvalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //retrieve data array based on 1 key 3
                    var tableDataArray_3 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_3.push(parseFloat(documentData[i][index_3]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_threeStr);
                    if(checkNumber === true){
                        var threshold_3 = parseFloat(threecountvalue_threeStr);
                        resultat_3 = findEqual(tableDataArray_3,threshold_3);
                    }else{
                        var threshold_3 = threecountvalue_threeStr;
                        resultat_3 = findString(tableDataArray_3,threshold_3);
                    }

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length + resultat_3.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    var res_1 = [resultat_1.length];
                    var res_2 = [resultat_2.length];
                    var res_3 = [resultat_3.length];

                    //1.create dynamic 
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<1; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode("▶");
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        var td2 = document.createElement('td');
                        var text2 = document.createTextNode(res_1[i]);
                        td2.appendChild(text2);
                        tr.appendChild(td2);
                        var td3 = document.createElement('td');
                        var text3 = document.createTextNode(res_2[i]);
                        td3.appendChild(text3);
                        tr.appendChild(td3);
                        var td4 = document.createElement('td');
                        var text4 = document.createTextNode(res_3[i]);
                        td4.appendChild(text4);
                        tr.appendChild(td4);
                        userTable.appendChild(tr);
                    }

                    dynamicviewquery.appendChild(userTable);
                }//

                //1.operator 1 < and operator 2 = and operator 3 <
                if(threecountoperator_oneStr === '<' && threecountoperator_twoStr === '=' && threecountoperator_threeStr === '<'){
                    var resultat_1 = [];
                    var resultat_2 = [];
                    var resultat_3 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(threecountvalue_oneStr);
                        resultat_1 = findSmaller(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = threecountvalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(threecountvalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = threecountvalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //retrieve data array based on 1 key 3
                    var tableDataArray_3 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_3.push(parseFloat(documentData[i][index_3]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_threeStr);
                    if(checkNumber === true){
                        var threshold_3 = parseFloat(threecountvalue_threeStr);
                        resultat_3 = findSmaller(tableDataArray_3,threshold_3);
                    }else{
                        var threshold_3 = threecountvalue_threeStr;
                        resultat_3 = findString(tableDataArray_3,threshold_3);
                    }

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length + resultat_3.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    var res_1 = [resultat_1.length];
                    var res_2 = [resultat_2.length];
                    var res_3 = [resultat_3.length];

                    //1.create dynamic 
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<1; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode("▶");
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        var td2 = document.createElement('td');
                        var text2 = document.createTextNode(res_1[i]);
                        td2.appendChild(text2);
                        tr.appendChild(td2);
                        var td3 = document.createElement('td');
                        var text3 = document.createTextNode(res_2[i]);
                        td3.appendChild(text3);
                        tr.appendChild(td3);
                        var td4 = document.createElement('td');
                        var text4 = document.createTextNode(res_3[i]);
                        td4.appendChild(text4);
                        tr.appendChild(td4);
                        userTable.appendChild(tr);
                    }

                    dynamicviewquery.appendChild(userTable);
                }//

                //1.operator 1 < and operator 2 = and operator 3 >
                if(threecountoperator_oneStr === '<' && threecountoperator_twoStr === '=' && threecountoperator_threeStr === '>'){
                    var resultat_1 = [];
                    var resultat_2 = [];
                    var resultat_3 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(threecountvalue_oneStr);
                        resultat_1 = findSmaller(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = threecountvalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        if(!documentData[i].hasOwnProperty(querycol_2)){
                            continue;
                        }
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(threecountvalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = threecountvalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //retrieve data array based on 1 key 3
                    var tableDataArray_3 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_3.push(parseFloat(documentData[i][index_3]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_threeStr);
                    if(checkNumber === true){
                        var threshold_3 = parseFloat(threecountvalue_threeStr);
                        resultat_3 = findGreater(tableDataArray_3,threshold_3);
                    }else{
                        var threshold_3 = threecountvalue_threeStr;
                        resultat_3 = findString(tableDataArray_3,threshold_3);
                    }

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length + resultat_3.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    var res_1 = [resultat_1.length];
                    var res_2 = [resultat_2.length];
                    var res_3 = [resultat_3.length];

                    //1.create dynamic 
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<1; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode("▶");
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        var td2 = document.createElement('td');
                        var text2 = document.createTextNode(res_1[i]);
                        td2.appendChild(text2);
                        tr.appendChild(td2);
                        var td3 = document.createElement('td');
                        var text3 = document.createTextNode(res_2[i]);
                        td3.appendChild(text3);
                        tr.appendChild(td3);
                        var td4 = document.createElement('td');
                        var text4 = document.createTextNode(res_3[i]);
                        td4.appendChild(text4);
                        tr.appendChild(td4);
                        userTable.appendChild(tr);
                    }

                    dynamicviewquery.appendChild(userTable);
                }//

                //1.operator 1 > and operator 2 = and operator 3 =
                if(threecountoperator_oneStr === '>' && threecountoperator_twoStr === '=' && threecountoperator_threeStr === '='){
                    var resultat_1 = [];
                    var resultat_2 = [];
                    var resultat_3 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(threecountvalue_oneStr);
                        resultat_1 = findGreater(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = threecountvalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(threecountvalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = threecountvalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //retrieve data array based on 1 key 3
                    var tableDataArray_3 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_3.push(parseFloat(documentData[i][index_3]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_threeStr);
                    if(checkNumber === true){
                        var threshold_3 = parseFloat(threecountvalue_threeStr);
                        resultat_3 = findEqual(tableDataArray_3,threshold_3);
                    }else{
                        var threshold_3 = threecountvalue_threeStr;
                        resultat_3 = findString(tableDataArray_3,threshold_3);
                    }

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length + resultat_3.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    var res_1 = [resultat_1.length];
                    var res_2 = [resultat_2.length];
                    var res_3 = [resultat_3.length];

                    //1.create dynamic 
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<1; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode("▶");
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        var td2 = document.createElement('td');
                        var text2 = document.createTextNode(res_1[i]);
                        td2.appendChild(text2);
                        tr.appendChild(td2);
                        var td3 = document.createElement('td');
                        var text3 = document.createTextNode(res_2[i]);
                        td3.appendChild(text3);
                        tr.appendChild(td3);
                        var td4 = document.createElement('td');
                        var text4 = document.createTextNode(res_3[i]);
                        td4.appendChild(text4);
                        tr.appendChild(td4);
                        userTable.appendChild(tr);
                    }

                    dynamicviewquery.appendChild(userTable);
                }//

                //1.operator 1 > and operator 2 = and operator 3 <
                if(threecountoperator_oneStr === '>' && threecountoperator_twoStr === '=' && threecountoperator_threeStr === '<'){
                    var resultat_1 = [];
                    var resultat_2 = [];
                    var resultat_3 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(threecountvalue_oneStr);
                        resultat_1 = findGreater(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = threecountvalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(threecountvalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = threecountvalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //retrieve data array based on 1 key 3
                    var tableDataArray_3 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_3.push(parseFloat(documentData[i][index_3]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_threeStr);
                    if(checkNumber === true){
                        var threshold_3 = parseFloat(threecountvalue_threeStr);
                        resultat_3 = findSmaller(tableDataArray_3,threshold_3);
                    }else{
                        var threshold_3 = threecountvalue_threeStr;
                        resultat_3 = findString(tableDataArray_3,threshold_3);
                    }

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length + resultat_3.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    var res_1 = [resultat_1.length];
                    var res_2 = [resultat_2.length];
                    var res_3 = [resultat_3.length];

                    //1.create dynamic 
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<1; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode("▶");
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        var td2 = document.createElement('td');
                        var text2 = document.createTextNode(res_1[i]);
                        td2.appendChild(text2);
                        tr.appendChild(td2);
                        var td3 = document.createElement('td');
                        var text3 = document.createTextNode(res_2[i]);
                        td3.appendChild(text3);
                        tr.appendChild(td3);
                        var td4 = document.createElement('td');
                        var text4 = document.createTextNode(res_3[i]);
                        td4.appendChild(text4);
                        tr.appendChild(td4);
                        userTable.appendChild(tr);
                    }

                    dynamicviewquery.appendChild(userTable);
                }//

                //1.operator 1 > and operator 2 = and operator 3 >
                if(threecountoperator_oneStr === '>' && threecountoperator_twoStr === '=' && threecountoperator_threeStr === '>'){
                    var resultat_1 = [];
                    var resultat_2 = [];
                    var resultat_3 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(threecountvalue_oneStr);
                        resultat_1 = findGreater(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = threecountvalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(threecountvalue_twoStr);
                        resultat_2 = findEqual(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = threecountvalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //retrieve data array based on 1 key 3
                    var tableDataArray_3 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_3.push(parseFloat(documentData[i][index_3]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_threeStr);
                    if(checkNumber === true){
                        var threshold_3 = parseFloat(threecountvalue_threeStr);
                        resultat_3 = findGreater(tableDataArray_3,threshold_3);
                    }else{
                        var threshold_3 = threecountvalue_threeStr;
                        resultat_3 = findString(tableDataArray_3,threshold_3);
                    }

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length + resultat_3.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    var res_1 = [resultat_1.length];
                    var res_2 = [resultat_2.length];
                    var res_3 = [resultat_3.length];

                    //1.create dynamic 
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<1; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode("▶");
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        var td2 = document.createElement('td');
                        var text2 = document.createTextNode(res_1[i]);
                        td2.appendChild(text2);
                        tr.appendChild(td2);
                        var td3 = document.createElement('td');
                        var text3 = document.createTextNode(res_2[i]);
                        td3.appendChild(text3);
                        tr.appendChild(td3);
                        var td4 = document.createElement('td');
                        var text4 = document.createTextNode(res_3[i]);
                        td4.appendChild(text4);
                        tr.appendChild(td4);
                        userTable.appendChild(tr);
                    }

                    dynamicviewquery.appendChild(userTable);
                }//

                //1.operator 1 > and operator 2 > and operator 3 >
                if(threecountoperator_oneStr === '>' && threecountoperator_twoStr === '>' && threecountoperator_threeStr === '>'){
                    var resultat_1 = [];
                    var resultat_2 = [];
                    var resultat_3 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(threecountvalue_oneStr);
                        resultat_1 = findGreater(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = threecountvalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(threecountvalue_twoStr);
                        resultat_2 = findGreater(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = threecountvalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //retrieve data array based on 1 key 3
                    var tableDataArray_3 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_3.push(parseFloat(documentData[i][index_3]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_threeStr);
                    if(checkNumber === true){
                        var threshold_3 = parseFloat(threecountvalue_threeStr);
                        resultat_3 = findGreater(tableDataArray_3,threshold_3);
                    }else{
                        var threshold_3 = threecountvalue_threeStr;
                        resultat_3 = findString(tableDataArray_3,threshold_3);
                    }

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length + resultat_3.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    var res_1 = [resultat_1.length];
                    var res_2 = [resultat_2.length];
                    var res_3 = [resultat_3.length];

                    //1.create dynamic 
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<1; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode("▶");
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        var td2 = document.createElement('td');
                        var text2 = document.createTextNode(res_1[i]);
                        td2.appendChild(text2);
                        tr.appendChild(td2);
                        var td3 = document.createElement('td');
                        var text3 = document.createTextNode(res_2[i]);
                        td3.appendChild(text3);
                        tr.appendChild(td3);
                        var td4 = document.createElement('td');
                        var text4 = document.createTextNode(res_3[i]);
                        td4.appendChild(text4);
                        tr.appendChild(td4);
                        userTable.appendChild(tr);
                    }

                    dynamicviewquery.appendChild(userTable);
                }//

                //1.operator 1 < and operator 2 < and operator 3 <
                if(threecountoperator_oneStr === '<' && threecountoperator_twoStr === '<' && threecountoperator_threeStr === '<'){
                    var resultat_1 = [];
                    var resultat_2 = [];
                    var resultat_3 = [];

                    //retrieve data array based on 1 key 1
                    var tableDataArray_1 = [];
                    for(let i=0; i<documentData.length; i++){
                        if(!documentData[i].hasOwnProperty(querycol)){
                            continue;
                        }
                        tableDataArray_1.push(parseFloat(documentData[i][index_1]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_oneStr);
                    if(checkNumber === true){
                        var threshold_1 = parseFloat(threecountvalue_oneStr);
                        resultat_1 = findSmaller(tableDataArray_1,threshold_1);
                    }else{
                        var threshold_1 = threecountvalue_oneStr;
                        resultat_1 = findString(tableDataArray_1,threshold_1);
                    }

                    //retrieve data array based on 1 key 2
                    var tableDataArray_2 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_2.push(parseFloat(documentData[i][index_2]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_twoStr);
                    if(checkNumber === true){
                        var threshold_2 = parseFloat(threecountvalue_twoStr);
                        resultat_2 = findSmaller(tableDataArray_2,threshold_2);
                    }else{
                        var threshold_2 = threecountvalue_twoStr;
                        resultat_2 = findString(tableDataArray_2,threshold_2);
                    }

                    //retrieve data array based on 1 key 3
                    var tableDataArray_3 = [];
                    for(let i=0; i<documentData.length; i++){
                        tableDataArray_3.push(parseFloat(documentData[i][index_3]));
                    }
                    //check if this threshold is number or not
                    var checkNumber = isNum(threecountvalue_threeStr);
                    if(checkNumber === true){
                        var threshold_3 = parseFloat(threecountvalue_threeStr);
                        resultat_3 = findSmaller(tableDataArray_3,threshold_3);
                    }else{
                        var threshold_3 = threecountvalue_threeStr;
                        resultat_3 = findString(tableDataArray_3,threshold_3);
                    }

                    //display number of document queried
                    var tableLength = (resultat_1.length + resultat_2.length + resultat_3.length);
                    var documentnumber = document.getElementById('dynamicviewdocumentquery');
                    documentnumber.innerText = 'Query Results: '+tableLength;

                    var res_1 = [resultat_1.length];
                    var res_2 = [resultat_2.length];
                    var res_3 = [resultat_3.length];

                    //1.create dynamic 
                    var userTable = document.createElement('table');
                    var tr = document.createElement('tr');
                    userTable.setAttribute('id', 'usertable');
                    userTable.setAttribute('class', 'thin_table_config');
                    //2.add header to the table
                    for(let j=0; j<headerconcat.length; j++){
                        var th = document.createElement('th'); //column
                        var text = document.createTextNode(headerconcat[j]); //cell
                        th.appendChild(text);
                        tr.appendChild(th);
                    }
                    userTable.appendChild(tr);
                    //3.add rows dynamically to the table
                    for(let i=0; i<1; i++){
                        var tr = document.createElement('tr');
                        var td1 = document.createElement('td');
                        var text1 = document.createTextNode("▶");
                        td1.appendChild(text1);
                        tr.appendChild(td1);
                        var td2 = document.createElement('td');
                        var text2 = document.createTextNode(res_1[i]);
                        td2.appendChild(text2);
                        tr.appendChild(td2);
                        var td3 = document.createElement('td');
                        var text3 = document.createTextNode(res_2[i]);
                        td3.appendChild(text3);
                        tr.appendChild(td3);
                        var td4 = document.createElement('td');
                        var text4 = document.createTextNode(res_3[i]);
                        td4.appendChild(text4);
                        tr.appendChild(td4);
                        userTable.appendChild(tr);
                    }

                    dynamicviewquery.appendChild(userTable);
                }//
            } catch (error) {
            
            }
        }//

        //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX SELECT UNION FROM table XXXXXXXXXXXXXXXXXXXX
        //1. Result SELECT doc FROM table UNION SELECT doc FROM table;
        if(selectedtqueryStr === 'union' && querycol !== '' && querycol_2 !== '' && querycol_3 === '' && 
        colname_1 === '' && colname_2 === '' && colname_3 === ''){
            var hostnametaskStr = document.getElementById('hostnamequery').value;
            var hostporttaskStr = document.getElementById('hostportquery').value;
            var usernametaskStr = document.getElementById('usernamequery').value;
            var passwordnametaskStr = document.getElementById('passwordnamequery').value;
            //xxxxxxxxxxxxxxx
            var databasequeryStr = document.getElementById('databasequery').value;
            var templatequeryStr = document.getElementById('templatequery').value;
            var serverqueryStr = document.getElementById('serverquery').value;
            var documentqueryStr = document.getElementById('documentquery').value;
            try {
                //call function specificDocument
                var resultat_1 = [];
                resultat_1 = await specificDocument(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol, serverqueryStr);
                var keyvalue_1 = Object.values(resultat_1);
                var resultat_2 = [];
                resultat_2 = await specificDocument(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol_2, serverqueryStr);
                var keyvalue_2 = Object.values(resultat_2);
                const column = Object.keys(tableData[0]);
                var querydoc = 'Column';
                var querydocname_1 = 'Doc'+querycol;
                var querydocname_2 = 'Doc'+querycol_2;

                //concat the two header
                var headerconcat = [querydoc,querydocname_1,querydocname_2];

                //display number of document queried
                var tableLength = (keyvalue_1.length + keyvalue_2.length);
                var documentnumber = document.getElementById('dynamicviewdocumentquery');
                documentnumber.innerText = 'Query Results: '+tableLength;
                
                //1.create dynamic table
                var userTable = document.createElement('table');
                var tr = document.createElement('tr');
                userTable.setAttribute('id', 'usertable');
                userTable.setAttribute('class', 'thin_table_config');
                //2.add header to the table
                for(let j=0; j<headerconcat.length; j++){
                    var th = document.createElement('th'); //column
                    var text = document.createTextNode(headerconcat[j]); //cell
                    th.appendChild(text);
                    tr.appendChild(th);
                }
                userTable.appendChild(tr);
                //3.add rows dynamically to the table
                for(let i=0; i<keyvalue_1.length; i++){
                    var tr = document.createElement('tr');
                    var td1 = document.createElement('td');
                    var text1 = document.createTextNode(column[i]);
                    td1.appendChild(text1);
                    tr.appendChild(td1);
                    var td2 = document.createElement('td');
                    var text2 = document.createTextNode(keyvalue_1[i]);
                    td2.appendChild(text2);
                    tr.appendChild(td2);
                    var td3 = document.createElement('td');
                    var text3 = document.createTextNode(keyvalue_2[i]);
                    td3.appendChild(text3);
                    tr.appendChild(td3);

                    userTable.appendChild(tr);
                }

                dynamicviewquery.appendChild(userTable);
            } catch (error) {
                
            }
        }//
        
        //1. Result SELECT doc FROM table UNION SELECT doc FROM table WHERE column = ?;
        if(selectedtqueryStr === 'union' && querycol !== '' && querycol_2 !== '' && querycol_3 === '' && 
        colname_1 !== '' && colname_2 === '' && colname_3 === ''){
            var hostnametaskStr = document.getElementById('hostnamequery').value;
            var hostporttaskStr = document.getElementById('hostportquery').value;
            var usernametaskStr = document.getElementById('usernamequery').value;
            var passwordnametaskStr = document.getElementById('passwordnamequery').value;
            //xxxxxxxxxxxxxxx
            var databasequeryStr = document.getElementById('databasequery').value;
            var templatequeryStr = document.getElementById('templatequery').value;
            var serverqueryStr = document.getElementById('serverquery').value;
            var documentqueryStr = document.getElementById('documentquery').value;
            try {
                //call function specificDocumentOneColumn
                var resultat_1 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol, colname_1, serverqueryStr);
                var keyvalue_1 = [resultat_1];
                
                var resultat_2 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol_2, colname_1, serverqueryStr);
                var keyvalue_2 = [resultat_2];
                
                var columnname = [colname_1];

                var querydoc = 'Column';
                var querydocname_1 = 'Doc'+querycol;
                var querydocname_2 = 'Doc'+querycol_2;

                //concat the two header
                var headerconcat = [querydoc,querydocname_1,querydocname_2];

                //display number of document queried
                var tableLength = (keyvalue_1.length + keyvalue_2.length);
                var documentnumber = document.getElementById('dynamicviewdocumentquery');
                documentnumber.innerText = 'Query Results: '+tableLength;

                //1.create dynamic table
                var userTable = document.createElement('table');
                var tr = document.createElement('tr');
                userTable.setAttribute('id', 'usertable');
                userTable.setAttribute('class', 'thin_table_config');
                //2.add header to the table
                for(let j=0; j<headerconcat.length; j++){
                    var th = document.createElement('th'); //column
                    var text = document.createTextNode(headerconcat[j]); //cell
                    th.appendChild(text);
                    tr.appendChild(th);
                }
                userTable.appendChild(tr);
                //3.add rows dynamically to the table
                for(let i=0; i<keyvalue_1.length; i++){
                    var tr = document.createElement('tr');
                    var td1 = document.createElement('td');
                    var text1 = document.createTextNode(columnname[i]);
                    td1.appendChild(text1);
                    tr.appendChild(td1);
                    var td2 = document.createElement('td');
                    var text2 = document.createTextNode(keyvalue_1[i]);
                    td2.appendChild(text2);
                    tr.appendChild(td2);
                    var td3 = document.createElement('td');
                    var text3 = document.createTextNode(keyvalue_2[i]);
                    td3.appendChild(text3);
                    tr.appendChild(td3);

                    userTable.appendChild(tr);
                }

                dynamicviewquery.appendChild(userTable);
            } catch (error) {
                
            }
        }//

        //1. Result SELECT doc FROM table UNION SELECT doc FROM table WHERE column = ?;
        if(selectedtqueryStr === 'union' && querycol !== '' && querycol_2 !== '' && querycol_3 === '' && 
        colname_1 !== '' && colname_2 !== '' && colname_3 === ''){
            var hostnametaskStr = document.getElementById('hostnamequery').value;
            var hostporttaskStr = document.getElementById('hostportquery').value;
            var usernametaskStr = document.getElementById('usernamequery').value;
            var passwordnametaskStr = document.getElementById('passwordnamequery').value;
            //xxxxxxxxxxxxxxx
            var databasequeryStr = document.getElementById('databasequery').value;
            var templatequeryStr = document.getElementById('templatequery').value;
            var serverqueryStr = document.getElementById('serverquery').value;
            var documentqueryStr = document.getElementById('documentquery').value;
            try {
                //call function specificDocumentOneColumn
                var resultat_1 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol, colname_1, serverqueryStr);
                var keyvalue_1 = [resultat_1]; 
                var resultat_2 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol_2, colname_1, serverqueryStr);
                var keyvalue_2 = [resultat_2];
                
                var resultat_3 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol, colname_2, serverqueryStr);
                var keyvalue_3 = [resultat_3];
                var resultat_4 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol_2, colname_2, serverqueryStr);
                var keyvalue_4 = [resultat_4];
                
                var colnames = [colname_1,colname_2];
                var valeur_1 = [resultat_1,resultat_2];
                var valeur_2 = [resultat_3,resultat_4];

                var querydoc = 'Column';
                var querydocname_1 = 'Doc'+querycol;
                var querydocname_2 = 'Doc'+querycol_2;
                

                //concat the two header
                var headerconcat = [querydoc,querydocname_1,querydocname_2];

                //display number of document queried
                var tableLength = (keyvalue_1.length + keyvalue_2.length + keyvalue_3.length + keyvalue_4.length);
                var documentnumber = document.getElementById('dynamicviewdocumentquery');
                documentnumber.innerText = 'Query Results: '+tableLength;

                //1.create dynamic table
                var userTable = document.createElement('table');
                var tr = document.createElement('tr');
                userTable.setAttribute('id', 'usertable');
                userTable.setAttribute('class', 'thin_table_config');
                //2.add header to the table
                for(let j=0; j<headerconcat.length; j++){
                    var th = document.createElement('th'); //column
                    var text = document.createTextNode(headerconcat[j]); //cell
                    th.appendChild(text);
                    tr.appendChild(th);
                }
                userTable.appendChild(tr);
                //3.add rows dynamically to the table
                for(let i=0; i<valeur_1.length; i++){
                    var tr = document.createElement('tr');
                    var td1 = document.createElement('td');
                    var text1 = document.createTextNode(colnames[i]);
                    td1.appendChild(text1);
                    tr.appendChild(td1);

                    var td2 = document.createElement('td');
                    var text2 = document.createTextNode(valeur_1[i]);
                    td2.appendChild(text2);
                    tr.appendChild(td2);
                    var td3 = document.createElement('td');
                    var text3 = document.createTextNode(valeur_2[i]);
                    td3.appendChild(text3);
                    tr.appendChild(td3);

                    userTable.appendChild(tr);
                }

                dynamicviewquery.appendChild(userTable);
            } catch (error) {
                
            }
        }

        //1. Result SELECT doc FROM table UNION SELECT doc FROM table WHERE column = ?;
        if(selectedtqueryStr === 'union' && querycol !== '' && querycol_2 !== '' && querycol_3 === '' && 
        colname_1 !== '' && colname_2 !== '' && colname_3 !== ''){
            var hostnametaskStr = document.getElementById('hostnamequery').value;
            var hostporttaskStr = document.getElementById('hostportquery').value;
            var usernametaskStr = document.getElementById('usernamequery').value;
            var passwordnametaskStr = document.getElementById('passwordnamequery').value;
            //xxxxxxxxxxxxxxx
            var databasequeryStr = document.getElementById('databasequery').value;
            var templatequeryStr = document.getElementById('templatequery').value;
            var serverqueryStr = document.getElementById('serverquery').value;
            var documentqueryStr = document.getElementById('documentquery').value;
            try {
                //call function specificDocumentOneColumn
                var resultat_1 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol, colname_1, serverqueryStr);
                var keyvalue_1 = [resultat_1];
                var resultat_2 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol_2, colname_1, serverqueryStr);
                var keyvalue_2 = [resultat_2];

                var resultat_3 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol, colname_2, serverqueryStr);
                var keyvalue_3 = [resultat_3];
                var resultat_4 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol_2, colname_2, serverqueryStr);
                var keyvalue_4 = [resultat_4];

                var resultat_5 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol, colname_3, serverqueryStr);
                var keyvalue_5 = [resultat_5];
                var resultat_6 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol_2, colname_3, serverqueryStr);
                var keyvalue_6 = [resultat_6];

                var colnames = [colname_1,colname_2,colname_3];
                var valeur_1 = [resultat_1,resultat_2,resultat_5];
                var valeur_2 = [resultat_3,resultat_4,resultat_6];

                var querydoc = 'Column';
                var querydocname_1 = 'Doc'+querycol;
                var querydocname_2 = 'Doc'+querycol_2;
                

                //concat the two header
                var headerconcat = [querydoc,querydocname_1,querydocname_2];

                //display number of document queried
                var tableLength = (keyvalue_1.length + keyvalue_2.length + keyvalue_3.length + 
                keyvalue_4.length + keyvalue_5.length + keyvalue_6.length);
                var documentnumber = document.getElementById('dynamicviewdocumentquery');
                documentnumber.innerText = 'Query Results: '+tableLength;

                //1.create dynamic table
                var userTable = document.createElement('table');
                var tr = document.createElement('tr');
                userTable.setAttribute('id', 'usertable');
                userTable.setAttribute('class', 'thin_table_config');
                //2.add header to the table
                for(let j=0; j<headerconcat.length; j++){
                    var th = document.createElement('th'); //column
                    var text = document.createTextNode(headerconcat[j]); //cell
                    th.appendChild(text);
                    tr.appendChild(th);
                }
                userTable.appendChild(tr);
                //3.add rows dynamically to the table
                for(let i=0; i<valeur_1.length; i++){
                    var tr = document.createElement('tr');
                    var td1 = document.createElement('td');
                    var text1 = document.createTextNode(colnames[i]);
                    td1.appendChild(text1);
                    tr.appendChild(td1);

                    var td2 = document.createElement('td');
                    var text2 = document.createTextNode(valeur_1[i]);
                    td2.appendChild(text2);
                    tr.appendChild(td2);
                    var td3 = document.createElement('td');
                    var text3 = document.createTextNode(valeur_2[i]);
                    td3.appendChild(text3);
                    tr.appendChild(td3);

                    userTable.appendChild(tr);
                }

                dynamicviewquery.appendChild(userTable);
            } catch (error) {
               
            }
        }//

        //1. Result SELECT doc FROM table UNION SELECT doc FROM table;
        if(selectedtqueryStr === 'union' && querycol !== '' && querycol_2 !== '' && querycol_3 !== '' && 
        colname_1 === '' && colname_2 === '' && colname_3 === ''){
            var hostnametaskStr = document.getElementById('hostnamequery').value;
            var hostporttaskStr = document.getElementById('hostportquery').value;
            var usernametaskStr = document.getElementById('usernamequery').value;
            var passwordnametaskStr = document.getElementById('passwordnamequery').value;
            //xxxxxxxxxxxxxxx
            var databasequeryStr = document.getElementById('databasequery').value;
            var templatequeryStr = document.getElementById('templatequery').value;
            var serverqueryStr = document.getElementById('serverquery').value;
            var documentqueryStr = document.getElementById('documentquery').value;
            try {
                //call function specificDocument
                var resultat_1 = [];
                resultat_1 = await specificDocument(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol, serverqueryStr);
                var keyvalue_1 = Object.values(resultat_1);
                var resultat_2 = [];
                resultat_2 = await specificDocument(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol_2, serverqueryStr);
                var keyvalue_2 = Object.values(resultat_2);
                var resultat_3 = [];
                resultat_3 = await specificDocument(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol_3, serverqueryStr);
                var keyvalue_3 = Object.values(resultat_3);
                const column = Object.keys(tableData[0]);
                var querydoc = 'Column';
                var querydocname_1 = 'Doc'+querycol;
                var querydocname_2 = 'Doc'+querycol_2;
                var querydocname_3 = 'Doc'+querycol_3;

                //concat the two header
                var headerconcat = [querydoc,querydocname_1,querydocname_2,querydocname_3];

                //display number of document queried
                var tableLength = (keyvalue_1.length + keyvalue_2.length);
                var documentnumber = document.getElementById('dynamicviewdocumentquery');
                documentnumber.innerText = 'Query Results: '+tableLength;
                
                //1.create dynamic table
                var userTable = document.createElement('table');
                var tr = document.createElement('tr');
                userTable.setAttribute('id', 'usertable');
                userTable.setAttribute('class', 'thin_table_config');
                //2.add header to the table
                for(let j=0; j<headerconcat.length; j++){
                    var th = document.createElement('th'); //column
                    var text = document.createTextNode(headerconcat[j]); //cell
                    th.appendChild(text);
                    tr.appendChild(th);
                }
                userTable.appendChild(tr);
                //3.add rows dynamically to the table
                for(let i=0; i<keyvalue_1.length; i++){
                    var tr = document.createElement('tr');
                    var td1 = document.createElement('td');
                    var text1 = document.createTextNode(column[i]);
                    td1.appendChild(text1);
                    tr.appendChild(td1);
                    var td2 = document.createElement('td');
                    var text2 = document.createTextNode(keyvalue_1[i]);
                    td2.appendChild(text2);
                    tr.appendChild(td2);
                    var td3 = document.createElement('td');
                    var text3 = document.createTextNode(keyvalue_2[i]);
                    td3.appendChild(text3);
                    tr.appendChild(td3);
                    var td4 = document.createElement('td');
                    var text4 = document.createTextNode(keyvalue_3[i]);
                    td4.appendChild(text4);
                    tr.appendChild(td4);

                    userTable.appendChild(tr);
                }

                dynamicviewquery.appendChild(userTable);
            } catch (error) {
                
            }
        }//

        //1. Result SELECT doc FROM table UNION SELECT doc FROM table WHERE column = ?;
        if(selectedtqueryStr === 'union' && querycol !== '' && querycol_2 !== '' && querycol_3 !== '' && 
        colname_1 !== '' && colname_2 === '' && colname_3 === ''){
            var hostnametaskStr = document.getElementById('hostnamequery').value;
            var hostporttaskStr = document.getElementById('hostportquery').value;
            var usernametaskStr = document.getElementById('usernamequery').value;
            var passwordnametaskStr = document.getElementById('passwordnamequery').value;
            //xxxxxxxxxxxxxxx
            var databasequeryStr = document.getElementById('databasequery').value;
            var templatequeryStr = document.getElementById('templatequery').value;
            var serverqueryStr = document.getElementById('serverquery').value;
            var documentqueryStr = document.getElementById('documentquery').value;
            try {
                //call function specificDocumentOneColumn
                var resultat_1 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol, colname_1, serverqueryStr);
                var keyvalue_1 = [resultat_1];
                
                var resultat_2 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol_2, colname_1, serverqueryStr);
                var keyvalue_2 = [resultat_2];

                var resultat_3 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol_3, colname_1, serverqueryStr);
                var keyvalue_3 = [resultat_3];

                var columnname = [colname_1];

                var querydoc = 'Column';
                var querydocname_1 = 'Doc'+querycol;
                var querydocname_2 = 'Doc'+querycol_2;
                var querydocname_3 = 'Doc'+querycol_3;

                //concat the two header
                var headerconcat = [querydoc,querydocname_1,querydocname_2,querydocname_3];

                //display number of document queried
                var tableLength = (keyvalue_1.length + keyvalue_2.length + keyvalue_3.length);
                var documentnumber = document.getElementById('dynamicviewdocumentquery');
                documentnumber.innerText = 'Query Results: '+tableLength;

                //1.create dynamic table
                var userTable = document.createElement('table');
                var tr = document.createElement('tr');
                userTable.setAttribute('id', 'usertable');
                userTable.setAttribute('class', 'thin_table_config');
                //2.add header to the table
                for(let j=0; j<headerconcat.length; j++){
                    var th = document.createElement('th'); //column
                    var text = document.createTextNode(headerconcat[j]); //cell
                    th.appendChild(text);
                    tr.appendChild(th);
                }
                userTable.appendChild(tr);
                //3.add rows dynamically to the table
                for(let i=0; i<keyvalue_1.length; i++){
                    var tr = document.createElement('tr');
                    var td1 = document.createElement('td');
                    var text1 = document.createTextNode(columnname[i]);
                    td1.appendChild(text1);
                    tr.appendChild(td1);
                    var td2 = document.createElement('td');
                    var text2 = document.createTextNode(keyvalue_1[i]);
                    td2.appendChild(text2);
                    tr.appendChild(td2);
                    var td3 = document.createElement('td');
                    var text3 = document.createTextNode(keyvalue_2[i]);
                    td3.appendChild(text3);
                    tr.appendChild(td3);
                    var td4 = document.createElement('td');
                    var text4 = document.createTextNode(keyvalue_3[i]);
                    td4.appendChild(text4);
                    tr.appendChild(td4);

                    userTable.appendChild(tr);
                }

                dynamicviewquery.appendChild(userTable);
            } catch (error) {
                
            }
        }//

        //1. Result SELECT doc FROM table UNION SELECT doc FROM table WHERE column = ?;
        if(selectedtqueryStr === 'union' && querycol !== '' && querycol_2 !== '' && querycol_3 !== '' && 
        colname_1 !== '' && colname_2 !== '' && colname_3 === ''){
            var hostnametaskStr = document.getElementById('hostnamequery').value;
            var hostporttaskStr = document.getElementById('hostportquery').value;
            var usernametaskStr = document.getElementById('usernamequery').value;
            var passwordnametaskStr = document.getElementById('passwordnamequery').value;
            //xxxxxxxxxxxxxxx
            var databasequeryStr = document.getElementById('databasequery').value;
            var templatequeryStr = document.getElementById('templatequery').value;
            var serverqueryStr = document.getElementById('serverquery').value;
            var documentqueryStr = document.getElementById('documentquery').value;
            try {
                //call function specificDocumentTwoColumn
                //first column
                var resultat_first_1 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol, colname_1, serverqueryStr);
                var resultat_first_2 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol, colname_2, serverqueryStr);
                var valeur_first = [resultat_first_1,resultat_first_2];
                

                //second column
                var resultat_second_1 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol_2, colname_1, serverqueryStr);
                var resultat_second_2 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol_2, colname_2, serverqueryStr);
                var valeur_second = [resultat_second_1,resultat_second_2];

                //third column
                var resultat_third_1 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol_3, colname_1, serverqueryStr);
                var resultat_third_2 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol_3, colname_2, serverqueryStr);
                var valeur_third = [resultat_third_1,resultat_third_2];

                var colnames = [colname_1,colname_2];

                var querydoc = 'Column';
                var querydocname_1 = 'Doc'+querycol;
                var querydocname_2 = 'Doc'+querycol_2;
                var querydocname_3 = 'Doc'+querycol_3;
                //concat the two header
                var headerconcat = [querydoc,querydocname_1,querydocname_2,querydocname_3];

                //display number of document queried
                var tableLength = (valeur_first.length + valeur_second.length + valeur_third.length);
                var documentnumber = document.getElementById('dynamicviewdocumentquery');
                documentnumber.innerText = 'Query Results: '+tableLength;

                //1.create dynamic table
                var userTable = document.createElement('table');
                var tr = document.createElement('tr');
                userTable.setAttribute('id', 'usertable');
                userTable.setAttribute('class', 'thin_table_config');
                //2.add header to the table
                for(let j=0; j<headerconcat.length; j++){
                    var th = document.createElement('th'); //column
                    var text = document.createTextNode(headerconcat[j]); //cell
                    th.appendChild(text);
                    tr.appendChild(th);
                }
                userTable.appendChild(tr);
                //3.add rows dynamically to the table
                for(let i=0; i<valeur_first.length; i++){
                    var tr = document.createElement('tr');
                    var td1 = document.createElement('td');
                    var text1 = document.createTextNode(colnames[i]);
                    td1.appendChild(text1);
                    tr.appendChild(td1);

                    var tdfirst = document.createElement('td');
                    var textfirst = document.createTextNode(valeur_first[i]);
                    tdfirst.appendChild(textfirst);
                    tr.appendChild(tdfirst);

                    var tdsecond = document.createElement('td');
                    var textsecond = document.createTextNode(valeur_second[i]);
                    tdsecond.appendChild(textsecond);
                    tr.appendChild(tdsecond);

                    var tdthird = document.createElement('td');
                    var textthird = document.createTextNode(valeur_third[i]);
                    tdthird.appendChild(textthird);
                    tr.appendChild(tdthird);

                    userTable.appendChild(tr);
                }
                
                dynamicviewquery.appendChild(userTable);
            } catch (error) {
                
            }
        }//

        //1. Result SELECT doc FROM table UNION SELECT doc FROM table WHERE column = ?;
        if(selectedtqueryStr === 'union' && querycol !== '' && querycol_2 !== '' && querycol_3 !== '' && 
        colname_1 !== '' && colname_2 !== '' && colname_3 !== ''){
            var hostnametaskStr = document.getElementById('hostnamequery').value;
            var hostporttaskStr = document.getElementById('hostportquery').value;
            var usernametaskStr = document.getElementById('usernamequery').value;
            var passwordnametaskStr = document.getElementById('passwordnamequery').value;
            //xxxxxxxxxxxxxxx
            var databasequeryStr = document.getElementById('databasequery').value;
            var templatequeryStr = document.getElementById('templatequery').value;
            var serverqueryStr = document.getElementById('serverquery').value;
            var documentqueryStr = document.getElementById('documentquery').value;
            try {
                //call function specificDocumentTwoColumn
                //first column
                var resultat_first_1 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol, colname_1, serverqueryStr);
                var keyvalue_first_1 = [resultat_first_1];
                var resultat_first_2 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol, colname_2, serverqueryStr);
                var keyvalue_first_2 = [resultat_first_2];
                var resultat_first_3 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol, colname_3, serverqueryStr);
                var keyvalue_first_3 = [resultat_first_3];
                var valeur_first = [resultat_first_1,resultat_first_2,resultat_first_3];

                //second column
                var resultat_second_1 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol_2, colname_1, serverqueryStr);
                var keyvalue_second_1 = [resultat_second_1];
                var resultat_second_2 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol_2, colname_2, serverqueryStr);
                var keyvalue_second_2 = [resultat_second_2];
                var resultat_second_3 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol_2, colname_3, serverqueryStr);
                var keyvalue_second_3 = [resultat_second_3];
                var valeur_second = [resultat_second_1,resultat_second_2,resultat_second_3];

                //third column
                var resultat_third_1 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol_3, colname_1, serverqueryStr);
                var keyvalue_third_1 = [resultat_third_1];
                var resultat_third_2 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol_3, colname_2, serverqueryStr);
                var keyvalue_third_2 = [resultat_third_2];
                var resultat_third_3 = await specificDocumentOneColumn(hostnametaskStr, hostporttaskStr, usernametaskStr, passwordnametaskStr, databasequeryStr, templatequeryStr, documentqueryStr, querycol_3, colname_3, serverqueryStr);
                var keyvalue_third_3 = [resultat_third_3];
                var valeur_third = [resultat_third_1,resultat_third_2,resultat_third_3];

                var colnames = [colname_1,colname_2,colname_3];

                var querydoc = 'Column';
                var querydocname_1 = 'Doc'+querycol;
                var querydocname_2 = 'Doc'+querycol_2;
                var querydocname_3 = 'Doc'+querycol_3;
                //concat the two header
                var headerconcat = [querydoc,querydocname_1,querydocname_2,querydocname_3];

                //display number of document queried
                var tableLength = (valeur_first.length + valeur_second.length + valeur_third.length);
                var documentnumber = document.getElementById('dynamicviewdocumentquery');
                documentnumber.innerText = 'Query Results: '+tableLength;

                //1.create dynamic table
                var userTable = document.createElement('table');
                var tr = document.createElement('tr');
                userTable.setAttribute('id', 'usertable');
                userTable.setAttribute('class', 'thin_table_config');
                //2.add header to the table
                for(let j=0; j<headerconcat.length; j++){
                    var th = document.createElement('th'); //column
                    var text = document.createTextNode(headerconcat[j]); //cell
                    th.appendChild(text);
                    tr.appendChild(th);
                }
                userTable.appendChild(tr);
                //3.add rows dynamically to the table
                for(let i=0; i<valeur_first.length; i++){
                    var tr = document.createElement('tr');
                    var td1 = document.createElement('td');
                    var text1 = document.createTextNode(colnames[i]);
                    td1.appendChild(text1);
                    tr.appendChild(td1);

                    var tdfirst = document.createElement('td');
                    var textfirst = document.createTextNode(valeur_first[i]);
                    tdfirst.appendChild(textfirst);
                    tr.appendChild(tdfirst);

                    var tdsecond = document.createElement('td');
                    var textsecond = document.createTextNode(valeur_second[i]);
                    tdsecond.appendChild(textsecond);
                    tr.appendChild(tdsecond);

                    var tdthird = document.createElement('td');
                    var textthird = document.createTextNode(valeur_third[i]);
                    tdthird.appendChild(textthird);
                    tr.appendChild(tdthird);

                    userTable.appendChild(tr);
                }
                
                dynamicviewquery.appendChild(userTable);
            } catch (error) {
                
            }
        }
    }
    /**Clear and update the UI to display the docs.*/
    function resultQueryTableUIWithDocs(docs) {
        var dynamicviewquery = document.getElementById("dynamicviewquery");

        // Remove all child nodes from the table
        while (dynamicviewquery.hasChildNodes()) {
            dynamicviewquery.removeChild(dynamicviewquery.childNodes[0]);
        }

        // Add the new documents to the div
        for (var i = 0; i<1; i++) {
            var doc = docs[i];
            resultQueryDocToToTable(doc, dynamicviewquery);
        }
    }//

    //back to new query
    document.getElementById("confirmdynamicqueryview").addEventListener("click", confirmdynamicqueryviewChange);
    async function confirmdynamicqueryviewChange(e){
        e.preventDefault();

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'none';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'block';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }

    //Undo table query selected
    document.getElementById("canceldynamicqueryview").addEventListener("click", canceldynamicqueryviewChange);
    async function canceldynamicqueryviewChange(e){
        e.preventDefault();

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('documentqueries').style.display = 'block';
        document.getElementById('querydocumentqueries').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('connectqueries').style.display = 'none';
        document.getElementById('listviewqueries').style.display = 'block';
        document.getElementById('viewdisplayqueries').style.display = 'none';
        document.getElementById('viewdocqueries').style.display = 'none';
        document.getElementById('editorqueries').style.display = 'none';
        document.getElementById('dynamictablequeries').style.display = 'none';
        //xxxxxxxxxxxxxxx sub-components xxxxxxxxxxx
        document.getElementById('outputquery').style.display = 'none';
        document.getElementById('executerquery').style.display = 'none';
        //select components
        document.getElementById('selecttable').style.display = 'none';
        document.getElementById('selectonecolumn').style.display = 'none';
        document.getElementById('selecttwocolumn').style.display = 'none';
        document.getElementById('selectthreecolumn').style.display = 'none';
        //count components
        document.getElementById('counttable').style.display = 'none';
        document.getElementById('countonecolumn').style.display = 'none';
        document.getElementById('counttwocolumn').style.display = 'none';
        document.getElementById('countthreecolumn').style.display = 'none';
        //union components
        document.getElementById('uniontable').style.display = 'none';
        document.getElementById('uniontwodocument').style.display = 'none';
        document.getElementById('uniontwodoconecolumn').style.display = 'none';
        document.getElementById('uniontwodoctwocolumn').style.display = 'none';
        document.getElementById('uniontwodocthreecolumn').style.display = 'none';
        document.getElementById('unionthreedocument').style.display = 'none';
        document.getElementById('unionthreedoconecolumn').style.display = 'none';
        document.getElementById('unionthreedoctwocolumn').style.display = 'none';
        document.getElementById('unionthreedocthreecolumn').style.display = 'none';
    }
});

/**1 connect to specified database */
function couchProvider(hostname, portnumber, username, password, typebasename){
    //alert
    var elementOutId = 'modaloutalertqueries';
    var elementDropId = 'backdropoutalertqueries';
    var buttonHandler = 'ouiHandlerAlertQueriesBtn';

    var dbName = '';
    var remoteCouch = '';
    var options = {
        live:true,
        retry:true,
        continuous:true,
        attachments: true,
    };

    try {
        dbName = new PouchDB(typebasename);
        remoteCouch = new PouchDB('http://'+username+':'+password+'@'+hostname+':'+portnumber+'/'+typebasename);
        PouchDB.plugin(PouchFind);
        dbName.sync(remoteCouch,options);
    } catch (error) {
        var pTexteerreurconnection = "Connection Failed";
        alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
    }
}

/**2: alertmodal function*/
function alertModal(elementOutId,elementDropId,pTexte,buttonHandler){
    var divClearModal = document.getElementById(elementOutId);
    let divModal = document.createElement('div');
    divModal.innerHTML =
    `<div class="modalalert">
        <p>${pTexte}</p>
        <button class="btnalert btnalert--alt" id="${buttonHandler}">OK</button>
    </div>`;
    divClearModal.appendChild(divModal);

    var divClearBackdrop = document.getElementById(elementDropId);
    let divBack = document.createElement('div');
    divBack.innerHTML =
    `<div class="backdropalert"></div>`;
    divClearBackdrop.appendChild(divBack);

    var buttonChange = document.getElementById(buttonHandler);
    buttonChange.addEventListener('click', (e) =>{
        e.preventDefault();

        var divClearModal = document.getElementById(elementOutId);
        while(divClearModal.hasChildNodes()) {
            divClearModal.removeChild(divClearModal.childNodes[0]);
        }

        var divClearModalDrop = document.getElementById(elementDropId);
        while(divClearModalDrop.hasChildNodes()) {
            divClearModalDrop.removeChild(divClearModalDrop.childNodes[0]);
        }
    }); 
};

/**implement autocomplete list of columns function */
function autocomplete(inp, arr){
    /*the autocomplete function takes two arguments,
    the text field element and an array of possible autocompleted values:*/
    var currentFocus;
    /*execute a function when someone writes in the text field:*/
    inp.addEventListener("input", function(e) {
        var a, b, i, val = this.value;
        /*close any already open lists of autocompleted values*/
        closeAllLists();
        if(!val){return false;}
        currentFocus = -1;
        /*create a DIV element that will contain the items (values):*/
        a = document.createElement("DIV");
        a.setAttribute("id", this.id + "autocomplete-list");
        a.setAttribute("class", "autocomplete-items");
        /*append the DIV element as a child of the autocomplete container:*/
        this.parentNode.appendChild(a);
        /*for each item in the array...*/
        for(i=0; i<arr.length; i++){
            /*check if the item starts with the same letters as the text field value:*/
            if(arr[i].substr(0, val.length).toUpperCase() === val.toUpperCase()){
                /*create a DIV element for each matching element:*/
                b = document.createElement("DIV");
                /*make the matching letters bold:*/
                b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                b.innerHTML += arr[i].substr(val.length);
                /*insert a input field that will hold the current array item's value:*/
                b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                /*execute a function when someone clicks on the item value (DIV element):*/
                b.addEventListener("click", function(e) {
                    /*insert the value for the autocomplete text field:*/
                    inp.value = this.getElementsByTagName("input")[0].value;
                    /*close the list of autocompleted values,(or any other open lists of autocompleted values:*/
                    closeAllLists();
                });
                a.appendChild(b);
            }
        }
    });
    /*execute a function presses a key on the keyboard:*/
    inp.addEventListener("keydown", function(e) {
        var x = document.getElementById(this.id + "autocomplete-list");
        if (x) x = x.getElementsByTagName("div");
        if (e.keyCode === 40) {
            /*If the arrow DOWN key is pressed,
            increase the currentFocus variable:*/
            currentFocus++;
            /*and and make the current item more visible:*/
            addActive(x);
        } else if (e.keyCode === 38) { //up
            /*If the arrow UP key is pressed,
            decrease the currentFocus variable:*/
            currentFocus--;
            /*and and make the current item more visible:*/
            addActive(x);
        } else if (e.keyCode === 13) {
            /*If the ENTER key is pressed, prevent the form from being submitted,*/
            e.preventDefault();
            if (currentFocus > -1) {
              /*and simulate a click on the "active" item:*/
              if (x) x[currentFocus].click();
            }
        }
    });
    function addActive(x) {
        /*a function to classify an item as "active":*/
        if (!x) return false;
        /*start by removing the "active" class on all items:*/
        removeActive(x);
        if (currentFocus >= x.length) currentFocus = 0;
        if (currentFocus < 0) currentFocus = (x.length - 1);
        /*add class "autocomplete-active":*/
        x[currentFocus].classList.add("autocomplete-active");
    }
    function removeActive(x) {
        /*a function to remove the "active" class from all autocomplete items:*/
        for (var i = 0; i < x.length; i++) {
          x[i].classList.remove("autocomplete-active");
        }
    }
    function closeAllLists(elmnt) {
        /*close all autocomplete lists in the document,
        except the one passed as an argument:*/
        var x = document.getElementsByClassName("autocomplete-items");
        for (var i = 0; i < x.length; i++) {
          if (elmnt !== x[i] && elmnt !== inp) {
            x[i].parentNode.removeChild(x[i]);
          }
        }
    }
    /*execute a function when someone clicks in the document:*/
    document.addEventListener("click", function (e) {
        closeAllLists(e.target);
    });
};

//check if string is number or not
function isNum(val){
    return !isNaN(val);
}

//Getting equal or greater than number from the list of numbers in JavaScript
//get equal number function
function findEqual(arr, num){
    const res = [];
    for(let i = 0; i < arr.length; i++){
        if(arr[i] === num){
            res.push(arr[i]);
        };
    }
    return res;
}
//get smaller number function
function findSmaller(arr, num){
    const res = [];
    for(let i=0; i<arr.length; i++){
        if(arr[i] < num){
            res.push(arr[i]);
        };
    }
    return res;
}
//get greater number function
function findGreater(arr, num){
    const res = [];
    for(let i=0; i<arr.length; i++){
        if(arr[i] > num){
            res.push(arr[i]);
        };
    }
    return res;
}

//Getting equal string from the list of strings in JavaScript
function findString(arr, valeur){
    const res = [];
    for(let i = 0; i < arr.length; i++){
        if(arr[i] === valeur){
            res.push(arr[i]);
        };
    }
    return res;
}

// program to find the largest among three numbers
function greaterOne(valeurone, valeurtwo, valeurthree){
    let largest;
    // check the condition
    if(valeurone === valeurtwo && valeurone === valeurthree){
        largest = "equal";
    }else if(valeurone > valeurtwo && valeurone > valeurthree){
        largest = "val1";
    }else if(valeurtwo > valeurone && valeurtwo > valeurthree){
        largest = "val2";
    }else if(valeurone === valeurtwo && valeurone > valeurthree){
        largest = "val1";
    }else if(valeurone === valeurtwo && valeurone < valeurthree){
        largest = "val3";
    }else if(valeurone < valeurtwo && valeurone === valeurthree){
        largest = "val2";
    }else if(valeurone < valeurtwo && valeurone < valeurthree){
        if(valeurtwo === valeurthree){
            largest = "val2";
        }else if(valeurtwo < valeurthree){
            largest = "val3"
        }else{
            largest = "val2";
        }  
    }else if(valeurone > valeurtwo && valeurone === valeurthree){
        largest = "val1";
    }else if(valeurone > valeurtwo && valeurone < valeurthree){
        largest = "val3"
    }else{
        largest = "val3";
    }
    return largest;
}

//function retrieve data based on document name
async function specificDocument(hostname, portnumber, username, password, typebasename, template, docname, docvalue, servername){
    //alert
    var elementOutId = 'modaloutalertqueries';
    var elementDropId = 'backdropoutalertqueries';
    var buttonHandler = 'ouiHandlerAlertQueriesBtn';

    var deviceStr = document.getElementById('device').innerText;
    var serveurHeaderStr = document.getElementById('serveurheader').innerText;

    var dbName = '';
    var remoteCouch = '';
    var options = {
        live:true,
        retry:true,
        continuous:true,
        attachments: true,
    };

    if((deviceStr === '' && serveurHeaderStr === 'local') || 
    (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
        dbName = new PouchDB(typebasename, {skip_setup: true,});
        remoteCouch = new PouchDB('http://'+username+':'+password+'@'+hostname+':'+portnumber+'/'+typebasename, { skip_setup: true });
        PouchDB.plugin(PouchFind);
        dbName.sync(remoteCouch,options);

        var docsSpecificDocument = [];
        try {
            let result = await dbName.createIndex({
                index: {
                    fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "documentname",
                        "documentnumber",
                    ],
                },
            });
            result = await dbName.find({
                selector: {
                    taskdatabase: typebasename,
                    taskname: template,
                    taskserver: servername,
                    documentname: docname,
                    documentnumber: docvalue,
                },
            });
            docsSpecificDocument = result.docs;
            var resultatSpecificDocument = docsSpecificDocument.length;
            if(resultatSpecificDocument === 0){
                var pTextenodocument = "No Document available";
                alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
            }else{
                var resultat = [];
                //var valeur = [];
                for(let i=0; i<docsSpecificDocument.length; i++){
                    resultat = docsSpecificDocument[i].taskdata;
                }

                return resultat;
            }
        } catch (error) {
            var pTexteerreurconnection = "Connection Failed";
            alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
        }
    };

    if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
        dbName = new PouchDB(typebasename, {skip_setup: true,});
        PouchDB.plugin(PouchFind);

        var docsSpecificDocument = [];
        try {
            let result = await dbName.createIndex({
                index: {
                    fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "documentname",
                        "documentnumber",
                    ],
                },
            });
            result = await dbName.find({
                selector: {
                    taskdatabase: typebasename,
                    taskname: template,
                    taskserver: servername,
                    documentname: docname,
                    documentnumber: docvalue,
                },
            });
            docsSpecificDocument = result.docs;
            var resultatSpecificDocument = docsSpecificDocument.length;
            if(resultatSpecificDocument === 0){
                var pTextenodocument = "No Document available";
                alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
            }else{
                var resultat = [];
                //var valeur = [];
                for(let i=0; i<docsSpecificDocument.length; i++){
                    resultat = docsSpecificDocument[i].taskdata;
                }

                return resultat;
            }
        } catch (error) {
            var pTexteerreurconnection = "Connection Failed";
            alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
        }
    };
}

//function retrieve data based on document name and on column name
async function specificDocumentOneColumn(hostname, portnumber, username, password, typebasename, template, docname, docvalue, colname, servername){
    //alert
    var elementOutId = 'modaloutalertqueries';
    var elementDropId = 'backdropoutalertqueries';
    var buttonHandler = 'ouiHandlerAlertQueriesBtn';

    var deviceStr = document.getElementById('device').innerText;
    var serveurHeaderStr = document.getElementById('serveurheader').innerText;

    var dbName = '';
    var remoteCouch = '';
    var options = {
        live:true,
        retry:true,
        continuous:true,
        attachments: true,
    };

    if((deviceStr === '' && serveurHeaderStr === 'local') || 
    (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
        dbName = new PouchDB(typebasename, {skip_setup: true,});
        remoteCouch = new PouchDB('http://'+username+':'+password+'@'+hostname+':'+portnumber+'/'+typebasename, { skip_setup: true });
        PouchDB.plugin(PouchFind);
        dbName.sync(remoteCouch,options);

        var docsSpecificDocument = [];
        try {
            let result = await dbName.createIndex({
                index: {
                    fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "documentname",
                        "documentnumber",
                    ],
                },
            });
            result = await dbName.find({
                selector: {
                    taskdatabase: typebasename,
                    taskname: template,
                    taskserver: servername,
                    documentname: docname,
                    documentnumber: docvalue,
                },
            });
            docsSpecificDocument = result.docs;
            var resultatSpecificDocument = docsSpecificDocument.length;
            if(resultatSpecificDocument === 0){
                var pTextenodocument = "No Document available";
                alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
            }else{
                var resultat = [];
                for(let i=0; i<docsSpecificDocument.length; i++){
                    resultat = docsSpecificDocument[i].taskdata;
                }
                var keyvalue = [resultat];
                var res = keyvalue[0][colname];
            
                return res;
            }
        } catch (error) {
            var pTexteerreurconnection = "Connection Failed";
            alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
        }
    };

    if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
        dbName = new PouchDB(typebasename, {skip_setup: true,});
        PouchDB.plugin(PouchFind);

        var docsSpecificDocument = [];
        try {
            let result = await dbName.createIndex({
                index: {
                    fields: [
                        "taskdatabase",
                        "taskname",
                        "taskserver",
                        "documentname",
                        "documentnumber",
                    ],
                },
            });
            result = await dbName.find({
                selector: {
                    taskdatabase: typebasename,
                    taskname: template,
                    taskserver: servername,
                    documentname: docname,
                    documentnumber: docvalue,
                },
            });
            docsSpecificDocument = result.docs;
            var resultatSpecificDocument = docsSpecificDocument.length;
            if(resultatSpecificDocument === 0){
                var pTextenodocument = "No Document available";
                alertModal(elementOutId,elementDropId,pTextenodocument,buttonHandler);
            }else{
                var resultat = [];
                for(let i=0; i<docsSpecificDocument.length; i++){
                    resultat = docsSpecificDocument[i].taskdata;
                }
                var keyvalue = [resultat];
                var res = keyvalue[0][colname];
            
                return res;
            }
        } catch (error) {
            var pTexteerreurconnection = "Connection Failed";
            alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
        }
    };
}