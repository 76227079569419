/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';

window.addEventListener('load', () => {
    //local database test
    document.getElementById('localdatabaseserver').addEventListener('click', localdatabaseserverChange);
    async function localdatabaseserverChange(e){
        e.preventDefault();

        var deviceStr = document.getElementById('device').innerText;

        var divClearDevice = document.getElementById('device');
        while(divClearDevice.hasChildNodes()) {
            divClearDevice.removeChild(divClearDevice.childNodes[0]);
        }

        document.getElementById('databasetasks').style.display = 'block';
        document.getElementById('queriestasks').style.display = 'none';
        document.getElementById('crudactions').style.display = 'none';

        if(deviceStr === ''){
            var deviceStr = document.getElementById('device');
            deviceStr.innerText = '';
            var databasetypeForm = document.getElementById('databasetextform');
            databasetypeForm.innerText = "Desktop Local Database Tasks";

            var textLocal = document.getElementById('textlocal');
            textLocal.innerText = "Desktop Local Database"; 
        }

        if(deviceStr === 'mobile'){
            var deviceStr = document.getElementById('device');
            deviceStr.innerText = 'mobile';
            var databasetypeForm = document.getElementById('databasetextform');
            databasetypeForm.innerText = "Mobile Local Database Tasks";

            var textLocal = document.getElementById('textlocal');
            textLocal.innerText = "Mobile Local Database"; 
        }

        var serveurHeader = document.getElementById('serveurheader');
        serveurHeader.innerText = "local";
        serveurHeader.style.color = 'red';
    }

    //local database test
    document.getElementById('remotedatabaseserver').addEventListener('click', remotedatabaseserverChange);
    async function remotedatabaseserverChange(e){
        e.preventDefault();

        var deviceStr = document.getElementById('device').innerText;

        document.getElementById('databasetasks').style.display = 'block';
        document.getElementById('queriestasks').style.display = 'none';
        document.getElementById('crudactions').style.display = 'none';

        if(deviceStr === ''){
            var deviceStr = document.getElementById('device');
            deviceStr.innerText = '';
            var databasetypeForm = document.getElementById('databasetextform');
            databasetypeForm.innerText = "Desktop Remote Database Tasks";

            var textLocal = document.getElementById('textlocal');
            textLocal.innerText = "Desktop remote Database"; 
        }

        if(deviceStr === 'mobile'){
            var deviceStr = document.getElementById('device');
            deviceStr.innerText = 'mobile';
            var databasetypeForm = document.getElementById('databasetextform');
            databasetypeForm.innerText = "Mobile Remote Database Tasks";

            var textLocal = document.getElementById('textlocal');
            textLocal.innerText = "Mobile Remote Database"; 
        }

        /*var databasetypeForm = document.getElementById('databasetextform');
        databasetypeForm.innerText = "Remote Database Tasks";

        var textLocal = document.getElementById('textlocal');
        textLocal.innerText = "Remote Database";*/

        var serveurHeader = document.getElementById('serveurheader');
        serveurHeader.innerText = "remote";
        serveurHeader.style.color = 'red';
    }

    //new database test
    document.getElementById('newdatabase').addEventListener('click', newdatabaseChange);
    async function newdatabaseChange(e){
        e.preventDefault();

        var deviceStr = document.getElementById('device').innerText;
        var serveurHeaderStr = document.getElementById('serveurheader').innerText;

        document.getElementById('databasetasks').style.display = 'block';
        document.getElementById('queriestasks').style.display = 'block';
        document.getElementById('crudactions').style.display = 'block';
        document.getElementById('adddatabase').style.display = 'block';
        document.getElementById('editdatabase').style.display = 'none';
        document.getElementById('deletedatabase').style.display = 'none';
        //xxxxxxxxx crudbase xxxxxxxxx
        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            document.getElementById('newbase').style.display = 'block';
            document.getElementById('deskdatabase').style.display = 'block';
            document.getElementById('mobiledatabase').style.display = 'none';
            document.getElementById('listbase').style.display = 'none';
            document.getElementById('previousbase').style.display = 'none';
        }

        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            document.getElementById('newbase').style.display = 'block';
            document.getElementById('deskdatabase').style.display = 'none';
            document.getElementById('mobiledatabase').style.display = 'block';
            document.getElementById('listbase').style.display = 'none';
            document.getElementById('previousbase').style.display = 'none';
        }
        
    }

    //previous database test
    //set global variable
    var typecrud = '';
    document.getElementById('previousdatabase').addEventListener('click', previousdatabaseChange);
    async function previousdatabaseChange(e){
        e.preventDefault();

        var divClearModal = document.getElementById('modaloutdatabase');
        let divModal = document.createElement('div');
        divModal.innerHTML =
        `<div class="modal">
            <p>Select your task</p>
            <button class="btn btn--alt" id="ouiHandlerEditBtn">Edit</button>
            <button class="btn" id="nonHandlerDeleteBtn">Delete</button>
        </div>`;
        divClearModal.appendChild(divModal);

        var divClearBackdrop = document.getElementById('backdropoutdatabase');
        let divBack = document.createElement('div');
        divBack.innerHTML =
        `<div class="backdrop" id="backdropHandlerCloseBtn"></div>`;
        divClearBackdrop.appendChild(divBack);

        //If Edit Clicked
        document.getElementById('ouiHandlerEditBtn').addEventListener('click', ouiHandlerEditBtnChange);
        async function ouiHandlerEditBtnChange(e){
            e.preventDefault();

            var deviceStr = document.getElementById('device').innerText;
            var serveurHeaderStr = document.getElementById('serveurheader').innerText;

            typecrud = 'edit';

            document.getElementById('databasetasks').style.display = 'block';
            document.getElementById('queriestasks').style.display = 'block';
            document.getElementById('crudactions').style.display = 'block';
            document.getElementById('adddatabase').style.display = 'none';
            document.getElementById('editdatabase').style.display = 'none';
            document.getElementById('deletedatabase').style.display = 'none';
            
            if((deviceStr === '' && serveurHeaderStr === 'local') || 
            (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
                //xxxxxxxxx crudbase xxxxxxxxx
                document.getElementById('newbase').style.display = 'none';
                document.getElementById('listbase').style.display = 'none';
                document.getElementById('previousbase').style.display = 'block';
                //xxxxxxx component input field
                document.getElementById('prevdeskbase').style.display = 'block';
                document.getElementById('prevmobilebase').style.display = 'none';
                //xxxxxxxxxxxxx 
                document.getElementById('dataname').style.display = 'none';
                document.getElementById('servername').style.display = 'none';
                document.getElementById('confirmer').style.display = 'block';
                document.getElementById('confirmermobile').style.display = 'none';
                //removeing attribute: readonly
                var hostnamebasechangetext = document.getElementById('hostnamebasechange');
                hostnamebasechangetext.removeAttribute("readonly");
                hostnamebasechangetext.value = '';
                var hostportbasechangetext = document.getElementById('hostportbasechange');
                hostportbasechangetext.removeAttribute("readonly");
                hostportbasechangetext.value = '';
                var usernamebasechangetext = document.getElementById('usernamebasechange');
                usernamebasechangetext.removeAttribute("readonly");
                usernamebasechangetext.value = '';
                var passwordnamebasechangetext = document.getElementById('passwordnamebasechange');
                passwordnamebasechangetext.removeAttribute("readonly");
                passwordnamebasechangetext.value = '';
                //removeing attribute: readonly
                var mobileusernamebasechangetext = document.getElementById('mobileusernamebasechange');
                mobileusernamebasechangetext.removeAttribute("readonly");
                mobileusernamebasechangetext.value = '';
                var mobilepasswordnamebasechangetext = document.getElementById('mobilepasswordnamebasechange');
                mobilepasswordnamebasechangetext.removeAttribute("readonly");
                mobilepasswordnamebasechangetext.value = '';
            }

            if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
                //xxxxxxxxx crudbase xxxxxxxxx
                document.getElementById('newbase').style.display = 'none';
                document.getElementById('listbase').style.display = 'none';
                document.getElementById('previousbase').style.display = 'block';
                //xxxxxxx component input field
                document.getElementById('prevdeskbase').style.display = 'none';
                document.getElementById('prevmobilebase').style.display = 'block';
                //xxxxxxxxxxxxx 
                document.getElementById('dataname').style.display = 'none';
                document.getElementById('servername').style.display = 'none';
                document.getElementById('confirmer').style.display = 'none';
                document.getElementById('confirmermobile').style.display = 'block';
                //removeing attribute: readonly
                var hostnamebasechangetext = document.getElementById('hostnamebasechange');
                hostnamebasechangetext.removeAttribute("readonly");
                hostnamebasechangetext.value = '';
                var hostportbasechangetext = document.getElementById('hostportbasechange');
                hostportbasechangetext.removeAttribute("readonly");
                hostportbasechangetext.value = '';
                var usernamebasechangetext = document.getElementById('usernamebasechange');
                usernamebasechangetext.removeAttribute("readonly");
                usernamebasechangetext.value = '';
                var passwordnamebasechangetext = document.getElementById('passwordnamebasechange');
                passwordnamebasechangetext.removeAttribute("readonly");
                passwordnamebasechangetext.value = '';
                //removeing attribute: readonly
                var mobileusernamebasechangetext = document.getElementById('mobileusernamebasechange');
                mobileusernamebasechangetext.removeAttribute("readonly");
                mobileusernamebasechangetext.value = '';
                var mobilepasswordnamebasechangetext = document.getElementById('mobilepasswordnamebasechange');
                mobilepasswordnamebasechangetext.removeAttribute("readonly");
                mobilepasswordnamebasechangetext.value = '';
            };

            var divClearModal = document.getElementById('modaloutdatabase');
            while(divClearModal.hasChildNodes()) {
                divClearModal.removeChild(divClearModal.childNodes[0]);
            }

            var divClearBackdrop = document.getElementById('backdropoutdatabase');
            while(divClearBackdrop.hasChildNodes()) {
                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
            }
        }

        //If Delete Clicked
        document.getElementById('nonHandlerDeleteBtn').addEventListener('click', nonHandlerDeleteBtnChange);
        async function nonHandlerDeleteBtnChange(e){
            e.preventDefault();

            var deviceStr = document.getElementById('device').innerText;
            var serveurHeaderStr = document.getElementById('serveurheader').innerText;

            typecrud = 'delete';

            document.getElementById('databasetasks').style.display = 'block';
            document.getElementById('queriestasks').style.display = 'block';
            document.getElementById('crudactions').style.display = 'block';
            document.getElementById('adddatabase').style.display = 'none';
            document.getElementById('editdatabase').style.display = 'none';
            document.getElementById('deletedatabase').style.display = 'none';
            if((deviceStr === '' && serveurHeaderStr === 'local') || 
            (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
                //xxxxxxxxx crudbase xxxxxxxxx
                document.getElementById('newbase').style.display = 'none';
                document.getElementById('listbase').style.display = 'none';
                document.getElementById('previousbase').style.display = 'block';
                //xxxxxxx component input field
                document.getElementById('prevdeskbase').style.display = 'block';
                document.getElementById('prevmobilebase').style.display = 'none';
                //xxxxxxxxxxxxx 
                document.getElementById('dataname').style.display = 'none';
                document.getElementById('servername').style.display = 'none';
                document.getElementById('confirmer').style.display = 'block';
                document.getElementById('confirmermobile').style.display = 'none';
                //removeing attribute: readonly
                var hostnamebasechangetext = document.getElementById('hostnamebasechange');
                hostnamebasechangetext.removeAttribute("readonly");
                hostnamebasechangetext.value = '';
                var hostportbasechangetext = document.getElementById('hostportbasechange');
                hostportbasechangetext.removeAttribute("readonly");
                hostportbasechangetext.value = '';
                var usernamebasechangetext = document.getElementById('usernamebasechange');
                usernamebasechangetext.removeAttribute("readonly");
                usernamebasechangetext.value = '';
                var passwordnamebasechangetext = document.getElementById('passwordnamebasechange');
                passwordnamebasechangetext.removeAttribute("readonly");
                passwordnamebasechangetext.value = '';
                //removeing attribute: readonly
                var mobileusernamebasechangetext = document.getElementById('mobileusernamebasechange');
                mobileusernamebasechangetext.removeAttribute("readonly");
                mobileusernamebasechangetext.value = '';
                var mobilepasswordnamebasechangetext = document.getElementById('mobilepasswordnamebasechange');
                mobilepasswordnamebasechangetext.removeAttribute("readonly");
                mobilepasswordnamebasechangetext.value = '';
            }

            if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
                //xxxxxxxxx crudbase xxxxxxxxx
                document.getElementById('newbase').style.display = 'none';
                document.getElementById('listbase').style.display = 'none';
                document.getElementById('previousbase').style.display = 'block';
                //xxxxxxx component input field
                document.getElementById('prevdeskbase').style.display = 'none';
                document.getElementById('prevmobilebase').style.display = 'block';
                //xxxxxxxxxxxxx 
                document.getElementById('dataname').style.display = 'none';
                document.getElementById('servername').style.display = 'none';
                document.getElementById('confirmer').style.display = 'none';
                document.getElementById('confirmermobile').style.display = 'block';
                //removeing attribute: readonly
                var hostnamebasechangetext = document.getElementById('hostnamebasechange');
                hostnamebasechangetext.removeAttribute("readonly");
                hostnamebasechangetext.value = '';
                var hostportbasechangetext = document.getElementById('hostportbasechange');
                hostportbasechangetext.removeAttribute("readonly");
                hostportbasechangetext.value = '';
                var usernamebasechangetext = document.getElementById('usernamebasechange');
                usernamebasechangetext.removeAttribute("readonly");
                usernamebasechangetext.value = '';
                var passwordnamebasechangetext = document.getElementById('passwordnamebasechange');
                passwordnamebasechangetext.removeAttribute("readonly");
                passwordnamebasechangetext.value = '';
                //removeing attribute: readonly
                var mobileusernamebasechangetext = document.getElementById('mobileusernamebasechange');
                mobileusernamebasechangetext.removeAttribute("readonly");
                mobileusernamebasechangetext.value = '';
                var mobilepasswordnamebasechangetext = document.getElementById('mobilepasswordnamebasechange');
                mobilepasswordnamebasechangetext.removeAttribute("readonly");
                mobilepasswordnamebasechangetext.value = '';
            };

            var divClearModal = document.getElementById('modaloutdatabase');
            while(divClearModal.hasChildNodes()) {
                divClearModal.removeChild(divClearModal.childNodes[0]);
            }

            var divClearBackdrop = document.getElementById('backdropoutdatabase');
            while(divClearBackdrop.hasChildNodes()) {
                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
            }
        }

        //If backdrop Clicked
        document.getElementById('backdropHandlerCloseBtn').addEventListener('click', backdropHandlerCloseBtnChange);
        async function backdropHandlerCloseBtnChange(e){
            e.preventDefault();

            document.getElementById('databasetasks').style.display = 'block';
            document.getElementById('queriestasks').style.display = 'none';
            document.getElementById('crudactions').style.display = 'none';
            document.getElementById('adddatabase').style.display = 'none';
            document.getElementById('editdatabase').style.display = 'none';
            document.getElementById('deletedatabase').style.display = 'none';
            //xxxxxxxxx crudbase xxxxxxxxx
            document.getElementById('newbase').style.display = 'none';
            document.getElementById('listbase').style.display = 'none';
            document.getElementById('previousbase').style.display = 'none';
            //xxxxxxx input field
            document.getElementById('dataname').style.display = 'none';
            document.getElementById('servername').style.display = 'none';
            document.getElementById('confirmer').style.display = 'none';
            //removeing attribute: readonly
            var hostnamebasechangetext = document.getElementById('hostnamebasechange');
            hostnamebasechangetext.removeAttribute("readonly");
            hostnamebasechangetext.value = '';
            var hostportbasechangetext = document.getElementById('hostportbasechange');
            hostportbasechangetext.removeAttribute("readonly");
            hostportbasechangetext.value = '';
            var usernamebasechangetext = document.getElementById('usernamebasechange');
            usernamebasechangetext.removeAttribute("readonly");
            usernamebasechangetext.value = '';
            var passwordnamebasechangetext = document.getElementById('passwordnamebasechange');
            passwordnamebasechangetext.removeAttribute("readonly");
            passwordnamebasechangetext.value = '';
            //removeing attribute: readonly
            var mobileusernamebasechangetext = document.getElementById('mobileusernamebasechange');
            mobileusernamebasechangetext.removeAttribute("readonly");
            mobileusernamebasechangetext.value = '';
            var mobilepasswordnamebasechangetext = document.getElementById('mobilepasswordnamebasechange');
            mobilepasswordnamebasechangetext.removeAttribute("readonly");
            mobilepasswordnamebasechangetext.value = '';

            var divClearModal = document.getElementById('modaloutdatabase');
            while(divClearModal.hasChildNodes()) {
                divClearModal.removeChild(divClearModal.childNodes[0]);
            }

            var divClearBackdrop = document.getElementById('backdropoutdatabase');
            while(divClearBackdrop.hasChildNodes()) {
                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
            }
        };
    }

    //views database test
    document.getElementById('viewdatabase').addEventListener('click', viewdatabaseChange);
    async function viewdatabaseChange(e){
        e.preventDefault();

        var deviceStr = document.getElementById('device').innerText;
        var serveurHeaderStr = document.getElementById('serveurheader').innerText;

        typecrud = 'view';

        document.getElementById('databasetasks').style.display = 'block';
        document.getElementById('queriestasks').style.display = 'block';
        document.getElementById('crudactions').style.display = 'block';
        document.getElementById('adddatabase').style.display = 'none';
        document.getElementById('editdatabase').style.display = 'none';
        document.getElementById('deletedatabase').style.display = 'none';

        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            //xxxxxxxxx crudbase xxxxxxxxx
            document.getElementById('newbase').style.display = 'none';
            document.getElementById('listbase').style.display = 'none';
            document.getElementById('previousbase').style.display = 'block';
            document.getElementById('prevdeskbase').style.display = 'block';
            document.getElementById('prevmobilebase').style.display = 'none';
            //xxxxxxx input field
            document.getElementById('dataname').style.display = 'none';
            document.getElementById('servername').style.display = 'none';
            document.getElementById('confirmer').style.display = 'block';
        };

        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            //xxxxxxxxx crudbase xxxxxxxxx
            document.getElementById('newbase').style.display = 'none';
            document.getElementById('listbase').style.display = 'none';
            document.getElementById('previousbase').style.display = 'block';
            document.getElementById('prevdeskbase').style.display = 'none';
            document.getElementById('prevmobilebase').style.display = 'block';
            //xxxxxxx input field
            document.getElementById('dataname').style.display = 'none';
            document.getElementById('servername').style.display = 'none';
            document.getElementById('confirmermobile').style.display = 'block';
        }
    }

    //Crud tasks (add)
    document.getElementById('adddatabase').addEventListener('click', adddatabaseChange);
    async function adddatabaseChange(e){
        e.preventDefault();

        //alert
        var elementOutId = 'modaloutalertdatabase';
        var elementDropId = 'backdropoutalertdatabase';
        var buttonHandler = 'ouiHandlerAlertDatabaseBtn';

        //desktop input fields
        var hostnamebaseStr = document.getElementById('hostnamebase').value;
        var hostportbaseStr = document.getElementById('hostportbase').value;
        var usernamebaseStr = document.getElementById('usernamebase').value;
        var passwordnamebaseStr = document.getElementById('passwordnamebase').value;
        var databasenamebaseStr = (document.getElementById('databasenamebase').value).toLowerCase();
        //mobile input fields
        var mobileusernamebaseStr = document.getElementById('mobileusernamebase').value;
        var mobilepasswordnamebaseStr = document.getElementById('mobilepasswordnamebase').value;
        //xxxxxxxxx
        var serveurHeaderStr = document.getElementById('serveurheader').innerText;
        var deviceStr = document.getElementById('device').innerText;
        var localnamebaseStr = '';
        if(serveurHeaderStr === 'local'){
            localnamebaseStr = 'local';
        }else if(serveurHeaderStr === 'remote'){
            localnamebaseStr = 'remote';
        }
        var localtypebaseStr = 'database';

        //check if values not null
        if((deviceStr === '' && localnamebaseStr === 'local') || 
        (deviceStr === '' && localnamebaseStr === 'remote') || (deviceStr === 'mobile' && localnamebaseStr === 'remote')){
            if(!hostnamebaseStr){
                var pTextehostname = "Enter Host Name";
                alertModal(elementOutId,elementDropId,pTextehostname,buttonHandler);
            }else if(!hostportbaseStr){
                var pTexteportname = "Enter Port Number";
                alertModal(elementOutId,elementDropId,pTexteportname,buttonHandler);
            }else if(!usernamebaseStr){
                var pTexteusername = "Enter Username";
                alertModal(elementOutId,elementDropId,pTexteusername,buttonHandler);
            }else if(!passwordnamebaseStr){
                var pTextepasswordname = "Enter Password";
                alertModal(elementOutId,elementDropId,pTextepasswordname,buttonHandler);
            }else if(!databasenamebaseStr){
                var pTextedatabasename = "Enter Database Name";
                alertModal(elementOutId,elementDropId,pTextedatabasename,buttonHandler);
            }else{
                const db = new PouchDB(localnamebaseStr, {skip_setup:true});
                const remote = new PouchDB('http://'+usernamebaseStr+':'+passwordnamebaseStr+'@'+hostnamebaseStr+':'+hostportbaseStr+'/'+localnamebaseStr, {skip_setup:true});
                PouchDB.plugin(PouchFind);
                //test if hostname and portnumber are true
                var http_request = new XMLHttpRequest();
                http_request.open("GET", "http://"+hostnamebaseStr+":"+hostportbaseStr+"/_utils/" ,true);
                http_request.send("null");
                http_request.onreadystatechange = async function(){
                    if(http_request.readyState === 4){
                        if(http_request.status === 200){
                            function sync(){
                                var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                db.sync(remote, opts);
                            }
                            if(remote){
                                sync();
                            }
                            //end sync
                            var docsCheck = [];
                            try {
                                let result = await db.createIndex({
                                    index: {
                                        fields: [
                                            'hostname', 
                                            'portname',
                                            'username',
                                            'passwordname'
                                        ]
                                    }
                                });
                                result = await db.find({
                                    selector: {
                                        hostname: hostnamebaseStr,
                                        portname: hostportbaseStr,
                                        username: usernamebaseStr,
                                        passwordname: passwordnamebaseStr
                                    }
                                });
                                docsCheck = result.docs;
                                var resultatCheck = docsCheck.length;
                                if(resultatCheck === ''){
                                    var pTexteincorrectconnection = "Name or password is incorrect.";
                                    alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
                                }else{
                                    var docsCheckDatabase = [];
                                    let resultDatabase = await db.createIndex({
                                        index: {
                                            fields: [
                                                'connectname'
                                            ]
                                        }
                                    });
                                    resultDatabase = await db.find({
                                        selector: {
                                            connectname: databasenamebaseStr
                                        }
                                    });
                                    docsCheckDatabase = resultDatabase.docs;
                                    var resultatCheckDatabase = docsCheckDatabase.length;
                                    if(resultatCheckDatabase === 0){
                                        const dbNew = new PouchDB(databasenamebaseStr);
                                        const remoteNew = couchProvider(hostnamebaseStr, hostportbaseStr, usernamebaseStr, passwordnamebaseStr, databasenamebaseStr);
                                        //add new database created to local connection server
                                        //Create the document _id and _rev.
                                        var now = Date.now();
                                        //new database id
                                        var id = databasenamebaseStr.toLowerCase()+'_' + now;
                                        // Add the document to the database.
                                        var response = await db.put({
                                            _id: id,
                                            connectname: databasenamebaseStr, //the new database name
                                            hostname: hostnamebaseStr,
                                            portname: hostportbaseStr,
                                            username: usernamebaseStr,
                                            passwordname: passwordnamebaseStr,
                                            servername: localtypebaseStr,
                                        });
                                        // check if document inserted
                                        var doc = await db.get(response.id);
                                        if(doc !== ''){
                                            //Initialise a sync with the remote server
                                            function sync(){
                                                var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                                dbNew.sync(remoteNew, opts);
                                            };
                                            if(remoteNew){
                                                sync();
                                            };
                                            var pTextenewdatabase = "New Database added Succesfully";
                                            alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
                                        }else{
                                            var pTexteerreuraddnew = "An Error has Occurred";
                                            alertModal(elementOutId,elementDropId,pTexteerreuraddnew,buttonHandler);
                                        };
                                    }else{
                                        var pTexteexistaddnew = "Database "+databasenamebaseStr+" already exists";
                                        alertModal(elementOutId,elementDropId,pTexteexistaddnew,buttonHandler);
                                    };
                                };
                            } catch (error) {
                                var pTexteerreurconnection = "An Error has Occurred";
                                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                            }
                        }else{
                            var pTextefailedconnect = "Connection Failed.";
                            alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                        };
                    };
                };
            }
        }//--

        if(deviceStr === 'mobile' && localnamebaseStr === 'local'){
            if(!mobileusernamebaseStr){
                var pTexteusername = "Enter Username";
                alertModal(elementOutId,elementDropId,pTexteusername,buttonHandler); 
            }else if(!mobilepasswordnamebaseStr){
                var pTextepasswordname = "Enter Password";
                alertModal(elementOutId,elementDropId,pTextepasswordname,buttonHandler);
            }else{
                var docsCheck = [];
                const db = new PouchDB(serveurHeaderStr, {skip_setup:true});
                PouchDB.plugin(PouchFind);
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: [
                                'servername',
                            ]
                        }
                    });
                    result = await db.find({
                        selector: {
                            servename: serveurHeaderStr,
                        }
                    });
                    docsCheck = result.docs;
                    var resultatCheck = docsCheck.length;
                    if(resultatCheck === ''){
                        var connectionnameStr = 'Trial';
                        //create database
                        //Create the document _id and _rev.
                        var now = Date.now();
                        //new database id
                        var id = serveurHeaderStr.toLowerCase()+'_' + now;
                        // Add the document to the database.
                        var response = await db.put({
                            _id: id,
                            connectname: connectionnameStr, //the new database name
                            username: mobileusernamebaseStr,
                            passwordname: mobilepasswordnamebaseStr,
                            servername: serveurHeaderStr.toLowerCase(),
                        });
                        // check if document inserted
                        var doc = await db.get(response.id);
                        if(doc !== ''){
                            var pTextenewdatabase = "New Database added Succesfully";
                            alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
                        }else{
                            var pTexteerreuraddnew = "An Error has Occurred";
                            alertModal(elementOutId,elementDropId,pTexteerreuraddnew,buttonHandler);
                        }
                    }else{
                        var pTexteexistaddnew = "Database "+serveurHeaderStr+" already exists";
                        alertModal(elementOutId,elementDropId,pTexteexistaddnew,buttonHandler);
                    }
                } catch (error) {
                    var pTextefailedconnect = "Connection Failed.";
                    alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                } 
            }
        };//--
    }//

    //Crud tasks desktop (confirm)
    document.getElementById('confirm').addEventListener('click', confirmChange);
    async function confirmChange(e){
        e.preventDefault();

        //alert
        var elementOutId = 'modaloutalertdatabase';
        var elementDropId = 'backdropoutalertdatabase';
        var buttonHandler = 'ouiHandlerAlertDatabaseBtn';

        var hostnamebasechangeStr = document.getElementById('hostnamebasechange').value;
        var hostportbasechangeStr = document.getElementById('hostportbasechange').value;
        var usernamebasechangeStr = document.getElementById('usernamebasechange').value;
        var passwordnamebasechangeStr = document.getElementById('passwordnamebasechange').value;
        var serveurHeaderStr = document.getElementById('serveurheader').innerText;
        var deviceStr = document.getElementById('device').innerText;
        var localnamebaseStr = '';
        var servernameStr = 'database';
        if(serveurHeaderStr === 'local'){
            localnamebaseStr = 'local';
        }else if(serveurHeaderStr === 'remote'){
            localnamebaseStr = 'remote';
        }

        //check if values not null
        if(!hostnamebasechangeStr){
            var pTextehostname = "Enter Host Name";
            alertModal(elementOutId,elementDropId,pTextehostname,buttonHandler);
        }else if(!hostportbasechangeStr){
            var pTexteportname = "Enter Port Number";
            alertModal(elementOutId,elementDropId,pTexteportname,buttonHandler);
        }else if(!usernamebasechangeStr){
            var pTexteusername = "Enter Username";
            alertModal(elementOutId,elementDropId,pTexteusername,buttonHandler);
        }else if(!passwordnamebasechangeStr){
            var pTextepasswordname = "Enter Password";
            alertModal(elementOutId,elementDropId,pTextepasswordname,buttonHandler);
        }else{
            if((deviceStr === '' && serveurHeaderStr === 'local') || 
            (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
                const db = new PouchDB(localnamebaseStr, {skip_setup:true});
                const remote = new PouchDB('http://'+usernamebasechangeStr+':'+passwordnamebasechangeStr+'@'+hostnamebasechangeStr+':'+hostportbasechangeStr+'/'+localnamebaseStr, {skip_setup:true});
                PouchDB.plugin(PouchFind);
                //test if hostname and portnumber are true
                var http_request = new XMLHttpRequest();
                http_request.open("GET", "http://"+hostnamebasechangeStr+":"+hostportbasechangeStr+"/_utils/" ,true);
                http_request.send("null");
                http_request.onreadystatechange = async function(){
                    if(http_request.readyState === 4){
                        if(http_request.status === 200){
                            // Initialise a sync with the remote server
                            function sync(){
                                var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                db.sync(remote, opts);
                            }
                            if(remote){
                                sync();
                            }
                            //end sync
                            var docsCheckConfirm = [];
                            try {
                                let result = await db.createIndex({
                                    index: {
                                        fields: [
                                            'servername'
                                        ]
                                    }
                                });
                                result = await db.find({
                                    selector: {
                                        servername: servernameStr
                                    }
                                });
                                docsCheckConfirm = result.docs;
                                var resultatCheckConfirm = docsCheckConfirm.length;
                                if(resultatCheckConfirm === ''){
                                    var pTexteincorrectconnection = "No databases available.";
                                    alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
                                }else{
                                    document.getElementById('databasetasks').style.display = 'block';
                                    document.getElementById('queriestasks').style.display = 'block';
                                    document.getElementById('crudactions').style.display = 'block';
                                    document.getElementById('adddatabase').style.display = 'none';
                                    document.getElementById('editdatabase').style.display = 'none';
                                    document.getElementById('deletedatabase').style.display = 'none';
                                    //xxxxxxxxx crudbase xxxxxxxxx
                                    updateEditDatabseUIWithDocs(docsCheckConfirm);
                                    document.getElementById('newbase').style.display = 'none';
                                    document.getElementById('listbase').style.display = 'block';
                                    document.getElementById('previousbase').style.display = 'none';
                                    //xxxxxxx input field
                                    document.getElementById('dataname').style.display = 'none';
                                    document.getElementById('servername').style.display = 'none';
                                    document.getElementById('confirmer').style.display = 'none'; 
                                }
                            } catch (error) {
                                var pTexteerreurconnection = "An Error has Occurred";
                                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                            }
                        }else{
                            var pTextefailedconnect = "Connection Failed.";
                            alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                        }
                    }
                }
            }//
        }
    }
    //Crud tasks mobile (confirmmobile)
    document.getElementById('confirmmobile').addEventListener('click', confirmmobileChange);
    async function confirmmobileChange(e){
        e.preventDefault();

        //alert
        var elementOutId = 'modaloutalertdatabase';
        var elementDropId = 'backdropoutalertdatabase';
        var buttonHandler = 'ouiHandlerAlertDatabaseBtn';

        var mobileusernamebasechangeStr = document.getElementById('mobileusernamebasechange').value;
        var mobilepasswordnamebasechangeStr = document.getElementById('mobilepasswordnamebasechange').value;
        var serveurHeaderStr = document.getElementById('serveurheader').innerText;
        var deviceStr = document.getElementById('device').innerText;
        //check if values not null
        if(!mobileusernamebasechangeStr){
            var pTexteusername = "Enter Username";
            alertModal(elementOutId,elementDropId,pTexteusername,buttonHandler);
        }else if(!mobilepasswordnamebasechangeStr){
            var pTextepasswordname = "Enter Password";
            alertModal(elementOutId,elementDropId,pTextepasswordname,buttonHandler);
        }else{
            if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
                var docsCheckConfirm = [];
                const db = new PouchDB(serveurHeaderStr, {skip_setup:true});
                PouchDB.plugin(PouchFind);
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: [
                                'servername', 
                                'username', 
                                'passwordname'
                            ]
                        }
                    });
                    result = await db.find({
                        selector: {
                            servername: serveurHeaderStr, 
                            username: mobileusernamebasechangeStr,
                            passwordname: mobilepasswordnamebasechangeStr
                        }
                    });
                    docsCheckConfirm = result.docs;
                    var resultatCheckConfirm = docsCheckConfirm.length;
                    if(resultatCheckConfirm === 0){
                        var pTexteincorrectconnection = "No databases available.";
                        alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
                    }else{
                        document.getElementById('databasetasks').style.display = 'block';
                        document.getElementById('queriestasks').style.display = 'block';
                        document.getElementById('crudactions').style.display = 'block';
                        document.getElementById('adddatabase').style.display = 'none';
                        document.getElementById('editdatabase').style.display = 'none';
                        document.getElementById('deletedatabase').style.display = 'none';
                        //xxxxxxxxx crudbase xxxxxxxxx
                        updateEditDatabseUIWithDocs(docsCheckConfirm);
                        document.getElementById('newbase').style.display = 'none';
                        document.getElementById('listbase').style.display = 'block';
                        document.getElementById('previousbase').style.display = 'none';
                        //xxxxxxx input field
                        document.getElementById('dataname').style.display = 'none';
                        document.getElementById('servername').style.display = 'none';
                        document.getElementById('confirmer').style.display = 'none'; 
                    }
                } catch (error) {
                    var pTexteerreurconnection = "An Error has Occurred";
                    alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                }
            }
        }
    }
    // Adds the document to the HTML First table.
    // If the document already exists in the table, it will be updated.
    function editDatabaseDocToToTable(doc, editDatabaseTableBody){
        // Each row of the table has an id attribute set to the doc._id field.
        var tr = editDatabaseTableBody.querySelector(`#${doc._id}`);

        if( !tr ) {
            tr = document.createElement('tr');
            tr.id = doc._id;
        }

        // Remove any existing table cells.
        while(tr.hasChildNodes()) {
            tr.removeChild(tr.childNodes[0]);
        }

        var deviceStr = document.getElementById('device').innerText;
        var serveurHeaderStr = document.getElementById('serveurheader').innerText;
        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            // Create new table cells for text fields.
            var tdDatabase = document.createElement('td');
            tdDatabase.innerText = doc.connectname;
            var databaseNameStr = doc.connectname;
            tdDatabase.onclick = function(){
                var hostnameStr = doc.hostname;
                var portnameStr = doc.portname;
                var usernameStr = doc.username;
                var passwordnameStr = doc.passwordname;
                var servernameStr = doc.servername;
                //xxx
                var hostnamebasechangetext = document.getElementById('hostnamebasechange');
                hostnamebasechangetext.value = hostnameStr;
                hostnamebasechangetext.setAttribute("readonly", "readonly");
                var hostportbasechangetext = document.getElementById('hostportbasechange');
                hostportbasechangetext.value = portnameStr;
                hostportbasechangetext.setAttribute("readonly", "readonly");
                var usernamebasechangetext = document.getElementById('usernamebasechange');
                usernamebasechangetext.value = usernameStr;
                usernamebasechangetext.setAttribute("readonly", "readonly");
                var passwordnamebasechangetext = document.getElementById('passwordnamebasechange');
                passwordnamebasechangetext.value = passwordnameStr;
                passwordnamebasechangetext.setAttribute("readonly", "readonly");
                var databasenametochangetext = document.getElementById('databasenametochange');
                databasenametochangetext.value = tdIdDatabaseStr;
                var databasenamechangetext = document.getElementById('databasenamechange');
                databasenamechangetext.value = databaseNameStr;
                databasenamechangetext.style.border = '1px solid #00e64d';
                var servernamechangetext = document.getElementById('servernamechange');
                servernamechangetext.value = servernameStr;
                //
                document.getElementById('databasetasks').style.display = 'block';
                document.getElementById('queriestasks').style.display = 'block';
                document.getElementById('crudactions').style.display = 'block';
                document.getElementById('adddatabase').style.display = 'none';
                if(typecrud === 'edit'){
                    document.getElementById('editdatabase').style.display = 'block';
                    document.getElementById('deletedatabase').style.display = 'none';
                }else if(typecrud === 'delete'){
                    document.getElementById('editdatabase').style.display = 'none';
                    document.getElementById('deletedatabase').style.display = 'block';
                }else if(typecrud === 'view'){
                    document.getElementById('editdatabase').style.display = 'none';
                    document.getElementById('deletedatabase').style.display = 'none';
                }
                //xxxxxxxxx crudbase xxxxxxxxx
                document.getElementById('newbase').style.display = 'none';
                document.getElementById('listbase').style.display = 'none';
                document.getElementById('previousbase').style.display = 'block';
                //xxxx
                document.getElementById('prevdeskbase').style.display = 'block';
                document.getElementById('prevmobilebase').style.display = 'none';
                //xxxxxxx input field
                document.getElementById('dataname').style.display = 'block';
                document.getElementById('servername').style.display = 'none';
                document.getElementById('confirmer').style.display = 'none';
                document.getElementById('confirmermobile').style.display = 'none';
            }
            tr.appendChild(tdDatabase);//

            // Create new table cells for text fields.
            var tdId = document.createElement('td');
            tdId.innerText = doc._id;
            var tdIdDatabaseStr = doc.connectname;
            tdId.onclick = function(){
                var hostnameStr = doc.hostname;
                var portnameStr = doc.portname;
                var usernameStr = doc.username;
                var passwordnameStr = doc.passwordname;
                var servernameStr = doc.servername;
                //
                var hostnamebasechangetext = document.getElementById('hostnamebasechange');
                hostnamebasechangetext.value = hostnameStr;
                hostnamebasechangetext.setAttribute("readonly", "readonly");
                var hostportbasechangetext = document.getElementById('hostportbasechange');
                hostportbasechangetext.value = portnameStr;
                hostportbasechangetext.setAttribute("readonly", "readonly");
                var usernamebasechangetext = document.getElementById('usernamebasechange');
                usernamebasechangetext.value = usernameStr;
                usernamebasechangetext.setAttribute("readonly", "readonly");
                var passwordnamebasechangetext = document.getElementById('passwordnamebasechange');
                passwordnamebasechangetext.value = passwordnameStr;
                passwordnamebasechangetext.setAttribute("readonly", "readonly");
                var databasenametochangetext = document.getElementById('databasenametochange');
                databasenametochangetext.value = tdIdDatabaseStr;
                var databasenamechangetext = document.getElementById('databasenamechange');
                databasenamechangetext.value = tdIdDatabaseStr;
                databasenamechangetext.style.border = '1px solid #00e64d';
                var servernamechangetext = document.getElementById('servernamechange');
                servernamechangetext.value = servernameStr;
                //
                document.getElementById('databasetasks').style.display = 'block';
                document.getElementById('queriestasks').style.display = 'block';
                document.getElementById('crudactions').style.display = 'block';
                document.getElementById('adddatabase').style.display = 'none';
                if(typecrud === 'edit'){
                    document.getElementById('editdatabase').style.display = 'block';
                    document.getElementById('deletedatabase').style.display = 'none';
                }else if(typecrud === 'delete'){
                    document.getElementById('editdatabase').style.display = 'none';
                    document.getElementById('deletedatabase').style.display = 'block';
                }
                //xxxxxxxxx crudbase xxxxxxxxx
                document.getElementById('newbase').style.display = 'none';
                document.getElementById('listbase').style.display = 'none';
                document.getElementById('previousbase').style.display = 'block';
                //xxxx
                document.getElementById('prevdeskbase').style.display = 'block';
                document.getElementById('prevmobilebase').style.display = 'none'; 
                //xxxxxxx input field
                document.getElementById('dataname').style.display = 'block';
                document.getElementById('servername').style.display = 'none';
                document.getElementById('confirmer').style.display = 'none';
                document.getElementById('confirmermobile').style.display = 'none';
            }
            tr.appendChild(tdId);//
        };

        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            // Create new table cells for text fields.
            var tdDatabase = document.createElement('td');
            tdDatabase.innerText = doc.connectname;
            var databaseNameStr = doc.connectname;
            tdDatabase.onclick = function(){
                var usernameStr = doc.username;
                var passwordnameStr = doc.passwordname;
                //xxx
                var mobileusernamebasechangetext = document.getElementById('mobileusernamebasechange');
                mobileusernamebasechangetext.value = usernameStr;
                var mobilepasswordnamebasechangetext = document.getElementById('mobilepasswordnamebasechange');
                mobilepasswordnamebasechangetext.value = passwordnameStr;
                //
                document.getElementById('databasetasks').style.display = 'block';
                document.getElementById('queriestasks').style.display = 'block';
                document.getElementById('crudactions').style.display = 'block';
                document.getElementById('adddatabase').style.display = 'none';
                if(typecrud === 'edit'){
                    document.getElementById('editdatabase').style.display = 'block';
                    document.getElementById('deletedatabase').style.display = 'none';
                }else if(typecrud === 'delete'){
                    document.getElementById('editdatabase').style.display = 'none';
                    document.getElementById('deletedatabase').style.display = 'block';
                }else if(typecrud === 'view'){
                    document.getElementById('editdatabase').style.display = 'none';
                    document.getElementById('deletedatabase').style.display = 'none';
                }
                //xxxxxxxxx crudbase xxxxxxxxx
                document.getElementById('newbase').style.display = 'none';
                document.getElementById('listbase').style.display = 'none';
                document.getElementById('previousbase').style.display = 'block'; 
                //xxxx
                document.getElementById('prevdeskbase').style.display = 'none';
                document.getElementById('prevmobilebase').style.display = 'block';
                //xxxxxxx input field
                document.getElementById('dataname').style.display = 'block';
                document.getElementById('servername').style.display = 'none';
                document.getElementById('confirmer').style.display = 'none';
                document.getElementById('confirmermobile').style.display = 'none';
            }
            tr.appendChild(tdDatabase);//

            // Create new table cells for text fields.
            var tdId = document.createElement('td');
            tdId.innerText = doc._id;
            var tdIdDatabaseStr = doc.connectname;
            tdId.onclick = function(){
                var usernameStr = doc.username;
                var passwordnameStr = doc.passwordname;
                //
                var mobileusernamebasechangetext = document.getElementById('mobileusernamebasechange');
                mobileusernamebasechangetext.value = usernameStr;
                var mobilepasswordnamebasechangetext = document.getElementById('mobilepasswordnamebasechange');
                mobilepasswordnamebasechangetext.value = passwordnameStr;
                //
                document.getElementById('databasetasks').style.display = 'block';
                document.getElementById('queriestasks').style.display = 'block';
                document.getElementById('crudactions').style.display = 'block';
                document.getElementById('adddatabase').style.display = 'none';
                if(typecrud === 'edit'){
                    document.getElementById('editdatabase').style.display = 'block';
                    document.getElementById('deletedatabase').style.display = 'none';
                }else if(typecrud === 'delete'){
                    document.getElementById('editdatabase').style.display = 'none';
                    document.getElementById('deletedatabase').style.display = 'block';
                }
                //xxxxxxxxx crudbase xxxxxxxxx
                document.getElementById('newbase').style.display = 'none';
                document.getElementById('listbase').style.display = 'none';
                document.getElementById('previousbase').style.display = 'block'; 
                //xxxx
                document.getElementById('prevdeskbase').style.display = 'none';
                document.getElementById('prevmobilebase').style.display = 'block';
                //xxxxxxx input field
                document.getElementById('dataname').style.display = 'block';
                document.getElementById('servername').style.display = 'none';
                document.getElementById('confirmer').style.display = 'none';
                document.getElementById('confirmermobile').style.display = 'none';
            }
            tr.appendChild(tdId);//
        }

        // Add the row to the top of the table.
        editDatabaseTableBody.insertBefore(tr, editDatabaseTableBody.nextSibling);
    }
    /**Clear and update the UI to display the docs.*/
    function updateEditDatabseUIWithDocs(docs){
        var editDatabaseTableBody = document.getElementById('editDatabaseTableBody');

        // Remove all child nodes from the table
        while(editDatabaseTableBody.hasChildNodes()) {
            editDatabaseTableBody.removeChild(editDatabaseTableBody.childNodes[0]);
        }

        // Add the new documents to the div
        for(var i=0; i<docs.length; i++) {
            var doc = docs[i];
            editDatabaseDocToToTable(doc, editDatabaseTableBody);
        }
    }

    //Crud tasks (edit)
    document.getElementById('editdatabase').addEventListener('click', editdatabaseChange);
    async function editdatabaseChange(e){
        e.preventDefault();

        //alert
        var elementOutId = 'modaloutalertdatabase';
        var elementDropId = 'backdropoutalertdatabase';
        var buttonHandler = 'ouiHandlerAlertDatabaseBtn';

        var deviceStr = document.getElementById('device').innerText;
        var serveurHeaderStr = document.getElementById('serveurheader').innerText;

        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            var hostnamebasechangeStr = document.getElementById('hostnamebasechange').value;
            var hostportbasechangeStr = document.getElementById('hostportbasechange').value;
            var usernamebasechangeStr = document.getElementById('usernamebasechange').value;
            var passwordnamebasechangeStr = document.getElementById('passwordnamebasechange').value;
            var databasenametochangeStr = (document.getElementById('databasenametochange').value).toLowerCase();
            var databasenamechangeStr = (document.getElementById('databasenamechange').value).toLowerCase();
            var servernamechangeStr = document.getElementById('servernamechange').value;
            var serveurHeaderStr = document.getElementById('serveurheader').innerText;
            //var typecrudStr = typecrud;
            var localnamebaseStr = '';
            if(serveurHeaderStr === 'local'){
                localnamebaseStr = 'local';
            }else if(serveurHeaderStr === 'remote'){
                localnamebaseStr = 'remote';
            }

            if(!databasenametochangeStr){
                var pTextedatabasenameselect = "Select Database Name";
                alertModal(elementOutId,elementDropId,pTextedatabasenameselect,buttonHandler);
            }else if(!databasenamechangeStr){
                var pTextedatabasename = "Enter Database Name";
                alertModal(elementOutId,elementDropId,pTextedatabasename,buttonHandler);
            }else{
                var divClearModal = document.getElementById('modaloutdatabase');
                let divModal = document.createElement('div');
                divModal.innerHTML =
                `<div class="modal">
                    <p>Edit database erase the previous one. Do you want to edit?</p>
                    <button class="btn btn--alt" id="ouiHandlerYesBtn">Yes</button>
                    <button class="btn" id="nonHandlerNoBtn">No</button>
                </div>`;
                divClearModal.appendChild(divModal);

                var divClearBackdrop = document.getElementById('backdropoutdatabase');
                let divBack = document.createElement('div');
                divBack.innerHTML =
                `<div class="backdrop" id="backdropHandlerDropBtn"></div>`;
                divClearBackdrop.appendChild(divBack);

                //If Yes Clicked
                document.getElementById('ouiHandlerYesBtn').addEventListener('click', ouiHandlerYesBtnChange);
                async function ouiHandlerYesBtnChange(e){
                    e.preventDefault();

                    //test if hostname and portnumber are true
                    var http_request = new XMLHttpRequest();
                    http_request.open("GET", "http://"+hostnamebasechangeStr+":"+hostportbasechangeStr+"/_utils/" ,true);
                    http_request.send("null");
                    http_request.onreadystatechange = async function(){
                        if(http_request.readyState === 4){
                            if(http_request.status === 200){
                                //1:edit document database
                                const dbdoc = new PouchDB(localnamebaseStr, {skip_setup:true});
                                const remotedoc = new PouchDB('http://'+usernamebasechangeStr+':'+passwordnamebasechangeStr+'@'+hostnamebasechangeStr+':'+hostportbasechangeStr+'/'+localnamebaseStr, {skip_setup:true});
                                PouchDB.plugin(PouchFind);
                                var docsCheckDoc = [];
                                try {
                                    let result = await dbdoc.createIndex({
                                        index: {
                                            fields: [
                                                'connectname', 
                                            ]
                                        }
                                    });
                                    result = await dbdoc.find({
                                        selector: {
                                            connectname: databasenametochangeStr 
                                        }
                                    });
                                    docsCheckDoc = result.docs;
                                    var resultatCheckDoc = docsCheckDoc.length;
                                    if(resultatCheckDoc === 0){
                                        var pTexteincorrectconnectiondoc = "Database not exists.";
                                        alertModal(elementOutId,elementDropId,pTexteincorrectconnectiondoc,buttonHandler);
                                    }else{
                                        var documentID = '';
                                        for(let i = 0; i<docsCheckDoc.length; i++){
                                            documentID = docsCheckDoc[i]._id;
                                        }
                                        //edit document in local and remote database
                                        // Retrieve the document to get the latest revision
                                        var docModifier = await dbdoc.get(documentID);
                                        // Make changes
                                        docModifier.connectname = databasenamechangeStr;
                                        docModifier.hostname = hostnamebasechangeStr;
                                        docModifier.portname = hostportbasechangeStr;
                                        docModifier.username = usernamebasechangeStr;
                                        docModifier.passwordname = passwordnamebasechangeStr;
                                        docModifier.servername = servernamechangeStr;
                                        // Put the document, saving it as a new revision
                                        var responseModifier = await dbdoc.put(docModifier);
                                        // Get the document we change and update the UI.
                                        var docEdit = await dbdoc.get(responseModifier.id);
                                        if(docEdit !== ''){
                                            //Initialise a sync with the remote server
                                            function sync(){
                                                var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                                dbdoc.sync(remotedoc, opts);
                                            }
                                            if(remotedoc){
                                                sync();
                                            }
                                            //2:check database
                                            const db = new PouchDB(databasenametochangeStr, {skip_setup:true});
                                            const remote = new PouchDB('http://'+usernamebasechangeStr+':'+passwordnamebasechangeStr+'@'+hostnamebasechangeStr+':'+hostportbasechangeStr+'/'+databasenametochangeStr, {skip_setup:true});
                                            //3: destroy the database
                                            db.destroy().then(function(){
                                                return remote.destroy();
                                            }).then(function(){
                                                //4: re-create the database with le new name
                                                const dblocal = new PouchDB(databasenamechangeStr);
                                                const remotelocal = new PouchDB('http://'+usernamebasechangeStr+':'+passwordnamebasechangeStr+'@'+hostnamebasechangeStr+':'+hostportbasechangeStr+'/'+databasenamechangeStr);
                                                function sync(){
                                                    var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                                    dblocal.sync(remotelocal, opts);
                                                }
                                                if(remotelocal){
                                                    sync();
                                                }
                                                //end sync
                                            });
                                            var pTextesuccesedit = "Database updated successfully";
                                            alert(pTextesuccesedit);
                                            //xxxxxxxxxx
                                            document.getElementById('databasetasks').style.display = 'block';
                                            document.getElementById('queriestasks').style.display = 'none';
                                            document.getElementById('crudactions').style.display = 'none';
                                        }else{
                                            var pTextefailededit = "Database updated failed";
                                            alert(pTextefailededit);
                                            //xxxxxxxxxx
                                            document.getElementById('databasetasks').style.display = 'block';
                                            document.getElementById('queriestasks').style.display = 'none';
                                            document.getElementById('crudactions').style.display = 'none';
                                        }
                                    }
                                } catch (error) {
                                    var pTexteerreurconnection = "An Error has Occurred";
                                    alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                                }
                            }else{
                                var pTextefailedconnect = "Connection Failed.";
                                alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                            } 
                        }
                    }
                    var divClearModal = document.getElementById('modaloutdatabase');
                    while(divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById('backdropoutdatabase');
                    while(divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                    }
                }
                //If No Clicked
                document.getElementById('nonHandlerNoBtn').addEventListener('click', nonHandlerNoBtnChange);
                async function nonHandlerNoBtnChange(e){
                    e.preventDefault();

                    var divClearModal = document.getElementById('modaloutdatabase');
                    while(divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById('backdropoutdatabase');
                    while(divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                    }
                }
                //If backdrop Clicked
                document.getElementById('backdropHandlerDropBtn').addEventListener('click', backdropHandlerDropBtnChange);
                async function backdropHandlerDropBtnChange(e){
                    e.preventDefault();

                    var divClearModal = document.getElementById('modaloutdatabase');
                    while(divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById('backdropoutdatabase');
                    while(divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                    }
                }
            }
        };//

        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            var mobileusernamebasechangeStr = document.getElementById('mobileusernamebasechange').value;
            var mobilepasswordnamebasechangeStr = document.getElementById('mobilepasswordnamebasechange').value;
            var serveurHeaderStr = document.getElementById('serveurheader').innerText;
            if(!mobileusernamebasechangeStr){
                var pTexteuserbasenameselect = "Enter username";
                alertModal(elementOutId,elementDropId,pTexteuserbasenameselect,buttonHandler);
            }else if(!mobilepasswordnamebasechangeStr){
                var pTextepasswordbasename = "Enter Password";
                alertModal(elementOutId,elementDropId,pTextepasswordbasename,buttonHandler);
            }else{
                var divClearModal = document.getElementById('modaloutdatabase');
                let divModal = document.createElement('div');
                divModal.innerHTML =
                `<div class="modal">
                    <p>Edit database erase the previous one. Do you want to edit?</p>
                    <button class="btn btn--alt" id="ouiHandlerYesBtn">Yes</button>
                    <button class="btn" id="nonHandlerNoBtn">No</button>
                </div>`;
                divClearModal.appendChild(divModal);

                var divClearBackdrop = document.getElementById('backdropoutdatabase');
                let divBack = document.createElement('div');
                divBack.innerHTML =
                `<div class="backdrop" id="backdropHandlerDropBtn"></div>`;
                divClearBackdrop.appendChild(divBack);

                //If Yes Clicked
                document.getElementById('ouiHandlerYesBtn').addEventListener('click', ouiHandlerYesBtnChange);
                async function ouiHandlerYesBtnChange(e){
                    e.preventDefault();

                    const dbdoc = new PouchDB(serveurHeaderStr, {skip_setup:true});
                    PouchDB.plugin(PouchFind);
                    var docsCheckDoc = [];
                    try {
                        let result = await dbdoc.createIndex({
                            index: {
                                fields: [
                                    'servername', 
                                    //'username', 
                                    //'passwordname',
                                ]
                            }
                        });
                        result = await dbdoc.find({
                            selector: {
                                servername: serveurHeaderStr,
                                //username: mobileusernamebasechangeStr, 
                                //passwordname: mobilepasswordnamebasechangeStr 
                            }
                        });
                        docsCheckDoc = result.docs;
                        var resultatCheckDoc = docsCheckDoc.length;
                        if(resultatCheckDoc === 0){
                            var pTexteincorrectconnectiondoc = "Database not exists.";
                            alertModal(elementOutId,elementDropId,pTexteincorrectconnectiondoc,buttonHandler);
                        }else{
                            var documentID = '';
                            for(let i = 0; i<docsCheckDoc.length; i++){
                                documentID = docsCheckDoc[i]._id;
                            }
                            //edit document in local and remote database
                            // Retrieve the document to get the latest revision
                            var docModifier = await dbdoc.get(documentID);
                            // Make changes
                            docModifier.username = mobileusernamebasechangeStr;
                            docModifier.passwordname = mobilepasswordnamebasechangeStr;
                            docModifier.servername = serveurHeaderStr;
                            // Put the document, saving it as a new revision
                            var responseModifier = await dbdoc.put(docModifier);
                            // Get the document we change and update the UI.
                            var docEdit = await dbdoc.get(responseModifier.id);
                            if(docEdit !== ''){
                                var pTextesuccesedit = "Database updated successfully";
                                alert(pTextesuccesedit);
                                //xxxxxxxxxx
                                document.getElementById('databasetasks').style.display = 'block';
                                document.getElementById('queriestasks').style.display = 'none';
                                document.getElementById('crudactions').style.display = 'none';
                            }else{
                                var pTextefailededit = "Database updated failed";
                                alert(pTextefailededit);
                                //xxxxxxxxxx
                                document.getElementById('databasetasks').style.display = 'block';
                                document.getElementById('queriestasks').style.display = 'none';
                                document.getElementById('crudactions').style.display = 'none';
                            }
                        }
                    } catch (error) {
                        var pTexteerreurconnection = "An Error has Occurred";
                        alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler); 
                    }

                    var divClearModal = document.getElementById('modaloutdatabase');
                    while(divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById('backdropoutdatabase');
                    while(divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                    }
                }
                //If No Clicked
                document.getElementById('nonHandlerNoBtn').addEventListener('click', nonHandlerNoBtnChange);
                async function nonHandlerNoBtnChange(e){
                    e.preventDefault();

                    var divClearModal = document.getElementById('modaloutdatabase');
                    while(divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById('backdropoutdatabase');
                    while(divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                    }
                }
                //If backdrop Clicked
                document.getElementById('backdropHandlerDropBtn').addEventListener('click', backdropHandlerDropBtnChange);
                async function backdropHandlerDropBtnChange(e){
                    e.preventDefault();

                    var divClearModal = document.getElementById('modaloutdatabase');
                    while(divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById('backdropoutdatabase');
                    while(divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                    }
                }
            }
        };
    }

    //Crud tasks (delete)
    document.getElementById('deletedatabase').addEventListener('click', deletedatabaseChange);
    async function deletedatabaseChange(e){
        e.preventDefault();

        //alert
        var elementOutId = 'modaloutalertdatabase';
        var elementDropId = 'backdropoutalertdatabase';
        var buttonHandler = 'ouiHandlerAlertDatabaseBtn';

        var hostnamebasechangeStr = document.getElementById('hostnamebasechange').value;
        var hostportbasechangeStr = document.getElementById('hostportbasechange').value;
        var usernamebasechangeStr = document.getElementById('usernamebasechange').value;
        var passwordnamebasechangeStr = document.getElementById('passwordnamebasechange').value;
        var databasenametochangeStr = (document.getElementById('databasenametochange').value).toLowerCase();
        var databasenamechangeStr = (document.getElementById('databasenamechange').value).toLowerCase();
        var serveurHeaderStr = document.getElementById('serveurheader').innerText;
        var deviceStr = document.getElementById('device').innerText;
        //var typecrudStr = typecrud;
        var localnamebaseStr = '';
        if(serveurHeaderStr === 'local'){
            localnamebaseStr = 'local';
        }else if(serveurHeaderStr === 'remote'){
            localnamebaseStr = 'remote';
        }

        if(!databasenametochangeStr){
            var pTextedatabasenameselect = "Select Database Name";
            alertModal(elementOutId,elementDropId,pTextedatabasenameselect,buttonHandler);
        }else if(!databasenamechangeStr){
            var pTextedatabasename = "Enter Database Name";
            alertModal(elementOutId,elementDropId,pTextedatabasename,buttonHandler);
        }else{
            var divClearModal = document.getElementById('modaloutdatabase');
            let divModal = document.createElement('div');
            divModal.innerHTML =
            `<div class="modal">
                <p>This action will permanently delete. Do you want to continue?</p>
                <button class="btn btn--alt" id="ouiHandlerYesDeleteBtn">Yes</button>
                <button class="btn" id="nonHandlerNoDeleteBtn">No</button>
            </div>`;
            divClearModal.appendChild(divModal);

            var divClearBackdrop = document.getElementById('backdropoutdatabase');
            let divBack = document.createElement('div');
            divBack.innerHTML =
            `<div class="backdrop" id="backdropHandlerDropDeleteBtn"></div>`;
            divClearBackdrop.appendChild(divBack);

            //If Yes Clicked
            document.getElementById('ouiHandlerYesDeleteBtn').addEventListener('click', ouiHandlerYesDeleteBtnChange);
            async function ouiHandlerYesDeleteBtnChange(e){
                e.preventDefault();

                var documentID = '';

                //test if hostname and portnumber are true
                if(deviceStr === ''){
                    var http_request = new XMLHttpRequest();
                    http_request.open("GET", "http://"+hostnamebasechangeStr+":"+hostportbasechangeStr+"/_utils/" ,true);
                    http_request.send("null");
                    http_request.onreadystatechange = async function(){
                        if(http_request.readyState === 4){
                            if(http_request.status === 200){
                                const dbdoc = new PouchDB(localnamebaseStr, {skip_setup:true});
                                const remotedoc = new PouchDB('http://'+usernamebasechangeStr+':'+passwordnamebasechangeStr+'@'+hostnamebasechangeStr+':'+hostportbasechangeStr+'/'+localnamebaseStr, {skip_setup:true});
                                PouchDB.plugin(PouchFind);
                                //Initialise a sync with the remote server
                                function sync(){
                                    var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                    dbdoc.sync(remotedoc, opts);
                                }
                                if(remotedoc){
                                    sync();
                                }
                                var docsCheckDoc = [];
                                try {
                                    let result = await dbdoc.createIndex({
                                        index: {
                                            fields: [
                                                'connectname', 
                                            ]
                                        }
                                    });
                                    result = await dbdoc.find({
                                        selector: {
                                            connectname: databasenametochangeStr 
                                        }
                                    });
                                    docsCheckDoc = result.docs;
                                    var resultatCheckDoc = docsCheckDoc.length;
                                    if(resultatCheckDoc === ''){
                                        var pTexteincorrectconnectiondoc = "Database not exists.";
                                        alertModal(elementOutId,elementDropId,pTexteincorrectconnectiondoc,buttonHandler);
                                    }else{
                                        for(let i = 0; i<docsCheckDoc.length; i++){
                                            documentID = docsCheckDoc[i]._id;
                                        }
                                        var docSupprimer = await dbdoc.get(documentID);
                                        var responseSupprimer = await dbdoc.remove(docSupprimer);

                                        const dblocal = new PouchDB(databasenamechangeStr);
                                        const remotelocal = new PouchDB('http://'+usernamebasechangeStr+':'+passwordnamebasechangeStr+'@'+hostnamebasechangeStr+':'+hostportbasechangeStr+'/'+databasenamechangeStr);
                                        function synclocal(){
                                            var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                            dblocal.sync(remotelocal, opts);
                                        }
                                        if(remotelocal){
                                            synclocal();
                                        }
                                        //end sync
                                    
                                        //var docDelete = await dbdoc.get(responseSupprimer.id);
                                        //1:delete this database
                                        const db = new PouchDB(databasenametochangeStr, {skip_setup:true});
                                        const remote = new PouchDB('http://'+usernamebasechangeStr+':'+passwordnamebasechangeStr+'@'+hostnamebasechangeStr+':'+hostportbasechangeStr+'/'+databasenametochangeStr, {skip_setup:true});
                                        PouchDB.plugin(PouchFind);
                                        function sync(){
                                            var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                            db.sync(remote, opts);
                                        }
                                        if(remote){
                                            sync();
                                        }
                                        //end sync
                                        db.destroy().then(async function(){
                                            return remote.destroy();
                                        }).then(async function(){
                                            var pTextesuccesedit = "Database deleted successfully";
                                            alert(pTextesuccesedit);
                                            //xxxxxxxxxx
                                            document.getElementById('databasetasks').style.display = 'block';
                                            document.getElementById('queriestasks').style.display = 'none';
                                            document.getElementById('crudactions').style.display = 'none';
                                        });
                                    }
                                } catch (error) {
                                    var pTexteerreurconnection = "An Error has Occurred";
                                    alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                                }
                            }else{
                                var pTextefailedconnect = "Connection Failed.";
                                alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                            }
                        }
                    }
                    var divClearModal = document.getElementById('modaloutdatabase');
                    while(divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById('backdropoutdatabase');
                    while(divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                    }
                }//
                if(deviceStr === 'mobile'){
                    const dbdoc = new PouchDB(localnamebaseStr, {skip_setup:true});
                    PouchDB.plugin(PouchFind);
                    var docsCheckDoc = [];
                    try {
                        let result = await dbdoc.createIndex({
                            index: {
                                fields: [
                                    'connectname', 
                                ]
                            }
                        });
                        result = await dbdoc.find({
                            selector: {
                                connectname: databasenametochangeStr 
                            }
                        });
                        docsCheckDoc = result.docs;
                        var resultatCheckDoc = docsCheckDoc.length;
                        if(resultatCheckDoc === ''){
                            var pTexteincorrectconnectiondoc = "Database not exists.";
                            alertModal(elementOutId,elementDropId,pTexteincorrectconnectiondoc,buttonHandler);
                        }else{
                            for(let i = 0; i<docsCheckDoc.length; i++){
                                documentID = docsCheckDoc[i]._id;
                            }
                            var docSupprimer = await dbdoc.get(documentID);
                            var responseSupprimer = await dbdoc.remove(docSupprimer);

                            const dblocal = new PouchDB(databasenamechangeStr);

                            //1:delete this database
                            const db = new PouchDB(databasenametochangeStr, {skip_setup:true});
                            PouchDB.plugin(PouchFind);
                            db.destroy().then(async function(){
                                var pTextesuccesedit = "Database deleted successfully";
                                alert(pTextesuccesedit);
                                //xxxxxxxxxx
                                document.getElementById('databasetasks').style.display = 'block';
                                document.getElementById('queriestasks').style.display = 'none';
                                document.getElementById('crudactions').style.display = 'none';
                            });
                        }
                    } catch (error) {
                        var pTexteerreurconnection = "An Error has Occurred";
                        alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                    }
                    var divClearModal = document.getElementById('modaloutdatabase');
                    while(divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById('backdropoutdatabase');
                    while(divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                    }
                }
            }

            //If No Clicked
            document.getElementById('nonHandlerNoDeleteBtn').addEventListener('click', nonHandlerNoDeleteBtnChange);
            async function nonHandlerNoDeleteBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutdatabase');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutdatabase');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }

            //If backdrop Clicked
            document.getElementById('backdropHandlerDropDeleteBtn').addEventListener('click', backdropHandlerDropDeleteBtnChange);
            async function backdropHandlerDropDeleteBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutdatabase');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutdatabase');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
        }
    }
});

/*function getData(hostname, portnumber, username, password, databasename){
    //alert
    var elementOutId = 'modaloutalertdatabase';
    var elementDropId = 'backdropoutalertdatabase';
    var buttonHandler = 'ouiHandlerAlertDatabaseBtn';

    var http_request = new XMLHttpRequest();
    http_request.open("GET", "http://"+hostname+":"+portnumber+"/_utils/" ,true);
    http_request.send("null");
    http_request.onreadystatechange = function(){
        if(http_request.readyState === 4){
            if(http_request.status === 200){
                //var pTextesuccesconnect = "Connection Successful";
                //alertModal(elementOutId,elementDropId,pTextesuccesconnect,buttonHandler);
                const testremote = new PouchDB('http://'+username+':'+password+'@'+hostname+':'+portnumber+'/'+databasename, {skip_setup:true});
            }else{
                var pTextefailedconnect = "Connection Failed";
                alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
            }
        }
    }
}*/

function couchProvider(hostname, portnumber, username, password, typebasename){
    //alert
    var elementOutId = 'modaloutalertdatabase';
    var elementDropId = 'backdropoutalertdatabase';
    var buttonHandler = 'ouiHandlerAlertDatabaseBtn';

    var dbName = '';
    var remoteCouch = '';
    var options = {
        live:true,
        retry:true,
        continuous:true,
        attachments: true,
    };

    try {
        dbName = new PouchDB(typebasename);
        remoteCouch = new PouchDB('http://'+username+':'+password+'@'+hostname+':'+portnumber+'/'+typebasename);
        PouchDB.plugin(PouchFind);
        dbName.sync(remoteCouch,options);
    } catch (error) {
        var pTexteerreurconnection = "Connection Failed";
        alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
    }
}

/**1: alertmodal function*/
function alertModal(elementOutId,elementDropId,pTexte,buttonHandler){
    var divClearModal = document.getElementById(elementOutId);
    let divModal = document.createElement('div');
    divModal.innerHTML =
    `<div class="modalalert">
        <p>${pTexte}</p>
        <button class="btnalert btnalert--alt" id="${buttonHandler}">OK</button>
    </div>`;
    divClearModal.appendChild(divModal);

    var divClearBackdrop = document.getElementById(elementDropId);
    let divBack = document.createElement('div');
    divBack.innerHTML =
    `<div class="backdropalert"></div>`;
    divClearBackdrop.appendChild(divBack);

    var buttonChange = document.getElementById(buttonHandler);
    buttonChange.addEventListener('click', (e) =>{
        e.preventDefault();

        var divClearModal = document.getElementById(elementOutId);
        while(divClearModal.hasChildNodes()) {
            divClearModal.removeChild(divClearModal.childNodes[0]);
        }

        var divClearModalDrop = document.getElementById(elementDropId);
        while(divClearModalDrop.hasChildNodes()) {
            divClearModalDrop.removeChild(divClearModalDrop.childNodes[0]);
        }
    }); 
}