import React from 'react';
import '../pouchtasks';
import '../tasks.css';

import PrevDeskTasks from '../compotasks/PrevDeskTasks';
import PrevMobileTasks from '../compotasks/PrevMobileTasks';

function PreviousTasks() {
    return ( 
        <div id="previoustasks">
            <PrevDeskTasks />
            <PrevMobileTasks />
        </div>
    );
}

export default PreviousTasks;