/* eslint-disable no-redeclare */
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';

window.addEventListener('load', () => {
    document.getElementById('formserver').style.display = 'none';

    //local sever test
    document.getElementById('localservertest').addEventListener('click', localservertestChange);
    async function localservertestChange(e){
        e.preventDefault();

        var deviceStr = document.getElementById('device').innerText;

        var divClearDevice = document.getElementById('device');
        while(divClearDevice.hasChildNodes()) {
            divClearDevice.removeChild(divClearDevice.childNodes[0]);
        }

        document.getElementById('formserver').style.display = 'block';
        document.getElementById('tester').style.display = 'block';
        document.getElementById('sauvegarder').style.display = 'none';

        if(deviceStr === ''){
            var deviceStr = document.getElementById('device');
            deviceStr.innerText = '';
            var typeForm = document.getElementById('typeform');
            typeForm.innerText = "Desktop Test Local Connection";
            //xxxxxxxxxxx
            var testmobileStr = document.getElementById('testmobile');
            testmobileStr.innerText = "Hostname: 127.0.0.1 // Port: 5984";
            document.getElementById('testmobile').style.display = 'block';
            //xxxxxxxxxxxx
            document.getElementById('deskserver').style.display = 'block';
            document.getElementById('mobileserver').style.display = 'none';
        }

        if(deviceStr === 'mobile'){
            var deviceStr = document.getElementById('device');
            deviceStr.innerText = 'mobile';
            var typeForm = document.getElementById('typeform');
            typeForm.innerText = "Mobile Test Local Connection";
            //xxxxxxxxxxx
            document.getElementById('testmobile').style.display = 'none';
            //xxxxxxxxxxx
            document.getElementById('deskserver').style.display = 'none';
            document.getElementById('mobileserver').style.display = 'block';
        }
        var serveurHeader = document.getElementById('serveurheader');
        serveurHeader.innerText = "local";
    }

    //remote sever test
    document.getElementById('remoteservertest').addEventListener('click', remoteservertestChange);
    async function remoteservertestChange(e){
        e.preventDefault();

        var deviceStr = document.getElementById('device').innerText;

        document.getElementById('formserver').style.display = 'block';
        document.getElementById('tester').style.display = 'block';
        document.getElementById('sauvegarder').style.display = 'none';

        if(deviceStr === ''){
            var deviceStr = document.getElementById('device');
            deviceStr.innerText = '';
            var typeForm = document.getElementById('typeform');
            typeForm.innerText = "Desktop Test Remote Connection";
        }

        if(deviceStr === 'mobile'){
            var deviceStr = document.getElementById('device');
            deviceStr.innerText = 'mobile';
            var typeForm = document.getElementById('typeform');
            typeForm.innerText = "Mobile Test Remote Connection";
        }

        //xxxxxxxxxxxx
        document.getElementById('deskserver').style.display = 'block';
        document.getElementById('mobileserver').style.display = 'none';
        //xxxxxxxxxx
        var testmobileStr = document.getElementById('testmobile');
        testmobileStr.innerText = "Remote CouchDB IP -- Port:5984";

        var serveurHeader = document.getElementById('serveurheader');
        serveurHeader.innerText = "remote";
    }

    //cancel
    document.getElementById('cancelconnection').addEventListener('click', cancelconnectionChange);
    async function cancelconnectionChange(e){
        e.preventDefault();

        document.getElementById('principal').style.display = 'block';
        document.getElementById('serveur').style.display = 'none';
        document.getElementById('formserver').style.display = 'none';
        var serveurHeader = document.getElementById('serveurheader');
        serveurHeader.innerText = "Server";
    }

    //test connection local / remote
    document.getElementById('testconnection').addEventListener('click', testconnectionChange);
    async function testconnectionChange(e){
        e.preventDefault();

        
        //set variable values desktop
        var connectnameStr = document.getElementById('connectname').value;
        var hostnameStr = document.getElementById('hostname').value;
        var hostportStr = document.getElementById('hostport').value;
        var usernameStr = document.getElementById('username').value;
        var passwordnameStr = document.getElementById('passwordname').value;
        //set variable values mobile
        var mobileconnectnameStr = document.getElementById('mobileconnectname').value;
        var mobileusernameStr = document.getElementById('mobileusername').value;
        var mobilepasswordnameStr = document.getElementById('mobilepasswordname').value;

        var serveurHeaderStr = document.getElementById('serveurheader').innerText;
        var deviceStr = document.getElementById('device').innerText;

        //alert
        var elementOutId = 'modaloutalertserver';
        var elementDropId = 'backdropoutalertserver';
        var buttonHandler = 'ouiHandlerAlertServerBtn';

        if(deviceStr === ''){
            //check if values not null
            if(!connectnameStr){
                var pTexteconnectname = "Enter Connection Name";
                alertModal(elementOutId,elementDropId,pTexteconnectname,buttonHandler);
            }else if(!hostnameStr){
                var pTextehostname = "Enter Host Name";
                alertModal(elementOutId,elementDropId,pTextehostname,buttonHandler);
            }else if(!hostportStr){
                var pTexteportname = "Enter Port Number";
                alertModal(elementOutId,elementDropId,pTexteportname,buttonHandler);
            }else if(!usernameStr){
                var pTexteusername = "Enter Username";
                alertModal(elementOutId,elementDropId,pTexteusername,buttonHandler);
            }else if(!passwordnameStr){
                var pTextepasswordname = "Enter Password";
                alertModal(elementOutId,elementDropId,pTextepasswordname,buttonHandler);
            }else{
                //check if type connection already exists
                if(serveurHeaderStr === 'local'){
                    getDeskData(hostnameStr, hostportStr);
                }else if(serveurHeaderStr === 'remote'){
                    getDeskData(hostnameStr, hostportStr);
                }else{
                    var pTexteservername = "Select Server Type";
                    alertModal(elementOutId,elementDropId,pTexteservername,buttonHandler);
                }
            }
        };

        if(deviceStr === 'mobile'){
            //check if values not null
            if(!mobileconnectnameStr){
                var pTexteconnectname = "Enter Connection Name";
                alertModal(elementOutId,elementDropId,pTexteconnectname,buttonHandler);
            }else if(!mobileusernameStr){
                var pTexteusername = "Enter Username";
                alertModal(elementOutId,elementDropId,pTexteusername,buttonHandler);
            }else if(!mobilepasswordnameStr){
                var pTextepasswordname = "Enter Password";
                alertModal(elementOutId,elementDropId,pTextepasswordname,buttonHandler);
            }else{
                //call getMobileData
                var datatabaseName = document.getElementById('serveurheader').innerText;
                getMobileData(datatabaseName);
            }
        }
        
    }

    //save connection parameters local / remote
    document.getElementById('saveconnection').addEventListener('click', saveconnectionChange);
    async function saveconnectionChange(e){
        e.preventDefault();

        //alert
        var elementOutId = 'modaloutalertserver';
        var elementDropId = 'backdropoutalertserver';
        var buttonHandler = 'ouiHandlerAlertServerBtn';

        //set variable values
        //desktop
        var connectnameStr = document.getElementById('connectname').value;
        var hostnameStr = document.getElementById('hostname').value;
        var hostportStr = document.getElementById('hostport').value;
        var usernameStr = document.getElementById('username').value;
        var passwordnameStr = document.getElementById('passwordname').value;
        //mobile
        var mobileconnectnameStr = document.getElementById('mobileconnectname').value;
        var mobileusernameStr = document.getElementById('mobileusername').value;
        var mobilepasswordnameStr = document.getElementById('mobilepasswordname').value;
        var serveurHeaderStr = document.getElementById('serveurheader').innerText;
        var deviceStr = document.getElementById('device').innerText;

        //database = local
        if(serveurHeaderStr === 'local' && deviceStr === ''){
            //call this couchDB database
            const db = new PouchDB(serveurHeaderStr);
            const remote = couchProvider(hostnameStr, hostportStr, usernameStr, passwordnameStr, serveurHeaderStr);
            // Initialise a sync with the remote server
            function sync(){
                var opts = {live: true, retry: true, continuous: true, attachments: true,};
                db.sync(remote, opts);
            }
            if(remote){
                sync();
            }
            //end sync
            var docsCheckServer = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: [
                            'hostname', 
                            'portname',
                            'username',
                            'passwordname'
                        ]
                    }
                });
                result = await db.find({
                    selector: {
                        hostname: hostnameStr,
                        portname: hostportStr,
                        username: usernameStr,
                        passwordname: passwordnameStr
                    }
                });
                docsCheckServer = result.docs;
                var resultatCheck = docsCheckServer.length;
                if(resultatCheck === 0){
                    //Create the document _id and _rev.
                    var now = Date.now();
                    var id = 'servlocal_' + now;
                    // Add the document to the database.
                    var response = await db.put({
                        _id: id,
                        connectname: connectnameStr,
                        hostname: hostnameStr,
                        portname: hostportStr,
                        username: usernameStr,
                        passwordname: passwordnameStr,
                        servername: serveurHeaderStr,
                    });
                    // check if document inserted
                    var doc = await db.get(response.id);
                    if(doc !== ''){
                        var pTextesuccesconnect = "Connection has been saved Succesfully";
                        alertModal(elementOutId,elementDropId,pTextesuccesconnect,buttonHandler);
                    }else{
                        var pTextefailedconnect = "Connection Failed";
                        alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler); 
                    }
                }else{
                    var divClearModal = document.getElementById('modaloutserver');
                    let divModal = document.createElement('div');
                    divModal.innerHTML =
                    `<div class="modal">
                        <p>Connection already saved. Change Connection name?</p>
                        <button class="btn btn--alt" id="ouiHandlerChangeBtn">Yes</button>
                        <button class="btn" id="nonHandlerChangeBtn">No</button>
                    </div>`;
                    divClearModal.appendChild(divModal);

                    var divClearBackdrop = document.getElementById('backdropoutserver');
                    let divBack = document.createElement('div');
                    divBack.innerHTML =
                    `<div class="backdrop" id="backdropHandlerChangeBtn"></div>`;
                    divClearBackdrop.appendChild(divBack);

                    //If Yes Clicked
                    document.getElementById('ouiHandlerChangeBtn').addEventListener('click', ouiHandlerChangeBtnChange);
                    async function ouiHandlerChangeBtnChange(e){
                        e.preventDefault();

                        var idConnection = '';
                        var docsEdit = [];

                        for(let i=0; i<docsCheckServer.length; i++){
                            idConnection = docsCheckServer[i]._id;
                        }
                        //edit connection name
                        try {
                            var doc = await db.get(idConnection);
                            // Make changes
                            doc.connectname = connectnameStr;
                            doc.hostname = hostnameStr;
                            doc.portname = hostportStr;
                            doc.username = usernameStr;
                            doc.passwordname = passwordnameStr;
                            doc.servername = serveurHeaderStr;
                            // Put the document, saving it as a new revision
                            var response = await db.put(doc);
                            docsEdit = await db.get(response.id);
                            if(docsEdit !== ''){
                                var pTexteeditconnect = "Connection name edited Successfully";
                                alertModal(elementOutId,elementDropId,pTexteeditconnect,buttonHandler);
                                document.getElementById('tester').style.display = 'block';
                                document.getElementById('sauvegarder').style.display = 'none';
                            }else{
                                var pTextefailedconnect = "Connection name edited Failed";
                                alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                                document.getElementById('tester').style.display = 'block';
                                document.getElementById('sauvegarder').style.display = 'none';
                            }
                        } catch (error) {
                            var pTexteconnect = "An error has occurred";
                            alertModal(elementOutId,elementDropId,pTexteconnect,buttonHandler);
                        }

                        var divClearModal = document.getElementById('modaloutserver');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutserver');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }
                    };

                    //If No Clicked
                    document.getElementById('nonHandlerChangeBtn').addEventListener('click', nonHandlerChangeBtnChange);
                    async function nonHandlerChangeBtnChange(e){
                        e.preventDefault();

                        document.getElementById('tester').style.display = 'block';
                        document.getElementById('sauvegarder').style.display = 'none';

                        var divClearModal = document.getElementById('modaloutserver');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutserver');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }
                    };

                    //If backdrop Clicked
                    document.getElementById('backdropHandlerChangeBtn').addEventListener('click', backdropHandlerChangeBtnChange);
                    async function backdropHandlerChangeBtnChange(e){
                        e.preventDefault();

                        document.getElementById('tester').style.display = 'block';
                        document.getElementById('sauvegarder').style.display = 'none';

                        var divClearModal = document.getElementById('modaloutserver');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutserver');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }
                    };
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        //database = remote
        if((serveurHeaderStr === 'remote' && deviceStr === '') || (serveurHeaderStr === 'remote' && deviceStr === 'mobile')){
            //call this couchDB database
            const db = new PouchDB(serveurHeaderStr);
            const remote = couchProvider(hostnameStr, hostportStr, usernameStr, passwordnameStr, serveurHeaderStr);
            // Initialise a sync with the remote server
            function sync(){
                var opts = {live: true, retry: true, continuous: true, attachments: true,};
                db.sync(remote, opts);
            }
            if(remote){
                sync();
            }
            //end sync
            var docsCheckServer = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: [
                            'hostname', 
                            'portname',
                            'username',
                            'passwordname'
                        ]
                    }
                });
                result = await db.find({
                    selector: {
                        hostname: hostnameStr,
                        portname: hostportStr,
                        username: usernameStr,
                        passwordname: passwordnameStr
                    }
                });
                docsCheckServer = result.docs;
                var resultatCheck = docsCheckServer.length;
                if(resultatCheck === 0){
                    //Create the document _id and _rev.
                    var now = Date.now();
                    var id = 'servlocal_' + now;
                    // Add the document to the database.
                    var response = await db.put({
                        _id: id,
                        connectname: connectnameStr,
                        hostname: hostnameStr,
                        portname: hostportStr,
                        username: usernameStr,
                        passwordname: passwordnameStr,
                        servername: serveurHeaderStr,
                    });
                    // check if document inserted
                    var doc = await db.get(response.id);
                    if(doc !== ''){
                        var pTextesuccesconnect = "Connection has been saved Succesfully";
                        alertModal(elementOutId,elementDropId,pTextesuccesconnect,buttonHandler);
                    }else{
                        var pTextefailedconnect = "Connection Failed";
                        alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler); 
                    }
                }else{
                    var divClearModal = document.getElementById('modaloutserver');
                    let divModal = document.createElement('div');
                    divModal.innerHTML =
                    `<div class="modal">
                        <p>Connection already saved. Change Connection name?</p>
                        <button class="btn btn--alt" id="ouiHandlerChangeBtn">Yes</button>
                        <button class="btn" id="nonHandlerChangeBtn">No</button>
                    </div>`;
                    divClearModal.appendChild(divModal);

                    var divClearBackdrop = document.getElementById('backdropoutserver');
                    let divBack = document.createElement('div');
                    divBack.innerHTML =
                    `<div class="backdrop" id="backdropHandlerChangeBtn"></div>`;
                    divClearBackdrop.appendChild(divBack);

                    //If Yes Clicked
                    document.getElementById('ouiHandlerChangeBtn').addEventListener('click', ouiHandlerChangeBtnChange);
                    async function ouiHandlerChangeBtnChange(e){
                        e.preventDefault();

                        var idConnection = '';
                        var docsEdit = [];

                        for(let i=0; i<docsCheckServer.length; i++){
                            idConnection = docsCheckServer[i]._id;
                        }
                        //edit connection name
                        try {
                            var doc = await db.get(idConnection);
                            // Make changes
                            doc.connectname = connectnameStr;
                            doc.hostname = hostnameStr;
                            doc.portname = hostportStr;
                            doc.username = usernameStr;
                            doc.passwordname = passwordnameStr;
                            doc.servername = serveurHeaderStr;
                            // Put the document, saving it as a new revision
                            var response = await db.put(doc);
                            docsEdit = await db.get(response.id);
                            if(docsEdit !== ''){
                                var pTexteeditconnect = "Connection name edited Successfully";
                                alertModal(elementOutId,elementDropId,pTexteeditconnect,buttonHandler);
                                document.getElementById('tester').style.display = 'block';
                                document.getElementById('sauvegarder').style.display = 'none';
                            }else{
                                var pTextefailedconnect = "Connection name edited Failed";
                                alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                                document.getElementById('tester').style.display = 'block';
                                document.getElementById('sauvegarder').style.display = 'none';
                            }
                        } catch (error) {
                            var pTexteconnect = "An error has occurred";
                            alertModal(elementOutId,elementDropId,pTexteconnect,buttonHandler);
                        }

                        var divClearModal = document.getElementById('modaloutserver');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutserver');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }
                    };

                    //If No Clicked
                    document.getElementById('nonHandlerChangeBtn').addEventListener('click', nonHandlerChangeBtnChange);
                    async function nonHandlerChangeBtnChange(e){
                        e.preventDefault();

                        document.getElementById('tester').style.display = 'block';
                        document.getElementById('sauvegarder').style.display = 'none';

                        var divClearModal = document.getElementById('modaloutserver');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutserver');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }
                    };

                    //If backdrop Clicked
                    document.getElementById('backdropHandlerChangeBtn').addEventListener('click', backdropHandlerChangeBtnChange);
                    async function backdropHandlerChangeBtnChange(e){
                        e.preventDefault();

                        document.getElementById('tester').style.display = 'block';
                        document.getElementById('sauvegarder').style.display = 'none';

                        var divClearModal = document.getElementById('modaloutserver');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutserver');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }
                    };
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };

        //database = local
        if(serveurHeaderStr === 'local' && deviceStr === 'mobile'){
            //call this couchDB database
            const db = new PouchDB(serveurHeaderStr, {skip_setup:true});
            var docsCheckServer = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: [
                            'username',
                            'passwordname'
                        ]
                    }
                });
                result = await db.find({
                    selector: {
                        username: mobileusernameStr,
                        passwordname: mobilepasswordnameStr
                    }
                });
                docsCheckServer = result.docs;
                var resultatCheck = docsCheckServer.length;
                if(resultatCheck === 0){
                    //Create the document _id and _rev.
                    var now = Date.now();
                    var id = 'servlocal_' + now;
                    // Add the document to the database.
                    var response = await db.put({
                        _id: id,
                        connectname: mobileconnectnameStr,
                        username: mobileusernameStr,
                        passwordname: mobilepasswordnameStr,
                        servername: serveurHeaderStr,
                    });
                    // check if document inserted
                    var doc = await db.get(response.id);
                    if(doc !== ''){
                        var pTextesuccesconnect = "Connection has been saved Succesfully";
                        alertModal(elementOutId,elementDropId,pTextesuccesconnect,buttonHandler);
                    }else{
                        var pTextefailedconnect = "Connection Failed";
                        alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler); 
                    }
                    //xxxxxxxxxxxxxxxx
                    /*document.getElementById('formserver').style.display = 'none';
                    document.getElementById('tester').style.display = 'none';
                    document.getElementById('sauvegarder').style.display = 'none';
                    //xxxxxxxxxxxxxx
                    document.getElementById('deskserver').style.display = 'none';
                    document.getElementById('mobileserver').style.display = 'none';*/
                    //xxxxxxxxxxxxxx
                }else{
                    var divClearModal = document.getElementById('modaloutserver');
                    let divModal = document.createElement('div');
                    divModal.innerHTML =
                    `<div class="modal">
                        <p>Connection already saved. Change Connection name?</p>
                        <button class="btn btn--alt" id="ouiHandlerChangeBtn">Yes</button>
                        <button class="btn" id="nonHandlerChangeBtn">No</button>
                    </div>`;
                    divClearModal.appendChild(divModal);

                    var divClearBackdrop = document.getElementById('backdropoutserver');
                    let divBack = document.createElement('div');
                    divBack.innerHTML =
                    `<div class="backdrop" id="backdropHandlerChangeBtn"></div>`;
                    divClearBackdrop.appendChild(divBack);

                    //If Yes Clicked
                    document.getElementById('ouiHandlerChangeBtn').addEventListener('click', ouiHandlerChangeBtnChange);
                    async function ouiHandlerChangeBtnChange(e){
                        e.preventDefault();

                        var idConnection = '';
                        var connectionStr = '';
                        var usernameStr = '';
                        var passwordStr = '';
                        var docsEdit = [];

                        for(let i=0; i<docsCheckServer.length; i++){
                            idConnection = docsCheckServer[i]._id;
                            connectionStr = docsCheckServer[i].connectname;
                            usernameStr = docsCheckServer[i].username;
                            passwordStr = docsCheckServer[i].passwordname;
                        }
                        document.getElementById('mobileconnectname').value = connectionStr;
                        document.getElementById('mobileusername').value = usernameStr;
                        document.getElementById('mobilepasswordname').value = passwordStr;
                        //edit connection name
                        try {
                            var doc = await db.get(idConnection);
                            // Make changes
                            doc.connectname = mobileconnectnameStr;
                            doc.username = mobileusernameStr;
                            doc.passwordname = mobilepasswordnameStr;
                            doc.servername = serveurHeaderStr;
                            // Put the document, saving it as a new revision
                            var response = await db.put(doc);
                            docsEdit = await db.get(response.id);
                            if(docsEdit !== ''){
                                var pTexteeditconnect = "Connection name edited Successfully";
                                alertModal(elementOutId,elementDropId,pTexteeditconnect,buttonHandler);
                                /*document.getElementById('tester').style.display = 'block';
                                document.getElementById('sauvegarder').style.display = 'none';*/
                            }else{
                                var pTextefailedconnect = "Connection name edited Failed";
                                alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                                /*document.getElementById('tester').style.display = 'block';
                                document.getElementById('sauvegarder').style.display = 'none';*/
                            }
                        } catch (error) {
                            var pTexteconnect = "An error has occurred";
                            alertModal(elementOutId,elementDropId,pTexteconnect,buttonHandler);
                        }

                        var divClearModal = document.getElementById('modaloutserver');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutserver');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }
                    };

                    //If No Clicked
                    document.getElementById('nonHandlerChangeBtn').addEventListener('click', nonHandlerChangeBtnChange);
                    async function nonHandlerChangeBtnChange(e){
                        e.preventDefault();

                        document.getElementById('tester').style.display = 'block';
                        document.getElementById('sauvegarder').style.display = 'none';

                        var divClearModal = document.getElementById('modaloutserver');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutserver');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }
                    };

                    //If backdrop Clicked
                    document.getElementById('backdropHandlerChangeBtn').addEventListener('click', backdropHandlerChangeBtnChange);
                    async function backdropHandlerChangeBtnChange(e){
                        e.preventDefault();

                        document.getElementById('tester').style.display = 'block';
                        document.getElementById('sauvegarder').style.display = 'none';

                        var divClearModal = document.getElementById('modaloutserver');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutserver');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }
                    };
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }; 
        };
    }
});

function getDeskData(hostname, portnumber){
    //alert
    var elementOutId = 'modaloutalertserver';
    var elementDropId = 'backdropoutalertserver';
    var buttonHandler = 'ouiHandlerAlertServerBtn';
    
    var http_request = new XMLHttpRequest();
    http_request.open("GET", "http://"+hostname+":"+portnumber+"/_utils/", true);
    http_request.send("null");
    http_request.onreadystatechange = function(){
        if(http_request.readyState === 4){
            if(http_request.status === 200){
                var divClearModal = document.getElementById('modaloutserver');
                let divModal = document.createElement('div');
                divModal.innerHTML =
                `<div class="modal">
                    <p>Connection Successful. Save?</p>
                    <button class="btn btn--alt" id="ouiHandlerSaveBtn">Yes</button>
                    <button class="btn" id="nonHandlerSaveBtn">No</button>
                </div>`;
                divClearModal.appendChild(divModal);

                var divClearBackdrop = document.getElementById('backdropoutserver');
                let divBack = document.createElement('div');
                divBack.innerHTML =
                `<div class="backdrop" id="backdropHandlerSaveBtn"></div>`;
                divClearBackdrop.appendChild(divBack);

                //If Yes Clicked
                document.getElementById('ouiHandlerSaveBtn').addEventListener('click', ouiHandlerSaveBtnChange);
                async function ouiHandlerSaveBtnChange(e){
                    e.preventDefault();

                    //set variable values
                    var hostnameStr = document.getElementById('hostname').value;
                    var hostportStr = document.getElementById('hostport').value;
                    var usernameStr = document.getElementById('username').value;
                    var passwordnameStr = document.getElementById('passwordname').value;
                    var serveurHeaderStr = document.getElementById('serveurheader').innerText;

                    //call this couchDB database
                    const db = new PouchDB(serveurHeaderStr);
                    const remote = couchProvider(hostnameStr, hostportStr, usernameStr, passwordnameStr, serveurHeaderStr);
                    // Initialise a sync with the remote server
                    function sync(){
                        var opts = {live: true, retry: true, continuous: true, attachments: true,};
                        db.sync(remote, opts);
                    }
                    if(remote){
                        sync();
                    }
                    //end sync

                    document.getElementById('tester').style.display = 'none';
                    document.getElementById('sauvegarder').style.display = 'block';

                    var divClearModal = document.getElementById('modaloutserver');
                    while(divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById('backdropoutserver');
                    while(divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                    }
                };

                //If No Clicked
                document.getElementById('nonHandlerSaveBtn').addEventListener('click', nonHandlerSaveBtnChange);
                async function nonHandlerSaveBtnChange(e){
                    e.preventDefault();

                    document.getElementById('tester').style.display = 'block';
                    document.getElementById('sauvegarder').style.display = 'none';

                    var divClearModal = document.getElementById('modaloutserver');
                    while(divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById('backdropoutserver');
                    while(divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                    }
                };

                //If Backdrop Clicked
                document.getElementById('backdropHandlerSaveBtn').addEventListener('click', backdropHandlerSaveBtnChange);
                async function backdropHandlerSaveBtnChange(e){
                    e.preventDefault();

                    document.getElementById('tester').style.display = 'block';
                    document.getElementById('sauvegarder').style.display = 'none';

                    var divClearModal = document.getElementById('modaloutserver');
                    while(divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById('backdropoutserver');
                    while(divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                    }
                }
            }else{
                var divClearModal = document.getElementById('modaloutserver');
                let divModal = document.createElement('div');
                divModal.innerHTML =
                `<div class="modal">
                    <p>Are you running Couchbench on mobile device?"</p>
                    <button class="btn btn--alt" id="ouiHandlerMobileBtn">Yes</button>
                    <button class="btn" id="nonHandlerMobileBtn">No</button>
                </div>`;
                divClearModal.appendChild(divModal);

                var divClearBackdrop = document.getElementById('backdropoutserver');
                let divBack = document.createElement('div');
                divBack.innerHTML =
                `<div class="backdrop" id="backdropHandlerMobileBtn"></div>`;
                divClearBackdrop.appendChild(divBack);

                //If Yes Clicked
                document.getElementById('ouiHandlerMobileBtn').addEventListener('click', ouiHandlerMobileBtnChange);
                async function ouiHandlerMobileBtnChange(e){
                    e.preventDefault();

                    document.getElementById('tester').style.display = 'none';
                    document.getElementById('sauvegarder').style.display = 'block';
                    var deviceStr = document.getElementById('device');
                    deviceStr.innerText = 'mobile';

                    var divClearModal = document.getElementById('modaloutserver');
                    while(divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById('backdropoutserver');
                    while(divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                    }
                }

                //If No Clicked
                document.getElementById('nonHandlerMobileBtn').addEventListener('click', nonHandlerMobileBtnChange);
                async function nonHandlerMobileBtnChange(e){
                    e.preventDefault();

                    

                    document.getElementById('tester').style.display = 'block';
                    document.getElementById('sauvegarder').style.display = 'none';

                    var divClearModal = document.getElementById('modaloutserver');
                    while(divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById('backdropoutserver');
                    while(divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                    }

                    var pTextefailedconnect = "Connection Failed";
                    alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                };

                //If Backdrop Clicked
                document.getElementById('backdropHandlerMobileBtn').addEventListener('click', backdropHandlerMobileBtnChange);
                async function backdropHandlerMobileBtnChange(e){
                    e.preventDefault();

                    document.getElementById('tester').style.display = 'block';
                    document.getElementById('sauvegarder').style.display = 'none';

                    var divClearModal = document.getElementById('modaloutserver');
                    while(divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById('backdropoutserver');
                    while(divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                    }
                }
            }
            http_request = null;
        }
    };
}

function getMobileData(databasename){
    var divClearModal = document.getElementById('modaloutserver');
    let divModal = document.createElement('div');
    divModal.innerHTML =
    `<div class="modal">
        <p>Connection Successful. Save?</p>
        <button class="btn btn--alt" id="ouiHandlerSaveBtn">Yes</button>
        <button class="btn" id="nonHandlerSaveBtn">No</button>
    </div>`;
    divClearModal.appendChild(divModal);

    var divClearBackdrop = document.getElementById('backdropoutserver');
    let divBack = document.createElement('div');
    divBack.innerHTML =
    `<div class="backdrop" id="backdropHandlerSaveBtn"></div>`;
    divClearBackdrop.appendChild(divBack);

    //If Yes Clicked
    document.getElementById('ouiHandlerSaveBtn').addEventListener('click', ouiHandlerSaveBtnChange);
    async function ouiHandlerSaveBtnChange(e){
        e.preventDefault();

        new PouchDB(databasename);
        PouchDB.plugin(PouchFind);

        document.getElementById('tester').style.display = 'none';
        document.getElementById('sauvegarder').style.display = 'block';

        var divClearModal = document.getElementById('modaloutserver');
        while(divClearModal.hasChildNodes()) {
            divClearModal.removeChild(divClearModal.childNodes[0]);
        }

        var divClearBackdrop = document.getElementById('backdropoutserver');
        while(divClearBackdrop.hasChildNodes()) {
            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
        }
    };

    //If No Clicked
    document.getElementById('nonHandlerSaveBtn').addEventListener('click', nonHandlerSaveBtnChange);
    async function nonHandlerSaveBtnChange(e){
        e.preventDefault();

        document.getElementById('tester').style.display = 'block';
        document.getElementById('sauvegarder').style.display = 'none';

        var divClearModal = document.getElementById('modaloutserver');
        while(divClearModal.hasChildNodes()) {
            divClearModal.removeChild(divClearModal.childNodes[0]);
        }

        var divClearBackdrop = document.getElementById('backdropoutserver');
        while(divClearBackdrop.hasChildNodes()) {
            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
        }
    };

    //If Backdrop Clicked
    document.getElementById('backdropHandlerSaveBtn').addEventListener('click', backdropHandlerSaveBtnChange);
    async function backdropHandlerSaveBtnChange(e){
        e.preventDefault();

        document.getElementById('tester').style.display = 'block';
        document.getElementById('sauvegarder').style.display = 'none';

        var divClearModal = document.getElementById('modaloutserver');
        while(divClearModal.hasChildNodes()) {
            divClearModal.removeChild(divClearModal.childNodes[0]);
        }

        var divClearBackdrop = document.getElementById('backdropoutserver');
        while(divClearBackdrop.hasChildNodes()) {
            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
        }
    };
}

function couchProvider(hostname, portnumber, username, password, typebasename){
    //alert
    var elementOutId = 'modaloutalertserver';
    var elementDropId = 'backdropoutalertserver';
    var buttonHandler = 'ouiHandlerAlertServerBtn';

    var dbName = '';
    var remoteCouch = '';
    var options = {
        live:true,
        retry:true,
        continuous:true,
        attachments: true,
    };

    try {
        dbName = new PouchDB(typebasename);
        remoteCouch = new PouchDB('http://'+username+':'+password+'@'+hostname+':'+portnumber+'/'+typebasename);
        PouchDB.plugin(PouchFind);
        dbName.sync(remoteCouch,options);
    } catch (error) {
        var pTexteerreurconnection = "Connection Failed";
        alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
    }
}

/**1: alertmodal function*/
function alertModal(elementOutId,elementDropId,pTexte,buttonHandler){
    var divClearModal = document.getElementById(elementOutId);
    let divModal = document.createElement('div');
    divModal.innerHTML =
    `<div class="modalalert">
        <p>${pTexte}</p>
        <button class="btnalert btnalert--alt" id="${buttonHandler}">OK</button>
    </div>`;
    divClearModal.appendChild(divModal);

    var divClearBackdrop = document.getElementById(elementDropId);
    let divBack = document.createElement('div');
    divBack.innerHTML =
    `<div class="backdropalert"></div>`;
    divClearBackdrop.appendChild(divBack);

    var buttonChange = document.getElementById(buttonHandler);
    buttonChange.addEventListener('click', (e) =>{
        e.preventDefault();

        var divClearModal = document.getElementById(elementOutId);
        while(divClearModal.hasChildNodes()) {
            divClearModal.removeChild(divClearModal.childNodes[0]);
        }

        var divClearModalDrop = document.getElementById(elementDropId);
        while(divClearModalDrop.hasChildNodes()) {
            divClearModalDrop.removeChild(divClearModalDrop.childNodes[0]);
        }
    }); 
}

/**2: boxmodal function*/
/*function boxModal(elementOutId,elementDropId,pTexte,buttonYesHandler,buttonNoHandler,
buttonDropHandler,none1,none2,block1){
    var divClearModal = document.getElementById(elementOutId);
    let divModal = document.createElement('div');
    divModal.innerHTML =
    `<div class="modal">
        <p>${pTexte}</p>
        <button class="btn btn--alt" id="${buttonYesHandler}">Yes</button>
        <button class="btn" id="${buttonNoHandler}">No</button>
        </div>`;
    divClearModal.appendChild(divModal);

    var divClearBackdrop = document.getElementById(elementDropId);
    let divBack = document.createElement('div');
    divBack.innerHTML =
    `<div class="backdrop" id="${buttonDropHandler}"></div>`;
    divClearBackdrop.appendChild(divBack);

    //If Yes clicked
    var buttonYes = document.getElementById(buttonYesHandler);
    buttonYes.addEventListener('click', (e) => {
        e.preventDefault();

        //display none settings
        document.getElementById(none1).style.display = 'none';
        document.getElementById(none2).style.display = 'none';

        //display block settings
        document.getElementById(block1).style.display = 'block';

        var divClearModalDrop = document.getElementById(elementDropId);
        while(divClearModalDrop.hasChildNodes()) {
            divClearModalDrop.removeChild(divClearModalDrop.childNodes[0]);
        }
    });

    //If No clicked
    var buttonNo = document.getElementById(buttonNoHandler);
    buttonNo.addEventListener('click', (e) => {
        e.preventDefault();

        //display none settings
        document.getElementById(none1).style.display = 'none';

        //display block settings
        document.getElementById(block1).style.display = 'block';

        var divClearModal = document.getElementById(elementOutId);
        while(divClearModal.hasChildNodes()) {
            divClearModal.removeChild(divClearModal.childNodes[0]);
        }

        var divClearModalDrop = document.getElementById(elementDropId);
        while(divClearModalDrop.hasChildNodes()) {
            divClearModalDrop.removeChild(divClearModalDrop.childNodes[0]);
        }
    });

    //If dropout clicked
    var buttonDrop = document.getElementById(buttonDropHandler);
    buttonDrop.addEventListener('click', (e) => {
        e.preventDefault();

        var divClearModal = document.getElementById(elementOutId);
        while(divClearModal.hasChildNodes()) {
            divClearModal.removeChild(divClearModal.childNodes[0]);
        }

        var divClearModalDrop = document.getElementById(elementDropId);
        while(divClearModalDrop.hasChildNodes()) {
            divClearModalDrop.removeChild(divClearModalDrop.childNodes[0]);
        }
    });
}*/