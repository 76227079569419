import React from 'react';
import '../pouchqueries';

import { 
    ButtonDiv, 
    StyleButton, 
    //TextLocal, 
} from '../QueriesStyles';
import { LargeInput, ServeurContainer, SmallInput, PortInput } from '../../serveur/ServeurStyles';

//XXXXXXXXXXXXXX START SELECT FROM TABLE XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
export function SelectTable() {
    return ( 
        <div id="selecttable">
            <div id="result">
                <ul>
                    <li id="all_0">Select all rows of all columns</li>
                    <li id="all_1">Select all rows of 1 column</li>
                    <li id="all_2">Select all rows of 2 columns</li>
                    <li id="all_3">Select all rows of 3 columns</li>
                </ul>
            </div>
        </div>
    );
}

export function SelectOneColumn(){
    return(
        <div id='selectonecolumn'>
            <br/>
            <div className='autocomplete' style={{width:'300px'}}>
                <LargeInput id='onecolumn' type='text' placeholder='Enter column name' />
            </div>
            <br/>
            <ButtonDiv id='confirmeronecolumn'>
                <StyleButton id='confirmonecolumn'>Confirm</StyleButton>
            </ButtonDiv>
            <br/>
        </div>
    );
}

export function SelectTwoColumn(){
    return(
        <div id='selecttwocolumn'>
            <br/>
            <div className='autocomplete' style={{width:'300px'}}>
                <div>
                    <LargeInput id='twocolumn_one' type='text' placeholder='Enter column 1 name' />
                </div>
                <br/>
                <div>
                    <LargeInput id='twocolumn_two' type='text' placeholder='Enter column 2 name' />
                </div>
            </div>
            <br/>
            <ButtonDiv id='confirmertwocolumn'>
                <StyleButton id='confirmtwocolumn'>Confirm</StyleButton>
            </ButtonDiv>
            <br/>
        </div>
    );
}

export function SelectThreeColumn(){
    return(
        <div id='selectthreecolumn'>
            <br/>
            <div className='autocomplete' style={{width:'300px'}}>
                <div>
                    <LargeInput id='threecolumn_one' type='text' placeholder='Enter column 1 name' />
                </div>
                <br/>
                <div>
                    <LargeInput id='threecolumn_two' type='text' placeholder='Enter column 2 name' />
                </div>
                <br/>
                <div>
                    <LargeInput id='threecolumn_three' type='text' placeholder='Enter column 3 name' />
                </div>
            </div>
            <br/>
            <ButtonDiv id='confirmerthreecolumn'>
                <StyleButton id='confirmthreecolumn'>Confirm</StyleButton>
            </ButtonDiv>
            <br/>
        </div>
    );
}
//XXXXXXXXXXXXXX END SELECT FROM TABLE XXXXXXXXXXXXXXXXXXXXXXXX 

//XXXXXXXXXXXXXXXX START SELECT FROM TABLE WHERE XXXXXXXXXXXXXXX 
export function WhereTable(){
    return ( 
        <div id="wheretable">
            <div id="result">
                <ul>
                    <li id="where_1">From table where 1 column</li>
                    <li id="where_2">From table where 2 columns</li>
                    <li id="where_3">From table where 3 columns</li>
                </ul>
            </div>
        </div>
    );
}

export function WhereOneColumn(){
    return(
        <div id='whereonecolumn'>
            <br/>
            <div className='autocomplete' style={{width:'300px'}}>
                <ServeurContainer>
                    <div>
                        <SmallInput id='where_one' type='text' placeholder='Enter column 1 name' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='operator_one' type='text' placeholder='operator' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='value_one' type='text' placeholder='value 1' />
                    </div>
                </ServeurContainer>
            </div>
            <br/>
            <ButtonDiv id='confirmerwhereonecolumn'>
                <StyleButton id='confirmwhereonecolumn'>Confirm</StyleButton>
            </ButtonDiv>
            <br/>
        </div>
    );
}

export function WhereTwoColumn(){
    return(
        <div id='wheretwocolumn'>
            <br/>
            <div className='autocomplete' style={{width:'300px'}}>
                <ServeurContainer>
                    <div>
                        <SmallInput id='twowhere_one' type='text' placeholder='Enter column 1 name' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='twooperator_one' type='text' placeholder='operator' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='twovalue_one' type='text' placeholder='value 1' />
                    </div>
                </ServeurContainer>
                <br/>
                <ServeurContainer>
                    <div>
                        <SmallInput id='twowhere_two' type='text' placeholder='Enter column 2 name' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='twooperator_two' type='text' placeholder='operator' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='twovalue_two' type='text' placeholder='value 2' />
                    </div>
                </ServeurContainer>
            </div>
            <br/>
            <ButtonDiv id='confirmerwheretwocolumn'>
                <StyleButton id='confirmwheretwocolumn'>Confirm</StyleButton>
            </ButtonDiv>
            <br/>
        </div>
    );
}

export function WhereThreeColumn(){
    return(
        <div id='wherethreecolumn'>
            <br/>
            <div className='autocomplete' style={{width:'300px'}}>
                <ServeurContainer>
                    <div>
                        <SmallInput id='threewhere_one' type='text' placeholder='Enter column 1 name' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='threeoperator_one' type='text' placeholder='operator' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='threevalue_one' type='text' placeholder='value 1' />
                    </div>
                </ServeurContainer>
                <br/>
                <ServeurContainer>
                    <div>
                        <SmallInput id='threewhere_two' type='text' placeholder='Enter column 2 name' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='threeoperator_two' type='text' placeholder='operator' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='threevalue_two' type='text' placeholder='value 2' />
                    </div>
                </ServeurContainer>
                <br/>
                <ServeurContainer>
                    <div>
                        <SmallInput id='threewhere_three' type='text' placeholder='Enter column 3 name' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='threeoperator_three' type='text' placeholder='operator' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='threevalue_three' type='text' placeholder='value 3' />
                    </div>
                </ServeurContainer>
            </div>
            <br/>
            <ButtonDiv id='confirmerwherethreecolumn'>
                <StyleButton id='confirmwherethreecolumn'>Confirm</StyleButton>
            </ButtonDiv>
            <br/>
        </div>
    );
}

//XXXXXXXXXXXXXXXX START SELECT COUNT FROM TABLE AND WHERE XXXXXXXXXXXXXXX 
export function CountTable(){
    return ( 
        <div id="counttable">
            <div id="result">
                <ul>
                    <li id="count_all">All rows</li>
                    <li id="count_1">Rows where 1 column</li>
                    <li id="count_2">Rows where 2 columns</li>
                    <li id="count_3">Rows where 3 columns</li>
                </ul>
            </div>
        </div>
    );
}

export function CountOneColumn(){
    return(
        <div id='countonecolumn'>
            <br/>
            <div className='autocomplete' style={{width:'300px'}}>
                <ServeurContainer>
                    <div>
                        <SmallInput id='count_one' type='text' placeholder='Enter column 1 name' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='countoperator_one' type='text' placeholder='operator' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='countvalue_one' type='text' placeholder='value 1' />
                    </div>
                </ServeurContainer>
            </div>
            <br/>
            <ButtonDiv id='confirmercountonecolumn'>
                <StyleButton id='confirmcountonecolumn'>Confirm</StyleButton>
            </ButtonDiv>
            <br/>
        </div>
    );
}

export function CountTwoColumn(){
    return(
        <div id='counttwocolumn'>
            <br/>
            <div className='autocomplete' style={{width:'300px'}}>
                <ServeurContainer>
                    <div>
                        <SmallInput id='twocount_one' type='text' placeholder='Enter column 1 name' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='twocountoperator_one' type='text' placeholder='operator' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='twocountvalue_one' type='text' placeholder='value 1' />
                    </div>
                </ServeurContainer>
                <br/>
                <ServeurContainer>
                    <div>
                        <SmallInput id='twocount_two' type='text' placeholder='Enter column 2 name' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='twocountoperator_two' type='text' placeholder='operator' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='twocountvalue_two' type='text' placeholder='value 2' />
                    </div>
                </ServeurContainer>
            </div>
            <br/>
            <ButtonDiv id='confirmercounttwocolumn'>
                <StyleButton id='confirmcounttwocolumn'>Confirm</StyleButton>
            </ButtonDiv>
            <br/>
        </div>
    );
}

export function CountThreeColumn(){
    return(
        <div id='countthreecolumn'>
            <br/>
            <div className='autocomplete' style={{width:'300px'}}>
                <ServeurContainer>
                    <div>
                        <SmallInput id='threecount_one' type='text' placeholder='Enter column 1 name' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='threecountoperator_one' type='text' placeholder='operator' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='threecountvalue_one' type='text' placeholder='value 1' />
                    </div>
                </ServeurContainer>
                <br/>
                <ServeurContainer>
                    <div>
                        <SmallInput id='threecount_two' type='text' placeholder='Enter column 2 name' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='threecountoperator_two' type='text' placeholder='operator' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='threecountvalue_two' type='text' placeholder='value 2' />
                    </div>
                </ServeurContainer>
                <br/>
                <ServeurContainer>
                    <div>
                        <SmallInput id='threecount_three' type='text' placeholder='Enter column 3 name' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='threecountoperator_three' type='text' placeholder='operator' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='threecountvalue_three' type='text' placeholder='value 3' />
                    </div>
                </ServeurContainer>
            </div>
            <br/>
            <ButtonDiv id='confirmercountthreecolumn'>
                <StyleButton id='confirmcountthreecolumn'>Confirm</StyleButton>
            </ButtonDiv>
            <br/>
        </div>
    );
}

//XXXXXXXXXXXXXXXX START SELECT UNION FROM TABLE AND WHERE XXXXXXXXXXXXXXX 
export function UnionTable(){
    return ( 
        <div id="uniontable">
            <div id="result">
                <ul>
                    <li id="union_2">2 documents</li>
                    <li id="union_2_1">2 docs 1 column</li>
                    <li id="union_2_2">2 docs 2 columns</li>
                    <li id="union_2_3">2 docs 3 columns</li>
                    <hr></hr>
                    <li id="union_3">3 documents</li>
                    <li id="union_3_1">3 docs 1 column</li>
                    <li id="union_3_2">3 docs 2 columns</li>
                    <li id="union_3_3">3 docs 3 columns</li>
                </ul>
            </div>
        </div>
    );
}

//2 documents
export function UnionTwoDocument(){
    return(
        <div id='uniontwodocument'>
            <br/>
            <div className='autocomplete' style={{width:'300px'}}>
                <ServeurContainer>
                    <div>
                        <SmallInput id='uniontwodocument_one' type='text' placeholder='First Doc NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='uniontwodocument_two' type='text' placeholder='Second Doc NUMBER' />
                    </div>
                </ServeurContainer>
            </div>
            <br/>
            <ButtonDiv id='confirmeruniontwodocument'>
                <StyleButton id='confirmuniontwodocument'>Confirm</StyleButton>
            </ButtonDiv>
            <br/>
        </div>
    );
}

//2 docs 1 column
export function UnionTwoDocOneColumn(){
    return(
        <div id='uniontwodoconecolumn'>
            <br/>
            <div className='autocomplete' style={{width:'300px'}}>
                <ServeurContainer>
                    <div>
                        <SmallInput id='uniontwodocone_one' type='text' placeholder='First Doc NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='uniontwodocone_two' type='text' placeholder='Second Doc NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='uniontwodocone_onename' type='text' placeholder='Enter column NUMBER' />
                    </div>
                </ServeurContainer>
            </div>
            <br/>
            <ButtonDiv id='confirmeruniontwodoconecolumn'>
                <StyleButton id='confirmuniontwodoconecolumn'>Confirm</StyleButton>
            </ButtonDiv>
            <br/>
        </div>
    );
}

//2 docs 2 columns
export function UnionTwoDocTwoColumn(){
    return(
        <div id='uniontwodoctwocolumn'>
            <br/>
            <div className='autocomplete' style={{width:'300px'}}>
                <ServeurContainer>
                    <div>
                        <SmallInput id='uniontwodoctwo_one' type='text' placeholder='First Doc NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='uniontwodoctwo_two' type='text' placeholder='Second Doc NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='uniontwodoctwo_onename' type='text' placeholder='Enter column 1 NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='uniontwodoctwo_twoname' type='text' placeholder='Enter column 2 NUMBER' />
                    </div>
                </ServeurContainer>
            </div>
            <br/>
            <ButtonDiv id='confirmeruniontwodoctwocolumn'>
                <StyleButton id='confirmuniontwodoctwocolumn'>Confirm</StyleButton>
            </ButtonDiv>
            <br/>
        </div>
    );
}

//2 docs 3 columns
export function UnionTwoDocThreeColumn(){
    return(
        <div id='uniontwodocthreecolumn'>
            <br/>
            <div className='autocomplete' style={{width:'300px'}}>
                <ServeurContainer>
                    <div>
                        <SmallInput id='uniontwodocthree_one' type='text' placeholder='First Doc NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='uniontwodocthree_two' type='text' placeholder='Second Doc NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='uniontwodocthree_onename' type='text' placeholder='Enter column 1 NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='uniontwodocthree_twoname' type='text' placeholder='Enter column 2 NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='uniontwodocthree_threename' type='text' placeholder='Enter column 3 NUMBER' />
                    </div>
                </ServeurContainer>
            </div>
            <br/>
            <ButtonDiv id='confirmeruniontwodocthreecolumn'>
                <StyleButton id='confirmuniontwodocthreecolumn'>Confirm</StyleButton>
            </ButtonDiv>
            <br/>
        </div>
    );
}

//3 documents
export function UnionThreeDocument(){
    return(
        <div id='unionthreedocument'>
            <br/>
            <div className='autocomplete' style={{width:'300px'}}>
                <ServeurContainer>
                    <div>
                        <SmallInput id='unionthreedocument_one' type='text' placeholder='First Doc NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='unionthreedocument_two' type='text' placeholder='Second Doc NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='unionthreedocument_three' type='text' placeholder='Third Doc NUMBER' />
                    </div>
                </ServeurContainer>
            </div>
            <br/>
            <ButtonDiv id='confirmerunionthreedocument'>
                <StyleButton id='confirmunionthreedocument'>Confirm</StyleButton>
            </ButtonDiv>
            <br/>
        </div>
    );
}

//3 docs 1 column
export function UnionThreeDocOneColumn(){
    return(
        <div id='unionthreedoconecolumn'>
            <br/>
            <div className='autocomplete' style={{width:'300px'}}>
                <ServeurContainer>
                    <div>
                        <SmallInput id='unionthreedocone_one' type='text' placeholder='First Doc NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='unionthreedocone_two' type='text' placeholder='Second Doc NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='unionthreedocone_three' type='text' placeholder='Third Doc NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='unionthreedocone_onename' type='text' placeholder='Enter column NUMBER' />
                    </div>
                </ServeurContainer>
            </div>
            <br/>
            <ButtonDiv id='confirmerunionthreedoconecolumn'>
                <StyleButton id='confirmunionthreedoconecolumn'>Confirm</StyleButton>
            </ButtonDiv>
            <br/>
        </div>
    );
}

//3 docs 2 columns
export function UnionThreeDocTwoColumn(){
    return(
        <div id='unionthreedoctwocolumn'>
            <br/>
            <div className='autocomplete' style={{width:'300px'}}>
                <ServeurContainer>
                    <div>
                        <SmallInput id='unionthreedoctwo_one' type='text' placeholder='First Doc NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='unionthreedoctwo_two' type='text' placeholder='Second Doc NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='unionthreedoctwo_three' type='text' placeholder='Third Doc NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='unionthreedoctwo_onename' type='text' placeholder='Enter column 1 NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='unionthreedoctwo_twoname' type='text' placeholder='Enter column 2 NUMBER' />
                    </div>
                </ServeurContainer>
            </div>
            <br/>
            <ButtonDiv id='confirmerunionthreedoctwocolumn'>
                <StyleButton id='confirmunionthreedoctwocolumn'>Confirm</StyleButton>
            </ButtonDiv>
            <br/>
        </div>
    );
}

//3 docs 3 columns
export function UnionThreeDocThreeColumn(){
    return(
        <div id='unionthreedocthreecolumn'>
            <br/>
            <div className='autocomplete' style={{width:'300px'}}>
                <ServeurContainer>
                    <div>
                        <SmallInput id='unionthreedocthree_one' type='text' placeholder='First Doc NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='unionthreedocthree_two' type='text' placeholder='Second Doc NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='unionthreedocthree_three' type='text' placeholder='Third Doc NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='unionthreedocthree_onename' type='text' placeholder='Enter column 1 NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='unionthreedocthree_twoname' type='text' placeholder='Enter column 2 NUMBER' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <SmallInput id='unionthreedocthree_threename' type='text' placeholder='Enter column 3 NUMBER' />
                    </div>
                </ServeurContainer>
            </div>
            <br/>
            <ButtonDiv id='confirmerunionthreedocthreecolumn'>
                <StyleButton id='confirmunionthreedocthreecolumn'>Confirm</StyleButton>
            </ButtonDiv>
            <br/>
        </div>
    );
}

