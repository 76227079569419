import React from 'react';
import './pouchtasks';
import './tasks.css';

import NewTasks from './crudtasks/NewTasks';
import CreateTasks from './crudtasks/CreateTasks';
import DocTasks from './crudtasks/DocTasks';
import PreviousTasks from './crudtasks/PreviousTasks';
import EditDeleteTasks from './crudtasks/EditDeleteTasks';
import ChangeDelEditTasks from './crudtasks/ChangeDelEditTasks';
import ChangeTasks from './crudtasks/ChangeTasks';
import ListTasks from './crudtasks/ListTasks';
import ViewTasks from './crudtasks/ViewTasks';
import ListViewTasks from './crudtasks/ListViewTasks';
import ViewDisplayTasks from './crudtasks/ViewDisplayTasks';
import ViewDocTasks from './crudtasks/ViewDocTasks';
import ChangeViewTasks from './crudtasks/ChangeViewTask';

import { 
    TasksContainer, 
    TextBase,
    ServeurBaseContainer, 
    ServeurButtonBase, 
    AccesButtonBase, 
    FormTable, 
    TextForm, 
    TextLocal, 
    TaskButtons, 
    Buttons, 
    TasksUpper,
    QueryBase, 
    ButtonsBottom,
    TaskAdd, 
    TaskEdit, 
    TaskDelete,
} from './TasksStyles';

function Tasks() {
    return ( 
        <TasksContainer id="taskscontainer">
            <br/>
            <TextBase>
                <label id='taskstitle'>Documents Management</label>
            </TextBase>
            <br/>
            <ServeurBaseContainer>
                <ServeurButtonBase>
                    <AccesButtonBase id='localtasksserver'>Local</AccesButtonBase>
                </ServeurButtonBase>
                <ServeurButtonBase>
                    <AccesButtonBase id='remotetasksserver'>Remote</AccesButtonBase>
                </ServeurButtonBase>
            </ServeurBaseContainer>
            <br/><br/>
            <FormTable id="documenttasks">
                <TextForm>
                    <label id='taskstextform'>Tasks Form</label>
                </TextForm>
                <TextLocal>
                    <label id='texttaskslocal'>Local / Remote Database</label>
                </TextLocal>
                <br/>
                <TaskButtons>
                    <Buttons>
                        <TasksUpper id='newtask'>New</TasksUpper>
                    </Buttons>
                    <Buttons>
                        <TasksUpper id='previoustask'>Previous</TasksUpper>
                    </Buttons>
                    <Buttons>
                        <TasksUpper id='viewtask'>Views</TasksUpper>
                    </Buttons>
                </TaskButtons>
                <br/>
                <QueryBase id="querydocumenttasks">
                    <NewTasks />
                    <CreateTasks />
                    <DocTasks />
                    <PreviousTasks />
                    <EditDeleteTasks />
                    <ChangeDelEditTasks />
                    <ChangeTasks />
                    <ListTasks />
                    <ViewTasks />
                    <ListViewTasks />
                    <ViewDisplayTasks />
                    <ViewDocTasks />
                    <ChangeViewTasks />
                </QueryBase>
                <br/>
                <TaskButtons id="crudtasksactions">
                    <ButtonsBottom>
                        <TaskAdd id='addtasks'>Add</TaskAdd>
                    </ButtonsBottom>
                    <ButtonsBottom>
                        <TaskEdit id='edittasks'>Edit</TaskEdit>
                    </ButtonsBottom>
                    <ButtonsBottom>
                        <TaskDelete id='deletetasks'>Delete</TaskDelete>
                    </ButtonsBottom>
                </TaskButtons>
                <div id="modalouttasks"></div>
                <div id="backdropouttasks"></div>

                <div id="modaloutalerttasks"></div>
                <div id="backdropoutalerttasks"></div>
            </FormTable>
        </TasksContainer>
    );
}

export default Tasks;