import React from 'react';
import '../pouchtables';
import '../tables.css';
import { 
    LargeInput, 
    ServeurContainer, 
    SmallInput, 
    PortInput , 
    FormScroller, 
} from '../../serveur/ServeurStyles';
import { ButtonDiv, StyleButton } from '../TablesStyles';

function PrevDeskTables() {
    return ( 
        <div id="prevdesktables">
            <br/>
            <FormScroller>
                <ServeurContainer>
                    <div>
                        <SmallInput id='hostnametablechange' type='text' placeholder='Hostname' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='hostporttablechange' type='text' placeholder='Port' />
                    </div>
                </ServeurContainer>
                <br/>
                <div>
                    <LargeInput id='usernametablechange' type='text' placeholder='Username' />
                </div>
                <br/>
                <div>
                    <LargeInput id='passwordnametablechange' type='password' placeholder='Password' />
                </div>
                
                <div style={{display:"none"}} id='tabnametochange'>
                    <LargeInput id='tablenametochange' type='text' placeholder='Database Name' />
                </div>
                <br/>
                <div id='tabnamechange'>
                    <LargeInput id='tablenamechange' type='text' placeholder='Database Name' />
                </div>
                
                <div style={{display:"none"}} id='servertablenamechange'>
                    <LargeInput id='servertablenamechange' type='text' placeholder='server Name' />
                </div>
                <br/>
                <ButtonDiv id='confirmertablechange'>
                    <StyleButton id='confirmtablechange'>Confirm</StyleButton>
                </ButtonDiv>
                <br/>
            </FormScroller>
        </div>
    );
}

export default PrevDeskTables;