import React from 'react';
import '../pouchtasks';
import '../tasks.css';
import { 
    LargeInput,  
    FormScroller, 
} from '../../serveur/ServeurStyles';
import { ButtonDiv, StyleButton } from '../TasksStyles';

function ViewMobileTasks() {
    return ( 
        <div id="viewmobiletasks">
            <br/>
            <FormScroller>
                <div>
                    <LargeInput id='mobileusernametaskview' type='text' placeholder='Username' />
                </div>
                <br/>
                <div>
                    <LargeInput id='mobilepasswordnametaskview' type='password' placeholder='Password' />
                </div>
                
                <div style={{display:"none"}} id='mobiletasnametoview'>
                    <LargeInput id='mobiletasknametoview' type='text' placeholder='Database Name' />
                </div>
                
                <div style={{display:"none"}} id='mobiletasnameview'>
                    <LargeInput id='mobiletasknameview' type='text' placeholder='Database Name' />
                </div>
                
                <div style={{display:"none"}} id='mobileservertasknameview'>
                    <LargeInput id='mobileservertasknameview' type='text' placeholder='server Name' />
                </div>
                <br/>
                <ButtonDiv id='mobileconfirmertaskview'>
                    <StyleButton id='mobileconfirmtaskview'>Confirm</StyleButton>
                </ButtonDiv>
                <br/>
            </FormScroller>
        </div>
    );
}

export default ViewMobileTasks;