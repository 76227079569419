import React from 'react';
import '../pouchdatabase';
import '../database.css';

import DeskDatabase from '../compodatabase/DeskDatabase';
import MobileDatabase from '../compodatabase/MobileDatabase';

function NewBase() {
    return ( 
        <div id="newbase">
            <DeskDatabase />
            <MobileDatabase />
        </div>
    );
}

export default NewBase;