window.addEventListener('load', () => {
    //Introduction
    document.getElementById('contintroduction').addEventListener('click', contintroductionclickChange);
    async function contintroductionclickChange(e){
        e.preventDefault();

        document.getElementById('principal').style.display = 'none';
        document.getElementById('serveur').style.display = 'none';
        document.getElementById('basecontaineur').style.display = 'none';
        document.getElementById('tablescontainer').style.display = 'none';
        document.getElementById('taskscontainer').style.display = 'none';
        document.getElementById('queriescontainer').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('tablecontentview').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('introduction').style.display = 'block';
        document.getElementById('whatnew').style.display = 'none';
        document.getElementById('whycouchbench').style.display = 'none';
        document.getElementById('technicaloverview').style.display = 'none';
        document.getElementById('consistency').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('installationguide').style.display = 'none';
        document.getElementById('guideview').style.display = 'none';
        document.getElementById('tutorial').style.display = 'none';
    }
    //sublinks
    document.getElementById('introcontwhatnew').addEventListener('click', introcontwhatnewclickChange);
    async function introcontwhatnewclickChange(e){
        e.preventDefault();

        document.getElementById('principal').style.display = 'none';
        document.getElementById('serveur').style.display = 'none';
        document.getElementById('basecontaineur').style.display = 'none';
        document.getElementById('tablescontainer').style.display = 'none';
        document.getElementById('taskscontainer').style.display = 'none';
        document.getElementById('queriescontainer').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('tablecontentview').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('introduction').style.display = 'none';
        document.getElementById('whatnew').style.display = 'block';
        document.getElementById('whycouchbench').style.display = 'none';
        document.getElementById('technicaloverview').style.display = 'none';
        document.getElementById('consistency').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('installationguide').style.display = 'none';
        document.getElementById('guideview').style.display = 'none';
        document.getElementById('tutorial').style.display = 'none';
    }

    //What's new?
    document.getElementById('contwhatnew').addEventListener('click', contwhatnewclickChange);
    async function contwhatnewclickChange(e){
        e.preventDefault();

        document.getElementById('principal').style.display = 'none';
        document.getElementById('serveur').style.display = 'none';
        document.getElementById('basecontaineur').style.display = 'none';
        document.getElementById('tablescontainer').style.display = 'none';
        document.getElementById('taskscontainer').style.display = 'none';
        document.getElementById('queriescontainer').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('tablecontentview').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('introduction').style.display = 'none';
        document.getElementById('whatnew').style.display = 'block';
        document.getElementById('whycouchbench').style.display = 'none';
        document.getElementById('technicaloverview').style.display = 'none';
        document.getElementById('consistency').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('installationguide').style.display = 'none';
        document.getElementById('guideview').style.display = 'none';
        document.getElementById('tutorial').style.display = 'none';
    }
    //sublinks
    document.getElementById('introcontwhycouchbench').addEventListener('click', introcontwhycouchbenchclickChange);
    async function introcontwhycouchbenchclickChange(e){
        e.preventDefault();

        document.getElementById('principal').style.display = 'none';
        document.getElementById('serveur').style.display = 'none';
        document.getElementById('basecontaineur').style.display = 'none';
        document.getElementById('tablescontainer').style.display = 'none';
        document.getElementById('taskscontainer').style.display = 'none';
        document.getElementById('queriescontainer').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('tablecontentview').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('introduction').style.display = 'none';
        document.getElementById('whatnew').style.display = 'none';
        document.getElementById('whycouchbench').style.display = 'block';
        document.getElementById('technicaloverview').style.display = 'none';
        document.getElementById('consistency').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('installationguide').style.display = 'none';
        document.getElementById('guideview').style.display = 'none';
        document.getElementById('tutorial').style.display = 'none';
    }

    //Why Couchbench?
    document.getElementById('contwhycouchbench').addEventListener('click', contwhycouchbenchclickChange);
    async function contwhycouchbenchclickChange(e){
        e.preventDefault();

        document.getElementById('principal').style.display = 'none';
        document.getElementById('serveur').style.display = 'none';
        document.getElementById('basecontaineur').style.display = 'none';
        document.getElementById('tablescontainer').style.display = 'none';
        document.getElementById('taskscontainer').style.display = 'none';
        document.getElementById('queriescontainer').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('tablecontentview').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('introduction').style.display = 'none';
        document.getElementById('whatnew').style.display = 'none';
        document.getElementById('whycouchbench').style.display = 'block';
        document.getElementById('technicaloverview').style.display = 'none';
        document.getElementById('consistency').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('installationguide').style.display = 'none';
        document.getElementById('guideview').style.display = 'none';
        document.getElementById('tutorial').style.display = 'none';
    }
    //sublinks
    document.getElementById('introconttechnical').addEventListener('click', introconttechnicalclickChange);
    async function introconttechnicalclickChange(e){
        e.preventDefault();

        document.getElementById('principal').style.display = 'none';
        document.getElementById('serveur').style.display = 'none';
        document.getElementById('basecontaineur').style.display = 'none';
        document.getElementById('tablescontainer').style.display = 'none';
        document.getElementById('taskscontainer').style.display = 'none';
        document.getElementById('queriescontainer').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('tablecontentview').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('introduction').style.display = 'none';
        document.getElementById('whatnew').style.display = 'none';
        document.getElementById('whycouchbench').style.display = 'none';
        document.getElementById('technicaloverview').style.display = 'block';
        document.getElementById('consistency').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('installationguide').style.display = 'none';
        document.getElementById('guideview').style.display = 'none';
        document.getElementById('tutorial').style.display = 'none';
    }

    //Technical Overview
    document.getElementById('conttechnical').addEventListener('click', conttechnicalclickChange);
    async function conttechnicalclickChange(e){
        e.preventDefault();

        document.getElementById('principal').style.display = 'none';
        document.getElementById('serveur').style.display = 'none';
        document.getElementById('basecontaineur').style.display = 'none';
        document.getElementById('tablescontainer').style.display = 'none';
        document.getElementById('taskscontainer').style.display = 'none';
        document.getElementById('queriescontainer').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('tablecontentview').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('introduction').style.display = 'none';
        document.getElementById('whatnew').style.display = 'none';
        document.getElementById('whycouchbench').style.display = 'none';
        document.getElementById('technicaloverview').style.display = 'block';
        document.getElementById('consistency').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('installationguide').style.display = 'none';
        document.getElementById('guideview').style.display = 'none';
        document.getElementById('tutorial').style.display = 'none';
    }
    //sublinks
    document.getElementById('introcontconsistency').addEventListener('click', introcontconsistencyclickChange);
    async function introcontconsistencyclickChange(e){
        e.preventDefault();

        document.getElementById('principal').style.display = 'none';
        document.getElementById('serveur').style.display = 'none';
        document.getElementById('basecontaineur').style.display = 'none';
        document.getElementById('tablescontainer').style.display = 'none';
        document.getElementById('taskscontainer').style.display = 'none';
        document.getElementById('queriescontainer').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('tablecontentview').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('introduction').style.display = 'none';
        document.getElementById('whatnew').style.display = 'none';
        document.getElementById('whycouchbench').style.display = 'none';
        document.getElementById('technicaloverview').style.display = 'none';
        document.getElementById('consistency').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('installationguide').style.display = 'none';
        document.getElementById('guideview').style.display = 'none';
        document.getElementById('tutorial').style.display = 'none';
    }

    //Eventual Consistency
    document.getElementById('contconsistency').addEventListener('click', contconsistencyclickChange);
    async function contconsistencyclickChange(e){
        e.preventDefault();

        document.getElementById('principal').style.display = 'none';
        document.getElementById('serveur').style.display = 'none';
        document.getElementById('basecontaineur').style.display = 'none';
        document.getElementById('tablescontainer').style.display = 'none';
        document.getElementById('taskscontainer').style.display = 'none';
        document.getElementById('queriescontainer').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('tablecontentview').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('introduction').style.display = 'none';
        document.getElementById('whatnew').style.display = 'none';
        document.getElementById('whycouchbench').style.display = 'none';
        document.getElementById('technicaloverview').style.display = 'none';
        document.getElementById('consistency').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('installationguide').style.display = 'none';
        document.getElementById('guideview').style.display = 'none';
        document.getElementById('tutorial').style.display = 'none';
    }
    //sublinks
    document.getElementById('introcontinstallation').addEventListener('click', introcontinstallationclickChange);
    async function introcontinstallationclickChange(e){
        e.preventDefault();

        document.getElementById('principal').style.display = 'none';
        document.getElementById('serveur').style.display = 'none';
        document.getElementById('basecontaineur').style.display = 'none';
        document.getElementById('tablescontainer').style.display = 'none';
        document.getElementById('taskscontainer').style.display = 'none';
        document.getElementById('queriescontainer').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('tablecontentview').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('introduction').style.display = 'none';
        document.getElementById('whatnew').style.display = 'none';
        document.getElementById('whycouchbench').style.display = 'none';
        document.getElementById('technicaloverview').style.display = 'none';
        document.getElementById('consistency').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('installationguide').style.display = 'block';
        document.getElementById('guideview').style.display = 'none';
        document.getElementById('tutorial').style.display = 'none';
    }

    //Installation
    document.getElementById('continstallation').addEventListener('click', continstallationclickChange);
    async function continstallationclickChange(e){
        e.preventDefault();

        document.getElementById('principal').style.display = 'none';
        document.getElementById('serveur').style.display = 'none';
        document.getElementById('basecontaineur').style.display = 'none';
        document.getElementById('tablescontainer').style.display = 'none';
        document.getElementById('taskscontainer').style.display = 'none';
        document.getElementById('queriescontainer').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('tablecontentview').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('introduction').style.display = 'none';
        document.getElementById('whatnew').style.display = 'none';
        document.getElementById('whycouchbench').style.display = 'none';
        document.getElementById('technicaloverview').style.display = 'none';
        document.getElementById('consistency').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('installationguide').style.display = 'block';
        document.getElementById('guideview').style.display = 'none';
        document.getElementById('tutorial').style.display = 'none';
    }
    //sublinks
    document.getElementById('introcontguideview').addEventListener('click', introcontguideviewclickChange);
    async function introcontguideviewclickChange(e){
        e.preventDefault();

        document.getElementById('principal').style.display = 'none';
        document.getElementById('serveur').style.display = 'none';
        document.getElementById('basecontaineur').style.display = 'none';
        document.getElementById('tablescontainer').style.display = 'none';
        document.getElementById('taskscontainer').style.display = 'none';
        document.getElementById('queriescontainer').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('tablecontentview').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('introduction').style.display = 'none';
        document.getElementById('whatnew').style.display = 'none';
        document.getElementById('whycouchbench').style.display = 'none';
        document.getElementById('technicaloverview').style.display = 'none';
        document.getElementById('consistency').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('installationguide').style.display = 'none';
        document.getElementById('guideview').style.display = 'block';
        document.getElementById('tutorial').style.display = 'none';
    }

    //Guide View
    document.getElementById('contguideview').addEventListener('click', contguideviewclickChange);
    async function contguideviewclickChange(e){
        e.preventDefault();

        document.getElementById('principal').style.display = 'none';
        document.getElementById('serveur').style.display = 'none';
        document.getElementById('basecontaineur').style.display = 'none';
        document.getElementById('tablescontainer').style.display = 'none';
        document.getElementById('taskscontainer').style.display = 'none';
        document.getElementById('queriescontainer').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('tablecontentview').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('introduction').style.display = 'none';
        document.getElementById('whatnew').style.display = 'none';
        document.getElementById('whycouchbench').style.display = 'none';
        document.getElementById('technicaloverview').style.display = 'none';
        document.getElementById('consistency').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('installationguide').style.display = 'none';
        document.getElementById('guideview').style.display = 'block';
        document.getElementById('tutorial').style.display = 'none';
    }
    //sublinks
    document.getElementById('introconttutorial').addEventListener('click', introconttutorialclickChange);
    async function introconttutorialclickChange(e){
        e.preventDefault();

        document.getElementById('principal').style.display = 'none';
        document.getElementById('serveur').style.display = 'none';
        document.getElementById('basecontaineur').style.display = 'none';
        document.getElementById('tablescontainer').style.display = 'none';
        document.getElementById('taskscontainer').style.display = 'none';
        document.getElementById('queriescontainer').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('tablecontentview').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('introduction').style.display = 'none';
        document.getElementById('whatnew').style.display = 'none';
        document.getElementById('whycouchbench').style.display = 'none';
        document.getElementById('technicaloverview').style.display = 'none';
        document.getElementById('consistency').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('installationguide').style.display = 'none';
        document.getElementById('guideview').style.display = 'none';
        document.getElementById('tutorial').style.display = 'block';
    }

    //Tutorial
    document.getElementById('conttutorial').addEventListener('click', conttutorialclickChange);
    async function conttutorialclickChange(e){
        e.preventDefault();

        document.getElementById('principal').style.display = 'none';
        document.getElementById('serveur').style.display = 'none';
        document.getElementById('basecontaineur').style.display = 'none';
        document.getElementById('tablescontainer').style.display = 'none';
        document.getElementById('taskscontainer').style.display = 'none';
        document.getElementById('queriescontainer').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('tablecontentview').style.display = 'block';
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('introduction').style.display = 'none';
        document.getElementById('whatnew').style.display = 'none';
        document.getElementById('whycouchbench').style.display = 'none';
        document.getElementById('technicaloverview').style.display = 'none';
        document.getElementById('consistency').style.display = 'none';
        //xxxxxxxxxxxxxxxxxxxxxxxxx
        document.getElementById('installationguide').style.display = 'none';
        document.getElementById('guideview').style.display = 'none';
        document.getElementById('tutorial').style.display = 'block';
    }
});