import React from 'react';
import '../pouchtasks';
import '../tasks.css';
import {  
    ServeurContainer, 
    TextLocal ,  
    //FormScroller, 
} from '../../serveur/ServeurStyles';
import { StyleTableButton,  StyleUndoButton, FormScrollerView } from '../TasksStyles';

function ChangeViewTasks() {
    return ( 
        <div id="changeviewtasks">
            <br/>
            <FormScrollerView>
                <TextLocal>
                    <label id='textviewdocumenttask'>View your selected documents</label>
                </TextLocal>
                <br/>
                <div className='resultsList'>
                    <div id='dynamicview'>
                        dynamic table here
                    </div>
                </div>  
                <br/>
                <ServeurContainer>
                    <StyleTableButton id='confirmtasknamechangeview'>Back</StyleTableButton>
                    &nbsp;&nbsp;
                    <StyleUndoButton id='canceltasknamechangeview'>Close</StyleUndoButton>
                </ServeurContainer>
            </FormScrollerView>
        </div>
    );
}

export default ChangeViewTasks;