import React from 'react';
import '../../pouchdocumentation';

import { 
    TextContainer,
    TextTitle, 
    TextContent, 
    SubLink,  
} from '../../DocumentationStyles';

function WhatNew() {
    return ( 
        <TextContainer id="whatnew">
            <TextTitle>2.What's New?</TextTitle>
            <TextContent>
                Couchbench is in its first version and does not differ in any way in how different web 
                applications are developed. However, mainstream Fauxton users will find that this app's UI 
                is 80% similar to fauxton's. Just a way to quickly become familiar with it.
            </TextContent>
            <TextContent>
                However, an update will be available for each new version improving the previous one.
            </TextContent>
            <SubLink id='introcontwhycouchbench'>3.Why Couchbench?</SubLink>
            <br/><br/>
        </TextContainer>
    );
}

export default WhatNew;