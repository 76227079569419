import React from 'react';
import '../pouchdatabase';
import '../database.css';

import PrevDeskBase from '../compodatabase/PrevDeskBase';
import PrevMobileBase from '../compodatabase/PrevMobileBase';

function PreviousBase() {
    return ( 
        <div id="previousbase">
            <>
                <PrevDeskBase />
                <PrevMobileBase />
            </> 
        </div>
    );
}

export default PreviousBase;