import React from 'react';
import '../../pouchdocumentation';

import { 
    TextContainer,
    TextTitle, 
    TextContent, 
    SubLink,  
} from '../../DocumentationStyles';

function GuideView() {
    return ( 
        <TextContainer id="guideview">
            <TextTitle>7. Guide to Views</TextTitle>
            <TextContent>
                Query documents using Couchbench will be explained in detail in Tutorial section where 
                users will get the step-by-step guides to run and query documents. However, 
                for those wishing to know more about: <a href='https://docs.couchdb.org/en/3.2.0/ddocs/views/intro.html' style={{textDecoration:'none',color:'blue'}}>how query document in CouchDB</a>.  
            </TextContent>
            <SubLink id='introconttutorial'>8.Tutorial</SubLink>
            <br/><br/>
        </TextContainer>
    );
}

export default GuideView;