import React from 'react';
import '../pouchdatabase';
import '../database.css';

import {  
    LargeInput,  
} from '../../serveur/ServeurStyles';

function MobileDatabase() {
    return ( 
        <div id="mobiledatabase">
            <br/>
            <div>
                <LargeInput id='mobileusernamebase' type='text' placeholder='Username' />
            </div>
            <br/>
            <div>
                <LargeInput id='mobilepasswordnamebase' type='password' placeholder='Password' />
            </div>
            <br/>
            <div style={{display:"none"}}>
                <LargeInput id='mobiledatabasenamebase' type='text' placeholder='Database Name' />
            </div>
            <br/>
        </div>
    );
}

export default MobileDatabase;