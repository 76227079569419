import React from 'react';
import '../pouchqueries';
import '../queries.css';
import { 
    TextLocal, 
    LargeInput, 
    ServeurContainer, 
    SmallInput, 
    PortInput , 
    FormScroller, 
} from '../../serveur/ServeurStyles';
import { ButtonDiv, StyleButton } from '../QueriesStyles';

function DeskConQueries() {
    return ( 
        <div id="deskconqueries">
            <br/>
            <FormScroller>
                <TextLocal>
                    <label id='connectquerytextform'>Database Connection </label>
                </TextLocal>
                <br/>
                <ServeurContainer>
                    <div>
                        <SmallInput id='hostnamequery' type='text' placeholder='Hostname' />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='hostportquery' type='text' placeholder='Port' />
                    </div>
                </ServeurContainer>
                <br/>
                <div>
                    <LargeInput id='usernamequery' type='text' placeholder='Username' />
                </div>
                <br/>
                <div>
                    <LargeInput id='passwordnamequery' type='password' placeholder='Password' />
                </div>
                <br/>
                <div id='quername'>
                    <LargeInput id='queryname' type='text' placeholder='Database Name' />
                </div>
                <br/>
                <ButtonDiv id='confirmerquery'>
                    <StyleButton id='confirmquery'>Connect</StyleButton>
                </ButtonDiv>
                <br/>
            </FormScroller>
        </div>
    );
}

export default DeskConQueries;