/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';

window.addEventListener('load', () => {
    PouchDB.plugin(PouchFind);
    //local database test
    document.getElementById('localtableserver').addEventListener('click', localtableserverChange);
    async function localtableserverChange(e){
        e.preventDefault();

        var deviceStr = document.getElementById('device').innerText;

        var divClearDevice = document.getElementById('device');
        while(divClearDevice.hasChildNodes()) {
            divClearDevice.removeChild(divClearDevice.childNodes[0]);
        }

        if(deviceStr === ''){
            var deviceStr = document.getElementById('device');
            deviceStr.innerText = '';
            var tabletypeForm = document.getElementById('tabletextform');
            tabletypeForm.innerText = "Desktop Tables Form";

            var texttableLocal = document.getElementById('texttablelocal');
            texttableLocal.innerText = "Desktop Local Tables"; 
        }

        if(deviceStr === 'mobile'){
            var deviceStr = document.getElementById('device');
            deviceStr.innerText = 'mobile';
            var tabletypeForm = document.getElementById('tabletextform');
            tabletypeForm.innerText = "Mobile Tables Form";

            var texttableLocal = document.getElementById('texttablelocal');
            texttableLocal.innerText = "Mobile Local Tables"; 
        }

        document.getElementById('tabletasks').style.display = 'block';
        document.getElementById('querytablestasks').style.display = 'none';
        document.getElementById('crudtablesactions').style.display = 'none';

        var serveurHeader = document.getElementById('serveurheader');
        serveurHeader.innerText = "local";
        serveurHeader.style.color = 'red';
    }

    //local database test
    document.getElementById('remotetableserver').addEventListener('click', remotetableserverChange);
    async function remotetableserverChange(e){
        e.preventDefault();

        var deviceStr = document.getElementById('device').innerText;

        document.getElementById('tabletasks').style.display = 'block';
        document.getElementById('querytablestasks').style.display = 'none';
        document.getElementById('crudtablesactions').style.display = 'none';

        if(deviceStr === ''){
            var deviceStr = document.getElementById('device');
            deviceStr.innerText = '';
            var tabletypeForm = document.getElementById('tabletextform');
            tabletypeForm.innerText = "Desktop Tables Form";

            var texttableLocal = document.getElementById('texttablelocal');
            texttableLocal.innerText = "Desktop Remote Tables"; 
        }

        if(deviceStr === 'mobile'){
            var deviceStr = document.getElementById('device');
            deviceStr.innerText = 'mobile';
            var tabletypeForm = document.getElementById('tabletextform');
            tabletypeForm.innerText = "Mobile Tables Form";

            var texttableLocal = document.getElementById('texttablelocal');
            texttableLocal.innerText = "Mobile Remote Tables"; 
        }

        var serveurHeader = document.getElementById('serveurheader');
        serveurHeader.innerText = "remote";
        serveurHeader.style.color = 'red';
    }

    //new table test
    document.getElementById('newtable').addEventListener('click', newtableChange);
    async function newtableChange(e){
        e.preventDefault();

        var deviceStr = document.getElementById('device').innerText;
        var serveurHeaderStr = document.getElementById('serveurheader').innerText;

        document.getElementById('tabletasks').style.display = 'block';
        document.getElementById('querytablestasks').style.display = 'block';
        document.getElementById('crudtablesactions').style.display = 'block';
        document.getElementById('addtables').style.display = 'none';
        document.getElementById('edittables').style.display = 'none';
        document.getElementById('deletetables').style.display = 'none';
        //xxxxxxxxx crudbase xxxxxxxxx
        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            //xxxxxxxxx crudbase xxxxxxxxx
            document.getElementById('newtables').style.display = 'block';
            document.getElementById('desktables').style.display = 'block';
            document.getElementById('mobiletables').style.display = 'none';
            document.getElementById('createtables').style.display = 'none';
            document.getElementById('listtables').style.display = 'none';
            document.getElementById('previoustables').style.display = 'none';
            document.getElementById('changetables').style.display = 'none';
            //xxxxxxx input field
            document.getElementById('tabname').style.display = 'block';
            document.getElementById('confirmertable').style.display = 'block';
        };

        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            //xxxxxxxxx crudbase xxxxxxxxx
            document.getElementById('newtables').style.display = 'block';
            document.getElementById('desktables').style.display = 'none';
            document.getElementById('mobiletables').style.display = 'block';
            document.getElementById('createtables').style.display = 'none';
            document.getElementById('listtables').style.display = 'none';
            document.getElementById('previoustables').style.display = 'none';
            document.getElementById('changetables').style.display = 'none';
            //xxxxxxx input field
            document.getElementById('tabname').style.display = 'block';
            document.getElementById('mobileconfirmertable').style.display = 'block';
            //xxxxxx
            document.getElementById('mobiletablename').value = 'local';
        };
    }

    //previous table test
    //set global variable
    var typecrud = '';
    document.getElementById('previoustable').addEventListener('click', previoustableChange);
    async function previoustableChange(e){
        e.preventDefault();

        var divClearModal = document.getElementById('modalouttables');
        let divModal = document.createElement('div');
        divModal.innerHTML =
        `<div class="modal">
            <p>Select your task</p>
            <button class="btn btn--alt" id="ouiHandlerEditTableBtn">Edit</button>
            <button class="btn" id="nonHandlerDeleteTableBtn">Delete</button>
        </div>`;
        divClearModal.appendChild(divModal);

        var divClearBackdrop = document.getElementById('backdropouttables');
        let divBack = document.createElement('div');
        divBack.innerHTML =
        `<div class="backdrop" id="backdropHandlerCloseTableBtn"></div>`;
        divClearBackdrop.appendChild(divBack);

        //If Edit Clicked
        document.getElementById('ouiHandlerEditTableBtn').addEventListener('click', ouiHandlerEditTableBtnChange);
        async function ouiHandlerEditTableBtnChange(e){
            e.preventDefault();

            var deviceStr = document.getElementById('device').innerText;
            var serveurHeaderStr = document.getElementById('serveurheader').innerText;

            typecrud = 'edit';

            document.getElementById('tabletasks').style.display = 'block';
            document.getElementById('querytablestasks').style.display = 'block';
            document.getElementById('crudtablesactions').style.display = 'block';
            document.getElementById('addtables').style.display = 'none';
            document.getElementById('edittables').style.display = 'none';
            document.getElementById('deletetables').style.display = 'none';

            if((deviceStr === '' && serveurHeaderStr === 'local') || 
            (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
                //xxxxxxxxx crudbase xxxxxxxxx
                document.getElementById('newtables').style.display = 'none';
                document.getElementById('createtables').style.display = 'none';
                document.getElementById('listtables').style.display = 'none';
                document.getElementById('previoustables').style.display = 'block';
                document.getElementById('prevdesktables').style.display = 'block';
                document.getElementById('prevmobiletables').style.display = 'none';
                document.getElementById('changetables').style.display = 'none';
                //xxxxxxx input field
                document.getElementById('tabname').style.display = 'block';
                document.getElementById('confirmertable').style.display = 'block';
            };

            if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
                //xxxxxxxxx crudbase xxxxxxxxx
                document.getElementById('newtables').style.display = 'none';
                document.getElementById('createtables').style.display = 'none';
                document.getElementById('listtables').style.display = 'none';
                document.getElementById('previoustables').style.display = 'block';
                document.getElementById('prevdesktables').style.display = 'none';
                document.getElementById('prevmobiletables').style.display = 'block';
                document.getElementById('changetables').style.display = 'none';
                //xxxxxxx input field
                document.getElementById('mobiletabname').style.display = 'block';
                document.getElementById('mobileconfirmertable').style.display = 'block';
            };

            var divClearModal = document.getElementById('modalouttables');
            while(divClearModal.hasChildNodes()) {
                divClearModal.removeChild(divClearModal.childNodes[0]);
            }

            var divClearBackdrop = document.getElementById('backdropouttables');
            while(divClearBackdrop.hasChildNodes()) {
                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
            }
        }

        //If Delete Clicked
        document.getElementById('nonHandlerDeleteTableBtn').addEventListener('click', nonHandlerDeleteTableBtnChange);
        async function nonHandlerDeleteTableBtnChange(e){
            e.preventDefault();

            var deviceStr = document.getElementById('device').innerText;
            var serveurHeaderStr = document.getElementById('serveurheader').innerText;

            typecrud = 'delete';

            document.getElementById('tabletasks').style.display = 'block';
            document.getElementById('querytablestasks').style.display = 'block';
            document.getElementById('crudtablesactions').style.display = 'block';
            document.getElementById('addtables').style.display = 'none';
            document.getElementById('edittables').style.display = 'none';
            document.getElementById('deletetables').style.display = 'none';
            if((deviceStr === '' && serveurHeaderStr === 'local') || 
            (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
                //xxxxxxxxx crudbase xxxxxxxxx
                document.getElementById('newtables').style.display = 'none';
                document.getElementById('createtables').style.display = 'none';
                document.getElementById('listtables').style.display = 'none';
                document.getElementById('previoustables').style.display = 'block';
                document.getElementById('prevdesktables').style.display = 'block';
                document.getElementById('prevmobiletables').style.display = 'none';
                document.getElementById('changetables').style.display = 'none';
                //xxxxxxx input field
                document.getElementById('tabname').style.display = 'block';
                document.getElementById('confirmertable').style.display = 'block';
            };

            if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
                //xxxxxxxxx crudbase xxxxxxxxx
                document.getElementById('newtables').style.display = 'none';
                document.getElementById('createtables').style.display = 'none';
                document.getElementById('listtables').style.display = 'none';
                document.getElementById('previoustables').style.display = 'block';
                document.getElementById('prevdesktables').style.display = 'none';
                document.getElementById('prevmobiletables').style.display = 'block';
                document.getElementById('changetables').style.display = 'none';
                //xxxxxxx input field
                document.getElementById('mobiletabname').style.display = 'block';
                document.getElementById('mobileconfirmertable').style.display = 'block';
            };

            var divClearModal = document.getElementById('modalouttables');
            while(divClearModal.hasChildNodes()) {
                divClearModal.removeChild(divClearModal.childNodes[0]);
            }

            var divClearBackdrop = document.getElementById('backdropouttables');
            while(divClearBackdrop.hasChildNodes()) {
                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
            }
        }

        //If backdrop Clicked
        document.getElementById('backdropHandlerCloseTableBtn').addEventListener('click', backdropHandlerCloseTableBtnChange);
        async function backdropHandlerCloseTableBtnChange(e){
            e.preventDefault();

            var divClearModal = document.getElementById('modalouttables');
            while(divClearModal.hasChildNodes()) {
                divClearModal.removeChild(divClearModal.childNodes[0]);
            }

            var divClearBackdrop = document.getElementById('backdropouttables');
            while(divClearBackdrop.hasChildNodes()) {
                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
            }
        }
    }

    //views table test
    document.getElementById('viewtable').addEventListener('click', viewtableChange);
    async function viewtableChange(e){
        e.preventDefault();

        var deviceStr = document.getElementById('device').innerText;
        var serveurHeaderStr = document.getElementById('serveurheader').innerText;

        typecrud = 'view';

        document.getElementById('tabletasks').style.display = 'block';
        document.getElementById('querytablestasks').style.display = 'block';
        document.getElementById('crudtablesactions').style.display = 'block';
        document.getElementById('addtables').style.display = 'none';
        document.getElementById('edittables').style.display = 'none';
        document.getElementById('deletetables').style.display = 'none';
        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            //xxxxxxxxx crudbase xxxxxxxxx
            document.getElementById('newtables').style.display = 'none';
            document.getElementById('createtables').style.display = 'none';
            document.getElementById('listtables').style.display = 'none';
            document.getElementById('previoustables').style.display = 'block';
            document.getElementById('prevdesktables').style.display = 'block';
            document.getElementById('prevmobiletables').style.display = 'none';
            document.getElementById('changetables').style.display = 'none';
            //xxxxxxx input field
            document.getElementById('tabname').style.display = 'block';
            document.getElementById('confirmertable').style.display = 'block';
        };

        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            //xxxxxxxxx crudbase xxxxxxxxx
            document.getElementById('newtables').style.display = 'none';
            document.getElementById('createtables').style.display = 'none';
            document.getElementById('listtables').style.display = 'none';
            document.getElementById('previoustables').style.display = 'block';
            document.getElementById('prevdesktables').style.display = 'none';
            document.getElementById('prevmobiletables').style.display = 'block';
            document.getElementById('changetables').style.display = 'none';
            //xxxxxxx input field
            document.getElementById('tabname').style.display = 'block';
            document.getElementById('confirmertable').style.display = 'block';
        };
    }

    //Crud tasks (confirmertable)
    document.getElementById('confirmertable').addEventListener('click', confirmertableChange);
    async function confirmertableChange(e){
        e.preventDefault();

        //alert
        var elementOutId = 'modaloutalerttables';
        var elementDropId = 'backdropoutalerttables';
        var buttonHandler = 'ouiHandlerAlertTableBtn';

        var hostnametableStr = document.getElementById('hostnametable').value;
        var hostporttableStr = document.getElementById('hostporttable').value;
        var usernametableStr = document.getElementById('usernametable').value;
        var passwordnametableStr = document.getElementById('passwordnametable').value;
        var tablenameStr = document.getElementById('tablename').value.toLowerCase();
        var serveurHeaderStr = document.getElementById('serveurheader').innerText;
        var deviceStr = document.getElementById('device').innerText;
        var localnamebaseStr = '';
        var servernameStr = 'database';
        if(serveurHeaderStr === 'local'){
            localnamebaseStr = 'local';
        }else if(serveurHeaderStr === 'remote'){
            localnamebaseStr = 'remote';
        }
        var localtypebaseStr = 'database';

        //check if values not null
        if(!hostnametableStr){
            var pTextehostname = "Enter Host Name";
            alertModal(elementOutId,elementDropId,pTextehostname,buttonHandler);
        }else if(!hostporttableStr){
            var pTexteportname = "Enter Port Number";
            alertModal(elementOutId,elementDropId,pTexteportname,buttonHandler);
        }else if(!usernametableStr){
            var pTexteusername = "Enter Username";
            alertModal(elementOutId,elementDropId,pTexteusername,buttonHandler);
        }else if(!passwordnametableStr){
            var pTextepasswordname = "Enter Password";
            alertModal(elementOutId,elementDropId,pTextepasswordname,buttonHandler);
        }else if(!tablenameStr){
            var pTextedatabasename = "Enter Database Name";
            alertModal(elementOutId,elementDropId,pTextedatabasename,buttonHandler);
        }else{
            var docsCheckConfirm = [];
            try {
                //test if hostname and portnumber are true
                if((deviceStr === '' && localnamebaseStr === 'local') || 
                (deviceStr === '' && localnamebaseStr === 'remote') || (deviceStr === 'mobile' && localnamebaseStr === 'remote')){
                    var url = "http://"+hostnametableStr+":"+hostporttableStr+"/_utils/";
                    var http_request = new XMLHttpRequest();
                    http_request.open("GET", url ,true);
                    //http_request.send("null");
                    http_request.onreadystatechange = async function(){
                        if(http_request.readyState === 4){
                            if(http_request.status === 200){
                                //search in local / remote database documents
                                const db = new PouchDB(localnamebaseStr, {skip_setup:true});
                                const remote = new PouchDB('http://'+usernametableStr+':'+passwordnametableStr+'@'+hostnametableStr+':'+hostporttableStr+'/'+localnamebaseStr, {skip_setup:true});
                                PouchDB.plugin(PouchFind);
                                // Initialise a sync with the remote server
                                function sync(){
                                    var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                    db.sync(remote, opts);
                                }
                                if(remote){
                                    sync();
                                }
                                let result = await db.createIndex({
                                    index: {
                                        fields: [
                                            'connectname',
                                            'servername'
                                        ]
                                    }
                                });
                                result = await db.find({
                                    selector: {
                                        connectname: tablenameStr,
                                        servername: servernameStr
                                    }
                                });
                                docsCheckConfirm = result.docs;
                                var resultatCheckConfirm = docsCheckConfirm.length;
                                if(resultatCheckConfirm === 0){
                                    var divClearModal = document.getElementById('modalouttables');
                                    let divModal = document.createElement('div');
                                    divModal.innerHTML =
                                    `<div class="modal">
                                        <p>Sure this database exists?</p>
                                        <button class="btn btn--alt" id="ouiHandlerExistBtn">Yes</button>
                                        <button class="btn" id="nonHandlerExistsBtn">No</button>
                                    </div>`;
                                    divClearModal.appendChild(divModal);

                                    var divClearBackdrop = document.getElementById('backdropouttables');
                                    let divBack = document.createElement('div');
                                    divBack.innerHTML =
                                    `<div class="backdrop" id="backdropHandlerCloseExistsBtn"></div>`;
                                    divClearBackdrop.appendChild(divBack);

                                    //If Yes Clicked
                                    document.getElementById('ouiHandlerExistBtn').addEventListener('click', ouiHandlerExistBtnChange);
                                    async function ouiHandlerExistBtnChange(e){
                                        e.preventDefault();

                                        //code here
                                        var divClearModal = document.getElementById('modalouttables');
                                        let divModal = document.createElement('div');
                                        divModal.innerHTML =
                                        `<div class="modal">
                                            <p>Wish to Add it?</p>
                                            <button class="btn btn--alt" id="ouiHandlerAddBtn">Yes</button>
                                            <button class="btn" id="nonHandlerAddBtn">No</button>
                                        </div>`;
                                        divClearModal.appendChild(divModal);

                                        var divClearBackdrop = document.getElementById('backdropouttables');
                                        let divBack = document.createElement('div');
                                        divBack.innerHTML =
                                        `<div class="backdrop" id="backdropHandlerCloseAddBtn"></div>`;
                                        divClearBackdrop.appendChild(divBack);

                                        //If Yes Add Clicked
                                        document.getElementById('ouiHandlerAddBtn').addEventListener('click', ouiHandlerAddBtnChange);
                                        async function ouiHandlerAddBtnChange(e){
                                            e.preventDefault();

                                            //code here
                                            const db = new PouchDB(localnamebaseStr, {skip_setup:true});
                                            const remote = new PouchDB('http://'+usernametableStr+':'+passwordnametableStr+'@'+hostnametableStr+':'+hostporttableStr+'/'+localnamebaseStr, {skip_setup:true});
                                            PouchDB.plugin(PouchFind);
                                            //test if hostname and portnumber are true
                                            var http_request = new XMLHttpRequest();
                                            http_request.open("GET", "http://"+hostnametableStr+":"+hostporttableStr+"/_utils/" ,true);
                                            http_request.send("null");
                                            http_request.onreadystatechange = async function(){
                                                if(http_request.readyState === 4){
                                                    if(http_request.status === 200){
                                                        function sync(){
                                                            var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                                            db.sync(remote, opts);
                                                        }
                                                        if(remote){
                                                            sync();
                                                        }
                                                        //end sync
                                                        var docsCheck = [];
                                                        try {
                                                            let result = await db.createIndex({
                                                                index: {
                                                                    fields: [
                                                                        'hostname', 
                                                                        'portname',
                                                                        'username',
                                                                        'passwordname'
                                                                    ]
                                                                }
                                                            });
                                                            result = await db.find({
                                                                selector: {
                                                                    hostname: hostnametableStr,
                                                                    portname: hostporttableStr,
                                                                    username: usernametableStr,
                                                                    passwordname: passwordnametableStr
                                                                }
                                                            });
                                                            docsCheck = result.docs;
                                                            var resultatCheck = docsCheck.length;
                                                            if(resultatCheck === ''){
                                                                var pTexteincorrectconnection = "Name or password is incorrect.";
                                                                alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
                                                            }else{
                                                                var docsCheckDatabase = [];
                                                                let resultDatabase = await db.createIndex({
                                                                    index: {
                                                                        fields: [
                                                                            'connectname'
                                                                        ]
                                                                    }
                                                                });
                                                                resultDatabase = await db.find({
                                                                    selector: {
                                                                        connectname: tablenameStr
                                                                    }
                                                                });
                                                                docsCheckDatabase = resultDatabase.docs;
                                                                var resultatCheckDatabase = docsCheckDatabase.length;
                                                                if(resultatCheckDatabase === 0){
                                                                    const dbNew = new PouchDB(tablenameStr);
                                                                    const remoteNew = couchProvider(hostnametableStr, hostporttableStr, usernametableStr, passwordnametableStr, tablenameStr);
                                                                    //add new database created to local connection server
                                                                    //Create the document _id and _rev.
                                                                    var now = Date.now();
                                                                    //new database id
                                                                    var id = tablenameStr.toLowerCase()+'_' + now;
                                                                    // Add the document to the database.
                                                                    var response = await db.put({
                                                                        _id: id,
                                                                        connectname: tablenameStr, //the new database name
                                                                        hostname: hostnametableStr,
                                                                        portname: hostporttableStr,
                                                                        username: usernametableStr,
                                                                        passwordname: passwordnametableStr,
                                                                        servername: localtypebaseStr,
                                                                    });
                                                                    // check if document inserted
                                                                    var doc = await db.get(response.id);
                                                                    if(doc !== ''){
                                                                        //Initialise a sync with the remote server
                                                                        function sync(){
                                                                            var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                                                            dbNew.sync(remoteNew, opts);
                                                                        }
                                                                        if(remoteNew){
                                                                            sync();
                                                                        }
                                                                        var pTextenewdatabase = "New Database added Succesfully";
                                                                        alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
                                                                    }else{
                                                                        var pTexteerreuraddnew = "An Error has Occurred";
                                                                        alertModal(elementOutId,elementDropId,pTexteerreuraddnew,buttonHandler);
                                                                    }
                                                                }else{
                                                                    var pTexteexistaddnew = "Database "+tablenameStr+" already exists";
                                                                    alertModal(elementOutId,elementDropId,pTexteexistaddnew,buttonHandler);
                                                                }
                                                            }
                                                        } catch (error) {
                                                            var pTexteerreurconnection = "An Error has Occurred";
                                                            alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                                                        }
                                                    }else{
                                                        var pTextefailedconnect = "Connection Failed.";
                                                        alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                                                    }
                                                }
                                            }//

                                            var divClearModal = document.getElementById('modalouttables');
                                            while(divClearModal.hasChildNodes()) {
                                                divClearModal.removeChild(divClearModal.childNodes[0]);
                                            }

                                            var divClearBackdrop = document.getElementById('backdropouttables');
                                            while(divClearBackdrop.hasChildNodes()) {
                                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                            }
                                        }

                                        //If No Add Clicked
                                        document.getElementById('nonHandlerAddBtn').addEventListener('click', nonHandlerAddBtnChange);
                                        async function nonHandlerAddBtnChange(e){
                                            e.preventDefault();

                                            //code here

                                            var divClearModal = document.getElementById('modalouttables');
                                            while(divClearModal.hasChildNodes()) {
                                                divClearModal.removeChild(divClearModal.childNodes[0]);
                                            }

                                            var divClearBackdrop = document.getElementById('backdropouttables');
                                            while(divClearBackdrop.hasChildNodes()) {
                                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                            }
                                        }

                                        //If backdrop Clicked
                                        document.getElementById('backdropHandlerCloseAddBtn').addEventListener('click', backdropHandlerCloseAddBtnChange);
                                        async function backdropHandlerCloseAddBtnChange(e){
                                            e.preventDefault();

                                            //code here

                                            var divClearModal = document.getElementById('modalouttables');
                                            while(divClearModal.hasChildNodes()) {
                                                divClearModal.removeChild(divClearModal.childNodes[0]);
                                            }

                                            var divClearBackdrop = document.getElementById('backdropouttables');
                                            while(divClearBackdrop.hasChildNodes()) {
                                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                            }
                                        } 
                                    }

                                    //If No Clicked
                                    document.getElementById('nonHandlerExistsBtn').addEventListener('click', nonHandlerExistsBtnChange);
                                    async function nonHandlerExistsBtnChange(e){
                                        e.preventDefault();

                                        //code here
                                        var divClearModal = document.getElementById('modalouttables');
                                        let divModal = document.createElement('div');
                                        divModal.innerHTML =
                                        `<div class="modal">
                                            <p>Wish to Use it?</p>
                                            <button class="btn btn--alt" id="ouiHandlerUseBtn">Yes</button>
                                            <button class="btn" id="nonHandlerUseBtn">No</button>
                                        </div>`;
                                        divClearModal.appendChild(divModal);

                                        var divClearBackdrop = document.getElementById('backdropouttables');
                                        let divBack = document.createElement('div');
                                        divBack.innerHTML =
                                        `<div class="backdrop" id="backdropHandlerCloseUseBtn"></div>`;
                                        divClearBackdrop.appendChild(divBack);

                                        //If Yes Use Clicked
                                        document.getElementById('ouiHandlerUseBtn').addEventListener('click', ouiHandlerUseBtnChange);
                                        async function ouiHandlerUseBtnChange(e){
                                            e.preventDefault();

                                            //code here
                                            const db = new PouchDB(localnamebaseStr, {skip_setup:true});
                                            const remote = new PouchDB('http://'+usernametableStr+':'+passwordnametableStr+'@'+hostnametableStr+':'+hostporttableStr+'/'+localnamebaseStr, {skip_setup:true});
                                            PouchDB.plugin(PouchFind);
                                            //test if hostname and portnumber are true
                                            var http_request = new XMLHttpRequest();
                                            http_request.open("GET", "http://"+hostnametableStr+":"+hostporttableStr+"/_utils/" ,true);
                                            http_request.send("null");
                                            http_request.onreadystatechange = async function(){
                                                if(http_request.readyState === 4){
                                                    if(http_request.status === 200){
                                                        function sync(){
                                                            var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                                            db.sync(remote, opts);
                                                        }
                                                        if(remote){
                                                            sync();
                                                        }
                                                        //end sync
                                                        var docsCheck = [];
                                                        try {
                                                            let result = await db.createIndex({
                                                                index: {
                                                                    fields: [
                                                                        'hostname', 
                                                                        'portname',
                                                                        'username',
                                                                        'passwordname'
                                                                    ]
                                                                }
                                                            });
                                                            result = await db.find({
                                                                selector: {
                                                                    hostname: hostnametableStr,
                                                                    portname: hostporttableStr,
                                                                    username: usernametableStr,
                                                                    passwordname: passwordnametableStr
                                                                }
                                                            });
                                                            docsCheck = result.docs;
                                                            var resultatCheck = docsCheck.length;
                                                            if(resultatCheck === ''){
                                                                var pTexteincorrectconnection = "Name or password is incorrect.";
                                                                alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
                                                            }else{
                                                                var docsCheckDatabase = [];
                                                                let resultDatabase = await db.createIndex({
                                                                    index: {
                                                                        fields: [
                                                                            'connectname'
                                                                        ]
                                                                    }
                                                                });
                                                                resultDatabase = await db.find({
                                                                    selector: {
                                                                        connectname: tablenameStr
                                                                    }
                                                                });
                                                                docsCheckDatabase = resultDatabase.docs;
                                                                var resultatCheckDatabase = docsCheckDatabase.length;
                                                                if(resultatCheckDatabase === 0){
                                                                    const dbNew = new PouchDB(tablenameStr);
                                                                    const remoteNew = couchProvider(hostnametableStr, hostporttableStr, usernametableStr, passwordnametableStr, tablenameStr);
                                                                    //add new database created to local connection server
                                                                    //Create the document _id and _rev.
                                                                    var now = Date.now();
                                                                    //new database id
                                                                    var id = tablenameStr.toLowerCase()+'_' + now;
                                                                    // Add the document to the database.
                                                                    var response = await db.put({
                                                                        _id: id,
                                                                        connectname: tablenameStr, //the new database name
                                                                        hostname: hostnametableStr,
                                                                        portname: hostporttableStr,
                                                                        username: usernametableStr,
                                                                        passwordname: passwordnametableStr,
                                                                        servername: localtypebaseStr,
                                                                    });
                                                                    // check if document inserted
                                                                    var doc = await db.get(response.id);
                                                                    if(doc !== ''){
                                                                        //Initialise a sync with the remote server
                                                                        function sync(){
                                                                            var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                                                            dbNew.sync(remoteNew, opts);
                                                                        }
                                                                        if(remoteNew){
                                                                            sync();
                                                                        }
                                                                        var pTextenewdatabase = "New Database added Succesfully";
                                                                        alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
                                                                    }else{
                                                                        var pTexteerreuraddnew = "An Error has Occurred";
                                                                        alertModal(elementOutId,elementDropId,pTexteerreuraddnew,buttonHandler);
                                                                    }
                                                                }else{
                                                                    var pTexteexistaddnew = "Database "+tablenameStr+" already exists";
                                                                    alertModal(elementOutId,elementDropId,pTexteexistaddnew,buttonHandler);
                                                                }
                                                            }
                                                        } catch (error) {
                                                            var pTexteerreurconnection = "An Error has Occurred";
                                                            alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                                                        }
                                                    }else{
                                                        var pTextefailedconnect = "Connection Failed.";
                                                        alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                                                    }
                                                }
                                            }//

                                            var divClearModal = document.getElementById('modalouttables');
                                            while(divClearModal.hasChildNodes()) {
                                                divClearModal.removeChild(divClearModal.childNodes[0]);
                                            }

                                            var divClearBackdrop = document.getElementById('backdropouttables');
                                            while(divClearBackdrop.hasChildNodes()) {
                                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                            }
                                        }

                                        //If No Use Clicked
                                        document.getElementById('nonHandlerUseBtn').addEventListener('click', nonHandlerUseBtnChange);
                                        async function nonHandlerUseBtnChange(e){
                                            e.preventDefault();

                                            //code here

                                            var divClearModal = document.getElementById('modalouttables');
                                            while(divClearModal.hasChildNodes()) {
                                                divClearModal.removeChild(divClearModal.childNodes[0]);
                                            }

                                            var divClearBackdrop = document.getElementById('backdropouttables');
                                            while(divClearBackdrop.hasChildNodes()) {
                                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                            }
                                        }

                                        //If backdrop Clicked
                                        document.getElementById('backdropHandlerCloseUseBtn').addEventListener('click', backdropHandlerCloseUseBtnChange);
                                        async function backdropHandlerCloseUseBtnChange(e){
                                            e.preventDefault();

                                            //code here

                                            var divClearModal = document.getElementById('modalouttables');
                                            while(divClearModal.hasChildNodes()) {
                                                divClearModal.removeChild(divClearModal.childNodes[0]);
                                            }

                                            var divClearBackdrop = document.getElementById('backdropouttables');
                                            while(divClearBackdrop.hasChildNodes()) {
                                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                            }
                                        }
                                    }

                                    //If backdrop Clicked
                                    document.getElementById('backdropHandlerCloseExistsBtn').addEventListener('click', backdropHandlerCloseExistsBtnChange);
                                    async function backdropHandlerCloseExistsBtnChange(e){
                                        e.preventDefault();

                                        //code here

                                        var divClearModal = document.getElementById('modalouttables');
                                        while(divClearModal.hasChildNodes()) {
                                            divClearModal.removeChild(divClearModal.childNodes[0]);
                                        }

                                        var divClearBackdrop = document.getElementById('backdropouttables');
                                        while(divClearBackdrop.hasChildNodes()) {
                                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                        }
                                    }
                                }else{
                                    document.getElementById('tabletasks').style.display = 'block';
                                    document.getElementById('querytablestasks').style.display = 'block';
                                    document.getElementById('crudtablesactions').style.display = 'block';
                                    document.getElementById('addtables').style.display = 'none';
                                    document.getElementById('edittables').style.display = 'none';
                                    document.getElementById('deletetables').style.display = 'none';
                                    //xxxxxxxxx crudbase xxxxxxxxx
                                    document.getElementById('newtables').style.display = 'none';
                                    document.getElementById('createtables').style.display = 'block';
                                    document.getElementById('listtables').style.display = 'none';
                                    document.getElementById('previoustables').style.display = 'none';
                                    document.getElementById('changetables').style.display = 'none';
                                    //xxxxxxx input field
                                    document.getElementById('setcolumns').style.display = 'none';
                                }
                            }else{
                                var pTexteincorrectconnection = "Incorrect Connection Parameters";
                                alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
                            }
                        }
                    };
                    http_request.send();//
                }//
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            } 
        }
    }
    //Crud tasks (mobileconfirmertable)
    document.getElementById('mobileconfirmertable').addEventListener('click', mobileconfirmertableChange);
    async function mobileconfirmertableChange(e){
        e.preventDefault();

        //alert
        var elementOutId = 'modaloutalerttables';
        var elementDropId = 'backdropoutalerttables';
        var buttonHandler = 'ouiHandlerAlertTableBtn';

        var mobileusernametableStr = document.getElementById('mobileusernametable').value;
        var mobilepasswordnametableStr = document.getElementById('mobilepasswordnametable').value;
        var serveurHeaderStr = document.getElementById('serveurheader').innerText;
        var deviceStr = document.getElementById('device').innerText;
        if(!mobileusernametableStr){
            var pTexteusername = "Enter Username";
            alertModal(elementOutId,elementDropId,pTexteusername,buttonHandler);
        }else if(!mobilepasswordnametableStr){
            var pTextepasswordname = "Enter Password";
            alertModal(elementOutId,elementDropId,pTextepasswordname,buttonHandler);
        }else{
            if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
                var docsCheckConfirm = [];
                try {
                    const db = new PouchDB(serveurHeaderStr, {skip_setup:true});
                    PouchDB.plugin(PouchFind);

                    let result = await db.createIndex({
                        index: {
                            fields: [
                                'servername'
                            ]
                        }
                    });
                    result = await db.find({
                        selector: {
                            servername: serveurHeaderStr
                        }
                    });
                    docsCheckConfirm = result.docs;
                    var resultatCheckConfirm = docsCheckConfirm.length;
                    if(resultatCheckConfirm === 0){
                        var pTextenotexistaddnew = "Database not exists. Create it first";
                        alertModal(elementOutId,elementDropId,pTextenotexistaddnew,buttonHandler);
                    }else{
                        document.getElementById('tabletasks').style.display = 'block';
                        document.getElementById('querytablestasks').style.display = 'block';
                        document.getElementById('crudtablesactions').style.display = 'block';
                        document.getElementById('addtables').style.display = 'none';
                        document.getElementById('edittables').style.display = 'none';
                        document.getElementById('deletetables').style.display = 'none';
                        //xxxxxxxxx crudbase xxxxxxxxx
                        document.getElementById('newtables').style.display = 'none';
                        document.getElementById('createtables').style.display = 'block';
                        document.getElementById('listtables').style.display = 'none';
                        document.getElementById('previoustables').style.display = 'none';
                        document.getElementById('changetables').style.display = 'none';
                        //xxxxxxx input field
                        //document.getElementById('tabname').style.display = 'block';
                        //document.getElementById('mobileconfirmertable').style.display = 'block';
                        document.getElementById('setcolumns').style.display = 'none';
                        //document.getElementById('confirmtable').style.display = 'block';
                    }
                } catch (error) {
                    var pTexteerreurconnection = "An Error has Occurred";
                    alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                }
            }
        }
    }

    //generate a table dynamically to fill the data table value
    document.getElementById('confirmtablename').addEventListener('click', confirmtablenameChange);
    async function confirmtablenameChange(e){
        e.preventDefault();

        //alert
        var elementOutId = 'modaloutalerttables';
        var elementDropId = 'backdropoutalerttables';
        var buttonHandler = 'ouiHandlerAlertTableBtn';

        var number = 0;
        number = document.getElementById('fieldtable').value;
        var nametableStr = document.getElementById('nametable').value;
        if(!nametableStr){
            var pTextetablename = "Enter table template name";
            alertModal(elementOutId,elementDropId,pTextetablename,buttonHandler);
        }else if(!number){
            var pTexteselectcolumn = "Select number of columns";
            alertModal(elementOutId,elementDropId,pTexteselectcolumn,buttonHandler);
        }else{
            if(number > 20){
                var pTextenumbercolumn = "Columns maximum number are set to 20";
                alertModal(elementOutId,elementDropId,pTextenumbercolumn,buttonHandler);
            }else{
                // get the reference for the body
                var body = document.getElementById('tabletemplate');
                // Remove any existing input field.
                while(body.hasChildNodes()) {
                    body.removeChild(body.childNodes[0]);
                }
    
                // create the input element
                for(let i=0; i<number; i++){
                    // creates a table row
                    var input = document.createElement("input");
                    input.type = 'text';
                    input.id = 'column'+(i+1);
                    input.placeholder = 'column '+(i+1);
                    input.style.height = '35px';
                    input.style.fontSize = '14px';
                    body.appendChild(input);
                    // Append a line break 
                    body.appendChild(document.createElement('br'));
                }

                document.getElementById('tabletasks').style.display = 'block';
                document.getElementById('querytablestasks').style.display = 'block';
                document.getElementById('crudtablesactions').style.display = 'block';
                document.getElementById('addtables').style.display = 'block';
                document.getElementById('edittables').style.display = 'none';
                document.getElementById('deletetables').style.display = 'none';
                //xxxxxxxxx crudbase xxxxxxxxx
                document.getElementById('newtables').style.display = 'none';
                document.getElementById('createtables').style.display = 'block';
                document.getElementById('listtables').style.display = 'none';
                document.getElementById('previoustables').style.display = 'none';
                document.getElementById('changetables').style.display = 'none';
                //xxxxxxx input field
                document.getElementById('setcolumns').style.display = 'block';
            }
        }
    }

    //create the data table
    document.getElementById('addtables').addEventListener('click', addtablesChange);
    async function addtablesChange(e){
        e.preventDefault();

        //alert
        var elementOutId = 'modaloutalerttables';
        var elementDropId = 'backdropoutalerttables';
        var buttonHandler = 'ouiHandlerAlertTableBtn';

        var deviceStr = document.getElementById('device').innerText;
        var serveurHeaderStr = document.getElementById('serveurheader').innerText;

        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            var hostnametableStr = document.getElementById('hostnametable').value;
            var hostporttableStr = document.getElementById('hostporttable').value;
            //xxxxxx
            var usernametableStr = document.getElementById('usernametable').value;
            var passwordnametableStr = document.getElementById('passwordnametable').value;
            //xxxxxxxxxxx
            var nametableStr = document.getElementById('nametable').value.toLowerCase();
            var tabledatabasenameStr = document.getElementById('tablename').value;
            var tableconnectStr = 'table';
            var tabletemplateStr = 'template';
            //xxxxxxxxxxxxx
            var localnamebaseStr = '';
            //var servernameStr = 'database';
            if(serveurHeaderStr === 'local'){
                localnamebaseStr = 'local';
            }else if(serveurHeaderStr === 'remote'){
                localnamebaseStr = 'remote';
            }
            try {
                var url = "http://"+hostnametableStr+":"+hostporttableStr+"/_utils/";
                var http_request = new XMLHttpRequest();
                http_request.open("GET", url ,true);
                http_request.onreadystatechange = async function(){
                    if(http_request.readyState === 4){
                        if(http_request.status === 200){
                            //search in local / remote database documents
                            const db = new PouchDB(localnamebaseStr, {skip_setup:true});
                            const remote = new PouchDB('http://'+usernametableStr+':'+passwordnametableStr+'@'+hostnametableStr+':'+hostporttableStr+'/'+localnamebaseStr, {skip_setup:true});
                            PouchDB.plugin(PouchFind);
                            // Initialise a sync with the remote server
                            function sync(){
                                var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                db.sync(remote, opts);
                            }
                            if(remote){
                                sync();
                            }

                            var docsCheckConfirm = [];

                            let result = await db.createIndex({
                                index: {
                                    fields: [
                                        'tablename'
                                    ]
                                }
                            });
                            result = await db.find({
                                selector: {
                                    tablename: nametableStr
                                }
                            });
                            docsCheckConfirm = result.docs;
                            var resultatCheckConfirm = docsCheckConfirm.length;
                            if(resultatCheckConfirm === 0){
                                var numbercol = 0;
                                numbercol = document.getElementById('fieldtable').value;
                                var tablekey = [];
                                var tablevalue = [];
                                //Create the document _id and _rev.
                                var now = Date.now();
                                //new data table id
                                var id = nametableStr.toLowerCase()+'_' + now;
                                for(let i=0; i<numbercol; i++){
                                    var columnname = 'column'+(i+1);
                                    var idfield = document.getElementById('column'+(i+1)).value.toLocaleLowerCase();
                                    tablekey.push(columnname);
                                    tablevalue.push(idfield);  
                                }
                                // Add the document to the database.
                                var response = await db.put({
                                    _id: id,
                                    tabledatabase: tabledatabasenameStr.toLowerCase(),
                                    tablename: nametableStr.toLowerCase(),
                                    tableserver: localnamebaseStr.toLocaleLowerCase(),
                                    tablekey,
                                    tablevalue,
                                    tableconnect: tableconnectStr,
                                    tabletemplate: tabletemplateStr,
                                });
                                // check if document inserted
                                var doc = await db.get(response.id);
                                if(doc !== ''){
                                    var pTextenewdatabase = "New Table Template added Succesfully";
                                    alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
                                }else{
                                    var pTexteerreuraddnew = "An Error has Occurred";
                                    alertModal(elementOutId,elementDropId,pTexteerreuraddnew,buttonHandler);
                                }
                            }else{
                                var pTexteexistaddnew = "Table template "+nametableStr+" already exists";
                                alertModal(elementOutId,elementDropId,pTexteexistaddnew,buttonHandler);
                            }
                        }else{
                            var pTexteincorrectconnection = "Incorrect Connection Parameters";
                            alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
                        }
                    }
                }
                http_request.send();//
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler); 
            }
        };

        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            var nametableStr = document.getElementById('nametable').value.toLowerCase();
            var tabledatabasenameStr = serveurHeaderStr;
            var tableconnectStr = 'table';
            var tabletemplateStr = 'template';
            //xxxxx
            const db = new PouchDB(serveurHeaderStr, {skip_setup:true});
            PouchDB.plugin(PouchFind);

            var docsCheckConfirm = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: [
                            'tableserver',
                            'tablename'
                        ]
                    }
                });
                result = await db.find({
                    selector: {
                        tableserver: serveurHeaderStr,
                        tablename: nametableStr
                    }
                });
                docsCheckConfirm = result.docs;
                var resultatCheckConfirm = docsCheckConfirm.length;
                if(resultatCheckConfirm === 0){
                    var numbercol = 0;
                    numbercol = document.getElementById('fieldtable').value;
                    var tablekey = [];
                    var tablevalue = [];
                    //Create the document _id and _rev.
                    var now = Date.now();
                    //new data table id
                    var id = nametableStr.toLowerCase()+'_' + now;
                    for(let i=0; i<numbercol; i++){
                        var columnname = 'column'+(i+1);
                        var idfield = document.getElementById('column'+(i+1)).value.toLocaleLowerCase();
                        tablekey.push(columnname);
                        tablevalue.push(idfield);  
                    }
                    // Add the document to the database.
                    var response = await db.put({
                        _id: id,
                        tabledatabase: tabledatabasenameStr.toLowerCase(),
                        tablename: nametableStr.toLowerCase(),
                        tableserver: serveurHeaderStr.toLocaleLowerCase(),
                        tablekey,
                        tablevalue,
                        tableconnect: tableconnectStr,
                        tabletemplate: tabletemplateStr,
                    });
                    // check if document inserted
                    var doc = await db.get(response.id);
                    if(doc !== ''){
                        var pTextenewdatabase = "New Table Template added Succesfully";
                        alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
                    }else{
                        var pTexteerreuraddnew = "An Error has Occurred 1";
                        alertModal(elementOutId,elementDropId,pTexteerreuraddnew,buttonHandler);
                    }
                }else{
                    var pTexteexistaddnew = "Table template "+nametableStr+" already exists";
                    alertModal(elementOutId,elementDropId,pTexteexistaddnew,buttonHandler);
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred 2";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler); 
            }
        }
    }

    //Crud tasks (confirmertablechange)
    document.getElementById('confirmertablechange').addEventListener('click', confirmertablechangeChange);
    async function confirmertablechangeChange(e){
        e.preventDefault();

        //alert
        var elementOutId = 'modaloutalerttables';
        var elementDropId = 'backdropoutalerttables';
        var buttonHandler = 'ouiHandlerAlertTableBtn';

        var hostnametablechangeStr = document.getElementById('hostnametablechange').value;
        var hostporttablechangeStr = document.getElementById('hostporttablechange').value;
        var usernametablechangeStr = document.getElementById('usernametablechange').value;
        var passwordnametablechangeStr = document.getElementById('passwordnametablechange').value;
        var tablenamechangeStr = document.getElementById('tablenamechange').value.toLowerCase();
        var serveurHeaderStr = document.getElementById('serveurheader').innerText;
        var deviceStr = document.getElementById('device').innerText;
        var localnamebaseStr = '';
        var servernameStr = 'database';
        if(serveurHeaderStr === 'local'){
            localnamebaseStr = 'local';
        }else if(serveurHeaderStr === 'remote'){
            localnamebaseStr = 'remote';
        }
        var localtypebaseStr = 'database';
        var tableconnectStr = 'table';

        //check if values not null
        if(!hostnametablechangeStr){
            var pTextehostname = "Enter Host Name";
            alertModal(elementOutId,elementDropId,pTextehostname,buttonHandler);
        }else if(!hostporttablechangeStr){
            var pTexteportname = "Enter Port Number";
            alertModal(elementOutId,elementDropId,pTexteportname,buttonHandler);
        }else if(!usernametablechangeStr){
            var pTexteusername = "Enter Username";
            alertModal(elementOutId,elementDropId,pTexteusername,buttonHandler);
        }else if(!passwordnametablechangeStr){
            var pTextepasswordname = "Enter Password";
            alertModal(elementOutId,elementDropId,pTextepasswordname,buttonHandler);
        }else if(!tablenamechangeStr){
            var pTextedatabasename = "Enter Database Name";
            alertModal(elementOutId,elementDropId,pTextedatabasename,buttonHandler);
        }else{
            try {
                //test if hostname and portnumber are true
                if((deviceStr === '' && serveurHeaderStr === 'local') || 
                (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
                    var url = "http://"+hostnametablechangeStr+":"+hostporttablechangeStr+"/_utils/";
                    var http_request = new XMLHttpRequest();
                    http_request.open("GET", url ,true);
                    http_request.onreadystatechange = async function(){
                        if(http_request.readyState === 4){
                            if(http_request.status === 200){
                                //search in local / remote database documents
                                const db = new PouchDB(localnamebaseStr, {skip_setup:true});
                                const remote = new PouchDB('http://'+usernametablechangeStr+':'+passwordnametablechangeStr+'@'+hostnametablechangeStr+':'+hostporttablechangeStr+'/'+localnamebaseStr, {skip_setup:true});
                                PouchDB.plugin(PouchFind);
                                // Initialise a sync with the remote server
                                function sync(){
                                    var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                    db.sync(remote, opts);
                                }
                                if(remote){
                                    sync();
                                }

                                var docsCheckConfirm = [];

                                let result = await db.createIndex({
                                    index: {
                                        fields: [
                                            'connectname',
                                            'servername'
                                        ]
                                    }
                                });
                                result = await db.find({
                                    selector: {
                                        connectname: tablenamechangeStr,
                                        servername: servernameStr
                                    }
                                });
                                docsCheckConfirm = result.docs;
                                var resultatCheckConfirm = docsCheckConfirm.length;
                                if(resultatCheckConfirm === 0){
                                    var divClearModal = document.getElementById('modalouttables');
                                    let divModal = document.createElement('div');
                                    divModal.innerHTML =
                                    `<div class="modal">
                                        <p>Sure this database exists?</p>
                                        <button class="btn btn--alt" id="ouiHandlerExistChangeBtn">Yes</button>
                                        <button class="btn" id="nonHandlerExistsChangeBtn">No</button>
                                    </div>`;
                                    divClearModal.appendChild(divModal);

                                    var divClearBackdrop = document.getElementById('backdropouttables');
                                    let divBack = document.createElement('div');
                                    divBack.innerHTML =
                                    `<div class="backdrop" id="backdropHandlerCloseExistsChangeBtn"></div>`;
                                    divClearBackdrop.appendChild(divBack);

                                    //If Yes Clicked
                                    document.getElementById('ouiHandlerExistChangeBtn').addEventListener('click', ouiHandlerExistChangeBtnChange);
                                    async function ouiHandlerExistChangeBtnChange(e){
                                        e.preventDefault();

                                        //code here
                                        var divClearModal = document.getElementById('modalouttables');
                                        let divModal = document.createElement('div');
                                        divModal.innerHTML =
                                        `<div class="modal">
                                            <p>Wish to Add it?</p>
                                            <button class="btn btn--alt" id="ouiHandlerAddChangeBtn">Yes</button>
                                            <button class="btn" id="nonHandlerAddChangeBtn">No</button>
                                        </div>`;
                                        divClearModal.appendChild(divModal);

                                        var divClearBackdrop = document.getElementById('backdropouttables');
                                        let divBack = document.createElement('div');
                                        divBack.innerHTML =
                                        `<div class="backdrop" id="backdropHandlerCloseAddChangeBtn"></div>`;
                                        divClearBackdrop.appendChild(divBack);

                                        //If Yes Add Clicked
                                        document.getElementById('ouiHandlerAddChangeBtn').addEventListener('click', ouiHandlerAddChangeBtnChange);
                                        async function ouiHandlerAddChangeBtnChange(e){
                                            e.preventDefault();

                                            //code here
                                            const db = new PouchDB(localnamebaseStr, {skip_setup:true});
                                            const remote = new PouchDB('http://'+usernametablechangeStr+':'+passwordnametablechangeStr+'@'+hostnametablechangeStr+':'+hostporttablechangeStr+'/'+localnamebaseStr, {skip_setup:true});
                                            PouchDB.plugin(PouchFind);
                                            //test if hostname and portnumber are true
                                            var http_request = new XMLHttpRequest();
                                            http_request.open("GET", "http://"+hostnametablechangeStr+":"+hostporttablechangeStr+"/_utils/" ,true);
                                            http_request.send("null");
                                            http_request.onreadystatechange = async function(){
                                                if(http_request.readyState === 4){
                                                    if(http_request.status === 200){
                                                        function sync(){
                                                            var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                                            db.sync(remote, opts);
                                                        }
                                                        if(remote){
                                                            sync();
                                                        }
                                                        //end sync
                                                        var docsCheck = [];
                                                        try {
                                                            let result = await db.createIndex({
                                                                index: {
                                                                    fields: [
                                                                        'hostname', 
                                                                        'portname',
                                                                        'username',
                                                                        'passwordname'
                                                                    ]
                                                                }
                                                            });
                                                            result = await db.find({
                                                                selector: {
                                                                    hostname: hostnametablechangeStr,
                                                                    portname: hostporttablechangeStr,
                                                                    username: usernametablechangeStr,
                                                                    passwordname: passwordnametablechangeStr
                                                                }
                                                            });
                                                            docsCheck = result.docs;
                                                            var resultatCheck = docsCheck.length;
                                                            if(resultatCheck === ''){
                                                                var pTexteincorrectconnection = "Name or password is incorrect.";
                                                                alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
                                                            }else{
                                                                var docsCheckDatabase = [];
                                                                let resultDatabase = await db.createIndex({
                                                                    index: {
                                                                        fields: [
                                                                            'connectname'
                                                                        ]
                                                                    }
                                                                });
                                                                resultDatabase = await db.find({
                                                                    selector: {
                                                                        connectname: tablenamechangeStr
                                                                    }
                                                                });
                                                                docsCheckDatabase = resultDatabase.docs;
                                                                var resultatCheckDatabase = docsCheckDatabase.length;
                                                                if(resultatCheckDatabase === 0){
                                                                    const dbNew = new PouchDB(tablenamechangeStr);
                                                                    const remoteNew = couchProvider(hostnametablechangeStr, hostporttablechangeStr, usernametablechangeStr, passwordnametablechangeStr, tablenamechangeStr);
                                                                    //add new database created to local connection server
                                                                    //Create the document _id and _rev.
                                                                    var now = Date.now();
                                                                    //new database id
                                                                    var id = tablenamechangeStr.toLowerCase()+'_' + now;
                                                                    // Add the document to the database.
                                                                    var response = await db.put({
                                                                        _id: id,
                                                                        connectname: tablenamechangeStr, //the new database name
                                                                        hostname: hostnametablechangeStr,
                                                                        portname: hostporttablechangeStr,
                                                                        username: usernametablechangeStr,
                                                                        passwordname: passwordnametablechangeStr,
                                                                        servername: localtypebaseStr,
                                                                    });
                                                                    // check if document inserted
                                                                    var doc = await db.get(response.id);
                                                                    if(doc !== ''){
                                                                        //Initialise a sync with the remote server
                                                                        function sync(){
                                                                            var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                                                            dbNew.sync(remoteNew, opts);
                                                                        }
                                                                        if(remoteNew){
                                                                            sync();
                                                                        }
                                                                        var pTextenewdatabase = "New Database added Succesfully";
                                                                        alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
                                                                    }else{
                                                                        var pTexteerreuraddnew = "An Error has Occurred";
                                                                        alertModal(elementOutId,elementDropId,pTexteerreuraddnew,buttonHandler);
                                                                    }
                                                                }else{
                                                                    var pTexteexistaddnew = "Database "+tablenamechangeStr+" already exists";
                                                                    alertModal(elementOutId,elementDropId,pTexteexistaddnew,buttonHandler);
                                                                }
                                                            }
                                                        } catch (error) {
                                                            var pTexteerreurconnection = "An Error has Occurred";
                                                            alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler); 
                                                        }
                                                    }else{
                                                        var pTextefailedconnect = "Connection Failed.";
                                                        alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                                                    }
                                                }
                                            }
                                        
                                            var divClearModal = document.getElementById('modalouttables');
                                            while(divClearModal.hasChildNodes()) {
                                                divClearModal.removeChild(divClearModal.childNodes[0]);
                                            }

                                            var divClearBackdrop = document.getElementById('backdropouttables');
                                            while(divClearBackdrop.hasChildNodes()) {
                                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                            }
                                        }

                                        //If No Add Clicked
                                        document.getElementById('nonHandlerAddChangeBtn').addEventListener('click', nonHandlerAddChangeBtnChange);
                                        async function nonHandlerAddChangeBtnChange(e){
                                            e.preventDefault();

                                            //code here

                                            var divClearModal = document.getElementById('modalouttables');
                                            while(divClearModal.hasChildNodes()) {
                                                divClearModal.removeChild(divClearModal.childNodes[0]);
                                            }

                                            var divClearBackdrop = document.getElementById('backdropouttables');
                                            while(divClearBackdrop.hasChildNodes()) {
                                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                            }
                                        }

                                        //If backdrop Clicked
                                        document.getElementById('backdropHandlerCloseAddChangeBtn').addEventListener('click', backdropHandlerCloseAddChangeBtnChange);
                                        async function backdropHandlerCloseAddChangeBtnChange(e){
                                            e.preventDefault();

                                            //code here

                                            var divClearModal = document.getElementById('modalouttables');
                                            while(divClearModal.hasChildNodes()) {
                                                divClearModal.removeChild(divClearModal.childNodes[0]);
                                            }

                                            var divClearBackdrop = document.getElementById('backdropouttables');
                                            while(divClearBackdrop.hasChildNodes()) {
                                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                            }
                                        }
                                    }

                                    //If No Clicked
                                    document.getElementById('nonHandlerExistsChangeBtn').addEventListener('click', nonHandlerExistsChangeBtnChange);
                                    async function nonHandlerExistsChangeBtnChange(e){
                                        e.preventDefault();

                                        //code here
                                        var divClearModal = document.getElementById('modalouttables');
                                        let divModal = document.createElement('div');
                                        divModal.innerHTML =
                                        `<div class="modal">
                                            <p>Wish to Use it?</p>
                                            <button class="btn btn--alt" id="ouiHandlerUseChangeBtn">Yes</button>
                                            <button class="btn" id="nonHandlerUseChangeBtn">No</button>
                                        </div>`;
                                        divClearModal.appendChild(divModal);

                                        var divClearBackdrop = document.getElementById('backdropouttables');
                                        let divBack = document.createElement('div');
                                        divBack.innerHTML =
                                        `<div class="backdrop" id="backdropHandlerCloseUseChangeBtn"></div>`;
                                        divClearBackdrop.appendChild(divBack);

                                        //If Yes Use Clicked
                                        document.getElementById('ouiHandlerUseChangeBtn').addEventListener('click', ouiHandlerUseChangeBtnChange);
                                        async function ouiHandlerUseChangeBtnChange(e){
                                            e.preventDefault();

                                            //code here
                                            const db = new PouchDB(localnamebaseStr, {skip_setup:true});
                                            const remote = new PouchDB('http://'+usernametablechangeStr+':'+passwordnametablechangeStr+'@'+hostnametablechangeStr+':'+hostporttablechangeStr+'/'+localnamebaseStr, {skip_setup:true});
                                            PouchDB.plugin(PouchFind);
                                            //test if hostname and portnumber are true
                                            var http_request = new XMLHttpRequest();
                                            http_request.open("GET", "http://"+hostnametablechangeStr+":"+hostporttablechangeStr+"/_utils/" ,true);
                                            http_request.send("null");
                                            http_request.onreadystatechange = async function(){
                                                if(http_request.readyState === 4){
                                                    if(http_request.status === 200){
                                                        function sync(){
                                                            var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                                            db.sync(remote, opts);
                                                        }
                                                        if(remote){
                                                            sync();
                                                        }
                                                        //end sync
                                                        var docsCheck = [];
                                                        try {
                                                            let result = await db.createIndex({
                                                                index: {
                                                                    fields: [
                                                                        'hostname', 
                                                                        'portname',
                                                                        'username',
                                                                        'passwordname'
                                                                    ]
                                                                }
                                                            });
                                                            result = await db.find({
                                                                selector: {
                                                                    hostname: hostnametablechangeStr,
                                                                    portname: hostporttablechangeStr,
                                                                    username: usernametablechangeStr,
                                                                    passwordname: passwordnametablechangeStr
                                                                }
                                                            });
                                                            docsCheck = result.docs;
                                                            var resultatCheck = docsCheck.length;
                                                            if(resultatCheck === ''){
                                                                var pTexteincorrectconnection = "Name or password is incorrect.";
                                                                alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
                                                            }else{
                                                                var docsCheckDatabase = [];
                                                                let resultDatabase = await db.createIndex({
                                                                    index: {
                                                                        fields: [
                                                                            'connectname'
                                                                        ]
                                                                    }
                                                                });
                                                                resultDatabase = await db.find({
                                                                    selector: {
                                                                        connectname: tablenamechangeStr
                                                                    }
                                                                });
                                                                docsCheckDatabase = resultDatabase.docs;
                                                                var resultatCheckDatabase = docsCheckDatabase.length;
                                                                if(resultatCheckDatabase === 0){
                                                                    const dbNew = new PouchDB(tablenamechangeStr);
                                                                    const remoteNew = couchProvider(hostnametablechangeStr, hostporttablechangeStr, usernametablechangeStr, passwordnametablechangeStr, tablenamechangeStr);
                                                                    //add new database created to local connection server
                                                                    //Create the document _id and _rev.
                                                                    var now = Date.now();
                                                                    //new database id
                                                                    var id = tablenamechangeStr.toLowerCase()+'_' + now;
                                                                    // Add the document to the database.
                                                                    var response = await db.put({
                                                                        _id: id,
                                                                        connectname: tablenamechangeStr, //the new database name
                                                                        hostname: hostnametablechangeStr,
                                                                        portname: hostporttablechangeStr,
                                                                        username: usernametablechangeStr,
                                                                        passwordname: passwordnametablechangeStr,
                                                                        servername: localtypebaseStr,
                                                                    });
                                                                    // check if document inserted
                                                                    var doc = await db.get(response.id);
                                                                    if(doc !== ''){
                                                                        //Initialise a sync with the remote server
                                                                        function sync(){
                                                                            var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                                                            dbNew.sync(remoteNew, opts);
                                                                        }
                                                                        if(remoteNew){
                                                                            sync();
                                                                        }
                                                                        var pTextenewdatabase = "New Database added Succesfully";
                                                                        alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
                                                                    }else{
                                                                        var pTexteerreuraddnew = "An Error has Occurred";
                                                                        alertModal(elementOutId,elementDropId,pTexteerreuraddnew,buttonHandler);
                                                                    }
                                                                }else{
                                                                    var pTexteexistaddnew = "Database "+tablenamechangeStr+" already exists";
                                                                    alertModal(elementOutId,elementDropId,pTexteexistaddnew,buttonHandler);
                                                                }
                                                            }
                                                        } catch (error) {
                                                            var pTexteerreurconnection = "An Error has Occurred";
                                                            alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                                                        }
                                                    }else{
                                                        var pTextefailedconnect = "Connection Failed.";
                                                        alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                                                    }
                                                }
                                            }

                                            var divClearModal = document.getElementById('modalouttables');
                                            while(divClearModal.hasChildNodes()) {
                                                divClearModal.removeChild(divClearModal.childNodes[0]);
                                            }

                                            var divClearBackdrop = document.getElementById('backdropouttables');
                                            while(divClearBackdrop.hasChildNodes()) {
                                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                            }
                                        }

                                        //If No Use Clicked
                                        document.getElementById('nonHandlerUseChangeBtn').addEventListener('click', nonHandlerUseChangeBtnChange);
                                        async function nonHandlerUseChangeBtnChange(e){
                                            e.preventDefault();

                                            //code here

                                            var divClearModal = document.getElementById('modalouttables');
                                            while(divClearModal.hasChildNodes()) {
                                                divClearModal.removeChild(divClearModal.childNodes[0]);
                                            }

                                            var divClearBackdrop = document.getElementById('backdropouttables');
                                            while(divClearBackdrop.hasChildNodes()) {
                                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                            }
                                        }

                                        //If backdrop Clicked
                                        document.getElementById('backdropHandlerCloseUseChangeBtn').addEventListener('click', backdropHandlerCloseUseChangeBtnChange);
                                        async function backdropHandlerCloseUseChangeBtnChange(e){
                                            e.preventDefault();

                                            //code here

                                            var divClearModal = document.getElementById('modalouttables');
                                            while(divClearModal.hasChildNodes()) {
                                                divClearModal.removeChild(divClearModal.childNodes[0]);
                                            }

                                            var divClearBackdrop = document.getElementById('backdropouttables');
                                            while(divClearBackdrop.hasChildNodes()) {
                                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                            }
                                        }
                                    }

                                    //If backdrop Clicked
                                    document.getElementById('backdropHandlerCloseExistsChangeBtn').addEventListener('click', backdropHandlerCloseExistsChangeBtnChange);
                                    async function backdropHandlerCloseExistsChangeBtnChange(e){
                                        e.preventDefault();

                                        //code here

                                        var divClearModal = document.getElementById('modalouttables');
                                        while(divClearModal.hasChildNodes()) {
                                            divClearModal.removeChild(divClearModal.childNodes[0]);
                                        }

                                        var divClearBackdrop = document.getElementById('backdropouttables');
                                        while(divClearBackdrop.hasChildNodes()) {
                                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                        }
                                    }
                                }else{
                                    var docsCheckTable = [];
                                    try {
                                        let resultTable = await db.createIndex({
                                            index: {
                                                fields: [
                                                    'tabledatabase',
                                                    'tableserver',
                                                    'tableconnect'
                                                ]
                                            }
                                        });
                                        resultTable = await db.find({
                                            selector: {
                                                tabledatabase: tablenamechangeStr,
                                                tableserver: localnamebaseStr,
                                                tableconnect: tableconnectStr
                                            }
                                        });
                                        docsCheckTable = resultTable.docs;
                                        var resultatCheckTable = docsCheckTable.length;
                                        if(resultatCheckTable === 0){
                                            var pTextenotable = "No Table Template available";
                                            alertModal(elementOutId,elementDropId,pTextenotable,buttonHandler);
                                        }else{
                                            document.getElementById('tabletasks').style.display = 'block';
                                            document.getElementById('querytablestasks').style.display = 'block';
                                            document.getElementById('crudtablesactions').style.display = 'block';
                                            document.getElementById('addtables').style.display = 'none';
                                            document.getElementById('edittables').style.display = 'none';
                                            document.getElementById('deletetables').style.display = 'none';
                                            //xxxxxxxxx crudbase xxxxxxxxx
                                            updateEditTableUIWithDocs(docsCheckTable);
                                            document.getElementById('newtables').style.display = 'none';
                                            document.getElementById('createtables').style.display = 'none';
                                            document.getElementById('listtables').style.display = 'block';
                                            document.getElementById('previoustables').style.display = 'none';
                                            document.getElementById('changetables').style.display = 'none';
                                            //xxxxxxx input field
                                            document.getElementById('tabname').style.display = 'none';
                                            document.getElementById('confirmertable').style.display = 'block';
                                        }
                                    } catch (error) {
                                        var pTexteerreurtable = "An Error has Occurred";
                                        alertModal(elementOutId,elementDropId,pTexteerreurtable,buttonHandler);
                                    }
                                }
                            }else{
                                var pTexteincorrectconnection = "Incorrect Connection Parameters";
                                alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
                            }
                        }
                    }
                    http_request.send();
                };
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        }
    }
    //Crud tasks (mobileconfirmertablechange)
    document.getElementById('mobileconfirmertablechange').addEventListener('click', mobileconfirmertablechangeChange);
    async function mobileconfirmertablechangeChange(e){
        e.preventDefault();

        //alert
        var elementOutId = 'modaloutalerttables';
        var elementDropId = 'backdropoutalerttables';
        var buttonHandler = 'ouiHandlerAlertTableBtn';

        var mobileusernametablechangeStr = document.getElementById('mobileusernametablechange').value;
        var mobilepasswordnametablechangeStr = document.getElementById('mobilepasswordnametablechange').value;
        //var mobiletablenamechangeStr = document.getElementById('mobilemobiletablenamechange').value.toLowerCase();
        var serveurHeaderStr = document.getElementById('serveurheader').innerText;
        var deviceStr = document.getElementById('device').innerText;
        var tableconnectStr = 'table';

        if(!mobileusernametablechangeStr){
            var pTexteusername = "Enter Username";
            alertModal(elementOutId,elementDropId,pTexteusername,buttonHandler);
        }else if(!mobilepasswordnametablechangeStr){
            var pTextepasswordname = "Enter Password";
            alertModal(elementOutId,elementDropId,pTextepasswordname,buttonHandler);
        }else{
            if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
                const db = new PouchDB(serveurHeaderStr, {skip_setup:true});
                PouchDB.plugin(PouchFind);
                var docsCheckConfirm = [];
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: [
                                'servername'
                            ]
                        }
                    });
                    result = await db.find({
                        selector: {
                            servername: serveurHeaderStr
                        }
                    });
                    docsCheckConfirm = result.docs;
                    var resultatCheckConfirm = docsCheckConfirm.length;
                    if(resultatCheckConfirm === 0){
                        var pTextedatabasenotexist = "Database not exists. Create it first";
                        alertModal(elementOutId,elementDropId,pTextedatabasenotexist,buttonHandler);
                    }else{
                        var docsCheckTable = [];
                        try {
                            let resultTable = await db.createIndex({
                                index: {
                                    fields: [
                                        'tabledatabase',
                                        'tableserver',
                                        'tableconnect'
                                    ]
                                }
                            });
                            resultTable = await db.find({
                                selector: {
                                    tabledatabase: serveurHeaderStr,
                                    tableserver: serveurHeaderStr,
                                    tableconnect: tableconnectStr
                                }
                            });
                            docsCheckTable = resultTable.docs;
                            var resultatCheckTable = docsCheckTable.length;
                            if(resultatCheckTable === 0){
                                var pTextenotable = "No Table Template available";
                                alertModal(elementOutId,elementDropId,pTextenotable,buttonHandler);
                            }else{
                                document.getElementById('tabletasks').style.display = 'block';
                                document.getElementById('querytablestasks').style.display = 'block';
                                document.getElementById('crudtablesactions').style.display = 'block';
                                document.getElementById('addtables').style.display = 'none';
                                document.getElementById('edittables').style.display = 'none';
                                document.getElementById('deletetables').style.display = 'none';
                                //xxxxxxxxx crudbase xxxxxxxxx
                                updateEditTableUIWithDocs(docsCheckTable);
                                document.getElementById('newtables').style.display = 'none';
                                document.getElementById('createtables').style.display = 'none';
                                document.getElementById('listtables').style.display = 'block';
                                document.getElementById('previoustables').style.display = 'none';
                                document.getElementById('changetables').style.display = 'none';
                                //xxxxxxx input field
                                document.getElementById('tabname').style.display = 'none';
                                document.getElementById('confirmertable').style.display = 'block';
                            }
                        } catch (error) {
                            var pTexteerreurtable = "An Error has Occurred";
                            alertModal(elementOutId,elementDropId,pTexteerreurtable,buttonHandler);
                        }
                    }
                } catch (error) {
                    var pTexteerreurconnection = "An Error has Occurred";
                    alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                }
            }
        }
    }
    // Adds the document to the HTML First table.
    // If the document already exists in the table, it will be updated.
    function editTableDocToToTable(doc, editTableTableBody){
        // Each row of the table has an id attribute set to the doc._id field.
        var tr = editTableTableBody.querySelector(`#${doc._id}`);

        if( !tr ) {
            tr = document.createElement('tr');
            tr.id = doc._id;
        }

        // Remove any existing table cells.
        while(tr.hasChildNodes()) {
            tr.removeChild(tr.childNodes[0]);
        }

        // Create new table cells for text fields.
        var tdTable = document.createElement('td');
        tdTable.innerText = doc.tablename;
        //var tableNameStr = doc.tablename;
        tdTable.onclick = function(){
            var tableidStr = doc._id;
            var tabledatabaseStr = doc.tabledatabase;
            var tablenameStr = doc.tablename;
            var tableserverStr = doc.tableserver;
            var tablekeyStr = doc.tablekey;
            var tablevalueStr = doc.tablevalue;
            var tableconnectStr = doc.tableconnect;
            var nametablechangetext = document.getElementById('nametablechange');
            nametablechangetext.value = tablenameStr;
            var fieldtablechangetext = document.getElementById('fieldtablechange');
            fieldtablechangetext.value = tablekeyStr.length;
            var tableidchangetext = document.getElementById('tableidchange');
            tableidchangetext.value = tableidStr;
            var tabledatabasechangetext = document.getElementById('tabledatabasechange');
            tabledatabasechangetext.value = tabledatabaseStr;
            var tableserverchangetext = document.getElementById('tableserverchange');
            tableserverchangetext.value = tableserverStr;
            var tableconnectchangetext = document.getElementById('tableconnectchange');
            tableconnectchangetext.value = tableconnectStr;

            // get the reference for the body
            var body = document.getElementById('tabletemplatechange');
            // Remove any existing input field.
            while(body.hasChildNodes()) {
                body.removeChild(body.childNodes[0]);
            }

            //populate tablekey
            for(let i=0; i<tablevalueStr.length; i++){
                // creates a table row
                var input = document.createElement("input");
                input.type = 'text';
                input.id = 'column'+(i+1);
                //input.placeholder = 'column '+(i+1);
                input.value = tablevalueStr[i];
                input.style.height = '35px';
                input.style.fontSize = '14px';
                body.appendChild(input);
                // Append a line break 
                body.appendChild(document.createElement('br'));
            }
            document.getElementById('tabletasks').style.display = 'block';
            document.getElementById('querytablestasks').style.display = 'block';
            document.getElementById('crudtablesactions').style.display = 'block';
            document.getElementById('addtables').style.display = 'none';
            document.getElementById('edittables').style.display = 'none';
            document.getElementById('deletetables').style.display = 'none';
            //xxxxxxxxx crudbase xxxxxxxxx
            document.getElementById('newtables').style.display = 'none';
            document.getElementById('createtables').style.display = 'none';
            document.getElementById('listtables').style.display = 'none';
            document.getElementById('previoustables').style.display = 'none';
            document.getElementById('changetables').style.display = 'block';
            //xxxxxxx input field
            document.getElementById('tabname').style.display = 'none';
            document.getElementById('confirmertable').style.display = 'none';
        }
        tr.appendChild(tdTable);

        // Create new table cells for text fields.
        var tdId = document.createElement('td');
        tdId.innerText = doc._id;
        //var tdIdTableStr = doc.tablename;
        tdId.onclick = function(){
            var tableidStr = doc._id;
            var tabledatabaseStr = doc.tabledatabase;
            var tablenameStr = doc.tablename;
            var tableserverStr = doc.tableserver;
            var tablekeyStr = doc.tablekey;
            var tablevalueStr = doc.tablevalue;
            var tableconnectStr = doc.tableconnect;
            var nametablechangetext = document.getElementById('nametablechange');
            nametablechangetext.value = tablenameStr;
            var fieldtablechangetext = document.getElementById('fieldtablechange');
            fieldtablechangetext.value = tablekeyStr.length;
            var tableidchangetext = document.getElementById('tableidchange');
            tableidchangetext.value = tableidStr;
            var tabledatabasechangetext = document.getElementById('tabledatabasechange');
            tabledatabasechangetext.value = tabledatabaseStr;
            var tableserverchangetext = document.getElementById('tableserverchange');
            tableserverchangetext.value = tableserverStr;
            var tableconnectchangetext = document.getElementById('tableconnectchange');
            tableconnectchangetext.value = tableconnectStr;

            // get the reference for the body
            var body = document.getElementById('tabletemplatechange');
            // Remove any existing input field.
            while(body.hasChildNodes()) {
                body.removeChild(body.childNodes[0]);
            }

            //populate tablekey
            for(let i=0; i<tablevalueStr.length; i++){
                // creates a table row
                var input = document.createElement("input");
                input.type = 'text';
                input.id = 'column'+(i+1);
                //input.placeholder = 'column '+(i+1);
                input.value = tablevalueStr[i];
                input.style.height = '35px';
                input.style.fontSize = '14px';
                body.appendChild(input);
                // Append a line break 
                body.appendChild(document.createElement('br'));
            }
            document.getElementById('tabletasks').style.display = 'block';
            document.getElementById('querytablestasks').style.display = 'block';
            document.getElementById('crudtablesactions').style.display = 'block';
            document.getElementById('addtables').style.display = 'none';
            document.getElementById('edittables').style.display = 'none';
            document.getElementById('deletetables').style.display = 'none';
            //xxxxxxxxx crudbase xxxxxxxxx
            document.getElementById('newtables').style.display = 'none';
            document.getElementById('createtables').style.display = 'none';
            document.getElementById('listtables').style.display = 'none';
            document.getElementById('previoustables').style.display = 'none';
            document.getElementById('changetables').style.display = 'block';
            //xxxxxxx input field
            document.getElementById('tabname').style.display = 'none';
            document.getElementById('confirmertable').style.display = 'none';
        }
        tr.appendChild(tdId);

        // Add the row to the top of the table.
        editTableTableBody.insertBefore(tr, editTableTableBody.nextSibling);
    }
    /**Clear and update the UI to display the docs.*/
    function updateEditTableUIWithDocs(docs){
        var editTableTableBody = document.getElementById('editTableTableBody');

        // Remove all child nodes from the table
        while(editTableTableBody.hasChildNodes()) {
            editTableTableBody.removeChild(editTableTableBody.childNodes[0]);
        }

        // Add the new documents to the div
        for(var i=0; i<docs.length; i++) {
            var doc = docs[i];
            editTableDocToToTable(doc, editTableTableBody);
        }
    }

    document.getElementById('confirmtablenamechange').addEventListener('click', confirmtablenamechangeChange);
    async function confirmtablenamechangeChange(e){
        e.preventDefault();

        if(typecrud === 'edit'){
            document.getElementById('tabletasks').style.display = 'block';
            document.getElementById('querytablestasks').style.display = 'block';
            document.getElementById('crudtablesactions').style.display = 'block';
            document.getElementById('addtables').style.display = 'none';
            //xxxxxx
            document.getElementById('edittables').style.display = 'block';
            document.getElementById('deletetables').style.display = 'none';

            //xxxxxxxxx crudbase xxxxxxxxx
            document.getElementById('newtables').style.display = 'none';
            document.getElementById('createtables').style.display = 'none';
            document.getElementById('listtables').style.display = 'none';
            document.getElementById('previoustables').style.display = 'none';
            document.getElementById('changetables').style.display = 'block';
            //xxxxxxx input field
            document.getElementById('tabname').style.display = 'none';
            document.getElementById('confirmertable').style.display = 'none';
        }else if(typecrud === 'delete'){
            document.getElementById('tabletasks').style.display = 'block';
            document.getElementById('querytablestasks').style.display = 'block';
            document.getElementById('crudtablesactions').style.display = 'block';
            document.getElementById('addtables').style.display = 'none';
            //xxxxxx
            document.getElementById('edittables').style.display = 'none';
            document.getElementById('deletetables').style.display = 'block';

            //xxxxxxxxx crudbase xxxxxxxxx
            document.getElementById('newtables').style.display = 'none';
            document.getElementById('createtables').style.display = 'none';
            document.getElementById('listtables').style.display = 'none';
            document.getElementById('previoustables').style.display = 'none';
            document.getElementById('changetables').style.display = 'block';
            //xxxxxxx input field
            document.getElementById('tabname').style.display = 'none';
            document.getElementById('confirmertable').style.display = 'none';
        }else{
            document.getElementById('tabletasks').style.display = 'block';
            document.getElementById('querytablestasks').style.display = 'block';
            document.getElementById('crudtablesactions').style.display = 'block';
            document.getElementById('addtables').style.display = 'none';
            //xxxxxx
            document.getElementById('edittables').style.display = 'none';
            document.getElementById('deletetables').style.display = 'none';

            //xxxxxxxxx crudbase xxxxxxxxx
            document.getElementById('newtables').style.display = 'none';
            document.getElementById('createtables').style.display = 'none';
            document.getElementById('listtables').style.display = 'none';
            document.getElementById('previoustables').style.display = 'none';
            document.getElementById('changetables').style.display = 'none';
            //xxxxxxx input field
            document.getElementById('tabname').style.display = 'none';
            document.getElementById('confirmertable').style.display = 'none';
        }
    }

    //Crud tasks (edit)
    document.getElementById('edittables').addEventListener('click', edittablesChange);
    async function edittablesChange(e){
        e.preventDefault();

        //alert
        var elementOutId = 'modaloutalerttables';
        var elementDropId = 'backdropoutalerttables';
        var buttonHandler = 'ouiHandlerAlertTableBtn';

        var deviceStr = document.getElementById('device').innerText;
        var serveurHeaderStr = document.getElementById('serveurheader').innerText;

        if((deviceStr === '' && serveurHeaderStr === 'local') || 
        (deviceStr === '' && serveurHeaderStr === 'remote') || (deviceStr === 'mobile' && serveurHeaderStr === 'remote')){
            var hostnametableStr = document.getElementById('hostnametablechange').value;
            var hostporttableStr = document.getElementById('hostporttablechange').value;
            var usernametableStr = document.getElementById('usernametablechange').value;
            var passwordnametableStr = document.getElementById('passwordnametablechange').value;
            var tablenameStr = document.getElementById('tablenamechange').value.toLocaleLowerCase();
            var serveurHeaderStr = document.getElementById('serveurheader').innerText;
            var deviceStr = document.getElementById('device').innerText;
            //xxxxxxxxxxxxxxxxxxx
            var nametablechangeStr = document.getElementById('nametablechange').value.toLocaleLowerCase();
            var fieldtablechangeStr = document.getElementById('fieldtablechange').value;
            var tableidchangeStr = document.getElementById('tableidchange').value;
            var tabledatabasechangeStr = document.getElementById('tabledatabasechange').value.toLocaleLowerCase();
            var tableserverchangeStr = document.getElementById('tableserverchange').value;
            var tableconnectchangeStr = document.getElementById('tableconnectchange').value;
            var tabletemplateStr = 'template';
            //xxxxxxxxxxxxxx
            var localnamebaseStr = '';
            //var servernameStr = 'database';
            if(serveurHeaderStr === 'local'){
                localnamebaseStr = 'local';
            }else if(serveurHeaderStr === 'remote'){
                localnamebaseStr = 'remote';
            }

            //test if hostname and portnumber are true
            var http_request = new XMLHttpRequest();
            http_request.open("GET", "http://"+hostnametableStr+":"+hostporttableStr+"/_utils/" ,true);
            http_request.send("null");
            http_request.onreadystatechange = async function(){
                if(http_request.readyState === 4){
                    if(http_request.status === 200){
                        //1:edit document database
                        const dbdoc = new PouchDB(localnamebaseStr, {skip_setup:true});
                        const remotedoc = new PouchDB('http://'+usernametableStr+':'+passwordnametableStr+'@'+hostnametableStr+':'+hostporttableStr+'/'+localnamebaseStr, {skip_setup:true});
                        PouchDB.plugin(PouchFind);
                        var docsCheckDoc = [];
                        try {
                            let result = await dbdoc.createIndex({
                                index: {
                                    fields: [
                                        'connectname', 
                                    ]
                                }
                            });
                            result = await dbdoc.find({
                                selector: {
                                    connectname: tabledatabasechangeStr 
                                }
                            });
                            docsCheckDoc = result.docs;
                            var resultatCheckDoc = docsCheckDoc.length;
                            if(resultatCheckDoc === ''){
                                var pTexteincorrectconnectiondoc = "Template "+tablenameStr+" not exists.";
                                alertModal(elementOutId,elementDropId,pTexteincorrectconnectiondoc,buttonHandler);
                            }else{
                                var numbercol = 0;
                                numbercol = parseFloat(fieldtablechangeStr);
                                var tablekeychange = [];
                                var tablevaluechange = [];
                                for(let i=0; i<numbercol; i++){
                                    var columnname = 'column'+(i+1);
                                    var idfield = document.getElementById('column'+(i+1)).value.toLocaleLowerCase();
                                    tablekeychange.push(columnname);
                                    tablevaluechange.push(idfield);  
                                }

                                var documentID = tableidchangeStr;
                                //edit document in local and remote database
                                // Retrieve the document to get the latest revision
                                var docModifier = await dbdoc.get(documentID);
                                // Make changes
                                docModifier.tabledatabase = tablenameStr;
                                docModifier.tablename = nametablechangeStr;
                                docModifier.tableserver = tableserverchangeStr;
                                docModifier.tablekey = tablekeychange;
                                docModifier.tablevalue = tablevaluechange;
                                docModifier.tableconnect = tableconnectchangeStr;
                                docModifier.tabletemplate = tabletemplateStr;
                                // Put the document, saving it as a new revision
                                var responseModifier = await dbdoc.put(docModifier);
                                // Get the document we change and update the UI.
                                var docEdit = await dbdoc.get(responseModifier.id);
                                if(docEdit !== ''){
                                    //Initialise a sync with the remote server
                                    function sync(){
                                        var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                        dbdoc.sync(remotedoc, opts);
                                    }
                                    if(remotedoc){
                                        sync();
                                    }
                                    var pTextenewdatabase = "Template Updated Succesfully";
                                    alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
                                    //xxxxxxx
                                    document.getElementById('tabletasks').style.display = 'block';
                                    document.getElementById('querytablestasks').style.display = 'none';
                                    document.getElementById('crudtablesactions').style.display = 'none';
                                    document.getElementById('addtables').style.display = 'none';
                                    document.getElementById('edittables').style.display = 'none';
                                    document.getElementById('deletetables').style.display = 'none';
                                    //xxxxxxxxx crudbase xxxxxxxxx
                                    document.getElementById('newtables').style.display = 'none';
                                    document.getElementById('createtables').style.display = 'none';
                                    document.getElementById('listtables').style.display = 'none';
                                    document.getElementById('previoustables').style.display = 'none';
                                    document.getElementById('changetables').style.display = 'none';
                                    //xxxxxxx input field
                                    document.getElementById('tabname').style.display = 'none';
                                    document.getElementById('confirmertable').style.display = 'none';
                                }else{
                                    var pTexteerreuraddnew = "An Error has Occurred";
                                    alertModal(elementOutId,elementDropId,pTexteerreuraddnew,buttonHandler);
                                    //xxxxxxx
                                    document.getElementById('tabletasks').style.display = 'block';
                                    document.getElementById('querytablestasks').style.display = 'none';
                                    document.getElementById('crudtablesactions').style.display = 'none';
                                    document.getElementById('addtables').style.display = 'none';
                                    document.getElementById('edittables').style.display = 'none';
                                    document.getElementById('deletetables').style.display = 'none';
                                    //xxxxxxxxx crudbase xxxxxxxxx
                                    document.getElementById('newtables').style.display = 'none';
                                    document.getElementById('createtables').style.display = 'none';
                                    document.getElementById('listtables').style.display = 'none';
                                    document.getElementById('previoustables').style.display = 'none';
                                    document.getElementById('changetables').style.display = 'none';
                                    //xxxxxxx input field
                                    document.getElementById('tabname').style.display = 'none';
                                    document.getElementById('confirmertable').style.display = 'none';
                                }
                            }
                        } catch (error) {
                            var pTexteerreurconnection = "An Error has Occurred";
                            alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                        }
                    }else{
                        var pTextefailedconnect = "Connection Failed.";
                        alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                    }
                }
            }
        };

        if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
            var tablenameStr = document.getElementById('tablenamechange').value.toLocaleLowerCase();
            var serveurHeaderStr = document.getElementById('serveurheader').innerText;
            var nametablechangeStr = document.getElementById('nametablechange').value.toLocaleLowerCase();
            var fieldtablechangeStr = document.getElementById('fieldtablechange').value;
            var tableidchangeStr = document.getElementById('tableidchange').value;
            var tableserverchangeStr = document.getElementById('tableserverchange').value;
            var tableconnectchangeStr = document.getElementById('tableconnectchange').value;
            var tabletemplateStr = 'template';
            try {
                const dbdoc = new PouchDB(serveurHeaderStr, {skip_setup:true});
                PouchDB.plugin(PouchFind);
                var docsCheckDoc = [];
                let result = await dbdoc.createIndex({
                    index: {
                        fields: [
                            'tabledatabase', 
                            'tablename'
                        ]
                    }
                });
                result = await dbdoc.find({
                    selector: {
                        tabledatabase: serveurHeaderStr,
                        tablename: nametablechangeStr,
                    }
                });
                docsCheckDoc = result.docs;
                var resultatCheckDoc = docsCheckDoc.length;
                if(resultatCheckDoc === 0){
                    var pTexteincorrectconnectiondoc = "Template "+tablenameStr+" not exists.";
                    alertModal(elementOutId,elementDropId,pTexteincorrectconnectiondoc,buttonHandler);
                }else{
                    var numbercol = 0;
                    numbercol = parseFloat(fieldtablechangeStr);
                    var tablekeychange = [];
                    var tablevaluechange = [];
                    for(let i=0; i<numbercol; i++){
                        var columnname = 'column'+(i+1);
                        var idfield = document.getElementById('column'+(i+1)).value.toLocaleLowerCase();
                        tablekeychange.push(columnname);
                        tablevaluechange.push(idfield);  
                    }

                    var documentID = tableidchangeStr;
                    //edit document in local and remote database
                    // Retrieve the document to get the latest revision
                    var docModifier = await dbdoc.get(documentID);
                    // Make changes
                    docModifier.tabledatabase = serveurHeaderStr.toLocaleLowerCase() ;
                    docModifier.tablename = nametablechangeStr;
                    docModifier.tableserver = tableserverchangeStr;
                    docModifier.tablekey = tablekeychange;
                    docModifier.tablevalue = tablevaluechange;
                    docModifier.tableconnect = tableconnectchangeStr;
                    docModifier.tabletemplate = tabletemplateStr;
                    // Put the document, saving it as a new revision
                    var responseModifier = await dbdoc.put(docModifier);
                    // Get the document we change and update the UI.
                    var docEdit = await dbdoc.get(responseModifier.id);
                    if(docEdit !== ''){
                        var pTextenewdatabase = "Template Updated Succesfully";
                        alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
                        //xxxxxxx
                        document.getElementById('tabletasks').style.display = 'block';
                        document.getElementById('querytablestasks').style.display = 'none';
                        document.getElementById('crudtablesactions').style.display = 'none';
                        document.getElementById('addtables').style.display = 'none';
                        document.getElementById('edittables').style.display = 'none';
                        document.getElementById('deletetables').style.display = 'none';
                        //xxxxxxxxx crudbase xxxxxxxxx
                        document.getElementById('newtables').style.display = 'none';
                        document.getElementById('createtables').style.display = 'none';
                        document.getElementById('listtables').style.display = 'none';
                        document.getElementById('previoustables').style.display = 'none';
                        document.getElementById('changetables').style.display = 'none';
                        //xxxxxxx input field
                        document.getElementById('tabname').style.display = 'none';
                        document.getElementById('confirmertable').style.display = 'none';
                    }else{
                        var pTexteerreuraddnew = "An Error has Occurred";
                        alertModal(elementOutId,elementDropId,pTexteerreuraddnew,buttonHandler);
                        //xxxxxxx
                        document.getElementById('tabletasks').style.display = 'block';
                        document.getElementById('querytablestasks').style.display = 'none';
                        document.getElementById('crudtablesactions').style.display = 'none';
                        document.getElementById('addtables').style.display = 'none';
                        document.getElementById('edittables').style.display = 'none';
                        document.getElementById('deletetables').style.display = 'none';
                        //xxxxxxxxx crudbase xxxxxxxxx
                        document.getElementById('newtables').style.display = 'none';
                        document.getElementById('createtables').style.display = 'none';
                        document.getElementById('listtables').style.display = 'none';
                        document.getElementById('previoustables').style.display = 'none';
                        document.getElementById('changetables').style.display = 'none';
                        //xxxxxxx input field
                        document.getElementById('tabname').style.display = 'none';
                        document.getElementById('confirmertable').style.display = 'none';
                    }
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        };
    }////

    //Crud tasks (delete)
    document.getElementById('deletetables').addEventListener('click', deletetablesChange);
    async function deletetablesChange(e){
        e.preventDefault();

        //alert
        var elementOutId = 'modaloutalerttables';
        var elementDropId = 'backdropoutalerttables';
        var buttonHandler = 'ouiHandlerAlertTableBtn';

        var hostnametableStr = document.getElementById('hostnametablechange').value;
        var hostporttableStr = document.getElementById('hostporttablechange').value;
        var usernametableStr = document.getElementById('usernametablechange').value;
        var passwordnametableStr = document.getElementById('passwordnametablechange').value;
        var tablenameStr = document.getElementById('tablenamechange').value.toLocaleLowerCase();
        var serveurHeaderStr = document.getElementById('serveurheader').innerText;
        var deviceStr = document.getElementById('device').innerText;
        //xxxxxxxxxxxxxxxxxxx
        var tableidchangeStr = document.getElementById('tableidchange').value;
        var tabledatabasechangeStr = document.getElementById('tabledatabasechange').value;
        //xxxxxxxxxxxxxx
        var localnamebaseStr = '';
        //var servernameStr = 'database';
        if(serveurHeaderStr === 'local'){
            localnamebaseStr = 'local';
        }else if(serveurHeaderStr === 'remote'){
            localnamebaseStr = 'remote';
        }

        var divClearModal = document.getElementById('modalouttables');
        let divModal = document.createElement('div');
        divModal.innerHTML =
        `<div class="modal">
            <p>This action will permanently delete. Do you want to continue?</p>
            <button class="btn btn--alt" id="ouiHandlerYesDeleteBtn">Yes</button>
            <button class="btn" id="nonHandlerNoDeleteBtn">No</button>
        </div>`;
        divClearModal.appendChild(divModal);

        var divClearBackdrop = document.getElementById('backdropouttables');
        let divBack = document.createElement('div');
        divBack.innerHTML =
        `<div class="backdrop" id="backdropHandlerDropDeleteBtn"></div>`;
        divClearBackdrop.appendChild(divBack);

        //If Yes Clicked
        document.getElementById('ouiHandlerYesDeleteBtn').addEventListener('click', ouiHandlerYesDeleteBtnChange);
        async function ouiHandlerYesDeleteBtnChange(e){
            e.preventDefault();

            //test if hostname and portnumber are true
            if((deviceStr === '' && localnamebaseStr === 'local') || 
            (deviceStr === '' && localnamebaseStr === 'remote') || (deviceStr === 'mobile' && localnamebaseStr === 'remote')){
                var http_request = new XMLHttpRequest();
                http_request.open("GET", "http://"+hostnametableStr+":"+hostporttableStr+"/_utils/" ,true);
                http_request.send("null");
                http_request.onreadystatechange = async function(){
                    if(http_request.readyState === 4){
                        if(http_request.status === 200){
                            //1:delete document database
                            const dbdoc = new PouchDB(localnamebaseStr, {skip_setup:true});
                            const remotedoc = new PouchDB('http://'+usernametableStr+':'+passwordnametableStr+'@'+hostnametableStr+':'+hostporttableStr+'/'+localnamebaseStr, {skip_setup:true});
                            PouchDB.plugin(PouchFind);
                            var docsCheckDoc = [];

                            //Initialise a sync with the remote server
                            function sync(){
                                var opts = {live: true, retry: true, continuous: true, attachments: true,};
                                dbdoc.sync(remotedoc, opts);
                            }
                            if(remotedoc){
                                sync();
                            }
                            try {
                                let result = await dbdoc.createIndex({
                                    index: {
                                        fields: [
                                            'connectname', 
                                        ]
                                    }
                                });
                                result = await dbdoc.find({
                                    selector: {
                                        connectname: tabledatabasechangeStr 
                                    }
                                });
                                docsCheckDoc = result.docs;
                                var resultatCheckDoc = docsCheckDoc.length;
                                if(resultatCheckDoc === ''){
                                    var pTexteincorrectconnectiondoc = "Document "+tablenameStr+" not exists.";
                                    alertModal(elementOutId,elementDropId,pTexteincorrectconnectiondoc,buttonHandler);
                                }else{
                                    var documentID = tableidchangeStr;
                                    var docSupprimer = await dbdoc.get(documentID);
                                    var responseSupprimer = await dbdoc.remove(docSupprimer);
                                    var pTextenewdatabase = "Document Deleted Succesfully";
                                    alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
                                    //xxxxxxxxxxxx
                                    document.getElementById('tabletasks').style.display = 'block';
                                    document.getElementById('querytablestasks').style.display = 'none';
                                    document.getElementById('crudtablesactions').style.display = 'none';
                                    document.getElementById('addtables').style.display = 'none';
                                    document.getElementById('edittables').style.display = 'none';
                                    document.getElementById('deletetables').style.display = 'none';
                                    //xxxxxxxxx crudbase xxxxxxxxx
                                    document.getElementById('newtables').style.display = 'none';
                                    document.getElementById('createtables').style.display = 'none';
                                    document.getElementById('listtables').style.display = 'none';
                                    document.getElementById('previoustables').style.display = 'none';
                                    document.getElementById('changetables').style.display = 'none';
                                    //xxxxxxx input field
                                    document.getElementById('tabname').style.display = 'none';
                                    document.getElementById('confirmertable').style.display = 'none';

                                    var divClearModal = document.getElementById('modalouttables');
                                    while(divClearModal.hasChildNodes()) {
                                        divClearModal.removeChild(divClearModal.childNodes[0]);
                                    }

                                    var divClearBackdrop = document.getElementById('backdropouttables');
                                    while(divClearBackdrop.hasChildNodes()) {
                                        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                    }
                                }
                            } catch (error) {
                                var pTexteerreurconnection = "An Error has Occurred";
                                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                            }
                        }else{
                            var pTextefailedconnect = "Connection Failed.";
                            alertModal(elementOutId,elementDropId,pTextefailedconnect,buttonHandler);
                        }
                    }
                }
            };//

            if(deviceStr === 'mobile' && serveurHeaderStr === 'local'){
                //1:delete document database
                const dbdoc = new PouchDB(localnamebaseStr, {skip_setup:true});
                PouchDB.plugin(PouchFind);
                var docsCheckDoc = [];
                try {
                    let result = await dbdoc.createIndex({
                        index: {
                            fields: [
                                'tabledatabase', 
                                'tablename' 
                            ]
                        }
                    });
                    result = await dbdoc.find({
                        selector: {
                            tabledatabase: serveurHeaderStr,
                            tablename: tablenameStr,
                        }
                    });
                    docsCheckDoc = result.docs;
                    var resultatCheckDoc = docsCheckDoc.length;
                    if(resultatCheckDoc === ''){
                        var pTexteincorrectconnectiondoc = "Template "+tablenameStr+" not exists.";
                        alertModal(elementOutId,elementDropId,pTexteincorrectconnectiondoc,buttonHandler);
                    }else{
                        var documentID = tableidchangeStr;
                        var docSupprimer = await dbdoc.get(documentID);
                        var responseSupprimer = await dbdoc.remove(docSupprimer);
                        var pTextenewdatabase = "Template Deleted Succesfully";
                        alertModal(elementOutId,elementDropId,pTextenewdatabase,buttonHandler);
                        //xxxxxxxxxxxx
                        document.getElementById('tabletasks').style.display = 'block';
                        document.getElementById('querytablestasks').style.display = 'none';
                        document.getElementById('crudtablesactions').style.display = 'none';
                        document.getElementById('addtables').style.display = 'none';
                        document.getElementById('edittables').style.display = 'none';
                        document.getElementById('deletetables').style.display = 'none';
                        //xxxxxxxxx crudbase xxxxxxxxx
                        document.getElementById('newtables').style.display = 'none';
                        document.getElementById('createtables').style.display = 'none';
                        document.getElementById('listtables').style.display = 'none';
                        document.getElementById('previoustables').style.display = 'none';
                        document.getElementById('changetables').style.display = 'none';
                        //xxxxxxx input field
                        document.getElementById('tabname').style.display = 'none';
                        document.getElementById('confirmertable').style.display = 'none';

                        var divClearModal = document.getElementById('modalouttables');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropouttables');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }
                    }
                } catch (error) {
                    var pTexteerreurconnection = "An Error has Occurred";
                    alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                }
            }
        }

        //If No Clicked
        document.getElementById('nonHandlerNoDeleteBtn').addEventListener('click', nonHandlerNoDeleteBtnChange);
        async function nonHandlerNoDeleteBtnChange(e){
            e.preventDefault();

            var divClearModal = document.getElementById('modalouttables');
            while(divClearModal.hasChildNodes()) {
                divClearModal.removeChild(divClearModal.childNodes[0]);
            }

            var divClearBackdrop = document.getElementById('backdropouttables');
            while(divClearBackdrop.hasChildNodes()) {
                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
            }
        }

        //If backdrop Clicked
        document.getElementById('backdropHandlerDropDeleteBtn').addEventListener('click', backdropHandlerDropDeleteBtnChange);
        async function backdropHandlerDropDeleteBtnChange(e){
            e.preventDefault();

            var divClearModal = document.getElementById('modalouttables');
            while(divClearModal.hasChildNodes()) {
                divClearModal.removeChild(divClearModal.childNodes[0]);
            }

            var divClearBackdrop = document.getElementById('backdropouttables');
            while(divClearBackdrop.hasChildNodes()) {
                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
            }
        }
    }
});

function couchProvider(hostname, portnumber, username, password, typebasename){
    //alert
    var elementOutId = 'modaloutalerttables';
    var elementDropId = 'backdropoutalerttables';
    var buttonHandler = 'ouiHandlerAlertTablesBtn';

    var dbName = '';
    var remoteCouch = '';
    var options = {
        live:true,
        retry:true,
        continuous:true,
        attachments: true,
    };

    try {
        dbName = new PouchDB(typebasename);
        remoteCouch = new PouchDB('http://'+username+':'+password+'@'+hostname+':'+portnumber+'/'+typebasename);
        PouchDB.plugin(PouchFind);
        dbName.sync(remoteCouch,options);
    } catch (error) {
        var pTexteerreurconnection = "Connection Failed";
        alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
    }
}

/**1: alertmodal function*/
function alertModal(elementOutId,elementDropId,pTexte,buttonHandler){
    var divClearModal = document.getElementById(elementOutId);
    let divModal = document.createElement('div');
    divModal.innerHTML =
    `<div class="modalalert">
        <p>${pTexte}</p>
        <button class="btnalert btnalert--alt" id="${buttonHandler}">OK</button>
    </div>`;
    divClearModal.appendChild(divModal);

    var divClearBackdrop = document.getElementById(elementDropId);
    let divBack = document.createElement('div');
    divBack.innerHTML =
    `<div class="backdropalert"></div>`;
    divClearBackdrop.appendChild(divBack);

    var buttonChange = document.getElementById(buttonHandler);
    buttonChange.addEventListener('click', (e) =>{
        e.preventDefault();

        var divClearModal = document.getElementById(elementOutId);
        while(divClearModal.hasChildNodes()) {
            divClearModal.removeChild(divClearModal.childNodes[0]);
        }

        var divClearModalDrop = document.getElementById(elementDropId);
        while(divClearModalDrop.hasChildNodes()) {
            divClearModalDrop.removeChild(divClearModalDrop.childNodes[0]);
        }
    }); 
}