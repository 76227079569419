import React from 'react';
import '../pouchtasks';
import '../tasks.css';
import {  
    ServeurContainer, 
    SmallInput, 
    PortInput , 
    FormScroller, 
} from '../../serveur/ServeurStyles';
import { /*ButtonTableDiv,*/ StyleTableButton, StyleUndoButton } from '../TasksStyles';

function ChangeTasks() {
    return ( 
        <div id="changetasks">
            <br/>
            <FormScroller>
                <ServeurContainer>
                    <div>
                        <SmallInput id='nametaskchange' type='text' placeholder='Table Name' readOnly />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='fieldtaskchange' type='number' min="1" max="20" placeholder='Columns' readOnly />
                    </div>
                </ServeurContainer>
                <div style={{display:'none'}}>
                    <div>
                        <SmallInput id='taskidchange' type='text' placeholder='Table Id' />
                    </div>
                    <div>
                        <SmallInput id='taskdatabasechange' type='text' placeholder='Table database' />
                    </div>
                    <div>
                        <SmallInput id='taskserverchange' type='text' placeholder='Table server' />
                    </div>
                    <div>
                        <SmallInput id='taskconnectchange' type='text' placeholder='Table connect' />
                    </div>
                </div>
                <br/>
                <div id='tasktemplatechange'></div>
                <br/>
                <ServeurContainer id='confirmtaskchange'>
                    <StyleTableButton id='selecttasknamechange'>Select</StyleTableButton>
                    &nbsp;&nbsp;
                    <StyleUndoButton id='undotasknamechange'>Undo</StyleUndoButton>
                </ServeurContainer>
                <br/>
            </FormScroller>
        </div>
    );
}

export default ChangeTasks;