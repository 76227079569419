import React from 'react';
import '../pouchtables';
import '../tables.css';

import DeskTables from '../compotables/DeskTables';
import MobileTables from '../compotables/MobileTables';

function NewTables() {
    return ( 
        <div id="newtables">
            <DeskTables />
            <MobileTables />
        </div>
    );
}

export default NewTables;