import React from 'react';
import '../pouchqueries';
import '../queries.css';
import {  
    ServeurContainer, 
    SmallInput, 
    PortInput , 
    FormScroller, 
    TextLocal, 
} from '../../serveur/ServeurStyles';
import { /*ButtonTableDiv,*/ StyleTableButton, StyleUndoButton } from '../QueriesStyles';

function ViewDisplayQueries() {
    return ( 
        <div id="viewdisplayqueries">
            <br/>
            <FormScroller>
                <TextLocal>
                    <label id='textlistviewdocumentquery'>Confirm your template</label>
                </TextLocal>
                <br/>
                <ServeurContainer>
                    <div>
                        <SmallInput id='namequeryviewdisplay' type='text' placeholder='Table Name' readOnly />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <PortInput id='fieldqueryviewdisplay' type='number' min="1" max="20" placeholder='Columns' readOnly />
                    </div>
                </ServeurContainer>
                <div style={{display:'block'}}>
                    <div>
                        <SmallInput id='queryidviewdisplay' type='text' placeholder='Table Id' readOnly />
                    </div>
                    <div>
                        <SmallInput id='querydatabaseviewdisplay' type='text' placeholder='Table database' readOnly />
                    </div>
                    <div>
                        <SmallInput id='queryserverviewdisplay' type='text' placeholder='Table server' readOnly />
                    </div>
                    <div>
                        <SmallInput id='queryconnectviewdisplay' type='text' placeholder='Table connect' readOnly />
                    </div>
                </div>
                <br/>
                <div id='querytemplateviewdisplay'></div>
                <br/>
                <ServeurContainer id='confirmerqueryviewdisplay'>
                    <StyleTableButton id='confirmquerynameviewdisplay'>Confirm</StyleTableButton>
                    &nbsp;&nbsp;
                    <StyleUndoButton id='undoquerynameviewdisplay'>Undo</StyleUndoButton>
                </ServeurContainer>
                <br/>
            </FormScroller>
        </div>
    );
}

export default ViewDisplayQueries;