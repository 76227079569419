import React from 'react';
import '../pouchtasks';
import '../tasks.css';

import ViewDeskTasks from '../compotasks/ViewDeskTasks';
import ViewMobileTasks from '../compotasks/ViewMobileTasks';

function ViewTasks() {
    return ( 
        <div id="viewtasks">
           <ViewDeskTasks />
           <ViewMobileTasks /> 
        </div>
    );
}

export default ViewTasks;