import React from 'react';
import './pouchdocumentation';
import './documentation.css';

import TableContent from './TableContent';
import TableContentView from './TableContentView';

import { 
    DocumentContainer, 
    TextBase, 
    FormTable, 
    //TextForm, 
} from './DocumentationStyles';

function Documentation() {
    return ( 
        <DocumentContainer id="documentcontainer">
            <br/>
            <TextBase>
                <label id='documenttitle'>Couchbench 0.1.0 Documentation</label>
            </TextBase>
            <br/><br/>
            <FormTable id='documentcontents'>
                <>
                <TableContent />
                <TableContentView />
                </>
            </FormTable>
        </DocumentContainer>
    );
}

export default Documentation;